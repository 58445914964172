import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import { compose } from "redux";
import { Users, Copy } from "react-feather";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  RenderTextWithoutLabel,
  RenderRemove,
  AddLecture,
  ImportLecture,
  SaveLecture,
} from "../../../Forms/LecturesForm";
import {
  RenderCheckbox,
  RenderMultiselect,
  RenderSelect,
  RenderText,
} from "../../../Forms/forms";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import NestedList from "../../../Common/Components/NestedList/NestedList";
import { getLectureData } from "../Actions";
import NestedProductElement from "../Components/NestedProductElement";
import NestedVersionElement from "../Components/NestedVersionElement";
import NestedModulesElement from "../Components/NestedModulesElement";
import NestedLectureElement from "../Components/NestedLectureElement";
import LecturersList from "../Components/LecturersList";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import WeeksDays from "../../../Enums/WeeksDays";
import {
  timeInMinutesToString,
  timeInStringToMinuts,
} from "../../../Common/Utils/DateFormatters";
import TimePickerInput from "../../../Forms/TimePickerInput";

class ProductsLectures extends Component {
  constructor(props) {
    super(props);
    const { status, lecturesForImport } = props;
    this.state = {
      status,
      lecturesForImport: lecturesForImport ? lecturesForImport : [],
      shouldDisableByStatus:
        status === PRODUCT_STATUSES.ACTIVE ||
        status === PRODUCT_STATUSES.INACTIVE,
      modalIsOpen: false,
      modalLecturesIsOpen: false,
      selectedLectures: [],
      courseModeFilter: null,
    };
  }

  removeLecture = removedIndex => {
    const { fields } = this.props;
    const _lecture = fields.get(removedIndex);
    const items = fields.getAll();
    if (!!_lecture && !!_lecture.imported) {
      this.setState({
        lecturesForImport: this.state.lecturesForImport.map(product => {
          product.versions = product.versions.map(version => {
            version.modules = version.modules.map(module => {
              module.lectures = module.lectures.map(lecture => {
                if (lecture.id === _lecture.id) {
                  lecture.checked = false;
                }
                return lecture;
              });
              return module;
            });
            return version;
          });
          return product;
        }),
      });
    }
    items.splice(removedIndex, 1);
    if (items.length === 0) {
      items.push({
        sequence: 0,
        duration: 0,
      });
    }
    fields.removeAll();
    items.forEach((item, index) => {
      fields.push({
        ...item,
        sequence: index,
      });
    });
  };

  copyLecture = copyIndex => {
    const { fields } = this.props;
    const _lecture = fields.get(copyIndex);

    if (_lecture) {
      fields.push({
        ..._lecture,
        id: null,
        originModuleLectureId: _lecture.moduleLectureId,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { status, lecturesForImport } = nextProps;
    const { status: currStatus } = this.props;
    if (status !== currStatus) {
      this.setState({
        status,
        shouldDisableByStatus:
          status === PRODUCT_STATUSES.ACTIVE ||
          status === PRODUCT_STATUSES.INACTIVE,
      });
    }
    if (
      lecturesForImport &&
      lecturesForImport.length !== this.state.lecturesForImport.length
    ) {
      this.setState({ lecturesForImport });
    }
  }

  getTotalDuration() {
    let duration = 0;
    const lectures = this.props.fields.getAll() || [];

    lectures.forEach(lecture => {
      if (lecture.duration) {
        duration += timeInStringToMinuts(lecture.duration);
      }
    });
    return Math.floor(duration / 60);
  }

  onNestedListUpdate = (values, items) => {
    this.setState({
      selectedLectures: values,
      lecturesForImport: items,
    });
  };

  import = () => {
    const { fields } = this.props;
    let currSequence = fields.length;
    let unique = [];
    this.state.lecturesForImport.forEach(product => {
      product.versions.forEach(version => {
        version.modules.forEach(module => {
          module.lectures.forEach(lecture => {
            if (lecture.id) {
              let mayPush = true;
              let lectureIsNotImported = false;
              let allFields = fields.getAll();
              const fieldToPush = {
                sequence: currSequence,
                id: lecture.id,
                name: lecture.name,
                duration: timeInMinutesToString(lecture.duration),
                lecturersAmount: lecture.lecturersAmount,
                imported: true,
              };
              if (this.state.selectedLectures.indexOf(lecture.id) < 0) {
                lectureIsNotImported = true;
              }
              allFields.forEach(field => {
                if (field.id === lecture.id) {
                  mayPush = false;
                  if (lectureIsNotImported) {
                    allFields.splice(allFields.indexOf(fieldToPush));
                  }
                }
              });
              if (unique.indexOf(lecture.id) >= 0) {
                mayPush = false;
              }
              if (mayPush && !lectureIsNotImported) {
                unique.push(lecture.id);
                fields.push(fieldToPush);
                currSequence++;
              }
            }
          });
        });
      });
    });
    this.setState({ modalIsOpen: false });
  };

  onClearTime = field => {
    const { change } = this.props;
    change(field, null);
  };

  onChangeFilter = filterId => this.setState({ courseModeFilter: filterId });

  render() {
    const {
      moduleId,
      fields,
      cantEdit,
      t,
      productVersionsMeta,
      courseModes,
      saveLecturesHandler,
      handleSubmit,
    } = this.props;

    const { courseModeFilter } = this.state;

    return (
      <div className="row lectures">
        <CustomModal
          isOpen={this.state.modalLecturesIsOpen}
          title={t("List of lecturers with the required competences")}
          onRequestClose={() => {
            this.setState({ modalLecturesIsOpen: false });
          }}
        >
          <Field name="lecturerSkills" component={RenderText} />
          <LecturersList
            lectureData={this.props.lectureData}
            lecturerSkillsValue={this.props.lecturerSkillsValue}
          />
        </CustomModal>

        <CustomModal
          isOpen={this.state.modalIsOpen}
          title={t("List of lecturers")}
          onRequestClose={() => {
            this.setState({ modalIsOpen: false });
          }}
        >
          <NestedList
            dataSource={this.state.lecturesForImport}
            config={[
              {
                childrenKey: "versions",
                decorator: NestedProductElement,
                checkAvailable: true,
              },
              {
                childrenKey: "modules",
                decorator: NestedVersionElement,
                checkAvailable: true,
              },
              {
                childrenKey: "lectures",
                decorator: NestedModulesElement,
                checkAvailable: true,
              },
              {
                decorator: NestedLectureElement,
                checkAvailable: true,
                key: "id",
              },
            ]}
            onListUpdate={(values, items) => {
              this.onNestedListUpdate(values, items);
            }}
          />
          <button type="button" className="btn btn-dark" onClick={this.import}>
            Importuj
          </button>
          <button
            type="button"
            onClick={() => {
              this.setState({ modalIsOpen: false });
            }}
            className="btn btn-link btn-regular"
          >
            {t("Cancel")}
          </button>
        </CustomModal>
        <div className="col-xs-12">
          <h4>
            <span>{t("List of lectures, support and self work")}</span>
          </h4>
          <p className="lectures__summary">
            <span>{t("Duration")}:</span>
            <br />
            <span>
              {this.getTotalDuration()} {t("hour")}.
            </span>
            <br />
            <span>
              <i>{t("1 day = 8 hours")}</i>
            </span>
          </p>
        </div>
        <div className="col-xs-12">
          <div className="padding__b20 padding__l30">
            <h5>{t("Mode")}</h5>
            <div>
              <div
                onClick={() => this.onChangeFilter(null)}
                className={`${courseModeFilter === null ? "btn-dark " : ""}btn`}
              >
                {t("All")}
              </div>
              {courseModes.map(mode => (
                <div
                  key={mode.id}
                  onClick={() => this.onChangeFilter(mode.id)}
                  className={`${
                    courseModeFilter === mode.id ? "btn-dark " : ""
                  }btn`}
                >
                  {mode.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <header className="lectures__header ">
            <h5 className="lectures__cell lectures__cell--title">
              <span>{t("Day of the course")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--title">
              <span>{t("Day of the week")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--title">
              <span>{t("Title")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--title">
              <span>{t("Lecture's type")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--title">
              <span>{t("Mode")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--duration">
              <span>{t("Duration")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--duration">
              <span>{t("Start")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--lecturers">
              <span>{t("Participants")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--room">
              <span>{t("Room")}</span>
            </h5>
            <h5 className="lectures__cell lectures__cell--lecturers">
              <span>{t("Lecturers")}</span>
            </h5>
          </header>
          <div>
            {(() => {
              const LECTURE_HEIGHT = 52;
              if (!fields.getAll()) {
                return "";
              }
              const sortedFields = fields
                .getAll()
                .sort(
                  (firstValue, secondValue) =>
                    firstValue.sequence - secondValue.sequence,
                );
              const fieldNames = fields.map(field => field);
              return (
                <ul
                  className="lectures__list"
                  style={{ height: sortedFields.length * LECTURE_HEIGHT }}
                >
                  {sortedFields.map((lecture, index) => {
                    let lectureData = [];
                    const lectureJson = fields.get(index);
                    const disableByImportedAndStatus =
                      lectureJson.isImportedInOther ||
                      (lectureJson.sourceModule &&
                        lectureJson.sourceModule !== moduleId) ||
                      this.state.shouldDisableByStatus ||
                      lectureJson.imported;

                    if (this.props.lecturesCompetencies.length > 0) {
                      lectureData = this.props.lecturesCompetencies.filter(
                        item => {
                          return item.id === lectureJson.id;
                        },
                      );
                    }

                    const shouldRender =
                      courseModeFilter === null
                        ? true
                        : !lectureJson.courseMode ||
                          lectureJson.courseMode === courseModeFilter;

                    return (
                      shouldRender && (
                        <li className="lectures__item" key={index}>
                          <Field
                            name={`${fieldNames[index]}.courseDay`}
                            component={RenderTextWithoutLabel}
                            required="required"
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.weekDay`}
                            component={RenderSelect}
                            onClearCallback={() => {
                              this.props.change(
                                `${fieldNames[index]}.weekDay`,
                                null,
                              );
                            }}
                            required="required"
                            dropdownConfig={{
                              data: WeeksDays.OBJECTS.map(weekDay => {
                                weekDay.name = t(weekDay.name);
                                return weekDay;
                              }),
                              textField: "name",
                              valueField: "id",
                            }}
                            under={{
                              fieldClassName: "col-xs-12",
                            }}
                            className={`lectures__cell lectures__cell--title`}
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.name`}
                            component={RenderTextWithoutLabel}
                            required="required"
                            props={{
                              shouldDisabled:
                                cantEdit || !this.props.moduleId
                                  ? true
                                  : disableByImportedAndStatus,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.type`}
                            component={RenderSelect}
                            required="required"
                            onClearCallback={() => {
                              this.props.change(
                                `${fieldNames[index]}.type`,
                                null,
                              );
                            }}
                            dropdownConfig={{
                              data: productVersionsMeta.lectureTypes,
                              textField: "name",
                              valueField: "id",
                            }}
                            className={`lectures__cell lectures__cell--title`}
                            under={{
                              fieldClassName: "col-xs-12",
                            }}
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.courseMode`}
                            component={RenderSelect}
                            required="required"
                            onClearCallback={() => {
                              this.props.change(
                                `${fieldNames[index]}.courseMode`,
                                null,
                              );
                            }}
                            dropdownConfig={{
                              data: courseModes,
                              textField: "name",
                              valueField: "id",
                            }}
                            className={`lectures__cell lectures__cell--title`}
                            under={{
                              fieldClassName: "col-xs-12",
                            }}
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.duration`}
                            component={TimePickerInput}
                            customClass={`lectures__cell lectures__cell--title`}
                            required="required"
                            onClearCallback={() =>
                              this.onClearTime(`${fieldNames[index]}.duration`)
                            }
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.startTime`}
                            onClearCallback={() =>
                              this.onClearTime(`${fieldNames[index]}.startTime`)
                            }
                            component={TimePickerInput}
                            customClass={`lectures__cell lectures__cell--title`}
                            required="required"
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <Field
                            name={`${fieldNames[index]}.participants`}
                            component={RenderMultiselect}
                            required="required"
                            className={`lectures__cell lectures__cell--title`}
                            dropdownConfig={{
                              valueField: "id",
                              textField: "name",
                              placeholder: t("Choose participants"),
                              data: productVersionsMeta.lectureParticipants,
                            }}
                            under={{
                              fieldClassName: "col-xs-12",
                            }}
                            props={{
                              shouldDisabled: cantEdit || !this.props.moduleId,
                            }}
                          />
                          <div className="lectures__cell lectures__cell--room">
                            <Field
                              name={`${fieldNames[index]}.roomRequired`}
                              component={RenderCheckbox}
                              props={{
                                shouldDisabled:
                                  cantEdit || !this.props.moduleId
                                    ? true
                                    : disableByImportedAndStatus,
                              }}
                            />
                          </div>
                          <div className="lectures__cell lectures__cell--lecturers">
                            <button
                              onClick={() => {
                                if (lectureData && lectureData.length > 0) {
                                  this.props.getLectureData(lectureData);
                                  this.setState({
                                    modalLecturesIsOpen: true,
                                  });
                                }
                              }}
                              className="btn btn-regular btn-link btn-with-icon margin__r15"
                              type="button"
                              disabled={
                                cantEdit
                                  ? true
                                  : !lectureData ||
                                    (lectureData.length > 0 &&
                                      Number(lectureData[0].lecturersCount) ===
                                        0)
                              }
                            >
                              <Users size={20} />
                              <u>
                                x{" "}
                                {lectureData.length > 0
                                  ? Number(lectureData[0].lecturersCount)
                                  : "0"}
                              </u>
                            </button>
                            <button
                              disabled={cantEdit}
                              type="button"
                              className="btn btn-with-icon btn-regular btn-link"
                              onClick={() => this.copyLecture(index)}
                            >
                              <Copy size={20} />
                            </button>
                            <RenderRemove
                              callback={() => {
                                this.removeLecture(index);
                              }}
                              disabled={
                                cantEdit
                                  ? true
                                  : this.props.meta.submitting ||
                                    this.state.shouldDisableByStatus ||
                                    lectureJson.isImportedInOther
                              }
                            />
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              );
            })()}
          </div>
          <div className="row lectures__actions">
            {!cantEdit ? (
              <Fragment>
                <SaveLecture
                  callback={handleSubmit(() =>
                    saveLecturesHandler(fields.getAll(), moduleId),
                  )}
                  disabled={
                    !this.props.moduleId ||
                    this.props.meta.submitting ||
                    this.state.shouldDisableByStatus
                  }
                />
                <AddLecture
                  callback={() =>
                    fields.push({
                      sequence: fields.length,
                      duration: 0,
                      moduleLectureId: null,
                    })
                  }
                  disabled={
                    !this.props.moduleId ||
                    this.props.meta.submitting ||
                    this.state.shouldDisableByStatus
                  }
                />
                <ImportLecture
                  callback={() => {
                    this.setState({ modalIsOpen: true });
                  }}
                  disabled={
                    !this.props.moduleId ||
                    this.props.meta.submitting ||
                    this.state.shouldDisableByStatus
                  }
                />
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({
  lectureData,
  lecturesCompetencies,
  lecturesForImport,
}) {
  return {
    lectureData,
    lecturesCompetencies,
    lecturesForImport,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getLectureData }, dispatch);
}

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProductsLectures);

ProductsLectures.propTypes = {
  status: PropTypes.string,
  lecturesForImport: PropTypes.array,
  fields: PropTypes.object,
  t: PropTypes.func,
  moduleId: PropTypes.number,
  lectureData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  lecturerSkillsValue: PropTypes.array,
  lecturesCompetencies: PropTypes.array,
  meta: PropTypes.object,
  getLectureData: PropTypes.func,
  cantEdit: PropTypes.bool,
  productVersionsMeta: PropTypes.object,
  change: PropTypes.func,
  saveLecturesHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  courseModes: PropTypes.array,
};
