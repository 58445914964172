export default fields => {
  let errors = {};
  const { pollsList } = fields;
  let pollsListErrors = [];
  pollsList &&
    pollsList.forEach((poll, index) => {
      pollsListErrors[index] = {};
      if (!poll.name) {
        pollsListErrors[index] = {
          ...pollsListErrors[index],
          name: "Enter the poll's name",
        };
      }
      if (
        poll.templateUrl &&
        !poll.templateUrl.match(
          "^(?:http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
        )
      ) {
        pollsListErrors[index].templateUrl = "Not a valid url";
      }
    });
  errors.pollsList = { ...pollsListErrors };
  return errors;
};
