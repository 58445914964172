import React from "react";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";
import {
  in_review_icon,
  in_send_icon,
  in_send_retry_icon,
  not_handled_icon,
  not_sent_icon,
  ready_to_send_icon,
  sent_error_icon,
  sent_icon,
} from "../assets/icons";

type StatusIconProviderProps = {
  status: InvoiceSendStatus;
};

const StatusIconProvider: React.FunctionComponent<StatusIconProviderProps> = ({
  status,
}): React.ReactElement => {
  switch (status) {
    case InvoiceSendStatus.NOT_SENT:
      return not_sent_icon;
    case InvoiceSendStatus.READY_TO_SEND:
      return ready_to_send_icon;
    case InvoiceSendStatus.SENT:
      return sent_icon;
    case InvoiceSendStatus.IN_REVIEW:
      return in_review_icon;
    case InvoiceSendStatus.IN_SEND:
      return in_send_icon;
    case InvoiceSendStatus.IN_SEND_RETRY:
      return in_send_retry_icon;
    case InvoiceSendStatus.SENT_ERROR:
      return sent_error_icon;
    case InvoiceSendStatus.NOT_HANDLED:
      return not_handled_icon;

    default:
      return null;
  }
};

export default StatusIconProvider;
