import axios from "axios/index";
import FileSaver from "file-saver";
import { store } from "../../Configs/Root";
import ACTION_TYPES from "../../Enums/ActionTypes";
import COMMON from "../../Enums/Common";
import LANGUAGES from "../../Enums/Languages";
import ERROR_CODES from "../../Enums/ErrorCodes";
import {
  beginAjaxCall,
  endAjaxCall,
} from "../../Modules/App/AjaxStatus/AjaxStatusActions";
import { OK } from "http-status-codes";
import { getFileNameFromHeaders } from "./HttpClientUtils";
import { get } from "lodash";

function requestHeaders(token, contentType, language, apiVersion) {
  return {
    "Content-Type": contentType,
    "X-Accept-Version": apiVersion,
    Authorization: `Bearer ${token}`,
    "Content-Language": language,
  };
}
function setHeaders(fileName = "", language, apiVersion) {
  const token = store.getState().token;
  switch (fileName) {
    case fileName.includes(".pdf"):
      return requestHeaders(token, "application/pdf", language, apiVersion);
    case fileName.includes(".xls"):
      return requestHeaders(
        token,
        "application/vnd.ms-excel",
        language,
        apiVersion,
      );
    default:
      return requestHeaders(token, "application/json", language, apiVersion);
  }
}

function blobFile(data, fileName) {
  if (data.status === OK) {
    const blob = new Blob([data.data]);
    FileSaver.saveAs(blob, fileName);
  }
}

class ClientApi {
  constructor(store) {
    this.store = store;
  }
  getCurrentLanguageCode = () => {
    const code = LANGUAGES[this.store.getState().language].code;
    return typeof code === "string" ? code : code[0];
  };

  actionGET = config => {
    const options = {
      path: null,
      actionType: null,
      params: {},
      apiVersion: COMMON.API_VERSION_V1,
      callback: null,
      privateRequest: true,
      blobHeader: null,
      fileName: "",
      cancelToken: null,
      callGlobalLoader: false,
      ...config,
    };

    const {
      path,
      actionType,
      blobHeader,
      fileName,
      params,
      callback,
      privateRequest,
      cancelToken,
      apiVersion,
    } = options;

    return dispatch => {
      const requestConfig = privateRequest
        ? {
            headers: setHeaders("", this.getCurrentLanguageCode(), apiVersion),
            responseType: blobHeader ? "blob" : "",
            params,
          }
        : { params };
      if (cancelToken && cancelToken !== null && cancelToken.token) {
        requestConfig.cancelToken = cancelToken.token;
      }

      if (config.callGlobalLoader) {
        dispatch(beginAjaxCall());
      }

      return axios
        .get(path, requestConfig)
        .then(data => {
          let name = fileName;
          const headers = get(data, "headers");

          if (!!headers) {
            const fileNameFormHeader = getFileNameFromHeaders(headers);
            if (fileNameFormHeader) {
              name = fileNameFormHeader;
            }
          }

          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          blobHeader
            ? blobFile(
                data,
                data.request.getResponseHeader("X-suggested-filename")
                  ? data.request.getResponseHeader("X-suggested-filename")
                  : name,
              )
            : null;
          if (callback) {
            callback(data, dispatch);
          }
          return dispatch({
            type: actionType,
            payload: data,
          });
        })
        .catch(error => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          if (
            error.response &&
            error.response.status &&
            error.response.status === ERROR_CODES.MAINTENANCE
          ) {
            return dispatch({
              type: ACTION_TYPES.MAINTENANCE_MODE_ON,
              payload: true,
            });
          }
          return dispatch({
            type: ACTION_TYPES.PASS_ERROR_TO_STORE,
            payload: error,
          });
        });
    };
  };

  actionPUT = config => {
    const options = {
      path: null,
      actionType: null,
      values: {},
      callback: null,
      apiVersion: COMMON.API_VERSION_V1,
      callGlobalLoader: false,
      ...config,
    };

    const { path, actionType, values, callback, apiVersion } = options;

    return dispatch => {
      if (config.callGlobalLoader) {
        dispatch(beginAjaxCall());
      }

      return axios
        .put(path, values, {
          headers: setHeaders("", this.getCurrentLanguageCode(), apiVersion),
        })
        .then(data => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          callback ? callback(data) : null;
          return dispatch({
            type: actionType,
            payload: data,
          });
        })
        .catch(error => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          if (
            error.response &&
            error.response.status &&
            error.response.status === ERROR_CODES.MAINTENANCE
          ) {
            return dispatch({
              type: ACTION_TYPES.MAINTENANCE_MODE_ON,
              payload: true,
            });
          }
          return dispatch({
            type: ACTION_TYPES.PASS_ERROR_TO_STORE,
            payload: error,
          });
        });
    };
  };

  actionPOST = config => {
    const options = {
      path: null,
      actionType: null,
      values: {},
      callback: null,
      blobHeader: null,
      fileName: "",
      apiVersion: COMMON.API_VERSION_V1,
      privateRequest: true,
      callGlobalLoader: false,
      ...config,
    };

    const {
      path,
      actionType,
      blobHeader,
      fileName,
      values,
      callback,
      apiVersion,
      privateRequest,
    } = options;
    const requestHeaders = privateRequest
      ? setHeaders(fileName, this.getCurrentLanguageCode(), apiVersion)
      : {
          "Content-Type": "application/json",
          "X-Accept-Version": "v1",
          "Content-Language": "en-US",
        };
    return dispatch => {
      if (config.callGlobalLoader) {
        dispatch(beginAjaxCall());
      }

      return axios
        .post(path, values, {
          headers: requestHeaders,
          responseType: blobHeader ? "blob" : "",
        })
        .then(data => {
          let name = fileName;
          const headers = get(data, "headers");

          if (!!headers) {
            const fileNameFormHeader = getFileNameFromHeaders(headers);
            if (fileNameFormHeader) {
              name = fileNameFormHeader;
            }
          }

          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          blobHeader ? blobFile(data, name) : null;
          callback ? callback(data) : null;
          return dispatch({
            type: actionType,
            payload: data,
          });
        })
        .catch(error => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          if (
            error.response &&
            error.response.status &&
            error.response.status === ERROR_CODES.MAINTENANCE
          ) {
            return dispatch({
              type: ACTION_TYPES.MAINTENANCE_MODE_ON,
              payload: true,
            });
          }
          dispatch({
            type: ACTION_TYPES.PASS_ERROR_TO_STORE,
            payload: error,
          });
        });
    };
  };

  actionDELETE = config => {
    const options = {
      path: null,
      actionType: null,
      callback: null,
      apiVersion: COMMON.API_VERSION_V1,
      params: {},
      callGlobalLoader: false,
      ...config,
    };

    const { path, actionType, callback, apiVersion, params } = options;

    return dispatch => {
      if (config.callGlobalLoader) {
        dispatch(beginAjaxCall());
      }

      return axios
        .delete(path, {
          headers: setHeaders("", this.getCurrentLanguageCode(), apiVersion),
          params,
        })
        .then(data => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          callback ? callback(data) : null;
          return dispatch({
            type: actionType,
            payload: data,
          });
        })
        .catch(error => {
          if (config.callGlobalLoader) {
            dispatch(endAjaxCall());
          }

          if (
            error.response &&
            error.response.status &&
            error.response.status === ERROR_CODES.MAINTENANCE
          ) {
            return dispatch({
              type: ACTION_TYPES.MAINTENANCE_MODE_ON,
              payload: true,
            });
          }
          dispatch({
            type: ACTION_TYPES.PASS_ERROR_TO_STORE,
            payload: error,
          });
        });
    };
  };
}

const Client = new ClientApi(store);

export default Client;
