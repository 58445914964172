import React from "react";
import HomePageElement from "./HomePageElement.js";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { getReportLinkForPermissions } from "../../../Common/Utils/Acl";

const HomePage = ({ t }) => {
  return (
    <div>
      <div className="row ">
        <HomePageElement
          title={t("Courses")}
          text={t("dates, cities, lecturers, students, schedules")}
          link="/courses"
          icon="courses"
          disabled={!havePermissions([permissions.COURSE_LIST])}
        />
        <HomePageElement
          title={t("Products")}
          text={t(
            "substantive scope, modules, number of lectures, prices and installments",
          )}
          link="/products"
          icon="products"
          disabled={!havePermissions([permissions.PRODUCT_LIST])}
        />
        <HomePageElement
          title={t("Persons")}
          text={t(
            "students, employees, co-workers, their data and competences",
          )}
          link="/persons"
          icon="persons"
          disabled={!havePermissions([permissions.PERSON_LIST])}
        />
        <HomePageElement
          title={t("Reports")}
          text={t("charts, timeline, graduates, surveys, financial data")}
          link={getReportLinkForPermissions()}
          icon="reports"
          disabled={
            !havePermissions([
              permissions.REPORT_TREND,
              permissions.REPORT_GROUPFILL,
              permissions.REPORT_TIMELINE,
              permissions.REPORT_GRADUATES,
              permissions.REPORT_INVOICES_XLS,
            ])
          }
        />
      </div>
    </div>
  );
};

HomePage.propTypes = { t: PropTypes.func };

export default translate()(HomePage);
