import {
  addContractPaymentsNote,
  editContractPaymentsNote,
  fetchContractPaymentsNotes,
  removeContractPaymentsNote,
} from "../Actions";
import { connect } from "react-redux";
import NoteEditModal from "../Components/NoteEditModal";
import { bindActionCreators } from "redux";

const mapStateToProps = (state, ownProps) => {
  const { contractPaymentsNote } = state;

  return {
    notes: contractPaymentsNote,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchContractPaymentsNotes,
      addContractPaymentsNote,
      removeContractPaymentsNote,
      editContractPaymentsNote,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoteEditModal);
