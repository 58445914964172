import React, { useState } from "react";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Field, Form, Formik } from "formik";
import { Edit, Mail, Phone } from "react-feather";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import validate, {
  InvoiceReceiverFormValues,
} from "../Validators/ContactToInvoiceSendValidator";
import { RenderTextInput } from "../../../Forms/InputComponents/TextInput";

export type InvoiceReceiver = {
  companyId: number;
  email: string;
  id: number;
  name: string;
  phone: string;
};

type ContactToInvoiceSendProps = InjectedTranslateProps & {
  invoiceReceiver: InvoiceReceiver;
  onEditContact: (values: InvoiceReceiverFormValues) => void;
};

const ContactToInvoiceSend: React.FunctionComponent<
  ContactToInvoiceSendProps
> = ({ t, invoiceReceiver, onEditContact }): React.ReactElement => {
  const [showForm, setShowForm] = useState(false);
  const initialValues = {
    name: invoiceReceiver.name,
    email: invoiceReceiver.email,
    phone: invoiceReceiver.phone,
  };
  return (
    <div id="contact-for-invoice-send" className={`col-xs-12 padding__t20`}>
      <div>
        <h3>{t("Data for sending invoices")}</h3>
      </div>

      <div>
        <div className={`row product-item `}>
          <div className={`col-xs-10 vertical-center`}>
            <div>
              <h5 className={`text product-name`}>{invoiceReceiver.name}</h5>
              <span className={`product-info`}>
                <p>
                  <span className={`product-info-details`}>
                    <Mail size={16} />: {invoiceReceiver.email}
                  </span>
                </p>
                <p>
                  <span className={`product-info-details`}>
                    <Phone size={16} />: {invoiceReceiver.phone}
                  </span>
                </p>
              </span>
            </div>
          </div>
          <Authorize
            allows={[permissions.COMPANY_EDIT]}
            component={
              <div className={`col-xs-2 vertical-center`}>
                <button
                  className={`btn--transparent btn--no-border margin__r15`}
                  title={t("Edit contact")}
                  onClick={() => setShowForm(true)}
                >
                  <Edit />
                </button>
              </div>
            }
          />
        </div>
        {showForm && (
          <div className={`content--with-background padding__t20 padding__b20`}>
            <div className="fade-in row">
              <div className="col-xs-10 col-xs-offset-1">
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={onEditContact}
                >
                  <Form>
                    <Field
                      component={RenderTextInput}
                      className="form-control"
                      name="name"
                      label={t("Name and Surname")}
                      type="text"
                    />

                    <Field
                      component={RenderTextInput}
                      className="form-control"
                      name="email"
                      label={t("E-Mail")}
                      type="text"
                    />

                    <Field
                      component={RenderTextInput}
                      className="form-control"
                      name="phone"
                      label={t("Phone")}
                      type="text"
                    />

                    <button className="btn btn-dark" type="submit">
                      {t("Save")}
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactToInvoiceSend;
