import React from "react";
import { translate } from "react-i18next";
import FUNDING_TYPES from "../../../Enums/FundingTypes";
import CONTRACT from "../../../Enums/Contract";
import PAYMENT_STATUSES from "../../../Enums/PaymentStatuses";
import CONTRACT_STATUSES from "../../../Enums/ContractStatuses";
import CONTRACT_PAYER_TYPES from "../../../Enums/ContractPayerType";
import COMMON from "../../../Enums/Common";
import TIME from "../../../Enums/Time";
import checkIfContractTypeIs from "../../../Helpers/CheckIfContractFinishReasonIs";
import daysTextFormat from "../../../Helpers/DaysTextFormat";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import checkIfContractStatusIs from "../../../Helpers/CheckIfContractStatusIs";
import { Edit2, RotateCcw } from "react-feather";
import PropTypes from "prop-types";
import moment from "moment";
import { Field } from "redux-form";
import PriceFieldWithCalculation from "../../../Forms/PriceFieldWithCalculation";
import ContractManualIncome from "../Containers/ContractManualIncome";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import {
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";
import ReactTableWithSummary from "../../../Common/Components/ReactTableWithSummary/ReactTableWithSummary";
import { RenderDateTime } from "../../../Forms/forms";
import Switch from "react-switch";
import AmountField from "../../../Forms/AmountField";

class ContractPayments extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    payments: PropTypes.array,
    tableLoading: PropTypes.bool,
    contractStatus: PropTypes.number,
    priceGrossCalculator: PropTypes.func,
    contractFinishReasonModule: PropTypes.number,
    contractFinishReason: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
    ]),
    rebate: PropTypes.number,
    currency: PropTypes.string,
    closePopup: PropTypes.func,
    contractId: PropTypes.number,
    fundingType: PropTypes.number,
    paymentActivate: PropTypes.func,
    paymentCancel: PropTypes.func,
    contractType: PropTypes.string,
    contractStatusId: PropTypes.number,
    submitStatus: PropTypes.bool,
    paymentPackageMismatch: PropTypes.bool,
    paymentScheduleReload: PropTypes.func,
    change: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      payments: this.preparePayments(props.payments),
      columns: this.buildTableColumns(props.payments),
    };
  }

  preparePayments = payments =>
    payments.map(payment => {
      payment.isActive = payment.status !== COMMON.PAYMENT_CANCELED_STATUS;
      return payment;
    });

  componentDidUpdate(prevProps) {
    const { payments, contractStatusId, submitStatus } = this.props;
    if (
      (payments && !_.isEqual(payments, this.state.payments)) ||
      contractStatusId !== prevProps.contractStatusId
    ) {
      this.setState({
        payments: this.preparePayments(payments),
        columns: this.buildTableColumns(payments),
      });
    }
    if (submitStatus !== prevProps.submitStatus) {
      this.clearEditMode();
    }
  }

  clearEditMode = () => {
    const { payments } = this.state;
    this.setState({
      payments: payments.map(payment => {
        payment.editMode = [];
        return payment;
      }),
    });
  };

  renderEditDateField = rowData => {
    const { payments } = this.props;
    const indexInAllPayments = rowData.index;

    if (
      payments[indexInAllPayments] &&
      payments[indexInAllPayments].dates.deadline
    ) {
      const isAllow = havePermissions([permissions.STUDENT_CONTRACT_EDIT]);
      const date = moment(payments[indexInAllPayments].dates.deadline).format(
        TIME.DATE_FORMAT,
      );

      const editOnClick = () => {
        if (isAllow) {
          this.enableEditMode(rowData.column.id, rowData.index);
        }
      };

      const currentPayment = payments[rowData.index];

      return (
        <div className="editField" onClick={editOnClick}>
          {currentPayment &&
          currentPayment.editMode &&
          currentPayment.editMode.indexOf(rowData.column.id) !== -1 ? (
            <div className="datePickerContainer">
              <Field
                name={`payments.payments[${indexInAllPayments}].dates.deadline`}
                time={false}
                under={{ fieldClassName: "col-xs-12" }}
                component={RenderDateTime}
              />
            </div>
          ) : (
            <div>
              <span>
                {isAllow && <Edit2 size={16} />} {date}
              </span>
            </div>
          )}
        </div>
      );
    }
    return "";
  };

  renderPaymentDateFromContract = rowData => {
    if (rowData.original.dates && rowData.original.dates.contractDate) {
      return moment(rowData.original.dates.contractDate).format(
        TIME.DATE_FORMAT,
      );
    }
    return null;
  };

  enableEditMode(columnId, index) {
    const { payments } = this.state;
    if (!payments[index].editMode) {
      payments[index].editMode = [];
    }
    payments[index].editMode.push(columnId);
    this.setState({ payments });
  }

  renderEditFieldForPrice = rowData => {
    const { payments } = this.state;
    const indexInPayments = rowData.index;
    const { t, priceGrossCalculator } = this.props;
    const regularPrice = rowData.original.price;
    const paymentType = payments[rowData.index].type;
    const { ADDITIONAL_FEE_TYPE_ID } = CONTRACT;
    const isActive = havePermissions(
      paymentType === ADDITIONAL_FEE_TYPE_ID
        ? [
            permissions.STUDENT_CONTRACT_SPECIAL_PAYMENTS_EDIT_ON_FINISHED,
            permissions.STUDENT_CONTRACT_AMOUNT_EDIT,
          ]
        : [permissions.STUDENT_CONTRACT_AMOUNT_EDIT],
    );

    return (
      <div
        className="editField"
        onClick={() => {
          if (isActive) {
            this.enableEditMode(rowData.column.id, rowData.index);
          }
        }}
      >
        {payments[rowData.index] &&
        payments[rowData.index].editMode &&
        payments[rowData.index].editMode.indexOf(rowData.column.id) >= 0 ? (
          <div>
            <AmountField
              component={PriceFieldWithCalculation}
              t={t}
              name={`payments.payments[${indexInPayments}].price.amount`}
              currency={regularPrice.currency}
              priceCalculator={priceGrossCalculator}
            />
          </div>
        ) : (
          <div>
            <span>
              {isActive && <Edit2 size={16} />}{" "}
              {renderAmountWithCurrency(regularPrice)}
            </span>
          </div>
        )}
      </div>
    );
  };

  renderValueOrStatusField = rowData => {
    const { t } = this.props;
    const { payments } = this.state;
    if (!payments[rowData.index]) {
      return "";
    }

    const days = Math.abs(payments[rowData.index].deadlineDetails);

    switch (payments[rowData.index][rowData.column.id]) {
      case PAYMENT_STATUSES.UNPAID:
      case PAYMENT_STATUSES.PARTIALLY_PAID:
        if (payments[rowData.index].deadlineDetails === 0) {
          return (
            <span className="paymentStatusDetails">{t("No obligations")}</span>
          );
        }
        return payments[rowData.index].deadlineDetails > 0 ? (
          <span className="paymentStatusDetails">
            {t("Payment within")} {days} {t(daysTextFormat(days))}
          </span>
        ) : (
          <span className="paymentStatusDetails paymentStatusDetails--alert">
            {t("Payment delayed for")} {days} {t(daysTextFormat(days))}
          </span>
        );

      case PAYMENT_STATUSES.CANCELED:
        return (
          <span className="paymentStatusDetails paymentStatusDetails--alert">
            {t("Canceled")}
          </span>
        );

      case PAYMENT_STATUSES.PAID:
        return (
          <span className="paymentStatusDetails paymentStatusDetails--success">
            {t("Paid")}
          </span>
        );
    }
  };

  switchPaymentActive = (index, active) => {
    const { change } = this.props;
    const payments = [...this.state.payments];

    change(
      `payments.payments[${index}].status`,
      !active ? COMMON.PAYMENT_CANCELED_STATUS : COMMON.PAYMENT_ACTIVE_STATUS,
    );
    payments[index].isActive = active;
    this.setState({
      payments,
    });
  };

  paymentActiveSwitch = (payment, index) => {
    if (payment.isActive) {
      this.switchPaymentActive(index, false);
    } else {
      this.switchPaymentActive(index, true);
    }
  };

  buildTableColumns(payments) {
    const { t, contractStatusId } = this.props;
    if (!payments) {
      return [];
    }
    const dontDisplayPaymentDate =
      checkIfContractStatusIs(
        contractStatusId,
        CONTRACT_STATUSES.DURING_RECRUITMENT,
      ) ||
      checkIfContractStatusIs(
        contractStatusId,
        CONTRACT_STATUSES.CONTRACT_SEND,
      );
    const tableColumnsOptions = [
      {
        name: "installment",
        header: t("Installment number"),
        Cell: row =>
          row.original &&
          !row.original.shoudHideInstallment &&
          (row.original.installment
            ? row.original.installment.name
            : t("payment")),
      },
      {
        name: "contractDate",
        header: t("Payment date according to the contract"),
        width: 200,
        className: "rt-td--show-outside",
        Cell: row =>
          row.original &&
          !row.original.shoudHideInstallment &&
          this.renderPaymentDateFromContract(row),
      },
      {
        name: "deadline",
        header: t("Payment date"),
        Cell: this.renderEditDateField,
      },
      {
        name: "status",
        header: t("Settlement of receivables"),
        Cell: dontDisplayPaymentDate
          ? t("No obligations")
          : this.renderValueOrStatusField,
      },
      {
        name: "description",
        header: t("Module"),
        Cell: row => row.original.description,
      },
      {
        name: "side",
        header: t("Side"),
        Cell: row => row.original.contractPayer.balance.owner.name,
      },
      {
        header: t("Paid (gross)"),
        Cell: row => renderAmountWithCurrency(row.original.amountPaid),
        summaryAmount: "amountPaid.amount",
        summaryCurrency: "amountPaid.currency",
      },
      {
        name: "price",
        header: t("To pay (gross)"),
        Cell: row => {
          return row.original.price && this.renderEditFieldForPrice(row);
        },
        summaryAmount: "price.amount",
        summaryCurrency: "amountPaid.currency",
      },
      {
        name: "priceFromPriceList",
        header: t("Module's price (gross)"),
        Cell: row => {
          const { priceFromPriceList } = row.original;
          return (
            priceFromPriceList && renderAmountWithCurrency(priceFromPriceList)
          );
        },
        summaryAmount: "priceFromPriceList.amount",
        summaryCurrency: "amountPaid.currency",
      },
      {
        name: "priceFromPriceList.gross",
        header: t("Active payment"),
        Cell: row => (
          <Switch
            onChange={() => {
              this.paymentActiveSwitch(row.original, row.index);
            }}
            checked={row.original.isActive}
          />
        ),
      },
    ];
    let columns = [];
    const COLUMN_WIDTH = 80;
    tableColumnsOptions.forEach((column, index) => {
      let columnSettings = {
        Header: column.header,
        id: column.name,
        className: column.className || "",
        summaryAmount: column.summaryAmount,
        summaryCurrency: column.summaryCurrency,
      };
      if (index === 0) {
        columnSettings.maxWidth = COLUMN_WIDTH;
      }
      if (column.name !== null) {
        columnSettings.Cell = column.Cell
          ? column.Cell
          : this.renderValueOrTextField;
      } else if (column.accessor) {
        columnSettings.accessor = column.accessor;
      }
      columns.push(columnSettings);
    });
    return columns;
  }
  getSummaryConfig = () => {
    const { contractType, payments, t } = this.props;
    const summaryConfig = [
      {
        data: payments.filter(
          rowData =>
            rowData.contractPayer.side.id === CONTRACT_PAYER_TYPES.STUDENT,
        ),
        render: (amount = 0, currency = "PLN") => (
          <div>
            <span>
              {renderAmountWithCurrency({
                amount,
                currency,
              })}
            </span>
            <br />
            <small>{t("Student")}</small>
          </div>
        ),
      },
    ];
    if (!checkIfContractTypeIs(contractType, FUNDING_TYPES.INDEPENDENT)) {
      summaryConfig.push({
        data: payments.filter(
          rowData =>
            rowData.contractPayer.side.id === CONTRACT_PAYER_TYPES.THIRD_PARTY,
        ),
        render: (amount = 0, currency = "PLN") => (
          <div>
            <span>
              {renderAmountWithCurrency({
                amount,
                currency,
              })}
            </span>
            <br />
            <small>{t("Third party")}</small>
          </div>
        ),
      });
    }
    return summaryConfig;
  };
  render() {
    const {
      tableLoading,
      currency,
      t,
      contractId,
      paymentPackageMismatch,
      paymentScheduleReload,
    } = this.props;
    const { payments, showManualIncomeForm, manualIncomeData } = this.state;

    return (
      <div id="student-contract-payments" className="row">
        <Authorize
          component={
            <CustomModal
              isOpen={showManualIncomeForm}
              title={`${t("Add an income for")} ${(manualIncomeData &&
                manualIncomeData.description) ||
                t("Payment")}`}
              onRequestClose={() => {
                this.setState({ showManualIncomeForm: false });
              }}
              ariaHideApp={false}
            >
              <ContractManualIncome
                currency={currency}
                data={manualIncomeData}
                contractId={contractId}
                closePopup={() => {
                  this.setState({ showManualIncomeForm: false });
                  this.props.closePopup();
                }}
              />
            </CustomModal>
          }
          allows={[permissions.STUDENT_CONTRACT_MANUAL_INCOME]}
        />

        <div className="col-xs-12" id={`paymentsTable`}>
          {paymentPackageMismatch && (
            <div className="payments-cover">
              <div>{t("The payment schedule contains errors")}</div>
              <Authorize
                component={
                  <button
                    className={`btn btn-dark`}
                    onClick={paymentScheduleReload}
                    title={t("Payment schedule reset")}
                  >
                    <RotateCcw size={20} /> {t("Payment schedule reset")}
                  </button>
                }
                allows={[permissions.STUDENT_CONTRACT_AMOUNT_EDIT]}
              />
            </div>
          )}

          <Authorize
            component={
              <ReactTableWithSummary
                data={payments ? payments : []}
                summaryConfig={this.getSummaryConfig()}
                minRows={0}
                defaultPageSize={payments ? payments.length : 0}
                loading={tableLoading}
                showPagination={false}
                manual
                getTrProps={(state, rowInfo) => {
                  const payment = payments[rowInfo && rowInfo.index];
                  return payment && payment.lastInGroup
                    ? {
                        className: `${
                          rowInfo.index % 2 === 0
                            ? "rt-tr -odd last-in-group"
                            : "rt-tr -even last-in-group"
                        }`,
                      }
                    : {};
                }}
                columns={this.state.columns}
              />
            }
            allows={[permissions.STUDENT_CONTRACT_AMOUNT_VIEW]}
          />
        </div>
      </div>
    );
  }
}

export default translate()(ContractPayments);
