import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { getDateWithTime } from "../../../Common/Utils/DateFormatters";
import { Trash } from "react-feather";
import { Authorize, permissions } from "../../../Common/Utils/Acl";

const VISIBLE_ELEMENTS = 2;

const NotesList = ({ notes, t, handleRemoveNote }) => {
  const pages = _.chunk(notes, VISIBLE_ELEMENTS);
  const [pageNumber, setPageNumber] = useState(0);

  const renderHistory = () => {
    const notesForShow = pages[pageNumber];

    return (
      !!notesForShow &&
      notesForShow.map(note => {
        return (
          <li key={note.id}>
            <div>
              <div
                className="note-card__content"
                dangerouslySetInnerHTML={{ __html: note.note }}
              />
              <div className="small--grey">
                {getDateWithTime(note.createdAt)} {note.createdBy}
              </div>
              <div className="text-right">
                <Authorize
                  allows={[permissions.STUDENT_CONTRACT_EDIT]}
                  component={
                    <button
                      className="btn"
                      onClick={() => handleRemoveNote(note.id)}
                    >
                      <Trash size={18} />
                    </button>
                  }
                />
              </div>
            </div>
          </li>
        );
      })
    );
  };

  const handlePaginationType = (event, type) => {
    event.preventDefault();

    switch (type) {
      case "prev":
        setPageNumber(pageNumber - 1);
        break;

      case "next":
        setPageNumber(pageNumber + 1);
        break;
    }
  };

  return (
    <div className="studentHistory">
      <h3>{t("Notes")}</h3>

      {pages && pages.length > 0 ? (
        <ul className="studentHistory__list list-unstyled">
          {renderHistory()}
        </ul>
      ) : (
        <span className="studentHistory__noRecords">
          {t("No entries found")}
        </span>
      )}
      {pages.length > 1 && (
        <nav>
          <ul className="pagination">
            {pageNumber !== 0 && (
              <li className="page-item page-item-prev">
                <a
                  className="page-link"
                  href="#"
                  onClick={event => handlePaginationType(event, "prev")}
                >
                  {t("Previous")}
                </a>
              </li>
            )}
            {pages.length > pageNumber + 1 && (
              <li className="page-item page-item-next">
                <a
                  className="page-link"
                  href="#"
                  onClick={event => handlePaginationType(event, "next")}
                >
                  {t("Next")}
                </a>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};

NotesList.propTypes = {
  contractId: PropTypes.number,
  notes: PropTypes.array,
  handleRemoveNote: PropTypes.func,
  t: PropTypes.func,
};

export default translate()(NotesList);
