import React from "react";
import PropTypes from "prop-types";

const TimeLineCommentHeader = ({ t, data }) => {
  return (
    <div className="timeline__comment__header">
      {" "}
      <p>{data.signature} </p>
      {data.data.date ? <p>{data.data.date} </p> : ""}
      {data.data.moduleNr ? (
        <p>{`${t("Module")}: ${data.data.moduleNr}`} </p>
      ) : (
        ""
      )}
    </div>
  );
};

TimeLineCommentHeader.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
};

export default TimeLineCommentHeader;
