import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { RenderDateTime } from "../../../Forms/forms";
import { Field } from "redux-form";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import CourseSpecialistSelectWithPagination from "../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import MentorsSelectWithPagination from "../../../Common/Components/MentorsSelectWithPagination/MentorsSelectWithPagination";

class LecturersSettlement extends React.Component {
  static propTypes = {
    fetchLecturersSettlement: PropTypes.func,
    getCoursesSpecialists: PropTypes.func,
    fetchMentors: PropTypes.func,
    coursesSpecialists: PropTypes.array,
    mentorsList: PropTypes.array,
    t: PropTypes.func,
    validationErrors: PropTypes.object,
    values: PropTypes.object,
    change: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isWaitingForData: false,
      shouldDisplayError: false,
    };
    const { getCoursesSpecialists, fetchMentors } = props;
    this.cancelToken = getCancelToken();
    getCoursesSpecialists(this.cancelToken);
    fetchMentors(this.cancelToken);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  getReport = callback => {
    const { validationErrors } = this.props;
    if (!validationErrors) {
      this.setState({ isWaitingForData: true }, callback);
    }
  };

  handleReportDownload = () => {
    const {
      values: { startDate, endDate, mentorId, courseSpecialistId },
      fetchLecturersSettlement,
    } = this.props;

    fetchLecturersSettlement(
      {
        dateFrom: startDate,
        dateTo: endDate,
        format: "xls",
        mentor: mentorId,
        cs: courseSpecialistId,
      },
      () => {
        this.setState({ isWaitingForData: false });
      },
    );
  };

  render() {
    const { t, validationErrors, values } = this.props;
    const { isWaitingForData } = this.state;
    const cantGetReport =
      !values.startDate ||
      !values.endDate ||
      (validationErrors &&
        (validationErrors.startDate || validationErrors.endDate));
    const { change, coursesSpecialists, mentorsList } = this.props;

    return (
      <div className="main--row timeline">
        <div className="row">
          <div className="col-sm-12">
            <h1>{t("Lecturer settlement")}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1 text-right form-group  required">
            <label>{t("Date range")}</label>
          </div>
          <div className="col-md-2">
            <Field
              name="startDate"
              component={RenderDateTime}
              required
              time={false}
              className="custom-cols"
            />
          </div>
          <div className="col-md-2">
            <Field
              name="endDate"
              component={RenderDateTime}
              required
              time={false}
              className="custom-cols"
            />
          </div>
          <div className="col-md-2">
            <Field
              component={MentorsSelectWithPagination}
              name="mentorId"
              onChangeHandler={value => {
                this.props.change("mentorId", value.id);
              }}
              placeholder={t("Mentor")}
              getMentorsLists={this.props.fetchMentors}
              mentors={mentorsList}
              onClearCallback={() => change("mentorId", null)}
            />
          </div>
          <div className="col-md-2">
            <Field
              component={CourseSpecialistSelectWithPagination}
              name="courseSpecialistId"
              onChangeHandler={value => {
                this.props.change("courseSpecialistId", value.id);
              }}
              placeholder={t("Course Specialist")}
              getCoursesSpecialists={this.props.getCoursesSpecialists}
              coursesSpecialists={coursesSpecialists}
              onClearCallback={() => change("courseSpecialistId", null)}
            />
          </div>
          <div className="col-md-2">
            <Authorize
              component={
                <button
                  onClick={() => this.getReport(this.handleReportDownload)}
                  className="btn btn-dark btn-margin"
                  disabled={cantGetReport}
                >
                  {t("Download Report")}
                </button>
              }
              allows={[permissions.REPORT_LECTURER_SETTLEMENT]}
            />
            {isWaitingForData && (
              <div className="text-loader">
                {t("File is generating")}
                <span className="text-loader__animation" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(LecturersSettlement);
