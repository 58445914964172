import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Edit, Mail, Phone, Trash, UserCheck } from "react-feather";
import ContactForm from "../Containers/ContactForm";
import { Authorize, permissions } from "../../../Common/Utils/Acl";

const ContactsList = ({
  t,
  contacts,
  onRemove,
  onEdit,
  contactIdForEdit,
  companyId,
  onUpdate,
}) => {
  return (
    <div>
      {contacts.map(contact => (
        <div key={contact.email}>
          <div className={`row product-item `}>
            <div className={`col-xs-10 vertical-center`}>
              <div>
                <h5 className={`text product-name`}>{contact.name}</h5>
                <span className={`product-info`}>
                  <p>
                    <span className={`product-info-details`}>
                      <Mail size={16} />: {contact.email}
                    </span>
                  </p>
                  <p>
                    <span className={`product-info-details`}>
                      <Phone size={16} />: {contact.phone}
                    </span>
                  </p>
                  {contact.contractOwner && (
                    <p>
                      <span className={`product-info-details`}>
                        <UserCheck size={16} />: {t(`contractOwner`)}
                      </span>
                    </p>
                  )}
                </span>
              </div>
            </div>
            <Authorize
              allows={[permissions.COMPANY_EDIT]}
              component={
                !contactIdForEdit && (
                  <div className={`col-xs-2 vertical-center`}>
                    <button
                      className={`btn--transparent btn--no-border`}
                      title={t("Remove contact")}
                      onClick={() => onRemove(contact.id)}
                    >
                      <Trash />
                    </button>
                    <button
                      className={`btn--transparent btn--no-border margin__r15`}
                      title={t("Edit contact")}
                      onClick={() => onEdit(contact.id)}
                    >
                      <Edit />
                    </button>
                  </div>
                )
              }
            />
          </div>
          {contactIdForEdit && contact.id === contactIdForEdit && (
            <div
              className={`content--with-background padding__t20 padding__b20`}
            >
              <ContactForm
                companyId={companyId}
                initialValues={contact}
                onAdd={onUpdate}
                onCancel={() => onEdit(null)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

ContactsList.propTypes = {
  contacts: PropTypes.array,
  contactIdForEdit: PropTypes.number,
  companyId: PropTypes.number,
  t: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default translate()(ContactsList);
