export default {
  VAT: 0.23,
  URL: { MANUAL: "/assets/pdfs/instrukcja.pdf" },
  REPORT_ASPECT: 20 / 9,
  LAST_DAY_FOR_PREV_MONTH_INVOICES: 10,
  PAYMENT_ACTIVE_STATUS: 0,
  PAYMENT_CANCELED_STATUS: 3,
  API_VERSION_V1: "v1",
  API_VERSION_V2: "v2",
};
