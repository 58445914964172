import React from "react";
import { translate } from "react-i18next";
import TimePicker from "rc-time-picker";
import moment from "moment/moment";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { FieldProps } from "formik";
import { renderFieldsHelper, RenderError } from "../FormikUtils";
import { timeToMoment } from "../../Common/Utils/DateFormatters";

type TimePickerSingleProps = FieldProps &
  InjectedTranslateProps & {
    disabled: boolean;
    className?: string;
  };

const TimePickerSingle: React.FunctionComponent<TimePickerSingleProps> = (
  props,
): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);
  const { t, disabled, field, form, className } = props;

  return (
    <div className={`${className ? className : ""} ${classes ? classes : ""} `}>
      <TimePicker
        focusOnOpen
        name={field.name}
        format={"HH:mm"}
        defaultOpenValue={moment()
          .set("hour", 0)
          .set("minute", 0)}
        showSecond={false}
        value={timeToMoment(field.value)}
        minuteStep={15}
        onChange={value => {
          const time = value ? moment(value).format("HH:mm") : null;
          form.setFieldValue(field.name, time);
        }}
        disabled={disabled}
        clearText={t("Clear")}
      />
      {displayError && <RenderError t={t} error={error} />}
    </div>
  );
};

export default translate()(TimePickerSingle);
