import { connect } from "react-redux";
import Contracts from "../Components/Contracts/Contracts";
import { bindActionCreators } from "redux";
import React from "react";
import { forgetUser } from "../../Auth/Actions";
import { fetchContracts } from "../Actions/CoursesActions";

const ContractsContainer = props => <Contracts {...props} />;

const mapStateToProps = appState => {
  const {
    ajaxCallInProgressCount,
    courses: {
      contractsList: { items: contracts, counter },
    },
  } = appState;

  return {
    contracts,
    counter,
    isLoading: ajaxCallInProgressCount > 0,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ forgetUser, fetchContracts }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractsContainer);
