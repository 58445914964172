import React, { Component } from "react";
import {
  clearErrorFromStore,
  clearSaveStateFromStore,
} from "../../App/Actions";
import { error, removeAll } from "react-notification-system-redux";
import notificationOpts from "../../../Helpers/NotificationOpts";
import Notification from "../../../Common/Components/Notification/Notification";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import { getOAuth2UserInfo } from "../../../Common/Utils/GoogleLogin";
import GoogleLoginButton from "./GoogleLoginButton";

class Login extends Component {
  static propTypes = {
    notifyRemoveAll: PropTypes.func,
    showError: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    notifyError: PropTypes.func,
    clearErrorFromStore: PropTypes.func,
    onLogin: PropTypes.func,
    onLoginByPass: PropTypes.func,
    t: PropTypes.func,
    notifications: PropTypes.array,
  };

  state = { shownErrorNotification: false, email: "", password: "" };

  componentWillUnmount() {
    this.props.notifyRemoveAll();
  }

  componentDidMount() {
    const { showError, notifyError, clearErrorFromStore } = this.props;
    if (showError && showError.message && !this.state.shownErrorNotification) {
      let modifiedMessage;
      showError.code === 401
        ? (modifiedMessage = "Sesja wygasła. Prosimy zalogować się ponownie")
        : "";
      notifyError(
        notificationOpts({
          title: modifiedMessage || showError.message,
          uid: 5,
          onRemove: () => {
            clearErrorFromStore();
            this.setState({ shownErrorNotification: false });
          },
        }),
      );
      this.setState({ shownErrorNotification: true });
    }
  }
  handleGoogleLoginSuccess = async data => {
    const { access_token } = data;
    const userData = await getOAuth2UserInfo(access_token);

    this.props.clearErrorFromStore();
    if (userData) {
      const { email, given_name, family_name, picture } = userData;

      this.props
        .onLogin(access_token, email, `${given_name} ${family_name}`, picture)
        .then(resolve => {
          const { type } = resolve;
          if (type === ACTION_TYPES.PASS_ERROR_TO_STORE) {
            this.setState({ shouldDisplayLoginError: true });
          }
        });
    }
  };

  handleGoogleLoginError = errorData => {
    const { notifyError } = this.props;
    const { error, error_description } = errorData;

    notifyError({ title: error, message: error_description });
  };
  getTokenByPass = () => {
    this.props.clearErrorFromStore();
    if (this.state.password === "" || this.state.email === "") {
      this.setState({ shouldDisplayLoginError: true });

      return;
    }

    this.props
      .onLoginByPass(this.state.email, this.state.password)
      .then(resolve => {
        const { type } = resolve;
        if (type === ACTION_TYPES.PASS_ERROR_TO_STORE) {
          this.setState({ shouldDisplayLoginError: true });
        }
      });
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      shouldDisplayLoginError: false,
    });
  };

  handleEnter = event => {
    if (event.key === "Enter") {
      this.getTokenByPass();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <Notification notifications={this.props.notifications} />
        <div className="loginpanel">
          {this.state.shouldDisplayLoginError && (
            <span className="login-test-header">
              {t("Sorry, your attempt was unsuccessful. Try again.")}
            </span>
          )}
          <div className="txt">
            <input
              id="user"
              type="text"
              name="email"
              placeholder="E-mail"
              value={this.state.email}
              onChange={this.handleInputChange}
              onKeyUp={this.handleEnter}
            />
          </div>
          <div className="txt">
            <input
              id="pwd"
              type="password"
              name="password"
              placeholder={t("Password")}
              value={this.state.password}
              onChange={this.handleInputChange}
              onKeyUp={this.handleEnter}
            />
          </div>
          <div className="buttons">
            <input
              type="button"
              value={t("Login")}
              onClick={this.getTokenByPass}
            />
          </div>
          <div className="hr">
            <div></div>
            <div>{t("or")}</div>
            <div></div>
          </div>
          <div className="buttons txt">
            <GoogleLoginButton
              buttonText={t("Login with google")}
              onSuccess={this.handleGoogleLoginSuccess}
              onError={this.handleGoogleLoginError}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      notifyError: error,
      notifyRemoveAll: removeAll,
      clearErrorFromStore,
      clearSaveStateFromStore,
    },
    dispatch,
  );
}

function mapStateToProps({ showError, notifications }) {
  return {
    notifications,
    showError,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
