import React from "react";
import TIME from "../../../../Enums/Time";
import { Link } from "react-router-dom";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Edit, Info, Trash } from "react-feather";
import ReactTable from "react-table";
import { Invoice } from "../Types/Invoice";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";
import { havePermissions, permissions } from "../../../../Common/Utils/Acl";
import dateHelper from "../../../../Helpers/DateHelper";
import InvoiceStatusCell from "./InvoiceStatusCell";
import renderAmountWithCurrency from "../../../../Helpers/RenderAmountWithCurrency";
import { shouldCheckboxDisabled } from "../Utils/CheckboxDisabledStatus";
import SendLogs from "./SendLogs";
import Tooltip from "react-simple-tooltip";

type InvoicesListProps = InjectedTranslateProps & {
  invoices: Array<Invoice>;
  pageSize: number;
  handleDownloadInvoice: (invoice: Invoice) => void;
  handleSelectInvoiceForDelete: (invoice: Invoice) => void;
  handleSelectInvoiceForCorrection: (invoice: Invoice) => void;
  onRowSelect: (invoice: Invoice) => void;
  onColumnSelect: () => void;
  selectedRows: Array<number>;
  onChangeStatus: (
    transition: { name: string; to: InvoiceSendStatus },
    invoice: Invoice,
  ) => void;
};

const InvoicesList: React.FunctionComponent<InvoicesListProps> = ({
  t,
  invoices,
  pageSize,
  onRowSelect,
  onColumnSelect,
  handleDownloadInvoice,
  handleSelectInvoiceForDelete,
  handleSelectInvoiceForCorrection,
  selectedRows,
  onChangeStatus,
}): React.ReactElement => {
  const renderDownloadColumn = ({ original }): React.ReactElement => {
    const { nr, deleteReason, canBeEditedOrRemoved } = original;
    return (
      <div className={"flex"}>
        <a
          className={"margin__r15"}
          onClick={() => handleDownloadInvoice(original)}
        >
          {nr}
        </a>

        {deleteReason ? (
          <span title={deleteReason}>
            <Info color="#337ab7" size={18} />
          </span>
        ) : (
          canBeEditedOrRemoved && (
            <a
              onClick={() => handleSelectInvoiceForDelete(original)}
              title={t("Remove")}
            >
              <Trash size={16} className={`black-link`} />
            </a>
          )
        )}
      </div>
    );
  };

  const renderCorrectionsColumn = ({ original }) => {
    const { corrections } = original;
    const label = corrections.map(correction => correction.number).join(", ");
    const permission = havePermissions([permissions.CORRECTION_MANAGEMENT]);

    return permission ? (
      <a
        title={label}
        onClick={() => handleSelectInvoiceForCorrection(original)}
      >
        <Edit className={`black-link`} size={16} /> {label}
      </a>
    ) : (
      <span title={label}>{label}</span>
    );
  };

  const tableColumnsConfig = [
    {
      id: "selection",
      width: 50,
      className: "text-center",
      sortable: false,
      Header: () => {
        return (
          <div className="text-center">
            <input
              type="checkbox"
              title="Select all invoices"
              checked={selectedRows.length === invoices.length}
              onChange={onColumnSelect}
            />
          </div>
        );
      },
      Cell: ({ row }) => {
        const selected = !!selectedRows.find(id => id === row._original.id);

        return (
          <div>
            <input
              type="checkbox"
              checked={selected}
              disabled={shouldCheckboxDisabled(row._original)}
              onChange={() => onRowSelect(row._original)}
            />
          </div>
        );
      },
    },
    {
      Header: () => <div>{t(`Number`)}</div>,
      Cell: renderDownloadColumn,
      width: 200,
      accessor: "nr",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Corrections`)}</div>,
      Cell: renderCorrectionsColumn,
      width: 200,
      accessor: "corrections",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Issue date`)}</div>,
      Cell: ({ original: { issueDate } }) => (
        <span>
          {dateHelper(issueDate)
            .get()
            .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "issueDate",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Sell date`)}</div>,
      Cell: ({ original: { sellDate } }) => (
        <span>
          {dateHelper(sellDate)
            .get()
            .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "sellDate",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Payment status`)}</div>,
      Cell: ({
        original: {
          status: { name = "" },
        },
      }) => <span>{name}</span>,
      accessor: "status",
      sortable: false,
      width: 100,
    },
    {
      Header: () => <div>{t(`Payment date`)}</div>,
      Cell: ({ original: { paymentDate } }) => (
        <span>
          {dateHelper(paymentDate)
            .get()
            .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "paymentDate",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Amount`)}</div>,
      Cell: ({ original: { grossValue } }) => (
        <span>{renderAmountWithCurrency(grossValue)}</span>
      ),
      accessor: "grossValue.amount",
      sortable: false,
      className: "text-right",
      width: 100,
    },
    {
      Header: () => <div>{t(`Payer`)}</div>,
      Cell: ({
        original: {
          buyer: { name, id, type },
        },
      }) => {
        let url = `/persons/update/${id}/3`;
        if (type === `company`) {
          url = `/balance/${id}`;
        }
        return <Link to={url}>{name}</Link>;
      },
      accessor: "buyer.name",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Send status`)}</div>,
      Cell: ({ original }) => {
        return original.sendStatusLog.length > 0 ? (
          <Tooltip
            placement="left"
            color="#000"
            background="#FFF"
            zIndex={99}
            radius={15}
            content={<SendLogs t={t} logs={original.sendStatusLog} />}
          >
            <InvoiceStatusCell
              t={t}
              invoice={original}
              handleChangeStatus={transition =>
                onChangeStatus(transition, original)
              }
            />
          </Tooltip>
        ) : (
          <InvoiceStatusCell
            t={t}
            invoice={original}
            handleChangeStatus={transition =>
              onChangeStatus(transition, original)
            }
          />
        );
      },
      width: 200,
      className: "table-cell-overflow",
      accessor: "sendStatus",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Receiver emails`)}</div>,
      Cell: ({ original: { receiverEmails = [] } }) => {
        const emails = receiverEmails.join(", ");
        return <div title={emails}>{emails}</div>;
      },
      accessor: "receiverEmails",
      sortable: false,
      width: 200,
    },
    {
      Header: () => <div>{t(`Send by`)}</div>,
      Cell: ({ original: { lastSender } }) =>
        lastSender ? (
          <div>{`${lastSender.name} ${lastSender.surname}`}</div>
        ) : null,
      accessor: "lastSender",
      sortable: false,
      width: 200,
    },
  ];

  return (
    <div>
      <div className="row horizontal-center">
        <div className="col-md-12">
          <div className={`row`} id="invoices-list">
            <ReactTable
              noDataText={t("No records found")}
              showPagination={false}
              pageSize={pageSize}
              data={invoices}
              sortable
              columns={tableColumnsConfig}
              className="-striped -highlight refunds-table invoices-list-table"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesList;
