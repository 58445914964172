export default fields => {
  let errors = {};
  const {
    studentAddress: { city, country, address, zipCode },
  } = fields;
  if (!address) {
    errors.studentAddress = {
      ...errors.studentAddress,
      address: "Enter the street name",
    };
  }
  if (!country) {
    errors.studentAddress = {
      ...errors.studentAddress,
      country: "Choose country name",
    };
  }
  if (!city) {
    errors.studentAddress = {
      ...errors.studentAddress,
      city: "Choose city name",
    };
  }
  if (!zipCode) {
    errors.studentAddress = {
      ...errors.studentAddress,
      zipCode: "Enter the zip code",
    };
  }
  return errors;
};
