import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addUserEngageLeadType,
  fetchUserEngageLeadType,
  updateUserEngageLeadType,
  deleteUserEngageLeadType,
} from "../Actions";
import PipedriveMap from "../Components/PipedriveMap";

const FORM_NAME = "DICTIONARY_USER_ENGAGE_TYPE";

let DictionaryCoursesModes = props => <PipedriveMap {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "userEngageLeadTypes");
  let { userEngageLeadTypes } = state;

  return {
    values,
    initialValues: { userEngageLeadTypes },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserEngageLeadType,
      addUserEngageLeadType,
      updateUserEngageLeadType,
      deleteUserEngageLeadType,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryCoursesModes),
);
