import { isEmail } from "validator";

const phoneValidRegEx = /^\+?[0-9]{8,16}$/;

export default fields => {
  const errors = {};
  const { name, email, phone } = fields;

  if (!name) {
    errors.name = "Enter a valid contact name";
  }

  if (!phone || !phoneValidRegEx.test(phone)) {
    errors.phone = "Enter a valid phone number";
  }

  if (!email || !isEmail(email)) {
    errors.email = "Enter a valid e-mail address formatted name@domain.com";
  }

  return errors;
};
