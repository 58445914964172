import React from "react";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";

type GoogleLoginProps = {
  buttonText: string;
  onSuccess: (
    tokenResponse: Omit<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >,
  ) => void;
  onError?: (
    errorResponse: Pick<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >,
  ) => void;
};

const GoogleLoginButton: React.FunctionComponent<GoogleLoginProps> = ({
  buttonText,
  onSuccess,
  onError,
}): React.ReactElement => {
  const login = useGoogleLogin({
    onSuccess,
    onError,
    flow: "implicit",
    prompt: "",
  });

  return <button onClick={() => login()}>{buttonText}</button>;
};

export default GoogleLoginButton;
