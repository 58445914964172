import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import ReactTable from "react-table";
import Loader from "../../../Common/Components/Loader/Loader";
import getNestedObject from "../../../Helpers/GetNestedObject";
import { Field } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import EditDictionaryField from "../Helpers/EditDictionaryField";

const FORM_ACTIONS = {
  SAVE: "SAVE",
  CREATE: "CREATE",
};

class Comments extends Component {
  state = {
    editing: [],
    isLoading: false,
    action: null,
    confirmationPopup: false,
    leadIdForRemove: null,
  };

  componentDidMount() {
    const { fetchDictComments } = this.props;
    fetchDictComments();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize } = this.props;
    if (initialValues.dictComments !== prevProps.initialValues.dictComments) {
      initialize(initialValues);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;

    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }

  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`dictComments[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  addDictComment = () => {
    const { change, values } = this.props;
    const newValues = [
      {
        comment: null,
      },
      ...values,
    ];
    change("dictComments", newValues);
  };

  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    const submitter = handleSubmit(this.onSubmit);

    this.setState(
      {
        action,
        rowId,
      },
      submitter,
    );
  };

  onSubmit = values => {
    const { addDictComment, updateDictComment, fetchDictComments } = this.props;
    const { action } = this.state;

    const dictComment = values.dictComments.find(
      dictComment => dictComment.id === this.state.rowId,
    );

    switch (action) {
      case FORM_ACTIONS.SAVE:
        updateDictComment(dictComment, () => {
          fetchDictComments(this.cancelToken);
          const { editing } = this.state;
          const currentEditing = [...editing];
          const rowIndex = editing.indexOf(dictComment.id);

          if (rowIndex >= 0) {
            currentEditing.splice(rowIndex, 1);
          }

          this.setState({
            editing: currentEditing,
          });
        });
        break;

      case FORM_ACTIONS.CREATE:
        addDictComment(dictComment, () => fetchDictComments(this.cancelToken));

        break;
    }
  };

  removeEmptyLead = () => {
    const { change, values } = this.props;
    const dictComments = values.filter(value => value.id);

    change("dictComments", dictComments);
  };

  render() {
    const { t, values } = this.props;
    const { isLoading } = this.state;
    const addButtonDisabled = values && values.find(value => !value.id);

    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={addButtonDisabled}
                className="btn bottom-space btn btn-dark"
                onClick={this.addDictComment}
              >
                {t("Add new comment")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={isLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("Comment"),
                accessor: "comment",
                Cell: this.renderEditField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(FORM_ACTIONS.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(FORM_ACTIONS.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

Comments.propTypes = {
  fetchDictComments: PropTypes.func,
  dictComments: PropTypes.array,
  updateDictComment: PropTypes.func,
  addDictComment: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  change: PropTypes.func,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    dictComments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        comment: PropTypes.string,
      }),
    ).isRequired,
  }),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      comment: PropTypes.string,
    }).isRequired,
  ),
};

export default translate()(Comments);
