export default {
  "Lecture rooms": "Schulungsräume",
  "Course types": "Kursarten",
  "Course modes": "Kursformate",
  "Employees availability": "Verfügbarkeit der Mitarbeiter",
  "Employee data": "Angaben zum Mitarbeiter",
  "Basic data": "Grundangaben",
  "Basic Price": "Preis Basic",
  "Professional Price": "Preis Profi",
  "Premium Price": "Preis Premium",
  Payers: "Zahler",
  "Add room": "Kursraum hinzufügen",
  Courses: "Kurse",
  courses: "Kurse",
  Products: "Produkte",
  Persons: "Personen",
  Dictionaries: "Wörterbücher",
  "Withdrawal description": "Beschreibung des Rücktritts",
  "Courses, schedules & students management":
    "Verwaltung von Kursen, Zeitplänen und Kursteilnehmern",
  "Log out": "Abmelden",
  "Add new course": "Neuen Kurs hinzufügen",
  "Date range": "Zeitraum",
  "Download Timeline": "Die Zeitachse herunterladen",
  "Download Report": "Den Bericht herunterladen",
  "Download Extended Report": "Den erweiterten Bericht herunterladen",
  "File is generating": "Die Datei wird generiert",
  "Add dates range": "Bitte den Zeitraum angeben",
  clear: "Löschen",
  Cities: "Städte",
  City: "Stadt",
  Modes: "Formate",
  Mode: "Format",
  Statuses: "Status",
  Mentors: "Mentoren",
  Mentor: "Mentor",
  "Course specialists": "Betreuer",
  "Course Specialist": "Kursbetreuer",
  Product: "Produkt",
  Publication: "Veröffentlichung",
  "Start date": "Beginn",
  "End date": "Ende",
  "Cancel date": "Absagedatum",
  "Number of students": "Anzahl der Kursteilnehmer",
  "Number of places": "Anzahl der Plätze",
  Submit: "Speichern",
  Cancel: "Abbrechen",
  Delete: "Löschen",
  "Clear changes": "Änderungen löschen",
  "Cancel course": "Kurs absagen",
  From: "Von",
  To: "Bis",
  Set: "Einstellen",
  "Lecture hours": "Kurszeiten",
  Select: "Auswählen",
  Module: "Modul",
  Day: "Tag",
  Lecture: "Vorlesung",
  Lecturer: "Kurslehrer",
  Room: "Schulungsraum",
  "Number of hours": "Stundenanzahl",
  "Day of the week": "Wochentag",
  Settlement: "Abrechnung",
  "Creating schedule...": "Der Zeitplan wird erstellt...",
  "No date for schedule": "Keine Daten für den Zeitplan",
  Settings: "Einstellungen",
  Students: "Kursteilnehmer",
  Questionnaires: "Umfragen",
  Free: "Frei",
  Overbooking: "Überbuchung",
  "Add student": "Kursteilnehmer hinzufügen",
  "Add person": "Person hinzufügen",
  "Search student": "Kursteilnehmer suchen",
  Help: "Hilfe",
  Type: "Typ",
  "Form contains errors": "Das Formular weist Fehler auf",
  "Clear errors from the 'Basic Data' tab":
    "Fehler in der Registerkarte Grundangaben löschen",
  "Clear errors from the 'Employee Data' tab":
    "Fehler in der Registerkarte Angaben zum Mitarbeiter löschen",
  "New person": "Eine neue Person",
  "Are You sure You want to leave this page without saving the form?":
    "Möchten Sie diese Seite wirklich verlassen, ohne das Formular zu speichern?",
  "Are You sure You want to leave withour saving?":
    "Möchten Sie diese Seite wirklich verlassen, ohne die Änderungen zu speichern?",
  "Are You sure You want to clear the changes made?":
    "Möchten Sie die Änderungen wirklich löschen?",
  "Unless You save the changes, recovering then will not be possible":
    "Wenn Sie die Änderungen nicht speichern, gehen sie verloren.",
  "By clearing the changes You remove the possibility of recovering them":
    "Gelöschte Änderungen können nicht wiederhergestellt werden",
  Yes: "Ja",
  No: "Nein",
  Comments: "Bemerkungen",
  "Zip code": "PLZ",
  Country: "Land",
  Address: "Anschrift",
  "Contact address": "Postanschrift",
  "Use as a contact address": "Als Postanschrift verwenden",
  "Registered address": "Wohnanschrift",
  Phone: "Telefon",
  Email: "E-Mail",
  "Contact details": "Kontaktdaten",
  "Document Nr": "Nr. des Dokumentes",
  PIN: "PESEL",
  Sex: "Geschlecht",
  Female: "weiblich",
  F: "w",
  Male: "männlich",
  M: "m",
  Surname: "Nachname",
  NamePerson: "Vorname",
  "Choose a user type": "Benutzertyp auswählen",
  Lead: "Potenzieller",
  Employee: "Mitarbeiter",
  Student: "Kursteilnehmer",
  Partnership: "Zusammenarbeit",
  "Contract type": "Vertragstyp",
  "Contract expiration date": "Datum des Vertragsendes",
  "Contract start date": "Datum des Vertragsbeginns",
  Availability: "Verfügbarkeit",
  "Wants to be a mentor": "Er/sie will Mentor sein",
  Competencies: "Kompetenzen",
  "Additional Competencies": "Zusätzliche Kompetenzen",
  "Chosen Competencies": "Ausgewählte Kompetenzen",
  "Employee Permissions": "Berechtigungsprofile",
  "Payment status": "Status der Zahlung",
  "Contract status": "Status des Vertrags",
  "Payment date according to the contract": "Vertragliche Zahlungsfrist",
  ActiveRoom: "Aktiv",
  InactiveRoom: "Inaktiv",
  Add: "Hinzufügen",
  Status: "Status",
  NameItem: "Bezeichnung",
  "No.": "lfd. Nr.",
  "UE VAT": "USt.-IdNr.",
  "% VAT": "% MwSt.",
  "Payer type": "Typ des Zahlers",
  REGON: "REGON",
  TIN: "NIP",
  "Add payer": "Zahler hinzufügen",
  "No results": "Keine Ergebnisse",
  "Writing test score": "Testergebnis Schreiben",
  "English test score": "Testergebnis Englisch",
  "Logic test score": "Testergebnis Logik",
  Question: "Frage",
  Summary: "Zusammenfassung",
  "Knowledge test and interview": "Wissenstest und Gespräch",
  Return: "Zurück",
  Invoice: "Rechnung",
  "Booking date": "Datum der Buchung",
  "Transaction date": "Datum des Geschäftsvorgangs",
  Amount: "Betrag",
  "Transfer title": "Verwendungszweck der Überweisung",
  "Payment history - company": "Übersicht der Einzahlungen - Firma",
  "Payment history - student": "Übersicht der Einzahlungen - Kursteilnehmer",
  Payer: "Zahler",
  "Payment date": "Zahlungsziel",
  "Issue date": "Ausstellungsdatum",
  "Sell date": "Verkaufsdatum",
  Number: "Nummer",
  "List of Invoices": "Liste der Rechnungen",
  Financing: "Finanzierung",
  "Schedule and payment status": "Zahlungsplan und -status ",
  "Discount(%)": "Rabatt (%)",
  "Number of agreement with LO": "Nummer des Vertrags mit dem Arbeitsamt",
  "Company's account number (SWIFT)": "Bankverbindung der Firma (SWIFT)",
  "Company's account number (IBAN)": "Bankverbindung der Firma (IBAN)",
  "Student's account number (SWIFT)":
    "Bankverbindung des Kursteilnehmers (SWIFT)",
  "Student's account number (IBAN)":
    "Bankverbindung des Kursteilnehmers (IBAN)",
  "Company's sub-account": "Unterkonto der Firma",
  "Student's sub-account": "Unterkonto des Kursteilnehmers",
  "Bank accounts": "Bankkonten",
  "Show student's history": "Übersicht Kursteilnehmer anzeigen",
  "Final status": "Abschlussstatus",
  "Course end": "Kursabschluss",
  "Not shared": "Nicht freigegeben",
  "Prework shared": "Freigabe des Preworks",
  Payments: "Zahlungen",
  Contract: "Vertrag",
  "You will lose current table changes":
    "Die aktuellen Änderungen an der Tabelle werden verloren gehen",
  "Are You sure You want to change the financing type?":
    "Möchten Sie den Typ der Finanzierung wirklich ändern?",
  "Are You sure You want to change the payer?":
    "Möchten Sie den Zahler wirklich ändern?",
  "You will lose the current table changes":
    "Alle laufenden Änderungen am Terminplan werden verloren gehen",
  "Withdrawal date": "Datum des Rücktritts",
  "Reason for withdrawal": "Rücktrittsgrund",
  "Contract number": "Vertragsnummer",
  "Date of signing the contract": "Vertrag unterzeichnet am",
  "Contract send date": "Vertrag abgesandt am",
  "Payment within": "Zahlung für",
  Paid: "Bezahlt",
  "Waiting for DoL": "Warten auf das Arbeitsamt",
  Expired: "Überfällig",
  "From which module": "Ab welchem Modul",
  "Reason for resigning": "Grund der Abmeldung",
  "Date of resignation": "Datum der Abmeldung",
  "Choose module": "Modul auswählen",
  "Enter course's signature": "Kurssignatur eingeben",
  "Show diploma": "Diplom anzeigen",
  "Show certificate": "Zertifikat anzeigen",
  "Diploma's number": "Diplomnummer",
  "After rebate": "Nach Rabatt",
  Awaiting: "Erwartet",
  Overpaid: "Überzahlung",
  "No obligations": "Keine Verbindlichkeiten",
  "Paid (gross)": "Bezahlt (brutto)",
  "To pay (gross)": "Zu zahlen (brutto)",
  "Module's price (gross)": "Modulpreis (brutto)",
  "Invoice Pos.": "Pos. der Rechnung",
  "Paid by a third party (gross)": "Bezahlt Drittpartei (brutto)",
  "To pay by a third party (gross)": "Zu zahlen Drittpartei (brutto)",
  "Paid by the student (gross)": "Bezahlt Kursteilnehmer (brutto)",
  "To pay by the student (gross)": "Zu zahlen Kursteilnehmer (brutto)",
  day: "Tag",
  days: "Tage",
  gross: "Brutto",
  net: "Netto",
  Mon: "Mo.",
  Tue: "Di.",
  Wed: "Mi.",
  Thu: "Do.",
  Fri: "Fr.",
  Sat: "Sa.",
  Sun: "Nein",
  "labor office": "Arbeitsamt",
  foundation: "Stiftung",
  company: "Firma",
  parp: "PARP",
  power: "Power",
  kfs: "KFS",
  open: "Open",
  credit: "Kredit",
  "The payer has been added": "Der Zahler wurde hinzugefügt",
  Code: "Code",
  "VAT UE": "USt.-Id.Nr.",
  NO: "NEIN",
  YES: "JA",
  "Search in GUS": "Beim GUS suchen",
  "Company's name": "Firmenname",
  independent: "Selbständig",
  mixed: "Gemischt",
  "during recruitment": "Im Laufe des Bewerbungsverfahrens",
  "contract send": "Vertrag abgesandt",
  "contract signed": "Vertrag unterzeichnet",
  "contract denied": "Rücktritt vom Vertrag",
  "course cancelled": "Kurs abgesagt",
  "Transfer from": "Übertragung aus",
  clearCommitments: "Keine Verpflichtungen",
  wait: "Erwartet",
  expired: "Überfällig",
  overpay: "Überzahlung",
  "waiting labor office": "Erwartet Arbeitsamt",
  paid: "Bezahlt",
  lead: "Potenzieller",
  employee: "Mitarbeiter",
  student: "Kursteilnehmer",
  Monday: "Montag",
  Tuesday: "Dienstag",
  Wednesday: "Mittwoch",
  Thursday: "Donnerstag",
  Friday: "Freitag",
  Saturday: "Samstag",
  Sunday: "Sonntag",
  "Enter the name": "Namen angeben",
  "Enter the street name": "Straße angeben",
  "Enter the zip code": "PLZ angeben",
  "Choose the city": "Stadt auswählen",
  "Choose product's kind": "Art des Produkts auswählen",
  "Enter product's name": "Namen des Produkts angeben",
  "Enter 3-letter long shortcut of the product's name":
    "3-stelligen Kurznamen des Produkts angeben",
  "Choose a language": "Sprache auswählen",
  "Choose a currency": "Währung auswählen",
  "Choose a city": "Stadt auswählen",
  "Choose the business owner": "Geschäftlichen Inhaber auswählen",
  "Choose the essential owner": "Fachlichen Inhaber auswählen",
  "Enter the resignation fee": "Stornobetrag angeben",
  "Enter the transfer fee": "Übertragungsbetrag angeben",
  "Enter the module's name": "Namen des Moduls angeben",
  "Choose the number of instalments": "Ratenanzahl auswählen",
  "Enter the module's price": "Preis des Moduls angeben",
  "Enter the lecture's name": "Namen der Vorlesung angeben",
  "Enter the lecture's duration": "Dauer der Vorlesung angeben",
  "Enter the person's name": "Vornamen angeben",
  "Enter the surname": "Nachnamen angeben",
  "Choose sex": "Geschlecht auswählen",
  "Invalid National ID": "Falsche PESEL-Nummer",
  "Choose the user's type": "Benutzertyp auswählen",
  "Choose a country": "Land auswählen",
  "Enter a valid e-mail address formatted name@domain.com":
    "Korrekte E-Mail-Adresse im Format name@domain.com angeben",
  "Enter a valid phone number": "Korrekte Telefonnummer angeben",
  "Specify whether the employee is a Course Specialist":
    "Festlegen, ob der Mitarbeiter Courses Specialist ist",
  "Specify the contract type": "Vertragsart festlegen",
  "Specify the contract start date": "Datum des Vertragsbeginns festlegen",
  "Specify the course mode": "Format festlegen",
  "Specify the availability": "Verfügbarkeit festlegen",
  "The contract end date cannot be earlier than the contract start date":
    "Das Enddatum des Vertrages kann vor dem Beginndatum des Vertrages nicht liegen",
  Share: "Freigegeb.",
  "Import lecture": "Vorlesungen importieren",
  "Add lecture": "Vorlesungen hinzufügen",
  Lecturers: "Kurslehrer",
  Title: "Titel",
  "1 day = 8 hours": "1 Tag = 8 Stunden",
  hour: "St.",
  Duration: "Dauer",
  "List of lectures": "Liste von Vorlesungen",
  "List of lectures, support and self work":
    "Liste von Vorlesungen, Betreuung und Eigenarbeit",
  "List of lecturers": "Liste von Vorlesungen",
  "List of lecturers with the required competences":
    "Liste der einschlägig befähigten Mitarbeiter",
  "No lectures": "Keine Vorlesungen",
  "Installment number": "Nummer der Rate",
  Price: "Preis",
  "Add module": "Modul hinzufügen",
  "Remove module": "Modul löschen",
  "Module type": "Modultyp",
  "Module name": "Modulname",
  Author: "Verfasser",
  "Are you sure you want to remove this version?":
    "Möchten Sie diese Version wirklich löschen?",
  "By removing this version, you will not be able to restore it":
    "Die gelöschte Version kann nicht wiederhergestellt werden",
  "Product version history": "Geschichte der Produktversionen",
  "Remove version": "Version löschen",
  "Copy version": "Version kopieren",
  "Deactivate version": "Version deaktivieren",
  "Activate version": "Version aktivieren",
  "Deactivate product": "Produkt deaktivieren",
  "Activate product": "Produkt aktivieren",
  "Save and activate": "Speichern und aktivieren",
  Save: "Speichern",
  "Remove errors from products tab":
    "Fehler in der Registerkarte Produkte löschen",
  "Remove errors from module tab": "Fehler in der Registerkarte Module löschen",
  "Course summary": "Zusammenfassung des Kurses",
  "Thematic scope": "Thematischer Umfang",
  Language: "Sprache",
  "Charge for transfer": "Gebühr für Übertragung",
  "Charge for resignations": "Gebühr für Abmeldung",
  "Shortcut name": "Kurzname",
  "Product name": "Produktname",
  "Product type": "Produktart",
  Draft: "Vorläufig",
  InactiveProduct: "Inaktiv",
  Inactive: "Inaktiv",
  ActiveProduct: "Aktiv",
  version: "Version",
  Version: "Version",
  module: "Modul",
  Active: "Aktiv",
  "Import - Product": "Import - Produkt",
  "Essential owner": "Fachlicher Inhaber",
  "Bussines owner": "Geschäftlicher Inhaber",
  "Course status": "Status des Kurses",
  "Course type": "Kurstyp",
  "Add new product": "Neues Produkt hinzufügen",
  "Bad account number format": "Falsches Format der Kontonummer",
  "Choose status": "Status auswählen",
  "Enter the date of signing the contract":
    "Datum des Vertragsabschlusses angeben",
  "Enter the reason for withdrawal": "Grund des Rücktritts angeben",
  "Enter the date of withdrawal from the contract":
    "Datum des Vertragsrücktritts angeben",
  "Enter the date of sending the contract": "Absendedatum des Vertrags angeben",
  "Enter the customer's account number": "Kontonummer des Kunden angeben",
  "Enter the customer's account number (IBAN)":
    "Kontonummer des Kunden angeben (IBAN)",
  "Client's account number is too long (IBAN), allowed maximum 34 chars":
    "Die Kontonummer des Kunden ist zu lang (IBAN), maximal 34 Zeichen zulässig",
  "Client's account number is too long (SWIFT), allowed maximum 11 chars":
    "Die Kontonummer des Kunden ist zu lang (SWIFT), maximal 11 Zeichen zulässig",
  "Client's account number is too short (SWIFT), allowed minimum 8 chars":
    "Die Kontonummer des Kunden ist zu kurz (SWIFT), mindestens 8 Zeichen zulässig",
  "Give the course signature": "Kurssignatur angeben",
  "Give the module number": "Nummer des Moduls angeben",
  "Give the reason of resignation": "Grund der Abmeldung angeben",
  "Give the date of resignation": "Datum der Abmeldung angeben",
  "Give the from which module": "Angeben, ab welchem Modul",
  "Choose the type of financing": "Typ der Finanzierung auswählen",
  "Select the payer": "Zahler auswählen",
  "Give the installment number": "Nummer der Rate angeben",
  "Give the date": "Datum angeben",
  "Enter the amount": "Betrag angeben",
  "Enter the module": "Modul angeben",
  "Enter the number of places": "Geben Sie die Anzahl der Sitzplätze ein",
  "The amount can not be higher than the module price":
    "Der Betrag kann den Modulpreis nicht überschreiten",
  "Choose product": "Produkt auswählen",
  "Choose publication date": "Datum der Veröffentlichung auswählen",
  "Choose the course mode": "Kursformat auswählen",
  "Choose the start date of course": "Datum des Kursbeginns auswählen",
  "The number of seats should be greater than 0":
    "Die Anzahl der Plätze muss größer als 0 sein",
  "Publication date it has to be before start date":
    "Das Veröffentlichungsdatum muss vor dem Startdatum liegen",
  "Start date it has to be after publication date":
    "Das Startdatum muss nach dem Veröffentlichungsdatum liegen",
  "At least one day should be checked":
    "Mindestens ein Tag muss markiert werden",
  "The start hour should be less than the end hour":
    "Die Beginnuhrzeit muss kleiner sein als die Enduhrzeit",
  "Choose hour": "Stunde wählen",
  "Starting day of the course must overlap with lecture days":
    "Der erste Kurstag muss auf einen der Vorlesungstage fallen",
  "Enter the tax ID": "Steueridentifikationsnummer angeben",
  "Incorrect tax ID": "Falsche Steueridentifikationsnummer",
  "Choose payer type": "Typ des Zahlers auswählen",
  "Incorrect company ID": "Falsches Format der REGON-Nummer",
  "Give the VAT rate": "MwSt.-Satz angeben",
  "Give the street name": "Straße angeben",
  "Give the postal code": "PLZ angeben",
  "Give the name of the payer": "Namen des Zahlers angeben",
  "Give the % VAT": "% MwSt. angeben",
  "% VAT can not be negative number": "% MwSt. kann keine negative Zahl sein",
  Timetable: "Zeitplan",
  Parameters: "Parameter",
  "New course": "Neuer Kurs",
  "The lecturer is already assigned in the following courses":
    "Der Kurslehrer ist bereits den nachstehenden Kursen zugeordnet",
  "Reset Schedule": "Zeitplan zurücksetzen",
  "Course number": "Kursnummer",
  "Postpone counter": "Anzahl der Übertragungen",
  "Reset schedule": "Zeitplan zurücksetzen",
  "Change schedule": "Änderung des Zeitplans",
  "Are You sure, You want to leave this page without saving the form?":
    "Möchten Sie diese Seite wirklich verlassen, ohne das Formular zu speichern?",
  "Are You sure, You want to quit without saving?":
    "Möchten Sie diese Seite wirklich verlassen, ohne die Änderungen zu speichern?",
  "Are You sure, You want to clear the changes made":
    "Möchten Sie die Änderungen wirklich löschen?",
  "The field's change will result in deletion of current schedule and all the changes":
    "Die Änderung des Feldes wird zur Löschung des aktuellen Zeitplans und aller Änderungen führen",
  "The field's change will result in recalculation of the current schedule. Changes for specific lectures will be deleted":
    "Die Änderung des Feldes wird zur erneuten Berechnung des aktuellen Zeitplans führen Die Änderungen in den einzelnen Vorlesungen werden gelöscht.",
  "WARNING! Canceling the course will result in automatic termination of all contracts with students, calculation of reimbursements and the release of reservations for mentors and lecturers. This operation can not be undone! If you are sure you want to cancel the course click 'YES'.":
    "HINWEIS! Die Absage des Kurses wird automatisch zur Kündigung aller Verträge mit den Kursteilnehmern, Berechnung von Rückzahlungen sowie Annullierung der gebuchten Arbeitszeiten der Mentoren und Kurslehrer führen. Dieser Vorgang kann nicht rückgängig gemacht werden! Wenn Sie sicher sind, dass Sie den Kurs absagen möchten, klicken Sie auf „JA“.",
  "After erasing the changes, there will be no possibility of getting them back":
    "Gelöschte Änderungen können nicht wiederhergestellt werden",
  "WARNING! Moving forward will reset the course schedule during recording. Are You sure?":
    "HINWEIS! Wenn Sie fortfahren, wird der Zeitplan beim Speichern zurückgesetzt. Sind Sie sicher?",
  "Are You sure, You want to remove this version":
    "Möchten Sie diese Version wirklich löschen?",
  "Are You sure, You want to leave without saving the changes?":
    "Möchten Sie diese Seite wirklich verlassen, ohne die Änderungen zu speichern?",
  "Are You sure, You want to clear the changes?":
    "Möchten Sie die Änderungen wirklich löschen?",
  "After removing this version, there will be no possibility of getting it back":
    "Die gelöschte Version kann nicht wiederhergestellt werden",
  "Waiting labor office": "Erwartet Arbeitsamt",
  "New product": "Neues Produkt",
  Modules: "Module",
  "Before resignation": "Vor Abmeldung",
  "Before transfer": "Vor Übertragung",
  "Additional competencies": "Zusätzliche Kompetenzen",
  "Add competency": "Kompetenz hinzufügen",
  "Enter the competency": "Kompetenzbezeichnung angeben",
  "Give the number of module": "Angeben, ab welchem Modul",
  "Give the number of contract with LO":
    "Nummer des Vertrags mit dem Arbeitsamt angeben",
  "Choose country name": "Das gewünschte Land auswählen",
  "Choose city name": "Die gewünschte Stadt auswählen",
  "Street and number": "Straße und Hausnummer",
  "During Recruitment": "Bewerbungsverfahren",
  "During recruitment": "Bewerbungsverfahren",
  "Contracts Sent": "Verträge abgesandt",
  "Contracts sent": "Verträge abgesandt",
  "Contracts Signed": "Verträge unterzeichnet",
  Signed: "Unterzeichnet",
  "Resignations and Transfers": "Abmeldungen und Übertragungen",
  Resignations: "Abmeldungen",
  "On the course": "Während des Kurses",
  "diploma number": "Diplomnummer",
  "Active account": "Aktives Konto",
  Filters: "Filter",
  Sorting: "Sortieren",
  "Sort by": "Sortieren nach",
  "Signature, ascending": "Signatur, aufsteigend",
  "Signature, descending": "Signatur, absteigend",
  "Start date, ascending": "Beginndatum, aufsteigend",
  "Start date, descending": "Beginndatum, absteigend",
  Reports: "Berichte",
  Charts: "Diagramme",
  "The report end date cannot be earlier than the contract start date":
    "Das Enddatum des Berichts darf nicht vor dem Beginndatum des Berichts liegen",
  "Enter the start date": "Beginndatum angeben",
  "Enter the end date": "Enddatum angeben",
  "recruitment and offert score": "Bewertung Bewerbungsverfahren und Angebot",
  "Pre-work score": "Bewertung Prework",
  "Scrum Lab score": "Bewertung Scrum Lab",
  "Course finish score": "Schlussbewertung des Kurses",
  "Career LAB score": "Bewertung Career LAB",
  "Portfolio LAB score": "Bewertung Portfolio LAB",
  "the day of signing the contract": "Am Tag des Vertragsabschlusses",
  "a week before the course start": "Eine Woche vor Kursbeginn",
  "the last day of the scrum module": "Letzter Tag des Scrum-Moduls",
  "the last day of the course": "Letzter Kurstag",
  "28 days after ending of the course": "28 Tage nach Kursende",
  "4 weeks after ending of the course": "4 Wochen nach Kursende",
  "the last day of the module": "Letzter Modultag",
  "Not a valid url": "Falsche URL",
  "Enter the poll's name": "Bezeichnung der Umfrage eingeben",
  "Surname, ascending": "Nachnamen, aufsteigend",
  "Surname, descending": "Nachnamen, absteigend",
  "Payment status, ascending": "Zahlungsstatus, aufsteigend",
  "Payment status, descending": "Zahlungsstatus, absteigend",
  "Contract status, ascending": "Vertragsstatus, aufsteigend",
  "Contract status, descending": "Vertragsstatus, absteigend",
  "Final reason status, ascending": "Abschlussstatus, aufsteigend",
  Guardian: "Betreuer",
  "Final reason status, descending": "Abschlussstatus, absteigend",
  "Charge for package change": "Gebühr für Paketwechsel",
  "Enter the package change fee": "Betrag für Paketwechsel angeben",
  Package: "Paket",
  "Choose the package": "Paket auswählen",
  January: "Januar",
  February: "Februar",
  March: "März",
  April: "April",
  May: "Mai",
  Jun: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Dezember",
  Timeline: "Zeitachse",
  "No data": "Keine Angaben",
  "Are You sure You want to change the package?":
    "Sind Sie sicher, dass Sie das Paket wirklich wechseln möchten?",
  "Group details": "Belegung von Gruppen",
  Postponements: "Übertragungen",
  "Contracts signed": "Verträge unterzeichnet",
  Signature: "Signatur",
  "To start": "Bis zum Start",
  "Download List of Invoices": "Übersicht der Rechnungen",
  "logika test score": "Testergebnis Logik",
  "angielski test score": "Testergebnis Englisch",
  "pisanie test score": "Testergebnis Schreiben",
  "Knowledge test": "Wissenstest",
  Basic: "Basic",
  Professional: "Profi",
  Ultimate: "Ultimate",
  "save filter": "Filter speichern",
  "refresh filter": "Filter aktualisieren",
  "remove filter": "Filter löschen",
  "Starting module at the new course":
    "Modul Beginn der Veranstaltungen im neuen Kurs",
  "Expand modules": "Module erweitern",
  "Roll up modules": "Module reduzieren",
  "Choose filter": "Filter auswählen",
  good: "gut",
  midding: "durchschnittlich",
  bad: "schlecht",
  "course/days": "Kurstage",
  "students/days": "Kursteilnehmertage",
  "group's average": "Mittelwert der Gruppe",
  "Show started": "Begonnene anzeigen",
  "restore filter": "Filter zurücksetzen",
  "Search persons by competences": "Personen nach Kompetenzen suchen",
  "Search persons": "Suchen",
  "Your selected competences": "Ausgewählte Kompetenzen",
  "Lecturing in": "Er/sie lehrt in/an",
  "available in mode": "Verfügbar im Format",
  from: "von",
  to: "bis",
  room: "Kleiner Raum",
  "Show finished courses": "Beendete Kurse anzeigen",
  "With room": "Er/sie benötigt einen Saal",
  "Cancelled course": "Kurs abgesagt",
  Surveys: "Umfragen",
  "Course partial ratings": "Bewertungen Bestandteile des Kurses",
  "Modules ratings": "Bewertungen Module",
  "Slave ratings": "Bewertungen verbundene Kurse",
  Send: "Senden",
  "Send again": "Senden wiederholen",
  Sent: "Gesendet",
  "Days to send": "Tage bis zum Senden",
  "Sending event": "Ereignis Senden",
  "Add survey": "Umfrage hinzufügen",
  "Additional surveys": "Zusätzliche Umfragen",
  "Choose survey": "Umfrage auswählen",
  "Choose packages": "Pakete auswählen",
  "Survey types": "Umfragearten",
  "Add poll type": "Umfrageart hinzufügen",
  " we have a small issue...": " Wir haben ein kleines Problem...",
  "Tell us": "Sagen Sie uns",
  "what went wrong": "was schief gegangen ist",
  "Additional data": "Zusätzliche Angaben",
  "Sub-account": "Unterkonto",
  "Charge for resignation third party": "Gebühr für Abmeldung Drittpartei",
  "Charge for transfer third party": "Gebühr für Übertragung Drittpartei",
  "Charge for package change third party":
    "Gebühr für Paketwechsel Drittpartei",
  Company: "Firma",
  "The module's price can't be negative":
    "Der Modulpreis kann nicht negativ sein",
  Currency: "Währung",
  Graduates: "Absolventen",
  course_end: "Tage nach Kursende",
  course_end_negative: "Tage vor Kursende",
  course_start: "Tage nach Kursbeginn",
  course_start_negative: "Tage vor Kursbeginn",
  module_end: "Tage nach Modulende",
  module_end_negative: "Tage vor Modulende",
  module_start: "Tage nach Modulbeginn",
  module_start_negative: "Tage vor Modulbeginn",
  event_contract_signed: "Tage nach Vertragsabschluss",
  event_contract_signed_negative: "Tage vor Vertragsabschluss",
  at_the_course_start: "Am ersten Kurstag",
  at_the_course_end: "Am letzten Kurstag",
  at_the_module_start: "Am ersten Tag des Moduls",
  at_the_module_end: "Am letzten Tag des Moduls",
  at_the_event_contract_signed: "Am Tag des Vertragsabschlusses",
  Diploma: "Diplom",
  "Current marks": "Laufende Noten",
  "Show only last week": "Nur die letzte Woche anzeigen",
  effects: "Resultate",
  signature: "Signatur",
  "avbl date": "Datum der Freigabe",
  content: "Materialien",
  lecturer: "Kurslehrer",
  organization: "Organisation",
  filled: "Belegung",
  "Current students": "Gegenwärtige Kursteilnehmer",
  Transfers: "Übertragungen",
  "VAT Invoice": "Rechnung mit ausgewiesener MwSt.",
  "Correcting Invoice": "Korrekturrechnung",
  "Pro forma Invoice": "Proformarechnung",
  Income: "Einzahlung",
  "Third party": "Drittpartei",
  Side: "Seite",
  "Additional fee": "Zusätzliche Gebühr",
  "Insert additional fee": "Eingabe einer zusätzlichen Gebühr",
  "Subject of payment": "Gegenstand der Gebühr",
  "Enter the resignation fee and wait for gross amount":
    "Betrag für Abmeldung angeben und auf Berechnung des Bruttobetrags warten ",
  "Enter the transfer fee and wait for gross amount":
    "Betrag für Übertragung angeben und auf Berechnung des Bruttobetrags warten",
  "Enter the package change fee and wait for gross amount":
    "Betrag für Paketwechsel angeben und auf Berechnung des Bruttobetrags warten",
  "Student gross amount": "Student Bruttobetrag",
  "Thirdparty nett amount": "Drittpartei Nettobetrag",
  "Add fee": "Gebühr hinzufügen",
  "Add an income for": "Eingabe der Einzahlung in",
  "Add an income": "Eingabe der Einzahlung",
  Payment: "Zahlung",
  "Add income": "Einzahlung hinzufügen",
  "Income date": "Datum der Einzahlung",
  "Income title": "Verwendungszweck der Einzahlung",
  "Enter subject of the fee": "Gegenstand der Gebühr angeben",
  "Enter deadline of the fee": "Zahlungsfrist angeben",
  "Enter student's amount gross": "Bruttobetrag Student angeben",
  "Enter thirdparty's amount nett": "Nettobetrag Drittpartei angeben",
  "Enter the resignation fee and wait for nett amount":
    "Betrag für Abmeldung angeben und auf Berechnung des Bruttobetrags warten",
  "Enter the transfer fee and wait for nett amount":
    "Betrag für Übertragung angeben und auf Berechnung des Bruttobetrags warten",
  "Enter the package change fee and wait for nett amount":
    "Betrag für Paketwechsel angeben und auf Berechnung des Bruttobetrags warten",
  Canceled: "Storniert",
  "Payment delayed for": "Zahlung verspätet um",
  payment: "Gebühr",
  "ISTQB retake": "Wiederholung ISTQB",
  "Resignation fee": "Gebühr für Abmeldung",
  "Statutory interest": "Gesetzliche Zinsen",
  Insurance: "Versicherung",
  "Lost card": "Verlorene Karte",
  "Additional project session":
    "Zusätzliche Arbeitstermine für das Abschlussprojekt",
  "Block retake": "Wiederholung blockieren",
  "You have to choose positions to be invoiced":
    "Die in Rechnung zu stellenden Positionen müssen ausgewählt werden",
  "You have to choose a date": "Das Datum muss ausgewählt werden",
  "You have to choose payment days":
    "Die Zahlungstage müssen ausgewählt werden",
  "Invoice for a company": "Rechnung für Firma",
  "Invoice for LO": "Rechnung für Arbeitsamt",
  "Payment days": "Zahlungstage",
  InvoiceFrom: "Von",
  InvoiceTo: "Auf",
  "Service completion date": "Datum der Leistungserbringung",
  "Payment days is required": "Die Zahlungstage sind ein Pflichtfeld",
  "Service completion date is required":
    "Das Datum der Leistungserbringung ist ein Pflichtfeld",
  ContractsSent: "Abgesandt",
  New: "Neu",
  Transfered: "Übertragen",
  "Student's history": "Übersicht Kursteilnehmer",
  Previous: "Vorherige",
  Next: "Nächste",
  "We have a temporary technical break":
    "Wir sind in einer Wartungspause und vorübergehend nicht erreichbar",
  "Sit back and watch the movie.":
    "Setzen Sie sich bequem hin und schauen Sie sich einen Film an.",
  "It's possible we'll be back when you're done watching.":
    "Womöglich sind wir wieder zurück, wenn der Film zu Ende ist.",
  "Income side": "Checkout-Seite",
  "User's address is not filled, please fill data":
    "Für den Benutzer wurde keine Adresse angegeben, ergänzen Sie bitte die Daten",
  "Personal identity number is required": "PESEL-Nummer angeben",
  "Invalid personal identity number": "Falsche PESEL-Nummer",
  "Personal identity number": "PESEL",
  "Incomes report": "Einzahlungen",
  "Show unallocated": "Nur nicht zugeordnete anzeigen",
  "Show deleted": "Nur gelöschte anzeigen",
  "Transaction number": "Nummer des Geschäftsvorgangs",
  "Transaction number or description":
    "Nummer des Geschäftsvorgangs oder Beschreibung",
  "Transaction amount": "Betrag des Geschäftsvorgangs",
  Subaccount: "Unterkonto",
  "Make transfer": "Umbuchen",
  "Transaction transfer": "Umbuchung der Einzahlung",
  "Transfer's reason": "Grund der Umbuchung",
  "Destination subaccount": "Ziel-Unterkonto",
  "Correction's number": "Korrekturrechnungen",
  "Enter reason of the transfer, not less than 10 characters":
    "Der Grund der Umbuchung muss mindestens 10 Zeichen lang sein",
  "Destination subaccount must be different from the source":
    "Das Ziel-Unterkonto muss anders als das Ursprungs-Unterkonto sein",
  "Source subaccount": "Ursprungs-Unterkonto",
  "Destination subaccount is required":
    "Das Ziel-Unterkonto muss angegeben werden",
  "Transfer's reason is required":
    "Der Grund der Umbuchung ist ein Pflichtfeld",
  "Correcton's number is required":
    "Die Nummer der Korrektur ist ein Pflichtfeld",
  "Incomes' transfers report": "Umbuchungen der Einzahlungen",
  "Change info": "Information zur Änderung",
  "Product status": "Status des Produktes",
  "Settlement of receivables": "Abrechnung der Forderungen",
  Refunds: "Rückzahlungen",
  Reason: "Verwendungszweck",
  "Refunded by": "Wer",
  "Refund status": "Status der Rückzahlung",
  Correction: "Nummer der Korrekturrechnung",
  Invoiced: "Mit Rechnung",
  "Not invoiced": "Ohne Rechnung",
  "Correct the data on the form": "Daten im Formular berichtigen",
  Description: "Beschreibung",
  "Created by": "Erstellt von",
  "Refunded at": "Zurückgezahlt",
  "Created at": "Erstellt",
  Balance: "Saldo",
  "Overdues' sum": "Fällige Forderungen insgesamt",
  "Paid dues' sum": "Beglichene Forderungen insgesamt",
  "Incomes' sum": "Einzahlungen insgesamt",
  "Refunds' sum": "Rückzahlungen insgesamt",
  Dues: "Forderungen",
  Incomes: "Einzahlungen",
  "Deadline, ascending": "Die ältesten",
  "Deadline, descending": "Die neuesten",
  "Contract payments report": "Forderungen",
  "Student balance": "Saldo Kursteilnehmer",
  "Third party balance": "Saldo Drittpartei",
  "Add student's income": "Einzahlung des Kursteilnehmers hinzufügen",
  "Add third parties income": "Einzahlung der Drittpartei hinzufügen",
  "Nett amount": "Nettobetrag",
  "Gross amount": "Bruttobetrag",
  "Show expired only": "Nur überfällige zeigen",
  "Add refund": "Rückzahlung hinzufügen",
  "Refund title": "Verwendungszweck",
  "Refund side": "Rückzahlungs-Seite",
  "Refund amount": "Der zurückzuzahlende Betrag",
  "Amount is required": "Der Betrag ist ein Pflichtfeld",
  "Enter description of the refund, not less than 10 characters":
    "Verwendungszweck der Rückzahlung eingeben, mindestens 10 Zeichen",
  "Enter amount of the refund": "Den zurückzuzahlenden Betrag eingeben",
  "Select side of the refund": "Rückzahlungs-Seite auswählen",
  "Calculate rebate": "Rabatt neu berechnen",
  "Change rebate to": "Rabatt ersetzen durch",
  "Balances report": "Salden",
  "Entering manual income": "Eingabe der manuellen Einzahlung",
  Settlements: "Abrechnungen",
  "Invoice edition": "Rechnung bearbeiten",
  "Save invoice": "Rechnung speichern",
  "Description is required": "Die Beschreibung ist ein Pflichtfeld",
  "Amount gross is required": "Der Bruttobetrag ist ein Pflichtfeld",
  "Issue date is required": "Das Ausstellungsdatum ist ein Pflichtfeld",
  "Issue month": "Ausstellungsdatum",
  "Payment date is required": "Zahlungsfrist eingeben",
  "Sell date is required": "Verkaufsdatum eingeben",
  "Payment date must be after issue date":
    "Das Fälligkeitsdatum muss nach dem Ausstellungsdatum liegen",
  "Sell date must be after start of previous month":
    "Das Verkaufsdatum muss nach dem Anfang des Vormonats liegen",
  "Add an invoice": "Rechnung hinzufügen",
  Overdue: "Überfällige",
  Contracts: "Kontrakte",
  Invoices: "Rechnungen",
  Balance_: "Saldo",
  "Show only not refunded": "Nur nicht erstattete anzeigen",
  Date: "Datum",
  "Auto-invoices": "Automatische Rechnungsstellung",
  "After edit rebate the payments are calculate again!":
    "Nach Bearbeitung des Rabatts werden die Forderungen erneut berechnet!",
  "Are you sure you want to remove an income?":
    "Möchten Sie diese Einzahlung wirklich löschen?",
  "The second date can not be earlier than the first!":
    "Das zweite Datum kann nicht vor dem ersten Datum liegen!",
  "Correction number is required": "Die Korrekturrechnung ist erforderlich",
  "Payment schedule reset": "Zahlungsplan zurücksetzen",
  "The invoice amount is higher then dues' sum":
    "Der Rechnungsbetrag überschreitet die Summe der Forderungen",
  "Balance, ascending": "Nach Saldo sortieren, aufsteigend",
  "Balance, descending": "Nach Saldo sortieren, absteigend",
  "Payment method": "Zahlungsart",
  Cash: "Bargeld",
  "Online payment": "Online-Zahlung",
  "Bank transfer": "Banküberweisung",
  "Select payment method": "Zahlungsart auswählen",
  "Tax rate": "MwSt.-Satz",
  "You must select tax rate": "Sie müssen den MwSt.-Satz auswählen",
  "You must select receiver": "Sie müssen den Empfänger auswählen",
  "The e-mail address of the invoice sending has not been added":
    "Der Empfänger hat keine E-Mail-Adresse für den Rechnungsversand",
  "Your data was saved": "Ihre Daten wurden gespeichert",
  "Something goes wrong": "Etwas ist schief gegangen",
  "Active payment": "Aktive Forderung",
  "Assign payer": "Zahler zuordnen",
  "New payer created": "Ein neuer Zahler generiert",
  "New payer was created. Do you want assign him to this contract?":
    "Ein neuer Zahler wurde generiert. Möchten Sie ihn diesem Vertrag zuordnen?",
  self: "Selbständig",
  "own funds": "Selbständig",
  "Funding type": "Finanzierungstyp",
  "Choose funding type": "Typ der Finanzierung auswählen",
  "You must choose payer": "Sie müssen den Zahler auswählen",
  "Parts of payments must be equal 100% in sum":
    "Die Summe der Finanzierungsanteile muss 100 Prozent betragen",
  "You must choose funding type":
    "Sie müssen den Typ der Finanzierung auswählen",
  "Income type": "Art der Einzahlung",
  manual: "Manuell",
  bank: "Per Bank",
  "Correction's number is required": "Die Korrekturrechnung ist erforderlich",
  "dates, cities, lecturers, students, schedules":
    "Termine, Städte, Kurslehrer, Kursteilnehmer, Zeitpläne",
  "students, employees, co-workers, their data and competences":
    "Kursteilnehmer, Angestellte, Mitarbeiter, ihre Daten und Kompetenzen",
  "substantive scope, modules, number of lectures, prices and installments":
    "Sachlicher Umfang, Module, Anzahl von Vorlesungsstunden, Preise und Raten",
  "charts, timeline, graduates, surveys, financial data":
    "Diagramme, Zeitachse, Absolventen, Umfragen, Finanzdaten",
  "The contract is finished": "Der Vertrag ist beendet.",
  "Only payments have been modified": "Nur Zahlungen wurden modifiziert",
  "All mentors": "Alle Mentoren",
  "All curse specialists": "Alle Betreuer",
  "No entries found": "Keine Eintragungen in der Übersicht gefunden",
  All: "Alle",
  "No available lecturers within this period":
    "Im vorgegebenen Zeitraum keine Kurslehrer verfügbar",
  "N/A": "n/a",
  Corrections: "Korrekturen",
  "Edit corrections": "Korrekturen bearbeiten",
  "Add new correction": "Neue Korrektur hinzufügen",
  Remove: "Löschen",
  "Select payer": "Zahler auswählen",
  "Part of payments": "Teil der Zahlung",
  "Bank account IBAN": "Die Kontonummer IBAN",
  "Bank account SWIFT": "Die Kontonummer SWIFT",
  "From which module does not attend the course (does not pay)":
    "Ab welchem Modul bleibt er/sie dem Kurs fern (zahlt nicht)",
  "Cancellation during the last module":
    "Abmeldung im Laufe des letzten Moduls",
  "For this type of contract you have to assign 2 payers at least":
    "Diese Art der Kontrakte erfordert mindestens zwei zugeordnete Zahler",
  "Payer can be choose only once":
    "Der Zahler kann nur einmal ausgewählt werden",
  "You can have assign only one payer": "Nur ein Zahler kann zugeordnet werden",
  "Income already invoiced": "Rechnungsstellung außerhalb HUB",
  "Transfer invoices": "Rechnung mit ausgewiesener MwSt. übertragen",
  "Income invoiced": "Rechnung mit ausgewiesener MwSt. außerhalb HUB",
  "No records found": "Keine Datensätze gefunden",
  "Select the receiver": "Empfänger auswählen",
  Receiver: "Empfänger",
  "Type of contract change": "Typ des Kontraktes ändern",
  "Before changing the type of contract, remove payers other than the student":
    "Vor Änderung des Typs des Kontrakts alle Zahler, die keine Kursteilnehmer sind, löschen",
  "Payment type": "Zahlungsart",
  "Prework share date": "Das Prework freigegeben am",
  "Refund bank account": "Bankkonto für Rückzahlungen",
  "Part of the payment can not be greater than 100%":
    "Eine Teilzahlung kann nicht größer als 100 Prozent sein",
  "Part of the payment can not be less than 0%":
    "Eine Teilzahlung kann nicht kleiner als 0 Prozent sein",
  "Choose VAT rate": "MwSt.-Satz auswählen",
  "You must choose tax rate": "Sie müssen den MwSt.-Satz auswählen",
  "It is the sum of paid and overdue payments with a deadline to the present day":
    "Das ist die Summe der bereits bezahlten und überfälligen Forderungen mit dem Fälligkeitsdatum bis zum heutigen Tag",
  "You must fill correction's number":
    "Sie müssen die Nummer der Korrektur angeben",
  Owner: "Zahler",
  "Are you sure you want to delete the status of the Income already invoiced and issue an invoice for the payer":
    "Möchten Sie den Status Rechnungsstellung außerhalb HUB wirklich löschen und eine Rechnung für den Zahler ausstellen",
  "Add company": "Firma hinzufügen",
  Companies: "Firmen",
  "Enter the company ID": "REGON-Nummer angeben",
  "Bank account for refunds": "Bankkontonummer für Rückzahlungen",
  "Payments type": "Zahlungsart",
  "Enter payments type": "Zahlungsart angeben",
  "Choose payer": "Zahler auswählen",
  "Enter amount gross": "Bruttobetrag angeben",
  "Select the product": "Produkt auswählen",
  "Amount of payments": "Der zu zahlende Betrag",
  "Part of the payment can not be greater than modules total sum":
    "Die Teilzahlung kann nicht größer als die Summer der Module sein",
  "Part of the payment can not be less than 0":
    "Die Teilzahlung kann nicht kleiner als 0 sein",
  "Sum of parts of the payment must be equal to modules total sum":
    "Die Summe der Teilzahlungen muss der Summe der Module ({{totalSum}}) gleich sein. Es fehlt: {{currentSum}}",
  "Transfer reason": "Grund der Übertragung",
  "Choose the transfer reason": "Grund der Übertragung auswählen",
  "Sum of third parties' part must be equal":
    "Die Summe der Teilzahlungen seitens der Drittpartei muss {{maxPayer}} gleich sein",
  "Change product version": "Wechsel der Produktversion",
  "Are you sure you want to recalculate the payments on all contracts by new product version.":
    "Möchten Sie für alle Kontrakte die Forderungen gemäß der neuen Produktversion wirklich erneut berechnen?",
  "Max. lecturer's price": "Höchstentgelt Kurslehrer",
  "Module's type": "Typ des Moduls",
  "Day of the course": "Kurstag",
  "Lecture's type": "Art der Veranstaltungen",
  "Duration per student": "Prüfungszeit pro Kursteilnehmer",
  Participants: "Teilnehmer",
  "Choose participants": "Teilnehmer auswählen",
  "You must choose at least one package":
    "Sie müssen mindestens ein Paket auswählen",
  "You must fill max. lecturer price":
    "Sie müssen das Höchstentgelt des Kurslehrers ergänzen",
  "Max. lecturer price must be greater than zero":
    "Das Höchstentgelt eines Kurslehrers muss größer als Null sein",
  "Modules' surveys": "Modulumfragen",
  Client: "Leistungserwerber",
  "Select the client": "Leistungserwerber auswählen",
  "No income required": "Keine Zahlung erforderlich",
  "Invoice number or client name": "Rechnungsnummer bzw. Name des Kunden",
  "Lectures types": "Art der Veranstaltungen",
  "Add lecture type": "Neue Art der Veranstaltungen hinzufügen",
  Multiplier: "Multiplikator",
  "Lecture type": "Art der Veranstaltungen",
  "Contacts list": "Kontaktliste",
  "Add new contact": "Neuen Kontakt hinzufügen",
  "Edit contact": "Kontakt bearbeiten",
  "Remove contact": "Kontakt löschen",
  "Enter a valid contact name":
    "Den korrekten Namen der Kontaktperson eingeben",
  "Enter the course's day": "Kurstag angeben",
  "Enter the week day": "Wochentag angeben",
  "Choose type of lecture": "Art der Veranstaltungen auswählen",
  "Set the start time": "Beginnuhrzeit einstellen",
  "Set the participants": "Teilnehmer einstellen",
  "Date and time": "Tag und Uhrzeit",
  "Lecture duration": "Dauer",
  "Move next lectures": "Weitere Vorlesungen verschieben",
  "Fill module with this lecture and room":
    "Zuordnen Sie dem Modul diesen Kurslehrer und diesen Raum",
  Publish: "Veröffentlichen",
  "WARNING!": "HINWEIS!",
  "Are You sure You want to change this field?":
    "Möchten Sie dieses Feld wirklich ändern?",
  "Start time": "Beginn",
  "Set the date time": "Startdatum einstellen",
  "List of contracts": "Kontaktliste",
  "Contract kind": "Kontaktart",
  "Date of addition, ascending": "Datum der Hinzufügung, aufsteigend",
  "Date of addition, descending": "Datum der Hinzufügung, absteigend",
  "Enter withdrawal description": "Beschreibung des Rücktritts eingeben",
  "Connected contracts": "Verbundene Kontrakte",
  "assign contract": "Kontrakt zuordnen",
  "Diploma number": "Diplomnummer",
  "Finish status": "Status des Abschlusses",
  "Will you take the related course fees?":
    "Forderungen aus einem verbundenen Kurs herunterladen?",
  "Choose course": "Kurs auswählen",
  Assignment: "Besetzung",
  "Product master": "Führendes Produkt",
  "Has not free slots": "Er/sie hat keine freien Zeitfenster",
  "Has free slots": "Er/sie hat freie Zeitfenster",
  "Searching...": "Suchvorgang läuft...",
  "Show more": "Mehr zeigen",
  "Products not found": "Keine Produkte gefunden",
  "Type to search...": "Suchbegriff eingeben...",
  "Course days and groups report": "Kurstage und Gruppengröße",
  "Name and Surname": "Vor- und Nachname",
  "XLS reports": "XLS-Berichte",
  "Cash flow report": "Cashflow-Bericht",
  "n.d.": "k. A.",
  "Course specialists not found": "Kursbetreuer nicht gefunden",
  "Show more course specialists": "Mehr Kursbetreuer anzeigen",
  "Show more products": "Mehr Produkte anzeigen",
  "Save product": "Produkt speichern",
  "Save module": "Modul speichern",
  "Save lectures": "Vorlesungen speichern",
  "Save additional polls": "Zusätzliche Umfragen speichern",
  "Save polls": "Umfragen speichern",
  "This product has active version already":
    "Dieses Produkt hat bereits eine aktive Version",
  "If you want to active this version of product You must deactivate current active version.":
    "Wenn Sie diese Version des Produktes aktivieren möchten, müssen Sie zuerst seine aktuelle Version deaktivieren.",
  "Stationary mode": "Präsenzkurse",
  "Part time mode": "Wochenendkurse",
  "Online mode": "Online-Kurse",
  "Free mode": "Beliebiges Kursformat",
  "Payments parts reset": "Zahlungsaufteilung zurücksetzen",
  "You are in draft mode": "Du bist im Entwurfsmodus",
  "Select the signature": "Signatur auswählen",
  "Signature not found": "Signatur nicht gefunden",
  "Cancel draft": "Entwurf abbrechen",
  "Show deleted only": "Nur gelöschte zeigen",
  "Remove an invoice": "Rechnung löschen",
  deleted: "Gelöscht",
  "Course version": "Kursversion",
  "Course 2.0": "Kurs 2.0",
  "All students": "Alle/-r Kursteilnehmer",
  "Generate PayU link": "Einen PayU-Link erstellen",
  "PayU Order": "Bestellung PayU",
  "Copy to clipboard": "In die Zwischenablage kopieren",
  "PayU Orders report": "Einkaufsbericht PayU",
  "Settle the module": "Modul abrechnen",
  "Update buyer address": "Angaben zur Anschrift des Erwerbers aktualisieren",
  "Are You sure You want to update buyer address?":
    "Möchten Sie die Anschrift des Erwerbers wirklich aktualisieren?",
  "The payment schedule contains errors": "Der Zahlungsplan weist Fehler auf",
  "Additional events": "Zusätzliche Ereignisse",
  "Add new event": "Neues Ereignis hinzufügen",
  "Enter event's name": "Bezeichnung der Umfrage eingeben",
  "Enter event's start time": "Startuhrzeit eingeben",
  "Choose type of event": "Art des Ereignisses auswählen",
  "Enter event's duration": "Dauer des Ereignisses eingeben",
  "Choose mode": "Kursformat auswählen",
  "Choose room": "Schulungsräume auswählen",
  "Choose lecturer": "Kurslehrer auswählen",
  "Enter event's date": "Datum des Ereignisses eingeben",
  "Edit event": "Ereignis bearbeiten",
  "Room required": "Er/sie benötigt einen Saal",
  "Course days": "Kurstage",
  "Add new note": "Neue Bemerkung hinzufügen",
  ssp: "Kursteilnehmer Einmalzahlung",
  si: "Kursteilnehmer Raten",
  csp: "Firma Einmalzahlung",
  ci: "Firma Raten",
  Office: "Sitz",
  cs: "Zeitplan des Kurses",
  "Contract owner": "Auftraggeber",
  "Add new mapping": "Neue Zuordnung hinzufügen",
  "PipeDrive Mapping": "Zuordnung PipeDrive",
  "Remove lead map": "Zuordnung des Leads löschen",
  "Are You sure You want to remove lead map?":
    "Möchten Sie die Zuordnung des Leads wirklich löschen?",
  "Resigned contracts": "Kontrakte - Abmeldungen",
  "Amendment contract": "Kontrakte - Nachträge",
  "Date range by issue date": "Datumsbereich nach dem Ausstellungsdatum",
  "Are you sure you want to remove this module?":
    "Möchten Sie dieses Modul wirklich löschen?",
  "By removing this module, you will not be able to restore it":
    "Die Wiederherstellung des gelöschten Moduls wird nicht möglich",
  "Bought in shop": "Einkauf im Store",
  Notes: "Anmerkungen",
  "Add note": "Füg ein Notiz hinzu",
  "Show all notes": "Alle Notizen anzeigen",
  "Special offers": "Sonderangebote",
  "Add special offer": "Sonderangebot hinzufügen",
  "Start At": "Startdatum",
  "End At": "Endtermin",
  "Special offer": "Sonderangebot",
  "Contracted value": "Vertraglich vereinbarter Wert",
  "No diplomas to download": "Keine Diplome zum Herunterladen",
  "Download diplomas": "Diplome herunterladen",
  "Legal basis": "Rechtliche Grundlage",
  "You must select legal basis": "Sie müssen die Rechtsgrundlage auswählen",
  "Send status": "Status schicken",
  // TODO: add de translation
  not_sent: "Not sent",
  ready_to_send: "Ready to send",
  sent: "Sent",
  in_review: "In review",
  in_send: "In send",
  in_send_retry: "Send failed, rescheduled",
  sent_error: "Sent error",
  not_handled: "Sending outside HUB",
  "Changed by": "Angepasst von",
  "Invoice status log": "Rechnungsstatusprotokoll",
  Machine: "Maschine",
  "Data for sending invoices": "Daten für den Rechnungsversand",
  "Edit payer": "Zahler bearbeiten",
  "The e-mail address of the invoice receiver has not been added":
    "Die E-Mail-Adresse des Rechnungsempfängers wurde nicht hinzugefügt",
  "Are you sure you want to send the invoices?":
    "Sind Sie sicher, dass Sie die Rechnungen senden möchten?",
  invoice: "rechnung",
  invoices: "rechnungen",
  "The invoice cannot be edited because it has already been sent":
    "Die Rechnung kann nicht bearbeitet werden, da sie bereits gesendet wurde",
  "Send by": "Gesendet von",
  "Receiver emails": "Empfänger-E-Mails",
};
