import React from "react";
import { XCircle } from "react-feather";
import PropTypes from "prop-types";

const SideModal = ({ position, shouldRender, toggleSideModal, children }) => {
  const style = {
    [position || "right"]: "0",
    transform:
      position === "left" && !shouldRender
        ? "translate(-150%)"
        : shouldRender
        ? "translate(0)"
        : "translate(150%)",
  };
  return (
    <div style={style} className={`side-modal`}>
      <div className="side-modal__cancel" onClick={toggleSideModal}>
        <XCircle size={35} />
      </div>
      {children}
    </div>
  );
};

SideModal.propTypes = {
  position: PropTypes.string,
  shouldRender: PropTypes.bool,
  toggleSideModal: PropTypes.func,
  children: PropTypes.object,
};

export default SideModal;
