import Notifications from "react-notification-system-redux";
import React from "react";
import PropTypes from "prop-types";

const Notification = ({ notifications }) => {
  const notificationStyle = {
    NotificationItem: {
      DefaultStyle: { margin: "10px 5px 2px 1px" },
      error: { color: "red" },
      success: { color: "green" },
    },
  };

  return (
    <Notifications notifications={notifications} style={notificationStyle} />
  );
};

Notification.propTypes = { notifications: PropTypes.array };

export default Notification;
