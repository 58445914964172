import React, { Component } from "react";
import { RenderError, renderFieldsHelper } from "./forms";
import _ from "lodash";
import PropTypes from "prop-types";
import renderAmountWithCurrency from "../Helpers/RenderAmountWithCurrency";
import InputNumber from "rc-input-number";

class PriceFieldWithCalculation extends Component {
  static propTypes = {
    calculatedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isNetValue: PropTypes.bool,
    priceCalculator: PropTypes.func,
    currency: PropTypes.string,
    input: PropTypes.object,
    change: PropTypes.func,
    t: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    calculatedName: PropTypes.string,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    customClass: PropTypes.string,
    precision: PropTypes.number,
    vatRate: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      calculatedValue: props.calculatedValue ? props.calculatedValue : 0,
    };
    if (!props.calculatedValue) {
      this.calculateValue();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value) {
      this.calculateValue();
    }
    if (this.props.calculatedValue !== prevProps.calculatedValue) {
      this.setState({
        calculatedValue: this.props.calculatedValue,
      });
    }

    if (this.props.vatRate !== prevProps.vatRate) {
      this.setCalculatedValueFromApi();
    }
  }

  setCalculatedValueFromApi = () => {
    const {
      isNetValue,
      priceCalculator,
      currency,
      input,
      change,
      calculatedName,
      vatRate,
    } = this.props;
    const amount = _.round(Number(input.value) * 100);

    if (currency && Number.isInteger(amount)) {
      priceCalculator(
        {
          amount: {
            amount,
            currency,
          },
          vatRate,
        },
        response => {
          this.setState(
            {
              calculatedValue: isNetValue
                ? response.data.data.gross.amount
                : response.data.data.nett.amount,
            },
            () => {
              if (calculatedName && typeof change === "function") {
                change(calculatedName, this.state.calculatedValue);
              }
            },
          );
        },
      );
    }
  };

  calculateValue = _.debounce(this.setCalculatedValueFromApi, 500);

  renderCalculatedValue = () => {
    const { calculatedValue } = this.state;
    const { isNetValue, label, currency, t, customClass } = this.props;
    return (
      <div
        className={`${
          label
            ? customClass
              ? customClass
              : "col-sm-4 col-md-12"
            : "col-sm-12"
        } form-group__currency`}
      >
        (
        {calculatedValue && currency
          ? renderAmountWithCurrency({
              amount: calculatedValue,
              currency,
            })
          : 0}{" "}
        {isNetValue ? t("gross") : t("net")})
      </div>
    );
  };

  render() {
    const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
      this.props,
    );
    const { label, input, t, under, disabled, precision = 2 } = this.props;
    return (
      <div className={`form-group row ${classes}`}>
        {label && (
          <div className={`${under ? "col-sm-12" : "col-sm-4 col-md-3"}`}>
            <label htmlFor="">{label}</label>
          </div>
        )}
        <div className={label && !under ? "col-sm-6 col-md-4" : "col-sm-12"}>
          <InputNumber
            {...input}
            className={`numberFieldWithInsert`}
            defaultValue={0}
            precision={precision}
            value={input.value || 0}
            disabled={shouldDisabled || disabled}
            parser={displayValue => displayValue.replace(",", ".")}
            onMouseUp={this.calculateValue}
            onKeyUp={this.calculateValue}
          />
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
        {this.renderCalculatedValue()}
      </div>
    );
  }
}

export default PriceFieldWithCalculation;
