import React from "react";
import ReportForm from "../Components/ReportForm";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDictionary } from "../../Dictionaries/Actions";
import validate from "../Validators/ReportValidator.js";
import { fetchReport } from "../Actions";
import { fetchAllProducts } from "../../Products/Actions";
import { fetchCourses } from "../../Courses/Actions/CoursesActions";
import { fetchAllProductsFullInfo } from "../../Products/Actions";

const FORM_NAME = "REPORTS";
const selector = formValueSelector(FORM_NAME);

const Reports = props => <ReportForm {...props} />;

function mapStateToProps(state) {
  const {
    reportData,
    dictionaries,
    productsList,
    coursesList,
    allProductsFullInfo,
  } = state;
  const values = selector(
    state,
    "startDate",
    "endDate",
    "productId",
    "cityId",
    "courseModeId",
    "courseId",
  );
  return {
    reportData,
    values,
    dictionaries,
    productsList,
    coursesList,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchReport,
      fetchDictionary,
      fetchAllProducts,
      fetchCourses,
      fetchAllProductsFullInfo,
    },
    dispatch,
  );
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: { reportData: [] },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Reports),
);
