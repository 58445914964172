import React from "react";
import {
  DropdownList,
  DateTimePicker,
  NumberPicker,
  Multiselect,
} from "react-widgets";
import { Field } from "redux-form";
import { PlusCircle, MinusCircle, XCircle } from "react-feather";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { SwatchesPicker } from "react-color";
import dateHelper from "../Helpers/DateHelper";
import renderAmountWithCurrency from "../Helpers/RenderAmountWithCurrency";
import FIELD_STATUSES from "../Enums/FieldStatuses";
import TIME from "../Enums/Time";

export const renderFieldsHelper = field => {
  const { touched, submitting } = field.meta;
  let { error } = field.meta;
  const classes = `${touched && error ? "has-error" : ""} ${
    field.required ? "required" : ""
  } ${submitting ? "disabled" : ""}`;
  const displayError = touched && !!error;
  const shouldDisabled =
    field.shouldDisabled ||
    ((submitting ||
      field.status === FIELD_STATUSES.ACTIVE ||
      field.status === FIELD_STATUSES.INACTIVE) &&
      !field.alwaysEditable);
  return {
    touched,
    error,
    classes,
    displayError,
    shouldDisabled,
  };
};

export const RenderError = ({ t, error }) => {
  const interpolation = typeof error === "object";
  return interpolation ? (
    <span className="help-block">{t(error.key, error.values)}</span>
  ) : (
    <span className="help-block">{t(error)}</span>
  );
};

RenderError.propTypes = {
  t: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const RenderTextToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    under,
    label,
    t,
    nameToShow = null,
    placeholder,
    fieldClasses,
    onMouseOutHandler,
  } = field;
  return (
    <div
      className={`form-group row ${classes} ${
        fieldClasses ? fieldClasses : ""
      }`}
    >
      {label && (
        <div className={under ? "col-xs-12" : "col-sm-4 col-md-3"}>
          <label htmlFor="">{label}</label>
        </div>
      )}

      <div className={under || !label ? "col-xs-12" : "col-sm-8 col-md-9"}>
        <input
          className="form-control"
          disabled={shouldDisabled}
          type="text"
          {...field.input}
          value={
            nameToShow && typeof field.input.value === "number"
              ? nameToShow
              : field.input.value
          }
          placeholder={placeholder ? placeholder : ""}
          onMouseOut={onMouseOutHandler || null}
        />
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};

const RenderNumberToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    under,
    label,
    max,
    step = 1,
    customClasses,
    t,
    minimum = 0,
    onKeyPress,
    defaultValue = 0,
    parse,
  } = field;
  return (
    <div
      className={`form-group  ${classes} ${
        under
          ? under.containerClassName
          : customClasses
          ? customClasses.containerClassName
          : "row"
      }`}
    >
      <div className={under || customClasses ? "row" : ""}>
        <div
          className={`${
            under
              ? under.fieldClassName
              : customClasses
              ? customClasses.labelClassName
              : "col-sm-4 col-md-3"
          } ${!label || label.length === 0 ? "hidden" : ""}`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div
          className={
            under
              ? under.fieldClassName
              : customClasses
              ? customClasses.fieldClassName
              : "col-sm-8 col-md-9"
          }
        >
          <NumberPicker
            min={minimum}
            defaultValue={defaultValue}
            {...field.input}
            max={max}
            disabled={shouldDisabled || field.shouldDisabled}
            step={parseInt(step)}
            format={"#.##"}
            value={field.input.value !== "" ? Number(field.input.value) : null}
            onKeyPress={onKeyPress}
            parse={parse}
          />
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

const PriceToTranslate = ({ t, currency, priceNet, priceGross, isNet }) => (
  <div className={`form-group row packagePrice`}>
    <div className="col-xs-12">
      <div>
        {currency
          ? renderAmountWithCurrency({
              amount: isNet ? Math.round(priceNet) : Math.round(priceGross),
              currency,
            })
          : 0}
        <small>
          (
          {currency
            ? renderAmountWithCurrency({
                amount: isNet ? Math.round(priceGross) : Math.round(priceNet),
                currency,
              })
            : 0}{" "}
          {currency} {isNet ? t("gross") : t("net")})
        </small>
      </div>
    </div>
  </div>
);

PriceToTranslate.propTypes = {
  t: PropTypes.func,
  priceNet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceGross: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  isNet: PropTypes.bool,
};

export const RenderColorPicker = ({ colorPickerConfig }) => {
  return (
    <SwatchesPicker
      width="10vw"
      height="150px"
      id="colorPicker"
      {...colorPickerConfig}
    />
  );
};

RenderColorPicker.propTypes = {
  colorPickerConfig: PropTypes.object,
};

const RenderPriceFormToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const { onKeyUp, t } = field;
  return (
    <div className={`form-group row ${classes}`}>
      {field.label && (
        <div className="col-sm-4 col-md-3">
          <label htmlFor="">{field.label}</label>
        </div>
      )}

      <div className={field.label ? "col-sm-4 col-md-4" : "col-xs-12"}>
        <input
          className="form-control"
          {...field.input}
          onKeyUp={onKeyUp ? onKeyUp : field.input.onKeyUp}
          disabled={shouldDisabled}
          type="number"
          value={field.input.value || 0}
        />
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
      {!field.netValue ? (
        <div
          className={`${
            field.label ? "col-sm-4 col-md-5" : "col-sm-12"
          } form-group__currency`}
        >
          ({field.grossValue} {field.currency && field.currency} {t("gross")})
        </div>
      ) : (
        <div
          className={`${
            field.label ? "col-sm-4 col-md-5" : "col-sm-12"
          } form-group__currency`}
        >
          ({field.netValue} {field.currency && field.currency} {t("net")})
        </div>
      )}
    </div>
  );
};

const RenderMultiselectToTranslate = field => {
  const { classes, displayError, error, shouldDisabled } = renderFieldsHelper(
    field,
  );
  const {
    under,
    className,
    label,
    dropdownConfig: { data, valueField, textField, placeholder },
    t,
    onClearCallback,
    clearButtonStyle,
    required,
    customBlurHandler,
  } = field;
  return (
    <div
      className={`form-group ${className} ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div className={under ? "row" : ""}>
        <div
          className={`${required ? "required" : ""} ${
            under ? under.fieldClassName : "col-sm-4 col-md-3"
          } ${!label || label.length === 0 ? "hidden" : ""}`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <Multiselect
              {...field.input}
              disabled={shouldDisabled}
              data={data}
              value={field.input.value || []}
              filter="contains"
              placeholder={placeholder ? placeholder : t("Select")}
              valueField={valueField}
              textField={textField}
              onBlur={() => {
                field.input.onBlur();
                if (customBlurHandler) {
                  customBlurHandler(event);
                }
              }}
            />
            {onClearCallback && !shouldDisabled && (
              <div
                onClick={onClearCallback}
                className="form__clear-button"
                style={clearButtonStyle || {}}
              >
                <XCircle size={15} />
              </div>
            )}
          </div>
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

const RenderSelectToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    under,
    className,
    label,
    dropdownConfig: { placeholder, onChange: customChange },
    onClearCallback,
    t,
    nameToShow,
    disabled,
    clearButtonStyle,
    id,
  } = field;
  return (
    <div
      id={id}
      className={`form-group ${className} ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div
        className={
          under ? (under.wrapperClassName ? under.wrapperClassName : "row") : ""
        }
      >
        {label && (
          <div
            className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
              !label || label.length === 0 ? "hidden" : ""
            }`}
          >
            <label htmlFor="">{label}</label>
          </div>
        )}
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <DropdownList
              {...field.dropdownConfig}
              {...field.input}
              disabled={disabled || field.shouldDisabled || shouldDisabled}
              placeholder={placeholder ? placeholder : t("Select")}
              onChange={
                customChange
                  ? (item, meta) => customChange(item, meta, field.input.name)
                  : item =>
                      field.input.onChange(
                        item[field.dropdownConfig.valueField],
                      )
              }
              value={
                nameToShow && typeof field.input.value === "number"
                  ? nameToShow
                  : field.input.value
              }
              onBlur={() => {
                return {};
              }}
            />
            {onClearCallback && !shouldDisabled && (
              <div
                onClick={onClearCallback}
                className="form__clear-button"
                style={clearButtonStyle || {}}
              >
                <XCircle size={15} />
              </div>
            )}
          </div>
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};
const EmployeeItemToTranslate = ({ item }) => (
  <p className="employee-item">
    <span className="employee-item__name">{item.name}</span>{" "}
    <span className="employee-item__email">{item.email}</span>
  </p>
);
EmployeeItemToTranslate.propTypes = { item: PropTypes.object };

const RenderRadioToTranslate = field => {
  const { classes, displayError, shouldDisabled } = renderFieldsHelper(field);
  const {
    options,
    required,
    label,
    under,
    meta: { error, submitFailed },
    t,
  } = field;
  return (
    <div
      className={`form-group row ${
        under ? under.containerClassName : "row"
      } ${classes}`}
    >
      <div className={`col-sm-4 col-md-3 ${required ? "required" : ""}`}>
        <label htmlFor="">{label}</label>
      </div>
      <div className="col-sm-8 col-md-9">
        {options.map((option, index) => {
          const inputId = `${field.input.name}__${option.value}`;

          return (
            <label key={index} className="radio-inline" htmlFor={inputId}>
              <input
                id={inputId}
                type="radio"
                name={field.input.name}
                value={option.value}
                checked={String(option.value) == String(field.input.value)}
                onChange={field.input.onChange}
                disabled={shouldDisabled}
              />
              <span>{option.name}</span>
            </label>
          );
        })}
        {submitFailed && error && displayError ? (
          <RenderError t={t} error={error} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const RenderCheckboxToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const { input, label, labelForCheckbox, under, customLabel, t } = field;
  const inputLabel = label ? label : labelForCheckbox;
  return (
    <div
      className={`form-group ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div
        className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
          !label || label.length === 0 ? "hidden" : ""
        }`}
      >
        <label htmlFor="">{customLabel ? customLabel : label}</label>
      </div>
      <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
        <label className="checkbox-inline" htmlFor={input.name}>
          <input
            {...input}
            id={input.name}
            type="checkbox"
            disabled={shouldDisabled}
            checked={String(input.value) == "true"}
            onChange={value => input.onChange(value)}
          />
          <span>{inputLabel}</span>
        </label>
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};

const RenderCheckboxListToTranslate = field => {
  const {
    fields,
    fields: { name: fieldArrayName },
    options,
    meta: { error, submitFailed },
    required,
    label,
    shouldDisabled,
    t,
  } = field;
  const classes = submitFailed && error ? "has-error" : "";
  const chosenOptions = fields.getAll() ? [...fields.getAll()] : [];
  return (
    <div className={`form-group row ${classes}`}>
      <div className={`col-sm-4 col-md-3 ${required ? "required" : ""}`}>
        <label htmlFor="">{label}</label>
      </div>
      <div className="col-sm-8 col-md-9">
        {options.map(({ name, value }) => {
          const indexInFields = chosenOptions.indexOf(value);
          const fieldName = `${fieldArrayName}--${value}`;
          return (
            <label key={value} htmlFor={fieldName} className="checkbox-inline">
              <Field
                component="input"
                type="checkbox"
                name={fieldName}
                id={fieldName}
                checked={indexInFields > -1}
                onChange={() => {
                  indexInFields > -1
                    ? fields.remove(indexInFields)
                    : fields.push(value);
                }}
                disabled={shouldDisabled}
              />
              <span>{name}</span>
            </label>
          );
        })}
        {submitFailed && error ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};

const RenderTextAreaToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const { under, label, t } = field;
  return (
    <div className={`form-group row ${classes}`}>
      {label ? (
        <div className={under ? "col-xs-12" : "col-sm-4 col-md-3"}>
          <label htmlFor="">{label}</label>
        </div>
      ) : (
        ""
      )}
      <div className={under ? "col-xs-12" : "col-sm-8 col-md-9"}>
        <textarea
          className="form-control"
          disabled={shouldDisabled || field.shouldDisabled}
          onChange={() => {
            field.input.onChange(option.value);
          }}
          {...field.input}
        />
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};
const RenderDateTimeToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    time,
    date,
    input: { value, onChange, onBlur },
    under,
    label,
    onChange: customChange,
    min,
    step,
    className,
    t,
    views,
    dateFormat,
    max,
  } = field;
  return (
    <div
      className={`form-group ${classes} ${className} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div className={under ? "row" : ""}>
        <div
          className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
            !label || label.length === 0 ? "hidden" : ""
          }`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div
          className={
            under
              ? under.fieldClassName
              : className === "custom-cols"
              ? "col-sm-8 col-md-12"
              : "col-sm-8 col-md-9"
          }
        >
          <DateTimePicker
            {...field.input}
            time={time}
            date={date}
            disabled={shouldDisabled || field.disabled}
            format={dateFormat ? dateFormat : TIME.DATE_FORMAT}
            step={step ? step : 30}
            readOnly={field.readOnly}
            value={
              !value
                ? null
                : new Date(
                    dateHelper(Number(value))
                      .getUTC(Number(value))
                      .valueOf(),
                  )
            }
            min={
              min
                ? dateHelper(min)
                    .get()
                    .toDate()
                : dateHelper("1900-01-01")
                    .get()
                    .toDate()
            }
            max={
              max
                ? dateHelper(max)
                    .get()
                    .toDate()
                : dateHelper("9999-01-01")
                    .get()
                    .toDate()
            }
            onChange={
              customChange
                ? (event, value) => customChange(event, value)
                : value => onChange(dateHelper(value).getFormatted())
            }
            onBlur={() => onBlur()}
            views={views ? views : ["month", "year"]}
          />
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

const RenderTextsFieldArrayToTranslate = props => {
  const { fields, fieldLabel, shouldDisabled } = props;
  return fields.map((field, index) => {
    return (
      <li key={index}>
        <div className="col-sm-12">
          <div className="col-xs-10">
            <Field
              label={`${fieldLabel} ${index + 1}`}
              name={field}
              component={RenderText}
              shouldDisabled={shouldDisabled}
            />
          </div>
          <div className={`col-xs-2${shouldDisabled ? " muted" : ""}`}>
            {index === 0 ? (
              <button
                className="btn btn-regular btn-link"
                type="button"
                onClick={() => fields.push("")}
              >
                <PlusCircle size={18} />
              </button>
            ) : (
              <button
                className="btn btn-regular btn-link"
                type="button"
                onClick={() =>
                  fields.remove(fields.getAll().indexOf(field.value))
                }
              >
                <MinusCircle className="text-danger" size={18} />
              </button>
            )}
          </div>
        </div>
      </li>
    );
  });
};
const RenderFullWidthSelectToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    title,
    dropdownConfig: { onChange: customChange },
    t,
    onClearCallback,
    clearButtonStyle,
  } = field;
  return (
    <div className={`form-group ${classes} ${field.class} row`}>
      {!!title ? (
        <div className="col-xs-12">
          <label htmlFor="">{!!title ? title : ""}</label>
        </div>
      ) : (
        ""
      )}
      <div className="col-xs-12 fullWidthSelect">
        <DropdownList
          {...field.dropdownConfig}
          {...field.input}
          disabled={field.shouldDisabled || shouldDisabled}
          placeholder={t("Select")}
          onChange={
            customChange
              ? (item, meta) => customChange(item, meta)
              : item =>
                  field.input.onChange(item[field.dropdownConfig.valueField])
          }
        />
        {onClearCallback && !shouldDisabled && (
          <div
            onClick={onClearCallback}
            className="form__clear-button"
            style={clearButtonStyle || {}}
          >
            <XCircle size={15} />
          </div>
        )}
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};

const SelectToTranslateWithCustomOptions = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const {
    under,
    className,
    label,
    dropdownConfig: { onChange: customChange, placeholder },
    onClearCallback,
    t,
    nameToShow,
    disabled,
  } = field;
  return (
    <div
      className={`form-group ${className} ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div className={under ? "row" : ""}>
        <div
          className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
            !label || label.length === 0 ? "hidden" : ""
          }`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <DropdownList
              {...field.dropdownConfig}
              {...field.input}
              disabled={disabled || field.shouldDisabled || shouldDisabled}
              placeholder={placeholder ? placeholder : t("Select")}
              onChange={
                customChange
                  ? (item, meta) => customChange(item, meta)
                  : item => {
                      if (item.available) {
                        field.input.onChange(
                          item[field.dropdownConfig.valueField],
                        );
                      }
                    }
              }
              itemComponent={item => {
                if (!item.item.available) {
                  return <span className="disabled">{item.item.name}</span>;
                } else {
                  return <span>{item.item.name}</span>;
                }
              }}
              value={
                nameToShow && typeof field.input.value === "number"
                  ? nameToShow
                  : field.input.value
              }
            />
            {onClearCallback && !shouldDisabled && (
              <div onClick={onClearCallback} className="form__clear-button">
                <XCircle size={15} />
              </div>
            )}
          </div>
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

export const RenderText = translate()(RenderTextToTranslate);
export const RenderNumber = translate()(RenderNumberToTranslate);
export const Price = translate()(PriceToTranslate);
export const RenderPriceForm = translate()(RenderPriceFormToTranslate);
export const RenderSelect = translate()(RenderSelectToTranslate);
export const EmployeeItem = translate()(EmployeeItemToTranslate);
export const RenderRadio = translate()(RenderRadioToTranslate);
export const RenderCheckbox = translate()(RenderCheckboxToTranslate);
export const RenderCheckboxList = translate()(RenderCheckboxListToTranslate);
export const RenderTextArea = translate()(RenderTextAreaToTranslate);
export const RenderDateTime = translate()(RenderDateTimeToTranslate);
export const RenderSelectToTranslateWithCustomOptions = translate()(
  SelectToTranslateWithCustomOptions,
);
export const RenderTextsFieldArray = translate()(
  RenderTextsFieldArrayToTranslate,
);
export const RenderFullWidthSelect = translate()(
  RenderFullWidthSelectToTranslate,
);
export const RenderMultiselect = translate()(RenderMultiselectToTranslate);
