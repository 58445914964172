import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Field } from "redux-form";
import { RenderCheckbox, RenderText } from "../../../Forms/forms";

const ContactForm = ({ t, handleSubmit, onAdd, onCancel }) => {
  return (
    <div className={`row fade-in`}>
      <div className={`col-xs-10 col-xs-offset-1`}>
        <Field
          component={RenderText}
          label={t("Name and Surname")}
          name="name"
        />
        <Field component={RenderText} label={t("E-Mail")} name="email" />
        <Field component={RenderText} label={t("Phone")} name="phone" />
        <Field
          component={RenderCheckbox}
          label={t("Contract owner")}
          name="contractOwner"
        />
        <div className={`text-right`}>
          <button
            id="cancel-contact-form-submit"
            className={`btn margin__r15`}
            onClick={onCancel}
          >
            {t("Cancel")}
          </button>

          <button
            id="contact-form-submit"
            className={`btn btn-dark`}
            onClick={handleSubmit(onAdd)}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
};

export default translate()(ContactForm);
