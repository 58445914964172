import React from "react";
import PropTypes from "prop-types";

const Tabs = ({ config, activeTab, onChangeTab }) => {
  return (
    <div className={"react-tabs"}>
      <ul>
        {config.map(({ visible, id, name }) => {
          if (visible) {
            const classNameForTab = `react-tabs__tab ${
              activeTab === id ? "react-tabs__tab--selected" : ""
            }`;

            return (
              <li
                key={id}
                className={classNameForTab}
                onClick={() => onChangeTab(id)}
              >
                {name}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeTab: PropTypes.func,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      visible: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

export default Tabs;
