import React from "react";
import Modal from "react-modal";
import { X } from "react-feather";
import PropTypes from "prop-types";

//TODO move styles to scss
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(100, 100, 100, 0.5)",
  },
  content: {
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    border: "none",
    background: "none",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "0",
  },
};

const CustomModal = props => {
  return (
    <Modal
      style={{
        overlay: {
          ...customStyles.overlay,
          ...(props.customStyles ? props.customStyles.overlay : {}),
        },
        content: {
          ...customStyles.content,
          ...(props.customStyles ? props.customStyles.content : {}),
        },
      }}
      appElement={document.getElementById("main-app")}
      {...props}
    >
      <div className="panel remove-margin panel-default">
        <div className="panel-heading">
          <h2 className="pull-left">{props.title}</h2>
          <X
            className="close"
            onClick={() => props.onRequestClose()}
            size={20}
          />
        </div>
        <div className="panel-body">{props.children}</div>
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  customStyles: PropTypes.object,
};

export default CustomModal;
