import React, { Component } from "react";
import PropTypes from "prop-types";
import CompanyForm from "./CompanyForm";
import GetCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import Balance from "../../Balance/Containers/Balance";
import { getDataForDetailsFromApi } from "../Utils/FetchDictionaryConfigs";
import LoaderProvider from "../../../Common/Components/Loader/LoaderProvider";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import Contacts from "../Components/Contacts";
import ContactToInvoiceSend from "./ContactToInvoiceSend";
import notificationOpts from "../../../Helpers/NotificationOpts";

const BASE_DATA_TAB_INDEX = 0;
const BALANCE_TAB_INDEX = 1;

class CompanyDetails extends Component {
  state = {
    activeTab: BASE_DATA_TAB_INDEX,
  };

  cancelToken = GetCancelToken();

  componentDidMount() {
    getDataForDetailsFromApi(this.props, this.cancelToken);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  refreshContacts = () => {
    const { companyId, fetchContacts, fetchCompany } = this.props;
    fetchContacts(this.cancelToken, companyId);
    fetchCompany(this.cancelToken, companyId);
  };

  renderTabs = () => {
    const { t, isNew } = this.props;
    const tabs = [
      {
        name: t("Basic data"),
        visible: havePermissions([permissions.COMPANY_VIEW]),
      },
      {
        name: t("Settlements"),
        visible: !isNew && havePermissions([permissions.COMPANY_VIEW]),
      },
    ];

    const { activeTab } = this.state;

    return tabs.map((element, tabIndex) => {
      if (element.visible) {
        return (
          <li
            key={tabIndex}
            className={`react-tabs__tab ${
              activeTab === tabIndex ? "react-tabs__tab--selected" : ""
            }`}
            onClick={() => this.setState({ activeTab: tabIndex })}
          >
            {element.name}
          </li>
        );
      }
    });
  };

  updateInvoiceReceiver = values => {
    const {
      updateInvoiceContact,
      fetchCompany,
      companyId,
      success,
      t,
    } = this.props;
    updateInvoiceContact(companyId, values, () => {
      success(
        notificationOpts({
          title: t("Your data was saved"),
        }),
      );
      fetchCompany(this.cancelToken, companyId);
    });
  };

  render() {
    const { activeTab } = this.state;
    const {
      t,
      change,
      values,
      handleSubmit,
      fetchDictionary,
      updateCompany,
      addNewCompany,
      dictionaries: { cities, countries, taxRates },
      company,
      isLoading,
      isNew,
      companyId,
      contacts,
      updateContacts,
      refreshCompanyData,
    } = this.props;

    return (
      <LoaderProvider isLoading={isLoading}>
        <div className={`row`}>
          <div className={"react-tabs"}>
            <ul>{this.renderTabs()}</ul>
          </div>
        </div>
        <div className={"content"}>
          {activeTab === BASE_DATA_TAB_INDEX && (
            <div className={`row`}>
              <CompanyForm
                cities={cities}
                countries={countries}
                taxRates={taxRates}
                fetchDictionary={fetchDictionary}
                updateCompany={updateCompany}
                addNewCompany={addNewCompany}
                refreshCompanyData={refreshCompanyData}
                isNew={isNew}
                values={values}
                change={change}
                handleSubmit={handleSubmit}
              />
              <div className="col-md-6 col-xs-12">
                {!!companyId && !isNew && (
                  <Contacts
                    companyId={companyId}
                    contacts={contacts}
                    refreshContacts={this.refreshContacts}
                    updateContacts={updateContacts}
                  />
                )}
                {!!companyId &&
                  company &&
                  company.invoiceReceiver &&
                  !isNew && (
                    <ContactToInvoiceSend
                      t={t}
                      onEditContact={this.updateInvoiceReceiver}
                      invoiceReceiver={company.invoiceReceiver}
                    />
                  )}
              </div>
            </div>
          )}
          {activeTab === BALANCE_TAB_INDEX && (
            <Balance balanceId={company.balance.id} />
          )}
        </div>
      </LoaderProvider>
    );
  }
}

CompanyDetails.propTypes = {
  companyId: PropTypes.number,
  fetchMeta: PropTypes.func,
  fetchCompany: PropTypes.func,
  fetchDictionary: PropTypes.func,
  updateCompany: PropTypes.func,
  updateInvoiceContact: PropTypes.func,
  updateContacts: PropTypes.func,
  fetchContacts: PropTypes.func,
  refreshCompanyData: PropTypes.func,
  addNewCompany: PropTypes.func,
  dictionaries: PropTypes.object,
  company: PropTypes.object,
  change: PropTypes.func,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  isLoading: PropTypes.bool,
  isNew: PropTypes.bool,
  contacts: PropTypes.array,
  success: PropTypes.func,
};

export default translate()(CompanyDetails);
