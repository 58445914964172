import React from "react";
import { SendLog } from "../Types/Invoice";
import { InjectedTranslateProps } from "react-i18next/src/props";
import TIME from "../../../../Enums/Time";
import { ArrowRight } from "react-feather";
import moment from "moment";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";

type SendLogsProps = InjectedTranslateProps & {
  logs: Array<SendLog>;
};

const SendLogs: React.FunctionComponent<SendLogsProps> = ({
  t,
  logs,
}): React.ReactElement => {
  return (
    <div className="invoice-log-tooltip">
      <h1>{t("Invoice status log")}</h1>
      {logs.map((log, index) => (
        <div key={index} className="padding__b10">
          <div className="text--bold invoice-status-log">
            {t(log.fromStatus)} <ArrowRight /> {t(log.toStatus)}
          </div>
          <div className="small--grey">
            {moment.unix(log.transitionDate).format(TIME.DATE_IN_HISTORY)}
          </div>
          <div className="small--grey">
            {t("Changed by")}:{" "}
            {log.changedBy
              ? `${log.changedBy.name} ${log.changedBy.surname}`
              : t("Machine")}
          </div>
          {log.toStatus === InvoiceSendStatus.SENT && log.sendToEmails && (
            <div className="small--grey">
              {t("Receiver")}: {log.sendToEmails.join(", ")}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SendLogs;
