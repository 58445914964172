import React, { Component } from "react";
import { renderFieldsHelper } from "./forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCourses } from "../Modules/Courses/Actions/CoursesActions";

class SelectCourseWithPagination extends Component {
  #cancelToken = getCancelToken();
  state = {
    pageNo: 1,
  };
  componentWillUnmount() {
    this.#cancelToken.cancel();
  }
  componentDidUpdate(prevProps) {
    const {
      input: { value },
    } = this.props;
    if (value !== prevProps.input.value) {
      this.setState({
        selected: [value],
      });
    }
  }
  onSearchHandler = search => {
    this.setState({ search });
    const { fetchCourses } = this.props;
    fetchCourses(this.#cancelToken, {
      search,
    });
  };
  onInputChangeHandler = search => {
    const { fetchCourses } = this.props;
    this.setState({ selected: [] }, () => {
      search.length === 0 &&
        fetchCourses(this.#cancelToken, {
          search,
        });
    });
  };
  onPaginateHandler = () => {
    const { fetchCourses } = this.props;
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      const { pageNo, search } = this.state;
      const queryValues = {
        pageNo,
      };
      if (search && search.length > 0) {
        queryValues.search = search;
      }
      fetchCourses(this.#cancelToken, queryValues);
    });
  };
  render() {
    const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
      this.props,
    );
    const {
      under,
      label,
      onClearCallback,
      t,
      onChangeHandler,
      coursesList,
    } = this.props;

    return (
      <div
        className={`form-group  ${classes} ${
          under ? under.containerClassName : "row"
        }`}
      >
        <div className={under ? under.containerClassName : "row"}>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={"coursesTypeahead"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={selected => {
                  onChangeHandler(selected[0]);
                }}
                options={coursesList.coursesArray}
                labelKey={course => `${course.signature}`}
                filterBy={(option, { text }) => {
                  const search = option.signature;
                  return search.toLowerCase().indexOf(text.toLowerCase()) > -1;
                }}
                minLength={0}
                maxResults={9}
                paginate={true}
                paginationText="Pokaż więcej wyników"
                onPaginate={this.onPaginateHandler}
                disabled={shouldDisabled}
              />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError && <span className="help-block">{t(error)}</span>}
          </div>
        </div>
      </div>
    );
  }
}
SelectCourseWithPagination.propTypes = {
  coursesList: PropTypes.array,
  under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string,
  onClearCallback: PropTypes.func,
  onChangeHandler: PropTypes.func,
  t: PropTypes.func,
  fetchCourses: PropTypes.func,
  input: PropTypes.object,
};

function mapStateToProps(state) {
  const { coursesList } = state;
  return { coursesList };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCourses }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(SelectCourseWithPagination));
