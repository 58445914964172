import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSlave } from "../Actions";
import SlaveForm from "../Components/SlaveForm";
import validate from "../Validators/SlaveValidator";
import { fetchProductModulesForSlave } from "../../Products/Actions";
import { fetchContract, fetchPayments } from "../../Contracts/Actions";

const FORM_NAME = "NEW_SLAVE";

let NewSlave = props => <SlaveForm {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "course", "module", "relatedFees");
  const error = state.form[FORM_NAME] ? state.form[FORM_NAME].syncErrors : {};
  const { productModules } = state;
  return {
    values,
    error,
    productModules,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { createSlave, fetchContract, fetchPayments, fetchProductModulesForSlave },
    dispatch,
  );
}
export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewSlave),
);
