import React from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import CoursePollTable from "../Components/CoursePollTable/CoursePollTable";
import { fetchCoursePollResults } from "../../Reports/Actions";
import { getCourse } from "../Actions/CoursesActions";

let CoursePoll = props => <CoursePollTable {...props} />;

function mapStateToProps({ coursePollResults, currentCourse }) {
  return { coursePollResults, currentCourse };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCoursePollResults,
      getCourse,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursePoll);
