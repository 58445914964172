import React from "react";
import {
  addStudentToCourse,
  getStudentsForCourse,
  setCoursesStudentsSortOption,
  getCourseStatistics,
} from "../Actions/CoursesActions";
import { fetchPersons } from "../../Persons/Actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { translate } from "react-i18next";
import "react-bootstrap-typeahead/css/Typeahead.css";
import CourseStudentsLists from "../Components/CourseStudents/CourseStudentsLists";
import {
  downloadCourseDiplomas,
  downloadCourseStudentReport,
} from "../../Reports/Actions";
import { error } from "react-notification-system-redux";

const FORM_NAME = "COURSES_STUDENTS_LIST";
const selector = formValueSelector(FORM_NAME);

let CourseStudents = props => <CourseStudentsLists {...props} />;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPersons,
      addStudentToCourse,
      getStudentsForCourse,
      setCoursesStudentsSortOption,
      getCourseStatistics,
      downloadCourseStudentReport,
      downloadCourseDiplomas,
      notifyError: error,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const {
    persons,
    courseStudents,
    courseStudentsSortOption,
    courseStatistics,
  } = state;
  const values = selector(state, "sort");

  return {
    values,
    persons,
    courseStudents,
    courseStudentsSortOption,
    courseStatistics,
  };
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: { sort: null },
})(
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(CourseStudents),
);
