import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function productVersionsReducer(
  state = coursesInitState.products,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_PRODUCT_VERSIONS:
      return action.payload.data.data;
    default:
      return state;
  }
}
