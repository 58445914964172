import { connect } from "react-redux";
import CompaniesList from "../Components/Companies";
import React from "react";
import { bindActionCreators } from "redux";
import { forgetUser } from "../../Auth/Actions";
import { fetchCompanies } from "../Actions/CompaniesActions";

const List = props => <CompaniesList {...props} />;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompanies,
      forgetUser,
    },
    dispatch,
  );

const mapStateToProps = appState => {
  const {
    companies: { companiesList },
    ajaxCallInProgressCount,
  } = appState;

  return {
    companies: companiesList.items,
    counter: companiesList.counter,
    isLoading: ajaxCallInProgressCount > 0,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(List);
