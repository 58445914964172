import React, { Component } from "react";
import { renderFieldsHelper } from "../../../Forms/forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";

class MentorsSelectWithPagination extends Component {
  static propTypes = {
    getMentorsLists: PropTypes.func,
    mentors: PropTypes.array,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.string,
    onClearCallback: PropTypes.func,
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func,
    input: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.PAGE_NO = 1;
    this.PAGE_AMOUNT = 10;
    this.state = {
      page_no: 1,
      selectedMentors: 0,
      selected: [],
    };
    const { getMentorsLists } = this.props;
    getMentorsLists(
      this.cancelToken,
      {
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
      },
      () => {
        if (this.props.input.value) {
          this.setDefaultValue(this.props.input.value);
        }
      },
    );
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  setDefaultValue = value => {
    if (
      value &&
      this.state.selectedMentors !== value &&
      this.props.mentors &&
      this.props.mentors.length > 0
    ) {
      const mentor = this.props.mentors.find(person => {
        return person.id === value;
      });
      if (mentor) {
        this.setState({
          selected: [mentor],
          selectedMentors: value,
        });
      } else {
        this.props.getMentorsLists(
          this.cancelToken,
          {
            pageNo: this.state.page_no,
            pageAmount: this.PAGE_AMOUNT,
          },
          () => {
            if (this.props.input.value) {
              this.setDefaultValue(this.props.input.value);
            }
          },
        );
      }
    } else {
      this.setState({
        selected: [],
        selectedMentors: null,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    if (prevProps.input.value !== input.value) {
      this.setDefaultValue(input.value);
    }
  }

  onSearchHandler = query => {
    this.setState({ query });
    const { getMentorsLists } = this.props;
    getMentorsLists(this.cancelToken, {
      pageNo: this.state.page_no,
      pageAmount: this.PAGE_AMOUNT,
      search: query,
    });
  };

  onInputChangeHandler = query => {
    const { getMentorsLists } = this.props;
    this.setState({ selected: [] }, () => {
      query.length === 0 &&
        getMentorsLists(this.cancelToken, {
          pageNo: this.state.page_no,
          pageAmount: this.PAGE_AMOUNT,
          search: query,
        });
    });
  };

  onPaginateHandler = () => {
    const { getMentorsLists } = this.props;
    this.setState({ page_no: this.state.page_no + 1 }, () => {
      getMentorsLists(this.cancelToken, {
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
      });
    });
  };

  render() {
    const { classes, displayError, shouldDisabled } = renderFieldsHelper(
      this.props,
    );
    const { under, label, onClearCallback, input } = this.props;
    return (
      <div
        className={`mentorsSelect form-group  ${classes} ${
          under ? under.containerClassName : "row"
        }`}
      >
        <div>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={"mentorsListSelect"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={selected => {
                  this.props.onChangeHandler &&
                    selected[0] &&
                    this.props.onChangeHandler(selected[0], null, input.name);
                }}
                options={this.props.mentors.map(mentor => {
                  mentor.fullName = `${mentor.name} ${mentor.surname}`;
                  return mentor;
                })}
                labelKey={person => `${person.fullName}`}
                renderMenuItemChildren={person => (
                  <span>{person.fullName}</span>
                )}
                filterBy={(option, { text }) => {
                  const search = `${option.fullName}`;
                  return search.toLowerCase().indexOf(text.toLowerCase()) > -1;
                }}
                minLength={0}
                maxResults={9}
                paginate={true}
                placeholder={this.props.placeholder}
                paginationText="Pokaż więcej wyników"
                onPaginate={this.onPaginateHandler}
                disabled={shouldDisabled}
              />
              <span aria-hidden="true" className="rw-i rw-i-caret-down" />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError}
          </div>
        </div>
      </div>
    );
  }
}

export default MentorsSelectWithPagination;
