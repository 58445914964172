import "react-widgets/lib/scss/react-widgets.scss";
import "../scss/main.scss";

import React from "react";
import ReactDOM from "react-dom";
import Root from "./Configs/Root";
import MainApp from "./Modules/App/Containers/MainApp";
import { I18nextProvider } from "react-i18next";
import i18n from "../App/Configs/i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import restApiConfig from "./Configs/restApiConfig";

document.addEventListener("DOMContentLoaded", function() {
  ReactDOM.render(
    <Root>
      <GoogleOAuthProvider clientId={restApiConfig.googleClientId}>
        <I18nextProvider i18n={i18n}>
          <MainApp />
        </I18nextProvider>
      </GoogleOAuthProvider>
    </Root>,
    document.getElementById("main-app"),
  );
});
