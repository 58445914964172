import React, { Component } from "react";
import Loader from "../../../Common/Components/Loader/Loader";
import { RenderText } from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field, Form } from "redux-form";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import PropTypes from "prop-types";
import Switch from "react-switch";
import ACTION_TYPES from "../../../Enums/ActionTypes";

class LecturesTypes extends Component {
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      editing: null,
    };
  }

  componentDidMount() {
    const { values, initialValues, initialize, fetchDictionary } = this.props;
    if (!values) {
      initialize(initialValues);
    }
    fetchDictionary(this.cancelToken, {
      path: `/lectureTypes`,
      actionType: ACTION_TYPES.FETCH_LECTURES_TYPES,
    });
  }

  componentDidUpdate(prevProps) {
    const { lecturesTypes, initialize } = this.props;
    if (!_.isEqual(lecturesTypes, prevProps.lecturesTypes)) {
      initialize({ lecturesTypes });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  addLectureType = () => {
    const { change, values } = this.props;
    const lecturesTypes = [
      {
        name: "",
        multiplier: false,
      },
      ...values,
    ];
    change("lecturesTypes", lecturesTypes);
  };

  enableEditMode = row => {
    this.setState({ editing: row.index });
  };

  blurField = event => {
    event.target.blur();
  };

  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);

    return (
      <div className="editField">
        {!values[row.index].id || editing === row.index ? (
          <div className="dictionary-text__input">
            <Field
              onMouseOutHandler={this.blurField}
              value={value}
              name={`lecturesTypes[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  multiplierSwitch = row => {
    const { index, original } = row;
    const { change } = this.props;

    change(`lecturesTypes[${index}].multiplier`, !original.multiplier);
    this.enableEditMode(row);
  };

  saveLectureType = async lectureType => {
    const { createDictionaryItem, updateDictionary } = this.props;

    if (_.has(lectureType, "id")) {
      await updateDictionary(`/lectureTypes/${lectureType.id}`, {
        ...lectureType,
      });
    } else {
      if (!!lectureType.name) {
        await createDictionaryItem(
          `/lectureTypes`,
          ACTION_TYPES.CREATE_LECTURE_TYPES,
          {
            ...lectureType,
          },
        );
      }
    }
  };

  submitForm = values => {
    const { fetchDictionary } = this.props;

    this.saveLectureType(values).then(() => {
      fetchDictionary(
        this.cancelToken,
        {
          path: `/lectureTypes`,
          actionType: ACTION_TYPES.FETCH_LECTURES_TYPES,
        },
        () => {
          this.setState({ editing: [] });
        },
      );
    });
  };

  render() {
    const { values, t } = this.props;
    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={
                  values[values.length - 1] && !values[values.length - 1].id
                }
                className="btn bottom-space btn btn-dark"
                onClick={() => {
                  this.addLectureType();
                }}
              >
                {t("Add lecture type")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <Form>
            <ReactTable
              noDataText={t("No records found")}
              data={values}
              columns={[
                {
                  Header: t("No."),
                  accessor: "id",
                  Cell: row => <span>{row.index + 1}.</span>,
                  resizable: false,
                  width: 50,
                },
                {
                  Header: t("Lecture type"),
                  id: "name",
                  Cell: this.renderEditField,
                },
                {
                  Header: t("Multiplier"),
                  accessor: "multiplier",
                  width: 90,
                  Cell: row => (
                    <Switch
                      onChange={() => this.multiplierSwitch(row)}
                      checked={row.original.multiplier}
                    />
                  ),
                },
                {
                  Header: "",
                  width: 140,
                  resizable: false,
                  Cell: row => {
                    const rowData = row.original;
                    const showSaveButton =
                      !rowData.id ||
                      (!!rowData.id && this.state.editing === row.index);
                    return (
                      showSaveButton && (
                        <button
                          type="button"
                          onClick={() => this.submitForm(rowData)}
                          className="btn btn-dark"
                        >
                          {t("Submit")}
                        </button>
                      )
                    );
                  },
                },
              ]}
              className="dictionaryTable"
              defaultPageSize={10}
              showPagination={true}
            />
          </Form>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(LecturesTypes);

LecturesTypes.propTypes = {
  values: PropTypes.object,
  t: PropTypes.func,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  createDictionaryItem: PropTypes.func,
  updateDictionary: PropTypes.func,
  handleSubmit: PropTypes.func,
  lecturesTypes: PropTypes.object,
  fetchDictionary: PropTypes.func,
};
