import { translate } from "react-i18next";
import React from "react";
import { XCircle } from "react-feather";
import { RenderError, renderFieldsHelper } from "../FormikUtils";
import { FieldProps } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";
import * as _ from "lodash";

type TimePickerProps = FieldProps &
  InjectedTranslateProps & {
    customClass?: string;
    disabled?: boolean;
    limited?: boolean;
    onClearCallback?: () => void;
  };

const RenderTimePickerInputToTranslate: React.FunctionComponent<
  TimePickerProps
> = (props: TimePickerProps): React.ReactElement => {
  const { displayError, error, classes } = renderFieldsHelper(props);
  let minutesInString = "";
  let hoursInString = "";
  const {
    t,
    customClass,
    disabled,
    onClearCallback,
    limited,
    field,
    form,
  } = props;
  const limitedHours = typeof limited === "undefined" ? true : limited;

  const { value } = props.field;

  if (!!value) {
    const [hoursFromValue, minutesFromValue] = value.split(":");
    hoursInString = hoursFromValue;
    minutesInString = minutesFromValue;
  }

  const onChangeHours = event => {
    const { value } = event.target;
    const parsedValue = _.toNumber(value);
    let minutesForChange = "00";

    if (!!value) {
      minutesForChange = minutesInString;
    }

    if (_.isInteger(parsedValue)) {
      const hours = Number(
        parsedValue < 10 ? `0${parsedValue}` : `${parsedValue}`,
      );
      if (limitedHours && hours < 24 && hours >= 0) {
        form.setFieldValue(field.name, `${hours}:${minutesForChange}`);
      } else if (!limitedHours && hours >= 0) {
        form.setFieldValue(field.name, `${hours}:${minutesForChange}`);
      }
    }
  };

  const onBlurHours = () => {
    if (!!value) {
      const [hoursFromValue, minutesFromValue] = value.split(":");
      const time = `${hoursFromValue}:${
        !!minutesFromValue ? minutesFromValue : "00"
      }`;
      form.setFieldValue(field.name, time);
    }
  };

  const onChangeMinutes = event => {
    const { value } = event.target;
    const parsedValue = _.toNumber(value);
    let hoursForChange = "00";

    if (!!value) {
      hoursForChange = hoursInString;
    }

    if (_.isInteger(parsedValue)) {
      const minutes = Number(
        parsedValue < 10 ? `0${parsedValue}` : `${parsedValue}`,
      );
      if (minutes < 60 && minutes >= 0) {
        const time = `${hoursForChange}:${minutes}`;
        form.setFieldValue(field.name, time);
      }
    }
  };

  return (
    <div
      className={`${customClass ? customClass : ""} ${classes ? classes : ""} `}
    >
      <div className={`time-picker-input ${displayError ? "has-error" : ""}`}>
        <input
          onChange={onChangeHours}
          onBlur={onBlurHours}
          className="form-control"
          name={`${props.field.name}.hours`}
          disabled={disabled}
          placeholder="h"
          value={hoursInString}
        />
        <div className={`split ${disabled ? "disabled" : ""}`}>:</div>
        <input
          onChange={onChangeMinutes}
          className="form-control"
          name={`${props.field.name}.minutes`}
          disabled={disabled}
          placeholder="min"
          value={minutesInString}
        />
        {onClearCallback && !disabled && (
          <div className="clear-button-container">
            <div onClick={onClearCallback} className="clear-button">
              <XCircle size={15} />
            </div>
          </div>
        )}
      </div>
      {displayError ? <RenderError t={t} error={error} /> : ""}
    </div>
  );
};

export const RenderTimePicker = translate()(RenderTimePickerInputToTranslate);
