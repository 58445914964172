import React, { ChangeEvent } from "react";
import AmountField from "../../../Forms/AmountField";
import PriceField from "../../../Forms/PriceField";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { Price } from "../Types/ProductModule";

type PackagesPricesProps = WrappedFieldArrayProps<Price> & {
  t: (key: string) => string;
  currencyName: string;
  copyOnBlur: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled: boolean;
};

const PackagesPrices: React.FunctionComponent<PackagesPricesProps> = ({
  fields,
  disabled,
  currencyName,
  copyOnBlur,
  t,
}): React.ReactElement => {
  return (
    <>
      {fields.map((field, index) => {
        const priceForPackage = fields.get(index);

        return (
          <div key={field} className={`form-group row required`}>
            <div className="col-sm-4 col-md-3">
              <label htmlFor="">
                {`${t("Price")} (${currencyName}) - ${t(
                  priceForPackage.package.name,
                )}`}
              </label>
            </div>
            <div className={`col-sm-4 col-md-3`}>
              <div>
                <AmountField
                  className="form-control"
                  name={`${field}.price.student.amount`}
                  component={PriceField}
                  disabled={!currencyName}
                  required="required"
                  shouldDisabled={disabled}
                  onBlur={copyOnBlur}
                />
              </div>
            </div>
            <div className={`col-sm-4 col-md-3`}>
              <div>
                <AmountField
                  className="form-control"
                  name={`${field}.price.thirdParty.amount`}
                  component={PriceField}
                  disabled={!currencyName}
                  required="required"
                  shouldDisabled={disabled}
                  onBlur={copyOnBlur}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PackagesPrices;
