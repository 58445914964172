import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDictComments,
  updateDictComment,
  addDictComment,
} from "../Actions";
import Comments from "../Components/Comments";
import validate from "../Validators/CommentsValidator";

const FORM_NAME = "DICTIONARY_COMMENTS";

let DictionaryComments = props => <Comments {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "dictComments");
  let { dictComments } = state;
  return {
    values,
    initialValues: { dictComments },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictComments,
      updateDictComment,
      addDictComment,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryComments),
);
