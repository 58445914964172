import React, { Component } from "react";
import PropTypes from "prop-types";
import ContactsList from "./ContactsList";
import { translate } from "react-i18next";
import ContactForm from "../Containers/ContactForm";
import { Authorize, permissions } from "../../../Common/Utils/Acl";

class Contacts extends Component {
  state = {
    addNewContactFormVisible: false,
    contactIdForEdit: null,
  };

  editContact = contactId => {
    this.setState({ contactIdForEdit: contactId });
  };

  showAddNewContactForm = () =>
    this.setState({ addNewContactFormVisible: true });

  closeAddNewContactForm = () =>
    this.setState({ addNewContactFormVisible: false });

  addNewContact = newContact => {
    const { companyId, updateContacts, refreshContacts, contacts } = this.props;
    const contactForAdd = [
      ...contacts,
      { ...newContact, companyId, contractOwner: !!newContact.contractOwner },
    ];

    updateContacts(contactForAdd, companyId, refreshContacts);
  };

  removeContact = contactId => {
    const { companyId, contacts, updateContacts, refreshContacts } = this.props;
    const updatedContacts = contacts.filter(
      contact => contact.id !== contactId,
    );

    updateContacts(updatedContacts, companyId, refreshContacts);
  };

  updateContact = contactForUpdate => {
    const { companyId, updateContacts, refreshContacts, contacts } = this.props;
    const updatedContacts = contacts.map(contact =>
      contact.id === contactForUpdate.id ? contactForUpdate : contact,
    );

    updateContacts(updatedContacts, companyId, refreshContacts);
    this.editContact(null);
  };

  render() {
    const { t, contacts, companyId } = this.props;
    const { addNewContactFormVisible, contactIdForEdit } = this.state;
    return (
      <div id="contacts-list-for-company" className={`col-xs-12`}>
        <div>
          <h3>{t("Contacts list")}</h3>
        </div>

        <ContactsList
          contacts={contacts}
          companyId={companyId}
          onRemove={this.removeContact}
          onEdit={this.editContact}
          onUpdate={this.updateContact}
          contactIdForEdit={contactIdForEdit}
        />

        {addNewContactFormVisible ? (
          <>
            <div className={`text-center main-header`}>
              <h5>{t("Add new contact")}</h5>
            </div>
            <ContactForm
              companyId={companyId}
              onAdd={this.addNewContact}
              onCancel={this.closeAddNewContactForm}
            />
          </>
        ) : (
          !contactIdForEdit && (
            <Authorize
              allows={[permissions.COMPANY_EDIT]}
              component={
                <button
                  id="add-new-contact-button"
                  onClick={this.showAddNewContactForm}
                  className={`btn fade-in`}
                >
                  {t("Add new contact")}
                </button>
              }
            />
          )
        )}
      </div>
    );
  }
}

Contacts.propTypes = {
  contacts: PropTypes.array,
  t: PropTypes.func,
  refreshContacts: PropTypes.func,
  updateContacts: PropTypes.func,
  companyId: PropTypes.number,
};

export default translate()(Contacts);
