import dateHelper from "../../Helpers/DateHelper";
import moment from "moment";

export const getDateWithDaysBuffer = days => {
  const today = new Date();
  let newDate = new Date();
  newDate.setDate(today.getDate() + days);
  return newDate;
};

export const getDateOneMonthAfter = () => {
  const daysMax = 30;
  return getDateWithDaysBuffer(daysMax);
};

export const getDateOneMonthAgo = () => {
  const daysMin = -30;
  return getDateWithDaysBuffer(daysMin);
};

export const getDayNumber = date =>
  dateHelper(date)
    .get()
    .isoWeekday();

export const timeToMoment = timeString => {
  const startTimeRegExp = /[0-9]{2}:[0-9]{2}/;
  let startTime = moment();
  if (timeString && startTimeRegExp.test(timeString)) {
    const [hour, minute] = timeString.split(":");
    startTime.set("hour", hour).set("minute", minute);
  } else {
    return null;
  }

  return startTime;
};

export const timeInStringToMinuts = time => {
  if (!time || typeof time !== "string") {
    return null;
  }
  const [hours, minutes] = time.split(":");

  if (_.isInteger(Number(hours)) && _.isInteger(Number(minutes))) {
    return Number(hours) * 60 + Number(minutes);
  }

  return null;
};

export const timeInMinutesToString = time => {
  if (!time) {
    return "00:00";
  }

  const hours = Math.floor(time / 60);
  const minutes = time - hours * 60;

  let hoursInString = hours < 10 ? `0${hours}` : `${hours}`;
  let minutesInString = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${hoursInString}:${minutesInString}`;
};

export const isDateBeforeToday = date =>
  date ? moment(date).isBefore(moment()) : false;

export const getDateWithTime = timestamp => {
  return moment.utc(timestamp).format("DD.MM.YYYY HH:mm:ss");
};

export const getDate = timestamp => {
  return moment.utc(timestamp).format("DD.MM.YYYY");
};
