import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GraduatesForm from "../Components/GraduatesForm";
import { fetchAllProductsFullInfo } from "../../Products/Actions";
import {
  fetchDictionaryOnlyTeach,
  fetchDictComments,
} from "../../Dictionaries/Actions";
import {
  fetchReportGraduates,
  downloadReportGraduates,
  addContractComment,
  sendNps,
} from "../Actions";

import { formValueSelector, reduxForm } from "redux-form";

const FORM_NAME = "GRADUATES";

const Graduates = props => <GraduatesForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    onlyTeachCities,
    reportGraduates,
    allProductsFullInfo,
    dictComments,
  } = state;
  const values = selector(
    state,
    "cityId",
    "sort",
    "search",
    "started",
    "productId",
    "from",
    "to",
    "graduateStudy",
  );
  return {
    values,
    onlyTeachCities,
    dictComments,
    reportGraduates,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionaryOnlyTeach,
      fetchReportGraduates,
      downloadReportGraduates,
      fetchAllProductsFullInfo,
      addContractComment,
      fetchDictComments,
      sendNps,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Graduates),
);
