import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPayments, removeSlave, updateSlave } from "../Actions";
import SlavesTable from "../Components/SlavesTable";
import validate from "../Validators/SlaveEditValidator";
import { fetchContract } from "../Actions";

const FORM_NAME = "SLAVES_TABLE_FORM";

let SlavesTableForm = props => <SlavesTable {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "slaves");
  const error = state.form[FORM_NAME] ? state.form[FORM_NAME].syncErrors : {};
  const {
    contract,
    __metadata: {
      contract: { slaveFinishStatus },
    },
  } = state;

  return {
    values,
    error,
    slaveFinishStatus,
    contract,
    initialValues: {
      slaves: contract.contract.slaves,
    },
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateSlave, fetchContract, fetchPayments, removeSlave },
    dispatch,
  );
}
export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SlavesTableForm),
);
