import React from "react";
import { Users, Bell } from "react-feather";
import ListElement from "../../../Common/Components/ListElement/ListElement";
import { Link } from "react-router-dom";
import getFormattedDate from "../../../Helpers/GetFormattedDate";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

const CoursesListElement = props => {
  const {
    id,
    city,
    courseSchedule: { courseMode },
    dictionaries,
    publicityDate,
    signature,
    startDate,
    numberOfPlaces,
    t,
    statistics,
    product,
    alert,
  } = props;
  const mode =
    dictionaries.coursesModes &&
    dictionaries.coursesModes.find(mode => mode.id === courseMode);
  const renderPublicityDate = publicityDate && (
    <span className="product-info-details">
      {t("Publication")}: {getFormattedDate(publicityDate)}
    </span>
  );
  const renderStartDate = startDate && (
    <span className="product-info-details">
      {t("Start date")}: {getFormattedDate(startDate)}
    </span>
  );
  const renderNumberOfPlaces = numberOfPlaces && (
    <span className="product-info-details">
      {t("Number of places")}: {numberOfPlaces}
    </span>
  );

  const COLOR_ALERT = "#c9302c";
  return (
    <ListElement
      icon={
        havePermissions([permissions.COURSE_STUDENT_LIST]) ? (
          <Link to={`courses/${id}/students`}>
            <Users size={28} />
          </Link>
        ) : (
          <Users size={28} />
        )
      }
      path={`/courses/${id}/settings`}
      clickable={havePermissions([permissions.COURSE_DETAILS_VIEW])}
      actions={
        alert ? (
          <Link to={`courses/${id}/polls`}>
            <Bell color={COLOR_ALERT} fill={COLOR_ALERT} size={28} />
          </Link>
        ) : null
      }
      actionClasses={`horizontal-center`}
    >
      <div>
        <h5 className="text product-name">
          {product && product.name} - {mode && mode.name} - {city && city.name}{" "}
          <span className="signature">{signature}</span>
        </h5>
        <div id={`product-info-${id}`} className="product-info">
          <p className="product-info-details">
            {renderPublicityDate}
            {renderStartDate}
            {renderNumberOfPlaces}
          </p>
          <p>
            <span className="product-info-details">
              {t("During Recruitment")}: {statistics.duringRecruitment}
            </span>
            <span className="product-info-details">
              {t("Contracts Sent")}: {statistics.send}
            </span>
            <span className="product-info-details">
              {t("Contracts Signed")}: {statistics.actual + statistics.finished}
            </span>
            <span className="product-info-details">
              {t("Resignations and Transfers")}:{" "}
              {statistics.resign + statistics.transfer}
            </span>
            <span className="product-info-details">
              {t("All students")}: {statistics.allActiveStudents}
            </span>
          </p>
        </div>
      </div>
    </ListElement>
  );
};

CoursesListElement.propTypes = {
  id: PropTypes.number,
  cityId: PropTypes.number,
  courseSchedule: PropTypes.object,
  dictionaries: PropTypes.object,
  city: PropTypes.object,
  product: PropTypes.object,
  publicityDate: PropTypes.number,
  signature: PropTypes.string,
  startDate: PropTypes.number,
  numberOfPlaces: PropTypes.number,
  t: PropTypes.func,
  statistics: PropTypes.object,
  productVersionName: PropTypes.string,
  alert: PropTypes.bool,
};

export default translate()(CoursesListElement);
