export default fields => {
  const errors = {};

  const {
    name,
    type,
    date,
    startTime,
    duration,
    participants,
    isProduct,
  } = fields;

  if (!name) {
    errors.name = "Enter event's name";
  }

  if (!type) {
    errors.type = "Choose type of event";
  }

  if (!isProduct && !date) {
    errors.date = "Enter event's date";
  }

  if (!startTime) {
    errors.startTime = "Enter event's start time";
  }

  if (!duration || duration <= 0) {
    errors.duration = "Enter event's duration";
  }

  if (!participants || participants.length === 0) {
    errors.participants = "Set the participants";
  }

  return errors;
};
