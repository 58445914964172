export default fields => {
  const errors: Record<string, unknown> = {};
  const nameShortcutLength = 3;

  const {
    currency,
    kind,
    name,
    shortcut,
    language,
    businessOwner,
    essentialOwner,
    numberOfPlaces,
  } = fields;

  if (!kind) {
    errors.kind = "Choose product's kind";
  }
  if (!name) {
    errors.name = "Enter product's name";
  }
  if (!shortcut || shortcut.length !== nameShortcutLength) {
    errors.shortcut = "Enter 3-letter long shortcut of the product's name";
  }
  if (!language) {
    errors.language = "Choose a language";
  }
  if (!currency) {
    errors.currency = "Choose a currency";
  }
  if (!businessOwner) {
    errors.businessOwner = "Choose the business owner";
  }
  if (!essentialOwner) {
    errors.essentialOwner = "Choose the essential owner";
  }
  if (!numberOfPlaces) {
    errors.numberOfPlaces = "Enter the number of places";
  }

  return errors;
};
