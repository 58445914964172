export const isPolishPeselValid = value => {
  let regExp = /^[0-9]{11}$/;
  if (regExp.test(value) === false) {
    return false;
  }
  const config = {
    sumNumberOne: 9,
    sumNumberTwo: 7,
    sumNumberThree: 3,
    sumNumberFour: 1,
    radix: 10,
    substrStart: 10,
    substrFinish: 11,
    percent: 10,
  };
  let totalSum =
    [
      config.sumNumberOne,
      config.sumNumberTwo,
      config.sumNumberThree,
      config.sumNumberFour,
      config.sumNumberOne,
      config.sumNumberTwo,
      config.sumNumberThree,
      config.sumNumberFour,
      config.sumNumberOne,
      config.sumNumberTwo,
    ].reduce(
      (totalSum, weight, index) =>
        totalSum +
        weight * parseInt(value.substring(index, index + 1), config.radix),
      0,
    ) % config.percent;
  const valid =
    totalSum ===
    parseInt(
      value.substring(config.substrStart, config.substrFinish),
      config.radix,
    );
  return valid;
};

export const isPolishRegonValid = value => {
  const regonRegExp = new RegExp("^[0-9]{1,}$");
  const regonMinLength = 14;
  return regonRegExp.test(value) && value.length <= regonMinLength;
};

export const isPolishNIPValid = value => {
  const nipWithoutDashes = value.replace(/-/g, "");
  const regExp = /^[0-9]{10}$/;
  if (!regExp.test(nipWithoutDashes)) {
    return false;
  }
  const digits = ("" + nipWithoutDashes).split("");
  const checksum =
    (6 * parseInt(digits[0]) +
      5 * parseInt(digits[1]) +
      7 * parseInt(digits[2]) +
      2 * parseInt(digits[3]) +
      3 * parseInt(digits[4]) +
      4 * parseInt(digits[5]) +
      5 * parseInt(digits[6]) +
      6 * parseInt(digits[7]) +
      7 * parseInt(digits[8])) %
    11;

  return parseInt(digits[9]) === checksum;
};
