import React from "react";
import { FileText } from "react-feather";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import FormattedPollTimeframe from "../../../Common/Components/FormattedPollTimeframe/FormattedPollTimeframe";
import CONTRACT from "../../../Enums/Contract";

class StudentPoll extends React.Component {
  static propTypes = {
    contractId: PropTypes.number,
    id: PropTypes.number,
    hasAnswer: PropTypes.bool,
    t: PropTypes.func,
    pollPackage: PropTypes.object,
    slaveContractId: PropTypes.number,
    sendDate: PropTypes.string,
    handleSendPoll: PropTypes.func,
    contract: PropTypes.object,
    sendCounter: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
    };
  }
  componentWillUnmount() {
    clearTimeout(this.pollTimeout);
  }

  handleSendPoll = pollId => {
    this.props.handleSendPoll(pollId);
    const buttonTimeout = 5000;
    this.setState(
      {
        isDisabled: true,
      },
      () => {
        this.pollTimeout = setTimeout(() => {
          this.setState({
            isDisabled: false,
          });
        }, buttonTimeout);
      },
    );
  };
  render() {
    const {
      pollPackage: {
        poll: { name, ruleEvent, daysToSend },
      },
      t,
      hasAnswer,
      sendDate,
      id,
      contract,
      sendCounter,
    } = this.props;
    const isDisabled =
      hasAnswer ||
      this.state.isDisabled ||
      ((contract.status === CONTRACT.STATUSES.CONTRACT_SIGNED &&
        contract.finishReason === CONTRACT.FINISH_REASONS.RESIGN) ||
        contract.finishReason === CONTRACT.FINISH_REASONS.TRANSFER);
    return (
      <div className="poll-info col-xs-12">
        <FileText
          size={38}
          color="white"
          fill={hasAnswer ? "#fbbb21" : "#4d4d4f"}
        />
        <div className="poll-info__text">
          <h5>{name}</h5>
          <FormattedPollTimeframe days={daysToSend} ruleEvent={ruleEvent} />
          <p>{sendDate}</p>
        </div>
        <button
          onClick={() => this.handleSendPoll(id)}
          type="button"
          className={`btn btn-dark ${
            !hasAnswer ? "poll-info__button-send" : ""
          }`}
          disabled={isDisabled}
        >
          {hasAnswer || this.state.isDisabled
            ? t("Sent")
            : sendCounter > 0
            ? t("Send again")
            : t("Send")}
        </button>
        <div className="poll-info__text">
          <p>
            {t("Sent")}: {sendCounter}
          </p>
        </div>
      </div>
    );
  }
}

export default translate()(StudentPoll);
