import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function createInvoice(values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice`,
    actionType: ACTION_TYPES.CREATE_INVOICE,
    values,
    callback: callback,
  };

  return Client.actionPOST(config);
}

export function fetchInvoices(cancelToken, balanceId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice?balanceId=${balanceId}`,
    actionType: ACTION_TYPES.FETCH_INVOICES,
    callback,
    cancelToken,
  });
}

export function downloadInvoice(cancelToken, config, callback = null) {
  const { invoiceId, invoiceNumber } = config;
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/${invoiceId}/download`,
    actionType: ACTION_TYPES.DOWNLOAD_INVOICE,
    blobHeader: "blob",
    fileName: `${invoiceNumber}.pdf`,
    callback,
    cancelToken,
  });
}

export function saveInvoice(invoiceId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice/${invoiceId}`,
    actionType: ACTION_TYPES.SAVE_INVOICE,
    values,
    callback: callback,
  };
  return Client.actionPUT(config);
}

export function fetchInvoiceReceivers(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/receivers`,
    actionType: ACTION_TYPES.FETCH_INVOICE_RECEIVERS,
    callback,
    params: {
      search: params.search || "",
    },
    cancelToken,
  });
}

export function updateInvoiceCorrection(
  cancelToken,
  correctionId,
  values = {},
  callback = null,
) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice/correction/${correctionId}`,
    actionType: ACTION_TYPES.UPDATE_INVOICE_CORRECTION,
    values,
    callback,
    cancelToken,
  };

  return Client.actionPUT(config);
}

export function addInvoiceCorrection(cancelToken, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice/correction`,
    actionType: ACTION_TYPES.ADD_INVOICE_CORRECTION,
    values,
    callback,
    cancelToken,
  };

  return Client.actionPOST(config);
}

export function deleteInvoiceCorrection(correctionId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice/correction/${correctionId}`,
    actionType: ACTION_TYPES.REMOVE_INVOICE_CORRECTION,
    callback,
  };

  return Client.actionDELETE(config);
}

export function updateInvoiceBuyerAddress(
  cancelToken,
  invoiceId,
  values,
  callback = null,
) {
  const config = {
    path: `${restApiConfig.API_URL}/invoice/${invoiceId}/address`,
    actionType: ACTION_TYPES.UPDATE_INVOICE_BUYER_ADDRESS,
    values,
    callback,
    cancelToken,
  };

  return Client.actionPUT(config);
}

export function fetchLegalBasis(
  cancelToken,
  vatRate,
  issueDate,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/legalBasis?vatRate=${vatRate}&issueDate=${issueDate}`,
    actionType: ACTION_TYPES.GET_LEGAL_BASIS,
    callback,
    cancelToken,
  });
}

export function clearLegalBasis() {
  return {
    type: ACTION_TYPES.CLEAR_LEGAL_BASIS,
  };
}
