import { ajaxStatusActions } from "./AjaxStatusActions";

const initAjaxCallInProgressCount = 0;

export default function ajaxStatusReducer(
  state = initAjaxCallInProgressCount,
  action,
) {
  switch (action.type) {
    case ajaxStatusActions.BEGIN_AJAX_CALL:
      return state + 1;

    case ajaxStatusActions.END_AJAX_CALL:
      return state - 1;

    default:
      return state;
  }
}
