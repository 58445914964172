import Notes from "../Components/Notes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addContractPaymentsNote as addNote,
  fetchContractPaymentsNotes as fetchNotes,
  removeContractPaymentsNote as removeNote,
} from "../../Reports/Actions";

const mapStateToProps = state => ({
  notes: state.contractPaymentsNote,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotes,
      addNote,
      removeNote,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notes);
