import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import ContractUpdate from "../Containers/ContractUpdate";
import ContractTest from "../Containers/ContractTest";
import StudentPolls from "../../Students/Components/StudentPolls";
import Loader from "../../../Common/Components/Loader/Loader.js";
import { clearContract } from "../Actions";
import getCancelToken from "../../../Helpers/GetCancelToken";
import formatPhoneNumber from "../../../Helpers/FormatPhoneNumber";
import { Phone, Mail } from "react-feather";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import { Tenant, Features } from "../../../Common/Utils/Tenant";
import Balance from "../../Balance/Containers/Balance";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import POLL_TYPES from "../../../Enums/PollTypes";

class Contract extends Component {
  #tabs = {
    SUMMARY: 0,
    POLLS: 1,
    TEST: 2,
    BALANCE: 3,
  };

  static propTypes = {
    fetchContract: PropTypes.func,
    match: PropTypes.object,
    resetCourse: PropTypes.func,
    contract: PropTypes.object,
    t: PropTypes.func,
    fetchIncomesThirdParty: PropTypes.func,
    fetchContractPolls: PropTypes.func,
    contractPolls: PropTypes.array,
    contractModulesPolls: PropTypes.array,
    contractSlavePolls: PropTypes.array,
    contractManualPolls: PropTypes.array,
    sendPoll: PropTypes.func,
    fetchPayments: PropTypes.func,
    payments: PropTypes.object,
    fetchContractMeta: PropTypes.func,
    history: PropTypes.object,
    fetchProductModulesSimple: PropTypes.func,
    fetchProductModulesInstallmentStartSimple: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const {
      fetchContract,
      match,
      fetchContractPolls,
      fetchContractMeta,
      fetchProductModulesSimple,
      fetchProductModulesInstallmentStartSimple,
    } = this.props;
    const {
      FETCH_CONTRACT_POLLS,
      FETCH_CONTRACT_MODULES_POLLS,
      FETCH_CONTRACT_SLAVE_POLLS,
    } = ACTION_TYPES;

    this.state = {
      tabIndex: 0,
      contractId: match.params.contractId,
      contractLoading: false,
    };
    this.contractHeader = React.createRef();
    fetchContract(
      this.cancelToken,
      {
        contractId: match.params.contractId,
      },
      ({ data: { data } }) => {
        const finishReason = _.get(data, "contract.finishReason.id");
        const productVersionId = _.get(data, "course.productVersion.id");

        if (finishReason && productVersionId && finishReason === 3) {
          fetchProductModulesInstallmentStartSimple(
            this.cancelToken,
            productVersionId,
          );
        } else {
          fetchProductModulesSimple(this.cancelToken, productVersionId);
        }

        fetchContractPolls(this.cancelToken, {
          contractId: data.contract.id,
          pollType: POLL_TYPES.COURSE,
          actionType: FETCH_CONTRACT_POLLS,
        });
        fetchContractPolls(this.cancelToken, {
          contractId: data.contract.id,
          pollType: POLL_TYPES.MODULE,
          actionType: FETCH_CONTRACT_MODULES_POLLS,
        });
        fetchContractPolls(this.cancelToken, {
          contractId: data.contract.id,
          pollType: POLL_TYPES.SLAVE,
          actionType: FETCH_CONTRACT_SLAVE_POLLS,
        });
        fetchContractPolls(this.cancelToken, {
          contractId: data.contract.id,
          pollType: POLL_TYPES.MANUAL,
          actionType: ACTION_TYPES.FETCH_CONTRACT_MANUAL_POLLS,
        });
      },
    );
    fetchContractMeta(this.cancelToken, match.params.contractId);
  }

  componentDidUpdate(prevProps) {
    const {
      contract: { contract },
      payments,
      match: {
        params: { contractId },
      },
      fetchContract,
      fetchContractPolls,
    } = this.props;
    const showContract =
      contract && payments && !_.isEmpty(contract) && !_.isEmpty(payments);
    if (showContract !== this.state.showContract) {
      this.setState({
        showContract,
      });
    }
    if (prevProps.match.params.contractId !== contractId) {
      window.scrollTo(0, this.contractHeader.current.offsetTop);
      this.setState({ contractLoading: true }, () => {
        fetchContract(
          this.cancelToken,
          {
            contractId,
          },
          ({ data: { data } }) => {
            fetchContractPolls(this.cancelToken, {
              contractId: data.contract.id,
              pollType: POLL_TYPES.COURSE,
              actionType: FETCH_CONTRACT_POLLS,
            });
            fetchContractPolls(this.cancelToken, {
              contractId: data.contract.id,
              pollType: POLL_TYPES.MODULE,
              actionType: FETCH_CONTRACT_MODULES_POLLS,
            });
            fetchContractPolls(this.cancelToken, {
              contractId: data.contract.id,
              pollType: POLL_TYPES.SLAVE,
              actionType: FETCH_CONTRACT_SLAVE_POLLS,
            });
          },
        );
      });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
    clearContract();
    this.props.resetCourse();
  }

  handleSendPoll = contractPollId => {
    const {
      sendPoll,
      fetchContractPolls,
      contract: {
        contract: { id: contractId },
      },
    } = this.props;
    sendPoll(contractPollId, () => {
      fetchContractPolls(this.cancelToken, {
        contractId: contractId,
        pollType: POLL_TYPES.COURSE,
        actionType: ACTION_TYPES.FETCH_CONTRACT_POLLS,
      });
      fetchContractPolls(this.cancelToken, {
        contractId: contractId,
        pollType: POLL_TYPES.MODULE,
        actionType: ACTION_TYPES.FETCH_CONTRACT_MODULES_POLLS,
      });
      fetchContractPolls(this.cancelToken, {
        contractId: contractId,
        pollType: POLL_TYPES.SLAVE,
        actionType: ACTION_TYPES.FETCH_CONTRACT_SLAVE_POLLS,
      });
    });
  };
  renderPayerBalanceTabName = payerName => {
    if (payerName.length <= 25) {
      return payerName;
    }
    return `${payerName.substring(0, 25)}...  `;
  };
  showPayerBalance = (payerId, tabIndex) => {
    this.setState({
      tabIndex,
      payerId,
    });
  };
  render() {
    const {
      contract: {
        student: { person: { name, emails, surname, phones, id } = {} } = {},
        course,
        contract,
      },
      payments,
      t,
      contractPolls,
      contractModulesPolls,
      contractSlavePolls,
      contractManualPolls,
      match,
    } = this.props;
    const { payerId, tabIndex } = this.state;

    const tabs = [
      {
        name: t("Summary"),
        visible: true,
      },
      {
        name: t("Surveys"),
        visible: true,
      },
    ];
    if (Features.knowledge_test) {
      tabs.push({
        name: t("Knowledge test and interview"),
        visible: true,
      });
    }

    const renderTabs = tabs.map((element, index) => {
      if (element.visible) {
        return (
          <li
            key={index}
            className={`react-tabs__tab ${
              tabIndex === index ? "react-tabs__tab--selected" : ""
            }`}
            onClick={() => {
              this.setState({ tabIndex: index });
            }}
          >
            {element.name}
          </li>
        );
      }
    });

    if (payments && payments.payers) {
      payments.payers.forEach((payer, index) => {
        const balanceTabIndex = renderTabs.length + ++index;
        renderTabs.push(
          <li
            key={balanceTabIndex}
            className={`react-tabs__tab ${
              tabIndex === balanceTabIndex ? "react-tabs__tab--selected" : ""
            }`}
            onClick={() => {
              this.showPayerBalance(payer.balance.id, balanceTabIndex);
            }}
          >
            {this.renderPayerBalanceTabName(payer.balance.owner.name)}
          </li>,
        );
      });
    }

    return name && course ? (
      <main>
        <div className="custom-container">
          <header className="main-header">
            <Link
              to={course.id ? `/courses/${course.id}/students` : "/"}
              className="btn btn-warning contract__router-button"
            >
              {t("Return")}
            </Link>
            <h1 className="contract-heading" ref={this.contractHeader}>
              <Link
                to={`/persons/update/${id}`}
                className="contract-heading__name"
              >
                {`${name} ${surname}`}
              </Link>
              {phones.length > 0 ? (
                <a
                  href={`tel:${phones[0]}`}
                  className="contract-heading__phone"
                >
                  <Phone size={14} />
                  {formatPhoneNumber(phones[0])}
                </a>
              ) : null}
              {emails.length > 0 ? (
                <a
                  href={`mailto:${emails[0]}`}
                  className="contract-heading__email"
                >
                  <Mail size={14} />
                  {emails[0]}
                </a>
              ) : null}
            </h1>
            <h2>
              <strong>
                {course.productVersionBootcamp
                  ? course.productVersionBootcamp.name
                  : " "}
              </strong>{" "}
              {course.signature ? (
                <Link to={`/courses/${course.id}/settings`}>
                  {course.signature}
                </Link>
              ) : (
                ""
              )}{" "}
            </h2>
          </header>
        </div>
        <div className="react-tabs custom-container">
          <ul>{renderTabs}</ul>
        </div>
        <div className=" content col-xs-12">
          <div id="contract-container" className="custom-container">
            <Authorize
              component={
                <div
                  className={tabIndex !== this.#tabs.SUMMARY ? "hidden" : ""}
                >
                  <ContractUpdate contractId={match.params.contractId} />
                </div>
              }
              allows={[permissions.STUDENT_CONTRACT_VIEW]}
            />
            <div className={tabIndex !== this.#tabs.POLLS ? "hidden" : ""}>
              <StudentPolls
                contractModulesPolls={contractModulesPolls}
                contractPolls={contractPolls}
                contractSlavePolls={contractSlavePolls}
                contractManualPolls={contractManualPolls}
                handleSendPoll={this.handleSendPoll}
                contract={contract}
              />
            </div>
            <Tenant
              component={
                <div
                  className={tabIndex !== this.#tabs.SURVEYS ? "hidden" : ""}
                >
                  <Authorize
                    component={<ContractTest />}
                    allows={[permissions.QUESTIONNAIRE_ANSWERS_VIEW]}
                  />
                </div>
              }
              feature={Features.knowledge_test}
            />
            {tabIndex >= this.#tabs.BALANCE && (
              <Balance balanceId={payerId} showHeader={false} />
            )}
          </div>
        </div>
      </main>
    ) : (
      <Loader />
    );
  }
}

export default translate()(Contract);
