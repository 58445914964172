import React, { Component } from "react";
import { FieldArray, Field } from "redux-form";
import { translate } from "react-i18next";
import { RenderCheckbox } from "../../../Forms/forms";
import HoverInfo from "../../../Common/Components/HoverInfo/HoverInfo";
import PropTypes from "prop-types";
import { PersonFormContext } from "./PersonForm";

class PersonEmployeePermissions extends Component {
  static propTypes = {
    roles: PropTypes.array,
    disabled: PropTypes.bool,
    t: PropTypes.func,
    required: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = { shouldRender: false };
  }
  render() {
    const { roles, disabled, t } = this.props;
    return (
      <div className="employee-permissions">
        <div className="row">
          <h2 className="col-sm-6 col-md-7">{t("Employee Permissions")}</h2>
          <Field
            name="userEnabled"
            component={RenderCheckbox}
            labelForCheckbox={t("Active account")}
            under={{ fieldClassName: "col-sm-6 col-md-5" }}
          />
        </div>
        <FieldArray
          name="roles"
          options={roles}
          component={RenderRolesCheckboxes}
          disabled={disabled}
        />
      </div>
    );
  }
}

const RenderRolesCheckboxes = field => {
  const {
    fields,
    fields: { name: fieldArrayName },
    options,
    meta: { error, submitFailed },
    disabled,
  } = field;
  const classes = submitFailed && error ? "has-error" : "";
  const chosenOptions = fields.getAll() ? [...fields.getAll()] : [];

  return (
    <PersonFormContext.Consumer>
      {context => (
        <div className={`${disabled ? " muted" : ""}`}>
          <div className={`form-group row ${classes}`}>
            {options.map(({ name, id, permissions }) => {
              const indexInFields = chosenOptions.indexOf(id);
              const fieldName = `${fieldArrayName}--${id}`;
              const translatedPermission = permissions.map(
                perm => context.permissions[perm],
              );
              return (
                <div className="col-sm-6" key={id}>
                  <label htmlFor={fieldName} className="checkbox-inline">
                    <input
                      type="checkbox"
                      name={fieldName}
                      id={fieldName}
                      checked={indexInFields > -1}
                      onChange={() => {
                        indexInFields > -1
                          ? fields.remove(indexInFields)
                          : fields.push(id);
                      }}
                    />
                    <span>{name}</span>
                    <HoverInfo content={translatedPermission.join(", ")} />
                  </label>
                </div>
              );
            })}
          </div>
          {submitFailed && error && <span className="help-block">{error}</span>}
        </div>
      )}
    </PersonFormContext.Consumer>
  );
};

export default translate()(PersonEmployeePermissions);
