import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { formValueSelector, reduxForm } from "redux-form";
import RefundsReport from "../Components/RefundsReport";
import { fetchRefunds, registerRefundDate, downloadRefunds } from "../Actions";

const FORM_NAME = "REFUNDS_REPORT";

const Refunds = props => <RefundsReport {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const { refunds } = state;
  const values = { notRefunded: selector(state, "notRefunded") };
  return {
    refunds,
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRefunds,
      registerRefundDate,
      downloadRefunds,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Refunds),
);
