import Client from "../../Common/Utils/ClientApi";
import restApiConfig from "../../Configs/restApiConfig";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function calendarNextMonth() {
  return {
    type: ACTION_TYPES.CALENDAR_NEXT_MONTH,
  };
}

export function calendarPrevMonth() {
  return {
    type: ACTION_TYPES.CALENDAR_PREV_MONTH,
  };
}

export function fetchTimeline(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/course/timeline`,
    actionType: ACTION_TYPES.FETCH_TIMELINE,
    callback,
    values,
  });
}

export function fetchLecturersSettlement(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/lecturer-settlements`,
    actionType: ACTION_TYPES.LECTURERS_SETTLEMENTS,
    params: { format: "xls", ...params },
    blobHeader: "blob",
    fileName: "lecturers-settlements.xls",
    callback: callback,
  });
}

export function fetchTimelineStats(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/course/timeline/stats`,
    actionType: ACTION_TYPES.FETCH_TIMELINE_STATS,
    callback,
    values,
  });
}

export function fetchTimelineComments(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/course/timeline/comments`,
    actionType: ACTION_TYPES.FETCH_TIMELINE_COMMENTS,
    callback,
    values,
  });
}

export function fetchComment(cancelToken, params) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/comment/${params.type}/${params.id}`,
    actionType: ACTION_TYPES.FETCH_COMMENT,
    cancelToken,
  });
}

export function updateComment(values) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/comment/${values.type}/${values.id}`,
    actionType: ACTION_TYPES.UPDATE_COMMENT,
    values: { text: values.text },
  });
}

export function deleteComment(values) {
  return Client.actionDELETE({
    path: `${restApiConfig.API_URL}/comment/${values.type}/${values.id}`,
    actionType: ACTION_TYPES.DELETE_COMMENT,
  });
}

export function makeIncomeTransfer(bankTransferId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/bankTransfers/${bankTransferId}/transfers`,
    actionType: ACTION_TYPES.MAKE_INCOME_TRANSFER,
    values,
    callback: callback,
  };
  return Client.actionPOST(config);
}

export function updateIncome(incomeId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/incomes/${incomeId}`,
    actionType: ACTION_TYPES.UPDATE_INCOME,
    values,
    callback: callback,
  };
  return Client.actionPUT(config);
}

export function deleteIncome(incomeId, params, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/manualIncome/${incomeId}`,
    actionType: ACTION_TYPES.DELETE_MANUAL_INCOME,
    params,
    callback: callback,
  };
  return Client.actionDELETE(config);
}

export function fetchReport(cancelToken, values) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/dashboard`,
    actionType: ACTION_TYPES.FETCH_REPORT,
    values,
    cancelToken,
  });
}

export function fetchGroupDetailsData(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/groupFill`,
    actionType: ACTION_TYPES.FETCH_GROUP_FILL_DATA,
    cancelToken,
    params,
    callback,
  });
}

export function getGroupFillReport(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/groupFill`,
    actionType: ACTION_TYPES.DOWNLOAD_GROUP_FILL_REPORT,
    params: { format: "xls", ...params },
    blobHeader: "blob",
    fileName: "groupfill.xls",
    callback: callback,
  });
}

export function fetchReportGraduates(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/graduates`,
    actionType: ACTION_TYPES.FETCH_REPORT_GRADUATES,
    cancelToken,
    params,
    callback,
  });
}

export function downloadReportGraduates(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/graduates`,
    actionType: ACTION_TYPES.DOWNLOAD_REPORT_GRADUATES,
    params: { format: "xls", ...params },
    blobHeader: "blob",
    fileName: "graduates.xls",
    callback: callback,
  });
}

export function fetchCurrentPollResults(cancelToken, values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/currentPollResults`,
    actionType: ACTION_TYPES.FETCH_CURRENT_POLL_RESULTS,
    values,
    cancelToken,
    callback,
  });
}

export function downloadCurrentPollResults(params, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/currentPollResults`,
    actionType: ACTION_TYPES.DOWNLOAD_CURRENT_POLL_RESULTS,
    values: { format: "xls", ...params },
    blobHeader: "blob",
    fileName: "current_poll_results.xls",
    callback,
  });
}

export function fetchCoursePollResults(cancelToken, values, callback = null) {
  const {
    FETCH_COURSE_POLL_RESULTS_DETAILED,
    FETCH_COURSE_POLL_RESULTS_GENERAL,
  } = ACTION_TYPES;

  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/coursePollResults/${values.type}/${values.courseId}`,
    actionType:
      values.type === "detailed"
        ? FETCH_COURSE_POLL_RESULTS_DETAILED
        : FETCH_COURSE_POLL_RESULTS_GENERAL,
    values,
    cancelToken,
    callback,
  });
}

export function fetchBankIncomes(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/incomes`,
    actionType: ACTION_TYPES.FETCH_BANK_INCOMES,
    cancelToken,
    params,
    callback,
  });
}

export function downloadBankIncomes(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/incomes`,
    actionType: ACTION_TYPES.DOWNLOAD_BANK_INCOMES,
    blobHeader: "blob",
    fileName: "IncomesReport.xls",
    cancelToken,
    params,
    callback,
  });
}

export function fetchBankIncomesTransfers(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/bankTransfersTransfers`,
    actionType: ACTION_TYPES.FETCH_BANK_INCOMES_TRANSFERS,
    cancelToken,
    params,
    callback,
  });
}

export function downloadBankIncomesTransfers(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/bankTransfersTransfers`,
    actionType: ACTION_TYPES.DOWNLOAD_BANK_INCOMES_TRANSFERS,
    blobHeader: "blob",
    fileName: "bankincomestransfers.xls",
    cancelToken,
    params,
    callback,
  });
}

export function fetchRefunds(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/refunds`,
    actionType: ACTION_TYPES.FETCH_REFUNDS_REPORT,
    cancelToken,
    params,
    callback,
  });
}

export function downloadRefunds(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/refunds`,
    actionType: ACTION_TYPES.DOWNLOAD_REFUNDS_REPORT,
    blobHeader: "blob",
    fileName: "refunds.xls",
    cancelToken,
    params,
    callback,
  });
}

export function registerRefundDate(refundId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/refunds/${refundId}`,
    actionType: ACTION_TYPES.UPDATE_REFUND,
    values,
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function fetchInvoices(cancelToken, values, params, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/invoices?pageNo=${params.pageNo}&pageAmount=${params.pageAmount}`,
    actionType: ACTION_TYPES.FETCH_INVOICES_REPORT,
    cancelToken,
    values,
    callback,
  });
}

export function downloadInvoices(params, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/invoices`,
    actionType: ACTION_TYPES.DOWNLOAD_INVOICES_REPORT_DATA,
    values: params,
    blobHeader: "blob",
    fileName: "invoicesreport.xls",
    callback: callback,
  });
}

export function downloadInvoicesPackage(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/package`,
    actionType: ACTION_TYPES.DOWNLOAD_INVOICES_PACKAGE,
    params,
    blobHeader: "blob",
    fileName: "invoices.zip",
    callback: callback,
  });
}

export function fetchContractPayments(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contractPayments`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_REPORT,
    cancelToken,
    params,
    callback,
  });
}

export function deleteInvoice(cancelToken, invoiceId, values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/invoice/${invoiceId}/delete`,
    actionType: ACTION_TYPES.DELETE_INVOICE,
    cancelToken,
    values,
    callback,
  });
}

export function downloadContractPayments(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contractPayments`,
    actionType: ACTION_TYPES.DOWNLOAD_CONTRACT_PAYMENTS_REPORT,
    blobHeader: "blob",
    fileName: "contractpayments.xls",
    cancelToken,
    params,
    callback,
  });
}

export function fetchBalances(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/balance`,
    actionType: ACTION_TYPES.FETCH_BALANCES_REPORT,
    cancelToken,
    params,
    callback,
  });
}

export function downloadBalances(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/balance`,
    actionType: ACTION_TYPES.DOWNLOAD_BALANCES_REPORT,
    blobHeader: "blob",
    fileName: "balances.xls",
    cancelToken,
    params,
    callback,
  });
}

export function downloadCourseStudentReport(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/course/students`,
    actionType: ACTION_TYPES.DOWNLOAD_COURSE_STUDENTS,
    params: { format: "xls", ...params },
    blobHeader: "blob",
    fileName: "CourseStudents.xls",
    callback,
  });
}

export function downloadCourseDiplomas(params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/course/diplomas`,
    actionType: ACTION_TYPES.DOWNLOAD_COURSE_DIPLOMAS,
    params: { format: "zip", ...params },
    blobHeader: "blob",
    fileName: "CourseDiplomas.zip",
    callback,
  });
}

export function downloadCashFlowReport(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/cashflow`,
    actionType: ACTION_TYPES.DOWNLOAD_CASH_FLOW_REPORT,
    blobHeader: "blob",
    fileName: "CashFlowReport.xls",
    cancelToken,
    params,
    callback,
  });
}

export function downloadCourseDaysAngGroupsReport(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/course/days-groups`,
    actionType: ACTION_TYPES.DOWNLOAD_COURSE_DAYS_GROUPS_REPORT,
    blobHeader: "blob",
    fileName: "CourseDaysAndGroupsReport.xls",
    cancelToken,
    params,
    callback,
  });
}

export function downloadPayUOrderReport(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/payu-orders`,
    actionType: ACTION_TYPES.DOWNLOAD_PAYU_ORDER_REPORT,
    blobHeader: "blob",
    fileName: "PayUOrders.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function downloadContractsReport(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contracts`,
    actionType: ACTION_TYPES.DOWNLOAD_CONTRACTS_REPORT,
    blobHeader: "blob",
    fileName: "Contracts.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function downloadResignedContractsReport(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contracts/resigned`,
    actionType: ACTION_TYPES.DOWNLOAD_CONTRACTS_REPORT,
    blobHeader: "blob",
    fileName: "Resigned_contracts.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function downloadFinishedContractsReport(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contracts/finished`,
    actionType: ACTION_TYPES.DOWNLOAD_CONTRACTS_REPORT,
    blobHeader: "blob",
    fileName: "Finished_contracts.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function downloadMonthlyContractsReport(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contracts/monthly`,
    actionType: ACTION_TYPES.DOWNLOAD_MONTHLY_CONTRACTS_REPORT,
    blobHeader: "blob",
    fileName: "Monthly_contracts.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function downloadAmendmentContractsReport(
  cancelToken,
  params,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contracts/amendment`,
    actionType: ACTION_TYPES.DOWNLOAD_CONTRACTS_REPORT,
    blobHeader: "blob",
    fileName: "Amendment_contracts.xlsx",
    cancelToken,
    params,
    callback,
  });
}

export function fetchContractPaymentsNotes(
  cancelToken,
  contractId,
  source,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/${contractId}/notes?source=${source}`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_NOTE,
    cancelToken,
    callback,
  });
}

export function addContractPaymentsNote(
  cancelToken,
  contractId,
  values,
  callback = null,
) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/contract/${contractId}/note`,
    actionType: ACTION_TYPES.ADD_CONTRACT_PAYMENTS_NOTE,
    cancelToken,
    values,
    callback,
  });
}

export function editContractPaymentsNote(noteId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/note/${noteId}`,
    actionType: ACTION_TYPES.EDIT_CONTRACT_PAYMENTS_NOTE,
    values,
    callback: callback,
  };
  return Client.actionPUT(config);
}

export function removeContractPaymentsNote(
  cancelToken,
  noteId,
  callback = null,
) {
  return Client.actionDELETE({
    path: `${restApiConfig.API_URL}/contract/note/${noteId}`,
    actionType: ACTION_TYPES.REMOVE_CONTRACT_PAYMENTS_NOTE,
    cancelToken,
    callback,
  });
}

export function fetchContractPaymentsFilters(params) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/report/contractPayments/filters`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_FILTERS,
    params,
  });
}

export function addContractComment(contractId, commentId) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/contract/comment/${contractId}`,
    values: { commentId: commentId },
  });
}
export function sendNps(npsId) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/contract/poll/send/${npsId}`,
  });
}

export function updateInvoiceStatus(newStatuses, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/invoice/send-status`,
    values: newStatuses,
    actionType: ACTION_TYPES.UPDATE_INVOICE_STATUS,
    callback,
  });
}

export function getInvoicePossibleStatus() {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/status/possible-places`,
    actionType: ACTION_TYPES.GET_INVOICE_STATUS_POSSIBLE_PLACES,
  });
}
export function getInvoicePossibleSendStatus() {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/send-status/possible-places`,
    actionType: ACTION_TYPES.GET_INVOICE_SEND_STATUS_POSSIBLE_PLACES,
  });
}
