import { EventFormValues } from "../Types/EditEventForm";
import { FormikErrors } from "formik";

export default (fields: EventFormValues) => {
  const errors: FormikErrors<EventFormValues> = {};

  const { date, startTime } = fields;

  if (!date) {
    errors.date = "Enter event's date";
  }

  if (!startTime) {
    errors.startTime = "Enter event's start time";
  }

  return errors;
};
