import React, { Component } from "react";
import { connect } from "react-redux";
import { sendLog } from "../../Modules/App/Actions";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import StackTrace from "stacktrace-js";
import PropTypes from "prop-types";
import dateHelper from "../../Helpers/DateHelper";
import * as Sentry from "@sentry/browser";
import { translate } from "react-i18next";
import { init } from "@sentry/browser";
import config from "../../Configs/restApiConfig";

class ErrorBoundry extends Component {
  static propTypes = {
    getState: PropTypes.object.isRequired,
    sendLog: PropTypes.func.isRequired,
    children: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorName: null,
      line: null,
      fetched: false,
    };
    this.dataSent = false;
  }

  componentDidCatch(error) {
    const { getState } = this.props;
    config.sentryDsn &&
      init({
        dsn: config.sentryDsn,
      });
    StackTrace.fromError(error).then(_error => {
      this.setState({
        errorName: _error[0].fileName,
        line: _error[0].lineNumber,
        fetched: true,
      });
      const objToSend = {
        error: error.toString(),
        fileName: _error[0].fileName,
        url: window.location.href,
        line: _error[0].lineNumber,
        date: dateHelper()
          .get()
          .toDate(),
      };
      if (this.dataSent === false) {
        config.sentryDsn &&
          Sentry.withScope(scope => {
            Object.keys(objToSend).forEach(identifier => {
              scope.setExtra(identifier, objToSend[identifier]);
            });
            scope.setUser({ id: getState.userId, userName: getState.userName });
            Sentry.captureException(error);
          });
        this.dataSent = true;
      }
    });
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    const { error, errorName, line, fetched } = this.state;
    const { getState, t } = this.props;
    if (this.state.hasError) {
      return (
        <div className="errorBoundary">
          <h1>
            {getState.userName.replace(/ .*/, "")}
            {t(" we have a small issue...")}
          </h1>
          <h2>{error.toString()}</h2>
          {config.sentryDsn && (
            <h3 onClick={() => Sentry.showReportDialog()}>
              <span className="errorBoundary__message">{t("Tell us")}</span>{" "}
              {t("what went wrong")}
            </h3>
          )}
          <p>{fetched ? errorName : "please wait..."}</p>
          <p>Line of code: {fetched ? line : "please wait..."}</p>
          <a href={window.location.origin} className="btn btn-dark btn-margin">
            Powrót
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendLog }, dispatch);
}

function mapStateToProps(state) {
  return { getState: state };
}
export default withRouter(
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(ErrorBoundry),
);
