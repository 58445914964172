import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDictionary,
  updateDictionary,
  createDictionaryItem,
} from "../Actions";
import RoomsDictionary from "../Components/RoomsDictionary";
import validate from "../Validators/RoomValidator";

const FORM_NAME = `DICTIONARY_ROOMS`;

let DictionaryRoomsForm = props => <RoomsDictionary {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "rooms");
  let {
    dictionaries: { rooms, cities },
  } = state;
  return {
    values,
    initialValues: { rooms },
    dictionaries: { cities },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
      createDictionaryItem,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryRoomsForm),
);
