import PersonForm from "../../Modules/Persons/Validators/PersonValidator";
import CompanyForm from "../../Modules/Companies/Validators/NewCompanyValidator";
import { acceptAnyValue } from "./CommonValidators";
import {
  isPolishPeselValid,
  isPolishRegonValid,
  isPolishNIPValid,
} from "./InstancesValidators/PL_Validators";
import {
  isRomanianCNPValid,
  isRomanianTaxIDValid,
} from "./InstancesValidators/RO_Validators";

export const ValidatorFactory = {
  Validator(instance) {
    const fieldsValidatorDefault = {
      isPeselValid: acceptAnyValue,
      isTaxIDValid: acceptAnyValue,
      isCompanyIdValid: acceptAnyValue,
    };

    const isInstanceKeyInConfig = instance in this.instancesRules;

    const fieldsValidator = isInstanceKeyInConfig
      ? this.instancesRules[instance].fields
      : fieldsValidatorDefault;

    const formsValidator = isInstanceKeyInConfig
      ? this.instancesRules[instance].forms
      : {};

    const TenantValidator = {
      ...this.defaultRules.fields,
      ...fieldsValidator,
      forms: {
        ...this.defaultRules.forms,
        ...formsValidator,
      },
    };
    return TenantValidator;
  },
  defaultRules: {
    fields: {},
    forms: {
      PersonForm,
      CompanyForm,
    },
  },
  instancesRules: {
    PL: {
      fields: {
        isPeselValid: isPolishPeselValid,
        isCompanyIdValid: isPolishRegonValid,
        isTaxIDValid: isPolishNIPValid,
      },
    },
    RO: {
      fields: {
        isPeselValid: isRomanianCNPValid,
        isTaxIDValid: isRomanianTaxIDValid,
        isCompanyIdValid: acceptAnyValue,
      },
    },
    ID: {
      fields: {
        isPeselValid: acceptAnyValue,
        isTaxIDValid: acceptAnyValue,
        isCompanyIdValid: acceptAnyValue,
      },
    },
    ES: {
      fields: {
        isPeselValid: acceptAnyValue,
        isTaxIDValid: acceptAnyValue,
        isCompanyIdValid: acceptAnyValue,
      },
    },
    AT: {
      fields: {
        isPeselValid: acceptAnyValue,
        isTaxIDValid: acceptAnyValue,
        isCompanyIdValid: acceptAnyValue,
      },
    },
  },
};
