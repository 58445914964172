import React from "react";

import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContractTestFrom from "../Components/ContractTestForm";
import {
  fetchQuestionnaire,
  saveQuestionnaire,
  clearQuestionnaire,
  fetchInitialTestResult,
} from "../Actions";

const FORM_NAME = "CONTRACT_TEST";

let ContractTest = props => <ContractTestFrom {...props} />;

function mapStateToProps(state) {
  let { notifications, contract, questionnaire, initialTestsResults } = state;
  return {
    contract,
    notifications,
    initialTestsResults,
    initialValues: { answers: questionnaire },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQuestionnaire,
      clearQuestionnaire,
      saveQuestionnaire,
      fetchInitialTestResult,
    },
    dispatch,
  );
}
export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: { answers: null },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractTest),
);
