import { companiesList } from "./List";
import { companyDetails } from "./Details";
import { companyFromGus } from "./CompanyFromGus";
import { contacts } from "./Contacts";

export const companiesInitState = {
  companiesList: { items: [], counter: 0 },
  companyDetails: null,
  companyFromGus: {},
  companyContacts: [],
};

export function companiesReducer(state = companiesInitState, action) {
  return {
    companiesList: companiesList(state.companiesList, action),
    companyDetails: companyDetails(state.companyDetails, action),
    companyFromGus: companyFromGus(state.companyFromGus, action),
    companyContacts: contacts(state.companyContacts, action),
  };
}
