import React, { Component } from "react";
import { translate } from "react-i18next";
import checkPersonIsEmployee from "../../../Helpers/CheckPersonIsEmployee";
import checkPersonIsStudent from "../../../Helpers/CheckPersonIsStudent";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import PERSON_TYPES from "../../../Enums/PersonTypes";

class PersonBaseDateUserType extends Component {
  static propTypes = {
    t: PropTypes.func,
    fields: PropTypes.shape(),
    employeeTypeId: PropTypes.number,
    types: PropTypes.array,
    meta: PropTypes.object,
    toggleEmployeeTab: PropTypes.func,
    toggleTestTab: PropTypes.func,
    required: PropTypes.bool,
    disabledOptions: PropTypes.array,
    shouldDisabled: PropTypes.bool,
    studentTypeId: PropTypes.number,
    isNew: PropTypes.bool,
  };
  constructor(props) {
    super(props);

    const { t } = props;
    this.cancelToken = getCancelToken();
    this.translates = {
      1: t("Lead"),
      2: t("Employee"),
      3: t("Student"),
    };
  }
  componentWillUnmount() {
    this.cancelToken.cancel();
  }
  render() {
    const {
      fields,
      employeeTypeId,
      studentTypeId,
      types,
      meta: { error, submitFailed },
      toggleEmployeeTab,
      toggleTestTab,
      required,
      disabledOptions,
      shouldDisabled,
      t,
      isNew,
    } = this.props;
    const classes = submitFailed && error ? "has-error" : "";
    const chosenTypes = fields.getAll() ? [...fields.getAll()] : [];
    if (isNew && !chosenTypes.includes(PERSON_TYPES.LEAD)) {
      fields.push(PERSON_TYPES.LEAD);
    }
    return (
      <div className={`form-group row ${classes}`}>
        <div className={`col-sm-4 col-md-3 ${required ? "required" : ""}`}>
          <label htmlFor="">{t("Type")}</label>
        </div>
        <div className="col-sm-8 col-md-9">
          {types.map(type => {
            const { id, type: name } = type;
            const indexInFields = chosenTypes.indexOf(id);
            const disableByDisabledOptions = disabledOptions.indexOf(id) > -1;
            return (
              <label
                key={id}
                htmlFor={`user-type--${name}`}
                className={`checkbox-inline${
                  disableByDisabledOptions ? " disabled" : ""
                }`}
              >
                <input
                  type="checkbox"
                  name={`user-type--${name}`}
                  id={`user-type--${name}`}
                  checked={indexInFields > -1}
                  onChange={() => {
                    indexInFields > -1
                      ? fields.remove(indexInFields)
                      : fields.push(id);
                    checkPersonIsEmployee(
                      types.map(type => {
                        return type.id;
                      }),
                      employeeTypeId,
                    ) && toggleEmployeeTab();
                    checkPersonIsStudent(
                      types.map(type => {
                        return type.id;
                      }),
                      studentTypeId,
                    ) && toggleTestTab();
                  }}
                  disabled={shouldDisabled || disableByDisabledOptions}
                />
                <span>{this.translates[id]}</span>
              </label>
            );
          })}
          {submitFailed && error && <span className="help-block">{error}</span>}
        </div>
      </div>
    );
  }
}

export default translate()(PersonBaseDateUserType);
