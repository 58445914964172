import { productVersionsReducer } from "./ProductVersions";
import { citiesReducer } from "./Cities";
import { courseModesReducer } from "./CourseModes";
import { mentorsForCourseReducer } from "./MentorsForCourse";
import { courseSpecialistReducer } from "./CourseSpecialist";
import { currentCourseReducer } from "./CurrentCourse";
import { contractsListReducer } from "./ContractsList";
import { freeLecturesAndRoomsReducer } from "./FreeLecturesAndRooms";
import { packagesReducer } from "./Packages";
import { lecturersReducer } from "./Lecturers";

const initialCourse = {
  numberOfPlaces: 16,
  courseSchedule: {
    days: [],
    startHour: 0,
    endHour: 0,
    courseMode: null,
  },
  courseCalendar: { lectures: [] },
};

export const coursesInitState = {
  contractsList: { items: [], counter: 0 },
  products: { counter: 0, items: [] },
  cities: { counter: 0, items: [] },
  lecturers: [],
  packages: [],
  courseModes: [],
  mentors: [],
  courseSpecialists: { counter: 0, items: [] },
  course: initialCourse,
  freeLecturesAndRooms: null,
};

export function coursesReducer(state = coursesInitState, action) {
  return {
    products: productVersionsReducer(state.products, action),
    cities: citiesReducer(state.cities, action),
    courseModes: courseModesReducer(state.courseModes, action),
    mentors: mentorsForCourseReducer(state.mentors, action),
    courseSpecialists: courseSpecialistReducer(state.courseSpecialists, action),
    course: currentCourseReducer(state.course, action),
    contractsList: contractsListReducer(state.contractsList, action),
    packages: packagesReducer(state.packages, action),
    freeLecturesAndRooms: freeLecturesAndRoomsReducer(
      state.freeLecturesAndRooms,
      action,
    ),
    lecturers: lecturersReducer(state.lecturers, action),
  };
}
