import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import Loader from "../../../Common/Components/Loader/Loader";
import { RenderText } from "../../../Forms/forms";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import ReactTable from "react-table";
import { Check } from "react-feather";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import EditDictionaryField from "../Helpers/EditDictionaryField";

class EmployeeAvailability extends Component {
  static propTypes = {
    fetchDictionary: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    updateDictionary: PropTypes.func,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    const { fetchDictionary } = this.props;
    this.cancelToken = getCancelToken();
    this.state = { employeeAvailability: [] };

    fetchDictionary(this.cancelToken, {
      path: "/availability/type",
      actionType: ACTION_TYPES.FETCH_EMPLOYEE_AVAILABILITY,
    });

    this.renderEditField = this.renderEditField.bind(this);
    this.updateEmployeeDictionary = this.updateEmployeeDictionary.bind(this);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      initialValues: { employeeAvailability },
      initialize,
    } = this.props;

    if (employeeAvailability !== nextProps.initialValues.employeeAvailability) {
      this.setState({
        employeeAvailability: nextProps.initialValues.employeeAvailability,
      });
      initialize(nextProps.initialValues);
    }
  }

  enableEditMode(row) {
    const { employeeAvailability } = this.state;

    !employeeAvailability[row.index].editMode
      ? (employeeAvailability[row.index].editMode = [])
      : null;
    !employeeAvailability[row.index].editMode.includes(row.column.id) &&
      employeeAvailability[row.index].editMode.push(row.column.id);

    this.setState({ employeeAvailability });
  }

  renderEditField(row) {
    const { employeeAvailability } = this.state;
    const value = getNestedObject(this.props.values[row.index], row.column.id);

    return (
      <div className="editField">
        {employeeAvailability[row.index] &&
        employeeAvailability[row.index].editMode &&
        employeeAvailability[row.index].editMode.indexOf(row.column.id) >= 0 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`employeeAvailability[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
            <div
              onClick={() =>
                this.updateEmployeeDictionary(row.index, row.column.id)
              }
              className="dictionary-text__button"
            >
              <Check size={23} />
            </div>
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  }

  updateEmployeeDictionary(index, employeeAvailabilityId) {
    const {
      values,
      initialValues,
      updateDictionary,
      fetchDictionary,
    } = this.props;
    const { employeeAvailability } = this.state;
    const arraySpot = employeeAvailability[index].editMode.findIndex(
      element => element === employeeAvailabilityId,
    );

    employeeAvailability[index].editMode.splice(arraySpot, 1);
    this.setState({ employeeAvailability });

    if (
      values &&
      values[index][employeeAvailabilityId] !==
        initialValues.employeeAvailability[index][employeeAvailabilityId]
    ) {
      const updates = { name: values[index].name };

      updateDictionary(
        `/availability/type/${values[index].id}`,
        updates,
        () => {
          fetchDictionary(this.cancelToken, {
            path: "/availability/type",
            actionType: ACTION_TYPES.FETCH_EMPLOYEE_AVAILABILITY,
          });
        },
      );
    }
  }

  render() {
    const {
      initialValues: { employeeAvailability },
      values,
      t,
    } = this.props;

    return employeeAvailability.length === 0 ? (
      <Loader />
    ) : (
      <div className="fade-in">
        <ReactTable
          noDataText={t("No records found")}
          data={values}
          columns={[
            {
              Header: t("No."),
              accessor: "id",
              Cell: row => <span>{row.index + 1}.</span>,
              resizable: false,
              width: 50,
            },
            {
              Header: t("NameItem"),
              accessor: "name",
              Cell: this.renderEditField,
            },
          ]}
          defaultPageSize={10}
          showPagination={true}
        />
      </div>
    );
  }
}

export default translate()(EmployeeAvailability);
