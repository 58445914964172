import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { Features } from "../../../Common/Utils/Tenant";
import {
  downloadCashFlowReport,
  downloadCourseDaysAngGroupsReport,
  downloadPayUOrderReport,
  downloadContractsReport,
  downloadResignedContractsReport,
  downloadFinishedContractsReport,
  downloadAmendmentContractsReport,
  downloadMonthlyContractsReport,
} from "../Actions";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import { DateTimePicker } from "react-widgets";
import Time from "../../../Enums/Time";
import DateHelper from "../../../Helpers/DateHelper";
import moment from "moment";

class XLSReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWarning: false,
    };
  }
  #cancelToken = getCancelToken();
  static propTypes = {
    t: PropTypes.func,
  };

  handleDownload = report => {
    switch (report) {
      case "cash-flow":
        downloadCashFlowReport(this.#cancelToken, { format: "xls" })(() => {});
        break;
      case "course-days-groups":
        downloadCourseDaysAngGroupsReport(this.#cancelToken, { format: "xls" })(
          () => {},
        );
        break;
      case "payu-order":
        downloadPayUOrderReport(this.#cancelToken, { format: "xls" })(() => {});
        break;
      case "contracts":
        downloadContractsReport(this.#cancelToken, { format: "xls" })(() => {});
        break;
      case "resigned_contracts":
        downloadResignedContractsReport(this.#cancelToken, { format: "xls" })(
          () => {},
        );
        break;
      case "amendment_contracts":
        downloadAmendmentContractsReport(this.#cancelToken, { format: "xls" })(
          () => {},
        );
        break;
      case "finished_contracts":
        downloadFinishedContractsReport(this.#cancelToken, { format: "xls" })(
          () => {},
        );
        break;
      case "monthly_contracts":
        if (
          document.getElementById("contracts_from_input").value !== "" &&
          document.getElementById("contracts_to_input").value !== ""
        ) {
          this.setState({ showWarning: false });
          downloadMonthlyContractsReport(this.#cancelToken, {
            format: "xls",
            from: moment(
              document.getElementById("contracts_from_input").value +
                "12:00:00",
              "DD.MM.YYYY HH:ii:ss",
            ).unix(),
            to: moment(
              document.getElementById("contracts_to_input").value + "12:00:00",
              "DD.MM.YYYY HH:ii:ss",
            ).unix(),
          })(() => {});
        } else {
          this.setState({ showWarning: true });
        }

        break;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="row">
          {havePermissions([permissions.REPORT_FINANCE_XLS]) && (
            <button
              className="btn btn-dark pull-left mr-1"
              onClick={() => this.handleDownload("cash-flow")}
            >
              {t("Cash flow report")}
            </button>
          )}

          {havePermissions([permissions.REPORT_COURSE_DAYS_AND_GROUPS]) && (
            <button
              className="btn btn-dark pull-left mr-1"
              onClick={() => this.handleDownload("course-days-groups")}
            >
              {t("Course days and groups report")}
            </button>
          )}
          {Features.payu_order && (
            <button
              className="btn btn-dark pull-left mr-1"
              onClick={() => this.handleDownload("payu-order")}
            >
              {t("PayU Orders report")}
            </button>
          )}
          <button
            className="btn btn-dark pull-left mr-1"
            onClick={() => this.handleDownload("contracts")}
          >
            {t("Contracts")}
          </button>
          <button
            className="btn btn-dark pull-left mr-1"
            onClick={() => this.handleDownload("resigned_contracts")}
          >
            {t("Resigned contracts")}
          </button>
          <button
            className="btn btn-dark pull-left mr-1"
            onClick={() => this.handleDownload("amendment_contracts")}
          >
            {t("Amendment contract")}
          </button>
          <button
            className="btn btn-dark pull-left mr-1"
            onClick={() => this.handleDownload("finished_contracts")}
          >
            {t("Finished contracts")}
          </button>
        </div>
        {havePermissions([permissions.REPORT_MONTHLY_CONTRACTS]) && (
          <div className="row">
            <hr />
            <div className="col-md-3">
              <DateTimePicker
                id="contracts_from"
                time={false}
                date={true}
                format={Time.DATE_FORMAT}
                step={30}
                min={DateHelper("2017-01-01")
                  .get()
                  .toDate()}
                max={DateHelper("2099-12-31")
                  .get()
                  .toDate()}
                // onChange={
                //   customChange
                //     ? (event, value) => customChange(event, value)
                //     : value => onChange(dateHelper(value).getFormatted())
                // }
                views={["month", "year"]}
              />
            </div>
            <div className="col-md-3">
              <DateTimePicker
                id="contracts_to"
                time={false}
                date={true}
                format={Time.DATE_FORMAT}
                step={30}
                min={DateHelper("2017-01-01")
                  .get()
                  .toDate()}
                max={DateHelper("2099-12-31")
                  .get()
                  .toDate()}
                // onChange={value => onChange(DateHelper(value).getFormatted())}
                views={["month", "year"]}
              />
            </div>
            <button
              className="btn btn-dark pull-left mr-1"
              onClick={() => this.handleDownload("monthly_contracts")}
            >
              {t("Monthly contracts")}
            </button>
            {this.state.showWarning && <h5>{t("Insert dates")}</h5>}
          </div>
        )}
      </>
    );
  }
}

export default translate()(XLSReports);
