import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";

class Maintenance extends Component {
  static propTypes = {
    t: PropTypes.func,
  };
  render() {
    const { t } = this.props;
    return (
      <main>
        <div className="container">
          <header className="main-header">
            <h1 className={`text-center`}>
              {t(`We have a temporary technical break`)}
            </h1>
          </header>
          <section>
            <article>
              <h2 className={`text-center`}>
                {t(`Sit back and watch the movie.`)}
              </h2>
              <h4 className={`text-center`}>
                {t(`It's possible we'll be back when you're done watching.`)}
              </h4>
            </article>
            <article className={`text-center`}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/iDbyYGrswtg"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </article>
          </section>
        </div>
      </main>
    );
  }
}

export default translate()(Maintenance);
