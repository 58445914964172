export default values => {
  const { slaves } = values;
  const errors = {
    slaves: [],
  };
  if (slaves) {
    slaves.forEach((slave, index) => {
      if (slave && !slave.finishStatus) {
        errors.slaves[index] = {
          ...errors.slaves[index],
          finishStatus: "Choose finish status",
        };
      }
    });
  }
  return errors;
};
