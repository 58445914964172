export default fields => {
  let errors = {};
  const { employeeCompetencies } = fields;
  let competencyErrors = [];
  employeeCompetencies &&
    employeeCompetencies.forEach((competency, index) => {
      competencyErrors[index] = {};
      if (!competency.name) {
        competencyErrors[index] = {
          ...competencyErrors[index],
          name: "Enter the competency",
        };
      }
    });
  errors.employeeCompetencies = { ...competencyErrors };
  return errors;
};
