import React, { Component } from "react";
import { DropdownList } from "react-widgets";
import moment from "moment";
import _ from "lodash";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import TIME from "../../../Enums/Time";

class StudentHistoryList extends Component {
  static propTypes = {
    fetchDictionary: PropTypes.func,
    getContractLogs: PropTypes.func,
    contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contractLogs: PropTypes.array,
    dictionaries: PropTypes.object,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      eventGroupId: 0,
      pageNumber: 0,
      visibleElements: 2,
      contractLogsCount: 0,
    };
    const { fetchDictionary, getContractLogs, contractId } = props;
    const { FETCH_LOG_EVENT_GROUP } = ACTION_TYPES;

    fetchDictionary(this.cancelToken, {
      path: "/logeventgroup",
      actionType: FETCH_LOG_EVENT_GROUP,
    });
    getContractLogs(this.cancelToken, { contractId });
  }
  componentDidUpdate(prevProps) {
    if (this.props.contractLogs.length !== prevProps.contractLogs.length) {
      this.setState({
        contractLogsCount: Math.floor(
          this.props.contractLogs.length / this.state.visibleElements,
        ),
      });
    }
  }

  renderHistory() {
    const sortedContractLogs = _.reverse(
      _.sortBy(this.props.contractLogs, ["date"]),
    );
    const startPagin = this.state.pageNumber * this.state.visibleElements;
    const endPagin = startPagin + this.state.visibleElements;

    return sortedContractLogs
      .map(historyRecord => {
        return (
          <li key={historyRecord.id}>
            <div>
              <label>{historyRecord.title}</label>
              <p>
                {moment(historyRecord.date).format(TIME.DATE_IN_HISTORY)}{" "}
                {historyRecord.triggeredUser.email}
              </p>
              <p dangerouslySetInnerHTML={{ __html: historyRecord.message }} />
            </div>
          </li>
        );
      })
      .slice(startPagin, endPagin);
  }

  loadHistory = value => {
    this.setState({ eventGroupId: value.id }, () => {
      const { getContractLogs, contractId } = this.props;
      const { eventGroupId } = this.state;
      getContractLogs(
        this.cancelToken,
        {
          contractId,
          eventGroupId,
        },
        response => {
          const actualData = response.data.data;
          this.setState({
            pageNumber: 0,
            contractLogsCount: Math.floor(
              actualData && actualData.length / this.state.visibleElements,
            ),
          });
        },
      );
    });
  };

  handlePaginationType = (event, type) => {
    event.preventDefault();
    const { pageNumber } = this.state;

    if (type === "prev") {
      this.setState({ pageNumber: pageNumber - 1 });
    }
    if (type === "next") {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  render() {
    const {
      contractLogs,
      dictionaries: { logEventGroups },
      t,
    } = this.props;
    const { pageNumber, contractLogsCount } = this.state;

    return (
      <div className="studentHistory">
        <h3>{t("Student's history")}</h3>
        <DropdownList
          data={
            logEventGroups && logEventGroups.length > 0
              ? [
                  {
                    id: 0,
                    name: t("All"),
                  },
                  ...logEventGroups,
                ]
              : []
          }
          textField="name"
          valueField="id"
          onChange={this.loadHistory}
          value={this.state.eventGroupId}
          placeholder="Wybierz typ historii"
        />
        {contractLogs && contractLogs.length > 0 ? (
          <ul className="studentHistory__list list-unstyled">
            {this.renderHistory()}
          </ul>
        ) : (
          <span className="studentHistory__noRecords">
            {t("No entries found")}
          </span>
        )}
        {contractLogs.length > this.state.visibleElements && (
          <nav>
            <ul className="pagination">
              {pageNumber !== 0 && (
                <li className="page-item page-item-prev">
                  <a
                    className="page-link"
                    href="#"
                    onClick={event => this.handlePaginationType(event, "prev")}
                  >
                    {t("Previous")}
                  </a>
                </li>
              )}
              {contractLogsCount - 1 >= pageNumber && (
                <li className="page-item page-item-next">
                  <a
                    className="page-link"
                    href="#"
                    onClick={event => this.handlePaginationType(event, "next")}
                  >
                    {t("Next")}
                  </a>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    );
  }
}

export default translate()(StudentHistoryList);
