import React from "react";

type ProductTabsProps = {
  isNew?: boolean;
  activeTab: number;
  onTabClick: (tabIndex: number) => void;
  t: (key: string) => string;
};

const ProductTabs: React.FunctionComponent<ProductTabsProps> = ({
  t,
  isNew,
  activeTab,
  onTabClick,
}): React.ReactElement => {
  const renderTabs = [
    t("Product"),
    t("Module"),
    t("Surveys"),
    t("Additional events"),
  ].map((element, tabIndex) => (
    <li
      key={tabIndex}
      className={`react-tabs__tab ${
        activeTab === tabIndex ? "react-tabs__tab--selected" : ""
      }`}
      onClick={() => {
        if (tabIndex === 0 || !isNew) {
          onTabClick(tabIndex);
        }
      }}
    >
      {element}
    </li>
  ));

  return (
    <div className="react-tabs custom-container">
      <ul>{renderTabs}</ul>
    </div>
  );
};

export default ProductTabs;
