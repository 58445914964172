import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";

const PayU = ({ t, generatePayULink, order }) => {
  const clipboardCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(order.url);
    }
  };

  return (
    <div>
      {order && !!order.url ? (
        <div>
          <div id="order-url" className="order-url" title={order.url}>
            {order.url}
          </div>
          <button
            id="copy-to-clipboard"
            className="btn"
            onClick={clipboardCopy}
          >
            <span>{t("Copy to clipboard")}</span>
          </button>
        </div>
      ) : (
        <>
          {order && order.amount && (
            <h5 id="payu-amount">
              {`${t("Amount")} (${t("gross")}): `}
              {renderAmountWithCurrency({
                amount: order.amount.amount,
                currency: order.amount.currency,
              })}
            </h5>
          )}
          <button
            id="generate-url-button"
            type="button"
            className="btn btn-dark"
            onClick={generatePayULink}
          >
            {t("Generate PayU link")}
          </button>
        </>
      )}
    </div>
  );
};

PayU.propTypes = {
  t: PropTypes.func,
  generatePayULink: PropTypes.func,
  order: PropTypes.object,
};

export default translate()(PayU);
