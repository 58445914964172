import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDictionary, updateDictionary } from "../Actions";
import CoursesKinds from "../Components/CoursesKinds";

const FORM_NAME = "DICTIONARY_COURSES_KINDS";

let DictionaryCoursesKinds = props => <CoursesKinds {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "coursesKinds");
  let {
    dictionaries: { coursesKinds },
  } = state;
  return {
    values,
    initialValues: { coursesKinds: coursesKinds },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryCoursesKinds),
);
