import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchPersons(cancelToken, config, callback = null) {
  const params = {
    type: null,
    search: null,
    pageNo: 1,
    pageAmount: 10,
    emptyEmails: 0,
    ...config,
  };
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/get`,
    actionType: ACTION_TYPES.FETCH_PERSONS,
    params,
    callback,
    cancelToken,
  });
}
export function fetchPersonTypes(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/dict/person/type/get`,
    actionType: ACTION_TYPES.FETCH_PERSON_TYPES,
    callback,
    cancelToken,
  });
}
export function createPerson(values = {}, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/person/add`,
    actionType: ACTION_TYPES.SAVE_PERSON,
    values: values,
    callback: callback,
  };

  return Client.actionPOST(config);
}

export function updatePerson(values, personId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/person/edit/${personId}`,
    actionType: ACTION_TYPES.UPDATE_PERSON,
    values,
    callback,
  };

  return Client.actionPUT(config);
}

export function fetchPerson(cancelToken, personId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/get/${personId}`,
    actionType: ACTION_TYPES.FETCH_PERSON,
    callback,
    cancelToken,
  });
}

export function getMentorForProduct(
  cancelToken,
  productVersionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/getMentorForProduct/${productVersionId}`,
    actionType: ACTION_TYPES.GET_MENTOR_FOR_PRODUCT,
    cancelToken,
    callback,
  });
}

export function fetchMentors(cancelToken, config = {}, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/get`,
    actionType: ACTION_TYPES.FETCH_MENTORS,
    params: {
      mentorOnly: 1,
      pageAmount: 50,
      ...config,
    },
    callback,
    cancelToken,
  });
}

export function getCoursesSpecialists(
  cancelToken,
  config = {},
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/get`,
    actionType: ACTION_TYPES.GET_COURSES_SPECIALISTS,
    params: {
      courseSpecialistOnly: 1,
      pageAmount: 50,
      ...config,
    },
    callback,
    cancelToken,
  });
}

export function fetchLecturesCompetencies(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/person/lecturers/all/competencies`,
    actionType: ACTION_TYPES.FETCH_LECTURES_COMPETENCIES,
    callback,
    cancelToken,
  });
}

export function updateUserRole(options = {}, callback) {
  const values = {
    userId: null,
    roles: [],
    ...options,
  };
  return Client.actionPUT({
    values,
    callback,
    actionType: ACTION_TYPES.ADD_ROLES_TO_USER,
    path: `${restApiConfig.API_URL}/acl/roles/user`,
  });
}

export function resetPerson() {
  return {
    type: ACTION_TYPES.RESET_PERSON,
    payload: {},
  };
}

export function fetchOffices() {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/office`,
    actionType: ACTION_TYPES.GET_OFFICES,
  });
}

export function changePassword(options = {}) {
  const values = {
    email: null,
    password: null,
    ...options,
  };

  return Client.actionPUT({
    values,
    actionType: ACTION_TYPES.CHANGE_PASSWORD,
    path: `${restApiConfig.API_URL}/user/change-password`,
  });
}
