import React, { Component } from "react";
import { Form, Field, FieldArray } from "redux-form";
import { translate } from "react-i18next";
import PropTypes from "prop-types";

import Loader from "../../../Common/Components/Loader/Loader";
import { RenderTextArea } from "../../../Forms/forms";
import getCancelToken from "../../../Helpers/GetCancelToken";

class ContractTestForm extends Component {
  static propTypes = {
    fetchQuestionnaire: PropTypes.func,
    fetchInitialTestResult: PropTypes.func,
    contract: PropTypes.object,
    saveQuestionnaire: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    clearQuestionnaire: PropTypes.func,
    t: PropTypes.func,
    match: PropTypes.object,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    initialTestsResults: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.renderQuestions = this.renderQuestions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderQuestionOptions = this.renderQuestionOptions.bind(this);
    const { fetchQuestionnaire, contract, fetchInitialTestResult } = this.props;
    fetchQuestionnaire(this.cancelToken, contract.contract.id);
    fetchInitialTestResult(this.cancelToken, contract.student.person.id);
  }

  onSubmit(values) {
    let answers = values.answers.map(answer => {
      let cleanAnswer = { ...answer };
      cleanAnswer.questionId = answer.question.id;
      delete cleanAnswer["question"];
      return cleanAnswer;
    });
    const {
      saveQuestionnaire,
      contract: {
        contract: { id },
      },
    } = this.props;
    saveQuestionnaire({
      courseContractId: id,
      answers,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const hasAnswers = nextProps.initialValues.answers.length > 0;
    const receivedDifferentAnswers =
      this.props.initialValues.answers !== nextProps.initialValues.answers;
    if (
      nextProps.initialValues.answers &&
      hasAnswers &&
      receivedDifferentAnswers
    ) {
      this.props.initialize(nextProps.initialValues);
    }
  }
  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.clearQuestionnaire();
  }

  renderQuestionOptions(field) {
    const { options, fields } = field;
    const chosenOptions = fields.getAll() ? [...fields.getAll()] : [];

    return options.map(option => {
      const indexInFields = chosenOptions.findIndex(
        item => item.id === option.id,
      );
      return (
        <div className={"col-sm-2"} key={option.id}>
          <label
            className="checkbox-inline"
            key={option.id}
            htmlFor={`questionOption--${option.id}`}
          >
            <input
              name={`questionOption--${option.id}`}
              id={`questionOption--${option.id}`}
              type="checkbox"
              checked={indexInFields > -1}
              onChange={() => {
                indexInFields > -1
                  ? fields.remove(indexInFields)
                  : fields.push(option);
              }}
            />
            <span>{option.optionText}</span>
          </label>
        </div>
      );
    });
  }

  renderQuestions({ fields }) {
    const questions = fields.getAll();
    return fields.map((question, index) => {
      return (
        <div key={questions[index].question.id} className="row form-group">
          <div className="col-xs-12 col-sm-3">
            <span>{questions[index].question.questionHeader}</span>
            <label>{questions[index].question.questionText}</label>
          </div>
          <div className="col-xs-12 col-sm-9">
            <div className="row">
              <FieldArray
                name={`${question}.checkedOptions`}
                options={questions[index].question.questionOptions}
                checkedOptions={questions[index].checkedOptions}
                component={this.renderQuestionOptions}
              />
            </div>
            <Field
              name={`${question}.answer`}
              label=""
              under={true}
              component={RenderTextArea}
            />
          </div>
        </div>
      );
    });
  }

  renderInitialTestResults() {
    const { initialTestsResults, t } = this.props;
    return initialTestsResults.map((test, index) => {
      return (
        <div key={index} className="row form-group">
          <div className="col-xs-12 col-sm-3">
            <span>{t(`${test.type.name} test score`)}</span>
          </div>
          <div id={test.type.name} className="col-xs-12 col-sm-9">
            <p>
              {test.type.id === 3
                ? `${Math.floor(test.result.accuracy)}%`
                : `${Math.floor(test.result.score_percentage * 100)}%`}
            </p>
          </div>
        </div>
      );
    });
  }

  render() {
    const { initialValues, t, initialTestsResults } = this.props;
    return (!initialValues && !initialTestsResults) ||
      !initialValues.answers ||
      initialValues.answers.length === 0 ? (
      <Loader />
    ) : (
      <Form
        id={`contractTestForm`}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        {initialTestsResults && initialTestsResults.length === 0 ? (
          <div className="row form-group">
            <div className="col-xs-12 col-sm-3">
              <span>{t(`Knowledge test`)}</span>
            </div>
            <div className="col-xs-12 col-sm-9">
              <p>{t("No results")}</p>
            </div>
          </div>
        ) : (
          initialTestsResults && this.renderInitialTestResults()
        )}
        {<FieldArray name={`answers`} component={this.renderQuestions} />}
        <div className="row">
          <div className="col-xs-12">
            <button
              type="submit"
              className="btn btn-dark"
              disabled={this.props.submitting}
            >
              {t("Submit")}
            </button>
            <button
              type="button"
              onClick={() => {
                this.props.initialize(this.props.initialValues);
              }}
              className="btn btn-link btn-regular"
            >
              {t("Clear changes")}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default translate()(ContractTestForm);
