import ACTION_TYPES from "../../Enums/ActionTypes";

export function balance(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BALANCE:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function invoiceAmount(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INVOICE_AMOUNT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function balanceSettings(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BALANCE_SETTINGS:
    case ACTION_TYPES.SAVE_BALANCE_SETTINGS:
      return action.payload.data.data;
    default:
      return state;
  }
}
