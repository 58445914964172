import React from "react";
import {
  User,
  Phone,
  Mail,
  DollarSign,
  ShoppingCart,
  FileText,
  Compass,
} from "react-feather";
import ListElement from "../../../../Common/Components/ListElement/ListElement";
import { translate } from "react-i18next";
import CONTRACT from "../../../../Enums/Contract";
import CONTRACT_STATUSES from "../../../../Enums/ContractStatuses";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../../Common/Utils/Acl";
import ContractPaymentStatusCodes from "../../../../Enums/ContractPaymentStatusCodes";
import ContractFinishReason from "../Contracts/ContractFinishReason";

const CourseStudentsListElement = ({ student, counter, t }) => {
  const {
    name,
    surname,
    phone,
    email,
    id: contractId,
    diploma,
    status,
    paymentStatus,
    finishReason,
    transferFrom,
    finishDate,
    transferCourse,
    package: packageType,
    shop,
  } = student;
  const iconSize = { size: 15 };
  const renderEmail = (
    <span>
      <Mail {...iconSize} />
      {email}
    </span>
  );
  const renderPhone = (
    <span>
      <Phone {...iconSize} />
      {phone}
    </span>
  );

  function createContractStatus() {
    const {
      CONTRACT_SIGNED,
      CONTRACT_SEND,
      DURING_RECRUITMENT,
      CONTRACT_DENIED,
    } = CONTRACT_STATUSES;
    if (!havePermissions([permissions.STUDENT_CONTRACT_STATUS_VIEW])) {
      return "";
    }
    switch (status.code) {
      case CONTRACT_SIGNED:
        return (
          <span className="contract_signed text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case CONTRACT_SEND:
        return (
          <span className="contract_send text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case DURING_RECRUITMENT:
        return (
          <span className="during_recruitment text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case CONTRACT_DENIED:
        return (
          <span className="text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      default:
        return (
          <span>
            <FileText {...iconSize} /> {status.name}
          </span>
        );
    }
  }

  const paymentStatusElement =
    paymentStatus.code === ContractPaymentStatusCodes.EXPIRED ? (
      <span className="paymentStatusDetails--alert">{paymentStatus.name}</span>
    ) : (
      paymentStatus.name
    );

  return (
    <ListElement
      clickable={true}
      icon={<User size={30} />}
      className="product-item--with-border"
      path={`/persons/contract/${contractId}`}
      id={contractId}
    >
      <h4 className={"student-list-counter"}>{counter}</h4>
      <div className="student">
        <h4 className="text student__info">
          <strong className="text student__info--dark">
            {name} {surname}
          </strong>
          {renderPhone}
          {renderEmail}
        </h4>
        <p className="student__info ">
          <span>
            <DollarSign {...iconSize} /> {paymentStatusElement}
          </span>
          {shop && (
            <span className="contract_shop text--bold">
              <ShoppingCart {...iconSize} /> {t("Bought in shop")}
            </span>
          )}
          {status && createContractStatus()}
          {packageType && (
            <span>
              <DollarSign {...iconSize} />{" "}
              {packageType && packageType.name.toLowerCase()}
            </span>
          )}
          {transferFrom && (
            <span>
              <Compass {...iconSize} />
              {`${t("Transfer from")} ${transferFrom}`}
            </span>
          )}
          {finishReason ? (
            <ContractFinishReason
              t={t}
              finishReason={finishReason}
              diploma={diploma}
              finishDate={finishDate}
              transferCourse={transferCourse}
            />
          ) : status && status.id === CONTRACT.STATUSES.CONTRACT_SIGNED ? (
            <span className="contract_signed text--bold">
              <Compass {...iconSize} />
              {t("On the course")}
            </span>
          ) : null}
        </p>
      </div>
    </ListElement>
  );
};

CourseStudentsListElement.propTypes = {
  t: PropTypes.func,
  counter: PropTypes.number,
  student: PropTypes.object,
};

export default translate()(CourseStudentsListElement);
