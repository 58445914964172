import React from "react";
import CustomModal from "./CustomModal";
import { translate } from "react-i18next";
import PropTypes from "prop-types";

const ConfirmModal = props => {
  const { onRequestConfirm, body, customButtons } = props.config;
  const { t } = props;
  return (
    <CustomModal className="confirm-modal" {...props}>
      {body}
      <div className="confirm-modal__actions">
        {customButtons ? (
          <div>
            {customButtons.acceptButton &&
              customButtons.acceptButton.length > 0 && (
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => {
                    onRequestConfirm();
                    props.onRequestClose();
                  }}
                >
                  {customButtons.acceptButton}
                </button>
              )}
            {customButtons.cancelButton &&
              customButtons.cancelButton.length > 0 && (
                <button
                  type="button"
                  className="btn btn-regular"
                  onClick={() => props.onRequestClose()}
                >
                  {customButtons.cancelButton}
                </button>
              )}
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                onRequestConfirm();
                props.onRequestClose();
              }}
            >
              {t("Yes")}
            </button>
            <button
              type="button"
              className="btn btn-regular"
              onClick={() => props.onRequestClose()}
            >
              {t("No")}
            </button>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

ConfirmModal.propTypes = {
  config: PropTypes.object,
  t: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default translate()(ConfirmModal);
