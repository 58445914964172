export default {
  unifyToCreate: values => {
    const { course, relatedFees, module } = values;
    return {
      course: course.id,
      relatedFees: !!relatedFees,
      module,
    };
  },
  unifyToUpdate: values => {
    return values.finishStatus ? { finishStatus: values.finishStatus } : false;
  },
};
