import { connect } from "react-redux";
import CompanyDetails from "../Components/CompanyDetails";
import React from "react";
import { bindActionCreators } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import validate from "../Validators/CompanyValidator";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { success } from "react-notification-system-redux";

import {
  addNewCompany,
  fetchCompany,
  fetchContacts,
  fetchMeta,
  refreshCompanyData,
  updateCompany,
  updateContacts,
  updateInvoiceContact,
} from "../Actions/CompaniesActions";

const Details = props => <CompanyDetails {...props} />;
const FORM_NAME = "COMPANY";
const selector = formValueSelector(FORM_NAME);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMeta,
      fetchDictionary,
      fetchCompany,
      updateCompany,
      addNewCompany,
      fetchContacts,
      updateContacts,
      refreshCompanyData,
      updateInvoiceContact,
      success,
    },
    dispatch,
  );

const mapStateToProps = (appState, ownProps) => {
  const companyId = Number(ownProps.match.params.companyId);
  const values = selector(
    appState,
    "name",
    "regonNr",
    "taxNr",
    "taxRate",
    "isTaxNrEUActive",
    "address",
  );

  const {
    companies: { companyDetails, companyContacts: contacts },
    dictionaries: { cities, countries },
    ajaxCallInProgressCount,
    __metadata: {
      contract: { taxRates },
    },
  } = appState;

  return {
    isLoading: ajaxCallInProgressCount > 0,
    isNew: !companyId,
    values,
    company: companyDetails,
    companyId,
    contacts,
    dictionaries: {
      cities,
      countries,
      taxRates,
    },
  };
};

export default reduxForm({
  validate,
  form: FORM_NAME,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Details),
);
