import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import {
  fetchProductEvents,
  addProductEvent,
  removeProductEvent,
  fetchProductVersionsMeta,
} from "../Actions";
import { translate } from "react-i18next";
import Events from "../Components/Events";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchProductEvents,
      addProductEvent,
      removeProductEvent,
      fetchProductVersionsMeta,
    },
    dispatch,
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    productEvents,
    ajaxCallInProgressCount,
    productVersionsMeta: {
      lectureParticipants: participants,
      lectureTypes: types,
    },
  } = state;

  return {
    ...ownProps,
    productEvents,
    participants,
    types,
    isLoading: ajaxCallInProgressCount > 0,
  };
};

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Events);
