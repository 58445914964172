import { translate } from "react-i18next";
import { XCircle } from "react-feather";
import React from "react";
import { DropdownList } from "react-widgets";
import { RenderError, renderFieldsHelper } from "../FormikUtils";
import { FieldProps } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";

type UnderType = {
  containerClassName?: string;
  fieldClassName?: string;
  wrapperClassName?: string;
};

type FormikSelectProps<T> = FieldProps &
  InjectedTranslateProps & {
    label: string;
    id?: string;
    onClearCallback?: () => void;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    under?: UnderType;
    dropdownConfig: {
      data: Array<T>;
      textField?: null | string;
      valueField?: string;
      messages?: {
        emptyList: string;
      };
      optionComponent?: () => void;
      filter?: false | "startsWith" | "endsWith" | "contains";
      placeholder: string;
      onChange?: (item: T) => void;
    };
  };

const RenderSelectToTranslate: React.FunctionComponent<
  FormikSelectProps<unknown>
> = props => {
  const { classes, displayError, error } = renderFieldsHelper(props);

  const {
    label,
    dropdownConfig: { placeholder, onChange: customChange },
    t,
    disabled,
    id,
    field,
    form,
    onClearCallback,
    under,
    className,
  } = props;
  return (
    <div
      id={id}
      className={`form-group ${className} ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div
        className={
          under ? (under.wrapperClassName ? under.wrapperClassName : "row") : ""
        }
      >
        {label && (
          <div
            className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
              !label || label.length === 0 ? "hidden" : ""
            }`}
          >
            <label htmlFor="">{label}</label>
          </div>
        )}
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <DropdownList
              {...props.dropdownConfig}
              name={field.name}
              disabled={disabled}
              placeholder={placeholder ? placeholder : t("Select")}
              onChange={
                customChange
                  ? item => customChange(item)
                  : item => form.setFieldValue(field.name, item)
              }
              value={field.value}
            />
            {onClearCallback && (
              <div onClick={onClearCallback} className="form__clear-button">
                <XCircle size={15} />
              </div>
            )}
          </div>
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};
export const RenderSelect = translate()(RenderSelectToTranslate);
