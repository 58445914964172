import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchBalance,
  saveBalanceSettings,
  fetchBalanceSettings,
  fetchInvoiceAmount,
  updateIncomeAutoInvoice,
  updateIncomeComment,
} from "../Actions";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { deleteIncome, updateInvoiceStatus } from "../../Reports/Actions";
import { downloadInvoice, fetchInvoices } from "../../Invoices/Actions";
import BalancePreview from "../Components/BalancePreview";

const Balance = props => <BalancePreview {...props} />;

function mapStateToProps(state) {
  const { balance, dictionaries, invoices, balanceSettings } = state;
  return { balance, dictionaries, invoices, balanceSettings };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBalance,
      fetchBalanceSettings,
      saveBalanceSettings,
      fetchDictionary,
      deleteIncome,
      downloadInvoice,
      fetchInvoices,
      fetchInvoiceAmount,
      updateIncomeAutoInvoice,
      updateIncomeComment,
      updateInvoiceStatus,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Balance);
