import _ from "lodash";

export default fields => {
  const errors: Record<string, unknown> = {};
  const modulesArrayErrors = [];
  const { productModules } = fields;

  productModules &&
    productModules.forEach((module, index) => {
      const moduleErrors: Record<string, unknown> = {
        packages: "",
        prices: [],
      };
      const lectureArrayErrors = [];
      if (!module.name) {
        moduleErrors.name = "Enter the module's name";
      }

      if (!module.installment) {
        moduleErrors.installment = "Choose the number of instalments";
      }
      if (!module.packages || module.packages.length === 0) {
        moduleErrors.packages = "You must choose at least one package";
      }
      if (module.prices && module.prices.length > 0) {
        module.prices.forEach((modulePackagePrice, index) => {
          moduleErrors.prices[index] = {};
          if (
            _.has(modulePackagePrice, "student.amount") &&
            Number(modulePackagePrice.student.amount) < 0
          ) {
            moduleErrors.prices[index].student = {
              amount: "The module's price can't be negative",
            };
          }
          if (
            _.has(modulePackagePrice, "thirdParty.amount") &&
            Number(modulePackagePrice.thirdParty.amount) < 0
          ) {
            moduleErrors.prices[index].thirdParty = {
              amount: "The module's price can't be negative",
            };
          }
        });
      }
      if (module.id > 0 && module.lectures && module.lectures.length > 0) {
        module.lectures.forEach((lecture, _index) => {
          const lectureErrors: Record<string, string> = {};
          if (!lecture.name) {
            lectureErrors.name = "Enter the lecture's name";
          }
          if (!lecture.duration || lecture.duration <= 0) {
            lectureErrors.duration = "Enter the lecture's duration";
          }
          if (!lecture.courseDay) {
            lectureErrors.courseDay = "Enter the course's day";
          }
          if (!lecture.type) {
            lectureErrors.type = "Choose type of lecture";
          }
          if (!lecture.participants) {
            lectureErrors.participants = "Set the participants";
          }
          if (Object.keys(lectureErrors).length) {
            lectureArrayErrors[_index] = lectureErrors;
          }
        });
      }

      if (lectureArrayErrors.length) {
        moduleErrors.lectures = lectureArrayErrors;
      }

      if (Object.keys(moduleErrors).length) {
        modulesArrayErrors[index] = moduleErrors;
      }
    });

  if (modulesArrayErrors.length) {
    errors.productModules = modulesArrayErrors;
  }

  return errors;
};
