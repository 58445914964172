import React, { Component } from "react";
import { Form, Field } from "redux-form";
import { RenderSelect, RenderText } from "../../../Forms/forms";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PriceField from "../../../Forms/PriceField";
import { Features, Tenant } from "../../../Common/Utils/Tenant";
import AmountField from "../../../Forms/AmountField";

class RefundForm extends Component {
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      isSubmitting: false,
    };
  }

  changeSide = ({ value }) => {
    this.setState({ side: value }, () => {
      this.props.change("side", value);
    });
  };

  componentDidMount() {
    const { currencies, change } = this.props;
    const [defaultCurrency] = currencies;

    if (defaultCurrency) {
      change("currency", defaultCurrency.name);
    }
  }

  onSubmit = formData => {
    const { balanceId, addRefund, closePopup } = this.props;
    const {
      description,
      correction,
      currency,
      amountGross,
      invoiceId,
    } = formData;

    const data = {
      balanceId,
      amount: {
        amount: amountGross,
        currency,
      },
      description,
      correction,
      invoice: invoiceId,
    };

    const successCallback = resolve => {
      this.setState({ isSubmitting: false });

      if (resolve) {
        const afterSubmit = true;
        closePopup(afterSubmit);
      }
    };

    this.setState({ isSubmitting: true }, () =>
      addRefund(data).then(successCallback),
    );
  };

  render() {
    const { isSubmitting } = this.state;
    const { t, handleSubmit, currencies, invoices } = this.props;

    const invoiceSelectConfig = {
      data: invoices || [],
      placeholder: t("Invoice"),
      textField: "nr",
      valueField: "id",
    };

    const currencySelectConfig = {
      data: currencies || [],
      placeholder: t("Currency"),
      textField: "name",
      valueField: "name",
    };

    return (
      <Form id="add-new-refund-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className={`col-12`}>
          <Field
            under
            component={RenderText}
            label={t("Refund title")}
            name="description"
          />
        </div>
        <Tenant
          component={
            <div>
              <div className={`col-12`}>
                <Field
                  under
                  component={RenderText}
                  label={t("Correction")}
                  name="correction"
                />
              </div>
              <div className={`col-xs-12`}>
                <Field
                  under
                  name="invoiceId"
                  component={RenderSelect}
                  label={t("Invoice")}
                  dropdownConfig={invoiceSelectConfig}
                />
              </div>
            </div>
          }
          feature={Features.invoices}
        />
        <div className={`col-xs-6 padding__l0`}>
          <AmountField
            t={t}
            under
            name="amountGross"
            component={PriceField}
            label={t("Refund amount")}
          />
        </div>
        <div className={`col-xs-6 margin__t25`}>
          <Field
            required="required"
            under
            name="currency"
            component={RenderSelect}
            dropdownConfig={currencySelectConfig}
          />
        </div>

        <div className={`col-12 clear-both`}>
          <button
            onClick={handleSubmit(this.onSubmit)}
            type="button"
            id="add-refund-form-submit-button"
            className="btn btn-dark"
            disabled={isSubmitting}
          >
            {t("Add refund")}
          </button>
        </div>
      </Form>
    );
  }
  static propTypes = {
    t: PropTypes.func,
    priceGrossCalculator: PropTypes.func,
    priceNetCalculator: PropTypes.func,
    handleSubmit: PropTypes.func,
    change: PropTypes.func,
    data: PropTypes.object,
    values: PropTypes.object,
    addRefund: PropTypes.func,
    closePopup: PropTypes.func,
    currencies: PropTypes.array,
    invoices: PropTypes.array,
    balanceId: PropTypes.number,
  };
}

export default translate()(RefundForm);
