import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "../Validators/InvoiceEditValidator";
import {
  fetchInvoiceReceivers,
  fetchLegalBasis,
  saveInvoice,
  updateInvoiceBuyerAddress,
} from "../Actions";
import { priceGrossCalculator } from "../../Products/Actions";

const FORM_NAME = "INVOICE_EDIT_FORM";
import InvoiceEditForm from "../Components/InvoiceEditForm";
import { fetchTenantSettings } from "../../App/Actions";

let InvoiceEdit = props => <InvoiceEditForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const { tenantSettings, invoiceReceivers, invoiceLegalBasis } = state;
  const values = selector(
    state,
    "description",
    "vatRate",
    "amountGross",
    "sellDate",
    "issueMonth",
    "paymentType",
    "receiver",
    "client",
    "incomeable",
    "legalBasis",
    "hasLegalBasis",
  );

  return {
    values,
    invoiceReceivers,
    tenantSettings,
    invoiceLegalBasis,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveInvoice,
      priceGrossCalculator,
      fetchInvoiceReceivers,
      fetchTenantSettings,
      updateInvoiceBuyerAddress,
      fetchLegalBasis,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  touchOnChange: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InvoiceEdit),
);
