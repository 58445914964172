import React from "react";
import PersonForm from "../Components/PersonForm";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchDictionary,
  fetchDictionaryOnlyTeach,
} from "../../Dictionaries/Actions";
import {
  createPerson,
  updatePerson,
  fetchPerson,
  updateUserRole,
  resetPerson,
  getCoursesSpecialists,
  fetchOffices,
} from "../Actions";
import validate from "../Validators/PersonValidator";
import { fetchLecturesForImport } from "../../Products/Actions";
import { passErrorToStore, clearErrorFromStore } from "../../App/Actions";
import {
  getRolesList,
  getUserPermissions,
  forgetUser,
  getPermissionsList,
} from "../../Auth/Actions";

const FORM_NAME = "Person";
const selector = formValueSelector(FORM_NAME);

const PersonFormWithConnect = props => <PersonForm {...props} />;

function mapStateToProps(state) {
  const {
    studentContracts,
    person,
    personPermissions,
    dictionaries,
    lecturesForImport,
    employeeTypeId,
    studentTypeId,
    showError,
    roles,
    coursesSpecialists,
    onlyTeachCities,
    permissionsList,
    offices,
  } = state;
  const values = selector(
    state,
    "typeIds",
    "name",
    "surname",
    "isCourseSpecialist",
    "contractTypeId",
    "sex",
    "pesel",
    "otherDocument",
    "emails",
    "phones",
    "streetAddress",
    "cityNameAddress",
    "countryAddress",
    "zipCodeAddress",
    "streetContactAddress",
    "cityContactAddress",
    "countryContactAddress",
    "zipCodeContactAddress",
    "linkedin",
    "github",
    "comment",
    "contractTypeId",
    "contractStartDate",
    "contractEndDate",
    "courseModeIds",
    "availabilityTypeId",
    "isMentor",
    "competenciesIds",
    "additionalCompetenciesIds",
    "roles",
    "cityNameContactAddress",
    "courseSpecialist",
    "activeCities",
    "userId",
    "userEnabled",
    "bankAccount",
    "offices",
  );
  const validationErrors = state.form[FORM_NAME]
    ? state.form[FORM_NAME].syncErrors
    : {};
  return {
    initialValues: person,
    person,
    dictionaries,
    studentContracts,
    lecturesForImport,
    validationErrors,
    employeeTypeId,
    studentTypeId,
    showError,
    values,
    roles,
    personPermissions,
    coursesSpecialists,
    onlyTeachCities,
    permissionsList,
    offices,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPerson,
      updatePerson,
      fetchPerson,
      fetchDictionary,
      fetchDictionaryOnlyTeach,
      fetchLecturesForImport,
      passErrorToStore,
      clearErrorFromStore,
      getRolesList,
      getUserPermissions,
      getPermissionsList,
      updateUserRole,
      resetPerson,
      getCoursesSpecialists,
      forgetUser,
      fetchOffices,
    },
    dispatch,
  );
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    competenciesIds: [],
    additionalCompetenciesIds: [],
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PersonFormWithConnect),
);
