import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchDictionary, updateDictionary } from "../Actions";
import CoursesModes from "../Components/CoursesModes";

const FORM_NAME = "DICTIONARY_COURSES_MODES";

let DictionaryCoursesModes = props => <CoursesModes {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "coursesModes");
  let {
    dictionaries: { coursesModes },
  } = state;
  return {
    values,
    initialValues: { coursesModes: coursesModes },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryCoursesModes),
);
