import React from "react";

import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "../Validators/AdditionalFeeValidator";
import {
  priceGrossCalculator,
  priceNetCalculator,
} from "../../Products/Actions";
import { addAdditionalPayment } from "../Actions";

const FORM_NAME = "CONTRACT_ADDITIONAL_FEE";

import AdditionalFee from "../Components/AdditionalFee";

let ContractAdditionalFee = props => <AdditionalFee {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(
    state,
    "type",
    "description",
    "amountGross",
    "deadline",
    "balanceId",
  );

  return {
    values,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      priceGrossCalculator,
      priceNetCalculator,
      addAdditionalPayment,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractAdditionalFee),
);
