import _ from "lodash";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchEmployees(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_EMPLOYEES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function fetchPersons(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSONS:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}
export function fetchMentors(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MENTORS: {
      let mentors = [...state, ...action.payload.data.data.items];
      return [..._.uniqBy(mentors, "id")];
    }
    default:
      return state;
  }
}

const initialPerson = {
  competenciesIds: [],
  additionalCompetenciesIds: [],
  typeIds: [],
  courseModeIds: [],
  emails: [""],
  phones: [""],
};
export function fetchPerson(state = initialPerson, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSON:
      return action.payload.data.data;
    case ACTION_TYPES.RESET_PERSON:
      return initialPerson;
    case ACTION_TYPES.UPDATE_PERSON:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function fetchPersonTypes(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSON_TYPES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function getMentorForProduct(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_MENTOR_FOR_PRODUCT:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function getCoursesSpecialists(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_COURSES_SPECIALISTS: {
      let courseSpecialists = [...state, ...action.payload.data.data.items];
      return [..._.uniqBy(courseSpecialists, "id")];
    }
    default:
      return state;
  }
}

export function getLecturersForProductVersion(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_LECTURERS_FOR_PRODUCT_VERSION:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getPermissionsForPerson(
  state = {
    permissions: [],
    roles: [],
  },
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_PERSON_PERMISSIONS:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}
export function fetchOffices(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_OFFICES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
