export default {
  "Lecture rooms": "Sale szkoleniowe",
  "Course types": "Rodzaje kursów",
  "Course modes": "Tryby kursów",
  "Employees availability": "Dostępność pracowników",
  "Employee data": "Dane pracownika",
  "Basic data": "Dane podstawowe",
  "Basic Price": "Cena Podstawowy",
  "Professional Price": "Cena Profesjonalny",
  "Premium Price": "Cena Premium",
  "Ultimate Price": "Cena Pewna Praca",
  Payers: "Płatnicy",
  "Add room": "Dodaj salkę",
  Courses: "Kursy",
  courses: "kursów",
  Products: "Produkty",
  Persons: "Osoby",
  Dictionaries: "Słowniki",
  "Withdrawal description": "Opis odstąpienia",
  "Courses, schedules & students management":
    "Zarządzanie kursami, harmonogramami i kursantami",
  "Log out": "Wyloguj",
  "Add new course": "Dodaj nowy kurs",
  "Date range": "Zakres dat",
  "Download Timeline": "Pobierz Oś Czasu",
  "Download Report": "Pobierz Raport",
  "Download Extended Report": "Pobierz Rozszerzony Raport",
  "File is generating": "Trwa generowanie pliku",
  "Add dates range": "Proszę wskazać zakres dat",
  clear: "wyczyść",
  Cities: "Miasta",
  City: "Miasto",
  Modes: "Tryby",
  Mode: "Tryb",
  Statuses: "Statusy",
  Mentors: "Mentorzy",
  Mentor: "Mentor",
  "Course specialists": "Opiekunowie",
  "Course Specialist": "Opiekun kursu",
  Product: "Produkt",
  Publication: "Publikacja",
  "Start date": "Rozpoczęcie",
  "End date": "Zakończenie",
  "Cancel date": "Data odwołania",
  "Number of students": "Liczba kursantów",
  "Number of places": "Liczba miejsc",
  Submit: "Zapisz",
  Cancel: "Anuluj",
  Delete: "Usuń",
  "Clear changes": "Wyczyść zmiany",
  "Cancel course": "Odwołaj kurs",
  From: "Od",
  To: "Do",
  Set: "Ustaw",
  "Lecture hours": "Godziny prowadzenia zajęć",
  Select: "Wybierz",
  Module: "Moduł",
  Day: "Dzień",
  Lecture: "Wykład",
  Lecturer: "Wykładowca",
  Room: "Sala",
  "Number of hours": "Liczba godzin",
  "Day of the week": "Dzień tygodnia",
  Settlement: "Rozliczenie",
  "Creating schedule...": "Obliczanie harmonogramu...",
  "No date for schedule": "Brak danych do harmonogramu",
  Settings: "Ustawienia",
  Students: "Kursanci",
  Questionnaires: "Ankiety",
  Free: "Wolne",
  Overbooking: "Overbooking",
  "Add student": "Dodaj kursanta",
  "Add person": "Dodaj osobę",
  "Search student": "Szukaj kursanta",
  Help: "Pomoc",
  Type: "Typ",
  "Form contains errors": "Formularz posiada błędy",
  "Clear errors from the 'Basic Data' tab":
    "Usuń błędy w zakładce Dane Podstawowe",
  "Clear errors from the 'Employee Data' tab":
    "Usuń błędy w zakładce Dane pracownika",
  "New person": "Nowa osoba",
  "Are You sure You want to leave this page without saving the form?":
    "Czy na pewno chcesz opuścić stronę bez zapisywania formularza?",
  "Are You sure You want to leave withour saving?":
    "Czy na pewno chcesz wyjść bez zapisywania zmian?",
  "Are You sure You want to clear the changes made?":
    "Czy na pewno chcesz wyczyścić zmiany?",
  "Unless You save the changes, recovering then will not be possible":
    "Jeśli nie zapiszesz zmian nie będzie możliwości ich przywrócenia",
  "By clearing the changes You remove the possibility of recovering them":
    "Usuwając zmiany nie będzie możliwości ich przywrócenia",
  Yes: "Tak",
  No: "Nie",
  Comments: "Uwagi",
  "Zip code": "Kod pocztowy",
  Country: "Kraj",
  Address: "Adres",
  "Contact address": "Adres korespondencyjny",
  "Use as a contact address": "Użyj jako adresu korespondencyjnego",
  "Registered address": "Adres zamieszkania",
  Phone: "Telefon",
  Email: "Email",
  "Contact details": "Dane kontaktowe",
  "Document Nr": "Nr dokumentu",
  PIN: "PESEL",
  Sex: "Płeć",
  Female: "Kobieta",
  F: "K",
  Male: "Mężczyzna",
  M: "M",
  Surname: "Nazwisko",
  NamePerson: "Imię",
  "Choose a user type": "Wybierz typ użytkownika",
  Lead: "Potencjalny",
  Employee: "Pracownik",
  Student: "Kursant",
  Partnership: "Współpraca",
  "Contract type": "Typ umowy",
  "Contract expiration date": "Data zakończenia umowy",
  "Contract start date": "Data rozpoczęcia umowy",
  Availability: "Dostępność",
  "Wants to be a mentor": "Chce być mentorem",
  Competencies: "Kompetencje",
  "Additional Competencies": "Kompetencje dodatkowe",
  "Chosen Competencies": "Wybrane kompetencje",
  "Employee Permissions": "Profile uprawnień",
  "Payment status": "Status płatności",
  "Contract status": "Status umowy",
  "Payment date according to the contract": "Data płatności z umowy",
  ActiveRoom: "Aktywna",
  InactiveRoom: "Nieaktywna",
  Add: "Dodaj",
  Status: "Status",
  NameItem: "Nazwa",
  "No.": "L.P.",
  "UE VAT": "UE VAT",
  "% VAT": "% VAT",
  "Payer type": "Typ płatnika",
  REGON: "REGON",
  TIN: "NIP",
  "Add payer": "Dodaj płatnika",
  "No results": "brak wyników",
  "Writing test score": "Wynik testu pisanie",
  "English test score": "Wynik testu angielski",
  "Logic test score": "Wynik testu logika",
  Question: "Pytanie",
  Summary: "Podsumowanie",
  "Knowledge test and interview": "Test wiedzy i wywiad",
  Return: "Powrót",
  Invoice: "Faktura",
  "Booking date": "Data księgowania",
  "Transaction date": "Data transakcji",
  Amount: "Kwota",
  "Transfer title": "Tytuł przelewu",
  "Payment history - company": "Historia wpłat - firma",
  "Payment history - student": "Historia wpłat - kursant",
  Payer: "Płatnik",
  "Payment date": "Termin płatności",
  "Issue date": "Data wystawienia",
  "Sell date": "Data sprzedaży",
  Number: "Numer",
  "List of Invoices": "Lista faktur",
  Financing: "Finansowanie",
  "Schedule and payment status": "Harmonogram i status płatności",
  "Discount(%)": "Rabat (%)",
  "Number of agreement with LO": "Numer umowy z UP",
  "Company's account number (SWIFT)": "Konto bankowe firmy (SWIFT)",
  "Company's account number (IBAN)": "Konto bankowe firmy (IBAN)",
  "Student's account number (SWIFT)": "Konto bankowe kursanta (SWIFT)",
  "Student's account number (IBAN)": "Konto bankowe kursanta (IBAN)",
  "Company's sub-account": "Subkonto firmowe",
  "Student's sub-account": "Subkonto kursanta",
  "Bank accounts": "Konta bankowe",
  "Show student's history": "Pokaż historię kursanta",
  "Final status": "Status zakończenia",
  "Course end": "Zakończenie kursu",
  "Not shared": "Nie udostępniono",
  "Prework shared": "Udostępnienie preworku",
  Payments: "Płatności",
  Contract: "Umowa",
  "You will lose current table changes": "Utracisz aktualne zmiany w tabeli",
  "Are You sure You want to change the financing type?":
    "Czy na pewno chcesz zmienić typ finansowania?",
  "Are You sure You want to change the payer?":
    "Czy na pewno chcesz zmienić płatnika?",
  "You will lose the current table changes":
    "Utracisz wszystkie bieżące zmiany w harmonogramie",
  "Withdrawal date": "Data odstąpienia",
  "Reason for withdrawal": "Przyczyna odstąpienia",
  "Contract number": "Numer umowy",
  "Date of signing the contract": "Data podpisania umowy",
  "Contract send date": "Data wysłania umowy",
  "Payment within": "Płatność za",
  Paid: "Opłacona",
  "Waiting for DoL": "Oczekiwanie na UP",
  Expired: "Przeterminowana",
  "From which module": "Od którego modułu",
  "Reason for resigning": "Przyczyna rezygnacji",
  "Date of resignation": "Data rezygnacji",
  "Choose module": "Wybierz moduł",
  "Enter course's signature": "Wpisz sygnaturę kursu",
  "Show diploma": "Pokaż dyplom",
  "Show certificate": "Pokaż certyfikat",
  "Diploma's number": "Numer dyplomu",
  "After rebate": "Po rabacie",
  Awaiting: "Oczekiwana",
  Overpaid: "Nadpłata",
  "No obligations": "Brak zobowiązań",
  "Paid (gross)": "Zapłacone (brutto)",
  "To pay (gross)": "Do zapłaty (brutto)",
  "Module's price (gross)": "Cena modułu (brutto)",
  "Invoice Pos.": "Poz. faktury",
  "Paid by a third party (gross)": "Zapłacone strona trzecia (brutto)",
  "To pay by a third party (gross)": "Do zapłaty strona trzecia (brutto)",
  "Paid by the student (gross)": "Zapłacone kursant (brutto)",
  "To pay by the student (gross)": "Do zapłaty kursant (brutto)",
  day: "dzień",
  days: "dni",
  gross: "brutto",
  net: "netto",
  Mon: "Pn",
  Tue: "Wt",
  Wed: "Śr",
  Thu: "Cz",
  Fri: "Pt",
  Sat: "So",
  Sun: "Nie",
  "labor office": "urząd pracy",
  foundation: "fundacja",
  company: "firma",
  parp: "parp",
  power: "power",
  kfs: "kfs",
  open: "open",
  credit: "kredyt",
  "The payer has been added": "Płatnik został dodany",
  Code: "Kod",
  "VAT UE": "VAT UE",
  NO: "NIE",
  YES: "TAK",
  "Search in GUS": "Szukaj w GUS",
  "Company's name": "Nazwa firmy",
  independent: "samodzielne",
  mixed: "mieszany",
  "during recruitment": "w trakcie rekrutacji",
  "contract sent": "umowa wysłana",
  "contract signed": "umowa podpisana",
  "contract denied": "odstąpienie od umowy",
  "course cancelled": "kurs odwołany",
  "Transfer from": "Przeniesienie z",
  clearCommitments: "brak zobowiązań",
  wait: "oczekiwana",
  expired: "przeterminowana",
  overpay: "nadpłata",
  "waiting labor office": "oczekiwana UP",
  paid: "opłacona",
  lead: "potencjalny",
  employee: "pracownik",
  student: "kursant",
  Monday: "Poniedziałek",
  Tuesday: "Wtorek",
  Wednesday: "Środa",
  Thursday: "Czwartek",
  Friday: "Piątek",
  Saturday: "Sobota",
  Sunday: "Niedziela",
  "Enter the name": "Podaj nazwę",
  "Enter the street name": "Podaj ulicę",
  "Enter the zip code": "Podaj kod pocztowy",
  "Choose the city": "Wybierz miasto",
  "Choose product's kind": "Wybierz rodzaj produktu",
  "Enter product's name": "Podaj nazwę produktu",
  "Enter 3-letter long shortcut of the product's name":
    "Podaj trzyliterowy skrót nazwy produktu",
  "Choose a language": "Wybierz język",
  "Choose a currency": "Wybierz walutę",
  "Choose a city": "Wybierz miasto",
  "Choose the business owner": "Wybierz właściciela biznesowego",
  "Choose the essential owner": "Wybierz właściciela merytorycznego",
  "Enter the resignation fee": "Podaj kwotę rezygnacji",
  "Enter the transfer fee": "Podaj kwotę przeniesienia",
  "Enter the module's name": "Podaj nazwę modułu",
  "Choose the number of instalments": "Wybierz ilość rat",
  "Enter the module's price": "Podaj cenę modułu",
  "Enter the lecture's name": "Podaj nazwę wykładu",
  "Enter the lecture's duration": "Podaj czas trwania wykładu",
  "Enter the person's name": "Podaj imię",
  "Enter the surname": "Podaj nazwisko",
  "Choose sex": "Wybierz płeć",
  "Invalid National ID": "Błędny numer PESEL",
  "Choose the user's type": "Wybierz typ użytkownika",
  "Choose a country": "Wybierz kraj",
  "Enter a valid e-mail address formatted name@domain.com":
    "Podaj poprawny adres e-mail w formacie name@domain.com",
  "Enter a valid phone number": "Podaj poprawny numer telefonu",
  "Specify whether the employee is a Course Specialist":
    "Określ, czy pracownik jest Courses Specialist",
  "Specify the contract type": "Określ rodzaj umowy",
  "Specify the contract start date": "Określ datę rozpoczęcia umowy",
  "Specify the course mode": "Określ tryb",
  "Specify the availability": "Określ dostępność",
  "The contract end date cannot be earlier than the contract start date":
    "Data zakończenia umowy nie może być wcześniejsza od daty jej rozpoczęcia",
  Share: "Udost.",
  "Import lecture": "Importuj wykłady",
  "Add lecture": "Dodaj wykład",
  Lecturers: "Wykładowcy",
  Title: "Tytuł",
  "1 day = 8 hours": "1 dzień = 8 godzin",
  hour: "godz",
  Duration: "Czas trwania",
  "List of lectures": "Lista wykładów",
  "List of lectures, support and self work":
    "Lista wykładów, wsparcia i pracy własnej",
  "List of lecturers": "Lista wykładów",
  "List of lecturers with the required competences":
    "Lista wykładowców posiadających wymagane kompetencje",
  "No lectures": "Brak Wykładów",
  "Installment number": "Numer raty",
  Price: "Cena",
  "Add module": "Dodaj moduł",
  "Remove module": "Usuń moduł",
  "Module type": "Typ modułu",
  "Module name": "Nazwa modułu",
  Author: "Autor",
  "Are you sure you want to remove this version?":
    "Czy na pewno chcesz usunąć wersję?",
  "By removing this version, you will not be able to restore it":
    "Usuwając tę wersję nie będzie możliwości jej przywrócenia",
  "Product version history": "Historia wersji produktu",
  "Remove version": "Usuń wersję",
  "Copy version": "Kopiuj wersję",
  "Deactivate version": "Zdezaktywuj wersję",
  "Activate version": "Aktywuj wersję",
  "Deactivate product": "Dezaktywuj produkt",
  "Activate product": "Aktywuj produkt",
  "Save and activate": "Zapisz i aktywuj",
  Save: "Zapisz",
  "Remove errors from products tab": "Usuń błędy w zakładce produkty",
  "Remove errors from module tab": "Usuń błędy w zakładce moduły",
  "Course summary": "Podsumowanie Kursu",
  "Thematic scope": "Zakres Tematyczny",
  Language: "Język",
  "Charge for transfer": "Opłata za przeniesienie",
  "Charge for resignations": "Opłata za rezygnację",
  "Shortcut name": "Skrót nazwy",
  "Product name": "Nazwa produktu",
  "Product type": "Rodzaj produktu",
  Draft: "Roboczy",
  InactiveProduct: "Nieaktywny",
  Inactive: "Nieaktywny",
  ActiveProduct: "Aktywny",
  version: "wersja",
  Version: "Wersja",
  module: "moduł",
  Active: "Aktywna",
  "Import - Product": "Import - Produkt",
  "Essential owner": "Właściciel merytoryczny",
  "Bussines owner": "Właściciel biznesowy",
  "Course status": "Status kursu",
  "Course type": "Typ kursu",
  "Add new product": "Dodaj nowy produkt",
  "Bad account number format": "Zły format numeru konta",
  "Choose status": "Wybierz status",
  "Enter the date of signing the contract": "Podaj datę podpisania umowy",
  "Enter the reason for withdrawal": "Podaj przyczynę odstąpienia",
  "Enter the date of withdrawal from the contract":
    "Podaj datę odstąpienia od umowy",
  "Enter the date of sending the contract": "Podaj datę wysłania umowy",
  "Enter the customer's account number": "Podaj numer konta klienta",
  "Enter the customer's account number (IBAN)":
    "Podaj numer konta klienta (IBAN)",
  "Client's account number is too long (IBAN), allowed maximum 34 chars":
    "Zbyt długi numer konta klienta (IBAN), dozwolone maksymalnie 34 znaki",
  "Client's account number is too long (SWIFT), allowed maximum 11 chars":
    "Zbyt długi numer konta klienta (SWIFT), dozwolone maksymalnie 11 znaków",
  "Client's account number is too short (SWIFT), allowed minimum 8 chars":
    "Zbyt krótki numer konta klienta (SWIFT), dozwolone minimalnie 8 znaków",
  "Give the course signature": "Podaj sygnaturę kursu",
  "Give the module number": "Podaj numer modułu",
  "Give the reason of resignation": "Podaj przyczynę rezygnacji",
  "Give the date of resignation": "Podaj datę rezygnacji",
  "Give the from which module": "Podaj od którego modułu",
  "Choose the type of financing": "Wybierz typ finansowania",
  "Select the payer": "Wybierz płatnika",
  "Give the installment number": "Podaj numer raty",
  "Give the date": "Podaj datę",
  "Enter the amount": "Podaj kwotę",
  "Enter the module": "Podaj moduł",
  "Enter the number of places": "Podaj liczbę miejsc",
  "The amount can not be higher than the module price":
    "Kwota nie może być wyższa niż cena modułu",
  "Choose product": "Wybierz produkt",
  "Choose publication date": "Wybierz datę publikacji",
  "Choose the course mode": "Wybierz tryb kursu",
  "Choose the start date of course": "Wybierz datę rozpoczęcia kursu",
  "The number of seats should be greater than 0":
    "Liczba miejsc powinna być większa od 0",
  "Publication date it has to be before start date":
    "Data publikacji ma nastąpić przed datą startu",
  "Start date it has to be after publication date":
    "Data startu ma nastąpić po dacie publikacji",
  "At least one day should be checked":
    "Co najmniej jeden dzień powinien być zaznaczony",
  "Choose hour": "Wybierz godzinę",
  "The start hour should be less than the end hour":
    "Godzina rozpoczęcia powinna być mniejsza od godziny zakończenia",
  "Starting day of the course must overlap with lecture days":
    "Dzień rozpoczęcia kursu musi pokrywać się z dniami prowadzenia zajęć",
  "Enter the tax ID": "Podaj numer identyfikacji podatkowej",
  "Incorrect tax ID": "Nieprawidłowy numer identyfikacji podatkowej",
  "Choose payer type": "Wybierz typ płatnika",
  "Incorrect company ID": "Nieprawidłowy format numeru REGON",
  "Give the VAT rate": "Podaj stawkę VAT",
  "Give the street name": "Podaj ulicę",
  "Give the postal code": "Podaj kod pocztowy",
  "Give the name of the payer": "Podaj nazwę płatnika",
  "Give the % VAT": "Podaj % VAT",
  "% VAT can not be negative number": "% VAT nie może być liczbą ujemną",
  Timetable: "Harmonogram",
  Parameters: "Parametry",
  "New course": "Nowy kurs",
  "The lecturer is already assigned in the following courses":
    "Wykładowca jest już przypisany w poniższych kursach",
  "Reset Schedule": "Zresetuj harmonogram",
  "Course number": "Numer kursu",
  "Postpone counter": "Ilość przesunięć",
  "Reset schedule": "Zresetuj harmonogram",
  "Change schedule": "Zmiana harmonogramu",
  "Are You sure, You want to leave this page without saving the form?":
    "Czy na pewno chcesz opuścić stronę bez zapisywania formularza?",
  "Are You sure, You want to quit without saving?":
    "Czy na pewno chcesz wyjść bez zapisywania zmian?",
  "Are You sure, You want to clear the changes made":
    "Czy na pewno chcesz wyczyścić zmiany?",
  "The field's change will result in deletion of current schedule and all the changes":
    "Zmiana pola spowoduje usunięcie obecnego harmonogramu oraz wszystkich zmian",
  "The field's change will result in recalculation of the current schedule. Changes for specific lectures will be deleted":
    "Zmiana pola spowoduje ponowne przeliczenie obecnego harmonogramu. Zmiany dla poszczególnych wykładów zostaną usunięte.",
  "WARNING! Canceling the course will result in automatic termination of all contracts with students, calculation of reimbursements and the release of reservations for mentors and lecturers. This operation can not be undone! If you are sure you want to cancel the course click 'YES'.":
    "UWAGA! Odwołanie kursu spowoduje automatyczne rozwiązanie wszystkich umów z kursantami, naliczenie zwrotów oraz uwolnienie rezerwacji czasu mentorów i wykładowców. Tej operacji nie da się cofnąć! Jeśli masz pewność, że chcesz odwołać kurs kliknij `TAK`.",
  "After erasing the changes, there will be no possibility of getting them back":
    "Usuwając zmiany nie będzie możliwości ich przywrócenia",
  "WARNING! Moving forward will reset the course schedule during recording. Are You sure?":
    "UWAGA! Przejście dalej spowoduje zresetowanie harmonogramu kursu podczas zapisu. Czy jesteś pewny?",
  "Are You sure, You want to remove this version":
    "Czy na pewno chcesz usunąć wersję?",
  "Are You sure, You want to leave without saving the changes?":
    "Czy na pewno chcesz wyjść bez zapisywania zmian?",
  "Are You sure, You want to clear the changes?":
    "Czy na pewno chcesz wyczyścić zmiany?",
  "After removing this version, there will be no possibility of getting it back":
    "Usuwając tę wersję nie będzie możliwości jej przywrócenia",
  "Waiting labor office": "Oczekiwana UP",
  "New product": "Nowy produkt",
  Modules: "Moduły",
  "Before resignation": "Przed rezygnacją",
  "Before transfer": "Przed przeniesieniem",
  "Additional competencies": "Kompetencje dodatkowe",
  "Add competency": "Dodaj kompetencję",
  "Enter the competency": "Podaj nazwę kompetencji",
  "Give the number of module": "Podaj od którego modułu",
  "Give the number of contract with LO": "Podaj numer umowy z UP",
  "Choose country name": "Wybierz nazwę kraju",
  "Choose city name": "Wybierz nazwę miasta",
  "Street and number": "Ulica i numer",
  "During Recruitment": "Rekrutacja",
  "During recruitment": "Rekrutacja",
  "Contracts Sent": "Umowy wysłane",
  "Contracts sent": "Umowy wysłane",
  "Contracts Signed": "Umowy podpisane",
  Signed: "Podpisane",
  "Resignations and Transfers": "Rezygnacje i przeniesienia",
  Resignations: "Rezygnacje",
  "On the course": "W trakcie kursu",
  "diploma number": "numer certyfikatu",
  "Active account": "Konto aktywne",
  Filters: "Filtry",
  Sorting: "Sortowanie",
  "Sort by": "Sortuj po",
  "Signature, ascending": "Sygnaturze, rosnąco",
  "Signature, descending": "Sygnaturze, malejąco",
  "Start date, ascending": "Dacie rozpoczęcia, rosnąco",
  "Start date, descending": "Dacie rozpoczęcia, malejąco",
  Reports: "Raporty",
  Charts: "Wykresy",
  "The report end date cannot be earlier than the contract start date":
    "Data zakończenia raportu nie może być wcześniejsza od daty jej rozpoczęcia",
  "Enter the start date": "Podaj datę startu",
  "Enter the end date": "Podaj datę końca",
  "recruitment and offert score": "ocena rekrutacji i oferty",
  "Pre-work score": "ocena Pre-work",
  "Scrum Lab score": "ocena Scrum Lab",
  "Course finish score": "ocena końcowa Kursu",
  "Career LAB score": "ocena Career LAB",
  "Portfolio LAB score": "ocena Portfolio LAB",
  "the day of signing the contract": "w dniu podpisania umowy",
  "a week before the course start": "tydzień przed rozpoczęciem kursu",
  "the last day of the scrum module": "ostatni dzień modułu scrumowego",
  "the last day of the course": "ostatni dzień kursu",
  "28 days after ending of the course": "28 dni od zakończenia kursu",
  "4 weeks after ending of the course": "4 tygodnie od zakończenia kursu",
  "the last day of the module": "ostatni dzień modułu",
  "Not a valid url": "Nieprawidłowy adres url",
  "Enter the poll's name": "Wprowadź nazwę ankiety",
  "Surname, ascending": "Nazwisko, rosnąco",
  "Surname, descending": "Nazwisko, malejąco",
  "Payment status, ascending": "Status płatności, rosnąco",
  "Payment status, descending": "Status płatności, malejąco",
  "Contract status, ascending": "Status umowy, rosnąco",
  "Contract status, descending": "Status umowy, malejąco",
  "Final reason status, ascending": "Status zakończenia, rosnąco",
  Guardian: "Opiekun",
  "Final reason status, descending": "Status zakończenia, malejąco",
  "Charge for package change": "Opłata za zmianę pakietu",
  "Enter the package change fee": "Podaj kwotę za zmianę pakietu",
  Package: "Pakiet",
  "Choose the package": "Wybierz pakiet",
  January: "Styczeń",
  February: "Luty",
  March: "Marzec",
  April: "Kwiecień",
  May: "Maj",
  Jun: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Październik",
  November: "Listopad",
  December: "Grudzień",
  Timeline: "Oś czasu",
  "No data": "Brak danych",
  "Are You sure You want to change the package?":
    "Jesteś pewien, że chcesz zmienić pakiet?",
  "Group details": "Zapełnienie grup",
  Postponements: "Przesunięcia",
  "Contracts signed": "Umowy podpisane",
  Signature: "Sygnatura",
  "To start": "Do startu",
  "Download List of Invoices": "Zestawienie Faktur",
  "logika test score": "Wynik testu logika",
  "angielski test score": "Wynik testu angielski",
  "pisanie test score": "Wynik testu pisanie",
  "Knowledge test": "Testy wiedzy",
  Basic: "Podstawowy",
  Professional: "Profesjonalny",
  Ultimate: "Pewna Praca",
  "save filter": "zapisz filtr",
  "refresh filter": "odśwież filtr",
  "remove filter": "usuń filtr",
  "Starting module at the new course":
    "Moduł rozpoczęcia zajęć na nowym kursie",
  "Expand modules": "Rozwiń moduły",
  "Roll up modules": "Zwiń moduły",
  "Choose filter": "Wybierz filtr",
  good: "dobrze",
  midding: "średnio",
  bad: "słabo",
  "course/days": "kurso-dni",
  "students/days": "kursanto-dni",
  "group's average": "średnia grupy",
  "Show started": "Pokaż rozpoczęte",
  "restore filter": "przywróć filtr",
  "Search persons by competences": "Wyszukaj osoby po kompetencjach",
  "Search persons": "Wyszukaj",
  "Your selected competences": "Wybrane kompetencje",
  "Lecturing in": "Wykłada w",
  "available in mode": "dostępny/a w trybie",
  from: "od",
  to: "do",
  room: "salka",
  "Show finished courses": "Pokaż zakończone kursy",
  "With room": "Wymaga sali",
  "Cancelled course": "Kurs odwołany",
  Surveys: "Ankiety",
  "Course partial ratings": "Oceny składowych kursu",
  "Modules ratings": "Oceny modułów",
  "Slave ratings": "Oceny kursów połączonych",
  Send: "Wyślij",
  "Send again": "Wyślij ponownie",
  Sent: "Wysłano",
  "Days to send": "Dni do wysyłki",
  "Sending event": "Zdarzenie wysyłki",
  "Add survey": "Dodaj ankietę",
  "Additional surveys": "Ankiety dodatkowe",
  "Choose survey": "Wybierz ankietę",
  "Choose packages": "Wybierz pakiety",
  "Survey types": "Typy ankiet",
  "Add poll type": "Dodaj typ ankiety",
  " we have a small issue...": " mamy mały problem...",
  "Tell us": "Powiedz nam",
  "what went wrong": "co poszło nie tak",
  "Additional data": "Dane dodatkowe",
  "Sub-account": "Subkonto",
  "Charge for resignation third party": "Opłata za rezygnacje strona trzecia",
  "Charge for transfer third party": "Opłata za przeniesienie strona trzecia",
  "Charge for package change third party":
    "Opłata za zmianę pakietu strona trzecia",
  Company: "Firma",
  "The module's price can't be negative": "Cena modułu nie może być ujemna",
  Currency: "Waluta",
  Graduates: "Absolwenci",
  course_end: "dni po zakończeniu kursu",
  course_end_negative: "dni przed zakończeniem kursu",
  course_start: "dni po rozpoczęciu kursu",
  course_start_negative: "dni przed rozpoczęciem kursu",
  module_end: "dni po zakończeniu modułu",
  module_end_negative: "dni przed zakończeniem modułu",
  module_start: "dni po rozpoczęciu modułu",
  module_start_negative: "dni przed rozpoczęciem modułu",
  event_contract_signed: "dni po podpisaniu umowy",
  event_contract_signed_negative: "dni przed podpisaniem umowy",
  at_the_course_start: "w dniu rozpoczęcia kursu",
  at_the_course_end: "w dniu zakończenia kursu",
  at_the_module_start: "w dniu rozpoczęcia modułu",
  at_the_module_end: "w dniu zakończenia modułu",
  at_the_event_contract_signed: "w dniu podpisania umowy",
  at_the_manual: "wysyłka ręczna",
  Diploma: "Dyplom",
  "Current marks": "Bieżące oceny",
  "Show only last week": "Pokaż tylko ostatni tydzień",
  effects: "efekty",
  signature: "sygnatura",
  "avbl date": "data udost.",
  content: "materiały",
  lecturer: "wykładowca",
  organization: "organizacja",
  filled: "wypełnienie",
  "Current students": "Aktualni kursanci",
  Transfers: "Przeniesienia",
  "VAT Invoice": "Faktura VAT",
  "Correcting Invoice": "Faktura korygująca",
  "Pro forma Invoice": "Faktura pro forma",
  Income: "Wpłata",
  "Third party": "Strona trzecia",
  Side: "Strona",
  "Additional fee": "Opłata dodatkowa",
  "Insert additional fee": "Wprowadzanie opłaty dodatkowej",
  "Subject of payment": "Przedmiot opłaty",
  "Enter the resignation fee and wait for gross amount":
    "Podaj kwotę rezygnacji i poczekaj na obliczenie kwoty brutto",
  "Enter the transfer fee and wait for gross amount":
    "Podaj kwotę przeniesienia i poczekaj na obliczenie kwoty brutto",
  "Enter the package change fee and wait for gross amount":
    "Podaj kwotę zmiany pakietu i poczekaj na obliczenie kwoty brutto",
  "Student gross amount": "Student kwota brutto",
  "Thirdparty nett amount": "Strona trzecia kwota netto",
  "Add fee": "Dodaj opłatę",
  "Add an income for": "Wprowadzenia wpłaty do",
  "Add an income": "Wprowadzenia wpłaty",
  Payment: "Płatność",
  "Add income": "Dodaj wpłatę",
  "Income date": "Data wpłaty",
  "Income title": "Tytuł wpłaty",
  "Enter subject of the fee": "Podaj przedmiot opłaty",
  "Enter deadline of the fee": "Podaj termin płatności",
  "Enter student's amount gross": "Podaj kwotę brutto studenta",
  "Enter thirdparty's amount nett": "Podaj kwotę netto strony trzeciej",
  "Enter the resignation fee and wait for nett amount":
    "Podaj kwotę rezygnacji i poczekaj na przeliczenie kwoty netto",
  "Enter the transfer fee and wait for nett amount":
    "Podaj kwotę przeniesienia i poczekaj na przeliczenie kwoty netto",
  "Enter the package change fee and wait for nett amount":
    "Podaj kwotę zmiany pakietu i poczekaj na przeliczenie kwoty netto",
  Canceled: "Anulowano",
  "Payment delayed for": "Płatność opóźniona o",
  payment: "opłata",
  "ISTQB retake": "Powtórka ISTQB",
  "Resignation fee": "Opłata za rezygnacje",
  "Statutory interest": "Odsetki ustawowe",
  Insurance: "Ubezpieczenie",
  "Lost card": "Zgubiona karta",
  "Additional project session":
    "Dodatkowe sesje do pracy nad projektem końcowym",
  "Block retake": "Powtórka bloku",
  "You have to choose positions to be invoiced":
    "Musisz wybrać pozycje do faktury",
  "You have to choose a date": "Musisz wybrać datę",
  "You have to choose payment days": "Musisz wybrać dni płatności",
  "Invoice for a company": "Faktura dla firmy",
  "Invoice for LO": "Faktura dla UP",
  "Payment days": "Dni płatności",
  InvoiceFrom: "Z",
  InvoiceTo: "na",
  "Service completion date": "Data wykonania usługi",
  "Payment days is required": "Dni płatności są polem wymaganym",
  "Service completion date is required":
    "Data wykonania usługi jest polem wymaganym",
  ContractsSent: "Wysłane",
  New: "Nowe",
  Transfered: "Przeniesione",
  "Student's history": "Historia kursanta",
  Previous: "Poprzednie",
  Next: "Następne",
  "We have a temporary technical break": "Mamy chwilową przerwę techniczną",
  "Sit back and watch the movie.": "Usiądź wygodnie i obejrzyj film.",
  "It's possible we'll be back when you're done watching.":
    "Możliwe, że wrócimy, gdy skończysz oglądać.",
  "Income side": "Strona płatności",
  "User's address is not filled, please fill data":
    "Użytkownik nie ma podanego adresu, proszę uzupełnić dane",
  "Personal identity number is required": "Podaj numer PESEL",
  "Invalid personal identity number": "Nieprawidłowy numer PESEL",
  "Personal identity number": "PESEL",
  "Incomes report": "Wpłaty",
  "Show unallocated": "Pokaż tylko nieprzypisane",
  "Show deleted": "Pokaż tylko usunięte",
  "Transaction number": "Numer transakcji",
  "Transaction number or description": "Numer transakcji lub opis",
  "Transaction amount": "Kwota transakcji",
  Subaccount: "Subkonto",
  "Make transfer": "Przeksięguj",
  "Transaction transfer": "Przeksięgowanie wpłaty",
  "Transfer's reason": "Powód przeksięgowania",
  "Destination subaccount": "Subkonto docelowe",
  "Correction's number": "Faktury korygujące",
  "Enter reason of the transfer, not less than 10 characters":
    "Treść powodu przeksięgowania musi mieć minimum 10 znaków",
  "Destination subaccount must be different from the source":
    "Subkonto docelowe musi różnić się od źródłowego",
  "Source subaccount": "Subkonto źródłowe",
  "Destination subaccount is required": "Musisz podać docelowe subkonto",
  "Transfer's reason is required": "Powód przeksięgowania jest polem wymaganym",
  "Correcton's number is required": "Numer korekty jest polem wymaganym",
  "Incomes' transfers report": "Przeksięgowania wpłat",
  "Change info": "Informacja o zmianie",
  "Product status": "Status produktu",
  "Settlement of receivables": "Rozliczenie należności",
  Refunds: "Zwroty",
  Reason: "Tytułem",
  "Refunded by": "Kto",
  "Refund status": "Status zwrotu",
  Correction: "Numer faktury korygującej",
  Invoiced: "Z fakturą",
  "Not invoiced": "Bez faktury",
  "Correct the data on the form": "Popraw dane na formularzu",
  Description: "Opis",
  "Created by": "Utworzone przez",
  "Refunded at": "Zwrócone",
  "Created at": "Utworzone",
  Balance: "Saldo",
  "Overdues' sum": "Suma wymaganych należności",
  "Paid dues' sum": "Suma opłaconych należności",
  "Incomes' sum": "Suma wpłat",
  "Refunds' sum": "Suma zwrotów",
  Dues: "Należności",
  Incomes: "Wpłaty",
  "Deadline, ascending": "Najstarsze",
  "Deadline, descending": "Najnowsze",
  "Contract payments report": "Należności",
  "Student balance": "Saldo kursanta",
  "Third party balance": "Saldo strony trzeciej",
  "Add student's income": "Dodaj wpłatę studenta",
  "Add third parties income": "Dodaj wpłatę strony trzeciej",
  "Nett amount": "Kwota netto",
  "Gross amount": "Kwota brutto",
  "Show expired only": "Pokaż tylko przeterminowane",
  "Add refund": "Dodaj zwrot",
  "Refund title": "Tytułem",
  "Refund side": "Strona zwrotu",
  "Refund amount": "Kwota do zwrotu",
  "Amount is required": "Kwota jest polem wymaganym",
  "Enter description of the refund, not less than 10 characters":
    "Wprowadz tytuł zwrotu, minimum 10 znaków",
  "Enter amount of the refund": "Wprowadz kwote zwrotu",
  "Select side of the refund": "Wybierz stronę zwrotu",
  "Calculate rebate": "Przelicz rabat",
  "Change rebate to": "Zmienić rabat na",
  "Balances report": "Salda",
  "Entering manual income": "Wprowadzenie wpłaty manualnej",
  Settlements: "Rozliczenia",
  "Invoice edition": "Edycja faktury",
  "Save invoice": "Zapisz fakturę",
  "Description is required": "Opis jest polem wymaganym",
  "Amount gross is required": "Kwota brutto jest polem wymaganym",
  "Issue date is required": "Data wystawienia jest polem wymaganym",
  "Issue month": "Data wystawienia",
  "Payment date is required": "Wprowadź termin płatności",
  "Sell date is required": "Wprowadź datę sprzedaży",
  "Payment date must be after issue date":
    "Termin płatności musi być po dacie wystawienia",
  "Sell date must be after start of previous month":
    "Data sprzedaży musi być po rozpoczęciu poprzedniego miesiąca",
  "Add an invoice": "Dodaj fakturę",
  Overdue: "Przeterminowane",
  Contracts: "Kontrakty",
  Invoices: "Faktury",
  Balance_: "Saldo",
  "Show only not refunded": "Pokaż tylko niezwrócone",
  Date: "Data",
  "Auto-invoices": "Automatyczne fakturowanie",
  "After edit rebate the payments are calculate again!":
    "Po edycji rabatu należności zostaną przeliczone ponownie!",
  "Are you sure you want to remove an income?":
    "Czy na pewno chcesz usunąć wpłatę?",
  "The second date can not be earlier than the first!":
    "Druga data nie może być wcześniejsza niż pierwsza!",
  "Correction number is required": "FK jest wymagana",
  "Payment schedule reset": "Reset harmonogramu płatności",
  "The invoice amount is higher then dues' sum":
    "Kwota faktury jest wyższa niż suma należności",
  "Balance, ascending": "Sortuj po saldzie, narastająco",
  "Balance, descending": "Sortuj po saldzie, malejąco",
  "Payment method": "Sposób płatności",
  Cash: "Gotówka",
  "Online payment": "Płatność online",
  "Bank transfer": "Przelew bankowy",
  "Select payment method": "Wybierz sposób płatności",
  "Tax rate": "Stawka VAT",
  "You must select tax rate": "Musisz wybrać stawkę VAT",
  "You must select receiver": "Musisz wybrać odbiorcę",
  "The e-mail address of the invoice sending has not been added":
    "Adres e-mail odbiorcy faktur nie został dodany",
  "Your data was saved": "Twoje dane zostały zapisane",
  "Something goes wrong": "Coś poszło nie tak",
  "Active payment": "Należność aktywna",
  "Assign payer": "Przypisz płatnika",
  "New payer created": "Nowy płatnik utworzony",
  "New payer was created. Do you want assign him to this contract?":
    "Nowy płatnik został utworzony. Czy chcesz go przypisać do tej umowy?",
  self: "samodzielne",
  "own funds": "samodzielne",
  "Funding type": "Typ finansowania",
  "Choose funding type": "Wybierz typ finansowania",
  "You must choose payer": "Musisz wybrać płatnika",
  "Parts of payments must be equal 100% in sum":
    "Suma udziałów w finansowaniu musi wynieść w sumie 100%",
  "You must choose funding type": "Musisz wybrać typ finansowania",
  "Income type": "Typ wpłaty",
  manual: "manualna",
  bank: "bankowa",
  "Correction's number is required": "FK jest wymagana",
  "dates, cities, lecturers, students, schedules":
    "terminy, miasta, wykładowcy, kursanci, harmonogramy",
  "students, employees, co-workers, their data and competences":
    "kursanci, pracownicy, współpracownicy, ich dane i kompetencje",
  "substantive scope, modules, number of lectures, prices and installments":
    "zakres merytoryczny, moduły, liczba godzin wykładów, ceny i raty",
  "charts, timeline, graduates, surveys, financial data":
    "wykresy, oś czasu, absolwenci, ankiety, dane finansowe",
  "The contract is finished": "Umowa jest zakończona",
  "Only payments have been modified": "Zmodyfikowano tylko płatności",
  "All mentors": "Wszyscy mentorzy",
  "All curse specialists": "Wszyscy opiekunowie",
  "No entries found": "Nie znaleziono wpisów w historii",
  All: "Wszystkie",
  "No available lecturers within this period":
    "Brak dostępnych wykładowców w tym terminie",
  "N/A": "nd.",
  Corrections: "Korekty",
  "Edit corrections": "Edytuj korekty",
  "Add new correction": "Dodaj nową korektę",
  Remove: "Usuń",
  "Select payer": "Wybierz płatnika",
  "Part of payments": "Część płatności",
  "Bank account IBAN": "Numer konta IBAN",
  "Bank account SWIFT": "Numer konta SWIFT",
  "From which module does not attend the course (does not pay)":
    "Od którego modułu nie chodzi na kurs (nie płaci)",
  "Cancellation during the last module": "Rezygnacja podczas ostatniego modułu",
  "For this type of contract you have to assign 2 payers at least":
    "Dla tego typu kontraktu musisz przypisać co najmniej dwóch płatników",
  "Payer can be choose only once": "Płatnik może być wybrany tylko raz",
  "You can have assign only one payer":
    "Możesz mieć przypisanego tylko jednego płatnika",
  "Income already invoiced": "Faktura wystawiona poza HUB",
  "Transfer invoices": "Przenieś fakturę VAT",
  "Income invoiced": "FV poza HUB",
  "No records found": "Nie znaleziono rekordów",
  "Select the receiver": "Wybierz odbiorcę",
  Receiver: "Odbiorca",
  "Type of contract change": "Zmiana typu kontraktu",
  "Before changing the type of contract, remove payers other than the student":
    "Przed zmianą typu umowy usuń płatników innych niż kursant",
  "Payment type": "Sposób płatności",
  "Prework share date": "Data udostępnienia preworku",
  "Refund bank account": "Konto bankowe do zwrotu",
  "Part of the payment can not be greater than 100%":
    "Część płatności nie może być większa od 100%",
  "Part of the payment can not be less than 0%":
    "Część płatności nie może być mniejsza od 0%",
  "Choose VAT rate": "Wybierz stawkę VAT",
  "You must choose tax rate": "Musisz wybrać stawkę VAT",
  "It is the sum of paid and overdue payments with a deadline to the present day":
    "Jest to suma należności opłaconych oraz przeterminowanych z datą zapadalności do dnia dzisiejszego",
  "You must fill correction's number": "Musisz podać numer korekty",
  Owner: "Płatnik",
  "Are you sure you want to delete the status of the Income already invoiced and issue an invoice for the payer":
    "Czy na pewno chcesz usunąć status faktura wystawiona poza HUB i wystawić fakturę dla płatnika",
  "Add company": "Dodaj firmę",
  Companies: "Firmy",
  "Enter the company ID": "Podaj numer REGON",
  "Bank account for refunds": "Numer konta do zwrotu środków",
  "Payments type": "Typ płatności",
  "Enter payments type": "Podaj typ płatności",
  "Choose payer": "Wybierz płatnika",
  "Enter amount gross": "Podaj kwotę brutto",
  "Select the product": "Wybierz produkt",
  "Amount of payments": "Kwota płatności",
  "Part of the payment can not be greater than modules total sum":
    "Część płatności nie może być większa niż suma modułów",
  "Part of the payment can not be less than 0":
    "Część płatności nie może być mniejsza od 0",
  "Sum of parts of the payment must be equal to modules total sum":
    "Suma części płatności musi być równa sumie modułów ({{totalSum}}). Brakuje: {{currentSum}}",
  "Transfer reason": "Przyczyna przeniesienia",
  "Choose the transfer reason": "Wybierz przyczynę przeniesienia",
  "Sum of third parties' part must be equal":
    "Suma części płatności strony trzeciej musi być równa {{maxPayer}}",
  "Change product version": "Zmiana wersji produktu",
  "Are you sure you want to recalculate the payments on all contracts by new product version.":
    "Czy na pewno chcesz przeliczyć należności na wszystkich kontraktach wg. nowej wersji produktu.",
  "Max. lecturer's price": "Maks. stawka wykładowcy",
  "Module's type": "Typ modułu",
  "Day of the course": "Dzień kursu",
  "Lecture's type": "Typ zajęć",
  "Duration per student": "Czas na sprawdzenie na 1 kursanta",
  Participants: "Uczestnicy",
  "Choose participants": "Wybierz uczestników",
  "You must choose at least one package":
    "Musisz wybrać przynajmniej jeden pakiet",
  "You must fill max. lecturer price":
    "Musisz uzupełnić max. stawkę wykładowcy",
  "Max. lecturer price must be greater than zero":
    "Max. stawka wykładowcy musi być większa niż zero",
  "Modules' surveys": "Ankiety modułowe",
  Client: "Nabywca",
  "Select the client": "Wybierz nabywcę",
  "No income required": "Nie wymaga wpłaty",
  "Invoice number or client name": "Numer faktury lub nazwa klienta",
  "Lectures types": "Typy zajęć",
  "Add lecture type": "Dodaj typ zajęć",
  Multiplier: "Mnożnik",
  "Lecture type": "Typ zajęć",
  "Contacts list": "Lista kontaktów",
  "Add new contact": "Dodaj nowy kontakt",
  "Edit contact": "Edytuj kontakt",
  "Remove contact": "Usuń kontakt",
  "Enter a valid contact name": "Wprowadź prawidłową nazwę kontaktu",
  "Enter the course's day": "Podaj dzień kursu",
  "Enter the week day": "Podaj dzień tygodnia",
  "Choose type of lecture": "Wybierz typ zajęć",
  "Set the start time": "Ustaw godzinę rozpoczęcia",
  "Set the participants": "Ustaw uczestników",
  "Date and time": "Data i godzina",
  "Lecture duration": "Czas trwania",
  "Move next lectures": "Przenies kolejne wykłady",
  "Fill module with this lecture and room":
    "Wypełnij moduł tym wykładowcą i pokojem",
  Publish: "Opublikuj",
  "WARNING!": "UWAGA!",
  "Are You sure You want to change this field?":
    "Czy na pewno chcesz zmienić to pole?",
  "Start time": "Czas rozpoczęcia",
  "Set the date time": "Ustaw date rozpoczęcia",
  "List of contracts": "Lista kontraktów",
  "Contract kind": "Rodzaj kontraktu",
  "Date of addition, ascending": "Dacie dodania, rosnąco",
  "Date of addition, descending": "Dacie dodania, malejąco",
  "Enter withdrawal description": "Wprowadź opis odstąpienia",
  "Connected contracts": "Połączone kontrakty",
  "assign contract": "przypisz kontrakt",
  "Diploma number": "Numer dyplomu",
  "Finish status": "Status zakończenia",
  "Will you take the related course fees?":
    "Czy zaciągnąć należności z kursu powiązanego?",
  "Choose course": "Wybierz kurs",
  Assignment: "Obsadzenie",
  "Product master": "Produkt wiodący",
  "Has not free slots": "Nie ma wolnych slotów",
  "Has free slots": "Ma wolne sloty",
  "Searching...": "Wyszukiwanie...",
  "Show more": "Pokaż wiecej",
  "Products not found": "Nie znaleziono produktów",
  "Type to search...": "Wpisz aby wyszukać...",
  "Course days and groups report": "Kursodni i liczebność grup",
  "Name and Surname": "Imię i Nazwisko",
  "XLS reports": "Raporty XLS",
  "Lecturer settlement": "Rozliczenie wykładowców",
  "Cash flow report": "Raport Cash flow",
  "n.d.": "b.d.",
  "Course specialists not found": "Nie znaleziono opiekuna kursu",
  "Show more course specialists": "Wyświetl wiecej opiekunów",
  "Show more products": "Wyświetl wiecej produktów",
  "Save product": "Zapisz produkt",
  "Save module": "Zapisz moduł",
  "Save lectures": "Zapisz wykłady",
  "Save additional polls": "Zapisz ankiety dodatkowe",
  "Save polls": "Zapisz ankiety",
  "This product has active version already":
    "Ten produkt posiada już aktywną wersję",
  "If you want to active this version of product You must deactivate current active version.":
    "Jeśli chcesz aktywować tą wersję produktu, musisz najpierw dezaktywować aktualną wersję.",
  "Stationary mode": "Tryb Stacjonarny",
  "Part time mode": "Tryb Weekendowy",
  "Online mode": "Tryb Online",
  "Free mode": "Tryb Dowolny",
  "Payments parts reset": "Resetuje podział płatności",
  "You are in draft mode": "Jesteś w trybie draft",
  "Select the signature": "Wybierz sygnature",
  "Signature not found": "Nie znaleziono sygnatury",
  "Cancel draft": "Anuluj draft",
  "Show deleted only": "Pokaż tylko usunięte",
  "Remove an invoice": "Usuń fakturę",
  deleted: "Usunięta",
  "Course version": "Wersja kursu",
  "Course 2.0": "Kurs 2.0",
  "All students": "Wszystkich kursantów",
  "Generate PayU link": "Wygeneruj link PayU",
  "PayU Order": "Zamówienie PayU",
  "Copy to clipboard": "Skopiuj do schowka",
  "PayU Orders report": "Raport zakupów PayU",
  "Settle the module": "Rozlicz moduł",
  "Update buyer address": "Aktualizuj dane adresowe nabywcy",
  "Are You sure You want to update buyer address?":
    "Czy na pewno chcesz zaktualizować dane adresowe nabywcy?",
  "The payment schedule contains errors": "Harmonogram płatności zawiera błędy",
  "Additional events": "Wydarzenia dodatkowe",
  "Add new event": "Dodaj nowe wydarzenie",
  "Enter event's name": "Wprowadź nazwe wydarzenia",
  "Enter event's start time": "Wprowadz godzinę startu",
  "Choose type of event": "Wybierz typ wydarzenia",
  "Enter event's duration": "Wprowadź czas trwania wydarzenia",
  "Choose mode": "Wybierz tryb",
  "Choose room": "Wybierz sale",
  "Choose lecturer": "Wybierz wykładowce",
  "Enter event's date": "Wprowadź date wydarzenia",
  "Edit event": "Edycja wydarzenia",
  "Room required": "Wymaga sali",
  "Course days": "Kursodni",
  "Add new note": "Dodaj nową uwage",
  ssp: "Kursant pojedyncza wpłata",
  si: "Kursant raty",
  csp: "Firma pojedyncza wpłata",
  ci: "Firma raty",
  Office: "Siedziba",
  cs: "Harmonogram kursu",
  "Contract owner": "Zlecający",
  "Add new mapping": "Dodaj nowe mapowanie",
  "PipeDrive Mapping": "Mapowanie PipeDrive",
  "Remove lead map": "Usuń mapowanie leada",
  "Are You sure You want to remove lead map?":
    "Czy na pewno chcesz usunąć mapowanie leada ?",
  "Resigned contracts": "Kontrakty - rezygnacje",
  "Amendment contract": "Kontrakty - aneksy",
  "Date range by issue date": "Zakres dat po dacie wystawienia",
  "Are you sure you want to remove this module?":
    "Czy na pewno chcesz usunąć ten moduł?",
  "By removing this module, you will not be able to restore it":
    "Usuwając ten moduł nie będzie możliwości przywrócenia go",
  "Bought in shop": "Zakup w sklepie",
  "Finished contracts": "Kontrakty - statusy zakończenia kursu",
  "Sorry, your attempt was unsuccessful. Try again.":
    "Próba zalogowania zakończyła się niepowodzeniem. Spróbuj jeszcze raz.",
  Password: "Hasło",
  Login: "Zaloguj się",
  "Login with google": "Zaloguj z Google",
  "Change password": "Zmiana hasła",
  "Login data": "Dane logowania",
  "Save password": "Zapisz hasło",
  "Password does not match": "Hasła nie pasują do siebie",
  "Password change error. Try again":
    "Nie udało się zmienić hasła. Spróbuj ponownie",
  "Password changed": "Hasło zmienione",
  "Monthly contracts": "Miesięczne kontrakty",
  "Insert dates": "Wprowadź daty",
  "Manual ratings": "Ankiety ręczne",
  "Not filled": "Niewypełnione",
  Filled: "Wypełnione",
  Fill: "Wypełnij",
  Comment: "Komentarz",
  "Graduate poll": "Badanie absolwentów",
  "Courses end dates": "Daty zakończenia kursu",
  "Select comment": "Wybierz komentarz",
  "Add new comment": "Dodaj nowy komentarz",
  "Comment is too length (max characters 255)":
    "Komentarz jest za długi (maksymalnie 255 znaków)",
  "Students amount": "Liczba studentów",
  "Auto invoice": "Wystaw fakturę",
  "Download invoices": "Pobierz faktury",
  "Date is required": "Data jest wymagana",
  Notes: "Notatki",
  "Add note": "Dodaj notatkę",
  "Show all notes": "Pokaż wszystkie notatki",
  "Special offers": "Promocje",
  "Add special offer": "Dodaj promocje",
  "Start At": "Data startu",
  "End At": "Data zakończenia",
  "Special offer": "Promocja",
  "Contracted value": "Zakontrakowana wartość",
  "Shows the number of completed surveys/per number of surveys assigned to the module (calculated based on product definition and number of participants).":
    "Przedstawia liczbę uzupełnionych ankiet/ na liczbę ankiet przypisaną do modułu (obliczaną na podstawie definicji produktu i liczby uczestników).",
  "No diplomas to download": "Brak dyplomów do pobrania",
  "Download diplomas": "Pobierz certyfikaty",
  "Finish reason": "Status ukończenia",
  "Legal basis": "Podstawa prawna",
  "You must select legal basis": "Musisz wybrać podstawę prawną",
  "Send status": "Status wysyłki",
  not_sent: "Niewysłana",
  ready_to_send: "Gotowa do wysyłki",
  sent: "Wysłana",
  in_review: "W trakcie weryfikacji",
  in_send: "W trakcie wysyłki",
  in_send_retry: "Wysyłka nieudana, zaplanowana ponownie",
  sent_error: "Błąd wysyłki",
  not_handled: "Wysyłka poza HUB",
  "Changed by": "Zmienione przez",
  "Invoice status log": "Historia wysyłki faktur",
  Machine: "Automat",
  "Data for sending invoices": "Dane do wysyłki faktur",
  "Edit payer": "Edytuj płatnika",
  "The e-mail address of the invoice receiver has not been added":
    "Adres e-mail odbiorcy faktur nie został dodany",
  "Are you sure you want to send the invoices?":
    "Czy na pewno chcesz wysłać faktury?",
  invoice: "fakturę",
  invoices: "faktury",
  "The invoice cannot be edited because it has already been sent":
    "Nie można edytować faktury, ponieważ została już wysłana",
  "Send by": "Wysłana przez",
  "Receiver emails": "Kontakt do wysyłki faktury",
};
