import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { formValueSelector, reduxForm } from "redux-form";
import {
  fetchBankIncomesTransfers,
  downloadBankIncomesTransfers,
} from "../Actions";
import IncomesTransfersReport from "../Components/IncomesTransfersReport";

const FORM_NAME = "INCOMES_TRANSFERS_REPORT";

const IncomesTransfersList = props => <IncomesTransfersReport {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const { bankIncomesTransfers } = state;
  const values = selector(state, "id", "transaction");
  return {
    bankIncomesTransfers,
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBankIncomesTransfers,
      downloadBankIncomesTransfers,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(IncomesTransfersList),
);
