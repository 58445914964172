import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function courseSpecialistReducer(
  state = coursesInitState.courseSpecialists,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_COURSE_SPECIALISTS_FOR_COURSE:
      return { ...action.payload.data.data };

    default:
      return state;
  }
}
