import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import Loader from "../../../Common/Components/Loader/Loader";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { RenderCheckbox, RenderSelect } from "../../../Forms/forms";
import Dinero from "dinero.js";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import { Link } from "react-router-dom";
import CourseSpecialistSelectWithPagination from "../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";

class BalancesReport extends Component {
  #cancelToken = getCancelToken();
  #tableColumns = [
    {
      Header: () => <div>{this.props.t(`Side`)}</div>,
      Cell: ({ original: { id, owner, personId } }) => {
        let url = `/balance/${id}`;
        if (personId) {
          url = `/persons/update/${personId}/3`;
        }
        return <Link to={url}>{owner}</Link>;
      },
      width: 250,
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Balance_`)}</div>,
      Cell: ({ original: { totalBalance } }) => {
        const amount = Dinero(totalBalance);
        let className = "";
        if (amount.isNegative()) {
          className = "text-danger";
        } else if (amount.isPositive() && !amount.isZero()) {
          className = "text-warning";
        }
        return (
          <span className={className}>
            {renderAmountWithCurrency(totalBalance)}
          </span>
        );
      },
      accessor: "totalBalance.amount",
      sortable: false,
    },
  ];

  static propTypes = {
    balancesReportResults: PropTypes.object,
    coursesSpecialists: PropTypes.array,
    fetchBalances: PropTypes.func,
    downloadBalances: PropTypes.func,
    getCoursesSpecialists: PropTypes.func,
    change: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (forceFirstPage = false) => {
    const { fetchBalances } = this.props;
    this.setState({
      isLoading: true,
    });
    fetchBalances(
      this.#cancelToken,
      this.prepareRequestParams(forceFirstPage),
      () => {
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  prepareRequestParams = forceFirstPage => {
    const {
      values: { overpaidOnly, expiredOnly, courseSpecialistIds, sort },
    } = this.props;
    const [sortBy, sortOrder] =
      typeof sort === "undefined" || sort === null
        ? [null, null]
        : sort.split(".");
    const { currentPage: pageNo } = this.state;
    this.setState({
      currentPage: forceFirstPage ? 1 : pageNo,
    });
    return {
      expiredOnly: Number(expiredOnly),
      overpaidOnly: Number(overpaidOnly),
      courseSpecialistIds:
        typeof courseSpecialistIds === "object" ? courseSpecialistIds : null,
      sortBy,
      sortOrder,
      pageNo: forceFirstPage ? 1 : pageNo,
    };
  };

  componentDidUpdate(prevProps) {
    const {
      values: { overpaidOnly, expiredOnly, courseSpecialistIds, sort },
    } = this.props;
    const {
      values: {
        expiredOnly: oldExpiredOnly,
        overpaidOnly: oldOverpaidOnly,
        courseSpecialistIds: oldCourseSpecialistIds,
        sort: oldSort,
      },
    } = prevProps;
    const valuesComparison = [
      [expiredOnly, oldExpiredOnly],
      [overpaidOnly, oldOverpaidOnly],
      [courseSpecialistIds, oldCourseSpecialistIds],
      [sort, oldSort],
    ];
    const valuesNotChanged = valuesComparison.every(valuesToCheck =>
      _.isEqual(valuesToCheck[0], valuesToCheck[1]),
    );

    if (!valuesNotChanged) {
      _.debounce(() => {
        this.loadData(true);
      }, 1000)();
    }
  }

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
      },
      this.loadData,
    );
  };

  handleDownload = () => {
    const { downloadBalances } = this.props;
    const params = this.prepareRequestParams();
    downloadBalances(this.#cancelToken, { ...params, format: "xls" }, () => {
      this.setState({ isWaitingForData: false });
    });
  };

  render() {
    const { isLoading, currentPage } = this.state;
    const { t, coursesSpecialists, balancesReportResults } = this.props;
    const PER_PAGE = 10;
    const counter = _.has(balancesReportResults, "counter")
      ? balancesReportResults.counter
      : 0;
    const balances = _.has(balancesReportResults, "elements")
      ? balancesReportResults.elements
      : [];
    const pages = Math.ceil(counter / PER_PAGE);

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <section id="balanceReport" className="form-section">
          <div className="row">
            <div className={`form-group col-md-2 col-xs-6`}>
              <Field
                name="expiredOnly"
                labelForCheckbox={t("Expired")}
                customLabel=""
                component={RenderCheckbox}
              />
            </div>
            <div className={`form-group col-md-2 col-xs-6`}>
              <Field
                name="overpaidOnly"
                labelForCheckbox={t("Overpaid")}
                customLabel=""
                component={RenderCheckbox}
              />
            </div>
            <div className={`form-group col-md-3 col-xs-6`}>
              <Field
                component={CourseSpecialistSelectWithPagination}
                name="courseSpecialistIds"
                onChangeHandler={values => {
                  this.props.change(
                    "courseSpecialistIds",
                    values.map(person => person.id),
                  );
                }}
                placeholder={t("Course Specialist")}
                getCoursesSpecialists={this.props.getCoursesSpecialists}
                coursesSpecialists={coursesSpecialists}
                onClearCallback={() =>
                  this.props.change("courseSpecialistIds", null)
                }
                multiple={true}
              />
            </div>
            <div className={`form-group col-md-3 col-xs-6`}>
              <Field
                component={RenderSelect}
                name="sort"
                dropdownConfig={{
                  data: [
                    { id: "totalBalance.asc", name: t("Balance, ascending") },
                    { id: "totalBalance.desc", name: t("Balance, descending") },
                  ],
                  textField: "name",
                  valueField: "id",
                }}
                placeholder={t("Sort by")}
                under={{
                  fieldClassName: "col-xs-12",
                }}
              />
            </div>
            <div
              className={`form-group col-md-1 col-xs-6`}
              id="balancesDownload"
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
                onClick={this.handleDownload}
              />
            </div>
          </div>
          <div className="row horizontal-center">
            <div className="col-md-12">
              <div className={`row`}>
                <ReactTable
                  noDataText={t("No records found")}
                  showPagination={false}
                  defaultPageSize={PER_PAGE}
                  data={balances}
                  sortable={true}
                  columns={this.#tableColumns}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Paginator
                pages={pages}
                onClick={this.changePage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default translate()(BalancesReport);
