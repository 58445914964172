import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import validate from "../Validators/DeleteIncomeValidator";
import DeleteIncomeForm from "../Components/DeleteIncomeForm";

const FORM_NAME = "REMOVE_INCOME_FORM";
const DeleteIncome = props => <DeleteIncomeForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "fk");
  return {
    ...state,
    values,
  };
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(
  connect(
    mapStateToProps,
    null,
  )(DeleteIncome),
);
