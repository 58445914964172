import ACTION_TYPES from "../Actions/ActionTypes";
import { coursesInitState } from "./Courses";

export function contractsListReducer(
  state = coursesInitState.contractsList,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_CONTRACTS_LIST:
      return action.payload.data.data;
    default:
      return state;
  }
}
