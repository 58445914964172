import React from "react";

import StudentHistoryList from "../../Students/Components/StudentHistoryList";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { getContractLogs } from "../Actions";

const StudentHistory = props => <StudentHistoryList {...props} />;

function mapStateToProps(state) {
  const { dictionaries, contractLogs } = state;
  return {
    dictionaries,
    contractLogs,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      getContractLogs,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentHistory);
