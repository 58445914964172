import { formValueSelector, reduxForm } from "redux-form";
import ContactForm from "../Components/ContactForm";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "../Validators/ContactValidator";

const FORM_NAME = "CONTACT_FORM";
const selector = formValueSelector(FORM_NAME);

const ContactFormContainer = props => <ContactForm {...props} />;

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = (appState, ownProps) => {
  const { initialValues } = ownProps;
  const values = selector(
    appState,
    "id",
    "companyId",
    "name",
    "email",
    "phone",
    "contractOwner",
  );

  return {
    values,
    initialValues,
  };
};

export default reduxForm({
  validate,
  form: FORM_NAME,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactFormContainer),
);
