import { Features } from "../../../Common/Utils/Tenant";

export default fields => {
  let errors = {};
  const { fk } = fields;

  if (Features.invoices && !fk) {
    errors.fk = "Correction's number is required";
  }

  return errors;
};
