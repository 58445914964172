import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function freeLecturesAndRoomsReducer(
  state = coursesInitState.freeLecturesAndRooms,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_FREE_LECTURERS_AND_ROOMS:
      return action.payload.data.data;
    default:
      return state;
  }
}
