import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import dateHelper from "../../../Helpers/DateHelper";
import getCancelToken from "../../../Helpers/GetCancelToken";
import Loader from "../../../Common/Components/Loader/Loader";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactTable from "react-table";
import { Search } from "react-feather";
import { Field } from "redux-form";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import TIME from "../../../Enums/Time";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import { Link } from "react-router-dom";
import { Features } from "../../../Common/Utils/Tenant";

class IncomesTransfersReport extends Component {
  #cancelToken = getCancelToken();
  #tableColumns = [
    {
      Header: () => (
        <div className="group-fill-table__header">
          {this.props.t(`Transaction number`)}
        </div>
      ),
      id: "transactionNumber",
      accessor: "transaction",
    },
    {
      Header: () => <div>{this.props.t(`Source subaccount`)}</div>,
      id: "sourceAccount",
      Cell: ({
        original: {
          balanceSourceId,
          source: { number, owner },
        },
      }) => (
        <Link to={`/balance/${balanceSourceId}`}>
          <span title={`${number} - ${owner}`}>{`${number} - ${owner}`}</span>
        </Link>
      ),
    },
    {
      Header: () => <div>{this.props.t(`Destination subaccount`)}</div>,
      id: "destinationAccount",
      Cell: ({
        original: {
          balanceDestinationId,
          destination: { number, owner },
        },
      }) => (
        <Link to={`/balance/${balanceDestinationId}`}>
          <span title={`${number} - ${owner}`}>{`${number} - ${owner}`}</span>
        </Link>
      ),
    },
    {
      Header: () => <div>{this.props.t(`Transfer's reason`)}</div>,
      id: "reason",
      Cell: ({ original: { reason } }) => <p title={reason}>{reason}</p>,
    },
    {
      Header: () => <div>{this.props.t(`Change info`)}</div>,
      id: "changeInfo",
      Cell: ({
        original: {
          date,
          user: { name },
        },
      }) => (
        <span>
          {dateHelper(date)
            .get()
            .format(TIME.DATE_FORMAT)}
          : {name}
        </span>
      ),
    },
    {
      Header: () => <div>{this.props.t(`Correction`)}</div>,
      accessor: "correction",
      id: "correction",
      sortable: false,
      show: Features.invoices,
    },
  ];

  static propTypes = {
    bankIncomesTransfers: PropTypes.object,
    fetchBankIncomesTransfers: PropTypes.func,
    downloadBankIncomesTransfers: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bankIncomesTransfers: {},
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (forceFirstPage = false) => {
    const { fetchBankIncomesTransfers } = this.props;
    fetchBankIncomesTransfers(
      this.#cancelToken,
      this.prepareRequestParams(forceFirstPage),
    );
  };

  prepareRequestParams = forceFirstPage => {
    const {
      values: { transaction },
    } = this.props;
    const { currentPage: page } = this.state;
    this.setState({
      currentPage: forceFirstPage ? 1 : page,
    });
    return {
      transaction,
      pageNo: forceFirstPage ? 1 : page,
    };
  };

  componentDidUpdate(prevProps) {
    const {
      bankIncomesTransfers,
      values: { transaction },
    } = this.props;
    const {
      values: { transaction: oldTransation },
    } = prevProps;
    const valuesComparison = [[transaction, oldTransation]];
    const valuesNotChanged = valuesComparison.every(
      valuesToCheck => valuesToCheck[0] === valuesToCheck[1],
    );

    if (!valuesNotChanged) {
      _.debounce(() => {
        this.loadData(true);
      }, 1000)();
    }

    if (!_.isEqual(bankIncomesTransfers, this.state.bankIncomesTransfers)) {
      this.setState({
        isLoading: false,
        bankIncomesTransfers,
      });
    }
  }

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
      },
      this.loadData,
    );
  };

  handleDownload = () => {
    const { downloadBankIncomesTransfers } = this.props;
    const params = this.prepareRequestParams();
    downloadBankIncomesTransfers(
      this.#cancelToken,
      { ...params, format: "xls" },
      () => {
        this.setState({ isWaitingForData: false });
      },
    );
  };

  render() {
    const {
      isLoading,
      bankIncomesTransfers: { counter, elements: incomesTransfers },
      currentPage,
    } = this.state;
    const { t } = this.props;
    const PER_PAGE = 10;
    const pages = Math.ceil(counter / PER_PAGE);

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <section className="form-section">
          <div className="row">
            <div className="form-group col-sm-12 col-md-3">
              <div className="input-group">
                <div className="input-group-addon">
                  <Search size={16} />
                </div>
                <Field
                  className="form-control"
                  name="transaction"
                  component="input"
                  type="text"
                  placeholder={t(`Transaction number`)}
                />
              </div>
            </div>
            <div
              className={`form-group col-md-1 col-xs-6`}
              id="incomesTransfersDownload"
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
                onClick={this.handleDownload}
              />
            </div>
          </div>
          <div className="col-md-12 row horizontal-center">
            <div className="col-md-12">
              <div className={`row`}>
                <ReactTable
                  noDataText={t("No records found")}
                  showPagination={false}
                  defaultPageSize={10}
                  data={incomesTransfers}
                  sortable={true}
                  columns={this.#tableColumns}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Paginator
                pages={pages}
                onClick={this.changePage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default translate()(IncomesTransfersReport);
