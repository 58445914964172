import ProductsAdditionalPolls from "../../Products/Components/ProductsAdditionalPolls";
import { FieldArray } from "redux-form";
import getCancelToken from "../../../Helpers/GetCancelToken";
import React, { Component } from "react";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import POLL_TYPES from "../../../Enums/PollTypes";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import ProductsPolls from "../../Products/Components/ProductsPolls";
import Loader from "../../../Common/Components/Loader/Loader";
import { RouteComponentProps } from "react-router";
import { CancelTokenSource } from "axios";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import { ProductStatus } from "../Types/CurrentProductVersionType";

export type PollsFormProps = {
  fetchContractPackages: (cancelToken: CancelTokenSource) => Promise<void>;
  fetchProductVersionsMeta: (cancelToken: CancelTokenSource) => Promise<void>;
  fetchProductVersionPolls: (
    cancelToken: CancelTokenSource,
    versionId: string,
  ) => Promise<void>;
  fetchProductModules: (
    cancelToken: CancelTokenSource,
    versionId: string,
  ) => Promise<void>;
  fetchFilteredPolls: (
    cancelToken: CancelTokenSource,
    config: { pollType: string; actionType: string },
  ) => Promise<void>;
  clearProductVersionModules: () => void;
  currentStatus: ProductStatus;
} & RouteComponentProps;

export type PollsFormState = {
  isLoading: boolean;
};

class PollsForm extends Component<PollsFormProps, PollsFormState> {
  private cancelToken = getCancelToken();
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    const {
      fetchContractPackages,
      fetchFilteredPolls,
      fetchProductVersionPolls,
      fetchProductModules,
      fetchProductVersionsMeta,
      match,
    } = this.props;
    const { versionId } = match.params;

    await fetchContractPackages(this.cancelToken);
    await fetchProductVersionsMeta(this.cancelToken);
    await fetchFilteredPolls(this.cancelToken, {
      pollType: POLL_TYPES.MODULE,
      actionType: ACTION_TYPES.FETCH_MODULE_POLLS,
    });
    fetchFilteredPolls(this.cancelToken, {
      pollType: POLL_TYPES.COURSE,
      actionType: ACTION_TYPES.FETCH_COURSE_POLLS,
    });
    fetchFilteredPolls(this.cancelToken, {
      pollType: POLL_TYPES.EVENT,
      actionType: ACTION_TYPES.FETCH_EVENT_POLLS,
    });

    await fetchProductVersionPolls(this.cancelToken, versionId);
    await fetchProductModules(this.cancelToken, versionId);
    this.setState({ isLoading: false });
  };

  componentDidUpdate(prevProps) {
    const { productPolls, productModules, initialize } = this.props;

    if (!isEqual(prevProps.productModules, productModules)) {
      const modules = productModules.map(module => {
        const packages = module.prices.map(price => price.package);

        return {
          ...module,
          polls: productPolls.filter(poll => poll.moduleId === module.id),
          packages,
        };
      });

      initialize({
        productModules: modules,
        additionalPolls: productPolls
          ? productPolls.filter(poll => !poll.moduleId)
          : [],
      });
    }
  }

  componentWillUnmount() {
    const { clearProductVersionModules } = this.props;
    clearProductVersionModules();
  }

  handleSavePolls = () => {
    const {
      createPollPackage,
      values: { additionalPolls, productModules },
      match,
    } = this.props;
    const { versionId } = match.params;
    const polls = [];

    additionalPolls.forEach(additionalPoll =>
      polls.push({
        ...additionalPoll,
        poll:
          typeof additionalPoll.poll === "number"
            ? additionalPoll.poll
            : additionalPoll.poll.id,
      }),
    );

    productModules.forEach(module => {
      if (module.polls.length > 0) {
        module.polls.forEach(item =>
          polls.push({
            ...item,
            moduleId: module.id,
            poll: item.poll.id || item.poll,
          }),
        );
      }
    });

    createPollPackage({
      pollsPackages: polls,
      productVersionId: versionId,
    });
  };

  render() {
    const {
      contractPackages,
      coursePolls,
      change,
      modulePolls,
      productVersionsMeta,
      currentStatus,
    } = this.props;

    const cantEdit =
      !havePermissions([permissions.PRODUCT_DETAILS_EDIT]) ||
      currentStatus.id !== PRODUCT_STATUSES.DRAFT;

    const { isLoading } = this.state;
    return isLoading ? (
      <Loader />
    ) : (
      <div>
        <ProductsPolls
          modulePolls={modulePolls}
          packages={contractPackages}
          moduleWithoutLectures={productVersionsMeta.moduleWithoutLectures}
          change={change}
          cantEdit={cantEdit}
          savePolls={this.handleSavePolls}
        />
        <FieldArray
          name="additionalPolls"
          component={ProductsAdditionalPolls}
          props={{
            packages: contractPackages,
            pollsList: coursePolls,
            cantEdit: cantEdit,
            saveAdditionalPolls: this.handleSavePolls,
          }}
        />
      </div>
    );
  }
}

export default withRouter(PollsForm);
