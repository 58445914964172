import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function courseModesReducer(
  state = coursesInitState.courseModes,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_COURSE_MODES:
      return action.payload.data.data;
    default:
      return state;
  }
}
