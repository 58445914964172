import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function lecturersReducer(state = coursesInitState.lecturers, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_EMPLOYEES_FOR_EVENT:
      return [...action.payload.data.data];

    default:
      return state;
  }
}
