import COMPANIES_ACTION_TYPES from "../Actions/ActionTypes";
import { companiesInitState } from "./Companies";

export function companiesList(
  state = companiesInitState.companiesList,
  action,
) {
  switch (action.type) {
    case COMPANIES_ACTION_TYPES.FETCH_COMPANIES: {
      return {
        ...action.payload.data.data,
      };
    }
    default:
      return state;
  }
}
