import React, { Component } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import LANGUAGES from "../../../Enums/Languages";
import { changeLanguage } from "../Actions";
import { bindActionCreators, compose } from "redux";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { getReportLinkForPermissions } from "../../../Common/Utils/Acl";
import i18n from "../../../Configs/i18n";
import restApiConfig from "../../../Configs/restApiConfig";

class MainTemplateNavbar extends Component {
  static propTypes = {
    history: PropTypes.object,
    userName: PropTypes.string,
    imageUrl: PropTypes.string,
    languages: PropTypes.string,
    t: PropTypes.func,
    language: PropTypes.string,
    changeLanguage: PropTypes.func,
  };

  reloadApp = () => {
    const { history } = this.props;
    const currUrl = history.location.pathname;
    this.props.history.push("/loader");
    this.timeoutId = setTimeout(() => {
      this.props.history.push(currUrl);
    }, 0);
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const { userName, imageUrl, changeLanguage, language, t } = this.props;
    const courses = (
      <Authorize
        component={
          <li>
            <NavLink exact={false} to="/courses" activeClassName="active">
              {t("Courses")}
            </NavLink>
          </li>
        }
        allows={[permissions.COURSE_LIST]}
      />
    );

    const persons = (
      <Authorize
        component={
          <li>
            <NavLink to="/persons" activeClassName="active">
              {t("Persons")}
            </NavLink>
          </li>
        }
        allows={[permissions.PERSON_LIST]}
      />
    );

    const products = (
      <Authorize
        component={
          <li>
            <NavLink to="/products" activeClassName="active">
              {t("Products")}
            </NavLink>
          </li>
        }
        allows={[permissions.PRODUCT_LIST]}
      />
    );

    const reports = (
      <Authorize
        component={
          <li>
            <NavLink
              to={getReportLinkForPermissions()}
              activeClassName="active"
            >
              {t("Reports")}
            </NavLink>
          </li>
        }
        allows={[
          permissions.REPORT_TREND,
          permissions.REPORT_GROUPFILL,
          permissions.REPORT_TIMELINE,
          permissions.REPORT_GRADUATES,
          permissions.REPORT_INVOICES_XLS,
        ]}
      />
    );

    const dictionaries = (
      <Authorize
        component={
          <li>
            <NavLink to="/dictionaries" activeClassName="active">
              {t("Dictionaries")}
            </NavLink>
          </li>
        }
        allows={[permissions.DICTIONARY_VIEW]}
      />
    );

    const companies = (
      <Authorize
        component={
          <li>
            <NavLink to="/companies" activeClassName="active">
              {t("Companies")}
            </NavLink>
          </li>
        }
        allows={[permissions.COMPANY_VIEW, permissions.COMPANY_EDIT]}
      />
    );

    const selectedLang = _.get(restApiConfig, "selectedLang");

    return (
      <aside className="slide-menu">
        <nav>
          <header className="text-center brand">
            <Link to="/">
              <img
                src={`/assets/images/logo-coderslab${
                  i18n.language === "en" ? "-en" : ""
                }.png`}
                className=" brand__logo"
                alt=""
              />
            </Link>
          </header>
          <div className="user text-center">
            <header className="user__avatar">
              <img src={imageUrl} alt="Profile picture" />
            </header>
            <h4 className="user__name">{userName}</h4>
            <span className="user__position">Client Service</span>
          </div>
          {!selectedLang && (
            <LanguageSwitcher
              languages={LANGUAGES}
              currentLanguage={language}
              changeLanguage={changeLanguage}
              reloadApp={this.reloadApp}
            />
          )}
          <ul className="nav main-menu">
            {courses}
            {products}
            {persons}
            {reports}
            {companies}
            {dictionaries}
          </ul>
        </nav>
      </aside>
    );
  }
}

const LanguageSwitcher = ({
  languages,
  currentLanguage,
  changeLanguage,
  reloadApp,
}) => (
  <div className="language-switcher">
    <div className="btn-group">
      {Object.values(languages).map(language => {
        return currentLanguage !== language.name ? (
          <a
            key={language.code}
            href="#"
            className="language-switcher__item"
            onClick={event => {
              event.preventDefault();
              changeLanguage(language.name, reloadApp);
            }}
          >
            {language.name.toUpperCase()}
          </a>
        ) : (
          <span
            key={language.code}
            className="language-switcher__item language-switcher__item--active"
          >
            {language.name.toUpperCase()}
          </span>
        );
      })}
    </div>
  </div>
);

function mapStateToProps({ userName, imageUrl, userPermissions, language }) {
  return {
    userName,
    imageUrl,
    userPermissions,
    language,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeLanguage }, dispatch);
}

LanguageSwitcher.propTypes = {
  languages: PropTypes.object,
  currentLanguage: PropTypes.string,
  changeLanguage: PropTypes.func,
  reloadApp: PropTypes.func,
};

export default withRouter(
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(MainTemplateNavbar),
);
