import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as notifications } from "react-notification-system-redux";
import {
  fetchDictionaryReducer,
  getEmployeeId,
  getStudentId,
  fetchDictionaryOnlyTeach,
  fetchPollEvents,
  fetchPolls,
  fetchContractPolls,
  fetchContractModulesPolls,
  fetchContractSlavePolls,
  fetchModulePolls,
  fetchCoursePolls,
  fetchUserEngageLeadTypes,
  fetchContractManualPolls,
  fetchDictComments,
  fetchDictPromotions,
} from "../Modules/Dictionaries/Reducers";
import {
  fetchEmployees,
  fetchPersons,
  fetchPerson,
  fetchPersonTypes,
  getMentorForProduct,
  getCoursesSpecialists,
  getLecturersForProductVersion,
  getPermissionsForPerson,
  fetchMentors,
  fetchOffices,
} from "../Modules/Persons/Reducers";
import {
  fetchProductById,
  fetchProductVersions,
  getLastVersionOfProduct,
  fetchLecturesForImport,
  fetchProductActiveVersion,
  fetchProductsList,
  fetchProductModules,
  fetchProductWithdrawModules,
  fetchLecturesCompetencies,
  getLectureData,
  fetchProductsForCourses,
  fetchAllActiveProductsList,
  fetchAllProductsFullInfo,
  fetchProductVersionsMeta as productVersionsMeta,
  fetchProductModulesSimple,
  fetchProductPolls,
  productEvents,
} from "../Modules/Products/Reducers";
import {
  fetchJWTReducer,
  getTokenExpiryTime,
  fetchRefreshToken,
  fetchUserIdReducer,
  fetchPersonIdReducer,
  fetchEmployeeIdReducer,
  fetchUserNameReducer,
  fetchImageUrlReducer,
  getPermissionsList,
  getUserPermissions,
  getRolesList,
} from "../Modules/Auth/Reducer";
import {
  getDatesForCourseSchedule,
  getCourse,
  fetchCourses,
  getStudentsForCourse,
  searchCourses,
  getLecturersAndRooms,
  fetchQuestionnaires,
  setStudentSortOption,
  getCourseStatistics,
} from "../Modules/Courses/Reducers/Reducers";
import {
  fetchContract,
  fetchContractLogs,
  fetchQuestionnaire,
  fundingCalculate,
  contractPackagesTransitions,
  contractPackages,
  fetchInitialTestResult,
  fetchIncomesThirdParty,
  __metadataReducer as __metadata,
} from "../Modules/Contracts/Reducers";
import {
  showErrorReducer,
  returnProductSaveState,
  fetchErrorCodes,
  sendLog,
  maintenanceMode,
  changeLanguage,
  tenantSettings,
} from "../Modules/App/Reducers";
import {
  invoices,
  invoiceReceivers,
  invoiceLegalBasis,
} from "../Modules/Invoices/Reducers";
import {
  fetchReport,
  fetchGroupDetailsData,
  fetchReportGraduates,
  fetchCurrentPollResults,
  fetchCoursePollResults,
  fetchBankIncomes,
  fetchBankIncomesTransfers,
  fetchRefunds,
  fetchInvoices,
  fetchContractPayments,
  fetchBalances,
  calendar,
  timeline,
  fetchComment,
  timelineStats,
  timelineComments,
  fetchContractPaymentsNote,
  fetchContractPaymentsFilters,
} from "../Modules/Reports/Reducers";
import {
  balance,
  balanceSettings,
  invoiceAmount,
} from "../Modules/Balance/Reducers";
import { companiesReducer as companies } from "../Modules/Companies/Reducers/Companies";
import ajaxStatus from "../Modules/App/AjaxStatus/AjaxStatusReducer";
import { coursesReducer as courses } from "../Modules/Courses/Reducers/Courses";
import {
  invoiceStatusPossiblePlaces,
  invoiceSendStatusPossiblePlaces,
} from "../Modules/Reports/Invoice/Reducers";

const rootReducer = combineReducers({
  dictionaries: fetchDictionaryReducer,
  employees: fetchEmployees,
  form: formReducer,
  token: fetchJWTReducer,
  refreshToken: fetchRefreshToken,
  tokenExpiryTime: getTokenExpiryTime,
  permissionsList: getPermissionsList,
  userId: fetchUserIdReducer,
  personId: fetchPersonIdReducer,
  employeeId: fetchEmployeeIdReducer,
  userName: fetchUserNameReducer,
  imageUrl: fetchImageUrlReducer,
  userPermissions: getUserPermissions,
  roles: getRolesList,
  currentProductVersion: fetchProductById,
  currentProductVersions: fetchProductVersions,
  currentProductVersionActive: fetchProductActiveVersion,
  lastVersionOfProduct: getLastVersionOfProduct,
  lecturesForImport: fetchLecturesForImport,
  persons: fetchPersons,
  person: fetchPerson,
  personPermissions: getPermissionsForPerson,
  personTypes: fetchPersonTypes,
  employeeTypeId: getEmployeeId,
  studentTypeId: getStudentId,
  notifications: notifications,
  saveState: returnProductSaveState,
  mentors: getMentorForProduct,
  coursesSpecialists: getCoursesSpecialists,
  courseScheduleDates: getDatesForCourseSchedule,
  courseStudents: getStudentsForCourse,
  courseStudentsSortOption: setStudentSortOption,
  coursePollResults: fetchCoursePollResults,
  lecturersAndRoomsForCourse: getLecturersAndRooms,
  lecturersForCurrentProductVersion: getLecturersForProductVersion,
  currentCourse: getCourse,
  contract: fetchContract,
  foundCourses: searchCourses,
  productModules: fetchProductModules,
  withdrawCourseModules: fetchProductWithdrawModules,
  productVersionModulesContract: fetchProductModulesSimple,
  coursesList: fetchCourses,
  contractLogs: fetchContractLogs,
  errorCodes: fetchErrorCodes,
  maintenanceMode,
  showError: showErrorReducer,
  error: showErrorReducer,
  questionnaire: fetchQuestionnaire,
  payments: fundingCalculate,
  lecturesCompetencies: fetchLecturesCompetencies,
  lectureData: getLectureData,
  invoices,
  errorLog: sendLog,
  language: changeLanguage,
  reportData: fetchReport,
  courseQuestionnaires: fetchQuestionnaires,
  contractPackagesTransitions,
  contractPackages,
  calendar,
  timeline,
  timelineStats,
  timelineComments,
  onlyTeachCities: fetchDictionaryOnlyTeach,
  initialTestsResults: fetchInitialTestResult,
  groupDetailsData: fetchGroupDetailsData,
  reportGraduates: fetchReportGraduates,
  comment: fetchComment,
  productsList: fetchProductsList,
  productsListActive: fetchAllActiveProductsList,
  productsListForCourse: fetchProductsForCourses,
  mentorsList: fetchMentors,
  incomesThirdPartyArray: fetchIncomesThirdParty,
  pollsList: fetchPolls,
  pollEvents: fetchPollEvents,
  contractPolls: fetchContractPolls,
  contractModulesPolls: fetchContractModulesPolls,
  contractSlavePolls: fetchContractSlavePolls,
  contractManualPolls: fetchContractManualPolls,
  userEngageLeadTypes: fetchUserEngageLeadTypes,
  dictComments: fetchDictComments,
  dictPromotions: fetchDictPromotions,
  modulePolls: fetchModulePolls,
  coursePolls: fetchCoursePolls,
  currentPollResults: fetchCurrentPollResults,
  courseStatistics: getCourseStatistics,
  allProductsFullInfo: fetchAllProductsFullInfo,
  bankIncomes: fetchBankIncomes,
  bankIncomesTransfers: fetchBankIncomesTransfers,
  refunds: fetchRefunds,
  invoicesReportResults: fetchInvoices,
  contractPaymentsReportResults: fetchContractPayments,
  contractPaymentsNote: fetchContractPaymentsNote,
  contractPaymentFilters: fetchContractPaymentsFilters,
  invoiceStatusPossiblePlaces,
  invoiceSendStatusPossiblePlaces,

  // TODO: group all invoices reducers
  balance,
  balancesReportResults: fetchBalances,
  balanceSettings,
  tenantSettings,
  invoiceReceivers,
  invoiceLegalBasis,
  invoiceAmount,
  __metadata,
  productVersionsMeta,
  companies,
  ajaxCallInProgressCount: ajaxStatus,
  courses,
  fetchProductPolls,
  productEvents,
  offices: fetchOffices,
});

export default rootReducer;
