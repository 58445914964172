import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchContract(cancelToken, config, callback = null) {
  const { contractId } = config;
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/${contractId}`,
    actionType: ACTION_TYPES.FETCH_CONTRACT,
    callback,
    cancelToken,
  });
}

export function fetchStudentContracts(cancelToken, studentId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/student/courses/${studentId}`,
    actionType: ACTION_TYPES.FETCH_STUDENT_CONTRACTS,
    callback,
    cancelToken,
  });
}

export function updateContract(values = {}, actionParams, callback) {
  const { contractId } = actionParams;
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}`,
    actionType: ACTION_TYPES.SAVE_CONTRACT,
    values: values,
    callback: callback,
  };
  return Client.actionPUT(config);
}

export function updatePersonAddress(studentId, values = {}, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/person/address/${studentId}`,
    actionType: ACTION_TYPES.ADD_STUDENT_ADDRESS_IN_CONTRACT,
    values: values,
    callback: callback,
  };
  return Client.actionPOST(config);
}

export function updateFinishedContract(contractId, values = {}, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/finish`,
    actionType: ACTION_TYPES.UPDATE_FINISHED_CONTRACT,
    values: values,
    callback: callback,
  };
  return Client.actionPUT(config);
}

export function fundingCalculate(cancelToken, values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/contract/${values.contractId}/fundingCalc`,
    actionType: ACTION_TYPES.FUNDING_CALCULATE,
    values,
    callback,
    cancelToken,
  });
}

export function fetchPayments(cancelToken, contractId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/${contractId}/payments`,
    actionType: ACTION_TYPES.FETCH_PAYMENTS,
    callback,
    cancelToken,
  });
}

export function updatePayments(values, contractId, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/contract/${contractId}/payments`,
    actionType: ACTION_TYPES.UPDATE_PAYMENTS,
    values,
    callback,
  });
}

export function addIncome(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/manualIncome`,
    actionType: ACTION_TYPES.ADD_MANUAL_INCOME,
    values,
    callback,
  });
}

export function contractOverpay(actionParams) {
  const { contractId, callback } = actionParams;
  const config = {
    path: `${restApiConfig.API_URL}/contract/overpay/${contractId}`,
    actionType: ACTION_TYPES.CONTRACT_OVERPAY,
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function getContractLogs(cancelToken, config, callback = null) {
  const { contractId, eventGroupId } = config;
  const url = `${contractId}`;
  const getPath = !eventGroupId ? url : `${url}/${eventGroupId}`;

  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/logs/${getPath}`,
    actionType: ACTION_TYPES.FETCH_COURSES_LOGS,
    callback,
    cancelToken,
  });
}
export function fetchInitialTestResult(cancelToken, personId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/initialTest/result/${personId}`,
    actionType: ACTION_TYPES.FETCH_INITIAL_TEST_RESULT,
    callback,
    cancelToken,
  });
}

export function clearfetchInitialTestResult() {
  return {
    type: ACTION_TYPES.CLEAR_INITIAL_TEST_RESULT,
    payload: null,
  };
}

export function fetchQuestionnaire(
  cancelToken,
  courseContractId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/questionnaire/answers/${courseContractId}`,
    actionType: ACTION_TYPES.FETCH_QUESTIONNAIRE,
    callback,
    cancelToken,
  });
}

export function clearQuestionnaire() {
  return {
    type: ACTION_TYPES.CLEAR_QUESTIONNAIRE,
    payload: null,
  };
}

export function clearContract() {
  return {
    type: ACTION_TYPES.CLEAR_CONTRACT,
    payload: null,
  };
}

export function saveQuestionnaire(actionParams) {
  const { answers, courseContractId, callback } = actionParams;

  const config = {
    path: `${restApiConfig.API_URL}/questionnaire/answers/${courseContractId}`,
    actionType: ACTION_TYPES.SAVE_QUESTIONNAIRE,
    values: { answers },
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function getDiploma(diplomaId) {
  const config = {
    path: `${restApiConfig.API_URL}/report/course/diploma/${diplomaId}`,
    actionType: ACTION_TYPES.DOWNLOAD_DIPLOMA,
    values: {},
    blobHeader: "blob",
    fileName: "diploma.pdf",
  };

  return Client.actionPOST(config);
}

export function addAdditionalPayment(contractId, values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/payments`,
    actionType: ACTION_TYPES.FETCH_PAYMENTS,
    values,
    callback,
  };

  return Client.actionPOST(config);
}

export function fetchContractPackages(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/package`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_PACKAGES,
    callback,
    cancelToken,
  });
}

export function fetchContractPackagesTransitions(
  cancelToken,
  contractId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/package/transitions/${contractId}`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_PACKAGES_TRANSITIONS,
    callback,
    cancelToken,
  });
}

export function fetchContractMeta(cancelToken, contractId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/meta${
      contractId ? `?contractId=${contractId}` : ``
    }`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_META,
    callback,
    cancelToken,
  });
}

export function paymentActivate(contractId, paymentId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/payments/${paymentId}/activate`,
    actionType: ACTION_TYPES.PAYMENT_ACTIVATE,
    callback,
  };
  return Client.actionPUT(config);
}

export function paymentCancel(contractId, paymentId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/payments/${paymentId}/cancel`,
    actionType: ACTION_TYPES.PAYMENT_CANCEL,
    callback,
  };
  return Client.actionPUT(config);
}

export function payerCalc(contractId, values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/payerCalc`,
    actionType: ACTION_TYPES.PAYER_CALC,
    values,
    callback,
  };

  return Client.actionPOST(config);
}

export function createSlave(contractId, values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/${contractId}/slave`,
    actionType: ACTION_TYPES.CREATE_SLAVE,
    values,
    callback,
  };

  return Client.actionPOST(config);
}

export function updateSlave(slaveId, values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/slave/${slaveId}`,
    actionType: ACTION_TYPES.SAVE_SLAVE,
    values,
    callback,
  };

  return Client.actionPUT(config);
}

export function removeSlave(slaveId, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/slave/${slaveId}`,
    actionType: ACTION_TYPES.REMOVE_SLAVE,
    callback,
  };

  return Client.actionDELETE(config);
}
export function createOrder(values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/order`,
    actionType: ACTION_TYPES.CREATE_ORDER,
    values,
    callback,
  };

  return Client.actionPOST(config);
}

export function getContract(contractId, type, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/${contractId}/${type}/doc`,
    actionType: ACTION_TYPES.GET_CONTRACT_DOC,
    blobHeader: "blob",
    callback,
  });
}

export function getContractAmounts(cancelToken, contractId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/${contractId}/amounts`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_AMOUNTS,
    callback,
    cancelToken,
  });
}
