import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoicesReport from "./components/InvoicesReport";
import {
  fetchInvoices,
  downloadInvoices,
  deleteInvoice,
  downloadInvoicesPackage,
  updateInvoiceStatus,
  getInvoicePossibleStatus,
  getInvoicePossibleSendStatus,
} from "../Actions";
import { downloadInvoice } from "../../Invoices/Actions";
function mapStateToProps(state) {
  const {
    invoicesReportResults,
    invoiceStatusPossiblePlaces,
    invoiceSendStatusPossiblePlaces,
  } = state;
  return {
    invoicesReportResults,
    invoiceStatusPossiblePlaces,
    invoiceSendStatusPossiblePlaces,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoices,
      downloadInvoice,
      downloadInvoices,
      downloadInvoicesPackage,
      deleteInvoice,
      updateInvoiceStatus,
      getInvoicePossibleStatus,
      getInvoicePossibleSendStatus,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoicesReport);
