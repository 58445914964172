import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GroupFillForm from "../Components/GroupFillForm";
import {
  fetchProductsForCourses,
  fetchAllProductsFullInfo,
} from "../../Products/Actions";
import { fetchDictionaryOnlyTeach } from "../../Dictionaries/Actions";
import { fetchGroupDetailsData, getGroupFillReport } from "../Actions";

import { formValueSelector, reduxForm } from "redux-form";

const FORM_NAME = "GROUP_DETAILS_REPORT";

const GroupFill = props => <GroupFillForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    onlyTeachCities,
    groupDetailsSortTypes,
    groupDetailsData,
    productsListForCourse,
    allProductsFullInfo,
  } = state;
  const values = selector(state, "cityId", "sort", "started", "productId");
  return {
    values,
    onlyTeachCities,
    groupDetailsSortTypes,
    groupDetailsData,
    productsListForCourse,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionaryOnlyTeach,
      fetchGroupDetailsData,
      getGroupFillReport,
      fetchProductsForCourses,
      fetchAllProductsFullInfo,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GroupFill),
);
