import { LectureFormValues } from "../Types/EditLecture";
import { FormikErrors } from "formik";

export default (fields: LectureFormValues) => {
  const errors: FormikErrors<LectureFormValues> = {};
  const { date } = fields;

  if (!date) {
    errors.date = "Set the date time";
  }

  return errors;
};
