import { renderFieldsHelper, RenderError } from "./forms";
import { translate } from "react-i18next";
import React from "react";
import { XCircle } from "react-feather";

const RenderTimePickerInputToTranslate = field => {
  const { displayError, error, classes } = renderFieldsHelper(field);
  let minutesInString = "";
  let hoursInString = "";
  const { t, customClass, shouldDisabled, onClearCallback, limited } = field;
  const limitedHours = typeof limited === "undefined" ? true : limited;

  const { value } = field.input;

  if (!!value) {
    const [hoursFromValue, minutesFromValue] = value.split(":");
    hoursInString = hoursFromValue;
    minutesInString = minutesFromValue;
  }

  const onChangeHours = event => {
    const { value } = event.target;
    const parsedValue = _.toNumber(value);
    let minutesForChange = "00";

    if (!!value) {
      minutesForChange = minutesInString;
    }

    if (_.isInteger(parsedValue)) {
      const hours = parsedValue < 10 ? `0${parsedValue}` : `${parsedValue}`;
      if (limitedHours && hours < 24 && hours >= 0) {
        field.input.onChange(`${hours}:${minutesForChange}`);
      } else if (!limitedHours && hours >= 0) {
        field.input.onChange(`${hours}:${minutesForChange}`);
      }
    }
  };

  const onBlurHours = () => {
    if (!!value) {
      const [hoursFromValue, minutesFromValue] = value.split(":");
      const time = `${hoursFromValue}:${
        !!minutesFromValue ? minutesFromValue : "00"
      }`;
      field.input.onChange(time);
    }
  };

  const onChangeMinutes = event => {
    const { value } = event.target;
    const parsedValue = _.toNumber(value);
    let hoursForChange = "00";

    if (!!value) {
      hoursForChange = hoursInString;
    }

    if (_.isInteger(parsedValue)) {
      const minutes = parsedValue < 10 ? `0${parsedValue}` : `${parsedValue}`;
      if (minutes < 60 && minutes >= 0) {
        const time = `${hoursForChange}:${minutes}`;
        field.input.onChange(time);
      }
    }
  };

  return (
    <div
      className={`${customClass ? customClass : ""} ${classes ? classes : ""} `}
    >
      <div className={`time-picker-input ${displayError ? "has-error" : ""}`}>
        <input
          onChange={onChangeHours}
          onBlur={onBlurHours}
          className="form-control"
          name={`${field.input.name}.hours`}
          disabled={shouldDisabled}
          placeholder="h"
          value={hoursInString}
        />
        <div className={`split ${shouldDisabled ? "disabled" : ""}`}>:</div>
        <input
          onChange={onChangeMinutes}
          className="form-control"
          name={`${field.input.name}.minutes`}
          disabled={shouldDisabled}
          placeholder="min"
          value={minutesInString}
        />
        {onClearCallback && !shouldDisabled && (
          <div className="clear-button-container">
            <div onClick={onClearCallback} className="clear-button">
              <XCircle size={15} />
            </div>
          </div>
        )}
      </div>
      {displayError ? <RenderError t={t} error={error} /> : ""}
    </div>
  );
};

export default translate()(RenderTimePickerInputToTranslate);
