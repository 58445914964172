import getLanguageNameByCode from "./GetLanguageNameByCode";
import setLanguageToLocalStore from "./SetLanguageToLocalStore";
import LANGUAGES from "../Enums/Languages";
import restApiConfig from "../Configs/restApiConfig";

export default (languages = LANGUAGES) => {
  const selectedLang = _.get(restApiConfig, "selectedLang");
  if (selectedLang) {
    return selectedLang;
  }

  if (localStorage.language) {
    return localStorage.language;
  }
  const browserLanguage = navigator.language;
  const currLanguage = browserLanguage
    ? getLanguageNameByCode(browserLanguage, languages)
    : languages.pl.name;
  setLanguageToLocalStore(currLanguage);
  return currLanguage;
};
