import validate from "../Validators/ProductFormValidator";
import { bindActionCreators, compose } from "redux";
import ProductForm from "../Components/ProductForm";
import { translate } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import ProductStatuses from "../../../Enums/ProductStatuses";
import {
  changeProductVersionStatus,
  deleteProductVersion,
  fetchProductById,
  saveProductVersion,
  updateProductVersion,
} from "../../Products/Actions";
import { ProductFormValues } from "../Types/ProductFormValues";
import { ProductFormProps } from "../Types/ProductFormComponent";

const FORM_NAME = `Product`;
const selector = formValueSelector(FORM_NAME);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveProductVersion,
      updateProductVersion,
      changeProductVersionStatus,
      deleteProductVersion,
      fetchProductById,
    },
    dispatch,
  );

const mapStateToProps = (
  store,
  ownProps: RouteComponentProps & { isNew: boolean },
) => {
  const { isNew } = ownProps;
  const {
    dictionaries,
    employees,
    currentProductVersion,
    currentProductVersions,
  } = store;
  const { languages, coursesKinds, currencies } = dictionaries;
  const values = selector(
    store,
    "id",
    "name",
    "currency",
    "price",
    "course20",
    "kind",
    "shortcut",
    "currency",
    "businessOwner",
    "essentialOwner",
    "numberOfPlaces",
    "fees",
    "language",
    "thematicScope",
    "summary",
  );

  return {
    languages,
    coursesKinds,
    currencies,
    values,
    employees,
    cantEdit: !havePermissions([permissions.PRODUCT_DETAILS_EDIT]),
    currentStatus:
      currentProductVersion && currentProductVersion.id
        ? currentProductVersion.status
        : { id: ProductStatuses.DRAFT },
    initialValues: currentProductVersion,
    currentProductVersions,
    isNew,
  };
};

export default reduxForm<ProductFormValues, ProductFormProps>({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  )(ProductForm),
);
