import React from "react";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { FieldArrayRenderProps } from "formik";
import { get } from "lodash";

type Option = { name: string; id: number };

type CourseDaysProps = InjectedTranslateProps &
  FieldArrayRenderProps & {
    options: Array<Option>;
    disabled: boolean;
    daysError: string;
  };

const CourseDays: React.FunctionComponent<CourseDaysProps> = (
  props,
): React.ReactElement => {
  const {
    t,
    disabled,
    name,
    remove,
    push,
    form: { values },
    daysError,
    options,
  } = props;
  const classes = daysError ? "has-error" : "";
  const chosenOptions = get(values, "courseSchedule.days", []);

  return (
    <div className={`form-group row course-schedule__days ${classes}`}>
      <div className="col-xs-12">
        {options.map(item => {
          const indexInFields = chosenOptions.findIndex(
            option => option.id === item.id,
          );
          const fieldName = `${name}--${item.id}`;
          const isChecked = indexInFields > -1;
          const onChange = () =>
            isChecked ? remove(indexInFields) : push(item);

          return (
            <label
              key={item.id}
              htmlFor={fieldName}
              className={`course-schedule__day course-schedule__day--${item.id}`}
            >
              <span>{item.name}</span> <br />
              <input
                type="checkbox"
                name={fieldName}
                id={fieldName}
                checked={isChecked}
                onChange={onChange}
                disabled={disabled}
              />
            </label>
          );
        })}
        {daysError && <span className="help-block">{t(daysError)}</span>}
      </div>
    </div>
  );
};

export default CourseDays;
