import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import CheckCountryIsPoland from "../../../Helpers/CheckCountryIsPoland";
import CitySelectWithPagination from "../../../Forms/CitySelectWithPagination";
import CityAutoComplete from "../../../Common/Components/CityAutoComplete/CityAutoComplete";
import { RenderCheckbox, RenderSelect, RenderText } from "../../../Forms/forms";
import * as HttpStatus from "http-status-codes";
import { withRouter } from "react-router";
import { Authorize, permissions } from "../../../Common/Utils/Acl";

class CompanyForm extends Component {
  getTaxRateSelectConfig = () => {
    const { taxRates } = this.props;
    const selectOptions = taxRates
      ? taxRates.map(taxRate => ({ taxRate }))
      : [];
    return {
      data: selectOptions,
      textField: "taxRate",
      valueField: "taxRate",
    };
  };

  getCountrySelectConfig = () => {
    const { change, countries } = this.props;

    return {
      data: countries,
      filter: "contains",
      textField: "name",
      valueField: "id",
      onChange: value => {
        change(`address.city`, null);
        change(`address.country.id`, value.id);
      },
    };
  };

  getCitySelectProps = () => {
    const {
      change,
      cities,
      values: { address },
      fetchDictionary,
    } = this.props;

    const city = address.city ? address.city : {};

    return {
      cities,
      fetchDictionary,
      value: city.name,
      onChangeHandler: value => change(`address.city`, value),
    };
  };

  renderCityInput = () => {
    const {
      t,
      change,
      countries,
      values: { address },
    } = this.props;
    const showCityAutoComplete =
      address &&
      address.country &&
      CheckCountryIsPoland(countries, address.country.id);

    return showCityAutoComplete ? (
      <div className="col-xs-12">
        <Field
          label={t("City")}
          name="address.city.name"
          component={CitySelectWithPagination}
          props={this.getCitySelectProps()}
        />
      </div>
    ) : (
      <CityAutoComplete
        change={change}
        name={`address.city.name`}
        label={t("City")}
      />
    );
  };

  onSubmit = formData => {
    const {
      updateCompany,
      isNew,
      history,
      addNewCompany,
      refreshCompanyData,
    } = this.props;
    const {
      id,
      name,
      taxNr,
      regonNr,
      taxRate,
      isTaxNrEUActive,
      address: { city, street, zipCode, country },
    } = formData;

    const payload = {
      id,
      name,
      taxNr,
      regonNr,
      taxRate,
      addressStreet: street,
      addressZipCode: zipCode,
      addressCity: city.name,
      addressCountry: country.id,
      isTaxNrEUActive: !!isTaxNrEUActive,
    };

    if (isNew) {
      addNewCompany(payload, response => {
        if (response.status === HttpStatus.OK) {
          const {
            data: { data: newCompany },
          } = response;

          refreshCompanyData(newCompany);

          history.replace(`/companies/${newCompany.id}`);
        }
      });
    } else {
      updateCompany(payload);
    }
  };

  render() {
    const { t, handleSubmit, isNew } = this.props;

    return (
      <div id="add-new-company-form" className={"col-lg-6 col-md-6 col-xs-12"}>
        <Field label={t("NameItem")} name="name" component={RenderText} />

        <Field
          label={t("Address")}
          name="address.street"
          component={RenderText}
        />

        <Field
          label={t("Zip code")}
          name="address.zipCode"
          component={RenderText}
        />

        <Field
          name="address.country.id"
          label={t("Country")}
          dropdownConfig={this.getCountrySelectConfig()}
          component={RenderSelect}
        />

        {this.renderCityInput()}

        <Field label={t("REGON")} name="regonNr" component={RenderText} />

        <Field label={t("TIN")} name="taxNr" component={RenderText} />

        <Field
          label={t("% VAT")}
          name="taxRate"
          component={RenderSelect}
          dropdownConfig={this.getTaxRateSelectConfig()}
        />

        <Field
          label={t("UE VAT")}
          name="isTaxNrEUActive"
          component={RenderCheckbox}
        />
        <div className={`margin__t25`}>
          <Authorize
            allows={[permissions.COMPANY_EDIT]}
            component={
              <button
                onClick={handleSubmit(this.onSubmit)}
                id="add-company-form-submit-button"
                className="btn btn-dark"
              >
                {t(isNew ? "Add company" : "Save")}
              </button>
            }
          />
        </div>
      </div>
    );
  }
}

CompanyForm.propTypes = {
  t: PropTypes.func,
  fetchDictionary: PropTypes.func,
  cities: PropTypes.array,
  countries: PropTypes.array,
  taxRates: PropTypes.array,
  change: PropTypes.func,
  values: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  updateCompany: PropTypes.func,
  addNewCompany: PropTypes.func,
  refreshCompanyData: PropTypes.func,
  isNew: PropTypes.bool,
};

export default translate()(withRouter(CompanyForm));
