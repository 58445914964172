import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import {
  fetchProductsForCourses,
  fetchAllProducts,
  clearProductsList,
} from "../../Products/Actions";
import { fetchCourses } from "../Actions/CoursesActions";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { forgetUser } from "../../Auth/Actions";
import {
  getMentorForProduct,
  getCoursesSpecialists,
  fetchMentors,
} from "../../Persons/Actions";
import { bindActionCreators } from "redux";

import CoursesList from "../Components/CoursesList";

const FORM_NAME = "SEARCH_COURSES";

let Courses = props => <CoursesList {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(
    state,
    "startDate",
    "endDate",
    "search",
    "productId",
    "cityId",
    "courseModeId",
    "courseStatusId",
    "mentorId",
    "courseSpecialistId",
    "sort",
  );

  const {
    coursesList,
    productsListForCourse,
    dictionaries,
    coursesSpecialists,
    timelineFile,
    mentorsList,
    productsList,
  } = state;
  const validationErrors = state.form[FORM_NAME].syncErrors;
  return {
    coursesList,
    productsListForCourse,
    dictionaries,
    coursesSpecialists,
    values,
    timelineFile,
    validationErrors,
    mentorsList,
    productsList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCourses,
      forgetUser,
      fetchProductsForCourses,
      fetchDictionary,
      getCoursesSpecialists,
      getMentorForProduct,
      fetchMentors,
      fetchAllProducts,
      clearProductsList,
    },
    dispatch,
  );
}

function validate(fields) {
  const errors = {};
  if (!fields.search) {
    errors.search = "Wpisz czego szukasz.";
  }
  return errors;
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  initialValues: {
    search: null,
    productId: null,
    cityId: null,
    courseModeId: null,
    courseStatusId: null,
    mentorId: null,
    courseSpecialistId: null,
    sort: null,
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Courses),
);
