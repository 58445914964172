import React, { Component, createContext } from "react";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import PersonBaseData from "./PersonBaseData";
import PersonEmployeeData from "./PersonEmployeeData";
import Loader from "../../../Common/Components/Loader/Loader.js";
import { Form } from "redux-form";
import { HelpCircle, Mail, Phone } from "react-feather";
import checkPersonIsEmployee from "../../../Helpers/CheckPersonIsEmployee";
import formatPhoneNumber from "../../../Helpers/FormatPhoneNumber";
import getCancelToken from "../../../Helpers/GetCancelToken";
import SwitchTabLink from "../../../Common/Components/SwtichTabLink/SwtichTabLink.js";
import PersonsCoursesList from "../Containers/PersonsCoursesList";
import { Prompt } from "react-router";
import {
  havePermissions,
  permissions,
  Authorize,
} from "../../../Common/Utils/Acl";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import Balance from "../../Balance/Containers/Balance";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import COMMON from "../../../Enums/Common";
import ChangePassword from "../Containers/ChangePassword";

const clearChanges = "clearChanges";
const quitWithoutSaving = "quitWithoutSaving";

export const PersonFormContext = createContext();

class PersonForm extends Component {
  static propTypes = {
    initialize: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    t: PropTypes.func,
    match: PropTypes.object,
    fetchPersons: PropTypes.func,
    fetchDictionary: PropTypes.func,
    fetchDictionaryOnlyTeach: PropTypes.func,
    fetchLecturesForImport: PropTypes.func,
    getRolesList: PropTypes.func,
    getUserPermissions: PropTypes.func,
    fetchOffices: PropTypes.func,
    getPermissionsList: PropTypes.func,
    forgetUser: PropTypes.func,
    employeeTypeId: PropTypes.number,
    personPermission: PropTypes.object,
    change: PropTypes.func,
    showError: PropTypes.object,
    submitFailed: PropTypes.bool,
    passErrorToStore: PropTypes.func,
    clearErrorFromStore: PropTypes.func,
    values: PropTypes.object,
    resetPerson: PropTypes.func,
    createPerson: PropTypes.func,
    updatePerson: PropTypes.func,
    updateUserRole: PropTypes.func,
    lecturesForImport: PropTypes.array,
    studentTypeId: PropTypes.number,
    dictionaries: PropTypes.object,
    onlyTeachCities: PropTypes.array,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    roles: PropTypes.array,
    dirty: PropTypes.bool,
    name: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    fetchPerson: PropTypes.func,
    personPermissions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    userId: PropTypes.number,
    coursesSpecialists: PropTypes.array,
    getCoursesSpecialists: PropTypes.func,
    valid: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    validationErrors: PropTypes.object,
    permissionsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    person: PropTypes.object,
    offices: PropTypes.array,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { initialize, initialValues, history, t } = this.props;
    const isNew = !props.match.params.personId;
    const defaultTabIndex = props.match.params.selectedTab;
    this.state = {
      tabIndex: defaultTabIndex ? Number(defaultTabIndex) : 0,
      showLinkToEmployee: false,
      showLinkToBase: false,
      shouldDisplay: isNew,
      isEmployee: false,
      isStudent: false,
      isNew,
    };

    this.baseFields = [
      "typeIds",
      "name",
      "surname",
      "sex",
      "emails",
      "phones",
      "linkedin",
      "github",
      "streetAddress",
      "cityAddress",
      "countryAddress",
      "zipCodeAddress",
      "streetContactAddress",
      "cityNameContactAddress",
      "countryContactAddress",
      "zipCodeContactAddress",
      "comment",
    ];
    this.employeeFields = [
      "isCourseSpecialist",
      "contractTypeId",
      "contractStartDate",
      "contractEndDate",
      "courseModeIds",
      "availabilityTypeId",
      "isMentor",
      "competenciesIds",
      "additionalCompetenciesIds",
      "offices",
    ];

    this.modalHeaders = {
      quitWithoutSaving: t("Are You sure You want to leave withour saving?"),
      clearChanges: t("Are You sure You want to clear the changes made?"),
    };

    this.modalBody = {
      quitWithoutSaving: t(
        "Unless You save the changes, recovering then will not be possible",
      ),
      clearChanges: t(
        "By clearing the changes You remove the possibility of recovering them",
      ),
    };

    this.modalOnConfirm = {
      quitWithoutSaving: () => {
        history.push("/persons");
      },
      clearChanges: () => {
        const { initialValues, initialize } = this.props;
        initialize(initialValues);
      },
    };
    this.cantEdit = !havePermissions([
      permissions.PERSON_DETAILS_EDIT,
      permissions.PERSON_EMP_DETAILS_EDIT,
    ]);
    initialize(initialValues);
  }

  componentDidUpdate(prevProps) {
    const { showError } = this.props;
    const { showError: showErrorPrev } = prevProps;

    if (showError) {
      const ERROR_CODE = 1004;
      const ERROR_INSTANCE = "Email";
      const { code, parameters } = showError;

      if (
        ERROR_CODE === code &&
        ERROR_INSTANCE === parameters.instance &&
        !showErrorPrev
      ) {
        const { initialValues, initialize } = this.props;

        initialize(initialValues);

        this.setState({
          modalHeaderText: clearChanges,
          modalBodyText: clearChanges,
          modalMethod: clearChanges,
          shouldDisplay: true,
        });
      }
    }
  }

  componentDidMount() {
    const {
      fetchPerson,
      match: {
        params: { personId },
      },
      fetchDictionary,
      fetchLecturesForImport,
      fetchDictionaryOnlyTeach,
      getUserPermissions,
      getPermissionsList,
      getCoursesSpecialists,
      getRolesList,
      fetchOffices,
    } = this.props;
    const { isNew } = this.state;
    const {
      FETCH_CITIES,
      FETCH_PERSON_TYPES,
      FETCH_EMPLOYEE_COMPETENCIES,
      FETCH_CONTRACT_TYPES,
      FETCH_COURSES_MODES,
      FETCH_EMPLOYEE_AVAILABILITY,
      FETCH_COUNTRIES,
      GET_PERSON_PERMISSIONS,
    } = ACTION_TYPES;
    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: FETCH_CITIES,
    });
    fetchDictionaryOnlyTeach(this.cancelToken);

    fetchDictionary(this.cancelToken, {
      path: "/person/type/get",
      actionType: FETCH_PERSON_TYPES,
    });
    fetchLecturesForImport(this.cancelToken);
    fetchDictionary(this.cancelToken, {
      path: "/employee/competencies",
      actionType: FETCH_EMPLOYEE_COMPETENCIES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/contract/type",
      actionType: FETCH_CONTRACT_TYPES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/mode",
      actionType: FETCH_COURSES_MODES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/availability/type",
      actionType: FETCH_EMPLOYEE_AVAILABILITY,
    });
    fetchDictionary(this.cancelToken, {
      path: "/countries",
      actionType: FETCH_COUNTRIES,
    });
    getRolesList();
    getCoursesSpecialists(this.cancelToken);
    getPermissionsList();
    fetchOffices();

    if (!isNew) {
      fetchPerson(this.cancelToken, personId).then(({ payload: { data } }) => {
        const { employeeTypeId } = this.props;
        const isEmployee = checkPersonIsEmployee(
          data.data.typeIds,
          employeeTypeId,
        );
        if (data.data.userId) {
          getUserPermissions(data.data.userId, GET_PERSON_PERMISSIONS);
        }
        this.setState({
          shouldDisplay: true,
          isEmployee,
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialValues, initialize, personPermissions, change } = this.props;
    const { isNew } = this.state;
    if (
      nextProps.submitFailed &&
      !nextProps.valid &&
      !this.props.showError &&
      this.props.submitFailed !== nextProps.submitFailed
    ) {
      this.props.passErrorToStore();
    } else if (
      nextProps.submitSucceeded &&
      nextProps.valid &&
      this.props.showError
    ) {
      this.props.clearErrorFromStore();
    }

    if (!isNew && nextProps.initialValues !== initialValues) {
      if (nextProps.initialValues.emails.length === 0) {
        nextProps.initialValues.emails.push("");
      }
      if (nextProps.initialValues.phones.length === 0) {
        nextProps.initialValues.phones.push("");
      }
      initialize(nextProps.initialValues);
    }
    if (personPermissions !== nextProps.personPermissions) {
      change("roles", nextProps.personPermissions.roles);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { values, employeeTypeId } = this.props;
    if (nextProps.submitFailed) {
      this.tabLinkSwitcher(nextProps.validationErrors, nextState);
    }
    if (nextProps.values && nextProps.values.typeIds !== values.typeIds) {
      const isEmployee = checkPersonIsEmployee(
        nextProps.values.typeIds,
        employeeTypeId,
      );
      this.setState({ isEmployee });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.resetPerson();
  }

  logout = () => {
    this.props.forgetUser();
  };

  onSubmit = values => {
    const correctedValues = this.prepareDataBeforeSaving(values);
    const {
      match: {
        params: { personId },
      },
      createPerson,
      updatePerson,
      updateUserRole,
      initialValues,
      history,
      change,
      getUserPermissions,
    } = this.props;
    const { GET_PERSON_PERMISSIONS } = ACTION_TYPES;

    const { isNew } = this.state;

    this.setState({ shouldDisplay: false });

    if (isNew) {
      createPerson(correctedValues, ({ data: { data } }) => {
        history.push(`/persons/update/${data.id}`);
      }).then(() => this.setState({ shouldDisplay: true }));
    } else {
      updatePerson(correctedValues, personId, ({ data: { data } }) => {
        if (!initialValues.userId) {
          change("userId", data.userId);
        }
        if (values.userId && values.roles !== initialValues.roles) {
          updateUserRole(
            {
              userId: values.userId,
              roles: values.roles,
            },
            () => {
              getUserPermissions(values.userId, GET_PERSON_PERMISSIONS);
            },
          );
        }
        this.setState({ shouldDisplay: true });
      }).then(resolve => {
        const { type } = resolve;
        if (type === ACTION_TYPES.PASS_ERROR_TO_STORE) {
          this.setState({ shouldDisplay: true });
        }
      });
    }
  };

  prepareDataBeforeSaving(values) {
    const preparingData = { ...values };
    if (
      preparingData.emails &&
      preparingData.emails.length === 1 &&
      preparingData.emails[0].length === 0
    ) {
      delete preparingData["emails"];
    }
    if (
      preparingData.phones &&
      preparingData.phones.length === 1 &&
      preparingData.phones[0].length === 0
    ) {
      delete preparingData["phones"];
    }
    if (this.state.isEmployee) {
      preparingData.isCourseSpecialist =
        String(preparingData.isCourseSpecialist) === "true";
    } else {
      this.employeeFields.forEach(employeeField => {
        if (
          Object.prototype.hasOwnProperty.call(preparingData, employeeField)
        ) {
          delete preparingData[employeeField];
        }
      });
    }
    if (
      preparingData.contractEndDate === "" ||
      preparingData.contractEndDate === 0
    ) {
      preparingData.contractEndDate = null;
    }

    if (preparingData.cityNameAddress) {
      preparingData.cityAddress = preparingData.cityNameAddress;
    }

    if (preparingData.cityNameContactAddress) {
      preparingData.cityContactAddress = preparingData.cityNameContactAddress;
    }

    if (preparingData.office && typeof preparingData.office === "object") {
      preparingData.office = preparingData.office.id;
    }

    for (const data in preparingData) {
      if (preparingData[data] === "") {
        preparingData[data] = null;
      }
    }
    return preparingData;
  }

  copyAddressData = () => {
    const {
      values: {
        streetAddress,
        cityNameAddress,
        zipCodeAddress,
        countryAddress,
      },
      change,
    } = this.props;
    change("streetContactAddress", streetAddress);
    change("cityNameContactAddress", cityNameAddress);
    change("zipCodeContactAddress", zipCodeAddress);
    change("countryContactAddress", countryAddress);
  };

  setFieldValue = (field, value) => {
    this.props.change(field, value);
  };

  toggleTestTab = () => {
    this.setState({ isStudent: !this.state.isStudent });
  };

  toggleEmployeeTab = () => {
    this.setState({ isEmployee: !this.state.isEmployee });
  };

  prepareLecturesForImport() {
    return this.props.lecturesForImport;
  }

  tabLinkSwitcher(validationErrors) {
    if (
      !validationErrors &&
      (this.state.showLinkToBase || this.state.showLinkToEmployee)
    ) {
      this.setState({
        showLinkToBase: false,
        showLinkToEmployee: false,
      });
      return;
    }
    if (validationErrors) {
      let linkToEmployee = false;
      let linkToBase = false;

      for (let error in validationErrors) {
        for (let i = 0; i < this.baseFields.length; i++) {
          if (this.baseFields[i] === error) {
            linkToBase = true;
            break;
          }
        }
        if (this.state.isEmployee) {
          for (let i = 0; i < this.employeeFields.length; i++) {
            if (this.employeeFields[i] === error) {
              linkToEmployee = true;
              break;
            }
          }
        }
      }

      if (linkToEmployee !== this.state.showLinkToEmployee) {
        this.setState({ showLinkToEmployee: linkToEmployee });
      }
      if (linkToBase !== this.state.showLinkToBase) {
        this.setState({ showLinkToBase: linkToBase });
      }
    }
  }

  personHeader = () => {
    const {
      person: { name, surname, phones, emails },
    } = this.props;
    const [mainPhone] = phones;
    const [mainEmail] = emails;

    const phoneSection = mainPhone && (
      <a href={`tel:${mainPhone}`} className="contract-heading__phone">
        <Phone size={14} />
        {formatPhoneNumber(mainPhone)}
      </a>
    );

    const emailSection = mainEmail && (
      <a href={`mailto:${mainEmail}`} className="contract-heading__email">
        <Mail size={14} />
        {mainEmail}
      </a>
    );

    return (
      <h1 className="contract-heading" id={`person-header`}>
        {`${name} ${surname}`}
        {phoneSection}
        {emailSection}
      </h1>
    );
  };

  render() {
    const {
      studentTypeId,
      employeeTypeId,
      change,
      dictionaries,
      submitting,
      pristine,
      initialValues,
      values,
      roles,
      dirty,
      handleSubmit,
      fetchDictionary,
      coursesSpecialists,
      t,
      onlyTeachCities,
      person,
    } = this.props;
    const { isNew } = this.state;
    const showBalance =
      !isNew &&
      person.student &&
      person.student.balance &&
      person.student.balance.id;
    const tabs = [
      {
        name: t("Basic data"),
        visible: havePermissions([permissions.PERSON_DETAILS_VIEW]),
      },
      {
        name: t("Employee data"),
        visible:
          havePermissions([permissions.PERSON_EMP_DETAILS_VIEW]) &&
          this.state.isEmployee,
      },
      {
        name: t("Courses"),
        visible:
          havePermissions([permissions.PERSON_COURSE_LIST]) &&
          !isNew &&
          initialValues &&
          initialValues.studentId,
      },
      {
        name: t("Settlements"),
        visible: showBalance,
      },
      {
        name: t("Change password"),
        visible: havePermissions([permissions.CHANGE_PASSWORD]) && !isNew,
      },
    ];
    const renderTabs = tabs.map((element, tabIndex) => {
      if (element.visible) {
        return (
          <li
            key={tabIndex}
            className={`react-tabs__tab ${
              this.state.tabIndex === tabIndex
                ? "react-tabs__tab--selected"
                : ""
            }`}
            onClick={() => {
              this.setState({ tabIndex });
            }}
          >
            {element.name}
          </li>
        );
      }
    });

    return this.state.shouldDisplay ? (
      <main>
        <Prompt
          when={this.state.showRemoveConfirmation_cancel === false && dirty}
          message={t(
            "Are You sure You want to leave this page without saving the form?",
          )}
        />
        <ConfirmModal
          ariaHideApp={false}
          isOpen={this.state.showRemoveConfirmation}
          title={this.modalHeaders[this.state.modalHeaderText]}
          onRequestClose={() =>
            this.setState({ showRemoveConfirmation: false })
          }
          config={{
            body: <p>{this.modalBody[this.state.modalBodyText]}</p>,
            onRequestConfirm: this.modalOnConfirm[this.state.modalMethod],
          }}
        />
        <header className="main-header">
          <div className="pull-right">
            <button onClick={this.logout} className="btn btn--logout">
              {t("Log out")}
            </button>
            <a
              href={COMMON.URL.MANUAL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--logout pull-right right-space left-space"
            >
              <HelpCircle size={16} /> {t("Help")}
            </a>
          </div>
        </header>
        <div className="custom-container">
          <header className="main-header">
            {isNew ? <h1>{t("New person")}</h1> : this.personHeader()}
          </header>
        </div>
        <div className="react-tabs custom-container">
          <ul>{renderTabs}</ul>
        </div>

        <div className="row">
          <PersonFormContext.Provider
            value={{ permissions: this.props.permissionsList }}
          >
            <div className=" content col-xs-12">
              <div className="custom-container">
                <div className="row ">
                  <div className="col-md-12">
                    <Authorize
                      component={
                        <div
                          className={this.state.tabIndex !== 2 ? "hidden" : ""}
                        >
                          <PersonsCoursesList />
                        </div>
                      }
                      allows={[permissions.PERSON_COURSE_LIST]}
                    />
                    <Form
                      className="form"
                      onSubmit={handleSubmit(this.onSubmit)}
                    >
                      <div
                        className={this.state.tabIndex !== 0 ? "hidden" : ""}
                      >
                        <PersonBaseData
                          studentTypeId={studentTypeId}
                          employeeTypeId={employeeTypeId}
                          onCopyAddressData={this.copyAddressData}
                          onSetFieldValue={this.setFieldValue}
                          toggleEmployeeTab={this.toggleEmployeeTab}
                          toggleTestTab={this.toggleTestTab}
                          dictionaries={dictionaries}
                          countryAddress={
                            values && values.countryAddress
                              ? values.countryAddress
                              : ""
                          }
                          change={change}
                          countryContactAddress={
                            values && values.countryContactAddress
                              ? values.countryContactAddress
                              : ""
                          }
                          values={values}
                          fetchDictionary={fetchDictionary}
                          initialValues={initialValues}
                          isNew={isNew}
                        />
                        <SwitchTabLink
                          text={t("Clear errors from the 'Employee Data' tab")}
                          callback={() => this.setState({ tabIndex: 1 })}
                          shouldDisplay={this.state.showLinkToEmployee}
                          offset="4"
                        />
                      </div>
                      <Authorize
                        component={
                          <div
                            className={
                              this.state.tabIndex !== 1 ? "hidden" : ""
                            }
                          >
                            <PersonEmployeeData
                              dictionaries={dictionaries}
                              onlyTeachCities={onlyTeachCities}
                              coursesSpecialists={coursesSpecialists}
                              lecturesForImport={this.prepareLecturesForImport()}
                              chosenCompetencies={initialValues.competenciesIds}
                              updateAdditionalCompetencies={
                                this.updateAdditionalCompetenciesHandler
                              }
                              isEmployee={this.state.isEmployee}
                              competenciesIds={
                                values && values.competenciesIds
                                  ? values.competenciesIds
                                  : []
                              }
                              additionalCompetenciesIds={
                                values && values.additionalCompetenciesIds
                                  ? values.additionalCompetenciesIds
                                  : []
                              }
                              isNew={isNew}
                              change={change}
                              values={values}
                              roles={roles}
                              isUser={
                                values.userId !== null && values.userEnabled
                              }
                              offices={this.props.offices}
                            />
                            <SwitchTabLink
                              text={t("Clear errors from the 'Basic Data' tab")}
                              callback={() => this.setState({ tabIndex: 0 })}
                              shouldDisplay={this.state.showLinkToBase}
                              offset="4"
                            />
                          </div>
                        }
                        allows={[permissions.PERSON_EMP_DETAILS_VIEW]}
                      />
                      {showBalance && (
                        <div
                          className={`${
                            this.state.tabIndex !== 3 ? "hidden" : ""
                          }`}
                        >
                          <Balance
                            balanceId={person.student.balance.id}
                            showHeader={false}
                          />
                        </div>
                      )}
                      <div
                        className={`${
                          this.state.tabIndex !== 4 ? "hidden" : ""
                        }`}
                      >
                        <ChangePassword userEmail={person.userEmail} />
                      </div>
                      <div
                        id="person-form-actions"
                        className={
                          this.state.tabIndex !== 0 && this.state.tabIndex !== 1
                            ? "hidden"
                            : ""
                        }
                      >
                        <button
                          type="submit"
                          className="btn btn-dark btn-margin"
                          disabled={
                            this.cantEdit ? true : pristine || submitting
                          }
                        >
                          {t("Submit")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-dark"
                          disabled={
                            this.cantEdit ? true : pristine || submitting
                          }
                          onClick={() => {
                            this.setState({
                              showRemoveConfirmation: true,
                              modalHeaderText: quitWithoutSaving,
                              modalBodyText: quitWithoutSaving,
                              modalMethod: quitWithoutSaving,
                            });
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-link btn-regular"
                          disabled={
                            this.cantEdit ? true : pristine || submitting
                          }
                          onClick={() => {
                            this.setState({
                              showRemoveConfirmation: true,
                              modalHeaderText: clearChanges,
                              modalBodyText: clearChanges,
                              modalMethod: clearChanges,
                            });
                          }}
                        >
                          {t("Clear changes")}
                        </button>
                        <div className="form-group has-error">
                          {(this.state.showLinkToEmployee ||
                            this.state.showLinkToBase) && (
                            <div className="col-sm-8 col-sm-offset-4">
                              <span className="has-error help-block">
                                {t("Form contains errors")}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </PersonFormContext.Provider>
        </div>
      </main>
    ) : (
      <Loader />
    );
  }
}

export default translate()(PersonForm);
