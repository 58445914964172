import React, { Component } from "react";
import SecondaryMenu from "../Components/MainTemplateSecondaryMenu.js";
import { forgetUser } from "../../Auth/Actions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { translate } from "react-i18next";
import { HelpCircle } from "react-feather";
import COMMON from "../../../Enums/Common";
import PropTypes from "prop-types";

class MainTemplateContent extends Component {
  static propTypes = {
    forgetUser: PropTypes.func,
    t: PropTypes.func,
    background: PropTypes.bool,
    title: PropTypes.string,
    closeDate: PropTypes.string,
    secondaryMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]),
    subtitle: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  logout = () => {
    localStorage.removeItem("bertaSettings");
    this.props.forgetUser();
  };

  render() {
    const { t } = this.props;
    const classes = `col-xs-12 content ${
      this.props.background ? "content--with-background" : ""
    }`;

    return (
      <main>
        <div className="custom-container">
          <header className="main-header">
            <div className="row">
              <div className="pull-right">
                <button onClick={this.logout} className="btn btn--logout">
                  {t("Log out")}
                </button>
                <a
                  href={COMMON.URL.MANUAL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--logout pull-right left-space"
                >
                  <HelpCircle size={16} /> {t("Help")}
                </a>
              </div>

              <div className="col-sm-8">
                <h1>
                  {this.props.title}{" "}
                  <span className="close-date">{this.props.closeDate}</span>
                </h1>
                {this.props.subtitle && <h3>{this.props.subtitle}</h3>}
              </div>
            </div>
          </header>
          <SecondaryMenu links={this.props.secondaryMenu} />
        </div>
        <div className=" row">
          <div className={classes}>
            <div className="custom-container">{this.props.children}</div>
          </div>
        </div>
      </main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ forgetUser }, dispatch);
}

export default compose(
  translate(),
  connect(
    null,
    mapDispatchToProps,
  ),
)(MainTemplateContent);
