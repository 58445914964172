import React, { Component } from "react";
import {
  RenderRadio,
  RenderText,
  RenderTextArea,
  RenderSelect,
  RenderTextsFieldArray,
} from "../../../Forms/forms";
import { Field, FieldArray } from "redux-form";
import { Repeat } from "react-feather";
import { translate } from "react-i18next";
import PersonBaseDataUserType from "./PersonBaseDataUserType";
import CityAutoComplete from "../../../Common/Components/CityAutoComplete/CityAutoComplete";
import checkCountryIsPoland from "../../../Helpers/CheckCountryIsPoland";
import CitySelectWithPagination from "../../../Forms/CitySelectWithPagination";
import PERSON_TYPES from "../../../Enums/PersonTypes";
import SEX from "../../../Enums/Sex";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import { Features, Tenant } from "../../../Common/Utils/Tenant";

class PersonBaseData extends Component {
  static propTypes = {
    onCopyAddressData: PropTypes.func,
    onSetFieldValue: PropTypes.func,
    studentTypeId: PropTypes.number,
    employeeTypeId: PropTypes.number,
    dictionaries: PropTypes.object,
    toggleEmployeeTab: PropTypes.func,
    toggleTestTab: PropTypes.func,
    change: PropTypes.func,
    countryAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    countryContactAddress: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    t: PropTypes.func,
    initialValues: PropTypes.object,
    cityNameContactAddress: PropTypes.string,
    fetchDictionary: PropTypes.func,
    values: PropTypes.object,
    isNew: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      dictionaries: {},
    };
    this.cantEdit = !havePermissions([permissions.PERSON_DETAILS_EDIT]);
  }

  onCopyAddressDataHandler = event => {
    event.preventDefault();
    this.props.onCopyAddressData();
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  globalCitiesList = () => {
    this.setState({ cities });
  };

  cityAddressSelected = city => {
    const value = city && city.name ? city.name : null;
    this.props.onSetFieldValue("cityNameAddress", value);
  };
  cityContactAddressSelected = city => {
    const value = city && city.name ? city.name : null;
    this.props.onSetFieldValue("cityNameContactAddress", value);
  };

  render() {
    const {
      studentTypeId,
      employeeTypeId,
      dictionaries,
      toggleEmployeeTab,
      toggleTestTab,
      change,
      countryAddress,
      countryContactAddress,
      t,
      initialValues,
      isNew,
      values,
    } = this.props;
    return (
      <div className="col-md-12 person-base-data">
        <div className="col-sm-6 col-md-4 person-base-data__section">
          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Basic data")}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <FieldArray
                name="typeIds"
                component={PersonBaseDataUserType}
                studentTypeId={studentTypeId}
                employeeTypeId={employeeTypeId}
                types={dictionaries.personTypes}
                validate={value =>
                  value && value.length === 0
                    ? t("Choose a user type")
                    : undefined
                }
                toggleEmployeeTab={toggleEmployeeTab}
                toggleTestTab={toggleTestTab}
                disabledOptions={[studentTypeId, PERSON_TYPES.LEAD]}
                shouldDisabled={this.cantEdit}
                required
                isNew={isNew}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                label={t("NamePerson")}
                name="name"
                component={RenderText}
                required="required"
                shouldDisabled={this.cantEdit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                label={t("Surname")}
                name="surname"
                component={RenderText}
                required="required"
                shouldDisabled={this.cantEdit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Field
                label={t("Sex")}
                name="sex"
                options={[
                  {
                    name: t("Female"),
                    value: SEX.FEMALE,
                  },
                  {
                    name: t("Male"),
                    value: SEX.MALE,
                  },
                ]}
                component={RenderRadio}
                required
                shouldDisabled={this.cantEdit}
              />
            </div>
          </div>
          <div className="sectionForm__section--border-top">
            <div className="row">
              <div className="col-md-12">
                <Field
                  label={t("PIN")}
                  name="pesel"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  label={t("Document Nr")}
                  name="otherDocument"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            {initialValues && initialValues.studentId && (
              <Tenant
                component={
                  <div className="row">
                    <div className="col-md-12">
                      <Field
                        component={RenderText}
                        shouldDisabled={true}
                        label={t("Sub-account")}
                        name="bankAccount"
                      />
                    </div>
                  </div>
                }
                feature={Features.bank_accounts}
              />
            )}
          </div>
          <div className="sectionForm__section--border-top">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Contact details")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled dynamic-fields">
                  <FieldArray
                    component={RenderTextsFieldArray}
                    name="emails"
                    fieldLabel={t("Email")}
                    shouldDisabled={this.cantEdit}
                  />
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled dynamic-fields">
                  <FieldArray
                    component={RenderTextsFieldArray}
                    name="phones"
                    fieldLabel={t("Phone")}
                    shouldDisabled={this.cantEdit}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 person-base-data__section">
          <div className="sectionForm__section">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Registered address")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  label={t("Address")}
                  name="streetAddress"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
                <Field
                  label={t("Country")}
                  name="countryAddress"
                  component={RenderSelect}
                  dropdownConfig={{
                    data: dictionaries.countries,
                    textField: "name",
                    valueField: "id",
                    filter: "contains",
                  }}
                  shouldDisabled={this.cantEdit}
                />
                {checkCountryIsPoland(
                  dictionaries.countries,
                  countryAddress,
                ) ? (
                  <div id="cityNameAddress" className="col-xs-12">
                    <Field
                      label={t("City")}
                      name="cityNameAddress"
                      component={CitySelectWithPagination}
                      props={{
                        onChangeHandler: this.cityAddressSelected,
                        cities: dictionaries.cities,
                        fetchDictionary: this.props.fetchDictionary,
                        value: values.cityNameAddress,
                        onCitiesListUpdate: this.globalCitiesList,
                        shouldDisabled: this.cantEdit,
                        countryId: countryAddress,
                      }}
                    />
                  </div>
                ) : (
                  <div id="cityNameAddress">
                    <CityAutoComplete
                      label={t("City")}
                      change={change}
                      name="cityNameAddress"
                      shouldDisabled={this.cantEdit}
                      nameToShow={initialValues.cityNameAddress}
                    />
                  </div>
                )}
                <Field
                  label={t("Zip code")}
                  name="zipCodeAddress"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      id="copyContactDataButton"
                      type="button"
                      onClick={this.onCopyAddressDataHandler}
                      className="btn btn-link pull-right"
                      disabled={this.cantEdit}
                    >
                      <Repeat size={16} /> {t("Use as a contact address")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionForm__section--border-top">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Contact address")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  label={t("Address")}
                  name="streetContactAddress"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
                <Field
                  label={t("Country")}
                  name="countryContactAddress"
                  component={RenderSelect}
                  dropdownConfig={{
                    data: dictionaries.countries,
                    textField: "name",
                    valueField: "id",
                    filter: "contains",
                  }}
                  shouldDisabled={this.cantEdit}
                />
                {checkCountryIsPoland(
                  dictionaries.countries,
                  countryContactAddress,
                ) ? (
                  <div id="cityNameContactAddress" className="col-xs-12">
                    <Field
                      label={t("City")}
                      name="cityNameContactAddress"
                      component={CitySelectWithPagination}
                      props={{
                        onChangeHandler: this.cityContactAddressSelected,
                        cities: dictionaries.cities,
                        fetchDictionary: this.props.fetchDictionary,
                        value: values.cityNameContactAddress,
                        onCitiesListUpdate: this.globalCitiesList,
                        shouldDisabled: this.cantEdit,
                      }}
                    />
                  </div>
                ) : (
                  <div id="cityNameContactAddress">
                    <CityAutoComplete
                      label={t("City")}
                      change={change}
                      name="cityNameContactAddress"
                      shouldDisabled={this.cantEdit}
                      nameToShow={values.cityContactAddress}
                    />
                  </div>
                )}
                <Field
                  label={t("Zip code")}
                  name="zipCodeContactAddress"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 person-base-data__section">
          <div className="sectionForm__section">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Additional data")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  label="Linkedin"
                  name="linkedin"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
                <Field
                  label="Github"
                  name="github"
                  component={RenderText}
                  shouldDisabled={this.cantEdit}
                />
                <Field
                  label={t("Comments")}
                  name="comment"
                  component={RenderTextArea}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(PersonBaseData);
