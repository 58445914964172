import React from "react";
import PropTypes from "prop-types";
import Paginator from "../../../../Common/Components/Paginator/Paginator";
import { translate } from "react-i18next";
import ContractListElement from "./ContractListElement";

const ContractsList = ({
  t,
  contracts,
  counter,
  currentPage,
  pageSize,
  onChangePage,
}) => {
  const numberOfPages = Math.ceil(counter / pageSize);

  const listPaging = numberOfPages > 1 && (
    <Paginator
      onClick={onChangePage}
      pages={numberOfPages}
      currentPage={currentPage}
    />
  );

  const noData = !contracts.length && (
    <div className={`row product-item`}>
      <div className={`vertical-center horizontal-center`}>
        <p>{t("No records found")}</p>
      </div>
    </div>
  );

  return (
    <div className="row">
      {contracts.map(contractItem => (
        <ContractListElement
          key={String(contractItem.contract.id)}
          contractItem={contractItem}
        />
      ))}
      {noData}
      {listPaging}
    </div>
  );
};

ContractsList.propTypes = {
  t: PropTypes.func,
  counter: PropTypes.number,
  contracts: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
};

export default translate()(ContractsList);
