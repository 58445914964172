import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import {
  RenderSelect,
  RenderCheckbox,
  RenderMultiselect,
} from "../../../Forms/forms";
import ReactTable from "react-table";
import _ from "lodash";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import Loader from "../../../Common/Components/Loader/Loader";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import PACKAGES from "../../../Enums/Packages";
import GROUP_FILL_STATUSES from "../../../Enums/GroupFillStatuses";

class GroupFillForm extends Component {
  static propTypes = {
    change: PropTypes.func,
    fetchDictionaryOnlyTeach: PropTypes.func,
    values: PropTypes.object,
    t: PropTypes.func,
    onlyTeachCities: PropTypes.array,
    fetchGroupDetailsData: PropTypes.func,
    groupDetailsData: PropTypes.object,
    getGroupFillReport: PropTypes.func,
    fetchProductsForCourses: PropTypes.func,
    productsListForCourse: PropTypes.array,
    allProductsFullInfo: PropTypes.array,
    fetchAllProductsFullInfo: PropTypes.func,
  };
  constructor(props) {
    super(props);
    const {
      fetchDictionaryOnlyTeach,
      fetchGroupDetailsData,
      fetchProductsForCourses,
      fetchAllProductsFullInfo,
    } = props;

    this.state = {
      currentPage: 1,
      isLoading: true,
      sortBy: null,
      sortAscending: true,
    };
    this.cancelToken = getCancelToken();

    fetchDictionaryOnlyTeach(this.cancelToken);
    fetchProductsForCourses(this.cancelToken);
    fetchGroupDetailsData(this.cancelToken, { started: false }, () =>
      this.setState({ isLoading: false }),
    );
    fetchAllProductsFullInfo(this.cancelToken);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.values, prevProps.values)) {
      this.changePage(1);
    }
  }

  handleSort = sortBy => {
    const { sortAscending } = this.state;
    this.setState(
      {
        sortBy,
        sortAscending:
          this.state.sortBy === sortBy ? !sortAscending : sortAscending,
      },
      () => this.changePage(1),
    );
  };

  changePage = currentPage => {
    const { fetchGroupDetailsData, values } = this.props;
    const productIdArray =
      values.productId && values.productId.map(product => product.productId);
    const { sortBy, sortAscending } = this.state;
    this.setState(
      {
        currentPage,
        isLoading: true,
      },
      () => {
        fetchGroupDetailsData(
          this.cancelToken,
          {
            pageNo: currentPage,
            sortBy,
            sortOrder: sortAscending ? "asc" : "desc",
            ...values,
            started: values.started ? values.started : false,
            productId: productIdArray,
          },
          () => this.setState({ isLoading: false }),
        );
      },
    );
  };

  renderColumns = () => {
    const {
      groupDetailsData: { elements },
      t,
    } = this.props;
    const additionalColumns = [];

    if (elements && elements[0]) {
      for (let element in elements[0].packages) {
        additionalColumns.push({
          Header: headProps => (
            <div
              className="group-fill-table__header"
              onClick={() => this.handleSort(headProps.column.sortBy)}
            >
              {t(PACKAGES[element])}
            </div>
          ),
          accessor: `packages[${element}]`,
          sortBy: `packages_${element}`,
          className: "centered-text",
          headerClassName: "centered-text",
        });
      }
    }

    return [
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("City")}
          </div>
        ),
        accessor: "city",
        sortBy: "city",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("Signature")}
          </div>
        ),
        accessor: "signature",
        sortBy: "signature",
        className: "centered-text--bold",
        headerClassName: "centered-text",
        width: 130,
        Cell: props => (
          <span>
            <Link to={`/courses/${props.original.courseId}/students`}>
              {props.value}
            </Link>
          </span>
        ),
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("Start date")}
          </div>
        ),
        accessor: "startDate",
        sortBy: "start_date",
        className: "centered-text",
        headerClassName: "centered-text",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("End date")}
          </div>
        ),
        accessor: "endDate",
        sortBy: "end_date",
        className: "centered-text",
        headerClassName: "centered-text",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("To start")}
          </div>
        ),
        accessor: "daysToStart",
        sortBy: "days_to_start",
        className: "centered-text",
        headerClassName: "centered-text",
        width: 70,
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("Postponements")}
          </div>
        ),
        accessor: "postpone",
        sortBy: "postpone",
        className: "centered-text",
        headerClassName: "centered-text",
        width: 90,
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("Status")}
          </div>
        ),
        accessor: "status.name",
        sortBy: "status",
        className: "border-right--dark centered-text",
        headerClassName: "centered-text",
        width: 90,
        Cell: props => (
          <span
            className={
              props.original.status.id === GROUP_FILL_STATUSES.GOOD
                ? "group-fill-table__cell--good"
                : props.original.status.id === GROUP_FILL_STATUSES.MIDDING
                ? "group-fill-table__cell--midding"
                : "group-fill-table__cell--bad"
            }
          >
            {t(props.value)}
          </span>
        ),
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("During recruitment")}
          </div>
        ),
        accessor: "contracts[1]",
        sortBy: "contracts_1",
        className: "centered-text",
        headerClassName: "centered-text",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("ContractsSent")}
          </div>
        ),
        accessor: "contracts[2]",
        sortBy: "contracts_2",
        className: "centered-text",
        headerClassName: "centered-text",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("New")}
          </div>
        ),
        accessor: "contracts[3_new]",
        sortBy: "contracts_3_new",
        className: "border-right--dark centered-text--bold",
        headerClassName: "centered-text",
      },
      {
        Header: headProps => (
          <div
            className="group-fill-table__header"
            onClick={() => this.handleSort(headProps.column.sortBy)}
          >
            {t("Transfered")}
          </div>
        ),
        accessor: "contracts[3_transfer]",
        sortBy: "contracts_3_transfer",
        className: "border-right--dark centered-text--bold",
        headerClassName: "centered-text",
      },
      ...additionalColumns,
    ];
  };

  render() {
    const {
      t,
      onlyTeachCities,
      change,
      groupDetailsData,
      getGroupFillReport,
      values: { cityId, started },
      allProductsFullInfo,
    } = this.props;
    const { isLoading, sortBy, sortAscending } = this.state;
    return (
      <Fragment>
        <div className="row col-md-12 vertical-center">
          <Field
            component={RenderSelect}
            name="cityId"
            dropdownConfig={{
              data: onlyTeachCities,
              textField: "name",
              valueField: "id",
              placeholder: t("Cities"),
            }}
            under={{
              containerClassName: "col-md-3 col-xs-6 padding__l0",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("cityId", null)}
          />
          <Field
            component={RenderMultiselect}
            name="productId"
            dropdownConfig={{
              data: allProductsFullInfo.map(product => ({
                ...product,
                productId: product.id,
                productRenderName: `${product.shortcut} ${product.name}`,
              })),
              textField: "productRenderName",
              valueField: "productId",
              placeholder: t("Products"),
            }}
            under={{
              containerClassName: "col-md-3 col-xs-6 padding__r0",
              fieldClassName: "col-xs-12",
            }}
          />
          <div className="col-md-3">
            <Field
              name="started"
              labelForCheckbox={t("Show started")}
              customLabel=" "
              component={RenderCheckbox}
            />
          </div>
          <div className="col-md-3" id="groupFillDownload">
            <CustomIcon
              icon="xls"
              color="#4d4d4f"
              size="40px"
              viewBox="0 0 512 512"
              className="pull-right"
              onClick={() =>
                getGroupFillReport({
                  cityId,
                  sortBy,
                  soortOrder: sortAscending ? "asc" : "desc",
                  started,
                })
              }
            />
          </div>
        </div>
        <div className="row col-md-12 group-fill-table">
          {isLoading ? (
            <Loader />
          ) : (
            <ReactTable
              noDataText={t("No records found")}
              showPagination={false}
              manual
              defaultPageSize={10}
              data={groupDetailsData.elements}
              sortable={true}
              columns={this.renderColumns()}
              className="-striped -highlight"
            />
          )}
        </div>
        <div className="row col-md-12">
          {groupDetailsData.counter ? (
            <Paginator
              onClick={this.changePage}
              pages={Math.ceil(groupDetailsData.counter / 10)}
              currentPage={this.state.currentPage}
            />
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

export default translate()(GroupFillForm);
