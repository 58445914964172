import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import dateHelper from "../../../Helpers/DateHelper";
import getCancelToken from "../../../Helpers/GetCancelToken";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import Loader from "../../../Common/Components/Loader/Loader";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { RenderCheckbox, RenderSelect } from "../../../Forms/forms";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import { Link } from "react-router-dom";
import Dinero from "dinero.js";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import TIME from "../../../Enums/Time";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import { Edit } from "react-feather";
import NoteEdit from "../Containers/NoteEdit";

class ContractPaymentsReport extends Component {
  #cancelToken = getCancelToken();
  #tableColumns = [
    {
      Header: () => (
        <div className="group-fill-table__header">
          {this.props.t(`Signature`)} / {this.props.t(`Contract`)}
        </div>
      ),
      Cell: ({ original }) => (
        <span className={"contract"}>
          <Link to={`/courses/${original.courseId}/settings`}>
            {original.courseSignature}
          </Link>{" "}
          /{" "}
          <Link to={`/persons/contract/${original.id}`}>
            {original.student}
          </Link>
        </span>
      ),
      width: 250,
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Payment status`)}</div>,
      accessor: "paymentStatus.name",
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Deadline`)}</div>,
      Cell: ({ original: { deadline } }) => {
        return (
          deadline &&
          dateHelper(deadline)
            .get()
            .format(TIME.DATE_FORMAT)
        );
      },
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Student`)}</div>,
      Cell: ({ original: { studentBalance } }) => {
        const amount = Dinero(studentBalance);

        return (
          <span className={amount.isNegative() ? "text-danger" : ""}>
            {renderAmountWithCurrency(studentBalance)}
          </span>
        );
      },
      accessor: "studentBalance.amount",
      width: 120,
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Third party`)}</div>,
      Cell: ({ original: { thirdPartyBalance } }) => {
        const amount = Dinero(thirdPartyBalance);

        return (
          <span className={amount.isNegative() ? "text-danger" : ""}>
            {renderAmountWithCurrency(thirdPartyBalance)}
          </span>
        );
      },
      accessor: "thirdPartyBalance.amount",
      width: 120,
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Course Specialist`)}</div>,
      accessor: "courseSpecialist",
      sortable: false,
    },
    {
      Header: () => <div>{this.props.t(`Comments`)}</div>,
      Cell: ({ original }) => {
        const { notes, id } = original;
        const note = notes[0].note !== null ? notes[0].note : "";
        const onClick = () => this.setState({ contractId: id });

        return (
          <span>
            <Edit
              onClick={onClick}
              className={`black-link cursor__pointer`}
              size={16}
            />
            &nbsp;
            <span title={note}>{note}</span>
          </span>
        );
      },
      width: 200,
      accessor: "notes",
      sortable: false,
    },
  ];

  static propTypes = {
    contractPaymentsReportResults: PropTypes.object,
    coursesSpecialists: PropTypes.array,
    fetchContractPayments: PropTypes.func,
    downloadContractPayments: PropTypes.func,
    getCoursesSpecialists: PropTypes.func,
    change: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
    fetchContractPaymentsFilters: PropTypes.func,
    contractPaymentFilters: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentPage: 1,
      contractId: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (forceFirstPage = false) => {
    const { fetchContractPayments, fetchContractPaymentsFilters } = this.props;
    this.setState({
      isLoading: true,
    });
    fetchContractPayments(
      this.#cancelToken,
      this.prepareRequestParams(forceFirstPage),
      () => {
        this.setState({
          isLoading: false,
        });
      },
    );
    fetchContractPaymentsFilters(this.prepareRequestParams());
  };

  closeNotesEditModal = () => {
    this.setState({ contractId: null });
    this.loadData();
  };

  prepareRequestParams = forceFirstPage => {
    const {
      values: { onlyExpired, courseSpecialistId, sort },
    } = this.props;
    const [sortBy, sortOrder] =
      typeof sort === "undefined" ? [null, null] : sort.split(".");
    const { currentPage: pageNo } = this.state;
    this.setState({
      currentPage: forceFirstPage ? 1 : pageNo,
    });
    return {
      onlyExpired: Number(onlyExpired),
      courseSpecialistId,
      sortBy,
      sortOrder,
      pageNo: forceFirstPage ? 1 : pageNo,
    };
  };

  componentDidUpdate(prevProps) {
    const {
      values: { onlyExpired, courseSpecialistId, sort },
    } = this.props;
    const {
      values: {
        onlyExpired: oldOnlyExpired,
        courseSpecialistId: oldCourseSpecialistId,
        sort: oldSort,
      },
    } = prevProps;
    const valuesComparison = [
      [onlyExpired, oldOnlyExpired],
      [courseSpecialistId, oldCourseSpecialistId],
      [sort, oldSort],
    ];
    const valuesNotChanged = valuesComparison.every(valuesToCheck =>
      _.isEqual(valuesToCheck[0], valuesToCheck[1]),
    );

    if (!valuesNotChanged) {
      _.debounce(() => {
        this.loadData(true);
      }, 1000)();
    }
  }

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
      },
      this.loadData,
    );
  };

  handleDownload = () => {
    const { downloadContractPayments } = this.props;
    const params = this.prepareRequestParams();
    downloadContractPayments(
      this.#cancelToken,
      { ...params, format: "xls" },
      () => {
        this.setState({ isWaitingForData: false });
      },
    );
  };

  render() {
    const { isLoading, currentPage, contractId } = this.state;
    const {
      t,
      contractPaymentsReportResults,
      contractPaymentFilters: { cs: cski },
    } = this.props;
    const PER_PAGE = 10;
    const counter = _.has(contractPaymentsReportResults, "counter")
      ? contractPaymentsReportResults.counter
      : 0;
    const incomes = _.has(contractPaymentsReportResults, "elements")
      ? contractPaymentsReportResults.elements
      : [];
    const pages = Math.ceil(counter / PER_PAGE);

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <section className="form-section">
          <div className="row">
            <div className={`form-group col-md-2 col-xs-6`}>
              <Field
                name="onlyExpired"
                labelForCheckbox={t("Show expired only")}
                customLabel=""
                component={RenderCheckbox}
              />
            </div>
            <div className={`form-group col-md-3 col-xs-6`}>
              <Field
                component={RenderSelect}
                name="courseSpecialistId"
                onChange={values => {
                  this.props.change("courseSpecialistId", values);
                }}
                placeholder={t("Course Specialist")}
                onClearCallback={() =>
                  this.props.change("courseSpecialistId", null)
                }
                dropdownConfig={{
                  data: cski,
                  textField: element => `${element.name} ${element.surname}`,
                  valueField: "id",
                }}
              />
            </div>
            <div className={`form-group col-md-3 col-xs-6`}>
              <Field
                component={RenderSelect}
                name="sort"
                dropdownConfig={{
                  data: [
                    { id: "deadline.asc", name: t("Deadline, ascending") },
                    { id: "deadline.desc", name: t("Deadline, descending") },
                  ],
                  textField: "name",
                  valueField: "id",
                }}
                placeholder={t("Sort by")}
                under={{
                  fieldClassName: "col-xs-12",
                }}
              />
            </div>
            <div
              className={`form-group col-md-1 col-xs-6`}
              id="contractPaymentsDownload"
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
                onClick={this.handleDownload}
              />
            </div>
          </div>
          <div className="row horizontal-center">
            <div className="col-md-12">
              <div className={`row`}>
                <ReactTable
                  noDataText={t("No records found")}
                  showPagination={false}
                  defaultPageSize={PER_PAGE}
                  data={incomes}
                  sortable={true}
                  columns={this.#tableColumns}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Paginator
                pages={pages}
                onClick={this.changePage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </section>

        <CustomModal
          isOpen={!!contractId}
          title={`${t("Comments")}`}
          onRequestClose={this.closeNotesEditModal}
          ariaHideApp={false}
        >
          <NoteEdit contractId={contractId} />
        </CustomModal>
      </Fragment>
    );
  }
}

export default translate()(ContractPaymentsReport);
