import moment from "moment";
import ACTION_TYPES from "../../Enums/ActionTypes";

const initState = {
  currentMonth: moment()
    .utc()
    .month(),
  currentYear: moment()
    .utc()
    .year(),
};
export function calendar(state = initState, action) {
  switch (action.type) {
    case ACTION_TYPES.CALENDAR_NEXT_MONTH: {
      if (state.currentMonth + 1 > 11) {
        state.currentMonth = 0;
        state.currentYear += 1;
      } else {
        state.currentMonth += 1;
      }
      return { ...state };
    }
    case ACTION_TYPES.CALENDAR_PREV_MONTH: {
      if (state.currentMonth - 1 < 0) {
        state.currentMonth = 11;
        state.currentYear -= 1;
      } else {
        state.currentMonth -= 1;
      }
      return { ...state };
    }
  }
  return state;
}

export function timeline(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TIMELINE:
      return { ...action.payload.data.data };
  }
  return state;
}

export function timelineStats(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TIMELINE_STATS:
      return { ...action.payload.data.data };
  }
  return state;
}

export function timelineComments(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TIMELINE_COMMENTS:
      return [...action.payload.data.data];
  }
  return state;
}

export function fetchComment(state = "", action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_COMMENT:
      return action.payload.data.data.text;
    default:
      return state;
  }
}
export function fetchReport(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_REPORT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchGroupDetailsSortTypes(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_GROUP_FILL_SORT_TYPES:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchGroupDetailsData(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_GROUP_FILL_DATA:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchReportGraduates(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_REPORT_GRADUATES:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchCurrentPollResults(
  state = { counter: 0, items: [] },
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CURRENT_POLL_RESULTS:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchCoursePollResults(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_COURSE_POLL_RESULTS_GENERAL: {
      const polls = action.payload.data.data.map(item => ({
        ...item,
        subrows: [],
      }));
      return polls;
    }
    case ACTION_TYPES.FETCH_COURSE_POLL_RESULTS_DETAILED: {
      const { data } = action.payload.data;
      const polls = state.map(item => {
        if (data[0].typeName === item.typeName) {
          return { ...item, subrows: [...data] };
        }
        return { ...item };
      });
      return polls;
    }
    default:
      return state;
  }
}

export function fetchBankIncomes(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BANK_INCOMES:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchBankIncomesTransfers(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BANK_INCOMES_TRANSFERS:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchRefunds(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_REFUNDS_REPORT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchInvoices(state = { counter: 0, elements: [] }, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INVOICES_REPORT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchContractPayments(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_REPORT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchContractPaymentsNote(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_NOTE:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchBalances(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_BALANCES_REPORT:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchContractPaymentsFilters(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_PAYMENTS_FILTERS:
      return action.payload.data.data;
    default:
      return state;
  }
}
