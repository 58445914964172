import React from "react";
import PropTypes from "prop-types";

const NestedLectureElement = props => {
  const { importedFrom, name } = props;
  return (
    <div>
      <span>{name}</span>
      {!!importedFrom && (
        <span className="small--grey">
          {importedFrom.product
            ? `Import - Produkt ${importedFrom.product} \\`
            : ""}
          {importedFrom.version ? `Wersja ${importedFrom.version} \\` : ""}
          {importedFrom.module ? `Moduł ${importedFrom.module}` : ""}
        </span>
      )}
    </div>
  );
};

NestedLectureElement.propTypes = {
  importedFrom: PropTypes.string,
  name: PropTypes.string,
};

export default NestedLectureElement;
