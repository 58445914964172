import React from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { Lecturer } from "../../../Types/Lecture";
import { RenderSelect } from "../../../Forms/InputComponents/Select";
import { RenderTimePicker } from "../../../Forms/InputComponents/TimePicker";
import { RenderDateTime } from "../../../Forms/InputComponents/DateTime";
import { RenderNumber } from "../../../Forms/InputComponents/Number";
import { RenderCheckbox } from "../../../Forms/InputComponents/Checkbox";
import PARTICIPANTS from "../../../Enums/Participants";
import AVAILABILITY_TYPES from "../../../Enums/AvailabilityTypes";
import { AlertCircle, Home } from "react-feather";
import validate from "../Validators/LectureUnitValidator";
import { LectureFormProps, LectureFormValues } from "../Types/EditLecture";

const LectureForm: React.FunctionComponent<LectureFormProps> = ({
  lecture,
  disabled,
  freeLecturesAndRooms,
  t,
  isLastUnit,
  handleSettleModule,
  handleSubmit,
}): React.ReactElement => {
  const { lecturer, date, startTime, room, settled, students } = lecture;

  const initialValues = {
    lecturer,
    date,
    startTime,
    room,
    settled,
    students,
    moveNext: false,
    fillModule: false,
  };

  const hasParticipantLecturer = lecture.participants.find(
    participant =>
      participant.id === PARTICIPANTS.lecturer.id ||
      participant.id === PARTICIPANTS.mentor.id,
  );

  const renderLecturerLabel = (
    data: { selected: boolean; dataItem: Lecturer; onSelect: () => void },
    formProps: FormikProps<LectureFormValues>,
  ): React.ReactElement => {
    const {
      onSelect,
      dataItem: { surname, name, availability, cities, hasCity },
      dataItem,
      selected,
    } = data;
    const selectLecturer = (): void => {
      const { setFieldValue } = formProps;
      setFieldValue("lecturer", dataItem);
      onSelect();
    };

    const conflicts =
      availability.conflicts &&
      availability.conflicts.map(conflict => conflict.signature).join(",");
    let addedClass;
    let title;
    switch (availability.type) {
      case AVAILABILITY_TYPES.PARTIAL:
        addedClass = "course-schedule__select-lecturer--partial";
        title = availability.info;
        break;
      case AVAILABILITY_TYPES.BUSY:
        addedClass = "course-schedule__select-lecturer--busy";
        title = conflicts;
        break;
      case AVAILABILITY_TYPES.UNAVAILABLE:
        addedClass = "course-schedule__select-lecturer--unavailable";
        break;
      case AVAILABILITY_TYPES.DIFFERENT_MODE:
        addedClass = "course-schedule__select-lecturer--different-mode";
        title = `${t("available in mode")}: ${availability.info}`;
        break;
      default:
        addedClass = "";
        break;
    }

    const cityNames = cities && cities.map(city => city.name).join("\n- ");
    let addedClassCity;
    let titleCity;

    if (hasCity) {
      addedClassCity = "course-schedule__select-lecturer--home";
      titleCity = `${t("Lecturing in")}: \n- ${cityNames}`;
    }

    return (
      <li
        onClick={availability.active ? selectLecturer : () => {}}
        className={`rw-list-option course-schedule__select-lecturer ${
          selected ? "rw-state-focus" : ""
        } ${addedClass} ${addedClassCity}`}
      >
        {name} {surname}
        <span>
          <span className="home" title={titleCity ? titleCity : ""}>
            <Home size={15} />
          </span>
          <span className="info" title={title ? title : ""}>
            <AlertCircle size={15} />
          </span>
        </span>
      </li>
    );
  };

  const renderRoomLabel = (
    data: { selected: boolean; dataItem: Lecturer; onSelect: () => void },
    formProps: FormikProps<LectureFormValues>,
  ): React.ReactElement => {
    const {
      onSelect,
      dataItem: { name, availability },
      dataItem,
      selected,
    } = data;

    const selectRoom = () => {
      const { setFieldValue } = formProps;
      setFieldValue("room", dataItem);
      onSelect();
    };

    let addedClass;
    let title;
    if (availability.type === AVAILABILITY_TYPES.BUSY) {
      addedClass = "course-schedule__select-lecturer--busy";
      title = availability.signature;
    } else {
      addedClass = "";
    }

    return (
      <li
        onClick={!title ? selectRoom : () => {}}
        className={`rw-list-option course-schedule__select-lecturer ${
          selected ? "rw-state-focus" : ""
        } ${addedClass}`}
      >
        {name}
        <span>
          <span className="info" title={title ? title : ""}>
            <AlertCircle size={15} />
          </span>
        </span>
      </li>
    );
  };

  const onClearLecturer = (props: FormikProps<LectureFormValues>): void => {
    const { setFieldValue } = props;
    setFieldValue("lecturer", null);
  };

  const onClearStartTime = (props: FormikProps<LectureFormValues>): void => {
    const { setFieldValue } = props;
    setFieldValue("startTime", null);
  };

  const onClearRoom = (props: FormikProps<LectureFormValues>): void => {
    const { setFieldValue } = props;
    setFieldValue("room", null);
  };

  return (
    <div id="lecture-form">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Form>
            {hasParticipantLecturer && (
              <Field
                name="lecturer"
                label={t("Lecturer")}
                component={RenderSelect}
                onClearCallback={() => onClearLecturer(formProps)}
                disabled={disabled}
                dropdownConfig={{
                  data: freeLecturesAndRooms.lecturers,
                  textField: lecturer =>
                    lecturer ? `${lecturer.name} ${lecturer.surname}` : null,
                  valueField: "personId",
                  messages: {
                    emptyList: t("No available lecturers within this period"),
                  },
                  optionComponent: data => renderLecturerLabel(data, formProps),
                  filter: "contains",
                }}
              />
            )}

            <Field
              name="date"
              label={t("Start date")}
              component={RenderDateTime}
              disabled={disabled}
              time={false}
            />

            <div className="row form-group">
              <div className="col-sm-4 col-md-3 ">
                <label>{t("Start time")}</label>
              </div>
              <div className="col-sm-8 col-md-9">
                <Field
                  name="startTime"
                  component={RenderTimePicker}
                  disabled={disabled}
                  required="required"
                  onClearCallback={() => onClearStartTime(formProps)}
                  customClass={`time-`}
                />
              </div>
            </div>

            <Field
              name="room"
              label={t("Room")}
              component={RenderSelect}
              disabled={disabled}
              className="courses-room-select"
              onClearCallback={() => onClearRoom(formProps)}
              dropdownConfig={{
                data: freeLecturesAndRooms.rooms,
                textField: "name",
                valueField: "id",
                optionComponent: data => renderRoomLabel(data, formProps),
              }}
            />

            <Field
              name="students"
              label={t("Students amount")}
              component={RenderNumber}
              disabled={isLastUnit || disabled}
              defaultValue={null}
            />

            <Field
              name="settled"
              id="settled-form-input"
              label={t("Settlement")}
              component={RenderCheckbox}
              disabled={disabled}
            />

            <div className="col-xs-offset-3 margin__b25">
              <button
                onClick={handleSettleModule}
                type="button"
                className="btn btn-dark btn-margin"
                disabled={disabled}
              >
                {t("Settle the module")}
              </button>
            </div>

            <Field
              name="moveNext"
              id="moveNext-form-input"
              label={t("Move next lectures")}
              component={RenderCheckbox}
              disabled={isLastUnit || disabled}
            />

            <Field
              name="fillModule"
              id="fillModule-form-input"
              disabled={disabled}
              label={t("Fill module with this lecture and room")}
              component={RenderCheckbox}
            />

            <div className="text-center">
              <button className="btn btn-done" type="submit">
                {t("Save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LectureForm;
