import { TenantValidator } from "../../../Common/Utils/Tenant";
import _ from "lodash";

export default fields => {
  const errors = {};
  const {
    name,
    payerType,
    taxValue,
    taxNr,
    regonNr,
    addressStreet,
    addressZipCode,
    addressCity,
    addressCountry,
    taxRate,
  } = fields;

  if (!name) {
    errors.name = "Give the name of the payer";
  }
  if (_.isEmpty(taxRate)) {
    errors.taxRate = "You must select tax rate";
  }
  if (!payerType) {
    errors.payerType = "Choose payer type";
  }
  if (!addressZipCode) {
    errors.addressZipCode = "Enter the zip code";
  }
  if (!taxNr) {
    errors.taxNr = "Enter the tax ID";
  }
  if (taxNr && !TenantValidator.isTaxIDValid(taxNr)) {
    errors.taxNr = "Incorrect tax ID";
  }
  if (regonNr && !TenantValidator.isCompanyIdValid(regonNr)) {
    errors.regonNr = "Incorrect company ID";
  }
  if (!taxValue) {
    errors.taxValue = "Give the % VAT";
  }
  if (taxValue < 0) {
    errors.taxValue = "% VAT can not be negative number";
  }
  if (!addressStreet) {
    errors.addressStreet = "Enter the street name";
  }
  if (!addressCity) {
    errors.addressCity = "Choose the city";
  }
  if (!addressCountry) {
    errors.addressCountry = "Choose a country";
  }

  return errors;
};
