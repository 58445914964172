import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { translate } from "react-i18next";
import { timeInMinutesToString } from "../../../Common/Utils/DateFormatters";
import dateHelper from "../../../Helpers/DateHelper";
import TIME from "../../../Enums/Time";

const EventsList = ({
  events,
  isLoading,
  t,
  onRemoveEvent,
  disabled,
  isProduct,
  onRowClick,
}) => {
  const dynamicColumn = isProduct
    ? [
        {
          Header: t("Mode"),
          accessor: "courseMode",
          id: "eventCourseMode",
          Cell: ({ value }) => (value ? value.name : "-"),
        },
      ]
    : [
        {
          Header: t("Date"),
          accessor: "date",
          id: "eventDate",
          Cell: ({ value }) =>
            dateHelper(value)
              .get()
              .format(TIME.DATE_FORMAT),
        },
        {
          Header: t("Lecturer"),
          accessor: "lecturer",
          id: "eventLecturer",
          Cell: ({ value }) => (value ? `${value.name} ${value.surname}` : "-"),
        },
        {
          Header: t("Room"),
          accessor: "room",
          id: "eventRoom",
          Cell: ({ value }) => (value ? value.name : "-"),
        },
        {
          Header: t("Settlement"),
          accessor: "settled",
          id: "eventSettled",
          Cell: ({ value }) => (value ? t("Yes") : t("No")),
        },
      ];

  const dynamicRemoveColumn = isProduct
    ? [
        {
          Header: t("Remove"),
          id: "eventRemove",
          Cell: ({ original: { id: eventId } }) => (
            <div className="text-center">
              <button
                disabled={disabled}
                className="btn btn-dark"
                onClick={() => onRemoveEvent(eventId)}
              >
                {t("Remove")}
              </button>
            </div>
          ),
        },
      ]
    : [];

  const tableColumns = [
    { Header: t("Title"), accessor: "name", id: "eventName" },
    {
      Header: t("Type"),
      accessor: "type",
      id: "eventType",
      Cell: ({ value }) => (value ? value.name : "-"),
    },
    ...dynamicColumn,
    { Header: t("Start time"), accessor: "startTime", id: "eventStartTime" },
    {
      Header: t("Duration"),
      accessor: "duration",
      id: "eventDuration",
      Cell: ({ value }) => timeInMinutesToString(value),
    },
    {
      Header: t("Participants"),
      accessor: "participants",
      id: "eventParticipants",
      Cell: ({ value }) =>
        value.map(participant => participant.name).join(", "),
    },
    {
      Header: t("Room required"),
      accessor: "roomRequired",
      id: "roomRequired",
      Cell: ({ value }) => (value ? t("Yes") : t("No")),
    },
    ...dynamicRemoveColumn,
  ];

  const getTrProps = (state, rowInfo) =>
    rowInfo && rowInfo.original
      ? {
          onClick: () => onRowClick(rowInfo.original),
        }
      : {};

  return (
    <div id="course-events-table">
      <h4 className="padding__t20">{t("Additional events")}</h4>

      <ReactTable
        data={events}
        columns={tableColumns}
        showPagination={false}
        minRows={0}
        loading={isLoading}
        noDataText={t("No data")}
        getTrProps={getTrProps}
      />
    </div>
  );
};

EventsList.propTypes = {
  t: PropTypes.func,
  events: PropTypes.array,
  isLoading: PropTypes.bool,
  onRemoveEvent: PropTypes.func,
  original: PropTypes.object,
  disabled: PropTypes.bool,
  isProduct: PropTypes.bool,
  onRowClick: PropTypes.func,
};

export default translate()(EventsList);
