import React from "react";
import { Field } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Features, Tenant } from "../../../Common/Utils/Tenant";

class ContractBankAccounts extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <div className="col-xs-12">
        <Tenant
          component={
            <Field
              component={RenderText}
              under
              shouldDisabled
              status={"inactive"}
              label={t("Student's sub-account")}
              name={"student.bankAccount.nr"}
            />
          }
          feature={Features.bank_accounts}
        />
      </div>
    );
  }
}

export default translate()(ContractBankAccounts);
