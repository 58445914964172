import React, { Component } from "react";
import { renderFieldsHelper } from "../../../Forms/forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

class SelectInvoiceReceiverWithSearch extends Component {
  static propTypes = {
    fetchData: PropTypes.func,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.string,
    onClearCallback: PropTypes.func,
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func,
    input: PropTypes.object,
    multiple: PropTypes.bool,
    receivers: PropTypes.array,
    renderMenuItemChildren: PropTypes.func,
    filterBy: PropTypes.func,
    labelKey: PropTypes.string,
    id: PropTypes.string,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      selectedPayer: 0,
      selected: [],
      receivers: [],
    };
  }

  componentDidUpdate(prevProps) {
    this.shouldDataListUpdate(prevProps);
    this.shouldValueUpdate(prevProps);
  }

  shouldDataListUpdate = prevProps => {
    const { receivers } = this.props;
    const receiversList = receivers.map(invoiceReceiver => {
      invoiceReceiver.uniqKey = `${invoiceReceiver.type}-${invoiceReceiver.receiverId}`;
      return invoiceReceiver;
    });
    if (
      !_.isEqual(prevProps.receivers, receiversList) &&
      !_.isEqual(receiversList, this.state.receivers)
    ) {
      this.setState({
        receivers: _.uniqBy(
          [...this.state.receivers, ...receiversList],
          "uniqKey",
        ),
      });
    }
  };
  shouldValueUpdate = prevProps => {
    const { input, fetchData } = this.props;
    const { receivers } = this.state;
    const inputValueHasChange =
      _.has(this.props, "input.value") &&
      !_.isEqual(prevProps.input.value, input.value);
    if (!inputValueHasChange) {
      return false;
    }
    if (receivers.length === 0 && input.value) {
      const search = input.value.name;
      fetchData(
        this.cancelToken,
        {
          search,
        },
        response => {
          const newReceivers = response.data.data.map(receiver => {
            receiver.uniqKey = `${receiver.type}-${receiver.receiverId}`;
            return receiver;
          });
          this.setState(
            {
              receivers: _.uniqBy(
                [...this.state.receivers, ...newReceivers],
                "uniqKey",
              ),
            },
            () => {
              this.setDefaultValue(input.value);
            },
          );
        },
      );
    } else {
      this.setDefaultValue(input.value);
    }
  };

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  setDefaultValue = value => {
    if (_.has(this.props, "multiple") && multiple) {
      this.selectMultiple(value);
    } else {
      this.selectSingle(value);
    }
  };

  /*
   * array value
   * */
  selectMultiple = value => {
    const { receivers } = this.state;
    this.setState({
      selected: receivers.filter(
        dataListItem => value.indexOf(dataListItem.id) >= 0,
      ),
    });
  };

  /*
   * int value
   * */
  selectSingle = value => {
    const { receivers } = this.state;
    if (
      value &&
      this.state.selectedItem !== value &&
      receivers &&
      receivers.length > 0
    ) {
      const dataListItem = receivers.find(item => {
        return _.get(item, "uniqKey") === _.get(value, "uniqKey");
      });
      if (dataListItem) {
        this.setState({
          selected: [dataListItem],
          selectedItem: value,
        });
      }
    } else {
      this.setState({
        selected: [],
        selectedItem: null,
      });
    }
  };

  onSearchHandler = query => {
    this.setState({ query });
    const { fetchData } = this.props;
    if (query.length > 0) {
      fetchData(this.cancelToken, {
        search: query,
      });
    }
  };

  onInputChangeHandler = query => {
    const { fetchData } = this.props;
    this.setState({ selected: [] }, () => {
      query.length > 0 &&
        fetchData(this.cancelToken, {
          search: query,
        });
    });
  };

  onChangeValue = selected => {
    const { onChangeHandler, input, multiple } = this.props;
    const isMultiple = multiple === undefined ? false : multiple;

    if (!onChangeHandler) {
      return;
    }
    if (isMultiple) {
      return onChangeHandler(selected, null, input.name);
    }
    if (selected[0]) {
      return onChangeHandler(selected[0], null, input.name);
    }
    return onChangeHandler(null, null, input.name);
  };

  render() {
    const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
      this.props,
    );

    const {
      under,
      label,
      onClearCallback,
      renderMenuItemChildren,
      filterBy,
      labelKey,
      placeholder,
      t,
      multiple,
    } = this.props;
    const isMultiple = multiple === undefined ? false : multiple;
    const { receivers } = this.state;
    return (
      <div
        className={`form-group selectWithPagination ${classes} ${
          under ? under.containerClassName : "row"
        }`}
      >
        <div>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={this.props.id || "selectWithPagination"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={this.onChangeValue}
                options={receivers}
                labelKey={labelKey}
                renderMenuItemChildren={renderMenuItemChildren}
                filterBy={filterBy}
                minLength={0}
                maxResults={90}
                paginate={false}
                placeholder={placeholder}
                disabled={shouldDisabled}
                multiple={isMultiple}
              />
              <span aria-hidden="true" className="rw-i rw-i-caret-down" />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError && <span className="help-block">{t(error)}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(SelectInvoiceReceiverWithSearch);
