export default fields => {
  let errors = {};
  const { rooms } = fields;
  let roomsErrors = [];
  rooms &&
    rooms.forEach((room, index) => {
      roomsErrors[index] = {};
      if (!room.name) {
        roomsErrors[index] = {
          ...roomsErrors[index],
          name: "Enter the name",
        };
      }
      if (room.address) {
        if (!room.address.street) {
          roomsErrors[index].address = {
            ...roomsErrors[index].address,
            street: "Enter the street name",
          };
        }
        if (!room.address.zipCode) {
          roomsErrors[index].address = {
            ...roomsErrors[index].address,
            zipCode: "Enter the zip code",
          };
        }
        if (!room.address.city) {
          roomsErrors[index].address = {
            ...roomsErrors[index].address,
            city: { id: "Choose the city" },
          };
        }
      }
    });
  errors.rooms = { ...roomsErrors };
  return errors;
};
