import Model from "../../Abstracts/Model";

class CurrentPollResultsModel extends Model {
  constructor(values) {
    super();
    this.values = { ...values };
  }
}

export default CurrentPollResultsModel;
