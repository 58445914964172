import { companiesInitState } from "./Companies";
import COMPANIES_ACTION_TYPES from "../Actions/ActionTypes";

export function contacts(state = companiesInitState.companyContacts, action) {
  switch (action.type) {
    case COMPANIES_ACTION_TYPES.FETCH_CONTACTS:
      return [...action.payload.data.data];

    default:
      return state;
  }
}
