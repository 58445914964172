import React, { Component } from "react";
import { renderFieldsHelper } from "./forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import ACTION_TYPES from "../Enums/ActionTypes";

class CitySelectWithPagination extends Component {
  static propTypes = {
    fetchDictionary: PropTypes.func,
    onlyTeach: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
    ]),
    cities: PropTypes.array,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.string,
    onClearCallback: PropTypes.func,
    onChangeHandler: PropTypes.func,
    t: PropTypes.func,
    countryId: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.PAGE_NO = 1;
    this.PAGE_AMOUNT = 10;
    this.state = {
      page_no: 1,
    };

    const { fetchDictionary, value, onlyTeach, countryId } = this.props;
    const fetchConfig = {
      path: "/cities",
      actionType: ACTION_TYPES.FETCH_CITIES,
      params: {
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
        onlyTeach,
        countryId,
      },
    };
    if (value && value.length > 0) {
      fetchConfig.params.q = value;
    }
    fetchDictionary(this.cancelToken, fetchConfig, () => {
      if (value) {
        this.setDefaultValue(value);
      }
    });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  setDefaultValue = value => {
    const { cities } = this.props;
    if (value !== this.state.selectedCityName) {
      const city = cities.find(city => {
        return city.name === value;
      });
      let selected = [];
      let selectedCityName = null;
      if (city) {
        selected = [city];
        selectedCityName = value;
      }
      this.setState({
        selected,
        selectedCityName,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { value, cities } = this.props;
    if (value !== prevProps.value) {
      this.setDefaultValue(value);
    }
    if (!_.isEqual(cities, prevProps.cities) && value && value.length > 0) {
      this.setDefaultValue(value);
    }
  }

  onSearchHandler = query => {
    this.setState({ query });
    const { fetchDictionary, countryId } = this.props;
    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: ACTION_TYPES.FETCH_CITIES,
      params: {
        q: query,
        pageNo: this.PAGE_NO,
        pageAmount: this.PAGE_AMOUNT,
        onlyTeach: this.props.onlyTeach,
        countryId,
      },
    });
  };

  onInputChangeHandler = query => {
    const { fetchDictionary, countryId } = this.props;
    this.setState({ selected: [] }, () => {
      query.length === 0 &&
        fetchDictionary(this.cancelToken, {
          path: "/cities",
          actionType: ACTION_TYPES.FETCH_CITIES,
          params: {
            pageNo: this.PAGE_NO,
            pageAmount: this.PAGE_AMOUNT,
            onlyTeach: this.props.onlyTeach,
            countryId,
          },
        });
    });
  };

  onPaginateHandler = () => {
    const { fetchDictionary } = this.props;
    this.setState({ page_no: this.state.page_no + 1 }, () => {
      fetchDictionary(this.cancelToken, {
        path: "/cities",
        actionType: ACTION_TYPES.FETCH_CITIES,
        params: {
          pageNo: this.state.page_no,
          pageAmount: this.PAGE_AMOUNT,
          onlyTeach: this.props.onlyTeach,
        },
      });
    });
  };

  render() {
    const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
      this.props,
    );
    const { under, label, onClearCallback, t } = this.props;

    const labelForCity = city => {
      const { country } = city;
      return (
        <span>
          {`${city.name} `}
          {country && country.name && <span>({country.name})</span>}
        </span>
      );
    };

    return (
      <div
        className={`form-group  ${classes} ${
          under ? under.containerClassName : "row"
        }`}
      >
        <div className={under ? under.containerClassName : "row"}>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={"citiesTypeahead"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={selected => {
                  this.props.onChangeHandler(selected[0]);
                }}
                options={this.props.cities}
                labelKey={city => `${city.name}`}
                renderMenuItemChildren={labelForCity}
                filterBy={(option, { text }) => {
                  const search = option.name;
                  return search.toLowerCase().indexOf(text.toLowerCase()) > -1;
                }}
                minLength={0}
                maxResults={9}
                paginate={true}
                paginationText="Pokaż więcej wyników"
                onPaginate={this.onPaginateHandler}
                disabled={shouldDisabled}
              />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError && <span className="help-block">{t(error)}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(CitySelectWithPagination);
