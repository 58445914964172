import COMPANIES_ACTION_TYPES from "../Actions/ActionTypes";
import { companiesInitState } from "./Companies";

export const companyFromGus = (
  state = companiesInitState.companyFromGus,
  action,
) => {
  switch (action.type) {
    case COMPANIES_ACTION_TYPES.FETCH_COMPANY_FROM_GUS:
      return { ...action.payload.data.data[0] };
    case COMPANIES_ACTION_TYPES.CLEAR_COMPANY:
      return {};
    default:
      return state;
  }
};
