import React from "react";
import ChangePasswordPreview from "../Components/ChangePasswordPreview";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changePassword } from "../Actions";

const ChangePassword = props => <ChangePasswordPreview {...props} />;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changePassword,
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(ChangePassword);
