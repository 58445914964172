import React from "react";
import { Field } from "redux-form";
import checkIfContractFinishReasonIs from "../../../Helpers/CheckIfContractFinishReasonIs";
import CONTRACT_FINISH_REASONS from "../../../Enums/ContractFinishReasons";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { RenderNumber, RenderSelect } from "../../../Forms/forms";
import {
  Authorize,
  havePermissions,
  permissions,
} from "../../../Common/Utils/Acl";

class ContractRebate extends React.Component {
  static propTypes = {
    contractFinishReason: PropTypes.number,
    setRebate: PropTypes.func,
    setSpecialOffer: PropTypes.func,
    clearSpecialOffer: PropTypes.func,
    specialOffers: PropTypes.array,
    disabled: PropTypes.bool,
    t: PropTypes.func,
  };

  render() {
    const {
      contractFinishReason,
      setRebate,
      setSpecialOffer,
      clearSpecialOffer,
      specialOffers = [],
      t,
      disabled,
    } = this.props;

    return (
      <div>
        <Authorize
          component={
            <Field
              name="payments.rebate"
              label={t("Discount(%)")}
              onChange={setRebate}
              max={100}
              parse={string => parseInt(string)}
              precision={1}
              shouldDisabled={
                disabled ||
                !havePermissions([permissions.STUDENT_CONTRACT_REBATE_EDIT])
              }
              status={
                checkIfContractFinishReasonIs(
                  contractFinishReason,
                  CONTRACT_FINISH_REASONS.RESIGN,
                ) ||
                checkIfContractFinishReasonIs(
                  contractFinishReason,
                  CONTRACT_FINISH_REASONS.TRANSFER,
                )
                  ? "inactive"
                  : ""
              }
              component={RenderNumber}
            />
          }
          allows={[permissions.STUDENT_CONTRACT_REBATE_VIEW]}
        />

        <Field
          name="contract.specialOfferId"
          label={t("Special offer")}
          dropdownConfig={{
            onChange: setSpecialOffer,
            data: specialOffers,
            textField: "name",
            valueField: "id",
          }}
          component={RenderSelect}
          disabled={disabled}
          onClearCallback={clearSpecialOffer}
        />
      </div>
    );
  }
}

export default translate()(ContractRebate);
