import React from "react";
import ReactTableWithSummary from "../../../Common/Components/ReactTableWithSummary/ReactTableWithSummary";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Invoice } from "../../Reports/Invoice/Types/Invoice";
import dateHelper from "../../../Helpers/DateHelper";
import TIME from "../../../Enums/Time";
import InvoiceStatusCell from "../../Reports/Invoice/components/InvoiceStatusCell";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import { Edit2 } from "react-feather";
import Tooltip from "react-simple-tooltip";
import { InvoiceSendStatus } from "../../../Enums/InvoiceSendStatus";
import SendLogs from "../../Reports/Invoice/components/SendLogs";

type InvoicesListProps = InjectedTranslateProps & {
  invoices: Array<Invoice>;
  showEditInvoiceForm: (invoice: Invoice) => void;
  handleChangeSendStatus: (
    transition: { name: string; to: InvoiceSendStatus },
    invoice: Invoice,
  ) => void;
  downloadInvoice: (data: { id: number; nr: number }) => void;
};

const InvoicesList: React.FunctionComponent<InvoicesListProps> = ({
  t,
  invoices,
  showEditInvoiceForm,
  handleChangeSendStatus,
  downloadInvoice,
}): React.ReactElement => {
  const invoicesTableColumns = [
    {
      Header: () => <div>{t(`Number`)}</div>,
      Cell: ({ original: { id, nr, correctionNumbers, deleteReason } }) => (
        <>
          {deleteReason ? (
            <span className={"text-line-through"}>{nr}</span>
          ) : (
            <a
              onClick={() => {
                downloadInvoice({ id, nr });
              }}
            >
              {nr}
            </a>
          )}
          {correctionNumbers && <span>{`(${correctionNumbers})`}</span>}
        </>
      ),
      width: 200,
      accessor: "nr",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Payment date`)}</div>,
      Cell: ({ original: { paymentDate } }) => (
        <span>
          {paymentDate &&
            dateHelper(paymentDate)
              .get()
              .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "paymentDate",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Incomes`)}</div>,
      Cell: ({ original: { incomes } }) => incomes.join(", "),
      accessor: "incomes",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Status`)}</div>,
      accessor: "status.name",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Send status`)}</div>,
      Cell: ({ original }) => {
        return (
          <div
            style={{
              position: "absolute",
              width: "inherit",
              paddingRight: "10px",
            }}
          >
            {original.sendStatusLog.length > 0 ? (
              <Tooltip
                placement="left"
                color="#000"
                background="#FFF"
                zIndex={99}
                radius={15}
                content={<SendLogs t={t} logs={original.sendStatusLog} />}
              >
                <InvoiceStatusCell
                  t={t}
                  invoice={original}
                  handleChangeStatus={transition =>
                    handleChangeSendStatus(transition, original)
                  }
                />
              </Tooltip>
            ) : (
              <InvoiceStatusCell
                t={t}
                invoice={original}
                handleChangeStatus={transition =>
                  handleChangeSendStatus(transition, original)
                }
              />
            )}
          </div>
        );
      },
      width: 200,
      className: "padding__t5",
      accessor: "sendStatus",
      sortable: false,
    },
    {
      Header: () => <div>{t(`Amount`)}</div>,
      Cell: ({ original: { total } }) => (
        <span className={`tableAmount`}>{renderAmountWithCurrency(total)}</span>
      ),
      accessor: "total.amount",
      sortable: false,
      className: "text-right",
      width: 100,
      summaryAmount: "total.amount",
      summaryCurrency: "total.currency",
    },
    {
      name: "edit",
      className: "table-cell-overflow",
      Header: "",
      width: 90,
      Cell: ({ original }) => {
        const { canBeEditedOrRemoved } = original;

        return canBeEditedOrRemoved ? (
          <button
            type={`button`}
            className={`btn btn--transparent btn--no-border invoiceEditButton`}
            style={{ padding: 0 }}
            onClick={() => showEditInvoiceForm(original)}
          >
            <Edit2 size={20} />
          </button>
        ) : (
          <div className={`text-center`}>
            <Tooltip
              padding={5}
              placement="left"
              zIndex={99}
              content={t(
                "The invoice cannot be edited because it has already been sent",
              )}
            >
              <button
                type={`button`}
                disabled={!canBeEditedOrRemoved}
                className={`btn btn--transparent btn--no-border invoiceEditButton`}
                style={{ padding: 0 }}
                onClick={() => showEditInvoiceForm(original)}
              >
                <Edit2 size={20} />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className={`row`}>
      <div className={`col-xs-12`} id={`invoices`}>
        <h2>{t("Invoices")}</h2>
        <ReactTableWithSummary
          data={invoices ? invoices : []}
          summaryConfig={[
            {
              data: invoices,
            },
          ]}
          defaultPageSize={invoices ? invoices.length : 0}
          showPagination={false}
          manual
          columns={invoicesTableColumns}
        />
      </div>
    </div>
  );
};

export default InvoicesList;
