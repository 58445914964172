import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPolls,
  updatePoll,
  createPoll,
  fetchPollEvents,
} from "../Actions";
import { fetchDictionary } from "../Actions";
import PollsDictionary from "../Components/PollsDictionary";
import validate from "../Validators/PollsDictionaryValidator";

const FORM_NAME = `DICTIONARY_POLLS`;

let DictionaryPolls = props => <PollsDictionary {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "pollsList");
  let { pollsList, pollEvents, dictionaries } = state;
  return {
    pollsList,
    pollEvents,
    dictionaries,
    values,
    initialValues: { pollsList },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPolls,
      updatePoll,
      createPoll,
      fetchPollEvents,
      fetchDictionary,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryPolls),
);
