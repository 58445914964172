export default (phone = "") => {
  const phoneWithoutSpaces = phone.replace(/-|\s/g, "");
  let formattedPhone = "";
  const digit = 3;

  for (let i = 0, n = phoneWithoutSpaces.length; i < n; i += digit) {
    formattedPhone += phoneWithoutSpaces.substr(i, digit);
    formattedPhone += " ";
  }

  return formattedPhone.trim();
};
