import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import NewEventForm from "../Components/NewEventForm";
import validate from "../Validators/EventValidator";

const FORM_NAME = "NEW_EVENT_FORM";
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, ownProps) => {
  const { ajaxCallInProgressCount } = state;

  const values = selector(
    state,
    "date",
    "name",
    "type",
    "duration",
    "startTime",
    "participants",
    "courseMode",
    "lecturer",
    "room",
    "settled",
    "isProduct",
    "roomRequired",
  );

  return { ...ownProps, values, isLoading: ajaxCallInProgressCount > 0 };
};

export default reduxForm({
  form: FORM_NAME,
  validate,
})(connect(mapStateToProps)(NewEventForm));
