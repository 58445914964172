import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CourseSettings from "../Components/CourseSettings";
import React from "react";

const CourseSettingsContainer = props => <CourseSettings {...props} />;

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = (appState, ownProps) => {
  const courseId = Number(ownProps.match.params.courseId);

  const {
    courses: {
      course: { signature, cancelDate },
    },
    ajaxCallInProgressCount,
  } = appState;

  return {
    courseId,
    courseSignature: signature,
    courseCancelDate: cancelDate,
    isLoading: ajaxCallInProgressCount > 0,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseSettingsContainer);
