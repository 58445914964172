import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Paginator extends Component {
  static propTypes = {
    pages: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    const { pagesLeft, pagesRight } = this.splitPages();
    this.state = {
      pagesLeft,
      pagesRight,
      visiblePages: 6,
    };
  }

  componentDidUpdate(prevProps) {
    const { pages } = this.props;
    if (pages > 0 && prevProps.pages !== pages) {
      const { pagesLeft, pagesRight } = this.splitPages();
      this.setState({ pagesLeft, pagesRight });
    }
  }

  splitPages = (changedPage = null) => {
    const { pages, currentPage } = this.props;

    const pagesLeft = [];
    const pagesRight = [];
    const pageLimit = 3;
    const bothSidesAvailable = pages > pageLimit * 2;
    const sidesDiffMinValue = -1;

    let leftSideStart = changedPage || currentPage;
    let rightSideStart = pages - pageLimit;
    let diff = leftSideStart - rightSideStart;

    if (diff >= sidesDiffMinValue && bothSidesAvailable) {
      leftSideStart = rightSideStart - pageLimit;
    } else if (leftSideStart === pages) {
      leftSideStart -= 2;
    } else if (leftSideStart !== 1) {
      leftSideStart--;
    }
    let leftSideStop = leftSideStart + pageLimit;

    for (let i = leftSideStart; i <= leftSideStop && i <= pages; i++) {
      if (i > 0) {
        pagesLeft.push(i);
      }
    }

    if (bothSidesAvailable) {
      for (let j = pageLimit - 1; j >= 0; j--) {
        pagesRight.push(pages - j);
      }
    }

    return {
      pagesLeft,
      pagesRight,
    };
  };

  changePage(event, page) {
    event.preventDefault();
    const { pagesLeft, pagesRight } = this.splitPages(page);
    this.setState({ pagesRight, pagesLeft }, () => {
      this.props.onClick(page);
    });
  }

  renderPageElement(page) {
    return (
      <li
        key={page}
        className={this.props.currentPage === page ? "active" : ""}
      >
        <a
          href="#"
          onClick={event => {
            event.preventDefault();
            this.changePage(event, page);
          }}
        >
          {page}
        </a>
      </li>
    );
  }

  renderPagination() {
    const { pages } = this.props;
    const { pagesRight, pagesLeft } = this.state;
    return (
      <Fragment>
        {pagesLeft.map(page => {
          return this.renderPageElement(page);
        })}
        {pages > this.state.visiblePages && (
          <li>
            <span>...</span>
          </li>
        )}
        {pagesRight.map(page => {
          return this.renderPageElement(page);
        })}
      </Fragment>
    );
  }

  render() {
    const { pages } = this.props;
    return (
      !!pages &&
      pages > 1 && (
        <nav className="pull-right">
          <ul className="pagination">
            <li>
              <a
                href="#"
                onClick={event => {
                  this.changePage(event, 1);
                }}
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {this.renderPagination()}
            <li>
              <a
                href="#"
                onClick={event => {
                  this.changePage(event, pages);
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      )
    );
  }
}

export default Paginator;
