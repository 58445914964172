import { translate } from "react-i18next";
import { DateTimePicker } from "react-widgets";
import dateHelper from "../../Helpers/DateHelper";
import React from "react";
import { RenderError } from "../forms";
import TIME from "../../Enums/Time";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { FieldProps } from "formik";
import { renderFieldsHelper } from "../FormikUtils";

type RenderDateTimeProps = FieldProps &
  InjectedTranslateProps & {
    min?: Date | undefined;
    max?: Date | undefined;
    time?: boolean | undefined;
    date?: boolean | undefined;
    dateFormat?: string | undefined;
    step?: number | boolean | undefined;
    onChange?: ((date?: Date, dateStr?: string) => void) | undefined;
    label?: string;
    id?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    views?: Array<string>;
    under?: {
      containerClassName?: string;
      fieldClassName?: string;
    };
  };

const RenderDateTimeToTranslate: React.FunctionComponent<
  RenderDateTimeProps
> = (props: RenderDateTimeProps): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);

  const {
    time,
    date,
    field: { value, onBlur },
    field,
    form,
    under,
    label,
    onChange: customChange,
    min,
    max,
    step,
    className,
    disabled,
    t,
    readOnly,
    views,
    dateFormat,
    id,
  } = props;

  return (
    <div
      id={id ? `${id}-container` : undefined}
      className={`form-group ${classes} ${className} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div className={under ? "row" : ""}>
        <div
          className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
            !label || label.length === 0 ? "hidden" : ""
          }`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <DateTimePicker
            time={time}
            disabled={disabled}
            format={dateFormat ? dateFormat : TIME.DATE_FORMAT}
            step={step ? step : 30}
            date={date}
            readOnly={readOnly}
            name={field.name}
            value={
              !value
                ? null
                : new Date(
                    dateHelper(Number(value))
                      .getUTC(Number(value))
                      .valueOf(),
                  )
            }
            min={
              min
                ? dateHelper(min)
                    .get()
                    .toDate()
                : dateHelper("1900-01-01")
                    .get()
                    .toDate()
            }
            max={
              max
                ? dateHelper(max)
                    .get()
                    .toDate()
                : dateHelper("9999-01-01")
                    .get()
                    .toDate()
            }
            onChange={
              customChange
                ? (date?: Date, dateStr?: string) => customChange(date, dateStr)
                : (date?: Date) =>
                    form.setFieldValue(
                      field.name,
                      dateHelper(date).getFormatted(),
                    )
            }
            onBlur={onBlur}
            views={views ? views : ["month", "year"]}
          />
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

export const RenderDateTime = translate()(RenderDateTimeToTranslate);
