import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

const CourseStudentsSummary = ({ shouldRender, t, courseStatistics }) => {
  if (!shouldRender) {
    return null;
  }
  return (
    <Fragment>
      <span className="item">
        {t("Number of places")}: {courseStatistics.all}
      </span>
      <span className="item">
        {t("Free")}: {courseStatistics.free}
      </span>
      <span className="item">
        {t("Overbooking")}: {courseStatistics.overbook}
      </span>
      <span className="item">
        <strong>
          {t("Current students")}: {courseStatistics.allActiveStudents}{" "}
        </strong>
      </span>
      <span className="item">
        {t("Resignations")}: {courseStatistics.resign}{" "}
      </span>
      <span className="item">
        {t("Transfers")}: {courseStatistics.transfer}{" "}
      </span>
    </Fragment>
  );
};

CourseStudentsSummary.propTypes = {
  shouldRender: PropTypes.bool,
  t: PropTypes.func,
  courseStatistics: PropTypes.object,
};

export default translate()(CourseStudentsSummary);
