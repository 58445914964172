export default {
  STATUSES: {
    DURING_RECRUITMENT: 1,
    CONTRACT_SEND: 2,
    CONTRACT_SIGNED: 3,
    CONTRACT_DENIED: 4,
    COURSE_CANCELED: 5,
    CONTRACT_CANCELED: 6,
  },
  FINISH_REASONS: {
    RESIGN: 1,
    FAIL: 2,
    TRANSFER: 3,
    ABSOLUTORY: 4,
    FINISHED: 5,
    CANCELLED: 6,
  },
  thirdPartyPayerType: 4,
  ADDITIONAL_FEE_TYPE_ID: 7,
};

export const COURSE_CONTRACT_FINISH_REASON_ID = {
  FINISHED: 1,
  TRANSFER: 2,
  RESIGN: 3,
  FAIL: 4,
  CANCELLED: 5,
  ABSOLUTORY: 6,
  TRANSFER_RESIGN: 7,
  WAITING_FOR_SLAVES: 8,
  MODULE_COMPLETED: 9,
};
