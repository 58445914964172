import React from "react";
import { Info } from "react-feather";
import PropTypes from "prop-types";

const HoverInfo = ({ content }) => (
  <div className="hover-info">
    <Info size={12} className="hover-info__trigger" />
    <div className="hover-info__content">{content}</div>
  </div>
);

HoverInfo.propTypes = { content: PropTypes.string };

export default HoverInfo;
