import dateHelper from "../../../Helpers/DateHelper";
import { FormikErrors } from "formik";
import { CourseSettingsFormValues } from "../Types/CourseSettingsForm";
import { timeToMoment } from "../../../Common/Utils/DateFormatters";

export default (
  fields: CourseSettingsFormValues,
): FormikErrors<CourseSettingsFormValues> => {
  const errors: FormikErrors<CourseSettingsFormValues> = {};
  const {
    product,
    publicityDate,
    startDate,
    city,
    numberOfPlaces,
    courseSchedule,
  } = fields;

  if (product.length === 0) {
    errors.product = "Choose product";
  }
  if (!publicityDate) {
    errors.publicityDate = "Choose publication date";
  }

  if (!startDate) {
    errors.startDate = "Choose the start date of course";
  }
  if (!city) {
    errors.city = "Choose the city";
  }
  if (!numberOfPlaces) {
    errors.numberOfPlaces = "Enter the number of places";
  }
  if (numberOfPlaces <= 0) {
    errors.numberOfPlaces = "The number of seats should be greater than 0";
  }
  if (
    publicityDate &&
    startDate &&
    dateHelper().isFirstDateLater(publicityDate, startDate)
  ) {
    errors.publicityDate = "Publication date it has to be before start date";
    errors.startDate = "Start date it has to be after publication date";
  }
  if (courseSchedule) {
    if (courseSchedule.days.length === 0) {
      const errorDays = {
        days: "At least one day should be checked",
      };

      errors.courseSchedule = { ...errors.courseSchedule, ...errorDays };
    }

    if (!courseSchedule.startHour) {
      const startHour = "Choose hour";

      errors.courseSchedule = { ...errors.courseSchedule, startHour };
    }

    if (!courseSchedule.endHour) {
      const endHour = "Choose hour";

      errors.courseSchedule = { ...errors.courseSchedule, endHour };
    }

    if (
      !timeToMoment(courseSchedule.endHour).isAfter(
        timeToMoment(courseSchedule.startHour),
      )
    ) {
      const startHour = "The start hour should be less than the end hour";

      errors.courseSchedule = { ...errors.courseSchedule, startHour };
    }

    if (!courseSchedule.courseMode) {
      const courseMode = "Choose course mode";

      errors.courseSchedule = { ...errors.courseSchedule, courseMode };
    }

    if (!courseSchedule.courseMode) {
      const errorCourseMode = {
        courseMode: "Choose the course mode",
      };

      errors.courseSchedule = { ...errors.courseSchedule, ...errorCourseMode };
    }
  }
  return errors;
};
