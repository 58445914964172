import dinero from "dinero.js";

export const convertAmountToPrice = amount =>
  amount
    ? Number(
        dinero({ amount: _.toInteger(amount) })
          .toFormat("0.00")
          .replace(",", "."),
      )
    : 0;

export const convertPriceToAmount = price =>
  price ? dinero({ amount: _.toInteger(_.round(price * 100)) }).getAmount() : 0;
