import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import React from "react";
import CourseCalendar from "../Components/CourseCalendar";
import {
  fetchFreeLecturesAndRooms,
  fetchPackagesDictionary,
  publishCalendar,
  refreshCurrentCourse,
  resetCalendar,
  getSchedule,
  fetchCourse,
  cancelDraft,
} from "../Actions/CoursesActions";
import { isDateBeforeToday } from "../../../Common/Utils/DateFormatters";

const SettingsFormContainer = props => <CourseCalendar {...props} />;
const FORM_NAME = "COURSE_CALENDAR_FORM";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchFreeLecturesAndRooms,
      publishCalendar,
      resetCalendar,
      refreshCurrentCourse,
      fetchPackagesDictionary,
      getSchedule,
      fetchCourse,
      cancelDraft,
    },
    dispatch,
  );
};

const mapStateToProps = (state, { courseId }) => {
  const {
    courses: {
      course: { courseCalendar, cancelDate, endDate },
      freeLecturesAndRooms,
      packages,
    },
    ajaxCallInProgressCount,
  } = state;

  return {
    courseId,
    packages,
    courseCalendar,
    freeLecturesAndRooms,
    initialValues: courseCalendar,
    isLoading: ajaxCallInProgressCount > 0,
    isCancel: !!cancelDate,
    isEnd: isDateBeforeToday(endDate),
  };
};

export default reduxForm({
  enableReinitialize: true,
  form: FORM_NAME,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsFormContainer),
);
