import ACTION_TYPES from "../../../Enums/ActionTypes";

export function invoiceStatusPossiblePlaces(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_INVOICE_STATUS_POSSIBLE_PLACES:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function invoiceSendStatusPossiblePlaces(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_INVOICE_SEND_STATUS_POSSIBLE_PLACES:
      return action.payload.data.data;
    default:
      return state;
  }
}
