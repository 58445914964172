import React, { Component } from "react";
import ProductStatus from "./ProductStatus.js";
import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import {
  permissions,
  Authorize,
  havePermissions,
} from "../../../Common/Utils/Acl";

class ProductFormHistoryItem extends Component {
  static propTypes = {
    version: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    lastVersionOfProduct: PropTypes.number,
    employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    makeProductCopy: PropTypes.func,
    history: PropTypes.object,
    shouldDisabled: PropTypes.bool,
    match: PropTypes.object,
    t: PropTypes.func,
    passValues: PropTypes.func,
    employees: PropTypes.array,
  };
  render() {
    const {
      version: { version, status, id: versionId, author },
      shouldDisabled,
      makeProductCopy,
    } = this.props;
    const versionIdUrl = this.props.match.params.versionId;
    const itemClasses = `history-item ${
      versionId == versionIdUrl ? "history-item--active" : ""
    }`;
    return (
      versionId && (
        <div className={itemClasses}>
          <div className="history-item__number">
            <NavLink
              to={`/products/product/${versionId}`}
              activeClassName="history__item--active"
            >
              <span className="number-ico">{version}</span>
            </NavLink>
          </div>
          <div className="history-item__content">
            <h4>
              {this.props.t("Author")}: {author}
            </h4>
            <ProductStatus status={status} />
          </div>
          <div className="history-item__actions">
            {status &&
              status === "draft" &&
              havePermissions([permissions.PRODUCT_VERSION_DELETE]) && (
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => {
                    this.props.passValues(versionId);
                  }}
                  disabled={shouldDisabled}
                >
                  <Icon.Trash size={20} />
                </button>
              )}
            <Authorize
              component={
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => {
                    makeProductCopy(versionId);
                  }}
                  disabled={shouldDisabled}
                >
                  <Icon.Copy size={20} />
                </button>
              }
              allows={[permissions.PRODUCT_COPY]}
            />
          </div>
        </div>
      )
    );
  }
}

export default translate()(withRouter(ProductFormHistoryItem));
