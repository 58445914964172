import { store } from "../../../Configs/Root.js";
import checkPersonIsEmployee from "../../../Helpers/CheckPersonIsEmployee";
import { isEmail } from "validator";
import { TenantValidator } from "../../../Common/Utils/Tenant";

export default fields => {
  const errors = {};
  const emailArrayErrors = [];
  const phoneArrayErrors = [];
  const employeeTypeId = store.getState().employeeTypeId;
  const {
    name,
    surname,
    sex,
    typeIds,
    isCourseSpecialist,
    contractTypeId,
    contractStartDate,
    courseModeIds,
    availabilityTypeId,
    emails,
    phones,
    pesel,
    streetAddress,
    zipCodeAddress,
    cityNameAddress,
    countryAddress,
    streetContactAddress,
    zipCodeContactAddress,
    cityContactAddress,
    countryContactAddress,
    contractEndDate,
    bankAccount,
  } = fields;
  let bankAccountFormat = /[0-9]{1,}/;

  if (!name) {
    errors.name = "Enter the person's name";
  }
  if (!surname) {
    errors.surname = "Enter the surname";
  }
  if (!sex) {
    errors.sex = "Choose sex";
  }
  if (bankAccount && !bankAccountFormat.test(bankAccount)) {
    errors.bankAccount = "Bad account number format";
  }
  if (typeIds && typeIds.length === 0) {
    errors.typeIds = "Choose the user's type";
  }
  if (pesel && !TenantValidator.isPeselValid(pesel)) {
    errors.pesel = "Invalid personal identity number";
  }
  if (
    hasAtLeastOneFilledField([
      streetAddress,
      zipCodeAddress,
      cityNameAddress,
      countryAddress,
    ])
  ) {
    if (!streetAddress) {
      errors.streetAddress = "Enter the street name";
    }
    if (!zipCodeAddress) {
      errors.zipCodeAddress = "Enter the zip code";
    }
    if (!cityNameAddress) {
      errors.cityNameAddress = "Choose a city";
    }
    if (!countryAddress) {
      errors.countryAddress = "Choose a country";
    }
  }
  if (
    hasAtLeastOneFilledField([
      streetContactAddress,
      zipCodeContactAddress,
      cityContactAddress,
      countryContactAddress,
    ])
  ) {
    if (!streetContactAddress) {
      errors.streetContactAddress = "Enter the street name";
    }
    if (!zipCodeContactAddress) {
      errors.zipCodeContactAddress = "Enter the zip code";
    }
    if (!cityContactAddress) {
      errors.cityContactAddress = "Choose a city";
    }
    if (!countryContactAddress) {
      errors.countryContactAddress = "Choose a country";
    }
  }
  emails &&
    emails.length > 0 &&
    emails.forEach((email, index) => {
      if (!email || !isEmail(email)) {
        emailArrayErrors[index] =
          "Enter a valid e-mail address formatted name@domain.com";
      }
    });

  phones &&
    phones.length > 0 &&
    phones.forEach((phone, index) => {
      if (phone && !phone.match(/^(?=.*[0-9])[- +()0-9]+$/)) {
        phoneArrayErrors[index] = "Enter a valid phone number";
      }
    });
  if (typeIds && checkPersonIsEmployee(typeIds, employeeTypeId)) {
    if (isCourseSpecialist === undefined) {
      errors.isCourseSpecialist =
        "Specify whether the employee is a Course Specialist";
    }
    if (!contractTypeId) {
      errors.contractTypeId = "Specify the contract type";
    }
    if (!contractStartDate || contractStartDate == 0) {
      errors.contractStartDate = "Specify the contract start date";
    }
    if (courseModeIds && courseModeIds.length === 0) {
      errors.courseModeIds = { _error: "Specify the course mode" };
    }
    if (!availabilityTypeId) {
      errors.availabilityTypeId = "Specify the availability";
    }
  }
  if (emailArrayErrors && emailArrayErrors.length > 0) {
    errors.emails = emailArrayErrors;
  }
  if (emailArrayErrors && phoneArrayErrors.length > 0) {
    errors.phones = phoneArrayErrors;
  }
  if (contractEndDate && contractStartDate > contractEndDate) {
    errors.contractEndDate =
      "The contract end date cannot be earlier than the contract start date";
  }
  return errors;
};

function hasAtLeastOneFilledField(fields = []) {
  let emptyCount = 0;
  fields.forEach(field => {
    if (!field || field.length === 0) {
      emptyCount++;
    }
  });
  return emptyCount !== fields.length;
}
