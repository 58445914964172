import { translate } from "react-i18next";

import React from "react";
import { FieldProps } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { RenderError, renderFieldsHelper } from "../FormikUtils";

type CheckboxInputProps = FieldProps &
  InjectedTranslateProps & {
    label?: string;
    id?: string;
    labelForCheckbox?: string;
    disabled?: boolean;
    under?: {
      containerClassName?: string;
      fieldClassName?: string;
    };
    customLabel?: string;
  };

const RenderCheckboxToTranslate: React.FunctionComponent<CheckboxInputProps> = (
  props: CheckboxInputProps,
): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);
  const {
    field,
    form,
    label,
    labelForCheckbox,
    under,
    customLabel,
    t,
    id,
  } = props;
  const inputLabel = label ? label : labelForCheckbox;
  return (
    <div
      className={`form-group ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div
        className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
          !label || label.length === 0 ? "hidden" : ""
        }`}
      >
        <label htmlFor={id}>{customLabel ? customLabel : label}</label>
      </div>
      <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
        <label className="checkbox-inline" htmlFor={id}>
          <input
            name={field.name}
            id={id}
            type="checkbox"
            disabled={displayError}
            checked={String(field.value) == "true"}
            onChange={event =>
              form.setFieldValue(field.name, event.target.checked)
            }
          />
          <span>{inputLabel}</span>
        </label>
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};
export const RenderCheckbox = translate()(RenderCheckboxToTranslate);
