import Model from "../../Abstracts/Model";

class TimeLineModel extends Model {
  constructor(values) {
    super();
    this.values = { ...values };
  }
  mapFiltersSearchTerms = () => {
    const formattedValues = {
      ...this.values,
      coursesModesId:
        this.values.coursesModesId && this.values.coursesModesId.length > 0
          ? this.values.coursesModesId.map(mode => mode.id)
          : null,
    };
    return this.removeEmptyKeys(formattedValues);
  };
}

export default TimeLineModel;
