export default {
  "Employees availability": "Employees availability",
  Payers: "Payers",
  "Add room": "Add room",
  ActiveRoom: "Active",
  InactiveRoom: "Inactive",
  InactiveProduct: "Inactive",
  ActiveProduct: "Active",
  NamePerson: "Name",
  NameItem: "Name",
  clearCommitments: "clear",
  "pisanie test score": "Writing test score",
  "angielski test score": "English test score",
  "logika test score": "Logic test score",
  "Search persons by competences": "Search persons by competences",
  "Your selected competences": "Your selected competences",
  "Search persons": "Search persons",
  "save filter": "save filter",
  "restore filter": "restore filter",
  "remove filter": "remove filter",
  "Choose filter": "Choose filter",
  Graduates: "Graduates",
  course_end: "days after the course ended",
  course_end_negative: "days before the course ended",
  course_start: "days after the course started",
  course_start_negative: "days before the course started",
  module_end: "days after the module ended",
  module_end_negative: "days before the module ended",
  module_start: "days after the module started",
  module_start_negative: "days before the module started",
  event_contract_signed: "days after the signing of the contract",
  event_contract_signed_negative: "days before the signing of the contract",
  at_the_course_start: "the day of the course start",
  at_the_course_end: "the day of the course end",
  at_the_module_start: "the day of the module start",
  at_the_module_end: "the day of the module end",
  at_the_event_contract_signed: "the day of contract signed",
  at_the_manual: "manual",
  InvoiceFrom: "From",
  InvoiceTo: "to",
  "Roll up modules": "Collapse modules",
  Balance_: "Balance",
  "Sum of third parties' part must be equal":
    "Sum of third parties' part must be equal {{maxPayer}}",
  ssp: "Student single payment",
  si: "Student installments",
  csp: "Company single payment",
  ci: "Company installments",
  cs: "Course schedule",
  not_sent: "Not sent",
  ready_to_send: "Ready to send",
  sent: "Sent",
  in_review: "In review",
  in_send: "In send",
  in_send_retry: "Send failed, rescheduled",
  sent_error: "Sent error",
  not_handled: "Sending outside HUB",
};
