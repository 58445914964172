import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchLecturersSettlement } from "../Actions";
import validate from "../Validators/ReportValidator";
import { formValueSelector, reduxForm } from "redux-form";
import { fetchMentors, getCoursesSpecialists } from "../../Persons/Actions";
import LecturersSettlement from "../Components/LecturersSettlement";

const FORM_NAME = "LECTURER_SETTLEMENT_REPORT";

const LecturersSettlementReport = props => <LecturersSettlement {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    timeline,
    calendar,
    dictionaries,
    coursesList,
    productsList,
    coursesSpecialists,
    mentorsList,
    employees,
    comment,
    timelineStats,
    timelineComments,
    allProductsFullInfo,
  } = state;
  const values = selector(
    state,
    "startDate",
    "endDate",
    "courseId",
    "cityId",
    "productId",
    "courseSpecialistId",
    "mentorId",
    "ended",
    "withRoom",
    "coursesModesId",
    "courseDays",
  );
  const validationErrors = state.form[FORM_NAME].syncErrors;
  return {
    timeline,
    calendar,
    values,
    validationErrors,
    dictionaries,
    coursesList,
    productsList,
    employees,
    comment,
    timelineStats,
    timelineComments,
    coursesSpecialists,
    mentorsList,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCoursesSpecialists,
      fetchMentors,
      fetchLecturersSettlement,
    },
    dispatch,
  );
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LecturersSettlementReport),
);
