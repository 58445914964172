import React, { Component } from "react";
import { Form, Field } from "redux-form";
import { RenderDateTime, RenderSelect } from "../../../Forms/forms";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import PriceFieldWithCalculation from "../../../Forms/PriceFieldWithCalculation";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getInvoiceIssueMonthsForSelect from "../../../Helpers/GetInvoiceIssueMonthsForSelect";
import getPaymentTypeDropdownConfig from "../../../Helpers/GetPaymentTypeDropdownConfig";
import notificationOpts from "../../../Helpers/NotificationOpts";
import moment from "moment";
import SelectInvoiceReceiverWithSearch from "../../../Common/Components/SelectInvoiceReceiverWithSearch/SelectInvoiceReceiverWithSearch";
import { first, isEqual } from "lodash";
import * as HttpStatus from "http-status-codes";
import RenderReceiverSelectOption from "../../../Helpers/RenderReceiverSelectOption";
import AmountField from "../../../Forms/AmountField";

class InvoiceForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    currencies: PropTypes.array,
    priceGrossCalculator: PropTypes.func,
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    change: PropTypes.func,
    createInvoice: PropTypes.func,
    closePopup: PropTypes.func,
    values: PropTypes.object,
    duesSum: PropTypes.object,
    balanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tenantSettings: PropTypes.object,
    fetchTenantSettings: PropTypes.func,
    fetchInvoiceReceivers: PropTypes.func,
    fetchLegalBasis: PropTypes.func,
    clearLegalBasis: PropTypes.func,
    invoiceReceivers: PropTypes.array,
    success: PropTypes.func,
    removeAll: PropTypes.func,
    error: PropTypes.func,
    invoiceAmount: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      isSubmitting: false,
      vatRates: [],
    };

    props.change("balanceId", props.balanceId);
    props.change("hasLegalBasis", false);
    props.change("sellDate", moment().valueOf());
    props.change("issueMonth", moment().format("Y-MM"));
    props.fetchTenantSettings(this.cancelToken);
  }
  componentDidUpdate(prevProps) {
    const {
      tenantSettings,
      values: { vatRate: currentVatRate, issueMonth },
      fetchLegalBasis,
      invoiceLegalBasis: currentLegalBasis,
      change,
    } = this.props;
    const {
      values: { vatRate: prevVatRate },
      invoiceLegalBasis: prevLegalBasis,
    } = prevProps;

    if (
      tenantSettings &&
      _.has(tenantSettings, "vatRates") &&
      !_.isEqual(tenantSettings.vatRates, this.state.vatRates)
    ) {
      this.setState({
        vatRates: tenantSettings.vatRates,
      });
    }

    if (currentVatRate && currentVatRate !== prevVatRate) {
      fetchLegalBasis(this.cancelToken, currentVatRate, issueMonth);
    }

    if (!isEqual(currentLegalBasis, prevLegalBasis)) {
      change("hasLegalBasis", currentLegalBasis.length > 0);
    }
  }

  componentDidMount() {
    this.setInitialAmount(this.props);
  }

  componentWillUnmount() {
    const { clearLegalBasis } = this.props;
    clearLegalBasis();
  }

  getDuesSumInCurrencies = () => {
    const { duesSum } = this.props;
    return Object.keys(duesSum).map(currencyKey => ({
      currency: currencyKey,
      amount: duesSum[currencyKey],
    }));
  };

  setInitialAmount = props => {
    const initAmount = {
      currency: first(props.currencies).name,
      amount: 0,
    };

    let newAmount = initAmount;

    if (props.invoiceAmount) {
      const autoAmount = first(props.invoiceAmount);
      newAmount =
        autoAmount && autoAmount.amount ? autoAmount.amount : initAmount;
    }

    props.change("amountGross", newAmount);
  };

  checkInvoiceAmountIsHigherThenDuesSum = () => {
    let isGross = false;

    const duesSumInCurrencies = this.getDuesSumInCurrencies();
    const {
      values: { amountGross },
    } = this.props;

    if (amountGross) {
      const sumInSelectedCurrency = duesSumInCurrencies.find(
        duesSum => duesSum.currency === amountGross.currency,
      );
      isGross =
        sumInSelectedCurrency &&
        amountGross.amount > sumInSelectedCurrency.amount;
    }

    return isGross;
  };

  onSubmit = ({
    amountGross,
    issueMonth,
    paymentDate,
    sellDate,
    vatRate,
    receiver,
    paymentType,
    legalBasis,
  }) => {
    const {
      balanceId,
      closePopup,
      createInvoice,
      success,
      removeAll,
      error,
      t,
    } = this.props;
    removeAll();
    this.setState(
      {
        isSubmitting: true,
      },
      () => {
        createInvoice(
          {
            amountGross,
            issueMonth,
            paymentDate,
            sellDate,
            vatRate,
            legalBasis,
            balanceId,
            receiver,
            paymentType,
          },
          ({ status }) => {
            if (status === HttpStatus.OK) {
              success(
                notificationOpts({
                  title: t("Your data was saved"),
                }),
              );
              this.setState(
                {
                  isSubmitting: false,
                },
                closePopup,
              );
            }
          },
        ).catch(() => {
          error(
            notificationOpts({
              title: t("Something goes wrong"),
            }),
          );
        });
      },
    );
  };
  setMinPaymentDate = () => {
    const today = moment().format("DD");
    if (Number(today) > 10) {
      return moment().add(1, "day");
    }
    return moment()
      .subtract(1, "month")
      .date(1);
  };
  setReceiver = value => {
    this.props.change("receiver", value);
  };

  handleChangeVatRate = option => {
    const { change } = this.props;

    change("vatRate", option.value);
    change("legalBasis", null);
  };
  render() {
    const showAlert = this.checkInvoiceAmountIsHigherThenDuesSum();

    const {
      t,
      priceGrossCalculator,
      handleSubmit,
      change,
      values: { amountGross, vatRate, hasLegalBasis },
      currencies,
      fetchInvoiceReceivers,
      invoiceReceivers,
      invoiceLegalBasis,
    } = this.props;

    return (
      <Form
        onSubmit={handleSubmit(this.onSubmit)}
        id="add-invoice-form"
        className="invoice-form__container"
      >
        <div className="invoice-form__row">
          <div className="col-sm-12">
            <Field
              required="required"
              under
              name="issueMonth"
              label={t("Issue month")}
              component={RenderSelect}
              dropdownConfig={{
                data: getInvoiceIssueMonthsForSelect(),
                textField: "label",
                valueField: "id",
              }}
            />
          </div>
          <div className="col-sm-12">
            <Field
              under
              required="required"
              component={RenderDateTime}
              label={t("Sell date")}
              name="sellDate"
              time={false}
              max={moment().add(30, "days")}
            />
          </div>
          <div className="col-sm-12">
            <Field
              min={this.setMinPaymentDate()}
              under
              required="required"
              component={RenderDateTime}
              label={t("Payment date")}
              name="paymentDate"
              time={false}
            />
          </div>
        </div>
        <div className="invoice-form__row">
          <div className="col-sm-3" id="paymentTypeSelect">
            <Field
              under
              name="paymentType"
              label={t("Payment method")}
              component={RenderSelect}
              dropdownConfig={getPaymentTypeDropdownConfig(t)}
            />
          </div>
          <div className="col-sm-3" id="payersSelect">
            <Field
              name="receiver"
              under={true}
              label={t("Receiver")}
              labelKey={"name"}
              id={"payer-select-with-additional-data"}
              placeholder={t("Select the receiver")}
              fetchData={fetchInvoiceReceivers}
              receivers={invoiceReceivers}
              component={SelectInvoiceReceiverWithSearch}
              renderMenuItemChildren={RenderReceiverSelectOption}
              onChangeHandler={this.setReceiver}
            />
          </div>
          <div className="col-sm-3" id="vatRate">
            <Field
              required="required"
              under
              name="vatRate"
              label={t("Tax rate")}
              component={RenderSelect}
              dropdownConfig={{
                data: this.state.vatRates.map(vatRate => {
                  return {
                    label: vatRate,
                    value: vatRate,
                  };
                }),
                textField: "label",
                valueField: "value",
                onChange: this.handleChangeVatRate,
              }}
            />
          </div>
          <div className="col-sm-3" id="vatRate">
            <Field
              required="required"
              under
              name="legalBasis"
              label={t("Legal basis")}
              disabled={!hasLegalBasis}
              component={RenderSelect}
              dropdownConfig={{
                data: invoiceLegalBasis,
                textField: "name",
                valueField: "id",
              }}
            />
          </div>
        </div>
        <div className="invoice-form__row">
          <div className="col-xs-3" style={{ paddingLeft: 0 }}>
            <AmountField
              under
              required="required"
              component={PriceFieldWithCalculation}
              label={t("Gross amount")}
              t={t}
              name="amountGross.amount"
              currency={currencies[0].name}
              isNetValue={false}
              change={change}
              calculatedName="amountNettThirdParty"
              priceCalculator={(amount, callback) =>
                priceGrossCalculator({ ...amount, vatRate }, callback)
              }
            />
            {showAlert && (
              <p className="balance--negative">
                {t("The invoice amount is higher then dues' sum")}
              </p>
            )}
          </div>
          <div className="col-xs-3" style={{ alignSelf: "flex-end" }}>
            <Field
              required="required"
              under
              name="amountGross.currency"
              component={RenderSelect}
              dropdownConfig={{
                data: currencies || [],
                placeholder: t("Currency"),
                textField: "name",
                valueField: "name",
              }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }}>
          <button
            type="button"
            onClick={handleSubmit(this.onSubmit)}
            id="add-invoice-submit-btn"
            className="btn btn-dark pull-right"
            disabled={
              !amountGross ||
              !amountGross.amount ||
              !amountGross.currency ||
              this.state.isSubmitting
            }
          >
            {t("Save")}
          </button>
        </div>
      </Form>
    );
  }
}

export default translate()(InvoiceForm);
