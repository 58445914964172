import React from "react";
import SettingsForm from "../Containers/SettingsForm";
import CourseCalendar from "../Containers/CourseCalendar";
import MainTemplateContent from "../../App/Containers/MainTemplateContent";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import { translate } from "react-i18next";
import moment from "moment";

type CourseSettingsProps = {
  courseId: number;
  courseSignature: string;
  courseCancelDate: number;
  t: (key: string) => string;
  isLoading: boolean;
};

const CourseSettings: React.FunctionComponent<CourseSettingsProps> = ({
  courseId,
  courseSignature,
  courseCancelDate,
  t,
  isLoading,
}) => {
  const secondaryMenu = [
    {
      name: t("Settings"),
      link: `/courses/${courseId}/settings`,
      show: havePermissions([permissions.COURSE_DETAILS_VIEW]),
    },
    {
      name: t("Students"),
      link: `/courses/${courseId}/students`,
      show: havePermissions([permissions.COURSE_STUDENT_LIST]),
    },
    {
      name: t("Surveys"),
      link: `/courses/${courseId}/polls`,
      position: "right",
      show: true,
    },
  ];

  const cancelLabel = courseCancelDate
    ? `${t("Cancelled course")} (${moment(courseCancelDate).format(
        "DD.MM.YYYY",
      )})`
    : "";

  return (
    <MainTemplateContent
      title={!courseId ? t("New course") : isLoading ? "" : courseSignature}
      closeDate={cancelLabel}
      background={true}
      secondaryMenu={!!courseId && secondaryMenu.filter(item => item.show)}
    >
      <SettingsForm courseId={courseId} />

      {!!courseId && (
        <CourseCalendar courseId={courseId} courseSignature={courseSignature} />
      )}
    </MainTemplateContent>
  );
};

export default translate()(CourseSettings);
