import React from "react";
import PropTypes from "prop-types";
import { Edit } from "react-feather";

const EditButton = ({ onClick, text, editable, component }) =>
  editable ? (
    <button className="btn btn--edit" onClick={onClick}>
      <Edit size={15} /> {text}{" "}
      {component ? (
        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
          {component}
        </div>
      ) : null}
    </button>
  ) : (
    <span className="btn--edit">
      {component ? (
        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
          {component}
        </div>
      ) : (
        text || null
      )}
    </span>
  );

EditButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool,
  component: PropTypes.element,
};

export default EditButton;
