import React from "react";
import PropTypes from "prop-types";

const NestedVersionElement = props => {
  return (
    <div>
      <p>Wersja {props.version}</p>
      <p className="nestedList__elementHeader__info__status">
        <span
          className={
            props.status && props.status.code === "inactive"
              ? "unactive"
              : "active"
          }
        />
        {props.status && props.status.code === "inactive"
          ? "Nieaktywna"
          : "Aktywna"}{" "}
        - {props.courses} kursów
      </p>
    </div>
  );
};

NestedVersionElement.propTypes = {
  version: PropTypes.string,
  status: PropTypes.string,
  courses: PropTypes.number,
};

export default NestedVersionElement;
