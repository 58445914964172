import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import ProductFormHistoryItem from "./ProductFormHistoryItem.js";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import _ from "lodash";

class ProductFormHistory extends Component {
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      showRemoveConfirmation: false,
      productVersionIdToDelete: null,
      isLoading: false,
      currentProductVersions: [],
    };
  }

  componentDidUpdate() {
    const { currentProductVersions } = this.props;
    if (!_.isEqual(currentProductVersions, this.state.currentProductVersions)) {
      this.setState({
        currentProductVersions,
      });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  reloadHistory = () => {
    const { currentProductVersion, fetchVersionsOfProduct } = this.props;
    if (currentProductVersion) {
      this.setState({ isLoading: true }, () => {
        fetchVersionsOfProduct();
      });
    }
  };

  async deactiveAllProductVersions(
    currentProductVersions,
    changeProductVersionStatus,
  ) {
    await currentProductVersions.forEach(productVersion => {
      if (productVersion.status.id === PRODUCT_STATUSES.ACTIVE) {
        changeProductVersionStatus(
          productVersion.id,
          PRODUCT_STATUSES.INACTIVE,
        );
      }
    });
  }

  inactiveProduct = () => {
    const {
      history,
      currentProductVersions,
      changeProductVersionStatus,
      productVersionId,
      fetchVersionsOfProduct,
    } = this.props;
    this.deactiveAllProductVersions(
      currentProductVersions,
      changeProductVersionStatus,
    ).then(() => {
      fetchVersionsOfProduct(() => {
        history.push(`/products/`);
        history.push(`/products/product/${productVersionId}`);
      });
    });
  };

  renderActiveButton() {
    const versions = this.props.currentProductVersions;
    let isActiveVersion = false;
    if (versions.length > 0) {
      for (let i = 0; i < versions.length; i++) {
        if (versions[i].status.id === PRODUCT_STATUSES.ACTIVE) {
          isActiveVersion = true;
          break;
        }
      }
    }
    return isActiveVersion ? (
      <Authorize
        component={
          <button
            className={`btn btn-regular btn-link btn-with-icon`}
            type="button"
            onClick={this.inactiveProduct}
          >
            <i className="glyphicon glyphicon-off" />
            <span>{this.props.t("Deactivate product")}</span>
          </button>
        }
        allows={[permissions.PRODUCT_DEACTIVATION]}
      />
    ) : null;
  }

  onRemove(productId) {
    const { deleteProductVersion, currentProductVersions } = this.props;
    const versionIdUrl = this.props.match.params.versionId;
    const versionsAmount = currentProductVersions.length - 1;

    deleteProductVersion(productId, () => {
      if (currentProductVersions.length <= 1) {
        this.props.history.push(`/products/`);
      } else if (versionIdUrl == productId) {
        const destinationId =
          currentProductVersions[versionsAmount].id == versionIdUrl
            ? currentProductVersions[versionsAmount - 1].id
            : currentProductVersions[versionsAmount].id;
        this.props.history.push(`/products/product/${destinationId}`);
      }
      this.reloadHistory();
    });
  }

  passValues = productVersionIdToDelete => {
    this.setState({
      showRemoveConfirmation: true,
      productVersionIdToDelete,
    });
  };

  render() {
    const {
      cantEdit,
      shouldRender,
      employeeId,
      currentProductVersion,
      lastVersionOfProduct,
      saveProductVersion,
    } = this.props;
    const propsForItem = {
      employeeId,
      currentProductVersion,
      lastVersionOfProduct,
      saveProductVersion,
    };
    const { currentProductVersions } = this.state;
    return (
      shouldRender && (
        <div className="product-history">
          <ConfirmModal
            isOpen={this.state.showRemoveConfirmation}
            title={this.props.t(
              "Are you sure you want to remove this version?",
            )}
            onRequestClose={() =>
              this.setState({ showRemoveConfirmation: false })
            }
            config={{
              body: (
                <p>
                  {this.props.t(
                    "By removing this version, you will not be able to restore it",
                  )}
                </p>
              ),
              onRequestConfirm: () => {
                this.onRemove(this.state.productVersionIdToDelete);
              },
            }}
          />
          <header>
            <h4>{this.props.t("Product version history")}</h4>
            {!cantEdit && this.renderActiveButton()}
          </header>
          <div
            className={`product-history__list${
              this.state.isLoading ? " product-history__list--disabled" : ""
            }`}
          >
            {currentProductVersions.map(item => {
              return (
                <ProductFormHistoryItem
                  key={item.id}
                  version={item}
                  reloadHistory={this.reloadHistory}
                  passValues={this.passValues}
                  shouldDisabled={cantEdit}
                  employees={this.props.employees}
                  makeProductCopy={this.props.makeProductCopy}
                  {...propsForItem}
                />
              );
            })}
          </div>
        </div>
      )
    );
  }
}

export default translate()(withRouter(ProductFormHistory));

ProductFormHistory.propTypes = {
  currentProductVersion: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  changeProductVersionStatus: PropTypes.func,
  currentProductVersionActive: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  deleteProductVersion: PropTypes.func,
  match: PropTypes.object,
  cantEdit: PropTypes.bool,
  shouldRender: PropTypes.bool,
  index: PropTypes.number,
  currentProductVersions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  employeeId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  saveProductVersion: PropTypes.func,
  employees: PropTypes.array,
  lastVersionOfProduct: PropTypes.number,
  makeProductCopy: PropTypes.func,
  productVersionId: PropTypes.number,
  fetchVersionsOfProduct: PropTypes.func,
};
