export default {
  FETCH_ROOMS: "FETCH_ROOMS",
  FETCH_PRODUCTS_KINDS: "FETCH_PRODUCTS_KINDS",
  FETCH_COURSES_MODES: "FETCH_COURSES_MODES",
  FETCH_EMPLOYEE_AVAILABILITY: "FETCH_EMPLOYEE_AVAILABILITY",
  FETCH_LANGUAGES: "FETCH_LANGUAGES",
  UPDATE_DICTIONARY: "UPDATE_DICTIONARY",
  GET_JWT_SERVER: "GET_JWT_SERVER",
  GET_JWT_SERVER_PASS: "GET_JWT_SERVER_PASS",
  GET_JWT_LOCAL: "GET_JWT_LOCAL",
  FETCH_REFRESH_TOKEN: "FETCH_REFRESH_TOKEN",
  GET_PERMISSIONS_LIST: "GET_PERMISSIONS_LIST",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",
  GET_ROLES_LIST: "GET_ROLES_LIST",
  FETCH_EMPLOYEES: "FETCH_EMPLOYEES",
  FETCH_INSTALLMENTS: "FETCH_INSTALLMENTS",
  FETCH_CURRENCIES: "FETCH_CURRENCIES",
  SAVE_PRODUCT_VERSION: "SAVE_PRODUCT_VERSION",
  FETCH_PRODUCT: "FETCH_PRODUCT",
  FETCH_PRODUCT_PRICES: "FETCH_PRODUCT_PRICES",
  UPDATE_PRODUCT_VERSION: "UPDATE_PRODUCT_VERSION",
  INACTIVE_PRODUCT_VERSION: "INACTIVE_PRODUCT_VERSION",
  ACTIVE_PRODUCT_VERSION: "ACTIVE_PRODUCT_VERSION",
  DELETE_PRODUCT_VERSION: "DELETE_PRODUCT_VERSION",
  FETCH_PRODUCT_VERSIONS: "FETCH_PRODUCT_VERSIONS",
  FETCH_ALL_PRODUCTS: "FETCH_ALL_PRODUCTS",
  FETCH_ALL_ACTIVE_PRODUCTS: "FETCH_ALL_ACTIVE_PRODUCTS",
  COPY_PRODUCT: "COPY_PRODUCT",
  RESET_PRODUCT: "RESET_PRODUCT",
  FETCH_LECTURES_FOR_IMPORT: "FETCH_LECTURES_FOR_IMPORT",
  FETCH_PERSONS: "FETCH_PERSONS",
  FETCH_PERSON: "FETCH_PERSON",
  RESET_PERSON: "RESET_PERSON",
  FETCH_PERSON_TYPES: "FETCH_PERSON_TYPES",
  GET_PERSON_PERMISSIONS: "GET_PERSON_PERMISSIONS",
  ADD_ROLES_TO_USER: "ADD_ROLES_TO_USER",
  CHANGE_PASSWORD: "change_password",
  FETCH_EMPLOYEE_COMPETENCIES: "FETCH_EMPLOYEE_COMPETENCIES",
  FETCH_CITIES: "FETCH_CITIES",
  RESET_CITIES: "RESET_CITIES",
  FETCH_CONTRACT_TYPES: "FETCH_CONTRACT_TYPES",
  SAVE_PERSON: "SAVE_PERSON",
  UPDATE_PERSON: "UPDATE_PERSON",
  GET_MENTOR_FOR_PRODUCT: "GET_MENTOR_FOR_PRODUCT",
  GET_LECTURERS_FOR_PRODUCT_VERSION: "GET_LECTURERS_FOR_PRODUCT_VERSION",
  GET_COURSES_SPECIALISTS: "GET_COURSES_SPECIALISTS",
  SAVE_NEW_COURSE: "SAVE_NEW_COURSE",
  ADD_STUDENT_TO_COURSE: "ADD_STUDENT_TO_COURSE",
  GET_STUDENTS_FOR_COURSE: "GET_STUDENTS_FOR_COURSE",
  SET_COURSE_STUDENTS_SORT_OPTION: "SET_COURSE_STUDENTS_SORT_OPTION",
  DATES_COURSE_SCHEDULE: "DATES_COURSE_SCHEDULE",
  GET_COURSE: "GET_COURSE",
  CANCEL_COURSE: "CANCEL_COURSE",
  EDIT_COURSE: "EDIT_COURSE",
  RESET_COURSE: "RESET_COURSE",
  FETCH_COUNTRIES: "FETCH_COUNTRIES",
  FETCH_CONTRACT_FINISH_REASONS: "FETCH_CONTRACT_FINISH_REASONS",
  FETCH_CONTRACT_FUNDING_TYPES: "FETCH_CONTRACT_FUNDING_TYPES",
  FETCH_CONTRACT_STATUS: "FETCH_CONTRACT_STATUS",
  FETCH_STUDENT_COURSE: "FETCH_STUDENT_COURSE",
  FETCH_CONTRACT: "FETCH_CONTRACT",
  FETCH_STUDENT_CONTRACTS: "FETCH_STUDENT_CONTRACTS",
  SAVE_CONTRACT: "SAVE_CONTRACT",
  SAVE_STATE_TRUE: "SAVE_STATE_TRUE",
  SAVE_STATE_FALSE: "SAVE_STATE_FALSE",
  FETCH_COURSES: "FETCH_COURSES",
  FETCH_COURSE_STATUSES: "FETCH_COURSE_STATUSES",
  MODAL_SET: "MODAL_SET",
  MODAL_RESET: "MODAL_RESET",
  FETCH_PRODUCT_MODULES: "FETCH_PRODUCT_MODULES",
  CLEAR_PRODUCT_MODULES: "CLEAR_PRODUCT_MODULES",
  FETCH_PRODUCT_MODULES_SIMPLE: "FETCH_PRODUCT_MODULES_SIMPLE",
  FETCH_LOG_EVENT_GROUP: "FETCH_LOG_EVENT_GROUP",
  FETCH_COURSES_LOGS: "FETCH_COURSES_LOGS",
  PASS_ERROR_TO_STORE: "PASS_ERROR_TO_STORE",
  SEARCH_COURSE: "SEARCH_COURSE",
  FETCH_PRODUCT_WITHDRAW_MODULES: "FETCH_PRODUCT_WITHDRAW_MODULES",
  GET_LECTURERS_ROOMS_FOR_COURSE: "GET_LECTURERS_ROOMS_FOR_COURSE",
  CLEAR_ERROR_FROM_STORE: "CLEAR_ERROR_FROM_STORE",
  FETCH_PRODUCTS_FOR_COURSES: "FETCH_PRODUCTS_FOR_COURSES",
  DOWNLOAD_TIMELINE_DATA: "DOWNLOAD_TIMELINE_DATA",
  FUNDING_CALCULATE: "FUNDING_CALCULATE",
  DOWNLOAD_DIPLOMA: "DOWNLOAD_DIPLOMA",
  DOWNLOAD_REPORT_DATA: "DOWNLOAD_REPORT_DATA",
  FETCH_LECTURES_COMPETENCIES: "FETCH_LECTURES_COMPETENCIES",
  FETCH_QUESTIONNAIRE: "FETCH_QUESTIONNAIRE",
  CLEAR_CONTRACT: "CLEAR_CONTRACT",
  SAVE_QUESTIONNAIRE: "SAVE_QUESTIONNAIRE",
  CLEAR_QUESTIONNAIRE: "CLEAR_QUESTIONNAIRE",
  GET_LECTURE_DATA: "GET_LECTURE_DATA",
  CREATE_INVOICE: "CREATE_INVOICE",
  DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",
  CREATE_NEW_ROOM: "CREATE_NEW_ROOM",
  FETCH_ERROR_CODES: "FETCH_ERROR_CODES",
  SEND_ERROR_LOG: "SEND_ERROR_LOG",
  DOWNLOAD_EXTENDED_REPORT_DATA: "DOWNLOAD_EXTENDED_REPORT_DATA",
  I18N_SET_LANGUAGE: "I18N_SET_LANGUAGE",
  CREATE_EMPLOYEE_COMPETENCY: "CREATE_EMPLOYEE_COMPETENCY",
  ADD_STUDENT_ADDRESS_IN_CONTRACT: "ADD_STUDENT_ADDRESS_IN_CONTRACT",
  FETCH_REPORT: "FETCH_REPORT",
  UPDATE_FINISHED_CONTRACT: "UPDATE_FINISHED_CONTRACT",
  FETCH_QUESTIONNAIRES: "FETCH_QUESTIONNAIRES",
  UPDATE_QUESTIONNAIRES: "UPDATE_QUESTIONNAIRES",
  MODULE_PRICING: "MODULE_PRICING",
  FETCH_CONTRACT_PACKAGES_TRANSITIONS: "FETCH_CONTRACT_PACKAGES_TRANSITIONS",
  FETCH_CONTRACT_PACKAGES: "FETCH_CONTRACT_PACKAGES",
  FETCH_TIMELINE: "FETCH_TIMELINE",
  FETCH_TIMELINE_STATS: "FETCH_TIMELINE_STATS",
  FETCH_TIMELINE_COMMENTS: "FETCH_TIMELINE_COMMENTS",
  CALENDAR_NEXT_MONTH: "CALENDAR_NEXT_MONTH",
  CALENDAR_PREV_MONTH: "CALENDAR_PREV_MONTH",
  FETCH_CITIES_ONLY_TEACH: "FETCH_CITIES_ONLY_TEACH",
  FETCH_INITIAL_TEST_RESULT: "FETCH_INITIAL_TEST_RESULT",
  FETCH_GROUP_FILL_SORT_TYPES: "FETCH_GROUP_FILL_SORT_TYPES",
  FETCH_GROUP_FILL_DATA: "FETCH_GROUP_FILL_DATA",
  DOWNLOAD_INVOICES_REPORT_DATA: "DOWNLOAD_INVOICES_REPORT_DATA",
  DOWNLOAD_INVOICES_PACKAGE: "DOWNLOAD_INVOICES_PACKAGE",
  DOWNLOAD_GROUP_FILL_REPORT: "DOWNLOAD_GROUP_FILL_REPORT",
  LECTURERS_SETTLEMENTS: "DOWNLOAD_LECTURERS_SETTLEMENTS",
  DOWNLOAD_SCHEDULE: "DOWNLOAD_SCHEDULE",
  FETCH_COMMENT: "FETCH_COMMENT",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  FETCH_MENTORS: "FETCH_MENTORS",
  FETCH_PAYMENTS: "FETCH_PAYMENTS",
  FETCH_CONTRACT_AMOUNTS: "FETCH_CONTRACT_AMOUNTS",
  FETCH_INCOMES: "FETCH_INCOMES",
  FETCH_POLLS: "FETCH_POLLS",
  FETCH_MODULE_POLLS: "FETCH_MODULE_POLLS",
  FETCH_COURSE_POLLS: "FETCH_COURSE_POLLS",
  FETCH_EVENT_POLLS: "FETCH_EVENT_POLLS",
  SAVE_POLL: "SAVE_POLL",
  UPDATE_POLL: "UPDATE_POLL",
  FETCH_POLL_EVENTS: "FETCH_POLL_EVENTS",
  CREATE_POLL_PACKAGE: "CREATE_POLL_PACKAGE",
  FETCH_CONTRACT_POLLS: "FETCH_CONTRACT_POLLS",
  FETCH_CONTRACT_MODULES_POLLS: "FETCH_CONTRACT_MODULES_POLLS",
  FETCH_CONTRACT_SLAVE_POLLS: "FETCH_CONTRACT_SLAVE_POLLS",
  FETCH_CONTRACT_MANUAL_POLLS: "FETCH_CONTRACT_MANUAL_POLLS",
  FETCH_POLL_TYPES: "FETCH_POLL_TYPES",
  CREATE_POLL_TYPE: "CREATE_POLL_TYPE",
  FETCH_REPORT_GRADUATES: "FETCH_REPORT_GRADUATES",
  DOWNLOAD_REPORT_GRADUATES: "DOWNLOAD_REPORT_GRADUATES",
  SEND_POLL: "SEND_POLL",
  PRICE_CALCULATOR: "PRICE_CALCULATOR",
  FETCH_INVOICES: "FETCH_INVOICES",
  UPDATE_PAYMENTS: "UPDATE_PAYMENTS",
  ADD_MANUAL_INCOME: "ADD_MANUAL_INCOME",
  ADD_REFUND: "ADD_REFUND",
  FETCH_CURRENT_POLL_RESULTS: "FETCH_CURRENT_POLL_RESULTS",
  DOWNLOAD_CURRENT_POLL_RESULTS: "DOWNLOAD_CURRENT_POLL_RESULTS",
  DOWNLOAD_COURSE_STUDENTS: "DOWNLOAD_COURSE_STUDENTS",
  DOWNLOAD_COURSE_DIPLOMAS: "DOWNLOAD_COURSE_DIPLOMAS",
  DOWNLOAD_CASH_FLOW_REPORT: "DOWNLOAD_CASH_FLOW_REPORT",
  DOWNLOAD_COURSE_DAYS_GROUPS_REPORT: "DOWNLOAD_COURSE_DAYS_GROUPS_REPORT",
  DOWNLOAD_PAYU_ORDER_REPORT: "DOWNLOAD_PAYU_ORDER_REPORT",
  DOWNLOAD_CONTRACTS_REPORT: "DOWNLOAD_CONTRACTS_REPORT",
  DOWNLOAD_MONTHLY_CONTRACTS_REPORT: "DOWNLOAD_MONTHLY_CONTRACTS_REPORT",
  DOWNLOAD_RESIGNED_CONTRACTS_REPORT: "DOWNLOAD_RESIGNED_CONTRACTS_REPORT",
  DOWNLOAD_AMENDMENT_CONTRACTS_REPORT: "DOWNLOAD_AMENDMENT_CONTRACTS_REPORT",
  FETCH_COURSE_POLL_RESULTS_GENERAL: "FETCH_COURSE_POLL_RESULTS_GENERAL",
  FETCH_COURSE_POLL_RESULTS_DETAILED: "FETCH_COURSE_POLL_RESULTS_DETAILED",
  GET_COURSE_STATISTICS: "GET_COURSE_STATISTICS",
  FETCH_ALL_PRODUCTS_FULL_INFO: "FETCH_ALL_PRODUCTS_FULL_INFO",
  MAINTENANCE_MODE_ON: "MAINTENANCE_MODE_ON",
  FETCH_BANK_INCOMES: "FETCH_BANK_INCOMES",
  DOWNLOAD_BANK_INCOMES: "DOWNLOAD_BANK_INCOMES",
  MAKE_INCOME_TRANSFER: "MAKE_INCOME_TRANSFER",
  UPDATE_INCOME: "UPDATE_INCOME",
  FETCH_BANK_INCOMES_TRANSFERS: "FETCH_BANK_INCOMES_TRANSFERS",
  DOWNLOAD_BANK_INCOMES_TRANSFERS: "DOWNLOAD_BANK_INCOMES_TRANSFERS",
  FETCH_REFUNDS_REPORT: "FETCH_REFUNDS_REPORT",
  DOWNLOAD_REFUNDS_REPORT: "DOWNLOAD_REFUNDS_REPORT",
  UPDATE_REFUND: "UPDATE_REFUND",
  FETCH_INVOICES_REPORT: "FETCH_INVOICES_REPORT",
  FETCH_STUDENT_BALANCE: "FETCH_STUDENT_BALANCE",
  FETCH_CONTRACT_PAYMENTS_REPORT: "FETCH_CONTRACT_PAYMENTS_REPORT",
  FETCH_CONTRACT_PAYMENTS_NOTE: "FETCH_CONTRACT_PAYMENTS_NOTE",
  ADD_CONTRACT_PAYMENTS_NOTE: "ADD_CONTRACT_PAYMENTS_NOTE",
  REMOVE_CONTRACT_PAYMENTS_NOTE: "REMOVE_CONTRACT_PAYMENTS_NOTE",
  EDIT_CONTRACT_PAYMENTS_NOTE: "EDIT_CONTRACT_PAYMENTS_NOTE",
  DOWNLOAD_CONTRACT_PAYMENTS_REPORT: "DOWNLOAD_CONTRACT_PAYMENTS_REPORT",
  FETCH_BALANCES_REPORT: "FETCH_BALANCES_REPORT",
  DOWNLOAD_BALANCES_REPORT: "DOWNLOAD_BALANCES_REPORT",
  FETCH_BALANCE: "FETCH_BALANCE",
  FETCH_INVOICE_AMOUNT: "FETCH_INVOICE_AMOUNT",
  FETCH_BALANCE_SETTINGS: "FETCH_BALANCE_SETTINGS",
  SAVE_BALANCE_SETTINGS: "SAVE_BALANCE_SETTINGS",
  DELETE_MANUAL_INCOME: "DELETE_MANUAL_INCOME",
  SAVE_INVOICE: "SAVE_INVOICE",
  FETCH_TENANT_SETTINGS: "FETCH_TENANT_SETTINGS",
  FETCH_INVOICE_RECEIVERS: "FETCH_INVOICE_RECEIVERS",
  PAYMENT_ACTIVATE: "PAYMENT_ACTIVATE",
  PAYMENT_CANCEL: "PAYMENT_CANCEL",
  FETCH_CONTRACT_META: "FETCH_CONTRACT_META",
  UPDATE_INVOICE_CORRECTION: "UPDATE_INVOICE_CORRECTION",
  REMOVE_INVOICE_CORRECTION: "REMOVE_INVOICE_CORRECTION",
  ADD_INVOICE_CORRECTION: "ADD_INVOICE_CORRECTION",
  UPDATE_INVOICE_BUYER_ADDRESS: "UPDATE_INVOICE_BUYER_ADDRESS",
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  CLEAR_PRODUCTS_LIST: "CLEAR_PRODUCTS_LIST",
  FETCH_LECTURES_TYPES: "FETCH_LECTURES_TYPES",
  PAYER_CALC: "PAYER_CALC",
  FETCH_CONTRACTS: "FETCH_CONTRACTS",
  FETCH_PRODUCT_VERSIONS_META: "FETCH_PRODUCT_VERSIONS_META",
  CREATE_PRODUCT_MODULES: "CREATE_PRODUCT_MODULES",
  CREATE_PRODUCT_MODULES_LECTURES: "CREATE_PRODUCT_MODULES_LECTURES",
  UPDATE_PRODUCT_MODULES: "UPDATE_PRODUCT_MODULES",
  DELETE_PRODUCT_MODULE: "DELETE_PRODUCT_MODULE",
  FETCH_PRODUCT_POLLS: "FETCH_PRODUCT_POLLS",
  SAVE_SLAVE: "SAVE_SLAVE",
  REMOVE_SLAVE: "REMOVE_SLAVE",
  CREATE_LECTURE_TYPES: "CREATE_LECTURE_TYPES",
  UPDATE_LECTURE_TYPES: "UPDATE_LECTURE_TYPES",
  MODULES_ORDER: "MODULES_ORDER",
  FETCH_PRODUCT_MODULES_FOR_SLAVE: "FETCH_PRODUCT_MODULES_FOR_SLAVE",
  DELETE_INVOICE: "DELETE_INVOICE",
  CREATE_ORDER: "CREATE_ORDER",
  FETCH_PRODUCT_EVENTS: "FETCH_PRODUCT_EVENTS",
  ADD_PRODUCT_EVENTS: "ADD_PRODUCT_EVENTS",
  DELETE_PRODUCT_EVENTS: "DELETE_PRODUCT_EVENTS",
  ADD_COURSE_EVENTS: "ADD_COURSE_EVENTS",
  DELETE_COURSE_ADDITIONAL_EVENT: "DELETE_COURSE_ADDITIONAL_EVENT",
  GET_CONTRACT_DOC: "GET_CONTRACT_DOC",
  GET_OFFICES: "GET_OFFICES",
  FETCH_CONTRACT_PAYMENTS_FILTERS: "FETCH_CONTRACT_PAYMENTS_FILTERS",
  FETCH_USER_ENGAGE_LEAD_TYPE: "FETCH_USER_ENGAGE_LEAD_TYPE",
  CREATE_USER_ENGAGE_LEAD_TYPE: "CREATE_USER_ENGAGE_LEAD_TYPE",
  UPDATE_USER_ENGAGE_LEAD_TYPE: "UPDATE_USER_ENGAGE_LEAD_TYPE",
  DELETE_USER_ENGAGE_LEAD_TYPE: "DELETE_USER_ENGAGE_LEAD_TYPE",
  FETCH_DICT_COMMENTS: "FETCH_DICT_COMMENTS",
  UPDATE_DICT_COMMENT: "UPDATE_DICT_COMMENT",
  ADD_DICT_COMMENT: "ADD_DICT_COMMENT",
  UPDATE_INCOME_AUTOINVOICE: "UPDATE_INCOME_AUTOINVOICE",
  UPDATE_INCOME_COMMENT: "UPDATE_INCOME_COMMENT",
  FETCH_DICT_PROMOTIONS: "FETCH_DICT_PROMOTIONS",
  UPDATE_DICT_PROMOTION: "UPDATE_DICT_PROMOTION",
  ADD_DICT_PROMOTION: "ADD_DICT_PROMOTION",
  GET_LEGAL_BASIS: "GET_LEGAL_BASIS",
  CLEAR_LEGAL_BASIS: "CLEAR_LEGAL_BASIS",
  UPDATE_INVOICE_STATUS: "UPDATE_INVOICE_STATUS",
  GET_INVOICE_STATUS_POSSIBLE_PLACES: "GET_INVOICE_STATUS_POSSIBLE_PLACES",
  GET_INVOICE_SEND_STATUS_POSSIBLE_PLACES:
    "GET_INVOICE_SEND_STATUS_POSSIBLE_PLACES",
};
