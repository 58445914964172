import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Filters from "../../Containers/Filters";
import Sorts from "./Sorts";
import ContractsList from "./ContractsList";
import GetCancelToken from "../../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import LoaderProvider from "../../../../Common/Components/Loader/LoaderProvider";

const PAGE_AMOUNT = 10;
const START_PAGE = 1;

class Contracts extends Component {
  cancelToken = GetCancelToken();

  state = {
    pageSize: PAGE_AMOUNT,
    currentPage: START_PAGE,
    search: "",
    sort: null,
    filters: null,
  };

  componentDidMount() {
    this.loadContracts();
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  loadContracts = (
    pageNo = START_PAGE,
    pageAmount = PAGE_AMOUNT,
    filters = null,
  ) => {
    const { fetchContracts } = this.props;
    let params = { pageNo, pageAmount };

    const { search, sort } = this.state;

    if (search && search.length > 0) {
      params.search = search;
    }

    if (!!filters) {
      params = {
        ...params,
        ...filters,
      };
    }

    if (sort) {
      params.sortBy = sort.sortBy;
      params.orderBy = sort.orderBy;
    }

    fetchContracts(this.cancelToken, params, null, true);
  };

  onSearch = () => {
    const { pageSize, filters } = this.state;
    this.loadContracts(START_PAGE, pageSize, filters);
  };

  searchContracts = _.debounce(this.onSearch, 500);

  onChangeSearch = ({ target }) =>
    this.setState({ search: target.value }, this.searchContracts);

  onChangeFilter = filters => {
    this.setState({ filters, currentPage: START_PAGE });
    this.loadContracts(START_PAGE, PAGE_AMOUNT, filters);
  };

  onChangePage = currentPage => {
    const { pageSize, filters } = this.state;
    this.setState({ currentPage }, () =>
      this.loadContracts(currentPage, pageSize, filters),
    );
  };

  onChangeSort = sortBy => {
    const { pageSize, currentPage, filters } = this.state;
    this.setState({ sort: sortBy }, () =>
      this.loadContracts(currentPage, pageSize, filters),
    );
  };

  render() {
    const { t, forgetUser, counter, contracts, isLoading } = this.props;
    const { search, sort, pageSize, currentPage } = this.state;

    return (
      <div className={`custom-container`}>
        <Header
          onChangeSearch={this.onChangeSearch}
          searchValue={search}
          logout={forgetUser}
        />

        <Sorts value={sort} onSortChange={this.onChangeSort} />

        <Filters onFilterChange={this.onChangeFilter} />

        <LoaderProvider isLoading={isLoading}>
          <div className={`content clear-both`}>
            <h1>{t("List of contracts")}</h1>

            <ContractsList
              translate={t}
              contracts={contracts}
              counter={counter}
              onChangePage={this.onChangePage}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </div>
        </LoaderProvider>
      </div>
    );
  }
}

Contracts.propTypes = {
  t: PropTypes.func,
  isLoading: PropTypes.bool,
  contracts: PropTypes.array,
  counter: PropTypes.number,
  forgetUser: PropTypes.func,
  fetchContracts: PropTypes.func,
};

export default translate()(Contracts);
