import ACTION_TYPES from "../../Enums/ActionTypes";
import PAYMENT_SIDE from "../../Enums/PaymentSide";

export function fetchContract(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT:
      return { ...action.payload.data.data };
    case ACTION_TYPES.CLEAR_CONTRACT:
      return {};
    case ACTION_TYPES.SAVE_CONTRACT:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function fetchStudentContracts(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_STUDENT_CONTRACTS:
      return { ...action.payload.data.data };
  }
  return state;
}

export function fetchContractLogs(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_COURSES_LOGS:
      return [...action.payload.data.data];
  }
  return state;
}

export function fetchQuestionnaire(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_QUESTIONNAIRE:
      return [...action.payload.data.data];
    case ACTION_TYPES.CLEAR_QUESTIONNAIRE:
      return [];
  }
  return state;
}

export function fetchInitialTestResult(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INITIAL_TEST_RESULT:
      return [...action.payload.data.data];
  }
  return state;
}

export function fundingCalculate(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FUNDING_CALCULATE:
      return { ...action.payload.data.data };
    case ACTION_TYPES.FETCH_PAYMENTS:
      return { ...action.payload.data.data };
    case ACTION_TYPES.UPDATE_PAYMENTS:
      return { ...action.payload.data.data };
    case ACTION_TYPES.CLEAR_CONTRACT:
      return {};
  }
  return state;
}

export function fetchIncomesThirdParty(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INCOMES:
      return [
        ...action.payload.data.data.filter(
          income => income.side === PAYMENT_SIDE.THIRD_PARTY,
        ),
      ];
  }
  return state;
}

export function contractPackagesTransitions(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_PACKAGES_TRANSITIONS:
      return [...action.payload.data.data];
  }
  return state;
}

export function contractPackages(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_PACKAGES:
      return [...action.payload.data.data];
  }
  return state;
}

export function contractMeta(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_META:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function prices(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_PRICES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function fetchContractAmounts(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_AMOUNTS:
      return { ...action.payload.data.data };

    default:
      return state;
  }
}

const initState = {
  contract: {},
  prices: [],
  contractAmounts: null,
};

export function __metadataReducer(state = initState, action) {
  return {
    contract: contractMeta(state.contract, action),
    prices: prices(state.prices, action),
    contractAmounts: fetchContractAmounts(state.contractAmounts, action),
  };
}
