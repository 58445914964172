import COMPANIES_ACTION_TYPES from "../Actions/ActionTypes";
import { companiesInitState } from "./Companies";

export const companyDetails = (
  state = companiesInitState.companyDetails,
  action,
) => {
  switch (action.type) {
    case COMPANIES_ACTION_TYPES.FETCH_COMPANY_DETAILS:
      return { ...action.payload.data.data };

    case COMPANIES_ACTION_TYPES.REFRESH_COMPANY_DETAILS:
      return { ...action.payload };

    default:
      return state;
  }
};
