import React from "react";
import ProductItem from "./ProductItem.js";
import Loader from "../../../Common/Components/Loader/Loader.js";
import PropTypes from "prop-types";

const ProductsList = ({
  products,
  copyProduct,
  shouldRender,
  itemPermissions: { canRedirectToProduct, canCopyProduct },
}) => {
  return shouldRender ? (
    products.map(element => {
      const {
        id,
        productId,
        name,
        productModules,
        status,
        version,
        businessOwner,
        essentialOwner,
        price,
      } = element;
      const productAttributes = {
        id,
        productId,
        name,
        status,
        version,
        essentialOwner: essentialOwner || "",
        businessOwner: businessOwner || "",
        productModules,
        price,
        canRedirectToProduct,
        canCopyProduct,
      };
      return (
        <ProductItem
          key={id}
          copyProduct={copyProduct}
          {...productAttributes}
        />
      );
    })
  ) : (
    <Loader />
  );
};

ProductsList.propTypes = {
  products: PropTypes.array,
  copyProduct: PropTypes.func,
  shouldRender: PropTypes.bool,
  itemPermissions: PropTypes.object,
};

export default ProductsList;
