export default [
  {
    name: "Surname, ascending",
    value: {
      columnName: "surname",
      orderType: "asc",
    },
  },
  {
    name: "Surname, descending",
    value: {
      columnName: "surname",
      orderType: "desc",
    },
  },
  {
    name: "Contract status, ascending",
    value: {
      columnName: "contractStatus",
      orderType: "asc",
    },
  },
  {
    name: "Contract status, descending",
    value: {
      columnName: "contractStatus",
      orderType: "desc",
    },
  },
  {
    name: "Final reason status, ascending",
    value: {
      columnName: "finishReasonStatus",
      orderType: "asc",
    },
  },
  {
    name: "Final reason status, descending",
    value: {
      columnName: "finishReasonStatus",
      orderType: "desc",
    },
  },
];
