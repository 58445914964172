import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeLine from "../Components/TimeLine";
import {
  calendarNextMonth,
  calendarPrevMonth,
  fetchTimeline,
  fetchComment,
  deleteComment,
  updateComment,
  fetchTimelineStats,
  fetchTimelineComments,
} from "../Actions";
import validate from "../Validators/ReportValidator";
import { formValueSelector, reduxForm } from "redux-form";
import { fetchDictionary } from "../../Dictionaries/Actions";
import {
  fetchAllProducts,
  fetchEmployees,
  fetchAllProductsFullInfo,
} from "../../Products/Actions";
import {
  getTimeline,
  getReport,
  getExtendedReport,
  fetchCourses,
} from "../../Courses/Actions/CoursesActions";
import { fetchMentors, getCoursesSpecialists } from "../../Persons/Actions";

const FORM_NAME = "TIMELINE_REPORT";

const TimeLineReport = props => <TimeLine {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    timeline,
    calendar,
    dictionaries,
    coursesList,
    productsList,
    coursesSpecialists,
    mentorsList,
    employees,
    comment,
    timelineStats,
    timelineComments,
    allProductsFullInfo,
  } = state;
  const values = selector(
    state,
    "startDate",
    "endDate",
    "courseId",
    "cityId",
    "productId",
    "courseSpecialistId",
    "mentorId",
    "ended",
    "withRoom",
    "coursesModesId",
    "courseDays",
  );
  const validationErrors = state.form[FORM_NAME].syncErrors;
  return {
    timeline,
    calendar,
    values,
    validationErrors,
    dictionaries,
    coursesList,
    productsList,
    employees,
    comment,
    timelineStats,
    timelineComments,
    coursesSpecialists,
    mentorsList,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      calendarNextMonth,
      calendarPrevMonth,
      fetchTimeline,
      getTimeline,
      fetchDictionary,
      fetchCourses,
      fetchAllProducts,
      getReport,
      getExtendedReport,
      fetchEmployees,
      fetchComment,
      deleteComment,
      updateComment,
      fetchTimelineStats,
      fetchTimelineComments,
      getCoursesSpecialists,
      fetchMentors,
      fetchAllProductsFullInfo,
    },
    dispatch,
  );
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TimeLineReport),
);
