import React, { Component } from "react";
import MainTemplateNavbar from "./MainTemplateNavbar.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { error, success, removeAll } from "react-notification-system-redux";
import getCancelToken from "../../../Helpers/GetCancelToken";
import notificationOpts from "../../../Helpers/NotificationOpts";
import Notification from "../../../Common/Components/Notification/Notification";
import { withRouter } from "react-router-dom";
import { setCoursesStudentsSortOption } from "../../Courses/Actions/CoursesActions";
import TIME from "../../../Enums/Time";

import {
  clearErrorFromStore,
  clearSaveStateFromStore,
  fetchErrorCodes,
} from "../Actions";
import { forgetUser, fetchRefreshToken } from "../../Auth/Actions";
import PropTypes from "prop-types";
import dateHelper from "../../../Helpers/DateHelper";
import { translate } from "react-i18next";

class MainTemplate extends Component {
  static propTypes = {
    t: PropTypes.func,
    fetchErrorCodes: PropTypes.func,
    tokenExpiryTime: PropTypes.number,
    showError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object,
    ]),
    forgetUser: PropTypes.func,
    notifyRemoveAll: PropTypes.func,
    notifyError: PropTypes.func,
    notifySuccess: PropTypes.func,
    clearErrorFromStore: PropTypes.func,
    saveState: PropTypes.object,
    errorCodes: PropTypes.array,
    clearSaveStateFromStore: PropTypes.func,
    refreshToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fetchRefreshToken: PropTypes.func,
    notifications: PropTypes.array,
    logged: PropTypes.bool,
    children: PropTypes.array,
    history: PropTypes.object,
    courseStudentsSortOption: PropTypes.object,
    setCoursesStudentsSortOption: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchErrorCodes } = this.props;
    this.state = {
      shownErrorNotification: false,
      showSuccessNotification: false,
    };
    this.showErrorNotifications = this.showErrorNotifications.bind(this);

    if (fetchErrorCodes.length === 0) {
      fetchErrorCodes(this.cancelToken);
    }
  }
  componentDidMount() {
    this.refreshToken();
  }
  componentDidUpdate(prevProps) {
    const {
      tokenExpiryTime,
      showError,
      forgetUser,
      history: {
        location: { pathname },
      },
      courseStudentsSortOption,
      setCoursesStudentsSortOption,
    } = this.props;
    if (prevProps.tokenExpiryTime !== tokenExpiryTime) {
      this.refreshToken();
    }
    if (showError && showError.code === 401) {
      forgetUser();
    }

    if (
      courseStudentsSortOption &&
      !(
        pathname.match(/\/persons\/contract\/[0-9]*\/[0-9]*/) ||
        pathname.match(/\/courses\/[0-9]*\/settings/) ||
        pathname.match(/\/courses\/[0-9]*\/students/)
      )
    ) {
      setCoursesStudentsSortOption();
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.notifyRemoveAll();
    clearTimeout(this.refreshTokenTimeoutId);
  }

  showErrorNotifications() {
    const {
      t,
      showError,
      notifyError,
      errorCodes,
      notifyRemoveAll,
      notifySuccess,
      clearErrorFromStore,
      saveState,
    } = this.props;

    const errorMessage = _.get(showError, "error.message", null);

    if (
      showError &&
      (showError.message || showError.code || errorMessage) &&
      !this.state.shownErrorNotification
    ) {
      const errorCode = errorCodes.find(code => code.code === showError.code);
      let modifiedMessage;
      if (errorCode) {
        modifiedMessage = errorCode.message;
        if (showError.parameters && !Array.isArray(showError.parameters)) {
          const errorParams = Object.keys(showError.parameters);
          const errorValues = Object.values(showError.parameters);
          errorParams.map((param, index) => {
            if (errorCode.message.includes(`{${param}}`)) {
              modifiedMessage = errorCode.message.replace(
                `{${param}}`,
                errorValues[index],
              );
            }
          });
        }
      }
      notifyError(
        notificationOpts({
          title: modifiedMessage || showError.message || errorMessage,
          uid: 5,
          onRemove: () => {
            clearErrorFromStore();
            this.setState({ shownErrorNotification: false });
          },
        }),
      );
      this.setState({ shownErrorNotification: true });
    }
    if (
      saveState.saveState &&
      !this.state.showSuccessNotification &&
      !showError
    ) {
      notifyRemoveAll();
      notifySuccess(
        notificationOpts({
          title: t("Your data was saved"),
          autoDismiss: 3,
          onRemove: () => {
            this.props.clearSaveStateFromStore();
            this.setState({ showSuccessNotification: false });
          },
        }),
      );
      this.setState({ showSuccessNotification: true });
    }
  }

  refreshToken = () => {
    const { refreshToken, fetchRefreshToken, tokenExpiryTime } = this.props;
    const timeToRefresh =
      dateHelper(tokenExpiryTime)
        .get()
        .valueOf() -
      dateHelper()
        .get()
        .valueOf() -
      TIME.TIME_TO_REFRESH;
    if (this.refreshTokenTimeoutId) {
      clearTimeout(this.refreshTokenTimeoutId);
    }
    this.refreshTokenTimeoutId = setTimeout(() => {
      fetchRefreshToken(refreshToken);
    }, timeToRefresh);
  };

  render() {
    const { notifications, logged, children } = this.props;
    this.showErrorNotifications();

    return (
      <div>
        <Notification notifications={notifications} />
        {logged ? <MainTemplateNavbar /> : ""}
        {children}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      notifyError: error,
      notifySuccess: success,
      notifyRemoveAll: removeAll,
      clearErrorFromStore,
      clearSaveStateFromStore,
      fetchErrorCodes,
      forgetUser,
      fetchRefreshToken,
      setCoursesStudentsSortOption,
    },
    dispatch,
  );
}

function mapStateToProps({
  showError,
  notifications,
  saveState,
  errorCodes,
  refreshToken,
  tokenExpiryTime,
  courseStudentsSortOption,
}) {
  return {
    notifications,
    showError,
    saveState,
    errorCodes,
    refreshToken,
    tokenExpiryTime,
    courseStudentsSortOption,
  };
}

export default translate()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(MainTemplate),
  ),
);
