import React, { Component } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import CourseStudentsListElement from "./CoursesStudentsListElement";
import CourseStudentsSlaveListElement from "./CoursesStudentsSlaveListElement";
import { Authorize, permissions } from "../../../../Common/Utils/Acl";
import getCancelToken from "../../../../Helpers/GetCancelToken";
import MainTemplateContent from "../../../App/Containers/MainTemplateContent";
import { PlusCircle, Mail } from "react-feather";
import CustomModal from "../../../../Common/Components/CustomModal/CustomModal";
import Loader from "../../../../Common/Components/Loader/Loader";
import { translate } from "react-i18next";
import { Field, Form } from "redux-form";
import { RenderSelect } from "../../../../Forms/forms";
import CourseStudentsSummary from "./CourseStudentsSummary";
import PropTypes from "prop-types";
import CustomIcon from "../../../../Common/Components/CustomIcon/CustomIcon";
import COURSE_STUDENT_LIST_SORT_OPTIONS from "../../../../Enums/CourseStudentsListSortOptions";
import { NO_CONTENT } from "http-status-codes";
import Tooltip from "react-simple-tooltip";

const INITIAL_PAGE = 1;
const PAGE_AMOUNT = 20;
const EMPTY_EMAILS = "0";

class CourseStudentsLists extends Component {
  static propTypes = {
    t: PropTypes.func,
    change: PropTypes.func,
    values: PropTypes.object,
    match: PropTypes.object,
    getStudentsForCourse: PropTypes.func,
    fetchPersons: PropTypes.func,
    persons: PropTypes.shape({
      items: PropTypes.array,
    }),
    downloadCourseStudentReport: PropTypes.func,
    downloadCourseDiplomas: PropTypes.func,
    setCoursesStudentsSortOption: PropTypes.func,
    initialize: PropTypes.func,
    addStudentToCourse: PropTypes.func,
    courseStudents: PropTypes.object,
    courseStudentsSortOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    getCourseStatistics: PropTypes.func,
    courseStatistics: PropTypes.object,
    numberOfPlaces: PropTypes.number,
    courseSignature: PropTypes.string,
    notifyError: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      shouldRender: false,
      modalIsOpen: false,
      studentId: null,
      loadingTypeahead: false,
      chosenPersonId: null,
      pageNo: INITIAL_PAGE,
      options: [],
    };
    this.sortOptions = COURSE_STUDENT_LIST_SORT_OPTIONS.map(item => ({
      ...item,
      name: props.t(item.name),
    }));
    props.getCourseStatistics(this.cancelToken, props.match.params.courseId);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  UNSAFE_componentWillMount() {
    const {
      match: {
        params: { courseId },
      },
      fetchPersons,
      getStudentsForCourse,
      courseStudentsSortOption,
      initialize,
    } = this.props;
    fetchPersons(this.cancelToken, {
      pageNo: INITIAL_PAGE,
      pageAmount: PAGE_AMOUNT,
      emptyEmails: EMPTY_EMAILS,
    });
    const sortParams = courseStudentsSortOption ? courseStudentsSortOption : {};

    getStudentsForCourse(this.cancelToken, courseId, sortParams, () => {
      this.setState({ shouldRender: true });
    });
    if (courseStudentsSortOption) {
      initialize({ sort: courseStudentsSortOption });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const {
      persons,
      values,
      getStudentsForCourse,
      match: {
        params: { courseId },
      },
      setCoursesStudentsSortOption,
    } = this.props;
    if (persons && nextProps.persons !== persons) {
      const options =
        nextState.pageNo > 1
          ? [...this.state.options, ...nextProps.persons.items]
          : nextProps.persons.items;

      this.setState({
        options,
        loadingTypeahead: false,
      });
    }
    if (nextProps.values !== values) {
      this.setState(
        {
          shouldRender: false,
        },
        () => {
          const params = nextProps.values ? nextProps.values : {};

          getStudentsForCourse(this.cancelToken, courseId, params, () => {
            this.setState({ shouldRender: true });
          });
        },
      );
      setCoursesStudentsSortOption(nextProps.values);
    }
  }

  renderQuicksearch = () => {
    const { fetchPersons, t } = this.props;
    return (
      <AsyncTypeahead
        id={"personsTypeahead"}
        isLoading={this.state.loadingTypeahead}
        onSearch={query => {
          this.setState({ loadingTypeahead: true });
          const search = query.length > 0 ? query : null;

          fetchPersons(
            this.cancelToken,
            {
              search: search,
              pageNo: INITIAL_PAGE,
              pageAmount: PAGE_AMOUNT,
              emptyEmails: EMPTY_EMAILS,
            },
            () => {
              this.setState({ pageNo: INITIAL_PAGE });
            },
          );
        }}
        onInputChange={query => {
          if (query.length === 0) {
            this.setState({ loadingTypeahead: true });
            fetchPersons(
              this.cancelToken,
              {
                pageNo: INITIAL_PAGE,
                pageAmount: PAGE_AMOUNT,
              },
              () => {
                this.setState({ pageNo: INITIAL_PAGE });
              },
            );
          }
        }}
        onChange={selected =>
          this.setState({
            chosenPersonId: selected.length === 0 ? null : selected[0].id,
          })
        }
        options={this.state.options}
        labelKey={person => `${person.name} ${person.surname}`}
        renderMenuItemChildren={person => (
          <span>
            {person.name} {person.surname}
            {person.emails.length > 0 && (
              <span className="small--grey">
                <Mail size={14} />
                {person.emails[0]}
              </span>
            )}
          </span>
        )}
        filterBy={(option, { text }) => {
          const search = option.name + option.surname + option.emails.join("");
          const textWithoutSpaces = text && text.split(" ").join("");

          return (
            search.toLowerCase().indexOf(textWithoutSpaces.toLowerCase()) > -1
          );
        }}
        minLength={0}
        placeholder={t("Search student")}
        maxResults={PAGE_AMOUNT - 1}
        paginate={true}
        paginationText="Pokaż więcej wyników"
        onPaginate={() =>
          this.setState(
            {
              pageNo: this.state.pageNo + 1,
              loadingTypeahead: true,
            },
            () =>
              fetchPersons(this.cancelToken, {
                pageNo: this.state.pageNo,
                pageAmount: PAGE_AMOUNT,
              }),
          )
        }
        delay={300}
        useCache={false}
      />
    );
  };

  savePerson = () => {
    const {
      addStudentToCourse,
      courseStudents,
      getStudentsForCourse,
      match: {
        params: { courseId },
      },
      values,
    } = this.props;

    addStudentToCourse(
      {
        courseId: courseStudents.courseId,
        personId: this.state.chosenPersonId,
      },
      () => {
        this.setState({
          modalIsOpen: false,
          chosenPersonId: null,
          shouldRender: false,
        });

        getStudentsForCourse(this.cancelToken, courseId, values, () => {
          this.setState({ shouldRender: true });
        });
      },
    );
  };

  downloadReport = courseId => {
    this.setState(
      {
        downloadDisabled: true,
      },
      () => {
        this.props.downloadCourseStudentReport({ courseId }, () => {
          this.setState({ downloadDisabled: false });
        });
      },
    );
  };

  downloadDiplomas = courseId => {
    this.setState(
      {
        downloadDiplomasDisabled: true,
      },
      () => {
        this.props.downloadCourseDiplomas({ courseId }, data => {
          if (data.status === NO_CONTENT) {
            const { notifyError, t } = this.props;
            notifyError({ title: t("No diplomas to download") });
          }
          this.setState({ downloadDiplomasDisabled: false });
        });
      },
    );
  };

  render() {
    const {
      match: {
        params: { courseId },
      },
      courseStudents,
      t,
      change,
      courseStatistics,
      numberOfPlaces,
    } = this.props;
    const secondaryMenu = [
      {
        name: t("Settings"),
        link: `/courses/${courseId}/settings`,
      },
      {
        name: t("Students"),
        link: `/courses/${courseId}/students`,
      },
      {
        name: t("Surveys"),
        link: `/courses/${courseId}/polls`,
        position: "right",
      },
    ];
    return this.state.shouldRender ? (
      <MainTemplateContent
        title={courseStudents.signature}
        background={true}
        secondaryMenu={secondaryMenu}
        className="course-students"
      >
        <Authorize
          component={
            <CustomModal
              isOpen={this.state.modalIsOpen}
              title={t("Add student")}
              onRequestClose={() => this.setState({ modalIsOpen: false })}
            >
              <div className="row">
                <div className="col-sm-12 course-students__quicksearch">
                  {this.renderQuicksearch()}
                </div>
                <div className="col-sm-12">
                  <button
                    className="btn btn-dark"
                    disabled={!this.state.chosenPersonId}
                    onClick={this.savePerson}
                  >
                    {t("Submit")}
                  </button>
                  <button
                    className="btn btn-link btn-regular"
                    onClick={() => this.setState({ modalIsOpen: false })}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </CustomModal>
          }
          allows={[permissions.COURSE_STUDENT_ADD]}
        />
        <div className="row course-students__info">
          <div className="col-md-9">
            <Authorize
              component={
                <button
                  className="btn btn-link btn-with-icon btn-regular"
                  onClick={() => this.setState({ modalIsOpen: true })}
                >
                  <PlusCircle size={16} /> {t("Add student")}
                </button>
              }
              allows={[permissions.COURSE_STUDENT_ADD]}
            />
            <CourseStudentsSummary
              shouldRender={!!courseStudents.contracts}
              courseStatistics={courseStatistics}
              numberOfPlaces={numberOfPlaces}
            />
          </div>
          <Form className="col-md-2" onSubmit={() => {}}>
            <Field
              component={RenderSelect}
              name="sort"
              dropdownConfig={{
                data: this.sortOptions,
                textField: "name",
                valueField: "value",
                placeholder: t("Sort by"),
              }}
              under={{
                fieldClassName: "col-xs-12",
              }}
              onClearCallback={() => change("sort", null)}
            />
          </Form>
          <div className="col-md-1">
            <button
              id="courseStudentsDownload"
              className="btn btn-default btn-link pull-left"
              onClick={() => this.downloadReport(courseId)}
              disabled={this.state.downloadDisabled}
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
              />
            </button>
            <button
              id="courseDiplomasDownload"
              className="btn btn-default btn-link pull-right"
              onClick={() => this.downloadDiplomas(courseId)}
              disabled={this.state.downloadDiplomasDisabled}
            >
              <Tooltip
                padding={5}
                placement="bottom"
                content={t("Download diplomas")}
              >
                <CustomIcon
                  icon="zip"
                  color="#4d4d4f"
                  size="40px"
                  viewBox="0 0 512 512"
                />
              </Tooltip>
            </button>
          </div>
        </div>
        <div className="row ">
          <ul className="col-xs-12 list-unstyled course-students__list">
            {courseStudents.contracts &&
              courseStudents.contracts.map((student, index) => (
                <li key={`${student.id}-${student.name}-${student.surname}`}>
                  {!student.isSlave ? (
                    <CourseStudentsListElement
                      student={student}
                      counter={index + 1}
                    />
                  ) : (
                    <CourseStudentsSlaveListElement
                      {...student}
                      counter={index + 1}
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </MainTemplateContent>
    ) : (
      <Loader />
    );
  }
}

export default translate()(CourseStudentsLists);
