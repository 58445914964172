import React from "react";
import PropTypes from "prop-types";
import { PlusCircle, Save, Trash } from "react-feather";
import { translate } from "react-i18next";
import { Field, FieldArray } from "redux-form";
import { RenderMultiselect, RenderSelect } from "../../../Forms/forms";

class ProductsPolls extends React.Component {
  addPoll = (module, fieldName) => {
    const { change } = this.props;
    const modulePolls = module.polls
      ? [...module.polls, { poll: null, package: null }]
      : [{ poll: null, package: null }];
    change(fieldName, modulePolls);
  };
  removePoll = (module, fieldName, index) => {
    const { change } = this.props;
    const modulePolls = [...module.polls];
    modulePolls.splice(index, 1);
    change(fieldName, modulePolls);
  };
  renderPolls = ({ fields }, module, modulePollsFieldName) => {
    const { t, cantEdit } = this.props;

    return fields.map((poll, index) => {
      return (
        <div key={index}>
          <div>
            <Field
              component={RenderSelect}
              under={{ fieldClassName: "position-relative" }}
              shouldDisabled={cantEdit}
              dropdownConfig={{
                valueField: "id",
                textField: "name",
                placeholder: t("Choose survey"),
                data: this.props.modulePolls,
              }}
              status={this.props.status}
              name={`${poll}.poll`}
              onClearCallback={() => change(`${poll}.poll`, null)}
              clearButtonStyle={{ right: "-5px" }}
            />
          </div>
          <div>
            <Field
              component={RenderMultiselect}
              under
              shouldDisabled={cantEdit}
              status={this.props.status}
              dropdownConfig={{
                valueField: "id",
                textField: "name",
                placeholder: t("Choose packages"),
                data: module.packages,
              }}
              type="text"
              name={`${poll}.packages`}
            />
          </div>
          <div>
            <button
              type="button"
              disabled={cantEdit}
              onClick={() => {
                this.removePoll(module, modulePollsFieldName, index);
              }}
              className="btn btn-with-icon btn--transparent btn--no-border"
            >
              <Trash size={20} />
            </button>
          </div>
        </div>
      );
    });
  };
  renderModulesPolls = ({ fields }) => {
    const { t, cantEdit, moduleWithoutLectures } = this.props;
    return fields.map((field, index) => {
      const module = fields.get(index);
      const modulePollsFieldName = `${field}.polls`;
      const showInForm =
        !!moduleWithoutLectures &&
        !moduleWithoutLectures.find(moduleWithoutLecture =>
          typeof module.type === "number"
            ? moduleWithoutLecture.id === module.type
            : moduleWithoutLecture.id === module.type.id,
        );

      return module.name && module.name.length > 0 && showInForm ? (
        <div className={`row`} key={index}>
          <div className={`row`}>
            <div className={`col-xs-12 col-sm-6`}>
              <div className={`col-sm-12 col-md-8`}>
                <label>{module.name}</label>
              </div>
              <div className={`col-sm-12 col-md-1`}>
                <button
                  type="button"
                  className="btn btn-with-icon btn--transparent btn--no-border"
                  disabled={cantEdit}
                  onClick={() => {
                    this.addPoll(module, `${field}.polls`);
                  }}
                >
                  <PlusCircle size={20} />
                  {t("Add survey")}
                </button>
              </div>
            </div>
          </div>
          <div className={`poll-group`} key={index}>
            <FieldArray
              rerenderOnEveryChange
              name={modulePollsFieldName}
              component={field => {
                return this.renderPolls(field, module, modulePollsFieldName);
              }}
            />
          </div>
        </div>
      ) : (
        ""
      );
    });
  };
  render() {
    const { t, savePolls, cantEdit } = this.props;
    return (
      <div>
        <h3>{t("Modules' surveys")}</h3>
        <FieldArray
          name="productModules"
          rerenderOnEveryChange
          component={this.renderModulesPolls}
        />
        <div className={`row`}>
          <div className={`col-xs-12`}>
            <button
              className="btn btn-dark"
              type="button"
              disabled={cantEdit}
              onClick={() => savePolls()}
            >
              <Save size={20} />
              {t("Save polls")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ProductsPolls);

ProductsPolls.propTypes = {
  fields: PropTypes.object,
  t: PropTypes.func,
  moduleId: PropTypes.number,
  modulePolls: PropTypes.array,
  moduleWithoutLectures: PropTypes.array,
  packages: PropTypes.array,
  disabled: PropTypes.bool,
  change: PropTypes.func,
  status: PropTypes.string,
  cantEdit: PropTypes.bool,
  savePolls: PropTypes.func,
};
