import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import dateHelper from "../../../Helpers/DateHelper";
import getCancelToken from "../../../Helpers/GetCancelToken";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import Loader from "../../../Common/Components/Loader/Loader";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { RenderCheckbox } from "../../../Forms/forms";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import { Link } from "react-router-dom";
import TIME from "../../../Enums/Time";
import EditButton from "../../../Common/Components/EditButton/EditButton";
import { DateTimePicker } from "react-widgets";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import { Features } from "../../../Common/Utils/Tenant";
import moment from "moment";

class RefundsReport extends Component {
  #cancelToken = getCancelToken();

  static propTypes = {
    refunds: PropTypes.object,
    fetchRefunds: PropTypes.func,
    downloadRefunds: PropTypes.func,
    registerRefundDate: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentPage: 1,
      sortAscending: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = (forceFirstPage = false) => {
    this.setState({ isLoading: true });
    const { fetchRefunds } = this.props;
    fetchRefunds(
      this.#cancelToken,
      this.prepareRequestParams(forceFirstPage),
      () => {
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  prepareRequestParams = forceFirstPage => {
    const {
      values: { notRefunded },
    } = this.props;
    const { currentPage: page, sortBy, sortAscending } = this.state;
    this.setState({
      currentPage: forceFirstPage ? 1 : page,
    });
    return {
      notRefunded: _.isBoolean(notRefunded) ? Number(notRefunded) : null,
      sortBy,
      sortOrder: sortAscending ? "asc" : "desc",
      pageNo: forceFirstPage ? 1 : page,
    };
  };

  componentDidUpdate(prevProps) {
    const {
      values: { notRefunded },
    } = this.props;
    const {
      values: { notRefunded: oldNotRefunded },
    } = prevProps;
    const valuesComparison = [[notRefunded, oldNotRefunded]];
    const valuesNotChanged = valuesComparison.every(
      valuesToCheck => valuesToCheck[0] === valuesToCheck[1],
    );

    if (!valuesNotChanged) {
      _.debounce(() => {
        this.loadData(true);
      }, 1000)();
    }
  }

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
      },
      this.loadData,
    );
  };

  handleSort = sortBy => {
    const { sortAscending } = this.state;
    this.setState(
      {
        sortBy,
        sortAscending:
          this.state.sortBy === sortBy ? !sortAscending : sortAscending,
      },
      () => {
        this.changePage(1);
      },
    );
  };

  handleDownload = () => {
    const { downloadRefunds } = this.props;
    const params = this.prepareRequestParams();
    downloadRefunds(this.#cancelToken, { ...params, format: "xls" }, () => {
      this.setState({ isWaitingForData: false });
    });
  };

  renderRefundedAtColumn = row => {
    const { id, refundedAt } = row.original;
    const date = refundedAt
      ? dateHelper(refundedAt)
          .get()
          .format(TIME.DATE_FORMAT)
      : null;

    return (
      <div className="editField">
        {this.state.openDatePicker !== id ? (
          <div className="refund-date-button">
            <EditButton
              editable={true}
              text={date}
              onClick={() => {
                this.setState({ openDatePicker: id });
              }}
            />
          </div>
        ) : (
          <div style={{ position: "absolute" }} id="refundDatePicker">
            <DateTimePicker
              time={false}
              max={new Date()}
              format={TIME.DATE_FORMAT}
              value={
                !refundedAt
                  ? null
                  : new Date(
                      dateHelper(Number(refundedAt))
                        .getUTC(Number(refundedAt))
                        .valueOf(),
                    )
              }
              onChange={value => {
                const refundedAt = moment(value)
                  .utc()
                  .unix();
                this.props.registerRefundDate(id, { refundedAt }, () => {
                  this.setState({ openDatePicker: null });
                  this.loadData();
                });
              }}
              onBlur={() => {
                this.setState({ openDatePicker: null });
              }}
              views={["month", "year"]}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isLoading, currentPage } = this.state;
    const { t, refunds } = this.props;
    const PER_PAGE = 10;
    const counter = _.has(refunds, "counter") ? refunds.counter : 0;
    const items = _.has(refunds, "elements") ? refunds.elements : [];
    const pages = Math.ceil(counter / PER_PAGE);

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <section className="form-section">
          <div className="row">
            <div className={`form-group col-md-9 col-xs-6`}>
              <div className="col-md-4">
                <Field
                  name="notRefunded"
                  labelForCheckbox={t("Show only not refunded")}
                  customLabel=""
                  component={RenderCheckbox}
                />
              </div>
            </div>
            <div
              className={`form-group col-md-1 col-xs-6`}
              id="refundsDownload"
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
                onClick={this.handleDownload}
              />
            </div>
          </div>
          <div className="row horizontal-center">
            <div className="col-md-12">
              <div className={`row`}>
                <ReactTable
                  noDataText={t("No records found")}
                  showPagination={false}
                  defaultPageSize={PER_PAGE}
                  data={items}
                  sortable={true}
                  columns={[
                    {
                      Header: () => <div>{this.props.t(`Owner`)}</div>,
                      Cell: ({ original }) => {
                        if (original.owner.type === "payer") {
                          return (
                            <Link to={`/balance/${original.owner.balanceId}`}>
                              {original.owner.name}
                            </Link>
                          );
                        }
                        return (
                          <Link to={`/persons/update/${original.owner.id}/3`}>
                            {original.owner.name}
                          </Link>
                        );
                      },
                      width: 200,
                      sortable: false,
                    },
                    {
                      Header: () => <div>{this.props.t(`Reason`)}</div>,
                      accessor: "description",
                      sortable: false,
                    },
                    {
                      Header: () => <div>{this.props.t(`Amount`)}</div>,
                      Cell: ({ original: { amount } }) => (
                        <span>{renderAmountWithCurrency(amount)}</span>
                      ),
                      accessor: "amount.amount",
                      sortable: false,
                      className: "text-right",
                      width: 100,
                    },
                    {
                      Header: () => (
                        <div>{this.props.t(`Refund bank account`)}</div>
                      ),
                      accessor: "owner.bankAccount",
                      sortable: false,
                    },
                    {
                      Header: () => (
                        <div onClick={() => this.handleSort("createdAt")}>
                          {this.props.t(`Date`)}
                        </div>
                      ),
                      Cell: ({ original: { createdAt } }) => (
                        <span>
                          {dateHelper(createdAt)
                            .get()
                            .format(TIME.DATE_FORMAT)}
                        </span>
                      ),
                      accessor: "createdAt",
                      sortable: false,
                      className: "centered-text",
                      width: 150,
                    },
                    {
                      Header: () => <div>{this.props.t(`Refunded by`)}</div>,
                      accessor: "createdBy",
                      sortable: false,
                    },
                    {
                      Header: () => (
                        <div onClick={() => this.handleSort("refundedAt")}>
                          {this.props.t(`Refund status`)}
                        </div>
                      ),
                      Cell: this.renderRefundedAtColumn,
                      accessor: "refundedAt",
                      sortable: false,
                      className: "centered-text",
                      width: 150,
                    },
                    {
                      Header: () => <div>{this.props.t(`Correction`)}</div>,
                      accessor: "correction",
                      sortable: false,
                      show: Features.invoices,
                    },
                  ]}
                  className="-striped -highlight refunds-table"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Paginator
                pages={pages}
                onClick={this.changePage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default translate()(RefundsReport);
