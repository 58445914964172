import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

import dateHelper from "../../../Helpers/DateHelper";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getPersonInitials from "../../../Helpers/GetPersonInitials";

import WEEK_DAYS from "../../../Enums/WeeksDays";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import MONTHS from "../../../Enums/Months";
import ReactTooltip from "react-tooltip";
import Loader from "../../../Common/Components/Loader/Loader";
import {
  RenderDateTime,
  RenderSelect,
  RenderCheckbox,
  RenderMultiselect,
} from "../../../Forms/forms";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  MessageSquare,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";
import TimeLineComment from "./TimeLineComment";
import {
  Authorize,
  havePermissions,
  permissions,
} from "../../../Common/Utils/Acl";
import CourseSpecialistSelectWithPagination from "../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import MentorsSelectWithPagination from "../../../Common/Components/MentorsSelectWithPagination/MentorsSelectWithPagination";
import TimeLineModel from "../Models/TimeLineModel";
import SelectWithPagingForFilters from "../../../Common/Components/SelectWithPagingForFilters/SelectWithPagingForFilters";

class TimeLine extends React.Component {
  static propTypes = {
    calendar: PropTypes.object,
    timeline: PropTypes.object,
    calendarPrevMonth: PropTypes.func,
    calendarNextMonth: PropTypes.func,
    fetchTimeline: PropTypes.func,
    fetchTimelineStats: PropTypes.func,
    fetchTimelineComments: PropTypes.func,
    t: PropTypes.func,
    validationErrors: PropTypes.object,
    values: PropTypes.object,
    getTimeline: PropTypes.func,
    getReport: PropTypes.func,
    getExtendedReport: PropTypes.func,
    productsList: PropTypes.object,
    dictionaries: PropTypes.object,
    change: PropTypes.func,
    fetchDictionary: PropTypes.func,
    fetchCourses: PropTypes.func,
    coursesList: PropTypes.object,
    fetchAllProducts: PropTypes.func,
    fetchEmployees: PropTypes.func,
    employees: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fetchComment: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
    comment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    timelineStats: PropTypes.object,
    timelineComments: PropTypes.array,
    getCoursesSpecialists: PropTypes.func,
    fetchMentors: PropTypes.func,
    coursesSpecialists: PropTypes.array,
    mentorsList: PropTypes.array,
    fetchAllProductsFullInfo: PropTypes.func,
    allProductsFullInfo: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const {
      fetchDictionary,
      fetchCourses,
      fetchAllProducts,
      getCoursesSpecialists,
      fetchMentors,
      fetchEmployees,
      fetchAllProductsFullInfo,
    } = props;
    this.cancelToken = getCancelToken();
    this.state = {
      timeline: null,
      loading: true,
      isWaitingForData: false,
      numberOfRowPerPage: 10,
      currentPage: 1,
      displayComment: false,
      displayColumns: true,
    };
    this.loadData(false);
    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: ACTION_TYPES.FETCH_CITIES,
      params: { onlyTeach: true },
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/mode",
      actionType: ACTION_TYPES.FETCH_COURSES_MODES,
    });
    fetchCourses(this.cancelToken, {
      pageNo: this.state.currentPage,
      pageAmount: this.state.numberOfRowPerPage,
    });
    fetchAllProducts(this.cancelToken);
    getCoursesSpecialists(this.cancelToken);
    fetchMentors(this.cancelToken);
    fetchEmployees(this.cancelToken);
    fetchAllProductsFullInfo(this.cancelToken);
  }
  changeOffset(event) {
    document.querySelector(
      "#legendTable",
    ).style.top = `-${event.currentTarget.scrollTop}px`;
    document.querySelector(
      "#dataHeader",
    ).style.top = `${event.currentTarget.scrollTop}px`;
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const {
      timeline,
      values: {
        cityId,
        courseId,
        productId,
        courseSpecialistId,
        mentorId,
        ended,
        withRoom,
        coursesModesId,
        courseDays,
      },
    } = this.props;

    ReactTooltip.rebuild();

    if (timeline && !_.isEqual(timeline.rows, this.state.timeline)) {
      this.setState({
        timeline: timeline.rows,
        loading: false,
      });
    }
    const shouldLoadData =
      cityId !== prevProps.values.cityId ||
      courseId !== prevProps.values.courseId ||
      productId !== prevProps.values.productId ||
      courseSpecialistId !== prevProps.values.courseSpecialistId ||
      mentorId !== prevProps.values.mentorId ||
      ended !== prevProps.values.ended ||
      withRoom !== prevProps.values.withRoom ||
      courseDays !== prevProps.values.courseDays ||
      coursesModesId !== prevProps.values.coursesModesId;
    if (shouldLoadData) {
      this.loadData(false);
    }
  }

  parseCourseModesIds = coursesModesId => {
    return (coursesModesId => {
      let modesIds = [];
      if (coursesModesId) {
        for (const mode of coursesModesId) {
          modesIds.push(mode.id);
        }
      }

      return modesIds;
    })(coursesModesId);
  };

  loadData = commentsOnly => {
    const {
      fetchTimeline,
      fetchTimelineStats,
      fetchTimelineComments,
      calendar: { currentYear, currentMonth },
      values: {
        cityId,
        courseId,
        productId,
        courseSpecialistId,
        mentorId,
        ended,
        withRoom,
        coursesModesId,
        courseDays,
      },
    } = this.props;
    const modesIds = this.parseCourseModesIds(coursesModesId);
    const searchTerms = {
      startDate: dateHelper([currentYear, currentMonth, 1]).getFormatted(),
      endDate: dateHelper([
        currentYear,
        currentMonth,
        this.daysInMonth(),
      ]).getFormatted(),
      format: "json",
      courseId,
      cityId,
      productId,
      courseSpecialistId,
      mentorId,
      ended,
      withRoom,
      modesIds,
      courseDays,
    };
    const contentData = document.querySelector("#contentData");
    const timeLine = new TimeLineModel(searchTerms);
    if (contentData) {
      contentData.scrollTop = 0;
    }
    if (!commentsOnly) {
      fetchTimeline(timeLine.mapFiltersSearchTerms(), () => {
        this.setState({
          loading: false,
        });
      });
      fetchTimelineStats(timeLine.mapFiltersSearchTerms());
    }
    fetchTimelineComments(timeLine.mapFiltersSearchTerms());
  };

  daysList = () => {
    const {
      t,
      calendar: { currentYear, currentMonth },
    } = this.props;
    let days = [];
    for (let i = 1; i <= this.daysInMonth(); i++) {
      let weekDay =
        dateHelper([currentYear, currentMonth, i])
          .get()
          .day() - 1;
      if (weekDay === -1) {
        weekDay = 6;
      }
      days.push(
        <th key={i} className={weekDay > 4 ? "sunday" : ""}>
          <span> {t(WEEK_DAYS.SHORT[weekDay])} </span>
          <span>{i}</span>
        </th>,
      );
    }
    return days;
  };

  daysInMonth = () => {
    const {
      calendar: { currentYear, currentMonth },
    } = this.props;
    return dateHelper([currentYear, currentMonth, 5])
      .get()
      .daysInMonth();
  };

  toggleColumns = () => {
    this.setState({ displayColumns: !this.state.displayColumns });
  };

  prepareLecturersAndRooms = cellData => {
    const result = {
      lecturers: "",
      rooms: "",
    };

    if (cellData && cellData.units.length > 0) {
      result.lecturers = (units => {
        let lecturers = units
          .filter(element => element.lecturer !== null)
          .map(
            element => element.lecturer.name + " " + element.lecturer.surname,
          );
        return lecturers.join(", ");
      })(cellData.units);

      result.rooms = (units => {
        let rooms = units
          .filter(element => element.room !== null)
          .map(element => element.room);

        return rooms.join(", ");
      })(cellData.units);
    }

    return result;
  };

  getColor = nameString => {
    let hash = 0;
    for (let i = 0; i < nameString.length; i++) {
      hash = nameString.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);

    const hColor = Math.floor(hash % 360);
    const sColor = 50;
    const lColor = 50;

    return `hsl(${hColor}, ${sColor}%, ${lColor}%)`;
  };

  getInitials = (name, surname) => name.charAt() + surname.charAt();

  renderTimeLineRow = data => {
    const days = [];
    const {
      calendar: { currentYear, currentMonth },
      timelineComments,
      t,
    } = this.props;
    const { signature, lectureUnits, courseId } = data;
    for (let i = 1; i <= this.daysInMonth(); i++) {
      const date = dateHelper([currentYear, currentMonth, i]).get();
      const cellData = lectureUnits.find(
        unit => unit.date === date.format("YYYY-MM-DD"),
      );
      const dayComments =
        timelineComments &&
        timelineComments.find(comments => comments.courseId === courseId);
      const comment =
        dayComments &&
        dayComments.days.find(
          comment => comment.date === date.format("YYYY-MM-DD"),
        );

      const [firstUnit] = cellData ? cellData.units : [];
      const moduleNrText = firstUnit ? firstUnit.moduleNr : "";
      const lecturerInitials =
        firstUnit && firstUnit.lecturer
          ? getPersonInitials(firstUnit.lecturer)
          : "";
      const lecturersAndRooms = this.prepareLecturersAndRooms(cellData);
      const content = `${signature} <br/> 
        ${t("Lecturers")}: ${lecturersAndRooms.lecturers} <br/>
        ${t("module")}: ${
        cellData && cellData.units.length > 0 ? cellData.units[0].moduleNr : ""
      } <br/> 
        ${t("room")}: ${lecturersAndRooms.rooms}
        ${
          !comment
            ? ""
            : comment.comment.text.length > 30
            ? `<br/> ${comment.comment.text.substr(0, 30)}...`
            : `<br/> ${comment.comment.text}`
        }`;

      const canCommentDay = havePermissions([
        permissions.COMMENT_TIMELINE_DAY_EDIT,
      ]);
      if (cellData) {
        const color =
          cellData.units.length > 0
            ? (units => {
                let colors = units.filter(element => element.hexColor !== null);

                return colors.length > 0 ? colors[0].hexColor : null;
              })(cellData.units)
            : null;
        days.push(
          <td
            key={i}
            style={cellData && color ? { background: color } : {}}
            className={`booking ${comment ? "commented" : ""}`}
            onClick={event =>
              canCommentDay &&
              this.displayComment(
                event,
                (comment && comment.comment) || {
                  objectType: "timeline_day",
                  objectId: `${courseId}_${date.format("YYYY-MM-DD")}`,
                },
                signature,
                cellData,
              )
            }
          >
            <p data-tip={content} data-for="enrich">
              {moduleNrText} {lecturerInitials}
            </p>
          </td>,
        );
      } else {
        days.push(
          <td
            onClick={event =>
              canCommentDay &&
              this.displayComment(
                event,
                (comment && comment.comment) || {
                  objectType: "timeline_day",
                  objectId: `${courseId}_${date.format("YYYY-MM-DD")}`,
                },
                signature,
                {
                  date: date.format("YYYY-MM-DD"),
                },
              )
            }
            className={
              date.day() === 0 || date.day() === 6
                ? `weekend ${comment ? "commented" : ""}`
                : `${comment ? "commented" : ""}`
            }
            key={i}
          >
            {comment && comment.comment.text && (
              <p
                data-for="enrich"
                data-tip={`${
                  comment.comment.text.length > 30
                    ? `<br/> ${comment.comment.text.substr(0, 30)}...`
                    : `<br/> ${comment.comment.text}`
                }`}
              >
                <span className="commented__empty-cell" />
              </p>
            )}
          </td>,
        );
      }
    }
    return days;
  };
  renderTimeLine = () => {
    const { timeline } = this.state;
    if (timeline && timeline.length > 0) {
      return timeline.map((data, index) => {
        return <tr key={index}>{this.renderTimeLineRow(data)}</tr>;
      });
    } else {
      return (
        <tr>
          <td colSpan={this.daysInMonth() + 5}>Brak danych</td>
        </tr>
      );
    }
  };
  prevMonth = () => {
    this.props.calendarPrevMonth();
    this.setState(
      {
        loading: true,
      },
      _.debounce(() => {
        this.loadData(false);
      }, 1000),
    );
  };
  nextMonth = () => {
    this.props.calendarNextMonth();
    this.setState(
      {
        loading: true,
      },
      _.debounce(() => {
        this.loadData(false);
      }, 1000),
    );
  };

  displayComment = (event, comment = {}, signature, data = {}) => {
    this.setState({
      displayComment: true,
      commentData: { ...comment, data, signature },
      commentPosition: [event.pageX, event.pageY],
    });
  };

  hideComment = reloadData => {
    if (this.state.displayComment) {
      this.setState(
        {
          displayComment: false,
        },
        () => {
          reloadData &&
            _.debounce(() => {
              this.loadData(true);
            }, 1000)();
        },
      );
    }
  };

  handleCommentSubmit = () => {
    this.setState({
      displayComment: false,
    });
  };

  renderCourses = () => {
    const { timeline, displayColumns } = this.state;
    const { timelineComments } = this.props;
    const commentLength = 30;
    return timeline.map((data, index) => {
      const {
        signature,
        courseSpecialistId,
        courseSpecialist,
        startDate,
        endDate,
        courseId,
        mentors,
      } = data;
      const mentorsArray = Object.keys(mentors).map(key => {
        const [name, surname] = mentors[key] && mentors[key].name.split(" ");
        const id = mentors[key].personId;
        return {
          id,
          name,
          surname,
        };
      });

      const csLastName = courseSpecialist && courseSpecialist.split(" ").pop();
      const courseComment = timelineComments.find(
        comment => comment.courseId === courseId,
      );
      const canCommentCourse = havePermissions([
        permissions.COMMENT_COURSE_EDIT,
      ]);
      return (
        <tr key={index}>
          <td
            onClick={event =>
              canCommentCourse &&
              this.displayComment(
                event,
                (courseComment && courseComment.comment) || {
                  objectType: "course",
                  objectId: courseId,
                },
                signature,
              )
            }
            className="calender__comment"
          >
            {courseComment &&
            courseComment.comment &&
            courseComment.comment.text ? (
              <div
                data-for="mainComment"
                data-tip={
                  courseComment.comment.text.length > commentLength
                    ? `<br/> ${courseComment.comment.text.substr(
                        0,
                        commentLength,
                      )}...`
                    : `<br /> ${courseComment.comment.text}`
                }
              >
                <MessageSquare size={21} color="#fbbb21" />
              </div>
            ) : (
              canCommentCourse && <MessageSquare size={21} color="#4b4b4b" />
            )}
          </td>
          <td>
            <Link to={`/courses/${courseId}/settings`}>{signature}</Link>
          </td>
          <td
            className={displayColumns ? "" : "no-display"}
            style={{ maxWidth: "none" }}
          >
            {mentorsArray.map(mentor => (
              <React.Fragment key={mentor.id}>
                {mentorsArray.length == 1 ? (
                  <Link
                    to={`/persons/update/${mentor.id}`}
                    title={`${mentor.name} ${mentor.surname}`}
                  >
                    {mentor.surname}
                  </Link>
                ) : (
                  <Link
                    to={`/persons/update/${mentor.id}`}
                    title={`${mentor.name} ${mentor.surname}`}
                  >
                    <div
                      className="avatar"
                      style={{
                        backgroundColor: this.getColor(mentor.name + mentor.id),
                      }}
                    >
                      <div className="avatar__letters">
                        {this.getInitials(mentor.name, mentor.surname)}
                      </div>
                    </div>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </td>
          <td className={displayColumns ? "" : "no-display"}>
            <Link to={`/persons/update/${courseSpecialistId}`}>
              {csLastName}
            </Link>
          </td>
          <td className={displayColumns ? "calendar__startDate" : "no-display"}>
            {startDate}
          </td>
          <td className={displayColumns ? "calendar__endDate" : "no-display"}>
            {endDate}
          </td>
        </tr>
      );
    });
  };
  getReport = callback => {
    const { validationErrors } = this.props;
    if (!validationErrors) {
      this.setState({ isWaitingForData: true }, callback);
    }
  };
  handleDownload = () => {
    const {
      values: {
        startDate,
        endDate,
        courseId,
        cityId,
        productId,
        courseSpecialistId,
        mentorId,
        ended,
        withRoom,
        courseDays,
        coursesModesId,
      },
      getTimeline,
    } = this.props;

    const modesIds = this.parseCourseModesIds(coursesModesId);
    const timeLine = new TimeLineModel({
      startDate,
      endDate,
      courseId,
      cityId,
      productId,
      courseSpecialistId,
      mentorId,
      ended,
      withRoom,
      modesIds,
      courseDays,
    });

    getTimeline(timeLine.mapFiltersSearchTerms(), () => {
      this.setState({ isWaitingForData: false });
    });
  };
  handleReportDownload = () => {
    const {
      values: { startDate, endDate },
      getReport,
    } = this.props;

    getReport(
      {
        dateFrom: startDate,
        dateTo: endDate,
        fileDownload: true,
      },
      () => {
        this.setState({ isWaitingForData: false });
      },
    );
  };
  handleExtendedReportDownload = () => {
    const {
      values: { startDate, endDate },
      getExtendedReport,
    } = this.props;

    getExtendedReport(
      {
        dateFrom: startDate,
        dateTo: endDate,
        fileDownload: true,
      },
      () => {
        this.setState({ isWaitingForData: false });
      },
    );
  };
  onCSPaginateHandler = () => {
    const { getCoursesSpecialists } = this.props;
    getCoursesSpecialists(this.cancelToken);
  };

  renderFilters() {
    const {
      dictionaries,
      coursesList,
      t,
      change,
      coursesSpecialists,
      mentorsList,
      allProductsFullInfo,
      fetchCourses,
    } = this.props;
    const underClasses = {
      containerClassName: "col-lg-2 col-md-3 col-xs-6",
      fieldClassName: "col-xs-12",
    };
    return (
      <div id="filters" className="col-sm-12">
        <div className="row">
          <div className="col-xs-12">
            <label className="label label--top">{t("Filters")}</label>
          </div>
        </div>
        <div className="row">
          <div className="width">
            <SelectWithPagingForFilters
              fetchData={fetchCourses}
              dataItems={coursesList.coursesArray}
              placeholder={t("Signature")}
              onClear={() => change("courseId", null)}
              under={underClasses}
              selectId="course-signature-select"
              containerId="course-signature"
              labelKey="signature"
              onChangeSelect={selectedCourse =>
                change("courseId", _.get(selectedCourse, "id", null))
              }
            />
          </div>
          <Field
            component={RenderSelect}
            name="cityId"
            dropdownConfig={{
              data: dictionaries.cities,
              textField: "name",
              valueField: "id",
              placeholder: t("Cities"),
            }}
            under={underClasses}
            onClearCallback={() => change("cityId", null)}
          />
          <Field
            component={RenderSelect}
            name="productId"
            dropdownConfig={{
              data: allProductsFullInfo.map(product => ({
                ...product,
                productRenderName: `${product.shortcut} ${product.name}`,
              })),
              textField: "productRenderName",
              valueField: "id",
              placeholder: t("Products"),
              filter: "contains",
            }}
            under={underClasses}
            onClearCallback={() => change("productId", null)}
          />
          <Field
            component={MentorsSelectWithPagination}
            name="mentorId"
            onChangeHandler={value => {
              this.props.change("mentorId", value.id);
            }}
            placeholder={t("Mentor")}
            getMentorsLists={this.props.fetchMentors}
            mentors={mentorsList}
            under={underClasses}
            onClearCallback={() => change("mentorId", null)}
          />
          <Field
            component={CourseSpecialistSelectWithPagination}
            name="courseSpecialistId"
            onChangeHandler={value => {
              this.props.change("courseSpecialistId", value.id);
            }}
            placeholder={t("Course Specialist")}
            getCoursesSpecialists={this.props.getCoursesSpecialists}
            coursesSpecialists={coursesSpecialists}
            under={underClasses}
            onClearCallback={() => change("courseSpecialistId", null)}
          />
          <Field
            component={RenderMultiselect}
            name="coursesModesId"
            dropdownConfig={{
              data: dictionaries.coursesModes,
              textField: "name",
              valueField: "id",
              placeholder: t("Courses modes"),
            }}
            under={underClasses}
            onClearCallback={() => change("coursesModesId", null)}
          />
          <Field
            component={RenderCheckbox}
            name="ended"
            under={underClasses}
            labelForCheckbox={t("Show finished courses")}
          />
          <Field
            component={RenderCheckbox}
            name="withRoom"
            under={underClasses}
            labelForCheckbox={t("With room")}
          />
          <Field
            component={RenderCheckbox}
            name="courseDays"
            under={underClasses}
            labelForCheckbox={t("Course days")}
          />
        </div>
      </div>
    );
  }
  render() {
    const {
      calendar,
      t,
      validationErrors,
      values,
      timelineStats: { courseDays, courseStudentDays },
      fetchEmployees,
      employees,
      fetchComment,
      updateComment,
      deleteComment,
      comment,
    } = this.props;
    const {
      isWaitingForData,
      timeline,
      commentData,
      commentPosition,
      displayColumns,
      loading,
    } = this.state;
    let prevMonth, nextMonth;
    if (calendar.currentMonth === 11) {
      prevMonth = 10;
      nextMonth = 0;
    } else if (calendar.currentMonth === 0) {
      prevMonth = 11;
      nextMonth = 1;
    } else {
      prevMonth = calendar.currentMonth - 1;
      nextMonth = calendar.currentMonth + 1;
    }
    const cantGetReport =
      !values.startDate ||
      !values.endDate ||
      (validationErrors &&
        (validationErrors.startDate || validationErrors.endDate));
    const groupAverage =
      courseStudentDays && courseDays
        ? (courseStudentDays / courseDays).toFixed(2)
        : 0;
    const parsedEmployees = employees.map(employee => {
      return {
        id: employee.id,
        display: `${employee.person.name} ${employee.person.surname}`,
      };
    });

    return (
      <div className="main--row timeline">
        <div className="row">
          <div className="col-sm-12">
            <h1>{t("Timeline")}</h1>
          </div>
        </div>
        {this.state.displayComment && (
          <TimeLineComment
            t={t}
            commentData={commentData}
            commentPosition={commentPosition}
            fetchEmployees={fetchEmployees}
            employees={parsedEmployees}
            handleSubmit={this.handleCommentSubmit}
            fetchComment={fetchComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            comment={comment}
            hideComment={this.hideComment}
          />
        )}
        <div className="row">
          <div className="col-md-1 text-right form-group  required">
            <label>{t("Date range")}</label>
          </div>
          <div className="col-md-2">
            <Field
              name="startDate"
              component={RenderDateTime}
              required
              time={false}
              className="custom-cols"
            />
          </div>
          <div className="col-md-2">
            <Field
              name="endDate"
              component={RenderDateTime}
              required
              time={false}
              className="custom-cols"
            />
          </div>
          <div className="col-md-7 timeline__actions">
            <Authorize
              component={
                <button
                  onClick={() => this.getReport(this.handleDownload)}
                  className="btn btn-dark btn-margin"
                  disabled={cantGetReport}
                >
                  {t("Download Timeline")}
                </button>
              }
              allows={[permissions.REPORT_TIMELINE]}
            />
            <Authorize
              component={
                <button
                  onClick={() => this.getReport(this.handleReportDownload)}
                  className="btn btn-dark btn-margin"
                  disabled={cantGetReport}
                >
                  {t("Download Report")}
                </button>
              }
              allows={[permissions.REPORT_FINANCE_XLS]}
            />
            <Authorize
              component={
                <button
                  onClick={() =>
                    this.getReport(this.handleExtendedReportDownload)
                  }
                  className="btn btn-dark btn-margin"
                  disabled={cantGetReport}
                >
                  {t("Download Extended Report")}
                </button>
              }
              allows={[permissions.REPORT_EXTENDED_XLS]}
            />
            {isWaitingForData && (
              <div className="text-loader">
                {t("File is generating")}
                <span className="text-loader__animation" />
              </div>
            )}
          </div>
        </div>
        <div className="row">{this.renderFilters()}</div>
        {timeline ? (
          <div className="calendar">
            <div className="calendar__header">
              <button
                id={`timeLinePrevMonth`}
                type="button"
                className="btn btn-with-icon btn-link"
                onClick={() => {
                  this.prevMonth();
                }}
              >
                <ArrowLeftCircle size={22} />
                <span>
                  {`${t(MONTHS[prevMonth])} ${
                    prevMonth > calendar.currentMonth
                      ? calendar.currentYear - 1
                      : calendar.currentYear
                  }`}
                </span>
              </button>
              <div className="calendar__summary">
                <h3>
                  {`${t(MONTHS[calendar.currentMonth])} ${
                    calendar.currentYear
                  }`}
                </h3>
                <Authorize
                  component={
                    <div className="timeline__summary">
                      <span>
                        {t("course/days")}: <strong>{courseDays}</strong>
                      </span>
                      <span>
                        {t("students/days")}:{" "}
                        <strong>{courseStudentDays}</strong>
                      </span>
                      <span>
                        {t("group's average")}: <strong>{groupAverage}</strong>
                      </span>
                    </div>
                  }
                  allows={[permissions.REPORT_TIMELINE_STATS]}
                />
              </div>
              <button
                id={`timeLineNextMonth`}
                type="button"
                className="btn btn-with-icon btn-link text-right"
                onClick={() => {
                  this.nextMonth();
                }}
              >
                <span>
                  {`${t(MONTHS[nextMonth])} ${
                    nextMonth < calendar.currentMonth
                      ? calendar.currentYear + 1
                      : calendar.currentYear
                  }`}
                </span>
                <ArrowRightCircle size={22} />
              </button>
            </div>
            {loading ? (
              <div className="calendar__content">
                <Loader />
              </div>
            ) : timeline.length === 0 ? (
              <div className="calendar__content">
                <div className="calendar__content__noData">
                  <h3>{t("No data")}</h3>
                </div>
              </div>
            ) : (
              <div className="calendar__content">
                <div className="calendar__content__legend">
                  <table className="calendar__content__legend__header">
                    <thead>
                      <tr>
                        <th>
                          {displayColumns ? (
                            <ChevronsLeft
                              onClick={this.toggleColumns}
                              size={21}
                              color="#4b4b4b"
                            />
                          ) : (
                            <ChevronsRight
                              onClick={this.toggleColumns}
                              size={21}
                              color="#4b4b4b"
                            />
                          )}
                        </th>
                        <th>{t("Signature")}</th>
                        <th className={displayColumns ? "" : "no-display"}>
                          {t("Mentor")}
                        </th>
                        <th className={displayColumns ? "" : "no-display"}>
                          {t("CS")}
                        </th>
                        <th className={displayColumns ? "" : "no-display"}>
                          {t("Start date")}
                        </th>
                        <th className={displayColumns ? "" : "no-display"}>
                          {t("End date")}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    id="legendTable"
                    className="calendar__content__legend__tableData"
                  >
                    <tbody>{this.renderCourses()}</tbody>
                  </table>
                </div>
                <div
                  id="contentData"
                  onScroll={this.changeOffset}
                  className={
                    displayColumns
                      ? "calendar__content__data"
                      : "calendar__content__data calendar__content__data__hidden-columns"
                  }
                >
                  <table
                    id="dataHeader"
                    className="calendar__content__data__header"
                  >
                    <thead>
                      <tr>{this.daysList()}</tr>
                    </thead>
                  </table>
                  <ReactTooltip
                    id="mainComment"
                    multiline={true}
                    place="right"
                  />
                  <table className="calendar__content__data__tableData">
                    <tbody>{this.renderTimeLine()}</tbody>
                  </table>
                  <ReactTooltip
                    id="enrich"
                    delayUpdate={1000}
                    multiline={true}
                    place="left"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default translate()(TimeLine);
