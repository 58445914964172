import React from "react";
import { InjectedTranslateProps } from "react-i18next/src/props";
import InvoiceStatus from "./InvoiceStatus";
import Select from "react-select";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";
import { Invoice } from "../Types/Invoice";

type InvoiceStatusCellProps = InjectedTranslateProps & {
  t;
  invoice: Invoice;
  handleChangeStatus: (transition: {
    name: string;
    to: InvoiceSendStatus;
  }) => void;
};

const InvoiceStatusCell: React.FunctionComponent<InvoiceStatusCellProps> = ({
  t,
  invoice,
  handleChangeStatus,
}): React.ReactElement => {
  const { sendStatusTransitions, sendStatus } = invoice;

  const statusOptions = sendStatusTransitions.map(transition => {
    const [status] = transition.tos;
    return { value: status, label: status };
  });

  const defaultValue = { value: sendStatus, label: sendStatus };

  const getStatusesWithTransitionName = () => {
    return sendStatusTransitions.map(transition => {
      const [status] = transition.tos;
      return { name: transition.name, to: status };
    });
  };

  const handleSelectStatus = (status: InvoiceSendStatus): void => {
    const statusesWithTransitionName = getStatusesWithTransitionName();
    const statusWithTransition = statusesWithTransitionName.find(
      statusWithTransition => statusWithTransition.to === status,
    );

    if (statusWithTransition) {
      handleChangeStatus(statusWithTransition);
    }
  };

  const statusOptionsWithoutInSendStatus = statusOptions.filter(
    statusOption => statusOption.value !== InvoiceSendStatus.IN_SEND,
  );

  return (
    <div>
      {statusOptions.length > 0 ? (
        <Select
          options={statusOptionsWithoutInSendStatus}
          defaultValue={defaultValue}
          onChange={option => handleSelectStatus(option.value)}
          formatOptionLabel={data => {
            return <InvoiceStatus t={t} status={data.value} />;
          }}
        />
      ) : (
        <InvoiceStatus t={t} status={invoice.sendStatus} />
      )}
    </div>
  );
};

export default InvoiceStatusCell;
