import React from "react";
import PropTypes from "prop-types";

const NestedProductElement = props => {
  return (
    <div>
      <span>{props.product.name}</span>
    </div>
  );
};

NestedProductElement.propTypes = { product: PropTypes.object };

export default NestedProductElement;
