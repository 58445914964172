import { formValueSelector, reduxForm } from "redux-form";
import { bindActionCreators, compose } from "redux";
import {
  createPollPackage,
  fetchFilteredPolls,
  fetchPolls,
} from "../../Dictionaries/Actions";
import { fetchContractPackages } from "../../Contracts/Actions";
import { connect } from "react-redux";
import PollsForm, { PollsFormProps } from "../Components/PollsForm";
import { translate } from "react-i18next";
import {
  clearProductVersionModules,
  fetchProductModules,
  fetchProductVersionPolls,
  fetchProductVersionsMeta,
} from "../../Products/Actions";
import { PollsFormType } from "../Types/PollsForm";
import ProductStatuses from "../../../Enums/ProductStatuses";
const FORM_NAME = `PRODUCT_POLLS_FORM`;
const selector = formValueSelector(FORM_NAME);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPolls,
      fetchFilteredPolls,
      fetchContractPackages,
      fetchProductVersionPolls,
      fetchProductVersionsMeta,
      fetchProductModules,
      createPollPackage,
      clearProductVersionModules,
    },
    dispatch,
  );

const mapStateToProps = store => {
  const {
    contractPackages,
    coursePolls,
    fetchProductPolls: productPolls,
    modulePolls,
    productVersionsMeta,
    productModules,
    currentProductVersion,
  } = store;
  const values = selector(store, "additionalPolls", "productModules");

  return {
    contractPackages,
    coursePolls,
    productPolls,
    modulePolls,
    productVersionsMeta,
    productModules,
    values,
    currentStatus:
      currentProductVersion && currentProductVersion.id
        ? currentProductVersion.status
        : { id: ProductStatuses.DRAFT },
  };
};

export default reduxForm<PollsFormType, PollsFormProps>({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  )(PollsForm),
);
