import MomentDateHelper from "./MomentDateHelper";

export default unixDate => {
  const formattedUnixDate = new MomentDateHelper(unixDate).get();
  const maxValue = 10;
  return (
    (formattedUnixDate.date() < maxValue
      ? "0" + formattedUnixDate.date()
      : formattedUnixDate.date()) +
    "/" +
    (formattedUnixDate.month() + 1 < maxValue
      ? "0" + (formattedUnixDate.month() + 1)
      : formattedUnixDate.month() + 1) +
    "/" +
    formattedUnixDate.year()
  );
};
