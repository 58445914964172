import ACTION_TYPES from "../../../Enums/ActionTypes";

export function saveNewCourse(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.SAVE_NEW_COURSE:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function getCourseStatistics(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_COURSE_STATISTICS:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getDatesForCourseSchedule(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.DATES_COURSE_SCHEDULE:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
const initialCourse = {
  numberOfPlaces: 18,
  courseLectures: [],
  courseSchedule: {
    days: [],
    startHour: 0,
    endHour: 0,
    courseModeId: null,
  },
};

export function getCourse(state = initialCourse, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_COURSE:
      return { ...action.payload.data.data };
    case ACTION_TYPES.RESET_COURSE:
      return { ...initialCourse };
    case ACTION_TYPES.EDIT_COURSE:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}
export function searchCourses(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_COURSE:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function fetchCourses(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_COURSES:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getStudentsForCourse(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_STUDENTS_FOR_COURSE:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getLecturersAndRooms(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_LECTURERS_ROOMS_FOR_COURSE:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function fetchQuestionnaires(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_QUESTIONNAIRES:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function setStudentSortOption(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_COURSE_STUDENTS_SORT_OPTION:
      return action.payload;
    default:
      return state;
  }
}

export function fetchTimeline(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TIMELINE:
      return action.payload.data.data;
    default:
      return state;
  }
}
