import React, { useState } from "react";
import { Plus } from "react-feather";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

const AddNotesForm = ({ onAddNote, t }) => {
  const [note, setNote] = useState("");
  const handleChange = event => setNote(event.target.value);

  return (
    <div>
      <div className="row margin__t25">
        <div className="col-xs-9">
          <textarea
            className="form-control"
            value={note}
            onChange={handleChange}
          />
        </div>

        <div className="col-xs-3">
          <button
            className="btn margin__b25"
            onClick={() => onAddNote(note)}
            disabled={note.length < 3}
          >
            <Plus size={18} />
            {`  ${t("Add")}`}
          </button>
        </div>
      </div>
    </div>
  );
};

AddNotesForm.propTypes = {
  t: PropTypes.func,
  onAddNote: PropTypes.func,
};

export default translate()(AddNotesForm);
