export default {
  STANDARD: 0,
  PREWORK: 1,
  SELF_WORK: 2,
  SCRUM_LAB: 3,
  PORTFOLIO_LAB: 4,
  CAREER_LAB: 5,
  MIX: 6,
  POSTWORK: 7,
};
