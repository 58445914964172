import FUNDING_TYPES from "../Enums/FundingTypes";
import PaymentSide from "../Enums/PaymentSide";

export default contractPayers => {
  return (
    Array.isArray(contractPayers) &&
    !!contractPayers.find(
      payer =>
        payer.side.id === PaymentSide.THIRD_PARTY &&
        payer.fundingType &&
        payer.fundingType.id === FUNDING_TYPES.LABOR_OFFICE,
    )
  );
};
