import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import formatPhoneNumber from "../../../Helpers/FormatPhoneNumber";
import dateHelper from "../../../Helpers/DateHelper";
import TIME from "../../../Enums/Time";
import PaymentStatus from "../Utils/PaymentStatus";
import { DropdownList } from "react-widgets";
const GraduatesTable = ({
  data,
  t,
  onClickHeading,
  dictComments,
  sendNpsHandler,
  addCommentHandler,
}) => {
  const commentSelect = cellProps => {
    const {
      value,
      original: { contractId },
    } = cellProps;

    return (
      <div>
        <DropdownList
          value={value}
          onChange={({ id }) => addCommentHandler(contractId, id)}
          placeholder={t("Select comment")}
          data={dictComments}
          textField={"comment"}
          valueField={"id"}
        />
      </div>
    );
  };

  const columns = [
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("City")}
        </div>
      ),
      accessor: "city",
      sortBy: "city",
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("Signature")}
        </div>
      ),
      accessor: "signature",
      sortBy: "signature",
      className: "text--bold",
      width: 130,
      Cell: cellProps => (
        <span>
          <Link to={`/courses/${cellProps.original.courseId}/students`}>
            {cellProps.value}
          </Link>
        </span>
      ),
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("End date")}
        </div>
      ),
      Cell: cellProps => (
        <span>
          {dateHelper(cellProps.value)
            .get()
            .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "endDate",
      sortBy: "end_date",
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("Finish reason")}
        </div>
      ),
      accessor: "finishReason",
      sortBy: "finishReason",
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("Package")}
        </div>
      ),
      Cell: cellProps => (
        <span>{cellProps.value ? cellProps.value : " - "}</span>
      ),
      accessor: "package",
      sortBy: "package",
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("NamePerson")}
        </div>
      ),
      accessor: "name",
      sortBy: "name",
    },
    {
      Header: headProps => (
        <div
          className="group-fill-table__header"
          onClick={() => onClickHeading(headProps.column.sortBy)}
        >
          {t("Surname")}
        </div>
      ),
      accessor: "surname",
      sortBy: "surname",
    },
    {
      Header: () => (
        <div className="group-fill-table__header">{t("Email")}</div>
      ),
      Cell: cellProps => (
        <a href={`mailto:${cellProps.value}`}>{cellProps.value}</a>
      ),
      accessor: "email",
      sortable: false,
    },
    {
      Header: () => (
        <div className="group-fill-table__header">{t("Phone")}</div>
      ),
      Cell: cellProps => (
        <a href={`tel:${cellProps.value}`}>
          {formatPhoneNumber(cellProps.value)}
        </a>
      ),
      accessor: "phone",
      sortable: false,
    },
    {
      Header: () => (
        <div className="group-fill-table__header">{t("Graduate poll")}</div>
      ),
      Cell: cellProps => {
        const {
          value,
          original: { graduateStudyUrl = null },
        } = cellProps;
        switch (Number(value)) {
          case PaymentStatus.DONE:
            return (
              <div className={"nps-status nps-fill-status"}>{t("Filled")}</div>
            );
          case PaymentStatus.NOT_FILLED:
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={"nps-status nps-notFill-status"}
                href={graduateStudyUrl}
              >
                {t("Fill")}
              </a>
            );
        }
      },
      accessor: "graduateStudyStatus",
      sortable: false,
    },
    {
      Header: () => <div className="group-fill-table__header">{t("NPS")}</div>,
      Cell: cellProps => {
        const {
          value,
          original: { npsContractPollId },
        } = cellProps;

        switch (Number(value)) {
          case PaymentStatus.DONE:
            return (
              <div className={"nps-status nps-fill-status"}>{t("Filled")}</div>
            );
          case PaymentStatus.SENT:
            return (
              <div className={"nps-status nps-sent-status"}>{t("Sent")}</div>
            );
          case PaymentStatus.NOT_SENT:
            return (
              <div
                onClick={() => sendNpsHandler(npsContractPollId)}
                className={"nps-status nps-notFill-status"}
              >
                {t("Send")}
              </div>
            );
        }
      },
      accessor: "npsStatus",
      sortable: false,
    },
    {
      Header: () => (
        <div className="group-fill-table__header">{t("Comment")}</div>
      ),
      Cell: commentSelect,
      className: "comment-select-cell",
      accessor: "comment",
      width: 140,
      sortable: false,
    },
  ];

  return (
    <ReactTable
      noDataText={t("No records found")}
      showPagination={false}
      manual
      pageSize={data ? data.length : 1}
      data={data}
      sortable={true}
      columns={columns}
      className="-striped -highlight"
    />
  );
};

GraduatesTable.propTypes = {
  data: PropTypes.array,
  t: PropTypes.func,
  onClickHeading: PropTypes.func,
  addCommentHandler: PropTypes.func,
  dictComments: PropTypes.array,
  sendNpsHandler: PropTypes.func,
};

export default translate()(GraduatesTable);
