import { formValueSelector, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import validate from "../Validators/PromotionsValidator";
import { connect } from "react-redux";
import Promotions from "../Components/Promotions";
import {
  addDictPromotion,
  fetchDictPromotions,
  updateDictPromotion,
} from "../Actions";

const FORM_NAME = "DICTIONARY_PROMOTIONS";

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "dictPromotions");
  let { dictPromotions } = state;
  return {
    values,
    initialValues: { dictPromotions },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictPromotions,
      updateDictPromotion,
      addDictPromotion,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Promotions),
);
