import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "../Validators/AdditionalFeeValidator";
import { priceGrossCalculator } from "../../Products/Actions";
import { addIncome } from "../Actions";
import ManualIncome from "../Components/ManualIncome";
import PAYMENT_METHODS from "../../../Enums/PaymentMethods";

const FORM_NAME = "CONTRACT_MANUAL_INCOME";

let ContractManualIncome = props => <ManualIncome {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state, ownProps) {
  const [defaultCurrency] = ownProps.currencies;
  const values = selector(
    state,
    "amountGross",
    "side",
    "contractId",
    "paymentId",
    "amount",
    "balanceId",
    "invoicingDisabled",
    "paymentType",
  );
  return {
    values,
    defaultCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      priceGrossCalculator,
      addIncome,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {
    invoicingDisabled: true,
    amount: { currency: null },
    paymentType: PAYMENT_METHODS.ONLINE.value,
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractManualIncome),
);
