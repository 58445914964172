export default [
  {
    name: "Signature, ascending",
    value: {
      sortBy: "signature",
      orderBy: "asc",
    },
  },
  {
    name: "Signature, descending",
    value: {
      sortBy: "signature",
      orderBy: "desc",
    },
  },
  {
    name: "Start date, ascending",
    value: {
      sortBy: "dateStart",
      orderBy: "asc",
    },
  },
  {
    name: "Start date, descending",
    value: {
      sortBy: "dateStart",
      orderBy: "desc",
    },
  },
];
