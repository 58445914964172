import React from "react";
import ReactTable from "react-table";
import { translate } from "react-i18next";
import dateHelper from "../../../Helpers/DateHelper";
import WEEK_DAYS from "../../../Enums/WeeksDays";
import { getDayNumber } from "../../../Common/Utils/DateFormatters";
import PARTICIPANTS from "../../../Enums/Participants";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Lecture } from "../../../Types/Lecture";

type CoursesSettingsScheduleProps = InjectedTranslateProps & {
  onRowEdit: (lecture: Lecture) => void;
  lectures: Array<Lecture>;
  isLoading: boolean;
};

const CoursesSettingsSchedule: React.FunctionComponent<
  CoursesSettingsScheduleProps
> = ({ isLoading, onRowEdit, lectures, t }) => {
  const noValue = t("n.d.");

  const isPrework = unitId => unitId === -1;

  const lecturesWithFirstProp = lectures.map((lecture, index) => {
    let firstLectureInModule = true;

    if (index !== 0 && lecture.moduleNr === lectures[index - 1].moduleNr) {
      firstLectureInModule = false;
    }

    return { ...lecture, firstLectureInModule };
  });

  const getTrProps = (state, rowInfo) =>
    rowInfo && rowInfo.original
      ? {
          onClick: () => onRowEdit(rowInfo.original),
          className: `course-schedule__row ${
            rowInfo.original.firstLectureInModule
              ? "course-schedule__row--first-in-module"
              : ""
          }`,
        }
      : {};

  const columns = [
    {
      Header: t("Module"),
      id: "module",
      minWidth: 200,
      Cell: meta => {
        const {
          original: { moduleName },
        } = meta;
        const label = !!moduleName ? moduleName : noValue;
        return <span title={label}>{label}</span>;
      },
    },
    {
      Header: t("Day of the course"),
      id: "courseDay",
      minWidth: 90,
      className: "text-center",
      Cell: meta => {
        const {
          original: { courseDay },
        } = meta;
        return <span>{!!courseDay ? courseDay : noValue}</span>;
      },
    },
    {
      Header: t("Lecture"),
      id: "lecture",
      minWidth: 150,
      Cell: meta => {
        const {
          original: { lectureName },
        } = meta;
        return (
          <span title={lectureName}>
            {!!lectureName ? lectureName : noValue}
          </span>
        );
      },
    },
    {
      Header: t("Lecture's type"),
      id: "type",
      minWidth: 200,
      Cell: meta => {
        const {
          original: { type },
        } = meta;
        const label = !!type ? type.name : noValue;
        return <span title={label}>{label}</span>;
      },
    },
    {
      Header: t("Participants"),
      id: "participants",
      minWidth: 100,
      Cell: meta => {
        const {
          original: { participants, lecturer },
        } = meta;

        return (
          <div>
            {participants &&
              participants.map(participant =>
                participant.id === PARTICIPANTS.student.id ? (
                  <div key={String(participant.id)}>{participant.name}</div>
                ) : (
                  <div key={String(participant.id)}>
                    <div>{participant.name}</div>
                    <div className="badge">
                      {lecturer && (
                        <small>{`${lecturer.name} ${lecturer.surname}`}</small>
                      )}
                    </div>
                  </div>
                ),
              )}
          </div>
        );
      },
    },
    {
      Header: t("Start date"),
      id: "startDate",
      minWidth: 100,
      Cell: meta => {
        const {
          original: { date, startTime, endTime },
        } = meta;
        return (
          <div>
            <div style={{ textAlign: "center" }}>
              {date ? dateHelper(date).print() : `-`}
            </div>
            <div style={{ textAlign: "center" }}>
              {startTime && endTime ? `${startTime} - ${endTime}` : `-`}
            </div>
          </div>
        );
      },
    },
    {
      Header: t("Lecture duration"),
      id: "duration",
      minWidth: 100,
      className: "text-center",
      Cell: meta => {
        const {
          original: { duration },
        } = meta;
        return <span>{!!duration ? `${duration}h` : noValue}</span>;
      },
    },
    {
      Header: t("Day of the week"),
      id: "dayOfWeek",
      className: "text-center",
      minWidth: 130,
      Cell: meta => {
        const {
          original: { date, id },
        } = meta;

        let dayLabel;
        if (!!date && !isPrework(id)) {
          const dayNum = getDayNumber(date) - 1;
          dayLabel = t(WEEK_DAYS.LONG[dayNum]);
        } else {
          dayLabel = noValue;
        }

        return <span>{dayLabel}</span>;
      },
    },
    {
      Header: t("Room"),
      id: "room",
      className: "text-center",
      Cell: meta => {
        const {
          original: { room },
        } = meta;
        return <span>{!!room && room.name ? room.name : noValue}</span>;
      },
      minWidth: 130,
    },
    {
      Header: t("Settlement"),
      id: "settlement",
      className: "text-center",
      minWidth: 80,
      Cell: meta => {
        const {
          original: { settled },
        } = meta;
        return <span>{!!settled ? t("Yes") : t("No")}</span>;
      },
    },
  ];

  return (
    <section id="course-schedule-table" className="form-section">
      <ReactTable
        data={lecturesWithFirstProp}
        columns={columns}
        showPagination={false}
        loading={isLoading}
        noDataText={t("No date for schedule")}
        loadingText={t("Creating schedule...")}
        sortable={false}
        className="course-schedule custom-react-table"
        pageSize={lectures.length}
        getTrProps={getTrProps}
      />
    </section>
  );
};

export default translate()(CoursesSettingsSchedule);
