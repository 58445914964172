import React from "react";
import PropTypes from "prop-types";

const NestedModulesElement = props => {
  return (
    <div>
      <span>{props.name}</span>
    </div>
  );
};

NestedModulesElement.propTypes = { name: PropTypes.string };

export default NestedModulesElement;
