import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function currentCourseReducer(state = coursesInitState.course, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_CURRENT_COURSE:
      return { ...action.payload.data.data };

    case ACTION_TYPES.REFRESH_CURRENT_COURSE:
      return { ...action.payload.data.data };

    case ACTION_TYPES.RESET_CURRENT_COURSE:
      return { ...coursesInitState.course };

    default:
      return state;
  }
}
