import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import {
  ProductComponentProps,
  ProductComponentState,
} from "../Types/ProductComponentTypes";
import ProductTabs from "./ProductTabs";
import ProductStatus from "../../Products/Components/ProductStatus";
import EventsList from "../../Products/Containers/EventsList";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import { get } from "lodash";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import ProductForm from "../Containers/ProductFormContaner";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import ProductFormHistory from "../../Products/Components/ProductFormHistory";
import Loader from "../../../Common/Components/Loader/Loader";
import ModulesForm from "../Containers/ModulesContainer";
import PollsForm from "../Containers/PollsContainer";

class Product extends Component<ProductComponentProps, ProductComponentState> {
  private cancelToken = getCancelToken();

  state = {
    activeTabIndex: 0,
    isLoadingDictionaries: false,
    isLoadingProduct: false,
  };

  componentDidMount(): void {
    const {
      isNew,
      fetchProductById,
      versionId,
      fetchCourseModes,
      fetchEmployees,
    } = this.props;

    fetchCourseModes(this.cancelToken);
    fetchEmployees(this.cancelToken);
    this.fetchAllDictionaries();

    if (!isNew && versionId) {
      this.setProductIsLoading();
      fetchProductById(this.cancelToken, versionId, this.productIsLoaded);
    }
  }

  componentWillUnmount() {
    const { resetProduct } = this.props;
    resetProduct();
  }

  fetchAllDictionaries = () => {
    const { fetchDictionary } = this.props;
    const {
      FETCH_LANGUAGES,
      FETCH_PRODUCTS_KINDS,
      FETCH_CURRENCIES,
      FETCH_INSTALLMENTS,
    } = ACTION_TYPES;

    this.setDictionariesIsLoading();

    const languagesDictFetch = fetchDictionary(this.cancelToken, {
      path: "/languages",
      actionType: FETCH_LANGUAGES,
    });
    const kindDictFetch = fetchDictionary(this.cancelToken, {
      path: "/product/kinds",
      actionType: FETCH_PRODUCTS_KINDS,
    });
    const currenciesDictFetch = fetchDictionary(this.cancelToken, {
      path: "/currencies",
      actionType: FETCH_CURRENCIES,
    });
    const installmentsDictFetch = fetchDictionary(this.cancelToken, {
      path: "/installments",
      actionType: FETCH_INSTALLMENTS,
    });

    Promise.all([
      languagesDictFetch,
      kindDictFetch,
      currenciesDictFetch,
      installmentsDictFetch,
    ]).then(this.dictionariesIsLoaded);
  };

  componentDidUpdate(prevProps: Readonly<ProductComponentProps>) {
    const { currentProductVersion, fetchVersionsOfProduct } = this.props;
    const { currentProductVersion: prevProductVersion } = prevProps;
    const productId = get(currentProductVersion, "product.id");
    const prevProductId = get(prevProductVersion, "product.id");

    if (productId && prevProductId !== productId) {
      fetchVersionsOfProduct(this.cancelToken, productId);
    }
  }

  handleTabClick = (tabIndex: number) =>
    this.setState({ activeTabIndex: tabIndex });

  displayProductFormAndHistory = () => {
    return this.state.activeTabIndex === 0;
  };

  displayProductModules = () => {
    return this.state.activeTabIndex === 1;
  };

  displayPolls = () => {
    return this.state.activeTabIndex === 2;
  };

  displayEventsList = () => {
    return this.state.activeTabIndex === 3;
  };

  setProductIsLoading = (): void => this.setState({ isLoadingProduct: true });
  productIsLoaded = (): void => this.setState({ isLoadingProduct: false });

  setDictionariesIsLoading = (): void =>
    this.setState({ isLoadingDictionaries: true });
  dictionariesIsLoaded = (): void =>
    this.setState({ isLoadingDictionaries: false });

  fetchVersionsOfProductHandler = (callback = () => {}) => {
    const { fetchVersionsOfProduct, currentProductVersion } = this.props;
    const productId = get(currentProductVersion, "product.id");
    if (productId) {
      fetchVersionsOfProduct(this.cancelToken, productId, callback);
    }
  };

  fetchProductHandler = () => {
    const { fetchProductById, versionId } = this.props;

    if (versionId) {
      fetchProductById(this.cancelToken, versionId);
    }
  };

  makeProductCopy = versionId => {
    const { copyVersionProduct } = this.props;
    copyVersionProduct(versionId, () => {
      this.fetchVersionsOfProductHandler();
    });
  };

  render() {
    const { isLoadingProduct, isLoadingDictionaries } = this.state;
    const isLoading = isLoadingProduct || isLoadingDictionaries;
    const {
      isNew,
      t,
      currentProductVersion,
      versionId,
      courseModes,
      employees,
      employeeId,
      currentProductVersions,
      lastVersionOfProduct,
      changeProductVersionStatus,
      deleteProductVersion,
      saveProductVersion,
    } = this.props;
    const { activeTabIndex } = this.state;
    const productStatus = get(currentProductVersion, "status.id");
    const cantEdit = !havePermissions([permissions.PRODUCT_DETAILS_EDIT]);
    const hasActiveVersion = this.props.currentProductVersions.find(
      product => product.status.id === PRODUCT_STATUSES.ACTIVE,
    );

    return isLoading ? (
      <Loader />
    ) : (
      <section id="product">
        <div className="custom-container">
          <header className="main-header">
            <h1>{isNew ? t("New product") : currentProductVersion.name}</h1>
            {currentProductVersion.id && (
              <ProductStatus
                status={currentProductVersion.status}
                version={currentProductVersion.version}
              />
            )}
          </header>
        </div>
        <ProductTabs
          t={t}
          isNew={isNew}
          activeTab={activeTabIndex}
          onTabClick={this.handleTabClick}
        />

        <div className="row">
          <div className=" content col-xs-12">
            <div className="custom-container">
              <div className="row ">
                <div
                  className={
                    this.displayProductFormAndHistory()
                      ? "col-md-8 col-lg-9"
                      : "col-md-12 col-lg-12"
                  }
                >
                  {this.displayProductFormAndHistory() && (
                    <ProductForm
                      isNew={isNew}
                      hasActiveVersion={hasActiveVersion}
                      makeProductCopy={this.makeProductCopy}
                      fetchProduct={this.fetchProductHandler}
                      fetchVersionsOfProductHandler={
                        this.fetchVersionsOfProductHandler
                      }
                    />
                  )}

                  {this.displayProductModules() && <ModulesForm />}

                  {this.displayPolls() && <PollsForm />}

                  {this.displayEventsList() && (
                    <EventsList
                      versionId={versionId}
                      courseModes={courseModes}
                      disabled={productStatus !== PRODUCT_STATUSES.DRAFT}
                    />
                  )}
                </div>
                <div className="col-md-4 col-lg-3">
                  <ProductFormHistory
                    shouldRender={!isNew && this.displayProductFormAndHistory()}
                    cantEdit={cantEdit}
                    employees={employees}
                    employeeId={employeeId}
                    makeProductCopy={this.makeProductCopy}
                    productVersionId={versionId}
                    lastVersionOfProduct={lastVersionOfProduct}
                    currentProductVersions={currentProductVersions}
                    currentProductVersion={currentProductVersion}
                    changeProductVersionStatus={changeProductVersionStatus}
                    deleteProductVersion={deleteProductVersion}
                    saveProductVersion={saveProductVersion}
                    fetchVersionsOfProduct={this.fetchVersionsOfProductHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Product;
