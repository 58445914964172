import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import Loader from "../../../Common/Components/Loader/Loader";
import ReactTable from "react-table";
import { Check } from "react-feather";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import { RenderText } from "../../../Forms/forms";
import PropTypes from "prop-types";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import ACTION_TYPES from "../../../Enums/ActionTypes";

class CoursesKinds extends Component {
  static propTypes = {
    fetchDictionary: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    updateDictionary: PropTypes.func,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchDictionary } = this.props;

    this.state = { coursesKinds: [] };

    fetchDictionary(this.cancelToken, {
      path: "/product/kinds",
      actionType: ACTION_TYPES.FETCH_PRODUCTS_KINDS,
    });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      initialValues: { coursesKinds },
      initialize,
    } = this.props;

    if (coursesKinds !== nextProps.initialValues.coursesKinds) {
      this.setState({ coursesKinds: nextProps.initialValues.coursesKinds });
      initialize(nextProps.initialValues);
    }
  }

  enableEditMode(row) {
    const { coursesKinds } = this.state;

    !coursesKinds[row.index].editMode
      ? (coursesKinds[row.index].editMode = [])
      : null;
    !coursesKinds[row.index].editMode.includes(row.column.id) &&
      coursesKinds[row.index].editMode.push(row.column.id);

    this.setState({ coursesKinds });
  }

  renderEditField = row => {
    const { coursesKinds } = this.state;
    const value = getNestedObject(this.props.values[row.index], row.column.id);

    return (
      <div className="editField">
        {coursesKinds[row.index] &&
        coursesKinds[row.index].editMode &&
        coursesKinds[row.index].editMode.indexOf(row.column.id) >= 0 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`coursesKinds[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
            <div
              onClick={() =>
                this.updateKindsDictionary(row.index, row.column.id)
              }
              className="dictionary-text__button"
            >
              <Check size={23} />
            </div>
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  updateKindsDictionary = (index, kindId) => {
    const {
      values,
      initialValues,
      updateDictionary,
      fetchDictionary,
    } = this.props;
    const { coursesKinds } = this.state;
    const arraySpot = coursesKinds[index].editMode.findIndex(
      element => element === kindId,
    );

    coursesKinds[index].editMode.splice(arraySpot, 1);
    this.setState({ coursesKinds });

    if (
      values &&
      values[index][kindId] !== initialValues.coursesKinds[index][kindId]
    ) {
      const updates = {
        active: values[index].active,
        name: values[index].name,
      };

      updateDictionary(`/product/kinds/${values[index].id}`, updates, () => {
        fetchDictionary(this.cancelToken, {
          path: "/product/kinds",
          actionType: ACTION_TYPES.FETCH_PRODUCTS_KINDS,
        });
      });
    }
  };

  render() {
    const {
      initialValues: { coursesKinds },
      values,
      t,
    } = this.props;

    return coursesKinds.length === 0 ? (
      <Loader />
    ) : (
      <div className="fade-in">
        <ReactTable
          noDataText={t("No records found")}
          data={values}
          columns={[
            {
              Header: t("No."),
              accessor: "id",
              Cell: row => <span>{row.index + 1}.</span>,
              resizable: false,
              width: 50,
            },
            {
              Header: t("NameItem"),
              accessor: "name",
              Cell: this.renderEditField,
            },
          ]}
          defaultPageSize={10}
          showPagination={true}
        />
      </div>
    );
  }
}

export default translate()(CoursesKinds);
