import React, { Component } from "react";
import PropTypes from "prop-types";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { Edit, Trash, Save, X } from "react-feather";
import dateHelper from "../../../Helpers/DateHelper";
import TIME from "../../../Enums/Time";
import ContractNoteSource from "../../../Enums/ContractNoteSource";

class NoteEditModal extends Component {
  cancelToken = getCancelToken();

  state = {
    newNoteContent: "",
    editNoteId: null,
    editNoteContent: "",
  };

  componentDidMount() {
    const { fetchContractPaymentsNotes, contractId } = this.props;

    fetchContractPaymentsNotes(
      this.cancelToken,
      contractId,
      ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    );
  }

  onChangeNote = event => this.setState({ newNoteContent: event.target.value });

  onChangeEditNoteInput = event =>
    this.setState({ editNoteContent: event.target.value });

  addNewNote = async () => {
    const {
      fetchContractPaymentsNotes,
      addContractPaymentsNote,
      contractId,
    } = this.props;
    const { newNoteContent } = this.state;
    const payload = {
      note: newNoteContent,
      source: ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    };

    await addContractPaymentsNote(this.cancelToken, contractId, payload);
    await fetchContractPaymentsNotes(
      this.cancelToken,
      contractId,
      ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    );
    this.setState({ newNoteContent: "" });
  };

  removeNote = async noteId => {
    const {
      fetchContractPaymentsNotes,
      removeContractPaymentsNote,
      contractId,
    } = this.props;

    await removeContractPaymentsNote(this.cancelToken, noteId);
    await fetchContractPaymentsNotes(
      this.cancelToken,
      contractId,
      ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    );
  };

  editNote = note => {
    this.setState({ editNoteContent: note.note, editNoteId: note.id });
  };

  onCloseNoteEdit = () => {
    this.setState({ editNoteId: null, editNoteContent: "" });
  };

  saveEditedNote = async () => {
    const {
      fetchContractPaymentsNotes,
      editContractPaymentsNote,
      contractId,
    } = this.props;
    const { editNoteContent, editNoteId } = this.state;
    const payload = {
      note: editNoteContent,
      source: ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    };

    await editContractPaymentsNote(editNoteId, payload);
    await fetchContractPaymentsNotes(
      this.cancelToken,
      contractId,
      ContractNoteSource.CONTRACT_PAYMENTS_REPORT,
    );
    this.onCloseNoteEdit();
  };

  render() {
    const { notes, t } = this.props;
    const { newNoteContent, editNoteId, editNoteContent } = this.state;
    return (
      <div className="padding__l30 padding__r30">
        <div className="margin__b25">
          <label>{t("Add new note")}</label>
          <div>
            <input
              className="margin__r15 width__50percent"
              name="note"
              value={newNoteContent}
              onChange={this.onChangeNote}
            />
            <button disabled={!newNoteContent} onClick={this.addNewNote}>
              {t("Add")}
            </button>
          </div>
        </div>
        <div className="padding__t20 padding__b20">
          {notes.map((note, index) => (
            <div className="padding__b10 flex small" key={`note-${note.id}`}>
              {editNoteId === note.id ? (
                <React.Fragment>
                  <input
                    className="margin__r15 width__50percent"
                    onChange={this.onChangeEditNoteInput}
                    value={editNoteContent}
                  />
                  <div>
                    <Save
                      onClick={this.saveEditedNote}
                      className="black-link margin__r15 cursor__pointer"
                      size={16}
                    />
                    <X
                      className="black-link cursor__pointer"
                      size={16}
                      onClick={() => this.onCloseNoteEdit(note.id)}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="margin__r15 width__50percent">{`${notes.length -
                    index}. ${note.note} - ${dateHelper(note.createdAt)
                    .get()
                    .format(TIME.DATE_FORMAT)} - ${note.createdBy}`}</div>
                  <div>
                    <Edit
                      onClick={() => this.editNote(note)}
                      className="black-link margin__r15 cursor__pointer"
                      size={16}
                    />
                    <Trash
                      className="black-link cursor__pointer"
                      size={16}
                      onClick={() => this.removeNote(note.id)}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

NoteEditModal.propTypes = {
  notes: PropTypes.array,
  contractId: PropTypes.string,
  fetchContractPaymentsNotes: PropTypes.func,
  addContractPaymentsNote: PropTypes.func,
  removeContractPaymentsNote: PropTypes.func,
  editContractPaymentsNote: PropTypes.func,
  t: PropTypes.func,
};

export default translate()(NoteEditModal);
