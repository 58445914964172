import React, { useEffect } from "react";
import { Search } from "react-feather";
import CustomIcon from "../../../../Common/Components/CustomIcon/CustomIcon";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Field, Form, Formik, FormikProps } from "formik";
import { RenderCheckbox } from "../../../../Forms/InputComponents/Checkbox";
import { RenderSelect } from "../../../../Forms/InputComponents/Select";
import { RenderDateTime } from "../../../../Forms/InputComponents/DateTime";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";

export type FiltersValues = {
  query?: string;
  startDate?: number;
  endDate?: number;
  deletedOnly?: boolean;
  status?: { name: string; translation: string };
  sendStatus?: string;
};

type FiltersProps = InjectedTranslateProps & {
  handleOpenInvoiceConfirmation: () => void;
  handleDownloadInvoicesXls: (values: FiltersValues) => void;
  handleDownloadInvoicesZip: (values: FiltersValues) => void;
  onChangeFilters: (values: FiltersValues) => void;
  invoiceStatusPossiblePlaces: Array<{ name: string; translation: string }>;
  invoiceSendStatusPossiblePlaces: InvoiceSendStatus;
};

const InvoicesListActions: React.FunctionComponent<FiltersProps> = ({
  t,
  handleOpenInvoiceConfirmation,
  onChangeFilters,
  handleDownloadInvoicesZip,
  handleDownloadInvoicesXls,
  invoiceStatusPossiblePlaces,
  invoiceSendStatusPossiblePlaces,
}): React.ReactElement => {
  const ref = React.useRef<HTMLDivElement>();
  const containerRef = React.useRef<HTMLDivElement>();

  const handleScroll = () => {
    const { current } = ref;
    const { current: containerCurrent } = containerRef;
    const fixedClass = "invoices-list-actions-and-filters-fixed";
    const fixedClassTable = "invoices-list-header-fixed";

    if (current && containerCurrent) {
      const { top } = containerCurrent.getBoundingClientRect();
      const tableHeader = document.querySelector(
        ".rt-table > div.rt-thead.-header",
      );

      if (top < 0) {
        if (!current.classList.contains(fixedClass)) {
          current.classList.add(fixedClass);
          tableHeader.classList.add(fixedClassTable);
        }
      } else {
        current.classList.remove(fixedClass);
        tableHeader.classList.remove(fixedClassTable);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const downloadInvoiceZip = (props: FormikProps<FiltersValues>): void => {
    const { values, setFieldError } = props;
    const { startDate, endDate } = values;

    if (!startDate) {
      setFieldError("startDate", t("Add dates range"));
    }

    if (!endDate) {
      setFieldError("endDate", t("Add dates range"));
    }

    if (startDate && endDate) {
      handleDownloadInvoicesZip(values);
    }
  };
  return (
    <div style={{ height: "110px" }}>
      <div ref={containerRef} className="row">
        <div ref={ref} className="padding__b20 col-xs-12">
          <div className="row">
            <div className="col-xs-10 padding__l0 padding__r0">
              <div className={"col-xs-3 col-xs-offset-2 form-group"}>
                <label>{t("Date range by issue date")}</label>
              </div>
            </div>
          </div>
          <div id="invoices-list-actions-and-filters" className="row">
            <Formik
              initialValues={{}}
              onSubmit={handleDownloadInvoicesZip}
              validate={onChangeFilters}
            >
              {formProps => (
                <Form>
                  <div className="col-xs-10 padding__l0 padding__r0">
                    <div className="form-group col-xs-2 padding__l0">
                      <div className="input-group ">
                        <div className="input-group-addon">
                          <Search size={16} />
                        </div>
                        <Field
                          className="form-control"
                          name="query"
                          component="input"
                          type="text"
                          placeholder={t(`Invoice number or client name`)}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2 col-xs-offset-0">
                      <div className="row">
                        <Field
                          name="startDate"
                          component={RenderDateTime}
                          under={{
                            containerClassName: "col-xs-12",
                            fieldClassName: "col-xs-12",
                          }}
                          onClearCallback={() =>
                            formProps.setFieldValue("startDate", null)
                          }
                          time={false}
                        />
                        {(formProps.errors.startDate ||
                          formProps.errors.endDate) && (
                          <div className="warning-alert__small--red font-small col-xs-12">
                            {t("Add dates range")}
                          </div>
                        )}
                      </div>
                    </div>

                    <Field
                      name="endDate"
                      component={RenderDateTime}
                      min={formProps.values.startDate}
                      under={{
                        containerClassName: "col-xs-2 col-xs-offset-0",
                        fieldClassName: "col-xs-12",
                      }}
                      onClearCallback={() =>
                        formProps.setFieldValue("endDate", null)
                      }
                      time={false}
                    />

                    <Field
                      name="status"
                      component={RenderSelect}
                      dropdownConfig={{
                        data: invoiceStatusPossiblePlaces,
                        textField: "translation",
                        valueField: "name",
                      }}
                      onClearCallback={() =>
                        formProps.setFieldValue("status", null)
                      }
                      under={{
                        containerClassName: "col-xs-2 col-xs-offset-0",
                        fieldClassName: "col-xs-12",
                      }}
                    />

                    <Field
                      name="sendStatus"
                      component={RenderSelect}
                      dropdownConfig={{
                        data: invoiceSendStatusPossiblePlaces,
                        textField: value => t(value),
                        valueField: value => value,
                      }}
                      onClearCallback={() =>
                        formProps.setFieldValue("sendStatus", null)
                      }
                      under={{
                        containerClassName: "col-xs-2 col-xs-offset-0",
                        fieldClassName: "col-xs-12",
                      }}
                    />
                    <Field
                      name="deletedOnly"
                      labelForCheckbox={t("Show deleted only")}
                      component={RenderCheckbox}
                      under={{
                        containerClassName: "col-xs-2 col-xs-offset-0",
                        fieldClassName: "col-xs-12",
                      }}
                    />
                  </div>

                  <div className="col-xs-2">
                    <div id="invoicesDownloadXls">
                      <CustomIcon
                        icon="xls"
                        color="#4d4d4f"
                        size="40px"
                        viewBox="0 0 512 512"
                        className="pull-right"
                        onClick={() =>
                          handleDownloadInvoicesXls(formProps.values)
                        }
                      />
                    </div>
                    <div id="invoicesDownloadZip">
                      <CustomIcon
                        icon="zip"
                        color="#4d4d4f"
                        size="40px"
                        viewBox="0 0 512 512"
                        className="pull-right"
                        onClick={() => downloadInvoiceZip(formProps)}
                      />
                    </div>
                    <div id="sendInvoices">
                      <CustomIcon
                        icon="send"
                        color="#4d4d4f"
                        size="40px"
                        viewBox=""
                        className="pull-right"
                        onClick={handleOpenInvoiceConfirmation}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesListActions;
