export const payerMapper = (payers, contractCurrency) => {
  const [firstPayer] = payers;
  const isPercent = firstPayer.part !== null;

  return payers.map(payer => {
    const partInPercent = isPercent ? (payer.part ? payer.part : 0) : null;
    const partInAmount = !isPercent
      ? {
          amount: _.get(payer, "partAmount.amount", 0),
          currency: contractCurrency,
        }
      : null;

    return {
      balanceId: payer.balance.id,
      part: partInPercent,
      partAmount: partInAmount,
      bankAccount: payer.bankAccount,
      taxRate: payer.taxRate,
      side: payer.side.id,
      fundingType: payer.fundingType ? payer.fundingType.id : null,
    };
  });
};
