import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

const FormattedPollTimeframe = ({ days, ruleEvent, t }) => {
  return (
    <h6>
      {days > 0
        ? `${days} ${t(ruleEvent)}`
        : days < 0
        ? `${days} ${t(`${ruleEvent}_negative`)}`
        : t(`at_the_${ruleEvent}`)}
    </h6>
  );
};

FormattedPollTimeframe.propTypes = {
  days: PropTypes.number,
  ruleEvent: PropTypes.string,
  t: PropTypes.func,
};

export default translate()(FormattedPollTimeframe);
