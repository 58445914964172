import moment from "moment";
import TIME from "../Enums/Time";

class MomentDateHelper {
  constructor(date) {
    this.date = moment(date).utc();
    this.date.add(moment(date).utcOffset(), "minutes");
  }

  getUTC(date) {
    return this.date.add(moment(date).utcOffset() * -2, "minutes");
  }

  get() {
    return this.date;
  }

  getFormatted() {
    return this.date.valueOf();
  }

  isFirstDateLater(firstDate, secondDate) {
    const firstDateObject = moment(firstDate).toObject();
    const secondDateObject = moment(secondDate).toObject();
    return (
      firstDateObject.years >= secondDateObject.years &&
      firstDateObject.months >= secondDateObject.months &&
      firstDateObject.date >= secondDateObject.date
    );
  }
  print(dateFormat = TIME.DATE_FORMAT) {
    return this.date.format(dateFormat);
  }
}

export default MomentDateHelper;
