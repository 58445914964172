import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Form, Field } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import { Features, Tenant } from "../../../Common/Utils/Tenant";

class DeleteIncomeForm extends Component {
  state = { isSubmitting: false };

  onSubmit = ({ fk }) => {
    const { onSubmit } = this.props;
    this.setState({ isSubmitting: true }, () => onSubmit(fk));
  };

  render() {
    const { t, handleSubmit, onClose } = this.props;

    const { isSubmitting } = this.state;

    return (
      <div>
        <Form onSubmit={handleSubmit(this.onSubmit)} id="delete-income-form">
          <Tenant
            component={
              <Field
                under
                component={RenderText}
                label={t("Correction's number")}
                name="fk"
              />
            }
            feature={Features.invoices}
          />
          <div className={`margin__t25`}>
            <button
              className={`btn btn-dark margin__r15`}
              disabled={isSubmitting}
              onClick={handleSubmit(this.onSubmit)}
            >
              {t("Delete")}
            </button>
            <button className={`btn`} onClick={onClose}>
              {t("Cancel")}
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

DeleteIncomeForm.propTypes = {
  t: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  income: PropTypes.object,
};

export default translate()(DeleteIncomeForm);
