import React, { Component } from "react";
import Loader from "../../../Common/Components/Loader/Loader";
import { RenderText } from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import PropTypes from "prop-types";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import EditDictionaryField from "../Helpers/EditDictionaryField";

class PollTypesDictionary extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      pollTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ).isRequired,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }).isRequired,
    ),
    initialize: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    change: PropTypes.func,
    fetchDictionary: PropTypes.func.isRequired,
    updateDictionary: PropTypes.func.isRequired,
    createDictionaryItem: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.formActions = {
      SAVE: "SAVE",
      CREATE: "CREATE",
    };
    const { FETCH_POLL_TYPES } = ACTION_TYPES;
    const { fetchDictionary } = this.props;
    this.state = {
      editing: [],
      pollsLoading: true,
    };
    fetchDictionary(
      this.cancelToken,
      {
        path: "/poll/type",
        actionType: FETCH_POLL_TYPES,
      },
      () => {
        this.setState({ pollsLoading: false });
      },
    );
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, values } = this.props;
    if (initialValues.pollTypes !== prevProps.initialValues.pollTypes) {
      initialize(initialValues);
    } else if (
      prevProps.values &&
      values.pollTypes !== prevProps.values.pollTypes
    ) {
      initialize(values);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;
    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }
  onSubmit = values => {
    const pollValues = values.pollTypes.find(
      pollType => pollType.id === this.state.rowId,
    );
    const {
      createDictionaryItem,
      updateDictionary,
      fetchDictionary,
    } = this.props;
    const { FETCH_POLL_TYPES, CREATE_POLL_TYPE } = ACTION_TYPES;
    switch (this.state.action) {
      case this.formActions.SAVE:
        updateDictionary(`/poll/type/${pollValues.id}`, pollValues, () => {
          fetchDictionary(
            this.cancelToken,
            {
              path: "/poll/type",
              actionType: FETCH_POLL_TYPES,
            },
            () => {
              const { editing } = this.state;
              const rowIndex = editing.indexOf(pollValues.id);
              rowIndex >= 0 && editing.splice(rowIndex, 1);
              this.setState({
                pollsLoading: false,
                editing,
              });
            },
          );
        });
        break;
      case this.formActions.CREATE:
        createDictionaryItem("/poll/type", CREATE_POLL_TYPE, pollValues, () => {
          fetchDictionary(
            this.cancelToken,
            {
              path: "/poll/type",
              actionType: FETCH_POLL_TYPES,
            },
            () => {
              this.setState({
                pollsLoading: false,
              });
            },
          );
        });
        break;
    }
  };

  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    this.setState(
      {
        action,
        rowId,
      },
      () => {
        const submitter = handleSubmit(this.onSubmit);
        submitter();
      },
    );
  };
  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`pollTypes[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  render() {
    const { t, values } = this.props;
    const { pollsLoading } = this.state;
    return values ? (
      <div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={pollsLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("NameItem"),
                accessor: "name",
                Cell: this.renderEditField,
              },
              {
                Header: t("Prefix"),
                accessor: "prefix",
              },
              {
                Header: t("Code"),
                accessor: "code",
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(this.formActions.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(this.formActions.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(PollTypesDictionary);
