import React, { Component } from "react";
import { renderFieldsHelper } from "../../../Forms/forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

class SelectWithPagination extends Component {
  static propTypes = {
    fetchData: PropTypes.func,
    dataList: PropTypes.array,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.string,
    onClearCallback: PropTypes.func,
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func,
    input: PropTypes.object,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    renderMenuItemChildren: PropTypes.func,
    filterBy: PropTypes.func,
    labelKey: PropTypes.string,
    id: PropTypes.string,
    valueKey: PropTypes.string,
    customClasses: PropTypes.string,
    t: PropTypes.func,
    fetchDataOptions: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.PAGE_NO = 1;
    this.PAGE_AMOUNT = 10;

    this.state = {
      page_no: 1,
      selectedPayer: 0,
      selected: props.input.value ? [props.input.value] : [],
      dataList: props.dataList || [],
    };
    const { fetchData, fetchDataOptions } = this.props;
    fetchData(
      this.cancelToken,
      {
        ...fetchDataOptions,
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
      },
      result => {
        this.setState({
          dataList: _.uniqBy(
            [...this.state.dataList, ...result.data.data.items],
            "id",
          ),
        });
      },
    );
  }

  componentDidUpdate(prevProps) {
    this.shouldDataListUpdate(prevProps);
    this.shouldValueUpdate(prevProps);
  }

  shouldDataListUpdate = prevProps => {
    const { options, input } = this.props;
    if (
      prevProps.options !== options &&
      !_.isEqual(options, this.state.dataList)
    ) {
      this.setState(
        {
          dataList: _.uniqBy([...this.state.dataList, ...options], "id"),
        },
        () => {
          this.setDefaultValue(input.value);
        },
      );
    }
  };
  shouldValueUpdate = prevProps => {
    const { input } = this.props;
    if (
      _.has(this.props, "input.value") &&
      !_.isEqual(prevProps.input.value, input.value)
    ) {
      this.setDefaultValue(input.value);
    }
  };

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  setDefaultValue = value => {
    if (_.has(this.props, "multiple") && multiple) {
      this.selectMultiple(value);
    } else {
      this.selectSingle(value);
    }
  };

  /*
   * array value
   * */
  selectMultiple = value => {
    const { dataList } = this.state;
    this.setState({
      selected: dataList.filter(
        dataListItem => value.indexOf(dataListItem.id) >= 0,
      ),
    });
  };

  /*
   * int value
   * */
  selectSingle = value => {
    const { dataList } = this.state;
    const { valueKey } = this.props;
    if (
      value &&
      this.state.selectedItem !== value &&
      dataList &&
      dataList.length > 0
    ) {
      const dataListItem = dataList.find(item => {
        if (valueKey && valueKey.length > 0) {
          return item[valueKey] === value;
        }
        return item.id === value;
      });
      if (dataListItem) {
        this.setState({
          selected: [dataListItem],
          selectedItem: value,
        });
      }
    } else if (
      !this.state.selectedItem ||
      !value ||
      !dataList ||
      dataList.length === 0
    ) {
      this.setState({
        selected: [],
        selectedItem: null,
      });
    }
  };

  loadData = result => {
    this.setState({
      dataList: _.uniqBy(
        [...this.state.dataList, ...result.data.data.items],
        "id",
      ),
    });
    if (this.props.input.value) {
      this.setDefaultValue(this.props.input.value);
    }
  };

  onSearchHandler = query => {
    this.setState({ query });
    const { fetchData, fetchDataOptions } = this.props;
    fetchData(
      this.cancelToken,
      {
        ...fetchDataOptions,
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
        search: query,
      },
      this.loadData,
    );
  };

  onInputChangeHandler = query => {
    const { fetchData, fetchDataOptions } = this.props;
    this.setState({ selected: [] }, () => {
      query.length === 0 &&
        fetchData(
          this.cancelToken,
          {
            ...fetchDataOptions,
            pageNo: this.state.page_no,
            pageAmount: this.PAGE_AMOUNT,
            search: query,
          },
          this.loadData,
        );
    });
  };

  onPaginateHandler = () => {
    const { fetchData, fetchDataOptions } = this.props;
    this.setState({ page_no: this.state.page_no + 1 }, () => {
      fetchData(
        this.cancelToken,
        {
          ...fetchDataOptions,
          pageNo: this.state.page_no,
          pageAmount: this.PAGE_AMOUNT,
        },
        this.loadData,
      );
    });
  };

  render() {
    const {
      classes,
      displayError,
      shouldDisabled,
      touched,
      error,
    } = renderFieldsHelper(this.props);
    const isMultiple =
      this.props.multiple === undefined ? false : this.props.multiple;
    const {
      under,
      label,
      onClearCallback,
      input,
      renderMenuItemChildren,
      filterBy,
      labelKey,
      onChangeHandler,
      placeholder,
      customClasses,
      t,
    } = this.props;
    const { dataList } = this.state;
    return (
      <div
        className={`form-group selectWithPagination ${
          touched && displayError ? classes : ""
        } ${under ? under.containerClassName : "row"} ${customClasses}`}
      >
        <div>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={this.props.id || "selectWithPagination"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={selected => {
                  if (!onChangeHandler) {
                    return;
                  }
                  if (isMultiple) {
                    return onChangeHandler(selected, null, input.name);
                  }
                  if (selected[0]) {
                    return onChangeHandler(selected[0], null, input.name);
                  }
                }}
                options={dataList}
                labelKey={labelKey}
                renderMenuItemChildren={renderMenuItemChildren}
                filterBy={filterBy}
                minLength={0}
                maxResults={9}
                paginate={true}
                placeholder={placeholder}
                paginationText="Pokaż więcej wyników"
                onPaginate={this.onPaginateHandler}
                disabled={shouldDisabled}
                multiple={isMultiple}
              />
              <span aria-hidden="true" className="rw-i rw-i-caret-down" />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError && <span className="help-block">{t(error)}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(SelectWithPagination);
