import React from "react";
import PropTypes from "prop-types";
import GRADES from "../../../Enums/Grades";

const PollGrade = ({ data }) => {
  const grade = data ? Number(data) : "–";
  let classes = "";
  if (typeof grade === "number") {
    classes =
      grade <= GRADES.NEGATIVE
        ? "text-danger"
        : grade >= GRADES.POSITIVE
        ? "text-success"
        : "";
  }
  return (
    <td className={classes}>
      <strong>{grade}</strong>
    </td>
  );
};

PollGrade.propTypes = {
  data: PropTypes.string,
};

export default PollGrade;
