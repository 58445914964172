import i18n from "i18next";
import plLang from "../Translations/pl";
import enLang from "../Translations/en";
import deLang from "../Translations/de";
import LANGUAGES from "../Enums/Languages";
import getInitLanguage from "../Helpers/GetInitLanguage";

i18n.init({
  resources: {
    pl: { translations: { ...plLang } },
    en: { translations: { ...enLang } },
    de: { translations: { ...deLang } },
  },
  lng: getInitLanguage(),
  fallbackLng: LANGUAGES.en.name,
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: { formatSeparator: "," },

  react: { wait: true },
});

export default i18n;
