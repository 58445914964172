import React from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";

const statuses = {
  active: {
    term: "Active",
    color: "green",
  },
  draft: {
    term: "Draft",
    color: "yellow",
  },
  inactive: {
    term: "Inactive",
    color: "gray",
  },
};

const ProductStatus = ({ status, version, t }) => {
  let currentStatus = "active";
  switch (status.id) {
    case PRODUCT_STATUSES.ACTIVE:
      currentStatus = "active";
      break;
    case PRODUCT_STATUSES.DRAFT:
      currentStatus = "draft";
      break;
    case PRODUCT_STATUSES.INACTIVE:
      currentStatus = "inactive";
      break;
  }
  return status ? (
    <div className="status-info">
      <span className={`status-info__ico ${statuses[currentStatus].color}`} />
      {t(statuses[currentStatus].term)}{" "}
      {!!version ? `- ${t("version")} ${version}` : ``}
    </div>
  ) : (
    <div />
  );
};

ProductStatus.propTypes = {
  status: PropTypes.object,
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func,
};

export default translate()(ProductStatus);
