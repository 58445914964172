import React from "react";
import {
  User,
  Phone,
  Mail,
  DollarSign,
  FileText,
  Compass,
  ShoppingCart,
} from "react-feather";
import ListElement from "../../../../Common/Components/ListElement/ListElement";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import ContractPaymentStatusCodes from "../../../../Enums/ContractPaymentStatusCodes";
import CONTRACT from "../../../../Enums/Contract";
import moment from "moment";
import { havePermissions, permissions } from "../../../../Common/Utils/Acl";
import ContractFinishReasons from "../../../../Enums/ContractFinishReasons";

const CourseStudentsSlaveListElement = props => {
  const {
    name,
    surname,
    phone,
    email,
    id,
    paymentStatus,
    masterContractStatus,
    masterContractFinishReason,
    masterFinishDate,
    masterPackage,
    masterContract,
    counter,
    shop,
    t,
  } = props;
  const iconSize = { size: 15 };

  const renderEmail = (
    <span key={email}>
      <Mail {...iconSize} />
      {email}
    </span>
  );
  const renderPhone = (
    <span key={phone}>
      <Phone {...iconSize} />
      {phone}
    </span>
  );
  const paymentStatusElement =
    paymentStatus.code === ContractPaymentStatusCodes.EXPIRED ? (
      <span className="paymentStatusDetails--alert">{paymentStatus.name}</span>
    ) : (
      paymentStatus.name
    );

  function createContractStatus() {
    let statusClass = `${masterContractStatus.code} text--bold`;

    return (
      <span className={statusClass}>
        <FileText {...iconSize} /> {masterContractStatus.name}
      </span>
    );
  }

  function createContractFinishReason() {
    if (!havePermissions([permissions.STUDENT_CONTRACT_FINISH_STATUS_VIEW])) {
      return "";
    }
    const date = masterContractFinishReason && masterFinishDate;
    const correctFormatDate = moment(date).format("YYYY-MM-DD");

    switch (masterContractFinishReason.code) {
      case ContractFinishReasons.FINISHED:
        return (
          <span className="contract_signed text--bold">
            <Compass {...iconSize} />
            {`${masterContractFinishReason.name}, ${correctFormatDate}`}
          </span>
        );
      default:
        return (
          <span className="finish-reason__fail text--bold">
            <Compass {...iconSize} />
            {`${masterContractFinishReason.name} ${correctFormatDate}`}
          </span>
        );
    }
  }

  return (
    <ListElement
      icon={<User size={30} />}
      className="product-item--with-border is-slave"
      path={`/persons/contract/${masterContract}`}
      id={id}
    >
      <h4 className={"student-list-counter"}>{counter}</h4>
      <div className="student">
        <h4 className="text student__info">
          <strong className="text student__info--dark">
            {name} {surname}
          </strong>
          {renderPhone}
          {renderEmail}
        </h4>
        <p className="student__info ">
          <span>
            <DollarSign {...iconSize} /> {paymentStatusElement}
          </span>
          {shop && (
            <span className="contract_shop text--bold">
              <ShoppingCart {...iconSize} /> {t("Bought in shop")}
            </span>
          )}
          {masterContractStatus && createContractStatus()}
          {masterPackage && (
            <span>
              <DollarSign {...iconSize} />{" "}
              {masterPackage && masterPackage.name.toLowerCase()}
            </span>
          )}
          {masterContractFinishReason ? (
            createContractFinishReason()
          ) : masterContractStatus.id === CONTRACT.STATUSES.CONTRACT_SIGNED ? (
            <span className="contract_signed text--bold">
              <Compass {...iconSize} />
              {t("On the course")}
            </span>
          ) : null}
        </p>
      </div>
    </ListElement>
  );
};

CourseStudentsSlaveListElement.propTypes = {
  t: PropTypes.func,
  student: PropTypes.object,
  name: PropTypes.string,
  surname: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number,
  shop: PropTypes.bool,
  counter: PropTypes.number,
  paymentStatus: PropTypes.object,
  masterContractStatus: PropTypes.object,
  masterContractFinishReason: PropTypes.object,
  masterFinishDate: PropTypes.string,
  masterPackage: PropTypes.object,
  masterContract: PropTypes.number,
};

export default translate()(CourseStudentsSlaveListElement);
