import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function packagesReducer(state = coursesInitState.packages, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PACKAGES_DICTIONARY:
      return action.payload.data.data;
    default:
      return state;
  }
}
