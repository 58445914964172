import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import { RenderSelect } from "../../../../Forms/forms";
import getCancelToken from "../../../../Helpers/GetCancelToken";
import ContractPaymentStatuses from "../../../../Enums/ContractPaymentStatuses";
import FundingTypes from "../../../../Enums/FundingTypes";
import CourseSpecialistSelectWithPagination from "../../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import SelectMultipleProductWithPaging from "../../../../Common/Components/SelectMultipleProductWithPagingForFilters/SelectMultipleProductWithPagingForFilters";
import _ from "lodash";

class Filters extends Component {
  cancelToken = getCancelToken();

  state = {
    selectedFilters: {},
  };

  componentDidMount() {
    const {
      getContractFinishReasons,
      getContractStatus,
      fetchContractPackages,
      fetchProductVersionsMeta,
      fetchAllProducts,
    } = this.props;

    getContractFinishReasons(this.cancelToken);
    getContractStatus(this.cancelToken);
    fetchContractPackages(this.cancelToken);
    fetchProductVersionsMeta(this.cancelToken);
    fetchAllProducts(this.cancelToken, { simpleResponse: true });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  handleOnChangeField = (item, meta, reduxFieldName) => {
    const { change } = this.props;

    if (!item) {
      change(reduxFieldName, null);
      return;
    }

    switch (reduxFieldName) {
      case "payer": {
        change(reduxFieldName, item.value);
        break;
      }
      case "product": {
        change(reduxFieldName, item.map(product => product.productId));
        break;
      }
      case "contractStatus":
      case "courseSpecialistId":
      case "finishReason":
      case "moduleType":
      case "package":
      case "hasFreeSlots":
      case "courseSpecialist":
      case "paymentStatus": {
        change(reduxFieldName, item.id);
        break;
      }
    }

    if (item) {
      this.setState(
        prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            [reduxFieldName]: item,
          },
        }),
        this.getFilters,
      );
    }
  };

  handleClearSelectedFilter = fieldName => {
    this.props.change(fieldName, null);
    this.setState(
      prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          [fieldName]: null,
        },
      }),
      this.getFilters,
    );
  };

  searchCourse = search => {
    const { fetchCourses } = this.props;
    fetchCourses(this.cancelToken, {
      search,
    });
  };

  getFilters = () => {
    const {
      selectedFilters: {
        finishReason,
        moduleType,
        package: contractPackage,
        payer,
        paymentStatus,
        hasFreeSlots,
        product,
        courseSpecialist,
        contractStatus,
      },
    } = this.state;

    const filters = {
      contractPaymentStatus: paymentStatus && paymentStatus.id,
      contractFinishStatusCode: finishReason && finishReason.code,
      payerFundingType: payer && payer.value,
      moduleType: moduleType && moduleType.id,
      package: contractPackage && contractPackage.id,
      hasFreeSlots: hasFreeSlots && hasFreeSlots.id,
      product: product && product.map(product => product.productId),
      courseSpecialist: courseSpecialist && courseSpecialist.id,
      contractStatusCode: contractStatus && contractStatus.code,
    };

    this.props.onFilterChange(filters);
  };

  render() {
    const { t, moduleTypes } = this.props;

    const underClasses = {
      containerClassName: "col-lg-2 col-md-3 col-xs-6",
      fieldClassName: "col-xs-12",
    };

    const paymentStatuses = Object.keys(ContractPaymentStatuses).map(
      (key, index) => ({ id: index + 1, name: key.toLowerCase() }),
    );

    const fundingTypesSelect = Object.keys(FundingTypes).map(key => ({
      value: FundingTypes[key],
      name: t(FundingTypes[key].replace("_", " ")),
    }));

    const assignments = [
      { name: t("Has not free slots"), id: false },
      { name: t("Has free slots"), id: true },
    ];

    const {
      contractFinishReasons,
      contractPackages,
      fetchAllProducts,
      productsList,
      getCoursesSpecialists,
      coursesSpecialists,
      contractStatus,
    } = this.props;

    const products = _.has(productsList, "items") ? productsList.items : [];

    return (
      <div id="contracts-list-filters" className="row">
        <div className="col-xs-12 padding__l0">
          <label className="label label--top">{t("Filters")}</label>
        </div>
        <div className="row">
          <div className="width">
            <SelectMultipleProductWithPaging
              fetchProducts={fetchAllProducts}
              products={products}
              placeholder={t("Product master")}
              customItemRender={product => (
                <span title={product.name}>{product.name}</span>
              )}
              onChangeProduct={products =>
                this.handleOnChangeField(products, _, "product")
              }
              onClear={() => this.handleClearSelectedFilter("product")}
              under={underClasses}
            />
          </div>

          <Field
            title={t("Final status")}
            name="finishReason"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() =>
              this.handleClearSelectedFilter("finishReason")
            }
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: contractFinishReasons || [],
              textField: "name",
              valueField: "id",
              placeholder: t("Final status"),
            }}
          />
          <Field
            title={t("Payment status")}
            name="paymentStatus"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() =>
              this.handleClearSelectedFilter("paymentStatus")
            }
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: paymentStatuses.map(status => ({
                ...status,
                name: t(status.name),
              })),
              textField: "name",
              valueField: "id",
              placeholder: t("Payment status"),
            }}
          />

          <Field
            title={t("Module type")}
            name="moduleType"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() => this.handleClearSelectedFilter("moduleType")}
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: moduleTypes || [],
              textField: "name",
              valueField: "id",
              placeholder: t("Module type"),
            }}
          />

          <Field
            title={t("Assignment")}
            name="hasFreeSlots"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() =>
              this.handleClearSelectedFilter("hasFreeSlots")
            }
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: assignments || [],
              textField: "name",
              valueField: "id",
              placeholder: t("Assignment"),
            }}
          />

          <Field
            title={t("Package")}
            name="package"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() => this.handleClearSelectedFilter("package")}
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: contractPackages || [],
              textField: "name",
              valueField: "id",
              placeholder: t("Package"),
            }}
          />

          <Field
            title={t("Contract status")}
            name="contractStatus"
            component={RenderSelect}
            under={underClasses}
            onClearCallback={() =>
              this.handleClearSelectedFilter("contractStatus")
            }
            dropdownConfig={{
              onChange: this.handleOnChangeField,
              data: contractStatus || [],
              textField: "name",
              valueField: "id",
              placeholder: t("Contract status"),
            }}
          />

          <Field
            component={RenderSelect}
            name="payer"
            dropdownConfig={{
              data: fundingTypesSelect,
              textField: "name",
              valueField: "value",
              placeholder: t("Payer"),
              onChange: this.handleOnChangeField,
            }}
            under={underClasses}
            onClearCallback={() => this.handleClearSelectedFilter("payer")}
          />

          <Field
            component={CourseSpecialistSelectWithPagination}
            name="courseSpecialist"
            onChangeHandler={this.handleOnChangeField}
            placeholder={t("All curse specialists")}
            getCoursesSpecialists={getCoursesSpecialists}
            coursesSpecialists={coursesSpecialists}
            under={underClasses}
            onClearCallback={() =>
              this.handleClearSelectedFilter("courseSpecialist")
            }
          />
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  t: PropTypes.func,
  values: PropTypes.object,
  change: PropTypes.func,
  getContractFinishReasons: PropTypes.func,
  getContractStatus: PropTypes.func,
  fetchContractPackages: PropTypes.func,
  contractFinishReasons: PropTypes.array,
  contractStatus: PropTypes.array,
  contractPackages: PropTypes.array,
  moduleTypes: PropTypes.array,
  coursesList: PropTypes.object,
  fetchCourses: PropTypes.func,
  fetchProductVersionsMeta: PropTypes.func,
  onFilterChange: PropTypes.func,
  fetchAllProducts: PropTypes.func,
  productsList: PropTypes.object,
  coursesSpecialists: PropTypes.array,
  getCoursesSpecialists: PropTypes.func,
};

export default translate()(Filters);
