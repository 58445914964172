const getFileName = (disposition: string): string => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  } else {
    const filenameStart = disposition.toLowerCase().indexOf("filename=");
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
};

export const getFileNameFromHeaders = (
  headers: Record<string, string>,
): string | null => {
  const contentDispositionHeaderValue =
    headers["content-disposition"] || headers["Content-Disposition"];

  return contentDispositionHeaderValue
    ? getFileName(contentDispositionHeaderValue)
    : null;
};
