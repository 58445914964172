import React, { Component } from "react";
import { Form, Field } from "redux-form";
import { RenderDateTime, RenderSelect } from "../../../Forms/forms";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import PriceFieldWithCalculation from "../../../Forms/PriceFieldWithCalculation";
import moment from "moment";
import ADDITIONAL_PAYMENT_TYPES from "../../../Enums/AdditionalPaymentTypes";
import AmountField from "../../../Forms/AmountField";
import NEW_PAYMENT_TYPES from "../../../Enums/NewPaymentTypes";

class AdditionalFee extends Component {
  static propTypes = {
    t: PropTypes.func,
    currency: PropTypes.string,
    priceGrossCalculator: PropTypes.func,
    handleSubmit: PropTypes.func,
    addAdditionalPayment: PropTypes.func,
    contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.object,
    close: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    change: PropTypes.func,
    payers: PropTypes.array,
    newPaymentTypes: PropTypes.array,
  };

  constructor(props) {
    super(props);
    props.change("deadline", moment().valueOf());
  }

  handleAddAdditionalFee = values => {
    const { description, amountGross, deadline, balanceId, type } = values;
    const { addAdditionalPayment, contractId, currency, close } = this.props;
    const data = {
      deadline,
      description,
      amountGross: {
        amount: amountGross,
        currency,
      },
      balanceId,
      type,
    };
    addAdditionalPayment(contractId, data, ({ data: { data: payload } }) => {
      close(payload);
    });
  };

  getAdditionalPaymentTypes = () => {
    const { t } = this.props;
    return Object.keys(ADDITIONAL_PAYMENT_TYPES).map(key => {
      return {
        name: t(ADDITIONAL_PAYMENT_TYPES[key]),
        value: ADDITIONAL_PAYMENT_TYPES[key],
      };
    });
  };

  getPayersList = () => {
    const { payers } = this.props;
    return payers.map(payer => {
      return {
        ...payer,
        name: payer.balance.owner.name,
        value: payer.balance.id,
      };
    });
  };

  setType = (event, value) => {
    const { newPaymentTypes, change } = this.props;
    const selectedType = newPaymentTypes.find(
      paymentType => paymentType.id === value,
    );
    if (selectedType.code !== NEW_PAYMENT_TYPES.OTHER_CHARGES) {
      change("description", selectedType.name);
    } else {
      change("description", null);
    }
  };

  render() {
    const {
      t,
      currency,
      priceGrossCalculator,
      handleSubmit,
      submitting,
      pristine,
      newPaymentTypes,
      values: { type },
    } = this.props;
    const selectedType = newPaymentTypes.find(
      paymentType => paymentType.id === type,
    );
    return (
      <Form onSubmit={handleSubmit(this.handleAddAdditionalFee)}>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-6`}>
            <div className={`col-xs-12`}>
              <Field
                name="type"
                dropdownConfig={{
                  data: newPaymentTypes,
                  textField: "name",
                  valueField: "id",
                }}
                component={RenderSelect}
                required="required"
                under
                label={t("Payments type")}
                onChange={this.setType}
              />
            </div>
          </div>
          {!selectedType ||
          selectedType.code === NEW_PAYMENT_TYPES.OTHER_CHARGES ? (
            <div className={`col-xs-12 col-sm-6`}>
              <div className={`col-xs-12`}>
                <Field
                  name="description"
                  dropdownConfig={{
                    data: this.getAdditionalPaymentTypes(),
                    textField: "name",
                    valueField: "name",
                  }}
                  component={RenderSelect}
                  required="required"
                  under
                  label={t("Subject of payment")}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-6`}>
            <div className={`col-xs-12`}>
              <Field
                name="balanceId"
                dropdownConfig={{
                  data: this.getPayersList(),
                  textField: "name",
                  valueField: "value",
                }}
                component={RenderSelect}
                required="required"
                under
                label={t("Select payer")}
              />
            </div>
          </div>
          <div className={`col-xs-12 col-sm-6`}>
            <div className={`col-xs-12`}>
              <Field
                under
                component={RenderDateTime}
                label={t("Payment date")}
                name={`deadline`}
                time={false}
              />
            </div>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-6`}>
            <AmountField
              under
              component={PriceFieldWithCalculation}
              label={t("Gross amount")}
              t={t}
              name={`amountGross`}
              currency={currency}
              priceCalculator={priceGrossCalculator}
              required="required"
            />
          </div>
        </div>
        <div className={`col-xs-12`}>
          <button
            disabled={submitting || pristine}
            type={`submit`}
            className={`btn btn-dark pull-right`}
          >
            {t("Add fee")}
          </button>
        </div>
      </Form>
    );
  }
}

export default translate()(AdditionalFee);
