export default {
  STUDENT: 3,
  EMPLOYEE: 2,
  LEAD: 1,
  1: {
    lowerCase: "lead",
    firstUpperCase: "Lead",
  },
  2: {
    lowerCase: "employee",
    firstUpperCase: "Employee",
  },
  3: {
    lowerCase: "student",
    firstUpperCase: "Student",
  },
};
