import React from "react";
import PropTypes from "prop-types";
import ListElement from "../../../../Common/Components/ListElement/ListElement";
import {
  Compass,
  DollarSign,
  FileText,
  Mail,
  Clipboard,
} from "react-feather/dist/index";
import { havePermissions, permissions } from "../../../../Common/Utils/Acl";
import CONTRACT from "../../../../Enums/Contract";
import ContractPaymentStatusCodes from "../../../../Enums/ContractPaymentStatusCodes";
import CONTRACT_STATUSES from "../../../../Enums/ContractStatuses";
import { translate } from "react-i18next";
import ContractFinishReason from "./ContractFinishReason";

const ContractListElement = ({ t, contractItem }) => {
  const {
    student: {
      person: { name, surname, email },
    },
    contract: {
      id: contractId,
      status,
      paymentStatus,
      package: packageType,
      finishReason,
      finishDate,
      diploma,
      transferCourse,
      transferFrom,
    },
    course: { signature },
  } = contractItem;

  const iconSize = { size: 15 };
  const renderEmail =
    email.length > 0 &&
    email.map(item => (
      <span key={item.email}>
        <Mail {...iconSize} />
        {item.email}
      </span>
    ));

  const paymentStatusElement =
    paymentStatus.code === ContractPaymentStatusCodes.EXPIRED ? (
      <span className="paymentStatusDetails--alert">{paymentStatus.name}</span>
    ) : (
      paymentStatus.name
    );

  const createContractStatus = () => {
    const {
      CONTRACT_SIGNED,
      CONTRACT_SEND,
      DURING_RECRUITMENT,
      CONTRACT_DENIED,
    } = CONTRACT_STATUSES;

    if (!havePermissions([permissions.STUDENT_CONTRACT_STATUS_VIEW])) {
      return "";
    }

    switch (status.code) {
      case CONTRACT_SIGNED:
        return (
          <span className="contract_signed text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case CONTRACT_SEND:
        return (
          <span className="contract_send text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case DURING_RECRUITMENT:
        return (
          <span className="during_recruitment text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      case CONTRACT_DENIED:
        return (
          <span className="text--bold">
            <FileText {...iconSize} /> {status.name}
          </span>
        );
      default:
        return (
          <span>
            <FileText {...iconSize} /> {status.name}
          </span>
        );
    }
  };

  return (
    <ListElement
      icon={<Clipboard size={30} />}
      className="product-item--with-border"
      path={`/persons/contract/${contractId}`}
      id={contractId}
    >
      <div className="student" id={`student_contract_${contractId}`}>
        <h4 className="text student__info">
          <strong className="text student__info--dark">
            {`${name} ${surname}`}
          </strong>
          <span className="signature">{signature}</span>
          {renderEmail}
        </h4>
        <p className="student__info ">
          <span>
            <DollarSign {...iconSize} /> {paymentStatusElement}
          </span>
          {status && createContractStatus()}
          {packageType && (
            <span>
              <DollarSign {...iconSize} />
              {packageType && packageType.name.toLowerCase()}
            </span>
          )}
          {transferFrom && (
            <span className="finish-reason__transfer text--bold">
              <Compass {...iconSize} />
              {`${t("Transfer from")} ${transferFrom}`}
            </span>
          )}
          {finishReason ? (
            <ContractFinishReason
              t={t}
              finishReason={finishReason}
              diploma={diploma}
              finishDate={finishDate}
              transferCourse={transferCourse}
            />
          ) : (
            status &&
            status.id === CONTRACT.STATUSES.CONTRACT_SIGNED && (
              <span className="finish-reason__onCourse text--bold">
                <Compass {...iconSize} />
                {t("On the course")}
              </span>
            )
          )}
        </p>
      </div>
    </ListElement>
  );
};

ContractListElement.propTypes = {
  contractItem: PropTypes.object,
  t: PropTypes.func,
};

export default translate()(ContractListElement);
