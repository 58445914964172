import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";
import LANGUAGES from "../../Enums/Languages";
import { changeLanguage as i18n_changeLanguage } from "i18next";
import Dinero from "dinero.js";
import setLanguageToLocalStore from "../../Helpers/SetLanguageToLocalStore";

export function clearErrorFromStore() {
  return {
    type: ACTION_TYPES.CLEAR_ERROR_FROM_STORE,
    payload: null,
  };
}

export function clearSaveStateFromStore() {
  return {
    type: ACTION_TYPES.SAVE_STATE_FALSE,
    payload: null,
  };
}

export function passErrorToStore(message = "Popraw dane na formularzu") {
  return {
    type: ACTION_TYPES.PASS_ERROR_TO_STORE,
    payload: { response: { data: { message } } },
  };
}

export function fetchErrorCodes(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/exception/codes`,
    actionType: ACTION_TYPES.FETCH_ERROR_CODES,
    cancelToken,
    callback,
  });
}

export function sendLog(values, callback = null) {
  const config = {
    path: "", // TO DO
    actionType: ACTION_TYPES.SEND_ERROR_LOG,
    values: values,
    callback: callback,
  };
  return Client.actionPOST(config);
}

export function fetchTenantSettings(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/tenant/settings`,
    actionType: ACTION_TYPES.FETCH_TENANT_SETTINGS,
    callback,
    cancelToken,
  });
}

export function changeLanguage(lang, callback = null) {
  i18n_changeLanguage(lang);
  Dinero.globalLocale = lang;
  setLanguageToLocalStore(lang);
  if (callback) {
    callback();
  }
  return {
    type: ACTION_TYPES.I18N_SET_LANGUAGE,
    payload: LANGUAGES[lang].name,
  };
}
