import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDictionary,
  updateDictionary,
  createDictionaryItem,
} from "../Actions";
import LecturesTypes from "../Components/LecturesTypes";

const FORM_NAME = `DICTIONARY_LECTURES_TYPES`;

let DictionaryLecturesTypes = props => <LecturesTypes {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "lecturesTypes");
  let {
    dictionaries: { lecturesTypes },
  } = state;
  return {
    values,
    initialValues: { lecturesTypes },
    lecturesTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
      createDictionaryItem,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryLecturesTypes),
);
