import React, { Component } from "react";
import PropTypes from "prop-types";
import getCancelToken from "../../../Helpers/GetCancelToken";
import NewEvent from "../Containers/NewEvent";
import EventsList from "./EventsList";

class Events extends Component {
  cancelToken = getCancelToken();
  componentDidMount() {
    const { fetchProductVersionsMeta } = this.props;
    fetchProductVersionsMeta(this.cancelToken);

    this.getEvents();
  }

  getEvents = async () => {
    const { fetchProductEvents, versionId } = this.props;

    await fetchProductEvents(this.cancelToken, versionId);
  };

  removeEvent = async eventId => {
    const { removeProductEvent, versionId } = this.props;

    await removeProductEvent(versionId, eventId);
    this.getEvents();
  };

  render() {
    const {
      isLoading,
      versionId,
      courseModes,
      productEvents,
      addProductEvent,
      participants,
      types,
      disabled,
    } = this.props;

    return (
      <div>
        <div className="margin__b25">
          <EventsList
            events={productEvents}
            onRemoveEvent={this.removeEvent}
            disabled={disabled}
            isLoading={isLoading}
            isProduct
          />
        </div>

        {!disabled && (
          <NewEvent
            versionId={versionId}
            courseModes={courseModes}
            participants={participants}
            types={types}
            addProductEvent={addProductEvent}
            getEvents={this.getEvents}
            isProduct
          />
        )}
      </div>
    );
  }
}

Events.propTypes = {
  isLoading: PropTypes.bool,
  versionId: PropTypes.string,
  fetchProductEvents: PropTypes.func,
  fetchProductVersionsMeta: PropTypes.func,
  addProductEvent: PropTypes.func,
  removeProductEvent: PropTypes.func,
  productEvents: PropTypes.array,
  courseModes: PropTypes.array,
  participants: PropTypes.array,
  types: PropTypes.array,
  disabled: PropTypes.bool,
};

export default Events;
