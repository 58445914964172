import * as React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { XCircle } from "react-feather";

const SelectLecturerForEvent = props => {
  const PER_PAGE = 10;

  const {
    t,
    setLecturer,
    fetchData,
    under,
    label,
    placeholder,
    lecturers,
    selected,
    isLoading,
    disabled,
    onShowMoreResults,
    meta,
  } = props;

  const { error, touched } = meta;
  const displayError = touched && !!error;

  const onRealSearch = query => {
    if (query) {
      fetchData(query);
    }
    fetchData(undefined);
  };

  return (
    <div
      id="lecturer-select"
      className={`form-group selectWithPagination ${
        displayError ? "has-error" : ""
      } ${under ? under.containerClassName : "row"}`}
    >
      <div className={`row`}>
        {label && (
          <div
            className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
              !label || label.length === 0 ? "hidden" : ""
            }`}
          >
            <label htmlFor="">{label}</label>
          </div>
        )}
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <AsyncTypeahead
              isLoading={isLoading}
              disabled={disabled}
              options={lecturers}
              selected={selected}
              id="event-lecturer-select"
              multiple={false}
              minLength={0}
              maxResults={PER_PAGE}
              labelKey={({ name, surname }) => `${name} ${surname}`}
              onSearch={onRealSearch}
              placeholder={placeholder}
              onChange={setLecturer}
              paginate={true}
              promptText={t("Type to search...")}
              searchText={t("Searching...")}
              paginationText={t("Show more lecturers")}
              emptyLabel={t("Lecturers not found")}
              useCache={true}
              onPaginate={(_, showResults) => onShowMoreResults(showResults)}
            />
            <span aria-hidden="true" className="rw-i rw-i-caret-down" />
            <div onClick={() => setLecturer([])} className="form__clear-button">
              <XCircle size={15} />
            </div>
          </div>
          {displayError && <span className="help-block">{t(error)}</span>}
        </div>
      </div>
    </div>
  );
};

SelectLecturerForEvent.propTypes = {
  t: PropTypes.func,
  fetchData: PropTypes.func,
  onShowMoreResults: PropTypes.func,
  setLecturer: PropTypes.func,
  lecturers: PropTypes.array,
  selected: PropTypes.array,
  under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
};

export default translate()(SelectLecturerForEvent);
