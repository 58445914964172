import React from "react";
type CourseCanceledProps = {
  children: React.ReactElement;
  isCancel: boolean;
};
const CourseCanceled: React.FunctionComponent<CourseCanceledProps> = ({
  isCancel,
  children,
}) => {
  return isCancel ? (
    <>
      <div id="course-cancel-container" />
      {children}
    </>
  ) : (
    children
  );
};

export default CourseCanceled;
