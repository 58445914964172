export type GoogleUser = {
  hd: string;
  email: string;
  email_verified: boolean;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  sub: string;
};

const USER_INFO_URL = "https://www.googleapis.com/oauth2/v3/userinfo";
export const getOAuth2UserInfo = async (
  accessToken: string,
): Promise<GoogleUser> => {
  const url = `${USER_INFO_URL}?access_token=${accessToken}`;
  const userResponse = await fetch(url);
  return await userResponse.json();
};
