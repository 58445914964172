export default fields => {
  const MAX_COMMENT_LENGTH = 255;
  const { dictComments } = fields;
  let errors = {};
  let commentsErrors = [];

  dictComments &&
    dictComments.forEach((comment, index) => {
      commentsErrors[index] = {};
      if (!!comment.comment && comment.comment.length > MAX_COMMENT_LENGTH) {
        commentsErrors[index] = {
          ...commentsErrors[index],
          comment: "Comment is too length (max characters 255)",
        };
      }
    });

  errors.dictComments = { ...commentsErrors };
  return errors;
};
