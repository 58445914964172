import React, { Component } from "react";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { RenderText, RenderSelect, RenderRadio } from "../../../Forms/forms";
import getCancelToken from "../../../Helpers/GetCancelToken";
import checkCountryIsPoland from "../../../Helpers/CheckCountryIsPoland";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import CityAutoComplete from "../../../Common/Components/CityAutoComplete/CityAutoComplete";
import CitySelectWithPagination from "../../../Forms/CitySelectWithPagination";

class NewCompanyForm extends Component {
  static propTypes = {
    fetchDictionary: PropTypes.func,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    change: PropTypes.func,
    values: PropTypes.object,
    fetchCompanyFromGus: PropTypes.func,
    onCancel: PropTypes.func,
    addNewCompany: PropTypes.func,
    clearCompany: PropTypes.func,
    fetchCompanies: PropTypes.func,
    taxRates: PropTypes.array,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
    dictionaries: PropTypes.object,
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
    payerData: PropTypes.object,
    isDisabledField: PropTypes.bool,
    searchInGus: PropTypes.bool,
    setSearchInGusState: PropTypes.func,
    validationErrors: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      payer: null,
      addressCity: null,
    };
    const { fetchDictionary } = this.props;
    const { FETCH_CITIES, FETCH_COUNTRIES } = ACTION_TYPES;
    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: FETCH_CITIES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/countries",
      actionType: FETCH_COUNTRIES,
    });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.clearCompany();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { values, initialValues } = nextProps;
    if (this.props.initialValues !== initialValues) {
      if (!initialValues.isTaxNrEUActive) {
        initialValues.isTaxNrEUActive = values.isTaxNrEUActive;
      }
      this.props.initialize(initialValues);
    }
    if (this.props.payerData !== nextProps.payerData) {
      this.props.initialize(this.prepareDataPayer(nextProps.payerData));
    }
  }

  componentDidMount() {
    const { payerData } = this.props;
    if (payerData && payerData.address) {
      this.props.initialize(this.prepareDataPayer(payerData));
    }
  }

  prepareDataPayer(payerObject) {
    let payerData = { ...payerObject };
    if (payerObject.address) {
      payerData = {
        ...payerData,
        addressStreet: payerObject.address.street,
        addressZipCode: payerObject.address.zipCode,
        addressCity: payerObject.address.city.name,
        addressCityName: payerObject.address.city.name,
        addressCountryName: payerObject.address.country.name,
        addressCountry: payerObject.address.country.name,
      };
    }

    return payerData;
  }

  addressCitySelected = city => {
    const value = city && city.name ? city.name : null;
    this.props.change("addressCity", value);
    this.setState({ addressCity: value });
  };

  addressCountrySelected = country => {
    const value = country && country.id ? country.id : null;
    this.props.change("addressCountry", value);
    this.props.change("addressCity", null);
  };

  searchInGus = () => {
    const { values, fetchCompanyFromGus } = this.props;
    const correctFormat = values.taxNr
      ? values.taxNr.replace(/[^\w]/g, "")
      : "";
    this.props.setSearchInGusState(true);
    fetchCompanyFromGus(correctFormat, null, this.cancelToken);
  };

  getTaxRateSelectConfig = () => {
    const { taxRates } = this.props;
    const selectOptions = taxRates
      ? taxRates.map(taxRate => ({ taxRate }))
      : [];
    return {
      data: selectOptions,
      textField: "taxRate",
      valueField: "taxRate",
    };
  };

  onSubmit = values => {
    const { onCancel, addNewCompany, fetchCompanies } = this.props;

    values.isTaxNrEUActive = values.isTaxNrEUActive === "true";
    values.taxRate = String(values.taxRate);

    addNewCompany(values, response => {
      const newPayer = response.data.data;
      fetchCompanies(this.cancelToken, { search: newPayer.name }, () => {
        onCancel(newPayer);
      });
    });
    this.props.setSearchInGusState(true);
  };

  render() {
    const {
      submitting,
      values,
      dictionaries: { countries, cities },
      t,
      isDisabledField,
      validationErrors,
    } = this.props;
    return (
      <div className="form companyForm">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Field
              name="name"
              component={RenderText}
              label={t("Company's name")}
              shouldDisabled={isDisabledField}
            />
            <Field
              name="taxNr"
              component={RenderText}
              label={t("TIN")}
              shouldDisabled={isDisabledField}
            />
            <div className="form-group row">
              <div className="col-xs-12">
                <button
                  onClick={this.searchInGus}
                  className="btn btn-primary pull-right"
                  disabled={
                    isDisabledField ||
                    (validationErrors && validationErrors.taxNr)
                  }
                >
                  {t("Search in GUS")}
                </button>
              </div>
            </div>
            <Field
              name="regonNr"
              shouldDisabled={isDisabledField}
              component={RenderText}
              label={t("REGON")}
            />
            <Field
              label={t("% VAT")}
              name="taxRate"
              component={RenderSelect}
              dropdownConfig={this.getTaxRateSelectConfig()}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="radioButtons">
              <Field
                value={false}
                name="isTaxNrEUActive"
                shouldDisabled={isDisabledField}
                component={RenderRadio}
                options={[
                  {
                    name: t("NO"),
                    value: false,
                  },
                  {
                    name: t("YES"),
                    value: true,
                  },
                ]}
                label={t("VAT UE")}
              />
            </div>
            <Field
              name="addressStreet"
              shouldDisabled={isDisabledField}
              component={RenderText}
              label={t("Address")}
            />
            <Field
              name="addressZipCode"
              shouldDisabled={isDisabledField}
              component={RenderText}
              label={t("Code")}
            />
            <Field
              name="addressCountry"
              shouldDisabled={isDisabledField}
              dropdownConfig={{
                data: countries,
                filter: "contains",
                textField: "name",
                valueField: "id",
                onChange: this.addressCountrySelected,
              }}
              component={RenderSelect}
              label={t("Country")}
            />
            <div
              className={`citySelectWrapper ${
                values.addressCountry &&
                checkCountryIsPoland(countries, values.addressCountry)
                  ? ""
                  : "hidden"
              }`}
            >
              <Field
                name="addressCity"
                shouldDisabled={isDisabledField}
                component={CitySelectWithPagination}
                label={t("City")}
                props={{
                  onChangeHandler: this.addressCitySelected,
                  cities,
                  fetchDictionary: this.props.fetchDictionary,
                  value: values.addressCity,
                }}
              />
            </div>
            <div
              className={`${
                values.addressCountry &&
                checkCountryIsPoland(countries, values.addressCountry)
                  ? "hidden"
                  : ""
              }`}
            >
              <CityAutoComplete
                label={t("City")}
                shouldDisabled={isDisabledField}
                change={this.props.change}
                name="addressCity"
              />
            </div>
          </div>
        </div>
        <div className="row">
          {!isDisabledField && (
            <div className="col-xs-12">
              <button
                type="button"
                onClick={this.props.handleSubmit(this.onSubmit)}
                className="btn btn-dark pull-right"
                disabled={submitting}
              >
                {" "}
                {t("Add payer")}{" "}
              </button>
              <button
                type="button"
                onClick={this.props.onCancel}
                className="btn btn-link pull-right"
                disabled={submitting}
              >
                {" "}
                {t("Cancel")}{" "}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default translate()(NewCompanyForm);
