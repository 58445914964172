import ACTION_TYPES from "../../Enums/ActionTypes";
import { get } from "lodash";

export function invoices(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INVOICES:
      return [...action.payload.data.data];
  }
  return state;
}

export function invoiceReceivers(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_INVOICE_RECEIVERS:
      return [...action.payload.data.data];
  }
  return state;
}

export function invoiceLegalBasis(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_LEGAL_BASIS: {
      const data = get(action, "payload.data.data.legalBasics", []);
      return [...data];
    }

    case ACTION_TYPES.CLEAR_LEGAL_BASIS: {
      return [];
    }
  }
  return state;
}
