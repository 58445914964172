export const getProductPayload = values => {
  const currencyName = values.currency.name;
  const fees = {
    packageChange: {
      student: {
        ...values.fees.packageChange.student,
        currency: currencyName,
      },
      thirdParty: {
        ...values.fees.packageChange.thirdParty,
        currency: currencyName,
      },
    },
    resign: {
      student: {
        ...values.fees.resign.student,
        currency: currencyName,
      },
      thirdParty: {
        ...values.fees.resign.thirdParty,
        currency: currencyName,
      },
    },
    transfer: {
      student: {
        ...values.fees.transfer.student,
        currency: currencyName,
      },
      thirdParty: {
        ...values.fees.transfer.thirdParty,
        currency: currencyName,
      },
    },
  };
  return {
    ...values,
    course20: !!values.course20,
    kind: values.kind.id,
    language: values.language.id,
    currency: values.currency.id,
    businessOwner: values.businessOwner.id,
    essentialOwner: values.essentialOwner.id,
    numberOfPlaces: Number(values.numberOfPlaces),
    fees,
  };
};
