import React, { Component } from "react";
import { Field, Form } from "redux-form";
import SelectCourseWithPagination from "../../../Forms/SelectCourseWithPagination";
import { ChevronsRight } from "react-feather";
import { RenderCheckbox, RenderSelect } from "../../../Forms/forms";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import getCancelToken from "../../../Helpers/GetCancelToken";
import Slave from "../Models/Slave";

class SlaveForm extends Component {
  #cancelToken = getCancelToken();
  chooseCourse = course => {
    const { change, fetchProductModulesForSlave, productId } = this.props;
    change("course", course);
    if (!!course) {
      fetchProductModulesForSlave(this.#cancelToken, productId);
    }
  };
  submitForm = values => {
    const {
      createSlave,
      contractId,
      fetchContract,
      fetchPayments,
      updatePayments,
    } = this.props;
    createSlave(contractId, Slave.unifyToCreate(values), () => {
      fetchContract(this.#cancelToken, { contractId });
      fetchPayments(this.#cancelToken, contractId, ({ data }) => {
        if (_.has(data, "data.payments")) {
          updatePayments(data.data.payments);
        }
      });
    });
  };
  render() {
    const { t, handleSubmit, productModules, values } = this.props;
    return (
      <Form
        onSubmit={handleSubmit(this.submitForm)}
        className={`contract__slaves__form`}
      >
        <div className={`col-xs-3`}>
          <Field
            onChangeHandler={this.chooseCourse}
            name={`course`}
            component={SelectCourseWithPagination}
            label={t(`Signature`)}
          />
        </div>
        <div className={`col-xs-1 contract__slaves__form-chevrons`}>
          <ChevronsRight size={28} />
        </div>
        <div className={`col-xs-3`}>
          <Field
            name={`module`}
            component={RenderSelect}
            shouldDisabled={!values.course}
            dropdownConfig={{
              data: productModules || [],
              textField: "name",
              valueField: "id",
              placeholder: this.props.t("Choose module"),
            }}
            label={t(`Choose module`)}
          />
        </div>
        <div className={`col-xs-1 contract__slaves__form-chevrons`}>
          <ChevronsRight size={28} />
        </div>
        <div className={`col-xs-2`}>
          <Field
            shouldDisabled={!values.course}
            name={`relatedFees`}
            component={RenderCheckbox}
            labelForCheckbox={t(`Will you take the related course fees?`)}
          />
        </div>
        <div className={`col-xs-2`}>
          <button className={`btn btn-dark`}>{t(`assign contract`)}</button>
        </div>
      </Form>
    );
  }
}
SlaveForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  createSlave: PropTypes.func,
  fetchProductModulesForSlave: PropTypes.func,
  productModules: PropTypes.array,
  values: PropTypes.object,
  fetchContract: PropTypes.func,
  fetchPayments: PropTypes.func,
  updatePayments: PropTypes.func,
};
export default translate()(SlaveForm);
