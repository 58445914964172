import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { formValueSelector, reduxForm } from "redux-form";
import { fetchBalances, downloadBalances } from "../Actions";
import { getCoursesSpecialists } from "../../Persons/Actions";
import BalancesReport from "../Components/BalancesReport";

const FORM_NAME = "BALANCES_REPORT";

const Balances = props => <BalancesReport {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const { balancesReportResults, coursesSpecialists } = state;
  const values = selector(
    state,
    "courseSpecialistIds",
    "expiredOnly",
    "overpaidOnly",
    "sort",
  );
  return {
    balancesReportResults,
    coursesSpecialists,
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCoursesSpecialists,
      fetchBalances,
      downloadBalances,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    expiredOnly: false,
    overpaidOnly: false,
    sort: "totalBalance.asc",
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Balances),
);
