import React from "react";
import { AlertTriangle } from "react-feather";
import PropTypes from "prop-types";

const SwitchTabLink = ({ text, callback, shouldDisplay, offset }) =>
  shouldDisplay ? (
    <div className="row has-error form-group">
      <div className={`col-sm-7 col-sm-offset-${offset} help-block`}>
        <button
          type="button"
          className="btn btn-link btn-with-icon text-danger"
          onClick={() => callback()}
        >
          <AlertTriangle size={20} /> <u>{text}</u>
        </button>
      </div>
    </div>
  ) : (
    ""
  );

SwitchTabLink.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  offset: PropTypes.string,
};

export default SwitchTabLink;
