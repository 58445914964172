import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

class MainTemplateSecondaryMenu extends Component {
  render() {
    const links =
      this.props.links &&
      this.props.links.length > 0 &&
      this.props.links.map(item => (
        <li
          className={
            item.position && `secondary-menu__position-${item.position}`
          }
          key={item.link}
        >
          <NavLink to={item.link} activeClassName="active">
            {item.name}
          </NavLink>
        </li>
      ));

    return links ? <ul className="nav secondary-menu">{links}</ul> : "";
  }
}

MainTemplateSecondaryMenu.propTypes = {
  links: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default MainTemplateSecondaryMenu;
