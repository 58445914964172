export default fields => {
  const { dictPromotions } = fields;
  let dictPromotionsErrors = [];

  let errors = {};
  dictPromotions &&
    dictPromotions.forEach((dictPromotion, index) => {
      dictPromotionsErrors[index] = {};

      if (!dictPromotion.name) {
        dictPromotionsErrors[index] = {
          ...dictPromotionsErrors[index],
          name: "Enter the name",
        };
      }
      if (!dictPromotion.description) {
        dictPromotionsErrors[index] = {
          ...dictPromotionsErrors[index],
          description: "Description is required",
        };
      }

      if (!dictPromotion.startDate) {
        dictPromotionsErrors[index] = {
          ...dictPromotionsErrors[index],
          startDate: "Enter the start date",
        };
      }

      if (!dictPromotion.endDate) {
        dictPromotionsErrors[index] = {
          ...dictPromotionsErrors[index],
          endDate: "Enter the end date",
        };
      }
    });

  errors.dictPromotions = { ...dictPromotionsErrors };

  return errors;
};
