import Client from "../../Common/Utils/ClientApi";
import restApiConfig from "../../Configs/restApiConfig";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchDictionary(cancelToken, config, callback = null) {
  const { path, actionType, params } = config;
  const action = Client.actionGET({
    path: `${restApiConfig.API_URL}/dict${path}`,
    actionType,
    params,
    callback,
    cancelToken,
  });

  if (actionType === ACTION_TYPES.FETCH_CITIES && params && params.onlyTeach) {
    return dispatch => {
      dispatch(resetDictionary(ACTION_TYPES.RESET_CITIES));
      return dispatch(action);
    };
  }

  return action;
}

export function fetchDictionaryOnlyTeach(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/dict/cities?onlyTeach=true`,
    actionType: ACTION_TYPES.FETCH_CITIES_ONLY_TEACH,
    callback,
    cancelToken,
  });
}

export function updateDictionary(path, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/dict${path}`,
    actionType: ACTION_TYPES.UPDATE_DICTIONARY,
    values: values,
    callback,
  };

  return Client.actionPUT(config);
}

export function createDictionaryItem(
  path,
  actionType,
  values,
  callback = null,
) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/dict${path}`,
    actionType,
    values,
    callback,
  });
}

export function resetDictionary(actionType, callback = null) {
  if (callback) {
    callback();
  }
  return {
    type: actionType,
    payload: [],
  };
}

export function createPoll(values = {}, callback) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/poll`,
    actionType: ACTION_TYPES.SAVE_POLL,
    values: values,
    callback: callback,
  });
}

export function fetchPolls(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/poll`,
    actionType: ACTION_TYPES.FETCH_POLLS,
    callback,
    cancelToken,
  });
}

export function fetchFilteredPolls(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/poll`,
    actionType: params.actionType,
    params: {
      type: params.pollType,
    },
    callback,
    cancelToken,
  });
}

export function updatePoll(values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/poll/${values.id}`,
    actionType: ACTION_TYPES.UPDATE_POLL,
    values,
    callback,
  });
}

export function fetchPollEvents(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/poll/events`,
    actionType: ACTION_TYPES.FETCH_POLL_EVENTS,
    callback,
    cancelToken,
  });
}

export function createPollPackage(values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/pollPackage/${values.productVersionId}`,
    actionType: ACTION_TYPES.CREATE_POLL_PACKAGE,
    callback,
    values,
  });
}

export function fetchContractPolls(
  cancelToken,
  { contractId, actionType, pollType },
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/contract/poll/${contractId}`,
    actionType: actionType,
    callback,
    params: {
      type: pollType,
    },
    cancelToken,
  });
}

export function fetchUserEngageLeadType(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/userengage/leadtype`,
    actionType: ACTION_TYPES.FETCH_USER_ENGAGE_LEAD_TYPE,
    callback,
    cancelToken,
  });
}

export function addUserEngageLeadType(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/userengage/leadtype`,
    actionType: ACTION_TYPES.CREATE_USER_ENGAGE_LEAD_TYPE,
    values,
    callback,
  });
}

export function updateUserEngageLeadType(values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/userengage/leadtype/${values.id}`,
    actionType: ACTION_TYPES.UPDATE_USER_ENGAGE_LEAD_TYPE,
    values,
    callback,
  });
}

export function deleteUserEngageLeadType(leadId, callback = null) {
  return Client.actionDELETE({
    path: `${restApiConfig.API_URL}/userengage/leadtype/${leadId}`,
    actionType: ACTION_TYPES.DELETE_USER_ENGAGE_LEAD_TYPE,
    callback,
  });
}

export function sendPoll(contractPollId, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/contract/poll/send/${contractPollId}`,
    actionType: ACTION_TYPES.SEND_POLL,
    callback,
  });
}

export function fetchDictComments(callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/dict/comment`,
    actionType: ACTION_TYPES.FETCH_DICT_COMMENTS,
    callback,
  });
}

export function updateDictComment(values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/dict/comment/${values.id}`,
    actionType: ACTION_TYPES.UPDATE_DICT_COMMENT,
    values,
    callback,
  });
}

export function addDictComment(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/dict/comment`,
    actionType: ACTION_TYPES.ADD_DICT_COMMENT,
    values,
    callback,
  });
}

export function fetchDictPromotions(
  cancelToken,
  onlyActive = false,
  contractId = null,
  callback = null,
) {
  const params = {};
  if (onlyActive) {
    params.active = "1";
  }

  if (contractId) {
    params.contractId = contractId;
  }

  return Client.actionGET({
    path: `${restApiConfig.API_URL}/dict/special-offer`,
    actionType: ACTION_TYPES.FETCH_DICT_PROMOTIONS,
    callback,
    params,
    cancelToken,
  });
}

export function updateDictPromotion(values, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/dict/special-offer/${values.id}`,
    actionType: ACTION_TYPES.UPDATE_DICT_PROMOTION,
    values,
    callback,
  });
}

export function addDictPromotion(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/dict/special-offer`,
    actionType: ACTION_TYPES.ADD_DICT_PROMOTION,
    values,
    callback,
  });
}
