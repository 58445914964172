import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SettingsForm from "../Components/SettingsForm";
import {
  addCourseEvent,
  addNewCourse,
  cancelCourse,
  editCourse,
  editEvent,
  fetchCities,
  fetchCourse,
  fetchEmployees,
  fetchCourseModes,
  fetchCourseSpecialists,
  fetchMentorsForCourse,
  fetchProductVersions,
  refreshCurrentCourse,
  removeAdditionalEvent,
  clearCourseState,
} from "../Actions/CoursesActions";
import { isDateBeforeToday } from "../../../Common/Utils/DateFormatters";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { fetchProductVersionsMeta } from "../../Products/Actions";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchProductVersions,
      fetchCities,
      fetchCourseModes,
      fetchMentorsForCourse,
      fetchCourseSpecialists,
      fetchCourse,
      cancelCourse,
      addNewCourse,
      editCourse,
      refreshCurrentCourse,
      removeAdditionalEvent,
      fetchProductVersionsMeta,
      addCourseEvent,
      fetchDictionary,
      fetchEmployees,
      editEvent,
      clearCourseState,
    },
    dispatch,
  );
};

const mapStateToProps = (state, { courseId }) => {
  const {
    courses: {
      products: { items: productItems },
      cities: { items: cityItems },
      courseModes,
      courseSpecialists: { items: courseSpecialistsItems },
      lecturers,
      mentors,
      course,
    },
    productVersionsMeta: { lectureParticipants, lectureTypes },
    ajaxCallInProgressCount,
    dictionaries: { rooms },
  } = state;

  return {
    courseId,
    isNew: !courseId,
    isEnd: !!course ? isDateBeforeToday(course.endDate) : false,
    products: productItems,
    cities: cityItems,
    modes: courseModes,
    courseSpecialists: courseSpecialistsItems,
    mentors,
    course,
    isLoading: ajaxCallInProgressCount > 0,
    isCancel: !!course.cancelDate && !!courseId,
    lectureParticipants,
    lectureTypes,
    rooms,
    lecturers,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsForm);
