import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchContracts } from "../../Courses/Actions/CoursesActions";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import ContractListElement from "../../Courses/Components/Contracts/ContractListElement";

class PersonsCoursesList extends Component {
  static propTypes = {
    person: PropTypes.object,
    fetchContracts: PropTypes.func,
    studentContracts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { person, fetchContracts } = this.props;
    if (person && person.studentId) {
      let params = {
        studentId: person.studentId,
      };
      fetchContracts(this.cancelToken, params);
    }
  }
  componentWillUnmount() {
    this.cancelToken.cancel();
  }
  renderCourses() {
    const { studentContracts, t } = this.props;

    return studentContracts && studentContracts.counter > 0 ? (
      studentContracts.items.map(contractItem => {
        return (
          <ContractListElement
            key={String(contractItem.contract.id)}
            contractItem={contractItem}
          />
        );
      })
    ) : (
      <div>{t("No records founds")}</div>
    );
  }
  render() {
    return this.props.person && this.props.person.studentId ? (
      <div>
        <div className="row">
          <div className="col-md-12 col-xs-12">{this.renderCourses()}</div>
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  const { person, courses } = state;

  return { person, studentContracts: courses.contractsList };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchContracts }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate()(PersonsCoursesList));
