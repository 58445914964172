import ACTION_TYPES from "../../Enums/ActionTypes";
import COMPANY_ACTION_TYPES from "../Companies/Actions/ActionTypes";
import COURSE_ACTION_TYPES from "../Courses/Actions/ActionTypes";
import LANGUAGES from "../../Enums/Languages";
import getInitLanguage from "../../Helpers/GetInitLanguage";

export function showErrorReducer(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.PASS_ERROR_TO_STORE:
      return action.payload.response && action.payload.response.data
        ? action.payload.response.data
        : null;
    case ACTION_TYPES.CLEAR_ERROR_FROM_STORE:
      return action.payload;
    default:
      return state;
  }
}

export function returnProductSaveState(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.EDIT_COURSE:
      return { saveState: true };
    case ACTION_TYPES.SAVE_NEW_COURSE:
      return { saveState: true };
    case ACTION_TYPES.SAVE_PERSON:
      return { saveState: true };
    case ACTION_TYPES.UPDATE_PERSON:
      return { saveState: true };
    case ACTION_TYPES.SAVE_PRODUCT_VERSION:
      return { saveState: true };
    case ACTION_TYPES.SAVE_CONTRACT:
      return { saveState: true };
    case COMPANY_ACTION_TYPES.ADD_NEW_COMPANY:
      return { saveState: true };
    case COMPANY_ACTION_TYPES.SAVE_COMPANY:
      return { saveState: true };
    case COMPANY_ACTION_TYPES.UPDATE_CONTACTS:
    case COMPANY_ACTION_TYPES.UPDATE_INVOICE_RECEIVER:
      return { saveState: true };
    case ACTION_TYPES.UPDATE_FINISHED_CONTRACT:
    case ACTION_TYPES.DELETE_PRODUCT_MODULE:
    case ACTION_TYPES.COPY_PRODUCT:
    case ACTION_TYPES.CREATE_PRODUCT_MODULES:
    case ACTION_TYPES.CREATE_PRODUCT_MODULES_LECTURES:
    case ACTION_TYPES.UPDATE_PRODUCT_MODULES:
    case ACTION_TYPES.CREATE_POLL_PACKAGE:
      return { saveState: true };
    case COURSE_ACTION_TYPES.EDIT_UNIT:
    case COURSE_ACTION_TYPES.ADD_NEW_COURSE:
    case COURSE_ACTION_TYPES.SETTLE_MODULE:
    case COURSE_ACTION_TYPES.PUBLISH_CALENDAR:
    case COURSE_ACTION_TYPES.DRAFT_CANCEL:
    case COURSE_ACTION_TYPES.EDIT_CURRENT_COURSE:
    case COURSE_ACTION_TYPES.CANCEL_CURRENT_COURSE:
      return { saveState: true };
    case ACTION_TYPES.SAVE_STATE_FALSE:
      return { saveState: false };
    case ACTION_TYPES.ADD_MANUAL_INCOME:
    case ACTION_TYPES.UPDATE_INVOICE_BUYER_ADDRESS:
    case ACTION_TYPES.REMOVE_CONTRACT_PAYMENTS_NOTE:
    case ACTION_TYPES.ADD_CONTRACT_PAYMENTS_NOTE:
    case ACTION_TYPES.EDIT_CONTRACT_PAYMENTS_NOTE:
      return { saveState: true };
    case ACTION_TYPES.ADD_REFUND:
      return { saveState: true };
    case ACTION_TYPES.FETCH_INCOMES:
      return { saveState: false };
    default:
      return state;
  }
}

export function fetchErrorCodes(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ERROR_CODES:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function maintenanceMode(state = false, action) {
  switch (action.type) {
    case ACTION_TYPES.MAINTENANCE_MODE_ON:
      return action.payload;
    default:
      return state;
  }
}

export function sendLog(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.SEND_ERROR_LOG:
      return action.payload;
    default:
      return state;
  }
}

export function changeLanguage(state = getInitLanguage(LANGUAGES), action) {
  switch (action.type) {
    case ACTION_TYPES.I18N_SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}

export function tenantSettings(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TENANT_SETTINGS:
      return action.payload.data.data;
  }
  return state;
}
