import CorrectionsEditForm from "../Components/CorrectionsEditForm";
import { formValueSelector, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";
import {
  addInvoiceCorrection,
  deleteInvoiceCorrection,
  updateInvoiceCorrection,
} from "../../Invoices/Actions";
import { success } from "react-notification-system-redux";
import validate from "../../Invoices/Validators/CorrectionInvoiceValidator";

const FORM_NAME = "CORRECTIONS_EDIT";
const CorrectionsEdit = props => <CorrectionsEditForm {...props} />;
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const values = selector(state, "corrections", "newCorrection");

  return {
    values,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addInvoiceCorrection,
      updateInvoiceCorrection,
      deleteInvoiceCorrection,
      success,
    },
    dispatch,
  );

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorrectionsEdit);

export default reduxForm({
  form: FORM_NAME,
  validate,
})(connectToRedux);
