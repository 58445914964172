import React from "react";
import ProductStatus from "./ProductStatus.js";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import Tooltip from "react-simple-tooltip";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";

const ProductItem = props => {
  const {
    id,
    productId,
    name,
    status,
    version,
    kind,
    essentialOwner,
    businessOwner,
    copyProduct,
    t,
    price,
    canCopyProduct,
    canRedirectToProduct,
  } = props;
  const pricesArray = _.map(price);
  const packages = _.keys(price);

  const productItemInfoClasses = "col-sm-9 vertical-center product-item__info";
  const productItemInfo = (
    <div className="container">
      <div className="row">
        <div className="text product-name">
          {`${name} - `}
          {pricesArray.map((price, index) => {
            return (
              <Tooltip padding={5} key={index} content={packages[index]}>
                <span>{`${renderAmountWithCurrency(price)}`}</span>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className="row product-info">
        <span className="product-info-details">
          <ProductStatus status={status} version={version} />
        </span>
        <span className="product-info-details">{kind}</span>
        <span className="product-info-details">
          {t("Bussines owner")}: {businessOwner}
        </span>
        <span className="product-info-details">
          {t("Essential owner")}: {essentialOwner}
        </span>
      </div>
    </div>
  );
  return (
    <div className="row product-item">
      <div className="col-sm-1 vertical-center horizontal-center">
        <Icon.Package size={20} />
      </div>
      {canRedirectToProduct ? (
        <Link
          id={`product-item-${id}`}
          to={`/products/product/${id}`}
          className={productItemInfoClasses}
        >
          {productItemInfo}
        </Link>
      ) : (
        <div className={productItemInfoClasses}>{productItemInfo}</div>
      )}
      <div className="col-sm-2 vertical-center product-item__actions">
        {canCopyProduct ? (
          <button
            className="btn btn-link"
            title="Copy"
            type="button"
            onClick={() => copyProduct(productId)}
          >
            <Icon.Copy size={20} />
          </button>
        ) : null}
      </div>
    </div>
  );
};

ProductItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.object,
  version: PropTypes.number,
  kind: PropTypes.string,
  essentialOwner: PropTypes.string,
  businessOwner: PropTypes.string,
  currency: PropTypes.string,
  copyProduct: PropTypes.func,
  productId: PropTypes.number,
  t: PropTypes.func,
  canCopyProduct: PropTypes.bool,
  canRedirectToProduct: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default translate()(ProductItem);
