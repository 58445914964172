import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchJWTReducer(state = "", action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.token;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.token;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.token;
    case ACTION_TYPES.FETCH_REFRESH_TOKEN:
      return action.payload.data.data.token;
  }
  return state;
}
export function fetchUserIdReducer(state = 0, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.userId;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.userId;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.userId;
  }
  return state;
}
export function fetchPersonIdReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.personId;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.personId;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.personId;
  }
  return state;
}
export function fetchEmployeeIdReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.employeeId;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.employeeId;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.employeeId;
  }
  return state;
}
export function fetchUserNameReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.userName;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.userName;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.userName;
  }
  return state;
}
export function fetchImageUrlReducer(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.imageUrl;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.imageUrl;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.imageUrl;
  }
  return state;
}

export function fetchRefreshToken(state = "", action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.refreshToken;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.refreshToken;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.refreshToken;
    case ACTION_TYPES.FETCH_REFRESH_TOKEN:
      return action.payload.data.data.refreshToken;
  }
  return state;
}

export function getTokenExpiryTime(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_JWT_SERVER:
      return action.payload.tokenExpiryTime;
    case ACTION_TYPES.GET_JWT_SERVER_PASS:
      return action.payload.tokenExpiryTime;
    case ACTION_TYPES.GET_JWT_LOCAL:
      return action.payload.tokenExpiryTime;
    case ACTION_TYPES.FETCH_REFRESH_TOKEN:
      return action.payload.data.data.tokenExpiryTime;
  }
  return state;
}

export function getPermissionsList(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_PERMISSIONS_LIST:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getUserPermissions(
  state = {
    permissions: [],
    roles: [],
  },
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_USER_PERMISSIONS:
      return { ...action.payload.data.data };
    default:
      return state;
  }
}

export function getRolesList(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_ROLES_LIST:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

//TODO: tablica ponizdej do usuniecia po testach
// const custompPerms = [
//   "course_view",
//   // "product_edit",
//   "product_list_edit",
//   "product_view",
//   "person_view",
//   "person_edit",
//   "ang_get_jwt_token",
//   "students_view",
//   "students_list",
// ];
