import { bindActionCreators } from "redux";
import EditLectureModal from "../Components/EditLectureModal";
import { connect } from "react-redux";
import {
  editUnit,
  refreshCurrentCourse,
  settleModule,
} from "../Actions/CoursesActions";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { editUnit, refreshCurrentCourse, settleModule },
    dispatch,
  );
};

const mapStateToProps = (state, { lecture }) => {
  const { id, unitId } = lecture;

  return { lectureId: id, unitId };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditLectureModal);
