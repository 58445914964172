/*
 * Function is checking if given status match to comparing status name
 * compare is doing by dictionary contract statuses
 * @param integer statusId
 * @param string statusName
 */
import { store } from "../Configs/Root";

export default (statusId, statusName) => {
  const statuses = store.getState().dictionaries.contractStatus;
  const status =
    statuses && statusName
      ? statuses.find(element => {
          return element.code.toLowerCase() === statusName.toLowerCase();
        })
      : null;
  return status && status.id === statusId ? true : false;
};
