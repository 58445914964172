import React from "react";
import { translate } from "react-i18next";
import { NumberPicker } from "react-widgets";
import { FieldProps } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { RenderError, renderFieldsHelper } from "../FormikUtils";

type NumberInputProps = FieldProps &
  InjectedTranslateProps & {
    label?: string;
    name?: string;
    disabled?: boolean;
    step?: number;
    defaultValue?: number;
    max?: number;
    min?: number;
    onKeyPress?: ((event: KeyboardEvent) => void) | undefined;
    parse?: string[] | ((str: string, culture: string) => number) | undefined;
    under?: {
      containerClassName?: string;
      fieldClassName?: string;
    };
    customClasses?: {
      containerClassName?: string;
      labelClassName?: string;
      fieldClassName?: string;
    };
  };
const RenderNumberToTranslate: React.FunctionComponent<NumberInputProps> = (
  props: NumberInputProps,
): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);
  const {
    under,
    label,
    max,
    step = 1,
    customClasses,
    t,
    min = 0,
    onKeyPress,
    defaultValue = 0,
    parse,
    disabled,
    form,
    field,
  } = props;
  return (
    <div
      className={`form-group  ${classes} ${
        under
          ? under.containerClassName
          : customClasses
          ? customClasses.containerClassName
          : "row"
      }`}
    >
      <div className={under || customClasses ? "row" : ""}>
        <div
          className={`${
            under
              ? under.fieldClassName
              : customClasses
              ? customClasses.labelClassName
              : "col-sm-4 col-md-3"
          } ${!label || label.length === 0 ? "hidden" : ""}`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div
          className={
            under
              ? under.fieldClassName
              : customClasses
              ? customClasses.fieldClassName
              : "col-sm-8 col-md-9"
          }
        >
          <NumberPicker
            name={field.name}
            min={min}
            defaultValue={defaultValue}
            max={max}
            disabled={disabled}
            step={step}
            format={"#.##"}
            value={props.field.value !== "" ? Number(props.field.value) : null}
            onChange={value => form.setFieldValue(field.name, value)}
            onKeyPress={onKeyPress}
            parse={parse}
          />
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

export const RenderNumber = translate()(RenderNumberToTranslate);
