import React, { Component } from "react";
import { translate } from "react-i18next";
import { RenderSelect } from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field, Form } from "redux-form";
import PropTypes from "prop-types";
import Slave from "../Models/Slave";
import { Edit2 } from "react-feather";
import getCancelToken from "../../../Helpers/GetCancelToken";

class SlavesTable extends Component {
  #cancelToken = getCancelToken();
  #columns = [
    {
      Header: this.props.t("Signature"),
      id: "slaveCourseSignature",
      Cell: ({ original }) => {
        return (
          <div>
            <a
              href={`/courses/${original.slaveCourseId}/settings`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {original.slaveCourseSignature}
            </a>
          </div>
        );
      },
    },
    {
      Header: this.props.t("Diploma number"),
      id: "diploma",
    },
    {
      Header: this.props.t("Module"),
      id: "module",
      accessor: "module.name",
    },
    {
      Header: this.props.t("Finish status"),
      id: "status",
      Cell: ({ original: { finishStatus }, index }) => {
        return this.state.editMode.indexOf(index) !== -1
          ? this.renderEditField(finishStatus, index)
          : this.renderValue(finishStatus, index);
      },
    },
    {
      Cell: ({ index, original }) => {
        return (
          <div className={`action-buttons`}>
            {this.state.editMode.indexOf(index) !== -1 && (
              <button type="submit" className={`btn btn-dark margin__r15`}>
                {this.props.t("Save")}
              </button>
            )}
            <button
              className={`btn`}
              onClick={() => this.removeSlave(original.id)}
            >
              {this.props.t("Remove")}
            </button>
          </div>
        );
      },
      id: "actions",
      maxWidth: 200,
    },
  ];
  state = {
    editMode: [],
    isLoading: false,
  };

  componentDidMount() {
    const { initialize, initialValues, values } = this.props;
    if (!values && initialValues) {
      initialize(initialValues);
    }
  }

  componentDidUpdate(prevProps) {
    const { slaves: currentSlaves, initialize } = this.props;
    const { slaves: prevSlaves } = prevProps;

    if (JSON.stringify(currentSlaves) !== JSON.stringify(prevSlaves)) {
      initialize({ slaves: currentSlaves });
    }
  }

  renderValue = (finishStatus, index) => {
    return (
      <div>
        <span>{finishStatus ? finishStatus.name : ""}</span>{" "}
        <Edit2
          size={16}
          onClick={() => {
            this.enableEditMode(index);
          }}
        />
      </div>
    );
  };

  disableEditMode = index => {
    const { editMode } = this.state;
    const foundIndex = editMode.indexOf(index);
    if (foundIndex !== -1) {
      editMode.splice(foundIndex, 1);
      this.setState({
        editMode,
      });
    }
  };

  enableEditMode = index => {
    const { editMode } = this.state;
    if (editMode.indexOf(index) === -1) {
      this.setState({
        editMode: [...this.state.editMode, index],
      });
    }
  };

  renderEditField = (finishStatus, index) => {
    const { slaveFinishStatus } = this.props;
    return (
      <Field
        name={`slaves[${index}].finishStatus`}
        component={RenderSelect}
        value={finishStatus}
        dropdownConfig={{
          data: slaveFinishStatus || [],
          textField: "name",
          valueField: "id",
        }}
      />
    );
  };

  saveSlave = (slave, index) => {
    const { updateSlave } = this.props;
    const { editMode } = this.state;
    const slaveData = Slave.unifyToUpdate(slave);
    if (slaveData && editMode.indexOf(index) !== -1) {
      updateSlave(slave.id, slaveData, () => {
        this.disableEditMode(index);
      });
    }
  };

  removeSlave = async slaveId => {
    this.setState({ isLoading: true });
    const {
      removeSlave,
      fetchContract,
      contractId,
      fetchPayments,
      updatePayments,
    } = this.props;
    await removeSlave(slaveId);
    fetchContract(this.#cancelToken, { contractId });
    fetchPayments(this.cancelToken, contractId, ({ data }) => {
      if (_.has(data, "data.payments")) {
        updatePayments(data.data.payments);
      }
    });
    this.setState({ isLoading: false, editMode: [] });
  };

  async updateSlaves(slaves, fetchContract, contractId) {
    await slaves.forEach(this.saveSlave);
    fetchContract(this.#cancelToken, { contractId });
  }

  submitForm = ({ slaves }) => {
    const { fetchContract, contractId } = this.props;
    this.updateSlaves(slaves, fetchContract, contractId);
  };

  render() {
    const {
      contract: {
        contract: { slaves },
      },
      handleSubmit,
    } = this.props;

    const { isLoading } = this.state;
    return (
      <Form onSubmit={handleSubmit(this.submitForm)}>
        <ReactTable
          loading={isLoading}
          pageSize={slaves.length}
          data={slaves}
          columns={this.#columns}
        />
      </Form>
    );
  }
}

export default translate()(SlavesTable);

SlavesTable.propTypes = {
  t: PropTypes.func,
  slaves: PropTypes.object,
  contract: PropTypes.object,
  slaveFinishStatus: PropTypes.array,
  values: PropTypes.object,
  updateSlave: PropTypes.func,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  contractId: PropTypes.number,
  fetchContract: PropTypes.func,
  removeSlave: PropTypes.func,
  fetchPayments: PropTypes.func,
  updatePayments: PropTypes.func,
};
