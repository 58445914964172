import React from "react";
import PropTypes from "prop-types";
import { Authorize, permissions } from "../../../../Common/Utils/Acl";
import { HelpCircle, Search } from "react-feather";
import COMMON from "../../../../Enums/Common";
import { translate } from "react-i18next";

const Header = ({ t, logout, searchValue, onChangeSearch }) => {
  return (
    <header className="main-header">
      <div className="row">
        <div className="col-xs-12 col-md-offset-8 col-md-4">
          <div className="row">
            <Authorize
              component={
                <div className="col-xs-6 padding__r0">
                  <div className="input-group">
                    <div className="input-group-addon">
                      <Search size={16} />
                    </div>
                    <input
                      value={searchValue}
                      onChange={onChangeSearch}
                      placeholder={`${t("Surname")}, ${t("signature")}, ${t(
                        "Email",
                      )}`}
                      className="form-control"
                      name="search"
                    />
                  </div>
                </div>
              }
              allows={[permissions.STUDENT_LIST_ALL]}
            />
            <div className="col-xs-6 buttons-block">
              <button onClick={logout} className="btn btn--logout pull-right">
                {t("Log out")}
              </button>
              <a
                href={COMMON.URL.MANUAL}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--logout pull-right left-space"
              >
                <HelpCircle size={16} /> {t("Help")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  logout: PropTypes.func,
  searchValue: PropTypes.string,
  onChangeSearch: PropTypes.func,
  t: PropTypes.func,
};

export default translate()(Header);
