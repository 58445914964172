import { Features } from "../../../Common/Utils/Tenant";

export default fields => {
  const errors = {};
  const MIN_REASON_LENGTH = 10;
  const {
    sourceAccount,
    destinationAccount,
    reason,
    correction,
    invoicesFv,
    transferInvoice,
  } = fields;
  if (!destinationAccount) {
    errors.destinationAccount = "Destination subaccount is required";
  }
  if (destinationAccount && sourceAccount === destinationAccount) {
    errors.destinationAccount =
      "Destination subaccount must be different from the source";
  }
  if (!reason) {
    errors.reason = "Transfer's reason is required";
  }
  if (reason && reason.length < MIN_REASON_LENGTH) {
    errors.reason = "Enter reason of the transfer, not less than 10 characters";
  }
  if (
    Features.invoices &&
    !transferInvoice &&
    invoicesFv &&
    invoicesFv.length > 0 &&
    !correction
  ) {
    errors.correction = "Correcton's number is required";
  }
  return errors;
};
