import { translate } from "react-i18next";
import React, { useState } from "react";
import propTypes from "prop-types";
import ACTION_TYPES from "../../../Enums/ActionTypes";

const ChangePasswordPreview = props => {
  const { changePassword, userEmail, t } = props;
  const [password, setPassword] = useState(null);
  const [passwordRepetition, setPasswordRepetition] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [changeError, setChangeError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSave = () => {
    setChangeError(false);
    if (password !== passwordRepetition || password === "") {
      setPasswordError(true);

      return;
    }

    changePassword({ email: userEmail, password: password }).then(resolve => {
      const { type } = resolve;
      if (type === ACTION_TYPES.PASS_ERROR_TO_STORE) {
        setChangeError(true);
      } else {
        setSuccess(true);
      }
    });
  };

  return (
    <div className="col-md-12 person-base-data">
      <div className="col-sm-6 col-md-4 person-base-data__section">
        <div className="row">
          <div className="row">
            <div className="col-md-12">
              <h2>{t("Login data")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row">
              <div className="col-sm-4 col-md-3">
                <label htmlFor="">E-mail</label>
              </div>
              <div className="col-sm-8 col-md-9">
                <input
                  className="form-control"
                  disabled={true}
                  type="text"
                  value={userEmail}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-md-12">
              <h2>{t("Change password")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {passwordError === true && (
              <div style={{ color: "red" }}>
                <span>{t("Password does not match")}</span>
              </div>
            )}
            {changeError === true && (
              <div style={{ color: "red" }}>
                <span>{t("Password change error. Try again")}</span>
              </div>
            )}
            {success === true && (
              <div style={{ color: "green" }}>
                <span>{t("Password changed")}</span>
              </div>
            )}
            <div className="form-group row">
              <div className="col-sm-4 col-md-3">
                <label htmlFor="">Hasło</label>
              </div>
              <div className="col-sm-8 col-md-9">
                <input
                  className="form-control"
                  disabled={false}
                  type="password"
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value);
                    setPasswordError(false);
                    setSuccess(false);
                    setChangeError(false);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4 col-md-3">
                <label htmlFor="">Powtórz hasło</label>
              </div>
              <div className="col-sm-8 col-md-9">
                <input
                  className="form-control"
                  disabled={false}
                  type="password"
                  value={passwordRepetition}
                  onChange={event => {
                    setPasswordRepetition(event.target.value);
                    setPasswordError(false);
                    setSuccess(false);
                    setChangeError(false);
                  }}
                />
              </div>
            </div>
            <div>
              <input
                type="button"
                onClick={handleSave}
                value={t("Save password")}
                className="btn btn-dark pull-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
``;
ChangePasswordPreview.propTypes = {
  changePassword: propTypes.func,
  userEmail: propTypes.string,
  t: propTypes.func,
};

export default translate()(ChangePasswordPreview);
