import { connect } from "react-redux";
import Product from "../Components/Product";
import { translate } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import {
  changeProductVersionStatus,
  copyVersionProduct,
  deleteProductVersion,
  fetchEmployees,
  fetchProductById,
  fetchProductVersionsMeta,
  fetchVersionsOfProduct,
  resetProduct,
  saveProductVersion,
} from "../../Products/Actions";
import { get } from "lodash";
import { RouteComponentProps } from "react-router";
import { fetchCourseModes } from "../../Courses/Actions/CoursesActions";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import { fetchDictionary } from "../../Dictionaries/Actions";

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductVersionsMeta,
      fetchProductById,
      fetchCourseModes,
      fetchDictionary,
      fetchEmployees,
      copyVersionProduct,
      fetchVersionsOfProduct,
      changeProductVersionStatus,
      deleteProductVersion,
      saveProductVersion,
      resetProduct,
    },
    dispatch,
  );
const mapStateToProps = (store, ownProps: RouteComponentProps) => {
  const versionId = get(ownProps, "match.params.versionId");
  const {
    currentProductVersion,
    currentProductVersions,
    courses: { courseModes },
    employees,
    employeeId,
    lastVersionOfProduct,
  } = store;

  return {
    versionId,
    currentProductVersion,
    currentProductVersions,
    courseModes,
    employees,
    employeeId,
    cantEdit: !havePermissions([permissions.PRODUCT_DETAILS_EDIT]),
    lastVersionOfProduct,
  };
};

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(Product);
