import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { RenderSelect } from "../../../Forms/forms";
import COMMON from "../../../Enums/Common";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import COURSES_LIST_SORT_OPTIONS from "../../../Enums/CoursesListSortOptions";
import {
  PlusCircle,
  Search,
  HelpCircle,
  RefreshCcw,
  Save,
  Delete,
  X,
} from "react-feather";
import CoursesListElement from "./CoursesListElement";
import Loader from "../../../Common/Components/Loader/Loader.js";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import PropTypes from "prop-types";
import CourseSpecialistSelectWithPagination from "../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import MentorsSelectWithPagination from "../../../Common/Components/MentorsSelectWithPagination/MentorsSelectWithPagination";
import SelectWithPagingForFilters from "../../../Common/Components/SelectWithPagingForFilters/SelectWithPagingForFilters";

const FILTER_CONFIG = JSON.parse(localStorage.getItem("filter_config"));
const INIT_REQUEST_PAGE_AMOUNT = 11;

class CoursesList extends Component {
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const {
      fetchCourses,
      fetchDictionary,
      fetchProductsForCourses,
      getCoursesSpecialists,
      fetchAllProducts,
      t,
      fetchMentors,
    } = props;

    this.state = {
      numberOfRowPerPage: 10,
      currentPage: 1,
      isWaitingForData: false,
      isLoading: true,
      selectedFilters: JSON.parse(localStorage.getItem("filter_config")),
      isTooltipExist: false,
      isButtonsShow: FILTER_CONFIG !== null,
      isEmptySelectError: false,
      isRestoreButtonActive: false,
      filtersToShow: {},
      productsList: [],
    };
    this.courseSortOptions = COURSES_LIST_SORT_OPTIONS.map(item => ({
      ...item,
      name: t(item.name),
    }));
    fetchCourses(
      this.cancelToken,
      {
        pageNo: this.state.currentPage,
        pageAmount: this.state.numberOfRowPerPage,
      },
      () => this.setState({ isLoading: false }),
    );

    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: ACTION_TYPES.FETCH_CITIES,
      params: { onlyTeach: true },
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/mode",
      actionType: ACTION_TYPES.FETCH_COURSES_MODES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/status/get",
      actionType: ACTION_TYPES.FETCH_COURSE_STATUSES,
    });
    fetchProductsForCourses(this.cancelToken);
    fetchMentors(this.cancelToken);
    getCoursesSpecialists(this.cancelToken);
    fetchAllProducts(this.cancelToken, {
      simpleResponse: true,
      pageAmount: INIT_REQUEST_PAGE_AMOUNT,
    });
    this.filterSettings();
  }
  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.clearProductsList();
  }
  componentDidMount() {
    const isLocalStorageExist = JSON.parse(
      localStorage.getItem("filter_config"),
    );
    if (isLocalStorageExist) {
      this.setState({
        isButtonsShow: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { values, productsList } = this.props;
    const dateChanged =
      values.startDate !== prevProps.values.startDate ||
      values.endDate !== prevProps.values.endDate;
    if (!dateChanged && !_.isEqual(values, prevProps.values)) {
      this.searchCourse();
    }
    if (
      productsList &&
      _.has(productsList, "items") &&
      !_.isEqual(productsList.items, prevProps.productsList.items)
    ) {
      this.setState({
        productsList: _.uniqBy(
          [...this.state.productsList, ...productsList.items],
          "id",
        ),
      });
    }
  }

  logout = () => {
    this.props.forgetUser();
  };

  searchCourse = _.debounce(() => {
    this.changePage(1);
  }, 500);

  changePage = currentPage => {
    const { fetchCourses, values } = this.props;
    this.setState(
      {
        currentPage,
        isLoading: true,
      },
      () => {
        fetchCourses(
          this.cancelToken,
          {
            pageNo: currentPage,
            pageAmount: this.state.numberOfRowPerPage,
            ...values,
          },
          () => this.setState({ isLoading: false }),
        );
      },
    );
  };
  handleOnChangeField = (item, meta, reduxFieldName) => {
    const { change } = this.props;
    let selectedValue = "";
    let itemId = item ? item.id : null;
    if (!item) {
      change(reduxFieldName, null);
      return;
    }
    switch (reduxFieldName) {
      case "productId": {
        selectedValue = `${item.nameShortcut} ${item.name}`;
        itemId = item.productId;
        break;
      }
      case "cityId": {
        selectedValue = item.name;
        break;
      }
      case "courseModeId": {
        selectedValue = item.name;
        break;
      }
      case "courseStatusId": {
        selectedValue = item.status;
        break;
      }
      case "mentorId": {
        selectedValue = `${item.name} ${item.surname}`;
        break;
      }
      case "courseSpecialistId": {
        selectedValue = `${item.name} ${item.surname}`;
        break;
      }
    }
    change(reduxFieldName, itemId);

    if (selectedValue) {
      this.setState({
        isRestoreButtonActive: true,
        selectedFilters: {
          ...this.state.selectedFilters,
          [reduxFieldName + "Name"]: selectedValue,
        },
      });
    }
  };
  renderCoursesList() {
    const { coursesList, dictionaries } = this.props;
    return coursesList.coursesArray
      ? coursesList.coursesArray.map(course => {
          return (
            <li
              key={course.id}
              id={course.id}
              className={`${course.isCancelled ? "muted with-access" : ""}`}
            >
              <CoursesListElement {...course} dictionaries={dictionaries} />
            </li>
          );
        })
      : "";
  }

  renderSorts() {
    const { change, t } = this.props;
    return (
      <Authorize
        component={
          <Field
            component={RenderSelect}
            name="sort"
            dropdownConfig={{
              data: this.courseSortOptions,
              textField: "name",
              valueField: "value",
              placeholder: t("Sort by") + ":",
            }}
            under={{
              fieldClassName: "col-md-12",
            }}
            onClearCallback={() => change("sort", null)}
          />
        }
        allows={[permissions.COURSE_SEARCH]}
      />
    );
  }

  filterSettings = type => {
    const { change } = this.props;
    const dataFromLocalStorage = localStorage.getItem("filter_config");
    if (dataFromLocalStorage) {
      const config = JSON.parse(dataFromLocalStorage);
      if (type === "refresh") {
        this.setState({
          selectedFilters: config,
        });
      }
      change("productId", config.productId || null);
      change("cityId", config.cityId || null);
      change("courseModeId", config.courseModeId || null);
      change("courseStatusId", config.courseStatusId || null);
      change("mentorId", config.mentorId || null);
      change("courseSpecialistId", config.courseSpecialistId || null);
    }
  };
  handleUserFiltersSettings = (event, filterType) => {
    event.preventDefault();
    const { change } = this.props;
    const {
      productId,
      cityId,
      courseModeId,
      courseStatusId,
      mentorId,
      courseSpecialistId,
    } = this.props.values;

    switch (filterType) {
      case "refresh": {
        this.filterSettings(filterType);
        this.setState({
          isTooltipExist: false,
          isRestoreButtonActive: false,
        });
        break;
      }
      case "save": {
        const uniqueSelectedFiltersId = {};
        const availableFiltersFromStore = {
          productId,
          cityId,
          courseModeId,
          courseStatusId,
          mentorId,
          courseSpecialistId,
        };

        const productVersionId =
          productId && this.state.productsList.length > 0
            ? this.state.productsList.filter(item => item.id === productId)[0]
                .id
            : "";

        if (
          productId ||
          cityId ||
          courseModeId ||
          courseStatusId ||
          mentorId ||
          courseSpecialistId
        ) {
          Object.keys(availableFiltersFromStore).map(filter => {
            if (availableFiltersFromStore[filter]) {
              return (uniqueSelectedFiltersId[filter] =
                availableFiltersFromStore[filter]);
            }
          });

          this.setState(
            {
              isRestoreButtonActive: false,
              isEmptySelectError: false,
              isTooltipExist: true,
              isButtonsShow: true,
              selectedFilters: {
                ...this.state.selectedFilters,
                ...uniqueSelectedFiltersId,
                productVersionId,
              },
              filtersToShow: {
                ...this.state.selectedFilters,
                ...uniqueSelectedFiltersId,
              },
            },
            () => {
              localStorage.setItem(
                "filter_config",
                JSON.stringify(this.state.selectedFilters),
              );
            },
          );
          return;
        }

        this.setState({
          isEmptySelectError: true,
        });
        break;
      }
      case "remove": {
        localStorage.removeItem("filter_config");

        change("productId", null);
        change("cityId", null);
        change("courseModeId", null);
        change("courseStatusId", null);
        change("mentorId", null);
        change("courseSpecialistId", null);
        this.setState({
          selectedFilters: null,
          isTooltipExist: false,
          isButtonsShow: false,
          isRestoreButtonActive: false,
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  getProductVersionsRequestConfig = () => {
    const ACTIVE = 2;

    return {
      simpleResponse: true,
      status: ACTIVE,
    };
  };

  handleClearSelectedFilter = fieldName => {
    this.props.change(fieldName, null);
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [fieldName + "Name"]: null,
        [fieldName]: null,
      },
      isRestoreButtonActive: true,
    });
  };
  renderFilters() {
    const {
      dictionaries,
      coursesSpecialists,
      mentorsList,
      t,
      fetchAllProducts,
    } = this.props;
    const underClasses = {
      containerClassName: "col-lg-2 col-md-3 col-xs-6",
      fieldClassName: "col-xs-12",
    };
    const { productsList } = this.state;
    return (
      <Authorize
        component={
          <div id="filters" className="col-sm-12">
            <div className="row">
              <div className="col-xs-12">
                <label className="label label--top">{t("Filters")}</label>
              </div>
            </div>
            <div className="row">
              <div className="width">
                <SelectWithPagingForFilters
                  fetchData={fetchAllProducts}
                  dataItems={productsList}
                  placeholder={t("Select the product")}
                  onClear={() => this.handleClearSelectedFilter("productId")}
                  under={underClasses}
                  selectId="product-version-select"
                  containerId="product-version"
                  labelKey="name"
                  customItemRender={product => (
                    <span title={product.name}>{product.name}</span>
                  )}
                  onChangeSelect={product =>
                    this.handleOnChangeField(product, null, "productId")
                  }
                  additionalRequestConfig={this.getProductVersionsRequestConfig()}
                />
              </div>
              <Field
                component={RenderSelect}
                name="cityId"
                dropdownConfig={{
                  data: dictionaries.cities,
                  textField: "name",
                  valueField: "id",
                  placeholder: t("Cities"),
                  onChange: this.handleOnChangeField,
                }}
                under={underClasses}
                onClearCallback={() => this.handleClearSelectedFilter("cityId")}
              />
              <Field
                component={RenderSelect}
                name="courseModeId"
                dropdownConfig={{
                  data: dictionaries.coursesModes,
                  textField: "name",
                  valueField: "id",
                  placeholder: t("Modes"),
                  onChange: this.handleOnChangeField,
                }}
                under={underClasses}
                onClearCallback={() =>
                  this.handleClearSelectedFilter("courseModeId")
                }
              />
              <Field
                component={RenderSelect}
                name="courseStatusId"
                dropdownConfig={{
                  data: dictionaries.courseStatuses,
                  textField: "status",
                  valueField: "id",
                  placeholder: t("Statuses"),
                  onChange: this.handleOnChangeField,
                }}
                under={underClasses}
                onClearCallback={() =>
                  this.handleClearSelectedFilter("courseStatusId")
                }
              />
              <Field
                component={MentorsSelectWithPagination}
                name="mentorId"
                onChangeHandler={this.handleOnChangeField}
                placeholder={t("All mentors")}
                getMentorsLists={this.props.fetchMentors}
                mentors={mentorsList}
                under={underClasses}
                onClearCallback={() =>
                  this.handleClearSelectedFilter("mentorId")
                }
              />
              <Field
                component={CourseSpecialistSelectWithPagination}
                name="courseSpecialistId"
                onChangeHandler={this.handleOnChangeField}
                placeholder={t("All curse specialists")}
                getCoursesSpecialists={this.props.getCoursesSpecialists}
                coursesSpecialists={coursesSpecialists}
                under={underClasses}
                onClearCallback={() =>
                  this.handleClearSelectedFilter("courseSpecialistId")
                }
              />
            </div>
          </div>
        }
        allows={[permissions.COURSE_SEARCH]}
      />
    );
  }
  handleCloseTooltip = event => {
    event.preventDefault();
    this.setState({ isTooltipExist: false });
  };

  render() {
    const { coursesList, t } = this.props;
    const { numberOfRowPerPage, isLoading, filtersToShow } = this.state;
    const correctFiltersOrder = [
      "productIdName",
      "cityIdName",
      "courseModeIdName",
      "courseStatusIdName",
      "mentorIdName",
      "courseSpecialistIdName",
    ];
    const sortedFiltersToShow = _.pick(filtersToShow, correctFiltersOrder);

    const noData = !coursesList.counter && (
      <div className={`row product-item`}>
        <div className={`vertical-center horizontal-center`}>
          <p>{this.props.t("No records found")}</p>
        </div>
      </div>
    );

    return (
      <main>
        <div className="custom-container">
          <header className="main-header">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6 pull-right main-header__flex">
                <Authorize
                  component={
                    <div className="col-md-8 col-xs-12">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <Search size={16} />
                        </div>
                        <Field
                          className="form-control"
                          name="search"
                          component="input"
                          type="text"
                        />
                      </div>
                    </div>
                  }
                  allows={[permissions.COURSE_SEARCH]}
                />
                <button
                  onClick={this.logout}
                  className="btn btn--logout pull-right"
                >
                  {t("Log out")}
                </button>
                <a
                  href={COMMON.URL.MANUAL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--logout pull-right left-space"
                >
                  <HelpCircle size={16} /> {t("Help")}
                </a>
              </div>
            </div>
          </header>
        </div>
        <div className=" row">
          <div className="col-xs-12 content">
            <div className="custom-container">
              <div className="row">
                <div className="row bottom-space">
                  <Authorize
                    component={
                      <div id="add-new-course" className="col-md-3 col-xs-12">
                        <Link
                          to="/courses/new"
                          className="btn btn-regular btn-link btn-with-icon"
                        >
                          <PlusCircle size={20} /> {t("Add new course")}
                        </Link>
                      </div>
                    }
                    allows={[
                      permissions.COURSE_DETAILS_EDIT,
                      permissions.COURSE_DETAILS_VIEW,
                    ]}
                  />
                </div>
                <div className="row">
                  <div className="col-md-7 course__reports-actions">
                    <div id="sorts" className="col-md-3 pull-left padding__l0">
                      {this.renderSorts()}
                    </div>
                  </div>
                  <div className="col-md-5 pull-left padding__l0 course__reports-user-filters">
                    {this.state.isButtonsShow && (
                      <div className="filter__block-refresh">
                        <button
                          className="btn btn-dark btn-margin"
                          onClick={event =>
                            this.handleUserFiltersSettings(event, "refresh")
                          }
                          disabled={!this.state.isRestoreButtonActive}
                        >
                          <RefreshCcw size={14} /> {t("restore filter")}
                        </button>
                      </div>
                    )}

                    <div className="filter__block-save">
                      <button
                        className="btn btn-dark btn-margin"
                        onClick={event =>
                          this.handleUserFiltersSettings(event, "save")
                        }
                      >
                        <Save size={14} /> {t("save filter")}
                      </button>
                      {this.state.isEmptySelectError && (
                        <span className="filter_error">
                          {t("Choose filter")}
                        </span>
                      )}
                      {this.state.isTooltipExist && !isMobile && (
                        <div className="filter__tooltip">
                          <ul>
                            {sortedFiltersToShow &&
                              Object.keys(sortedFiltersToShow).map(filter => {
                                if (
                                  typeof sortedFiltersToShow[filter] ===
                                  "string"
                                ) {
                                  return (
                                    <li key={filter}>
                                      {sortedFiltersToShow[filter]} <br />
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                          <span
                            className="close"
                            onClick={this.handleCloseTooltip}
                          >
                            <X size={24} />
                          </span>
                        </div>
                      )}
                    </div>
                    {this.state.isButtonsShow && (
                      <div className="filter__block-remove">
                        <button
                          className="btn btn-dark btn-margin"
                          onClick={event =>
                            this.handleUserFiltersSettings(event, "remove")
                          }
                        >
                          <Delete size={14} /> {t("remove filter")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row">{this.renderFilters()}</div>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="row">
                  <ul id="courses-list" className="list-unstyled">
                    {this.renderCoursesList()}
                  </ul>
                </div>
              )}
              {noData}
              <div className="row">
                {coursesList.counter > numberOfRowPerPage ? (
                  <Paginator
                    onClick={this.changePage}
                    pages={Math.ceil(coursesList.counter / numberOfRowPerPage)}
                    currentPage={this.state.currentPage}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default translate()(CoursesList);

CoursesList.propTypes = {
  t: PropTypes.func,
  fetchCourses: PropTypes.func,
  fetchDictionary: PropTypes.func,
  fetchProductsForCourses: PropTypes.func,
  getCoursesSpecialists: PropTypes.func,
  fetchMentors: PropTypes.func,
  values: PropTypes.shape({
    endDate: PropTypes.number,
    startDate: PropTypes.number,
    productId: PropTypes.number,
    cityId: PropTypes.number,
    courseModeId: PropTypes.number,
    courseStatusId: PropTypes.number,
    mentorId: PropTypes.number,
    courseSpecialistId: PropTypes.number,
  }),
  forgetUser: PropTypes.func,
  change: PropTypes.func,
  coursesList: PropTypes.object,
  dictionaries: PropTypes.object,
  productsListForCourse: PropTypes.array,
  coursesSpecialists: PropTypes.array,
  mentorsList: PropTypes.array,
  fetchAllProducts: PropTypes.func,
  productsList: PropTypes.object,
  clearProductsList: PropTypes.func,
};
