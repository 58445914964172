import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import moment from "moment";
import COMMON from "../../../Enums/Common";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import { RenderDateTime, RenderSelect } from "../../../Forms/forms";
import getCancelToken from "../../../Helpers/GetCancelToken";
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Area,
  Legend,
  Line,
  LabelList,
} from "recharts";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

class ReportForm extends Component {
  static propTypes = {
    change: PropTypes.func,
    fetchAllProducts: PropTypes.func,
    fetchDictionary: PropTypes.func,
    fetchCourses: PropTypes.func,
    values: PropTypes.object,
    fetchReport: PropTypes.func,
    t: PropTypes.func,
    dictionaries: PropTypes.object,
    reportData: PropTypes.array,
    productsList: PropTypes.object,
    coursesList: PropTypes.object,
    width: PropTypes.number,
    fetchAllProductsFullInfo: PropTypes.func,
    allProductsFullInfo: PropTypes.array,
  };
  constructor(props) {
    super(props);
    const {
      fetchAllProducts,
      fetchDictionary,
      fetchCourses,
      fetchAllProductsFullInfo,
    } = props;

    this.state = {
      places: false,
      contract_send: false,
      during_recruitment: false,
      resigns: false,
      contract_signed: false,
    };
    this.cancelToken = getCancelToken();
    fetchAllProducts(this.cancelToken);
    if (havePermissions([permissions.DICTIONARY_VIEW])) {
      fetchDictionary(this.cancelToken, {
        path: "/cities",
        actionType: ACTION_TYPES.FETCH_CITIES,
        params: { onlyTeach: true },
      });
      fetchDictionary(this.cancelToken, {
        path: "/course/mode",
        actionType: ACTION_TYPES.FETCH_COURSES_MODES,
      });
    }
    fetchCourses(this.cancelToken);
    fetchAllProductsFullInfo(this.cancelToken);
  }

  componentDidMount() {
    const { change } = this.props;
    change(
      "startDate",
      moment()
        .startOf("month")
        .valueOf(),
    );
    change(
      "endDate",
      moment()
        .endOf("month")
        .add(1, "months")
        .valueOf(),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      values: { startDate, endDate, cityId, courseId, courseModeId, productId },
      fetchReport,
      fetchAllProducts,
      fetchCourses,
    } = this.props;
    const {
      values: {
        startDate: prevStartDate,
        endDate: prevEndDate,
        cityId: prevCityId,
        courseId: prevCourseId,
        courseModeId: prevCourseModeId,
        productId: prevProductId,
      },
    } = prevProps;

    const values = [
      [startDate, prevStartDate],
      [endDate, prevEndDate],
      [cityId, prevCityId],
      [courseId, prevCourseId],
      [courseModeId, prevCourseModeId],
      [productId, prevProductId],
    ];

    if (!values.every(element => element[0] === element[1])) {
      const params = {
        startDate,
        endDate,
        cityId,
        courseId,
      };
      fetchReport(this.cancelToken, {
        ...params,
        modeId: courseModeId,
        productId: productId,
      });

      fetchCourses(this.cancelToken, {
        ...params,
        courseModeId,
        productId,
      });

      fetchAllProducts(this.cancelToken, {
        startDate,
        endDate,
      });
    }
  }

  editChart = data => {
    this.setState(previousState => {
      return {
        ...previousState,
        [data.payload.dataKey]: !previousState[data.payload.dataKey],
      };
    });
  };

  render() {
    const {
      t,
      dictionaries: { cities, coursesModes },
      change,
      reportData,
      coursesList,
      width,
      allProductsFullInfo,
    } = this.props;
    const {
      places,
      contract_send,
      during_recruitment,
      resigns,
      contract_signed,
    } = this.state;

    return (
      <Fragment>
        <section className="form-section">
          <div className="row horizontal-center">
            <Field
              name="startDate"
              label={t("Start date")}
              component={RenderDateTime}
              under={{
                containerClassName:
                  "col-md-3 col-md-offset-0 col-sm-6 col-sm-offset-0",
                fieldClassName: "col-sm-12 col-md-12",
              }}
              views={["year", "decade"]}
              time={false}
              required
              dateFormat="MM.YYYY"
            />
            <Field
              name="endDate"
              label={t("End date")}
              component={RenderDateTime}
              under={{
                containerClassName:
                  "col-md-3 col-md-offset-0 col-sm-6 col-sm-offset-0",
                fieldClassName: "col-sm-12 col-md-12",
              }}
              views={["year", "decade"]}
              time={false}
              required
              dateFormat="MM.YYYY"
            />
          </div>

          <div className="row col-md-12 horizontal-center">
            <div className="col-md-10 col-sm-12 horizontal-center">
              <Field
                component={RenderSelect}
                name="cityId"
                dropdownConfig={{
                  data: cities,
                  textField: "name",
                  valueField: "id",
                  placeholder: t("Cities"),
                }}
                under={{
                  containerClassName: "col-sm-3 col-xs-6",
                  fieldClassName: "col-xs-12",
                }}
                onClearCallback={() => change("cityId", null)}
              />
              <Field
                component={RenderSelect}
                name="productId"
                dropdownConfig={{
                  data: allProductsFullInfo.map(product => ({
                    ...product,
                    productDisplayName: `${product.shortcut} ${product.name}`,
                  })),
                  textField: "productDisplayName",
                  valueField: "id",
                  placeholder: t("Product"),
                }}
                under={{
                  containerClassName: "col-sm-3 col-xs-6",
                  fieldClassName: "col-xs-12",
                }}
                onClearCallback={() => change("productId", null)}
              />
              <Field
                component={RenderSelect}
                name="courseModeId"
                dropdownConfig={{
                  data: coursesModes,
                  textField: "name",
                  valueField: "id",
                  placeholder: t("Modes"),
                }}
                under={{
                  containerClassName: "col-sm-3 col-xs-6",
                  fieldClassName: "col-xs-12",
                }}
                onClearCallback={() => change("courseModeId", null)}
              />
              <Field
                component={RenderSelect}
                name="courseId"
                dropdownConfig={{
                  data: coursesList.coursesArray,
                  textField: "signature",
                  valueField: "id",
                  placeholder: t("Courses"),
                }}
                under={{
                  containerClassName: "col-sm-3 col-xs-6",
                  fieldClassName: "col-xs-12",
                }}
                onClearCallback={() => change("courseId", null)}
              />
            </div>
          </div>
          <div className="col-md-12 row horizontal-center">
            <ResponsiveContainer
              width={width || "90%"}
              aspect={COMMON.REPORT_ASPECT}
            >
              <ComposedChart
                data={reportData}
                margin={{
                  top: 50,
                  right: 210,
                  left: 0,
                  bottom: 50,
                }}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="middle"
                  layout="vertical"
                  iconSize={18}
                  onClick={this.editChart}
                />
                <Line
                  dataKey="contract_send"
                  type="monotone"
                  legendType="rect"
                  stroke="#4d4d4f"
                  dot={true}
                  hide={contract_send}
                  name={t("Contracts sent")}
                  strokeDasharray="5 5"
                  activeDot={true}
                  strokeWidth={3}
                  isAnimationActive={false}
                >
                  <LabelList dataKey="contract_send" position="top" />
                </Line>
                <Bar
                  dataKey="places"
                  type="monotone"
                  legendType="rect"
                  hide={places}
                  fill="#fc3"
                  name={t("Number of places")}
                  id="places"
                  barSize={34}
                >
                  <LabelList dataKey="places" position="insideTop" />
                </Bar>
                <Line
                  dataKey="during_recruitment"
                  type="monotone"
                  legendType="rect"
                  stroke="#00A99D"
                  dot={true}
                  activeDot={true}
                  hide={during_recruitment}
                  name={t("During recruitment")}
                  strokeWidth={3}
                  isAnimationActive={false}
                >
                  <LabelList dataKey="during_recruitment" position="top" />
                </Line>
                <Line
                  dataKey="resigns"
                  type="monotone"
                  legendType="rect"
                  stroke="#C1272D"
                  dot={true}
                  activeDot={true}
                  hide={resigns}
                  name={t("Resignations")}
                  id="resigns"
                  strokeWidth={3}
                  isAnimationActive={false}
                >
                  <LabelList dataKey="resigns" position="top" />
                </Line>
                <Area
                  dataKey="contract_signed"
                  type="monotone"
                  legendType="rect"
                  fill="#75727c"
                  dot={true}
                  activeDot={true}
                  hide={contract_signed}
                  stroke="#e3e3e3"
                  name={t("Signed")}
                  fillOpacity={0.3}
                >
                  <LabelList dataKey="contract_signed" position="top" />
                </Area>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default translate()(ReportForm);
