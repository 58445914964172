import React from "react";
import { Form, Field } from "redux-form";
import {
  RenderCheckbox,
  RenderDateTime,
  RenderSelect,
  RenderTextArea,
} from "../../../Forms/forms";
import { translate } from "react-i18next";
import propTypes from "prop-types";
import PriceFieldWithCalculation from "../../../Forms/PriceFieldWithCalculation";
import moment from "moment";
import getInvoiceIssueMonthsForSelect from "../../../Helpers/GetInvoiceIssueMonthsForSelect";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getPaymentTypeDropdownConfig from "../../../Helpers/GetPaymentTypeDropdownConfig";
import SelectInvoiceReceiverWithSearch from "../../../Common/Components/SelectInvoiceReceiverWithSearch/SelectInvoiceReceiverWithSearch";
import RenderReceiverSelectOption from "../../../Helpers/RenderReceiverSelectOption";
import AmountField from "../../../Forms/AmountField";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import { isEqual } from "lodash";

class InvoiceEditForm extends React.Component {
  static propTypes = {
    handleSubmit: propTypes.func,
    t: propTypes.func,
    invoice: propTypes.object,
    saveInvoice: propTypes.func,
    values: propTypes.object,
    initialize: propTypes.func,
    priceGrossCalculator: propTypes.func,
    closeForm: propTypes.func,
    fetchInvoiceReceivers: propTypes.func,
    invoiceReceivers: propTypes.array,
    incomes: propTypes.array,
    change: propTypes.func,
    tenantSettings: propTypes.object,
    fetchTenantSettings: propTypes.func,
    fetchLegalBasis: propTypes.func,
    updateInvoiceBuyerAddress: propTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      vatRates: [],
      confirmModalIsOpen: false,
    };
    props.fetchTenantSettings(this.cancelToken);
  }

  componentDidUpdate(prevProps) {
    const {
      tenantSettings,
      values: { vatRate: currentVatRate, issueMonth },
      fetchLegalBasis,
      invoiceLegalBasis: currentLegalBasis,
      change,
    } = this.props;

    const {
      values: { vatRate: prevVatRate },
      invoiceLegalBasis: prevLegalBasis,
    } = prevProps;

    if (
      tenantSettings &&
      _.has(tenantSettings, "vatRates") &&
      !_.isEqual(tenantSettings.vatRates, this.state.vatRates)
    ) {
      this.setState({
        vatRates: tenantSettings.vatRates,
      });
    }

    if (currentVatRate && currentVatRate !== prevVatRate) {
      fetchLegalBasis(this.cancelToken, currentVatRate, issueMonth);
    }

    if (!isEqual(currentLegalBasis, prevLegalBasis)) {
      change("hasLegalBasis", currentLegalBasis.length > 0);
    }
  }

  componentDidMount() {
    const { values, initialize, invoice } = this.props;
    if (!values.id) {
      const receiver = invoice.receiver;
      if (receiver) {
        receiver.uniqKey = `${receiver.type}-${receiver.receiverId}`;
      }
      initialize({
        ...invoice,
        receiver,
        amountGross: invoice.total,
        issueMonth: invoice.issueDate,
        paymentType: invoice.paymentType,
        vatRate: invoice.vatRate,
        incomeable: invoice.incomes.length ? false : !invoice.incomeable,
      });
    }
  }

  onSubmit = ({
    description,
    receiver,
    amountGross,
    sellDate,
    issueMonth,
    paymentType,
    vatRate,
    client,
    incomeable,
    legalBasis,
  }) => {
    const { invoice, closeForm, saveInvoice } = this.props;
    const payload = {
      amountGross,
      description,
      receiver,
      sellDate,
      vatRate,
      paymentType,
      incomeable: !incomeable,
      id: invoice.id,
      clientBalanceId: client && client.balanceId,
      issueMonth: moment(issueMonth).format("Y-MM"),
      legalBasis,
    };

    saveInvoice(invoice.id, payload, closeForm);
  };

  setReceiver = value => this.props.change("receiver", value);

  setClient = value => this.props.change("client", value);

  setIncomeable = value => this.props.change("incomeable", !value);

  openConfirmModal = () => this.setState({ confirmModalIsOpen: true });

  closeConfirmModal = () => this.setState({ confirmModalIsOpen: false });

  updateBuyerAddress = () => {
    const {
      updateInvoiceBuyerAddress,
      invoice: { id: invoiceId },
    } = this.props;
    if (invoiceId) {
      updateInvoiceBuyerAddress(this.cancelToken, invoiceId);
    }
  };

  handleChangeVatRate = option => {
    const { change } = this.props;
    change("vatRate", option.value);
    change("legalBasis", null);
  };

  render() {
    const {
      t,
      handleSubmit,
      invoice,
      priceGrossCalculator,
      fetchInvoiceReceivers,
      invoiceReceivers,
      values,
      values: { hasLegalBasis },
      invoiceLegalBasis,
    } = this.props;

    const { confirmModalIsOpen } = this.state;

    return (
      <Form
        id="invoiceEditForm"
        className="invoice-form__container"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <div className="col-sm-3">
          <Authorize
            allows={[permissions.INVOICE_ADDRESS_UPDATE]}
            component={
              <button
                onClick={this.openConfirmModal}
                type="button"
                className="btn btn-dark"
                id="update-buyer-address-button"
              >
                {t("Update buyer address")}
              </button>
            }
          />
        </div>
        <div className="invoice-form__row-edit">
          <div className="col-sm-3">
            <Field
              under
              component={RenderTextArea}
              label={t("Description")}
              name="description"
            />
          </div>
          <div className="col-sm-3">
            <Field
              under
              name="paymentType"
              label={t("Payment method")}
              component={RenderSelect}
              dropdownConfig={getPaymentTypeDropdownConfig(t)}
            />
          </div>
          <div className="col-sm-3">
            <Field
              name="receiver"
              under={true}
              label={t("Receiver")}
              labelKey={"name"}
              placeholder={t("Select the receiver")}
              fetchData={fetchInvoiceReceivers}
              receivers={invoiceReceivers}
              component={SelectInvoiceReceiverWithSearch}
              renderMenuItemChildren={RenderReceiverSelectOption}
              onChangeHandler={this.setReceiver}
            />
          </div>
          {!invoice.incomes.length && (
            <div className="col-sm-3">
              <Field
                name="client"
                under={true}
                label={t("Client")}
                labelKey={"name"}
                placeholder={
                  invoice.customer ? invoice.customer : t("Select the client")
                }
                fetchData={fetchInvoiceReceivers}
                receivers={invoiceReceivers}
                component={SelectInvoiceReceiverWithSearch}
                renderMenuItemChildren={RenderReceiverSelectOption}
                onChangeHandler={this.setClient}
              />
            </div>
          )}
        </div>
        <div className="invoice-form__row-edit">
          <div className="col-sm-3">
            <AmountField
              component={PriceFieldWithCalculation}
              label={t("Gross amount")}
              name="amountGross.amount"
              t={t}
              currency={invoice.total.currency}
              priceCalculator={priceGrossCalculator}
              vatRate={values.vatRate}
              under
            />
          </div>
          <div id="vatRate" className="col-xs-3">
            <Field
              required="required"
              under
              name="vatRate"
              label={t("Tax rate")}
              component={RenderSelect}
              dropdownConfig={{
                data: this.state.vatRates.map(vatRate => {
                  return {
                    label: vatRate,
                    value: vatRate,
                  };
                }),
                textField: "label",
                valueField: "value",
                onChange: this.handleChangeVatRate,
              }}
            />
          </div>
          <div className="col-xs-3" id="legalBasis">
            <Field
              required="required"
              under
              name="legalBasis"
              label={t("Legal basis")}
              disabled={!hasLegalBasis}
              component={RenderSelect}
              dropdownConfig={{
                data: invoiceLegalBasis,
                textField: "name",
                valueField: "id",
              }}
            />
          </div>
        </div>
        <div className="invoice-form__row-edit" style={{ paddingLeft: 16 }}>
          <div className="col-xs-3">
            <Field
              under
              component={RenderDateTime}
              label={t("Sell date")}
              name="sellDate"
              time={false}
              max={moment().add(30, "days")}
            />
          </div>
          <div className="col-xs-3">
            <Field
              required="required"
              name="issueMonth"
              label={t("Issue month")}
              component={RenderSelect}
              format={value => moment(value).format("MMMM Y")}
              dropdownConfig={{
                data: getInvoiceIssueMonthsForSelect(),
                textField: "label",
                valueField: "id",
              }}
              under
            />
          </div>
          {!invoice.incomes.length && (
            <div className="col-xs-3">
              <Field
                name="incomeable"
                labelForCheckbox={t("No income required")}
                component={RenderCheckbox}
                onChangeHandler={this.setIncomeable}
              />
            </div>
          )}
        </div>
        <div className="col-xs-4">
          <button
            onClick={handleSubmit(this.onSubmit)}
            type="button"
            className="btn btn-dark"
          >
            {t("Save invoice")}
          </button>
        </div>
        <ConfirmModal
          className="update-buyer-address-confirm-modal"
          isOpen={confirmModalIsOpen}
          title={t("Are You sure You want to update buyer address?")}
          onRequestClose={this.closeConfirmModal}
          ariaHideApp={false}
          config={{
            onRequestConfirm: this.updateBuyerAddress,
          }}
        />
      </Form>
    );
  }
}

export default translate()(InvoiceEditForm);
