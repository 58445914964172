import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { connect } from "react-redux";
import { getJwt, getJwtByPass } from "../../Auth/Actions";
import { bindActionCreators, compose } from "redux";
import { Features } from "../../../Common/Utils/Tenant";
import MainTemplate from "./MainTemplate.js";
import MainTemplateContent from "./MainTemplateContent.js";
import DictionaryRooms from "../../Dictionaries/Containers/DictionaryRooms";
import DictionaryPolls from "../../Dictionaries/Containers/DictionaryPolls";
import DictionaryCoursesKinds from "../../Dictionaries/Containers/DictionaryCoursesKinds";
import DictionaryCoursesModes from "../../Dictionaries/Containers/DictionaryCoursesModes";
import DictionaryEmployeeAvailability from "../../Dictionaries/Containers/DictionaryEmployeeAvailability";
import DictionaryAdditionalCompetencies from "../../Dictionaries/Containers/DictionaryAdditionalCompetencies";
import Products from "../../Products/Containers/Products.js";
import Login from "../../Auth/Containers/Login";
import PersonFormWithConnect from "../../Persons/Containers/PersonFormWithConnect";
import PersonsList from "../../Persons/Containers/PersonsList";
import Reports from "../../Reports/Containers/Reports";
import HomePage from "../../HomePage/Components/HomePage.js";
import Courses from "../../Courses/Containers/CoursesPage";
import CourseStudents from "../../Courses/Containers/CourseStudents";
import simpleNumberLocalizer from "react-widgets-simple-number";
import ContractDetails from "../../Contracts/Containers/ContractDetails";
import { getPermissionsList, getUserPermissions } from "../../Auth/Actions";
import {
  RouteAuthorize,
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";
import { translate } from "react-i18next";
import Loader from "../../../Common/Components/Loader/Loader";
import ErrorBoundry from "../../../Common/Utils/ErrorBoundry";
import TimeLineReport from "../../Reports/Containers/TimeLineReport";
import LecturersSettlementReport from "../../Reports/Containers/LecturersSettlement";
import GroupDetails from "../../Reports/Containers/GroupFill";
import CurrentPollResults from "../../Reports/Containers/CurrentPollResults";
import DictionaryPollTypes from "../../Dictionaries/Containers/DictionaryPollTypes";
import Graduates from "../../Reports/Containers/Graduates";
import CoursePoll from "../../Courses/Containers/CoursePoll";
import PropTypes from "prop-types";
import Maintenance from "../../SpecialPages/Components/Maintenance";
import Incomes from "../../Reports/Containers/Incomes";
import IncomesTransfersList from "../../Reports/Containers/IncomesTransfersList";
import Refunds from "../../Reports/Containers/Refunds";
import Invoices from "../../Reports/Invoice/InvoicesContainer";
import ContractPayments from "../../Reports/Containers/ContractPayments";
import Balance from "../../Balance/Containers/Balance";
import Balances from "../../Reports/Containers/Balances";
import Dinero from "dinero.js";
import CompaniesList from "../../Companies/Containers/List";
import CompanyDetails from "../../Companies/Containers/Details";
import DictionaryLecturesTypes from "../../Dictionaries/Containers/DictionaryLecturesTypes";
import CourseSettingsContainer from "../../CourseSettings/Containers/CourseSettings";
import XLSReports from "../../Reports/Components/XLSReports";
import DictionaryPipedriveMap from "../../Dictionaries/Containers/DictionaryPipedriveMap";
import DictionaryComments from "../../Dictionaries/Containers/DictionaryComments";
import Promotions from "../../Dictionaries/Containers/DictionaryPromotions";
import Product from "../../ProductsNew/Containers/ProductContainer";

class MainApp extends Component {
  static propTypes = {
    language: PropTypes.string,
    getJwt: PropTypes.func,
    getJwtByPass: PropTypes.func,
    getPermissionsList: PropTypes.func,
    getUserPermissions: PropTypes.func,
    token: PropTypes.string,
    haveToken: PropTypes.bool,
    userId: PropTypes.number,
    t: PropTypes.func,
    maintenanceMode: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      maintenance: false,
    };
    Moment.locale(props.language);
    momentLocalizer();
    simpleNumberLocalizer();
    this.props.getJwt();
    Dinero.globalLocale = props.language;
  }

  onLoginHandler = (
    googleAccessToken = "",
    email = "",
    name = "",
    image = "",
  ) => {
    return this.props.getJwt({
      googleAccessToken,
      email,
      name,
      image,
    });
  };

  onLoginByPassHandler = (email = "", password = "") => {
    return this.props.getJwtByPass({
      email,
      password,
    });
  };

  componentDidUpdate() {
    const { maintenanceMode } = this.props;
    if (maintenanceMode !== this.state.maintenance) {
      this.setState({
        maintenance: maintenanceMode,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      getPermissionsList,
      getUserPermissions,
      token,
      language,
    } = this.props;
    if (nextProps.haveToken && token !== nextProps.token) {
      getPermissionsList(nextProps.token);
      getUserPermissions(nextProps.userId);
    }
    if (language !== nextProps.language) {
      Moment.locale(nextProps.language);
      momentLocalizer();
      Dinero.globalLocale = nextProps.language;
    }
  }

  reportsSecondaryMenu = () => {
    const { t } = this.props;
    const secondaryMenu = [];

    if (havePermissions([permissions.REPORT_TREND])) {
      secondaryMenu.push({
        name: t("Charts"),
        link: "/reports/contracts",
      });
    }

    if (havePermissions([permissions.REPORT_TIMELINE])) {
      secondaryMenu.push({
        name: t("Timeline"),
        link: "/reports/timeline",
      });
    }

    if (havePermissions([permissions.REPORT_GROUPFILL])) {
      secondaryMenu.push({
        name: t("Group details"),
        link: "/reports/groups",
      });
    }

    if (havePermissions([permissions.REPORT_GRADUATES])) {
      secondaryMenu.push({
        name: t("Graduates"),
        link: "/reports/graduates",
      });
    }

    if (
      havePermissions([
        permissions.REPORT_TREND,
        permissions.REPORT_TIMELINE,
        permissions.REPORT_GROUPFILL,
        permissions.REPORT_GRADUATES,
        permissions.REPORT_BALANCE,
        permissions.REPORT_CONTRACT_PAYMENTS,
        permissions.REPORT_BANK_TRANSFERS,
        permissions.REPORT_BANK_TRANSFERS_TRANSFERS,
        permissions.REPORT_REFUNDS,
      ])
    ) {
      secondaryMenu.push({
        name: t("Current marks"),
        link: "/reports/current-poll-result",
      });
    }

    if (havePermissions([permissions.REPORT_BALANCE])) {
      secondaryMenu.push({
        name: t("Balances report"),
        link: "/reports/balances",
      });
    }

    if (havePermissions([permissions.REPORT_CONTRACT_PAYMENTS])) {
      secondaryMenu.push({
        name: t("Contract payments report"),
        link: "/reports/contractPayments",
      });
    }

    if (havePermissions([permissions.REPORT_BANK_TRANSFERS])) {
      secondaryMenu.push({
        name: t("Incomes report"),
        link: "/reports/incomes",
      });
    }

    if (havePermissions([permissions.REPORT_BANK_TRANSFERS_TRANSFERS])) {
      secondaryMenu.push({
        name: t("Incomes' transfers report"),
        link: "/reports/incomes-transfers",
      });
    }

    if (havePermissions([permissions.REPORT_REFUNDS])) {
      secondaryMenu.push({
        name: t("Refunds"),
        link: "/reports/refunds",
      });
    }

    if (
      Features.invoices &&
      havePermissions([permissions.REPORT_INVOICES_XLS])
    ) {
      secondaryMenu.push({
        name: t("List of Invoices"),
        link: "/reports/invoices",
      });
    }

    if (
      havePermissions([
        permissions.REPORT_FINANCE_XLS,
        permissions.REPORT_COURSE_DAYS_AND_GROUPS,
      ])
    ) {
      secondaryMenu.push({
        name: t("XLS reports"),
        link: "/reports/xls",
      });
    }

    if (havePermissions([permissions.REPORT_LECTURER_SETTLEMENT])) {
      secondaryMenu.push({
        name: t("Lecturer settlement"),
        link: "/reports/lecturers-settlement",
      });
    }

    return secondaryMenu;
  };

  render() {
    const { t, haveToken } = this.props;
    const { maintenance } = this.state;
    if (maintenance) {
      return <Maintenance />;
    }
    return !haveToken ? (
      <Login
        t={t}
        onLogin={this.onLoginHandler}
        onLoginByPass={this.onLoginByPassHandler}
      />
    ) : (
      <Router>
        <ErrorBoundry>
          <MainTemplate logged={this.props.haveToken}>
            <Route
              path="/courses"
              exact
              component={RouteAuthorize([permissions.COURSE_LIST])(Courses)}
            />
            <Route
              path="/courses/new"
              exact
              render={props => (
                <Authorize
                  component={
                    <CourseSettingsContainer key="course_new" {...props} />
                  }
                  allows={[
                    permissions.COURSE_DETAILS_EDIT,
                    permissions.COURSE_DETAILS_VIEW,
                  ]}
                  hasAll={true}
                />
              )}
            />
            <Route
              path="/courses/:courseId/settings"
              exact
              render={props => (
                <Authorize
                  component={
                    <CourseSettingsContainer
                      key={props.match.params.courseId}
                      {...props}
                    />
                  }
                  allows={[permissions.COURSE_DETAILS_VIEW]}
                />
              )}
            />
            <Route
              path="/courses/:courseId/students"
              exact
              component={RouteAuthorize([permissions.COURSE_STUDENT_LIST])(
                CourseStudents,
              )}
            />
            <Route
              path="/courses/:courseId/polls"
              exact
              component={CoursePoll}
            />
            <Route
              path={"/dictionaries"}
              render={() => (
                <Authorize
                  component={
                    <MainTemplateContent
                      title={t("Dictionaries")}
                      secondaryMenu={[
                        {
                          name: t("Lecture rooms"),
                          link: "/dictionaries/rooms",
                        },
                        {
                          name: t("Course types"),
                          link: "/dictionaries/courseskinds",
                        },
                        {
                          name: t("Course modes"),
                          link: "/dictionaries/coursesmodes",
                        },
                        {
                          name: t("Employees availability"),
                          link: "/dictionaries/employeeavailability",
                        },
                        {
                          name: t("Additional competencies"),
                          link: "/dictionaries/additionalcompetencies",
                        },
                        {
                          name: t("Surveys"),
                          link: "/dictionaries/polls",
                        },
                        {
                          name: t("Survey types"),
                          link: "/dictionaries/polltypes",
                        },
                        {
                          name: t("Lectures types"),
                          link: "/dictionaries/lectures-types",
                        },
                        {
                          name: t("PipeDrive Mapping"),
                          link: "/dictionaries/pipedrive-map",
                        },
                        {
                          name: t("Comments"),
                          link: "/dictionaries/comments",
                        },
                        {
                          name: t("Special offers"),
                          link: "/dictionaries/promotions",
                        },
                      ]}
                    >
                      <Route
                        path="/dictionaries/rooms"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(DictionaryRooms)}
                      />
                      <Route
                        path="/dictionaries/courseskinds"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(DictionaryCoursesKinds)}
                      />
                      <Route
                        path="/dictionaries/additionalcompetencies"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(DictionaryAdditionalCompetencies)}
                      />
                      <Route
                        path="/dictionaries/coursesmodes"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(DictionaryCoursesModes)}
                      />
                      <Route
                        path="/dictionaries/promotions"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(Promotions)}
                      />
                      <Route
                        path="/dictionaries/employeeavailability"
                        component={RouteAuthorize([
                          permissions.DICTIONARY_VIEW,
                        ])(DictionaryEmployeeAvailability)}
                      />
                      <Route
                        path="/dictionaries/polls"
                        component={DictionaryPolls}
                      />
                      <Route
                        path="/dictionaries/polltypes"
                        component={DictionaryPollTypes}
                      />
                      <Route
                        path="/dictionaries/lectures-types"
                        component={DictionaryLecturesTypes}
                      />
                      <Route
                        path="/dictionaries/pipedrive-map"
                        component={DictionaryPipedriveMap}
                      />
                      <Route
                        path="/dictionaries/comments"
                        component={DictionaryComments}
                      />
                    </MainTemplateContent>
                  }
                  allows={[permissions.DICTIONARY_VIEW]}
                />
              )}
            />
            <Route
              exact
              path="/companies"
              component={RouteAuthorize([
                permissions.COMPANY_VIEW,
                permissions.COMPANY_EDIT,
              ])(CompaniesList)}
            />
            <Route
              exact
              path={`/companies/:companyId`}
              render={props => (
                <MainTemplateContent title={t("Company")}>
                  <CompanyDetails {...props} />
                </MainTemplateContent>
              )}
            />
            <Route
              exact
              path="/products"
              render={() => (
                <Authorize
                  component={<Products />}
                  allows={[permissions.PERSON_LIST]}
                />
              )}
            />
            <Route
              exact
              path="/"
              render={() => (
                <MainTemplateContent title="">
                  <HomePage />
                </MainTemplateContent>
              )}
            />
            <Route
              exact
              path={`/products/product/:versionId`}
              render={props => (
                <Authorize
                  allows={[permissions.PRODUCT_DETAILS_VIEW]}
                  component={
                    <Product
                      isNew={false}
                      key={props.match.params.versionId}
                      {...props}
                    />
                  }
                />
              )}
            />
            <Route
              exact
              path={`/products/new`}
              render={props => (
                <Authorize
                  allows={[
                    permissions.PRODUCT_DETAILS_VIEW,
                    permissions.PRODUCT_DETAILS_EDIT,
                  ]}
                  component={
                    <Product
                      isNew
                      key={props.match.params.versionId}
                      {...props}
                    />
                  }
                />
              )}
            />
            <Route
              exact={true}
              path="/persons"
              component={RouteAuthorize([permissions.PERSON_LIST])(PersonsList)}
            />
            <Route
              exact={true}
              path="/persons/new"
              render={props => (
                <Authorize
                  component={<PersonFormWithConnect key="new" {...props} />}
                  allows={[
                    permissions.PERSON_DETAILS_EDIT,
                    permissions.PERSON_DETAILS_VIEW,
                  ]}
                  hasAll={true}
                />
              )}
            />
            <Route
              exact
              path={`/persons/update/:personId`}
              render={props => (
                <Authorize
                  component={
                    <PersonFormWithConnect
                      key={props.match.params.personId}
                      {...props}
                    />
                  }
                  allows={[permissions.PERSON_DETAILS_VIEW]}
                />
              )}
            />
            <Route
              exact
              path={`/persons/update/:personId/:selectedTab`}
              render={props => (
                <Authorize
                  component={
                    <PersonFormWithConnect
                      key={props.match.params.personId}
                      {...props}
                    />
                  }
                  allows={[permissions.PERSON_DETAILS_VIEW]}
                />
              )}
            />
            <Route
              exact
              path={`/balance/:balanceId`}
              render={props => (
                <MainTemplateContent title={t("Settlements")}>
                  <Balance
                    {...props}
                    balanceId={Number(props.match.params.balanceId)}
                  />
                </MainTemplateContent>
              )}
            />
            <Route
              exact
              path={`/persons/contract/:contractId/`}
              component={RouteAuthorize([permissions.STUDENT_CONTRACT_VIEW])(
                ContractDetails,
              )}
            />
            <Route
              path="/reports"
              render={() => (
                <MainTemplateContent
                  title={t("Reports")}
                  background={true}
                  secondaryMenu={this.reportsSecondaryMenu()}
                >
                  <Route
                    exact
                    path={`/reports/contracts`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_TREND]}
                        component={<Reports {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/timeline`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_TIMELINE]}
                        component={<TimeLineReport {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/groups`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_GROUPFILL]}
                        component={<GroupDetails {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/graduates`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_GRADUATES]}
                        component={<Graduates {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/current-poll-result`}
                    component={CurrentPollResults}
                  />
                  <Route
                    exact
                    path={`/reports/refunds`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_REFUNDS]}
                        component={<Refunds {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/incomes`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_BANK_TRANSFERS]}
                        component={<Incomes {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/incomes-transfers`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_BANK_TRANSFERS_TRANSFERS]}
                        component={<IncomesTransfersList {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/invoices`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_INVOICES_XLS]}
                        component={<Invoices {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/contractPayments`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_CONTRACT_PAYMENTS]}
                        component={<ContractPayments {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/balances`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_BALANCE]}
                        component={<Balances {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/xls`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_FINANCE_XLS]}
                        component={<XLSReports {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/reports/lecturers-settlement`}
                    render={props => (
                      <Authorize
                        allows={[permissions.REPORT_LECTURER_SETTLEMENT]}
                        component={<LecturersSettlementReport {...props} />}
                      />
                    )}
                  />
                </MainTemplateContent>
              )}
            />
            <Route path={"/loader"} commponent={Loader} />
          </MainTemplate>
        </ErrorBoundry>
      </Router>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getJwt,
      getJwtByPass,
      getPermissionsList,
      getUserPermissions,
    },
    dispatch,
  );
}
function mapStateToProps({
  token,
  userId,
  userPermissions,
  language,
  maintenanceMode,
}) {
  return {
    haveToken: token.length > 0,
    token,
    userId,
    userPermissions,
    language,
    maintenanceMode,
  };
}

export default compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MainApp);
