import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import NewSlave from "../Containers/NewSlave";
import SlavesTableForm from "../Containers/SlavesTableForm";

class Slaves extends Component {
  render() {
    const { t, contractId, productId, slaves, updatePayments } = this.props;
    return (
      <div className={`contract__slaves`}>
        <div className={`row`}>
          <div className={`col-xs-12`}>
            <h3> {t(`Connected contracts`)} </h3>
          </div>
        </div>
        <div className={`row`}>
          <NewSlave
            contractId={contractId}
            productId={productId}
            updatePayments={updatePayments}
          />
        </div>
        <div className={`row`}>
          <div className={`col-xs-12`}>
            <SlavesTableForm
              contractId={contractId}
              slaves={slaves}
              updatePayments={updatePayments}
            />
          </div>
        </div>
      </div>
    );
  }
}

Slaves.propTypes = {
  contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func,
  slaves: PropTypes.array,
  updatePayments: PropTypes.func,
};

export default translate()(Slaves);
