import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import dateHelper from "../../../Helpers/DateHelper";
import getCancelToken from "../../../Helpers/GetCancelToken";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import Loader from "../../../Common/Components/Loader/Loader";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactTable from "react-table";
import { Search } from "react-feather";
import { Field } from "redux-form";
import { RenderCheckbox, RenderSelect } from "../../../Forms/forms";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import IncomeTransfer from "../Containers/IncomeTransfer";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import { Features } from "../../../Common/Utils/Tenant";
import Switch from "react-switch";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";
import TIME from "../../../Enums/Time";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";

class IncomesReport extends Component {
  #cancelToken = getCancelToken();
  #tableColumns = [
    {
      Header: () => (
        <div className="group-fill-table__header">
          {this.props.t(`Transaction number`)}
        </div>
      ),
      width: 200,
      accessor: "transaction",
    },
    {
      Header: () => <div>{this.props.t(`Transaction amount`)}</div>,
      Cell: ({ original: { amount, deleted } }) => {
        const deletedStyle = deleted && { textDecoration: "line-through" };
        return (
          <span style={deletedStyle || {}}>
            {renderAmountWithCurrency(amount)}
          </span>
        );
      },
      accessor: "amount.amount",
    },
    {
      Header: () => (
        <div className="group-fill-table__header">
          {this.props.t(`Booking date`)}
        </div>
      ),
      Cell: ({ original: { bookingDate } }) => (
        <span>
          {dateHelper(bookingDate)
            .get()
            .format(TIME.DATE_FORMAT)}
        </span>
      ),
      accessor: "bookingDate",
    },
    {
      Header: () => <div>{this.props.t(`Subaccount`)}</div>,
      accessor: "account.number",
      width: 200,
    },
    {
      Header: () => <div>{this.props.t(`Payer`)}</div>,
      accessor: "owner",
    },
    {
      Header: () => <div>{this.props.t(`Income type`)}</div>,
      Cell: ({ original: { type } }) => <span>{this.props.t(type)}</span>,
    },
    {
      Header: () => <div>{this.props.t(`Invoice`)}</div>,
      Cell: ({ original: { invoicesFv, invoicesFk } }) => (
        <>
          {invoicesFv.map(invoice => (
            <a
              key={invoice.id}
              style={{ paddingRight: "5px" }}
              onClick={() => this.downloadInvoice(invoice)}
            >
              {invoice.number}
            </a>
          ))}

          {invoicesFk.map((invoiceFk, index) => (
            <span key={invoiceFk.number}>
              {index ? `, ${invoiceFk.number}` : invoiceFk.number}
            </span>
          ))}
        </>
      ),
      show: Features.invoices,
    },
    {
      Header: () => <div>{this.props.t(`Description`)}</div>,
      Cell: ({ original: { description } }) => (
        <span title={description}>{description}</span>
      ),
    },
    {
      Header: () => this.props.t(`Income invoiced`),
      width: 100,
      show: havePermissions([permissions.REPORT_CONTRACT_PAYMENTS]),
      Cell: row =>
        row.original.id && row.original.invoicesFv.length === 0 ? (
          <Switch
            onChange={() => this.invoicingSwitch(row.original)}
            checked={row.original.invoicingDisabled}
          />
        ) : (
          ""
        ),
    },
    {
      Header: () => "",
      width: 100,
      Cell: ({ original }) =>
        original.type === "bank" ? (
          <div className={`text-center`}>
            <button
              className={`transfer-button btn`}
              onClick={() => {
                this.openTransferForm(original);
              }}
            >
              {this.props.t(`Make transfer`)}
            </button>
          </div>
        ) : (
          ""
        ),
    },
  ];

  static propTypes = {
    bankIncomes: PropTypes.object,
    fetchBankIncomes: PropTypes.func,
    downloadBankIncomes: PropTypes.func,
    downloadInvoice: PropTypes.func,
    updateIncome: PropTypes.func,
    t: PropTypes.func,
    values: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bankIncomes: {},
      currentPage: 1,
      updateIncomeForApprove: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const {
      values: { onlyUnused, onlyDeleted, transaction, invoiced },
    } = this.props;
    const {
      values: {
        onlyUnused: oldOnlyUnused,
        onlyDeleted: oldOnlyDeleted,
        transaction: oldTransaction,
        invoiced: oldInvoiced,
      },
    } = prevProps;
    const valuesComparison = [
      [onlyUnused, oldOnlyUnused],
      [onlyDeleted, oldOnlyDeleted],
      [transaction, oldTransaction],
      [invoiced, oldInvoiced],
    ];
    const valuesNotChanged = valuesComparison.every(
      valuesToCheck => valuesToCheck[0] === valuesToCheck[1],
    );
    if (!valuesNotChanged) {
      _.debounce(() => {
        this.loadData(true);
      }, 1000)();
    }
  }

  loadData = (forceFirstPage = false) => {
    const { fetchBankIncomes } = this.props;
    this.setState({
      isLoading: true,
    });
    fetchBankIncomes(
      this.#cancelToken,
      this.prepareRequestParams(forceFirstPage),
      () => {
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  prepareRequestParams = forceFirstPage => {
    const {
      values: { onlyUnused, onlyDeleted, transaction: query, invoiced },
    } = this.props;
    const { currentPage: pageNo } = this.state;
    this.setState({
      currentPage: forceFirstPage ? 1 : pageNo,
    });
    return {
      onlyUnused: Number(onlyUnused),
      onlyDeleted: Number(onlyDeleted),
      query,
      invoiced,
      pageNo: forceFirstPage ? 1 : pageNo,
    };
  };

  invoicingSwitch(row) {
    const data = {
      invoicingDisabled: !row.invoicingDisabled,
      owner: row.owner,
    };

    if (row.invoicingDisabled) {
      this.setState({ updateIncomeForApprove: { id: row.id, data } });
    } else {
      this.props.updateIncome(row.id, data, () => this.loadData());
    }
  }

  approveUpdateIncome = () => {
    const {
      updateIncomeForApprove: { id, data },
    } = this.state;
    this.props.updateIncome(id, data, () => this.loadData());
    this.setState({ updateIncomeForApprove: null });
  };

  rejectUpdateIncome = () => this.setState({ updateIncomeForApprove: null });

  openTransferForm = transferIncomeData => {
    this.setState({
      showTransferForm: true,
      transferIncomeData,
    });
  };

  transferDoneHandler = () => {
    this.setState(
      {
        showTransferForm: false,
      },
      () => {
        this.loadData();
      },
    );
  };

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
      },
      this.loadData,
    );
  };

  downloadInvoice = invoice => {
    this.props.downloadInvoice(this.#cancelToken, {
      invoiceId: invoice.id,
      invoiceNumber: invoice.number,
    });
  };

  handleDownload = () => {
    const { downloadBankIncomes } = this.props;
    const params = this.prepareRequestParams();
    downloadBankIncomes(this.#cancelToken, { ...params, format: "xls" }, () => {
      this.setState({ isWaitingForData: false });
    });
  };

  styleForRemoveRow = (state, rowInfo) => {
    const style =
      (rowInfo &&
        rowInfo.original.deleted && {
          background: "#ff8a80",
        }) ||
      {};
    return {
      style,
    };
  };

  render() {
    const {
      isLoading,
      showTransferForm,
      transferIncomeData,
      currentPage,
      updateIncomeForApprove,
    } = this.state;
    const { t, bankIncomes } = this.props;
    const PER_PAGE = 10;
    const counter = _.has(bankIncomes, "counter") ? bankIncomes.counter : 0;
    const incomes = _.has(bankIncomes, "elements") ? bankIncomes.elements : [];
    const pages = Math.ceil(counter / PER_PAGE);

    return (
      <Fragment>
        <CustomModal
          isOpen={showTransferForm}
          title={t("Transaction transfer")}
          onRequestClose={() => this.setState({ showTransferForm: false })}
          ariaHideApp={false}
        >
          <IncomeTransfer
            transferDoneHandler={this.transferDoneHandler}
            transferIncomeData={transferIncomeData}
          />
        </CustomModal>

        <ConfirmModal
          isOpen={!!this.state.updateIncomeForApprove}
          title={t(`Income invoiced`)}
          onRequestClose={this.rejectUpdateIncome}
          config={{
            body: (
              <p>
                {`${t(
                  "Are you sure you want to delete the status of the Income already invoiced and issue an invoice for the payer",
                )} ${updateIncomeForApprove &&
                  updateIncomeForApprove.data.owner}`}
              </p>
            ),
            onRequestConfirm: this.approveUpdateIncome,
          }}
        />

        <section className="form-section">
          <div className="row">
            <div className="form-group col-md-3 col-xs-6">
              <div className="input-group ">
                <div className="input-group-addon">
                  <Search size={16} />
                </div>
                <Field
                  className="form-control"
                  name="transaction"
                  component="input"
                  type="text"
                  placeholder={t(`Transaction number or description`)}
                />
              </div>
            </div>
            <div className={`form-group col-md-2 col-xs-6`}>
              <Field
                name="onlyUnused"
                labelForCheckbox={t("Show unallocated")}
                customLabel=""
                component={RenderCheckbox}
              />
            </div>
            <div className={`form-group col-md-2 col-xs-6`}>
              <Field
                name="onlyDeleted"
                labelForCheckbox={t("Show deleted")}
                customLabel=""
                component={RenderCheckbox}
              />
            </div>
            <div className={`form-group col-md-3 col-xs-6`}>
              <Field
                component={RenderSelect}
                name="invoiced"
                dropdownConfig={{
                  data: [
                    { id: "all", name: t("All") },
                    { id: "invoiced", name: t("Invoiced") },
                    { id: "not_invoiced", name: t("Not invoiced") },
                  ],
                  textField: "name",
                  valueField: "id",
                }}
                under={{
                  fieldClassName: "col-xs-12",
                }}
              />
            </div>
            <div
              className={`form-group col-md-1 col-xs-6`}
              id="incomesDownload"
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
                onClick={this.handleDownload}
              />
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="row horizontal-center">
                <div className="col-md-12">
                  <div className={`row`}>
                    <ReactTable
                      noDataText={t("No records found")}
                      showPagination={false}
                      defaultPageSize={PER_PAGE}
                      data={incomes}
                      sortable={true}
                      columns={this.#tableColumns}
                      className="-striped -highlight"
                      getTrProps={this.styleForRemoveRow}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Paginator
                    pages={pages}
                    onClick={this.changePage}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </>
          )}
        </section>
      </Fragment>
    );
  }
}

export default translate()(IncomesReport);
