import * as React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { translate } from "react-i18next";
import { XCircle } from "react-feather";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { LecturerType } from "../../Modules/ProductsNew/Types/ProductVersionMeta";
import { FieldProps } from "formik";
import { renderFieldsHelper } from "../FormikUtils";

type LecturerSelectForEventProps = InjectedTranslateProps &
  FieldProps & {
    fetchData: (query: string) => void;
    onShowMoreResults: (page: number) => void;
    lecturers: Array<LecturerType>;
    label: string;
    error: string;
    placeholder: string;
    isLoading: boolean;
    disabled: boolean;
    under?: {
      containerClassName: string;
      fieldClassName: string;
    };
  };

const SelectLecturerForEvent: React.FunctionComponent<
  LecturerSelectForEventProps
> = (props: LecturerSelectForEventProps): React.ReactElement => {
  const PER_PAGE = 10;

  const {
    t,
    fetchData,
    under,
    label,
    placeholder,
    lecturers,
    isLoading,
    disabled,
    onShowMoreResults,
    form,
    field,
    field: { value },
  } = props;
  const { displayError, error } = renderFieldsHelper(props);

  return (
    <div
      id="lecturer-select"
      className={`form-group selectWithPagination ${
        displayError ? "has-error" : ""
      } ${under ? under.containerClassName : "row"}`}
    >
      <div className="row">
        {label && (
          <div
            className={`${under ? under.fieldClassName : "col-sm-4 col-md-3"} ${
              !label || label.length === 0 ? "hidden" : ""
            }`}
          >
            <label htmlFor="">{label}</label>
          </div>
        )}
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <AsyncTypeahead
              id="event-lecturer-select"
              isLoading={isLoading}
              disabled={disabled}
              options={lecturers}
              selected={!value ? [] : !Array.isArray(value) ? [value] : value}
              multiple={false}
              maxResults={PER_PAGE}
              labelKey={({ name, surname }) => `${name} ${surname}`}
              minLength={0}
              onSearch={fetchData}
              placeholder={placeholder}
              onChange={item => form.setFieldValue(field.name, item)}
              paginate={true}
              promptText={t("Type to search...")}
              searchText={t("Searching...")}
              paginationText={t("Show more lecturers")}
              emptyLabel={t("Lecturers not found")}
              useCache={false}
              onPaginate={(_, showResults) => onShowMoreResults(showResults)}
            />
            <span aria-hidden="true" className="rw-i rw-i-caret-down" />
            <div
              onClick={() => form.setFieldValue(field.name, [])}
              className="form__clear-button"
            >
              <XCircle size={15} />
            </div>
          </div>
          {displayError && <span className="help-block">{t(error)}</span>}
        </div>
      </div>
    </div>
  );
};

export default translate()(SelectLecturerForEvent);
