export default fields => {
  let errors = {};
  const { course, module } = fields;
  if (!course) {
    errors.course = "Choose course";
  }
  if (!module) {
    errors.module = "Choose module";
  }
  return errors;
};
