import React from "react";

import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoiceForm from "../Components/InvoiceForm";
import {
  createInvoice,
  fetchInvoices,
  fetchInvoiceReceivers,
  fetchLegalBasis,
  clearLegalBasis,
} from "../Actions";
import { passErrorToStore, clearErrorFromStore } from "../../App/Actions";
import moment from "moment";
import validate from "../Validators/InvoiceValidator";
import { priceGrossCalculator } from "../../Products/Actions";
import { fetchTenantSettings } from "../../App/Actions";
import { error, success, removeAll } from "react-notification-system-redux";
import PAYMENT_METHODS from "../../../Enums/PaymentMethods";

const FORM_NAME = "NEW_INVOICE";
const NewInvoice = props => <InvoiceForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    tenantSettings,
    invoiceReceivers,
    invoiceAmount,
    invoiceLegalBasis,
  } = state;
  const values = selector(
    state,
    "vatRate",
    "amountGross",
    "issueMonth",
    "paymentDate",
    "sellDate",
    "receiver",
    "paymentType",
    "legalBasis",
    "hasLegalBasis",
  );
  return {
    values,
    tenantSettings,
    invoiceReceivers,
    invoiceAmount,
    invoiceLegalBasis,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createInvoice,
      passErrorToStore,
      clearErrorFromStore,
      fetchInvoices,
      priceGrossCalculator,
      fetchTenantSettings,
      fetchInvoiceReceivers,
      fetchLegalBasis,
      clearLegalBasis,
      error,
      success,
      removeAll,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    date: moment(),
    paymentType: PAYMENT_METHODS.BANK_TRANSFER.value,
  },
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewInvoice),
);
