import { translate } from "react-i18next";

import React from "react";
import { FieldProps } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { RenderError, renderFieldsHelper } from "../FormikUtils";

type TextInputProps = FieldProps &
  InjectedTranslateProps & {
    disabled?: boolean;
    label?: string;
    under?: {
      containerClassName?: string;
      fieldClassName?: string;
    };
  };

const RenderTextToTranslate: React.FunctionComponent<TextInputProps> = (
  props: TextInputProps,
): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);
  const { field, form, label, under, t, disabled } = props;
  return (
    <div className={`form-group row ${classes}`}>
      {label ? (
        <div className={under ? "col-xs-12" : "col-sm-4 col-md-3"}>
          <label htmlFor={field.name}>{label}</label>
        </div>
      ) : (
        ""
      )}
      <div className={under ? "col-xs-12" : "col-sm-8 col-md-9"}>
        <input
          className="form-control"
          name={field.name}
          value={field.value}
          disabled={disabled}
          onChange={event => form.setFieldValue(field.name, event.target.value)}
        />
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};
export const RenderTextInput = translate()(RenderTextToTranslate);
