import { Invoice } from "../Types/Invoice";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";

export const shouldCheckboxDisabled = (invoice: Invoice): boolean => {
  const { sendStatusTransitions } = invoice;
  let availableTransitionStatuses: Array<string> = [];
  sendStatusTransitions.forEach(sendStatusTransition => {
    availableTransitionStatuses = [
      ...availableTransitionStatuses,
      ...sendStatusTransition.tos,
    ];
  });

  return !availableTransitionStatuses.find(
    status => status === InvoiceSendStatus.IN_SEND,
  );
};
