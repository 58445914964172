import React from "react";

import AddressPopup from "../Components/AddressPopup";

import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePersonAddress } from "../Actions";
import { fetchDictionary } from "../../Dictionaries/Actions";
import validate from "../../Students/Validators/AddressValidator";

const FORM_NAME = "CONTRACT_ADDRESS_POPUP";

let ContractAddressPopup = props => <AddressPopup {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  let { studentAddress, dictionaries } = state;

  const values = selector(state, "studentAddress");

  return {
    values,
    dictionaries,
    initialValues: { studentAddress: studentAddress },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePersonAddress,
      fetchDictionary,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {
    studentAddress: {
      city: "",
      country: "",
      address: "",
      zipCode: "",
    },
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractAddressPopup),
);
