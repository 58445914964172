export default {
  INDEPENDENT: "own_funds",
  LABOR_OFFICE: "labor_office",
  FOUNDATION: "foundation",
  COMPANY: "company",
  PARP: "parp",
  POWER: "power",
  KFS: "kfs",
  OPEN: "open",
  CREDIT: "credit",
};
