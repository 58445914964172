export default {
  GET_PRODUCT_VERSIONS: "GET_PRODUCT_VERSIONS",
  GET_CITIES: "GET_CITIES",
  GET_COURSE_MODES: "GET_COURSE_MODES",
  GET_MENTORS_FOR_COURSE: "GET_MENTORS_FOR_COURSE",
  GET_COURSE_SPECIALISTS_FOR_COURSE: "GET_COURSE_SPECIALISTS_FOR_COURSE",
  GET_CURRENT_COURSE: "GET_CURRENT_COURSE",
  GET_FREE_LECTURERS_AND_ROOMS: "GET_FREE_LECTURERS_AND_ROOMS",
  GET_CONTRACTS_LIST: "GET_CONTRACTS_LIST",
  RESET_CURRENT_COURSE: "RESET_CURRENT_COURSE",
  EDIT_UNIT: "EDIT_UNIT",
  REFRESH_CURRENT_COURSE: "REFRESH_CURRENT_COURSE",
  CANCEL_CURRENT_COURSE: "CANCEL_CURRENT_COURSE",
  PUBLISH_CALENDAR: "PUBLISH_CALENDAR",
  RESET_CALENDAR: "RESET_CALENDAR",
  FETCH_PACKAGES_DICTIONARY: "FETCH_PACKAGES_DICTIONARY",
  ADD_NEW_COURSE: "ADD_NEW_COURSE",
  EDIT_CURRENT_COURSE: "EDIT_CURRENT_COURSE",
  DRAFT_CANCEL: "DRAFT_CANCEL",
  SETTLE_MODULE: "SETTLE_MODULE",
  FETCH_EMPLOYEES_FOR_EVENT: "FETCH_EMPLOYEES_FOR_EVENT",
  EDIT_EVENT: "EDIT_EVENT",
};
