import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ListElement = ({
  icon,
  children,
  actions,
  path,
  className,
  clickable,
  actionClasses,
  id,
}) => (
  <div>
    <div
      id={id && `product-item-${id}`}
      className={`row product-item ${className ? className : ""}`}
    >
      <div className="col-sm-1 vertical-center horizontal-center">{icon}</div>
      {!!clickable || typeof clickable === "undefined" ? (
        <Link
          to={path && path.length > 0 ? path : "#"}
          className="col-sm-8 vertical-center product-item__info"
        >
          {children}
        </Link>
      ) : (
        <div className="col-sm-8 vertical-center product-item__info">
          {children}
        </div>
      )}
      <div
        className={`col-sm-3 vertical-center product-item__actions ${actionClasses ||
          ""}`}
      >
        {actions}
      </div>
    </div>
  </div>
);

ListElement.propTypes = {
  icon: PropTypes.shape(),
  children: PropTypes.object,
  actions: PropTypes.shape(),
  path: PropTypes.string,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  actionClasses: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ListElement.defaultProps = {
  id: null,
};

export default ListElement;
