import Client from "../../../Common/Utils/ClientApi";
import restApiConfig from "../../../Configs/restApiConfig";
import COURSES_ACTION_TYPES from "./ActionTypes";
import ACTION_TYPES from "../../../Enums/ActionTypes";

export function fetchProductVersions(
  cancelToken,
  params = {},
  callback = null,
  callGlobalLoader = false,
) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions`,
    actionType: COURSES_ACTION_TYPES.GET_PRODUCT_VERSIONS,
    params,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function fetchCities(
  cancelToken,
  params,
  callback = null,
  callGlobalLoader = false,
) {
  const config = {
    path: `${restApiConfig.API_URL}/dict/cities`,
    actionType: COURSES_ACTION_TYPES.GET_CITIES,
    params,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function fetchCourseModes(
  cancelToken,
  params,
  callback = null,
  callGlobalLoader = false,
) {
  const config = {
    path: `${restApiConfig.API_URL}/dict/course/mode`,
    actionType: COURSES_ACTION_TYPES.GET_COURSE_MODES,
    params,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function removeAdditionalEvent(
  courseId,
  eventId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/${courseId}/event/${eventId}`,
    actionType: ACTION_TYPES.DELETE_COURSE_ADDITIONAL_EVENT,
    callback: callback,
    callGlobalLoader,
  };

  return Client.actionDELETE(config);
}

export function addCourseEvent(
  courseId,
  values,
  callback = null,
  callGlobalLoader = true,
) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/course/${courseId}/events`,
    type: ACTION_TYPES.ADD_COURSE_EVENTS,
    callback,
    callGlobalLoader,
    values,
  });
}

export function fetchMentorsForCourse(
  cancelToken,
  productVersionId,
  callback = null,
  callGlobalLoader = false,
) {
  const config = {
    path: `${restApiConfig.API_URL}/person/getMentorForProduct/${productVersionId}`,
    actionType: COURSES_ACTION_TYPES.GET_MENTORS_FOR_COURSE,
    cancelToken,
    callback,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function fetchCourseSpecialists(
  cancelToken,
  configOptions = {},
  callback = null,
  callGlobalLoader = false,
) {
  const config = {
    path: `${restApiConfig.API_URL}/person/get`,
    actionType: COURSES_ACTION_TYPES.GET_COURSE_SPECIALISTS_FOR_COURSE,
    params: {
      courseSpecialistOnly: 1,
      pageAmount: 50,
      ...configOptions,
    },
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function fetchCourse(
  cancelToken,
  courseId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/get/${courseId}`,
    actionType: COURSES_ACTION_TYPES.GET_CURRENT_COURSE,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function refreshCurrentCourse(data) {
  return dispatch =>
    dispatch({
      type: COURSES_ACTION_TYPES.REFRESH_CURRENT_COURSE,
      payload: data,
    });
}

export function clearCourseState() {
  return dispatch =>
    dispatch({
      type: COURSES_ACTION_TYPES.RESET_CURRENT_COURSE,
    });
}

export function cancelCourse(values, callback, callGlobalLoader = true) {
  const config = {
    path: `${restApiConfig.API_URL}/course/cancel/${values.id}?cancelDate=${values.cancelDate}`,
    actionType: COURSES_ACTION_TYPES.CANCEL_CURRENT_COURSE,
    callback,
    callGlobalLoader,
  };

  return Client.actionDELETE(config);
}

export function addNewCourse(values, callback = null, callGlobalLoader = true) {
  const config = {
    path: `${restApiConfig.API_URL}/course/add`,
    actionType: COURSES_ACTION_TYPES.ADD_NEW_COURSE,
    values: values,
    callback: callback,
    callGlobalLoader,
  };

  return Client.actionPOST(config);
}

export function editCourse(
  courseId,
  values,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/edit/${courseId}`,
    actionType: COURSES_ACTION_TYPES.EDIT_CURRENT_COURSE,
    values: values,
    callback: callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function fetchEmployees(cancelToken, params, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/employee/filter`,
    actionType: COURSES_ACTION_TYPES.FETCH_EMPLOYEES_FOR_EVENT,
    params: params.q
      ? { ...params }
      : { pageNo: params.pageNo, pageAmount: params.pageAmount },
    callback,
    cancelToken,
  });
}

export function editEvent(
  courseId,
  eventId,
  values,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/${courseId}/event/${eventId}`,
    actionType: COURSES_ACTION_TYPES.EDIT_EVENT,
    values: values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function fetchFreeLecturesAndRooms(
  cancelToken,
  unitId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/lecture/availability/${unitId}`,
    actionType: COURSES_ACTION_TYPES.GET_FREE_LECTURERS_AND_ROOMS,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function fetchPackagesDictionary(
  cancelToken,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/package`,
    actionType: COURSES_ACTION_TYPES.FETCH_PACKAGES_DICTIONARY,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function publishCalendar(
  calendarId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/publish/${calendarId}`,
    actionType: COURSES_ACTION_TYPES.PUBLISH_CALENDAR,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function resetCalendar(
  calendarId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/calendar/reset/${calendarId}`,
    actionType: COURSES_ACTION_TYPES.RESET_CALENDAR,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function getSchedule(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/report/coursetimetable`,
    actionType: ACTION_TYPES.DOWNLOAD_SCHEDULE,
    values,
    blobHeader: "blob",
    fileName: `${values.fileName}.${values.format}`,
    callback,
  });
}

export function cancelDraft(
  calendarId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/calendar/${calendarId}`,
    actionType: COURSES_ACTION_TYPES.DRAFT_CANCEL,
    callback,
    callGlobalLoader,
  };

  return Client.actionDELETE(config);
}

export function editUnit(
  values,
  lectureId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/course/edit/unit/${lectureId}`,
    actionType: COURSES_ACTION_TYPES.EDIT_UNIT,
    values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function settleModule(unitId, callback = null, callGlobalLoader = true) {
  const config = {
    path: `${restApiConfig.API_URL}/unit/${unitId}/settle-module`,
    actionType: COURSES_ACTION_TYPES.SETTLE_MODULE,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}
