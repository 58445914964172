export default [
  {
    name: "Date of addition, ascending",
    value: {
      sortBy: "dateAdd",
      orderBy: "asc",
    },
  },
  {
    name: "Date of addition, descending",
    value: {
      sortBy: "dateAdd",
      orderBy: "desc",
    },
  },
];

// TODO: add translations
