import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

const LoaderProvider = props => {
  return props.isLoading ? <Loader /> : <>{props.children}</>;
};

LoaderProvider.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default LoaderProvider;
