import laborOfficeIsExistInContractPayers from "../../../Helpers/LaborOfficeIsExistInContractPayers";
import PAYMENT_SIDE from "../../../Enums/PaymentSide";
import CONTRACT_TYPE from "../../../Enums/ContractType";
import CONTRACT, {
  COURSE_CONTRACT_FINISH_REASON_ID,
} from "../../../Enums/Contract";
import RenderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import { getPriceForPackage } from "../Utils/GetPriceForPackage";

export default fields => {
  const errors = {};
  if (!fields.contract) {
    return {};
  }
  const {
    contract: {
      status,
      signDate,
      finishReason,
      finishReasonModule,
      resignReason,
      finishDate,
      studentBankAccountSwift,
      withdrawReason,
      transferCourse,
      transferReason,
      sendDate,
      withdrawDate,
      bankAccount,
      withdrawDescription,
    },
    type,
    payments: {
      payments,
      payers,
      laborOfficeContractNr,
      isPercent,
      package: packageType,
      productPrices = [],
    },
  } = fields;

  const studentBankAccountSwiftMaxLength = 11;
  const studentBankAccountSwiftMinLength = 8;

  let bankAccountFormat = /[0-9]{1,}/;

  const packagePriceForValidation = getPriceForPackage(
    packageType,
    productPrices,
  );

  const payersError = [];
  const countUniquePayers = _.countBy(payers, "balance.id");
  const maxPayer = packagePriceForValidation
    ? RenderAmountWithCurrency({
        amount: packagePriceForValidation.price.amount,
        currency: packagePriceForValidation.price.currency,
      })
    : 0;

  payers.forEach((payer, index) => {
    if (isPercent) {
      if (getSumOfParties(payers) !== 100) {
        payersError[index] = {
          ...payersError[index],
          part: "Parts of payments must be equal 100% in sum",
        };
      }
    }

    if (
      !isPercent &&
      packagePriceForValidation &&
      getSumOfAmountParties(payers) !== packagePriceForValidation.price.amount
    ) {
      if (
        getSumOfAmountParties(payers) < packagePriceForValidation.price.amount
      ) {
        const currentSum = RenderAmountWithCurrency({
          amount:
            packagePriceForValidation.price.amount -
            getSumOfAmountParties(payers),
          currency: packagePriceForValidation.price.currency,
        });
        payersError[index] = {
          ...payersError[index],
          partAmount: {
            amount: {
              key:
                "Sum of parts of the payment must be equal to modules total sum",
              values: {
                totalSum: maxPayer,
                currentSum: currentSum,
              },
            },
          },
        };
      } else {
        payersError[index] = {
          ...payersError[index],
          partAmount: {
            amount:
              "Part of the payment can not be greater than modules total sum",
          },
        };
      }
    }

    if (
      !isPercent &&
      payer.partAmount &&
      packagePriceForValidation &&
      payer.partAmount.amount > packagePriceForValidation.price.amount
    ) {
      payersError[index] = {
        ...payersError[index],
        partAmount: {
          amount:
            "Part of the payment can not be greater than modules total sum",
        },
      };
    }

    if (!payer.balance || !payer.balance.id) {
      payersError[index] = {
        ...payersError[index],
        balance: {
          owner: {
            name: "You must choose payer",
          },
        },
      };
    } else if (countUniquePayers[payer.balance.id] > 1) {
      payersError[index] = {
        ...payersError[index],
        balance: {
          owner: {
            name: "Payer can be choose only once",
          },
        },
      };
    }

    if (payer.side.id !== PAYMENT_SIDE.STUDENT && !payer.fundingType) {
      payersError[index] = {
        ...payersError[index],
        fundingType: { id: "You must choose funding type" },
      };
    }

    if (!isPercent && payer.partAmount && payer.partAmount.amount < 0) {
      payersError[index] = {
        ...payersError[index],
        partAmount: {
          amount: "Part of the payment can not be less than 0",
        },
      };
    }

    if (isPercent && payer.part > 100) {
      payersError[index] = {
        ...payersError[index],
        part: "Part of the payment can not be greater than 100%",
      };
    }

    if (isPercent && payer.part < 0) {
      payersError[index] = {
        ...payersError[index],
        part: "Part of the payment can not be less than 0%",
      };
    }

    if (
      payer.side.id !== PAYMENT_SIDE.STUDENT &&
      (payer.taxRate === undefined || payer.taxRate === null)
    ) {
      payersError[index] = {
        ...payersError[index],
        taxRate: "You must choose tax rate",
      };
    }
  });

  if (laborOfficeIsExistInContractPayers(payers) && !laborOfficeContractNr) {
    errors.payments = {
      ...errors.payments,
      laborOfficeContractNr: "Give the number of contract with LO",
    };
  }

  if (type === CONTRACT_TYPE.SELF && payers.length > 1) {
    errors.type = "You can have assign only one payer";
  }

  if (type !== CONTRACT_TYPE.SELF && payers.length < 2) {
    errors.type =
      "For this type of contract you have to assign 2 payers at least";
  }

  if (
    bankAccount &&
    bankAccount.nr &&
    bankAccount.nr.length > 0 &&
    !bankAccountFormat.test(bankAccount.nr)
  ) {
    errors.contract = {
      ...errors.contract,
      bankAccount: { nr: "Bad account number format" },
    };
  }
  if (!status) {
    errors.contract = {
      ...errors.contract,
      status: "Choose status",
    };
  } else {
    if (status === CONTRACT.STATUSES.CONTRACT_SIGNED && !signDate) {
      errors.contract = {
        ...errors.contract,
        signDate: "Enter the date of signing the contract",
      };
    }
    if (status === CONTRACT.STATUSES.CONTRACT_DENIED && !withdrawReason) {
      errors.contract = {
        ...errors.contract,
        withdrawReason: "Enter the reason for withdrawal",
      };
    }
    if (status === CONTRACT.STATUSES.CONTRACT_DENIED && !withdrawDescription) {
      errors.contract = {
        ...errors.contract,
        withdrawDescription: "Enter withdrawal description",
      };
    }
    if (status === CONTRACT.STATUSES.CONTRACT_DENIED && !withdrawDate) {
      errors.contract = {
        ...errors.contract,
        withdrawDate: "Enter the date of withdrawal from the contract",
      };
    }
    if (status === CONTRACT.STATUSES.CONTRACT_SEND && !sendDate) {
      errors.contract = {
        ...errors.contract,
        sendDate: "Enter the date of sending the contract",
      };
    }
    if (
      studentBankAccountSwift &&
      studentBankAccountSwift.length > studentBankAccountSwiftMaxLength
    ) {
      errors.contract = {
        ...errors.contract,
        studentBankAccountSwift:
          "Client's account number is too long (SWIFT), allowed maximum 11 chars",
      };
    }
    if (
      studentBankAccountSwift &&
      studentBankAccountSwift.length < studentBankAccountSwiftMinLength
    ) {
      errors.contract = {
        ...errors.contract,
        studentBankAccountSwift:
          "Client's account number is too short (SWIFT), allowed minimum 8 chars",
      };
    }
  }

  if (finishReason === COURSE_CONTRACT_FINISH_REASON_ID.TRANSFER) {
    if (!transferCourse) {
      errors.contract = {
        ...errors.contract,
        transferCourse: "Give the course signature",
      };
    }
    if (!transferReason) {
      errors.contract = {
        ...errors.contract,
        transferReason: "Choose the transfer reason",
      };
    }
    if (!finishReasonModule && finishReasonModule !== 0) {
      errors.contract = {
        ...errors.contract,
        finishReasonModule: "Give the module number",
      };
    }
  } else if (finishReason === COURSE_CONTRACT_FINISH_REASON_ID.FAIL) {
    if (!finishReasonModule && finishReasonModule !== 0) {
      errors.contract = {
        ...errors.contract,
        finishReasonModule: "Give the module number",
      };
    }
  } else if (finishReason === COURSE_CONTRACT_FINISH_REASON_ID.RESIGN) {
    if (!finishDate) {
      errors.contract = {
        ...errors.contract,
        finishDate: "Give the date of resignation",
      };
    }
    if (!finishReasonModule && finishReasonModule !== 0) {
      errors.contract = {
        ...errors.contract,
        finishReasonModule: "Give the number of module",
      };
    }
    if (!resignReason) {
      errors.contract = {
        ...errors.contract,
        resignReason: "Give the reason of resignation",
      };
    }
  }
  if (!fields.payments.package) {
    errors.payments = {
      ...errors.payments,
      fundingType: "Choose the package",
    };
  }

  let paymentErrors = [];
  payments &&
    payments.forEach((payment, index) => {
      paymentErrors[index] = {};
      if (!payment.installment) {
        paymentErrors[index] = {
          ...paymentErrors[index],
          installment: "Give the installment number",
        };
      }
      if (!payment.dates || !payment.dates.deadline) {
        paymentErrors[index] = {
          ...paymentErrors[index],
          dates: { deadline: "Give the date" },
        };
      }
      if (
        (!payment.price.amount && payment.price.amount !== 0) ||
        payment.price.amount < 0
      ) {
        paymentErrors[index] = {
          ...paymentErrors[index],
          price: {
            amount: "Enter the amount",
          },
        };
      }
      if (!payment.description) {
        paymentErrors[index] = {
          ...paymentErrors[index],
          description: "Enter the module",
        };
      }
    });

  errors.payments = {
    ...errors.payments,
    payments: [...paymentErrors],
    payers: [...payersError],
  };
  errors.contract = {
    ...errors.contract,
  };

  return errors;
};

const getSumOfParties = payers => {
  let sumOfParties = 0;
  payers.forEach(payer => (sumOfParties += payer.part));
  return sumOfParties;
};

const getSumOfAmountParties = payers => {
  let sumOfParties = 0;
  payers.forEach(
    payer => (sumOfParties += _.get(payer, "partAmount.amount", 0)),
  );
  return sumOfParties;
};
