import React, { Component } from "react";
import { Form, Field } from "redux-form";
import {
  RenderCheckbox,
  RenderDateTime,
  RenderSelect,
  RenderText,
} from "../../../Forms/forms";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import PriceFieldWithCalculation from "../../../Forms/PriceFieldWithCalculation";
import getCancelToken from "../../../Helpers/GetCancelToken";
import moment from "moment";
import { Features, Tenant } from "../../../Common/Utils/Tenant";
import AmountField from "../../../Forms/AmountField";
import getPaymentTypeDropdownConfig from "../../../Helpers/GetPaymentTypeDropdownConfig";
import {
  getDateOneMonthAfter,
  getDateOneMonthAgo,
} from "../../../Common/Utils/DateFormatters";

class ManualIncome extends Component {
  static propTypes = {
    t: PropTypes.func,
    currency: PropTypes.string,
    priceGrossCalculator: PropTypes.func,
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    change: PropTypes.func,
    addIncome: PropTypes.func,
    contractId: PropTypes.number,
    fetchPayments: PropTypes.func,
    closePopup: PropTypes.func,
    values: PropTypes.object,
    independent: PropTypes.bool,
    currencies: PropTypes.array,
    balanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultCurrency: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      isSubmitting: false,
    };
    props.change("paymentId", (props.data && props.data.id) || null);
    props.change("contractId", props.contractId);
    props.change("incomeDate", moment().valueOf());
    props.change("amount.currency", _.get(props, "defaultCurrency.name", null));
  }
  onSubmit = ({
    description,
    incomeDate,
    amount,
    invoicingDisabled,
    paymentType,
  }) => {
    const { balanceId, closePopup, addIncome } = this.props;

    this.setState(
      {
        isSubmitting: true,
      },
      () => {
        addIncome({
          description,
          incomeDate,
          amount,
          balanceId,
          invoicingDisabled,
          paymentType,
        }).then(() => {
          this.setState({
            isSubmitting: false,
          });
          closePopup("showManualIncomeForm");
        });
      },
    );
  };
  render() {
    const {
      t,
      priceGrossCalculator,
      handleSubmit,
      change,
      values: { amount },
      currencies,
    } = this.props;

    return (
      <Form id="manualIncomeForm" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="col-12">
          <Field
            under
            component={RenderText}
            label={t("Income title")}
            name="description"
          />
        </div>
        <div className="col-12 col-sm-4">
          <Field
            under
            component={RenderDateTime}
            max={getDateOneMonthAfter()}
            min={getDateOneMonthAgo()}
            label={t("Income date")}
            name="incomeDate"
            time={false}
          />
        </div>

        <div className="col-sm-4">
          <div className={`col-xs-12`}>
            <Field
              required="required"
              under
              label={t("Payment method")}
              name="paymentType"
              component={RenderSelect}
              dropdownConfig={getPaymentTypeDropdownConfig(t)}
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className={`col-xs-12 col-sm-8`}>
            <AmountField
              under
              component={PriceFieldWithCalculation}
              label={t("Gross amount")}
              t={t}
              name="amount.amount"
              currency={amount.currency}
              isNetValue={false}
              disabled={!amount.currency}
              change={change}
              calculatedName="amountNettThirdParty"
              priceCalculator={priceGrossCalculator}
            />
          </div>
          <div className={`col-xs-12 col-sm-4 margin__t25`}>
            <Field
              required="required"
              under
              name="amount.currency"
              component={RenderSelect}
              dropdownConfig={{
                data: currencies || [],
                placeholder: this.props.t("Currency"),
                textField: "name",
                valueField: "name",
              }}
            />
          </div>
        </div>
        <Tenant
          component={
            <div className="col-12">
              <Field
                name={`invoicingDisabled`}
                labelForCheckbox={t("Income already invoiced")}
                component={RenderCheckbox}
              />
            </div>
          }
          feature={Features.invoices}
        />
        <div className="col-12  margin__t25">
          <button
            onClick={handleSubmit(this.onSubmit)}
            type={`button`}
            className="btn btn-dark"
            disabled={
              !amount ||
              !amount.amount ||
              !amount.currency ||
              this.state.isSubmitting
            }
          >
            {t("Add income")}
          </button>
        </div>
      </Form>
    );
  }
}

export default translate()(ManualIncome);
