import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";
import propTypes from "prop-types";
import { translate } from "react-i18next";
import Loader from "../../../Common/Components/Loader/Loader";
import ReactTableWithSummary from "../../../Common/Components/ReactTableWithSummary/ReactTableWithSummary";
import moment from "moment";
import ContractManualIncome from "../../Contracts/Containers/ContractManualIncome";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import { DollarSign, Trash2, AlertTriangle } from "react-feather";
import { Link } from "react-router-dom";
import NewInvoice from "../../Invoices/Containers/NewInvoice";
import InvoiceEdit from "../../Invoices/Containers/InvoiceEdit";
import BalanceRefund from "../Containers/BalanceRefund";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import DeleteIncome from "../Containers/DeleteIncome";
import { Features, Tenant } from "../../../Common/Utils/Tenant";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import PAYMENT_STATUSES from "../../../Enums/PaymentStatuses";
import BALANCE_OWNER_TYPES from "../../../Enums/BalanceOwnerTypes";
import INCOME_TYPES from "../../../Enums/IncomeTypes";
import { havePermissions } from "../../../Common/Utils/Acl";
import InvoicesList from "./InvoicesList";

class BalancePreview extends Component {
  #cancelToken = getCancelToken();
  #contractsTableColumns = [
    {
      name: "signature",
      Header: this.props.t("Signature"),
      Cell: ({ original: { signature, studentName, contractId } }) => {
        const studentNameForSignature = studentName ? ` : ${studentName}` : "";
        const label = signature
          ? `${signature}${studentNameForSignature}`
          : studentName;

        return <Link to={`/persons/contract/${contractId}`}>{label}</Link>;
      },
    },
    {
      name: "contractPaymentStatus",
      Header: this.props.t("Payment status"),
      accessor: "contractPaymentStatus",
    },
  ];
  #duesTableColumns = [
    {
      name: "signature",
      Header: this.props.t("Signature"),
      accessor: "signature",
      Cell: ({ original: { signature, contractId } }) => (
        <Link to={`/persons/contract/${contractId}`}>{signature}</Link>
      ),
    },
    {
      name: "moduleName",
      Header: this.props.t("Module name"),
      accessor: "moduleName",
      Cell: ({ original: { moduleName } }) => (
        <span>{this.props.t(moduleName)}</span>
      ),
    },
    {
      name: "deadline",
      Header: this.props.t("Deadline"),
      accessor: "deadline",
    },
    {
      summaryAmount: "amount.amount",
      summaryCurrency: "amount.currency",
      name: "amount",
      Header: this.props.t("Amount"),
      Cell: ({ original: { amount, status, deadline } }) => {
        let className = "";
        if (
          (status.id === PAYMENT_STATUSES.UNPAID ||
            status.id === PAYMENT_STATUSES.PARTIALLY_PAID) &&
          moment(deadline).isBefore(moment().startOf("day"))
        ) {
          className = "text-danger";
        } else if (status.id === PAYMENT_STATUSES.PAID) {
          className = "text-success";
        }
        return (
          <span className={`tableAmount ${className}`}>
            <b>
              {renderAmountWithCurrency({
                currency: amount.currency,
                amount: amount.amount,
              })}
            </b>
          </span>
        );
      },
    },
  ];
  #incomesTableColumns = [
    {
      name: "transaction",
      Header: this.props.t("Transaction number"),
      accessor: "transaction",
    },
    {
      name: "type",
      Header: this.props.t("Type"),
      accessor: "type",
    },
    {
      name: "description",
      Header: this.props.t("Description"),
      accessor: "description",
    },
    {
      name: "transactionDate",
      Header: this.props.t("Transaction date"),
      accessor: "transactionDate",
    },
    {
      name: "bookingDate",
      Header: this.props.t("Booking date"),
      accessor: "bookingDate",
    },
    {
      summaryAmount: "amount.amount",
      summaryCurrency: "amount.currency",
      name: "amount",
      Header: this.props.t("Amount"),
      Cell: ({ original: { amount } }) => (
        <span className={`tableAmount`}>
          {renderAmountWithCurrency({
            currency: amount.currency,
            amount: amount.amount,
          })}
        </span>
      ),
    },
    {
      name: "delete",
      Header: "",
      width: 90,
      Cell: ({ original: { id, type } }) =>
        type === INCOME_TYPES.MANUAL ? (
          <div className={`text-center`}>
            <button
              type={`button`}
              className={`btn btn--transparent btn--no-border`}
              onClick={() => {
                this.setState({
                  showRemoveIncomeModal: true,
                  incomeToRemove: id,
                });
              }}
            >
              <Trash2 size={20} />
            </button>
          </div>
        ) : (
          ""
        ),
    },
    {
      name: "autoInvoice",
      Header: this.props.t("Auto invoice"),
      Cell: ({ original: { autoInvoice, id } }) =>
        havePermissions([permissions.INCOME_AUTOINVOICE]) ? (
          <div>
            <input
              type="checkbox"
              defaultChecked={autoInvoice}
              onChange={event => {
                this.props.updateIncomeAutoInvoice(
                  { autoInvoice: event.target.checked },
                  id,
                );
              }}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      name: "comment",
      Header: this.props.t("Comment"),
      Cell: ({ original: { id, comment } }) =>
        havePermissions([permissions.INCOME_AUTOINVOICE]) ? (
          <div>
            <input
              className="form-control"
              type="text"
              onChange={event => {
                this.props.updateIncomeComment(
                  { comment: event.target.value },
                  id,
                );
              }}
              defaultValue={comment}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  #refundsTableColumns = [
    {
      name: "description",
      Header: this.props.t("Description"),
      accessor: "description",
    },
    {
      name: "createdAt",
      Header: this.props.t("Created at"),
      Cell: ({ original: { createdAt } }) =>
        createdAt ? moment(createdAt).format("DD.MM.YYYY") : "",
    },
    {
      name: "createdBy",
      Header: this.props.t("Created by"),
      accessor: "createdBy",
    },
    {
      name: "refundedAt",
      Header: this.props.t("Refunded at"),
      Cell: ({ original: { refundedAt } }) =>
        refundedAt ? moment(refundedAt).format("DD.MM.YYYY") : "",
    },
    {
      name: "correction",
      Header: this.props.t("Correction"),
      accessor: "correction",
      show: Features.invoices,
    },
    {
      summaryAmount: "amount.amount",
      summaryCurrency: "amount.currency",
      name: "amount",
      Header: this.props.t("Amount"),
      Cell: ({ original: { amount } }) => (
        <span className={`tableAmount`}>
          {renderAmountWithCurrency({
            currency: amount.currency,
            amount: amount.amount,
          })}
        </span>
      ),
    },
  ];
  static propTypes = {
    fetchBalance: propTypes.func,
    fetchBalanceSettings: propTypes.func,
    saveBalanceSettings: propTypes.func,
    balanceId: propTypes.number,
    t: propTypes.func,
    balance: propTypes.object,
    balanceSettings: propTypes.object,
    fetchDictionary: propTypes.func,
    dictionaries: propTypes.object,
    invoices: propTypes.array,
    deleteIncome: propTypes.func,
    fetchInvoices: propTypes.func,
    downloadInvoice: propTypes.func,
    fetchInvoiceAmount: propTypes.func,
    updateIncomeAutoInvoice: propTypes.func,
    updateIncomeComment: propTypes.func,
    showHeader: propTypes.bool,
  };

  static defaultProps = {
    showHeader: true,
    loading: true,
  };

  state = {
    showAddIncomeForm: false,
    expired: true,
    showInvoiceEditForm: false,
    showAddRefundForm: false,
    showRemoveIncomeModal: false,
    settings: {},
    showErrorModal: false,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { balanceId } = this.props;
    if (balanceId !== prevProps.balanceId) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.loadData();
        },
      );
    }
  }

  loadData = () => {
    const {
      fetchBalance,
      fetchBalanceSettings,
      balanceId,
      fetchDictionary,
      fetchInvoices,
      fetchInvoiceAmount,
    } = this.props;
    const { expired } = this.state;
    fetchBalance(this.#cancelToken, balanceId, expired, () => {
      this.setState({
        loading: false,
      });
    });
    fetchInvoiceAmount(this.#cancelToken, balanceId);
    fetchBalanceSettings(this.#cancelToken, balanceId, data => {
      this.setState({ settings: data.data.data });
    });
    fetchDictionary(this.#cancelToken, {
      path: "/currencies",
      actionType: ACTION_TYPES.FETCH_CURRENCIES,
    });
    fetchInvoices(this.#cancelToken, balanceId);
  };

  removeIncome = correctionNumber => {
    const { deleteIncome, fetchBalance, balanceId } = this.props;
    const { expired, incomeToRemove } = this.state;
    const payload = { fk: correctionNumber };

    deleteIncome(incomeToRemove, payload, () => {
      fetchBalance(this.#cancelToken, balanceId, expired, () => {
        this.setState({
          loading: false,
        });
      });
      this.closeDeleteIncomeModal();
    });
  };

  showIncomeForm = () => {
    const {
      balance: {
        owner: { invoiceReceiverEmails },
      },
    } = this.props;
    if (!invoiceReceiverEmails || invoiceReceiverEmails.length === 0) {
      this.showOwnerErrorModal();
    } else {
      this.setState({
        showAddIncomeForm: true,
      });
    }
  };

  showOwnerErrorModal = () => this.setState({ showErrorModal: true });

  closeOwnerErrorModal = () => this.setState({ showErrorModal: false });

  showInvoiceForm = () => {
    const {
      balance: {
        owner: { invoiceReceiverEmails },
      },
    } = this.props;

    if (!invoiceReceiverEmails || invoiceReceiverEmails.length === 0) {
      this.showOwnerErrorModal();
    } else {
      this.setState({
        showAddInvoiceForm: true,
      });
    }
  };

  showEditInvoiceForm = invoice => {
    const {
      balance: {
        owner: { invoiceReceiverEmails },
      },
    } = this.props;

    if (!invoiceReceiverEmails || invoiceReceiverEmails.length === 0) {
      this.showOwnerErrorModal();
    } else {
      this.setState({
        showInvoiceEditForm: true,
        editingInvoice: invoice,
      });
    }
  };

  closeInvoiceForm = () => {
    this.setState({
      showAddInvoiceForm: false,
    });
  };

  setColorClass = amount => {
    let colorClass = "";
    if (amount > 0) {
      colorClass = "balance--positive";
    } else if (amount < 0) {
      colorClass = "balance--negative";
    }
    return colorClass;
  };

  renderSum = (data, withColors = false) => {
    return Object.keys(data).map((currency, index) => {
      const amount = data[currency];
      let colorClass = this.setColorClass(amount);
      return (
        <li className={`${withColors ? colorClass : ""}`} key={index}>
          {renderAmountWithCurrency({ currency, amount })}
        </li>
      );
    });
  };

  closePopup = () => {
    const {
      fetchBalance,
      fetchInvoices,
      balanceId,
      fetchInvoiceAmount,
    } = this.props;
    const { expired } = this.state;
    fetchBalance(this.#cancelToken, balanceId, expired, () => {
      this.setState({
        showAddIncomeForm: false,
        showAddInvoiceForm: false,
        loading: false,
      });
    });
    fetchInvoices(this.#cancelToken, balanceId);
    fetchInvoiceAmount(this.#cancelToken, balanceId);
  };

  toggleExpiredFilter = event => {
    this.setState(
      {
        expired: event.target.checked,
      },
      () => {
        this.loadData();
      },
    );
  };

  downloadInvoice = invoice => {
    this.props.downloadInvoice(this.#cancelToken, {
      invoiceId: invoice.id,
      invoiceNumber: invoice.nr,
    });
  };

  showAddRefundForm = () => this.setState({ showAddRefundForm: true });

  closeAddRefundForm = (afterSubmit = false) => {
    if (afterSubmit) {
      const { expired } = this.state;
      const { fetchBalance, fetchInvoices, balanceId } = this.props;
      fetchBalance(this.#cancelToken, balanceId, expired, () => {
        this.setState({ showAddRefundForm: false, loading: false });
      });
      fetchInvoices(this.#cancelToken, balanceId);
    } else {
      this.setState({ showAddRefundForm: false });
    }
  };

  toggleAutoInvoicesSetting = event => {
    this.setState(
      {
        settings: {
          ...this.state.settings,
          autoInvoices: event.target.checked,
        },
      },
      this.saveSettings,
    );
  };

  closeDeleteIncomeModal = () =>
    this.setState({
      showRemoveIncomeModal: false,
      incomeToRemove: null,
    });

  saveSettings = () => {
    const { balanceId, saveBalanceSettings } = this.props;
    saveBalanceSettings(this.state.settings, balanceId);
  };
  handleChangeSendStatus = (transition, invoice) => {
    const { updateInvoiceStatus, fetchInvoices, balanceId } = this.props;
    const payload = [
      {
        invoiceId: invoice.id,
        transition: transition.name,
      },
    ];
    updateInvoiceStatus(payload, () => {
      fetchInvoices(this.#cancelToken, balanceId);
    });
  };

  render() {
    const {
      t,
      balance: {
        overduesSum,
        paidDuesSum,
        incomeSum,
        refundSum,
        dues,
        incomes,
        refunds,
        contracts,
        owner,
        total,
      },
      dictionaries: { currencies },
      invoices,
      balanceId,
      showHeader,
    } = this.props;
    const {
      showAddIncomeForm,
      showAddInvoiceForm,
      showInvoiceEditForm,
      editingInvoice,
      showAddRefundForm,
      showErrorModal,
      loading,
    } = this.state;

    let editInvoiceFormTitle = _.get(editingInvoice, "nr", "");

    return !loading && incomes ? (
      <div>
        <CustomModal
          isOpen={showErrorModal}
          onRequestClose={this.closeOwnerErrorModal}
          ariaHideApp={false}
        >
          <div className="row">
            <div className="col-xs-1">
              <AlertTriangle size={70} />
            </div>
            <div className="col-xs-10 warning-alert">{t("WARNING!")}</div>
            <div className="col-xs-12 padding__b20 warning-alert__small warning-alert__small--red">
              {t(
                "The e-mail address of the invoice receiver has not been added",
              )}
            </div>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={showAddRefundForm}
          title={`${t("Add refund")}`}
          onRequestClose={this.closeAddRefundForm}
          ariaHideApp={false}
        >
          <BalanceRefund
            balanceId={balanceId}
            currencies={currencies}
            closePopup={this.closeAddRefundForm}
          />
        </CustomModal>
        <CustomModal
          isOpen={showInvoiceEditForm}
          title={`${t("Invoice edition")} ${editInvoiceFormTitle}`}
          onRequestClose={() => {
            this.setState({ showInvoiceEditForm: false });
          }}
          ariaHideApp={false}
        >
          <>
            <div className="col-xs-12">
              <div className="row padding__b20 font-small">
                <div className="col-xs-6">
                  <span>{t("Client")}: </span>
                  {owner.name}
                </div>
                <div className="col-xs-6">
                  <span>{t("Data for sending invoices")}: </span>
                  {owner.invoiceReceiverEmails.map(email => (
                    <span key={email}>{`${email} `}</span>
                  ))}
                </div>
              </div>
            </div>
            <InvoiceEdit
              closeForm={() => {
                this.setState({ showInvoiceEditForm: false }, this.loadData);
              }}
              invoice={editingInvoice}
              incomes={incomes}
            />
          </>
        </CustomModal>
        <CustomModal
          isOpen={showAddIncomeForm}
          title={`${t("Entering manual income")}`}
          onRequestClose={() => {
            this.setState({ showAddIncomeForm: false });
          }}
          ariaHideApp={false}
        >
          <ContractManualIncome
            balanceId={balanceId}
            currencies={currencies}
            closePopup={this.closePopup}
            independent
          />
        </CustomModal>
        <CustomModal
          isOpen={showAddInvoiceForm}
          title={`${t("Add an invoice")}`}
          onRequestClose={this.closeInvoiceForm}
          ariaHideApp={false}
        >
          <>
            <div className="col-xs-12">
              <div className="row padding__b20 font-small">
                <div className="col-xs-6">
                  <span>{t("Client")}: </span>
                  {owner.name}
                </div>
                <div className="col-xs-6">
                  <span>{t("Data for sending invoices")}: </span>
                  {owner.invoiceReceiverEmails.map(email => (
                    <span key={email}>{`${email} `}</span>
                  ))}
                </div>
              </div>
            </div>
            <NewInvoice
              balanceId={balanceId}
              duesSum={overduesSum}
              currencies={currencies}
              closePopup={this.closePopup}
              independent
              owner={owner}
            />
          </>
        </CustomModal>
        <CustomModal
          isOpen={this.state.showRemoveIncomeModal}
          title={t("Are you sure you want to remove an income?")}
          onRequestClose={this.closeDeleteIncomeModal}
        >
          <DeleteIncome
            income={this.state.incomeToRemove}
            onClose={this.closeDeleteIncomeModal}
            onSubmit={this.removeIncome}
          />
        </CustomModal>
        <div className={`row`}>
          <div className={`col-xs-12`}>
            {showHeader && (
              <header className="main-header">
                <h1 className="contract-heading">{owner.name}</h1>
                {owner.type === BALANCE_OWNER_TYPES.PAYER && (
                  <h2>
                    <strong>NIP: {owner.details}</strong>
                  </h2>
                )}
              </header>
            )}
            <div className={`col-xs-6`}>
              <div className={`checkbox`}>
                <input
                  id={`expired`}
                  type={`checkbox`}
                  name={`expired`}
                  checked={this.state.expired}
                  onChange={this.toggleExpiredFilter}
                />
                <label htmlFor={`expired`}>{t("Show expired only")}</label>
              </div>
              <Authorize
                component={
                  <Tenant
                    component={
                      <div className={`checkbox`}>
                        <input
                          id={`setting-autoInvoices`}
                          type={`checkbox`}
                          name={`autoInvoices`}
                          checked={this.state.settings.autoInvoices}
                          onChange={this.toggleAutoInvoicesSetting}
                        />
                        <label htmlFor={`setting-autoInvoices`}>
                          {t("Auto-invoices")}
                        </label>
                      </div>
                    }
                    feature={Features.invoices}
                  />
                }
                allows={[permissions.AUTOINVOICE_MANAGEMENT]}
              />
            </div>
            <button
              onClick={this.showIncomeForm}
              className={`btn btn-dark pull-right bottom-space`}
              type={`button`}
              id="addIncomeButton"
            >
              <DollarSign size={12} /> {t("Add income")}
            </button>
            <Tenant
              component={
                <button
                  onClick={this.showInvoiceForm}
                  className={`btn btn-dark pull-right bottom-space right-space`}
                  type={`button`}
                  id={"add-invoice-btn"}
                >
                  {t("Add an invoice")}
                </button>
              }
              feature={Features.invoices}
            />
            <Authorize
              component={
                <button
                  type="button"
                  id="add-refund-form-button"
                  onClick={this.showAddRefundForm}
                  className="btn btn-dark pull-right mr-1"
                >
                  {t("Add refund")}
                </button>
              }
              allows={[permissions.REFUND_ADD]}
            />
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-2`}>
            <div className="panel panel-default">
              <div className="panel-body" id={`totalSum`}>
                <p>{t("Balance_")}:</p>
                <ul className={`list-unstyled`}>
                  {this.renderSum(total, true)}
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-3`}>
            <div
              className="panel panel-default"
              title={t(
                "It is the sum of paid and overdue payments with a deadline to the present day",
              )}
            >
              <div className="panel-body" id={`overduesSum`}>
                <p>{t("Overdues' sum")}:</p>
                <ul className={`list-unstyled`}>
                  {this.renderSum(overduesSum)}
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-3`}>
            <div className="panel panel-default">
              <div className="panel-body" id={`paidDuesSum`}>
                <p>{t("Paid dues' sum")}:</p>
                <ul className={`list-unstyled`}>
                  {this.renderSum(paidDuesSum)}
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-2`}>
            <div className="panel panel-default">
              <div className="panel-body" id={`incomesSum`}>
                <p>{t("Incomes' sum")}:</p>
                <ul className={`list-unstyled`}>{this.renderSum(incomeSum)}</ul>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-2`}>
            <div className="panel panel-default">
              <div className="panel-body" id={`refundsSum`}>
                <p>{t("Refunds' sum")}:</p>
                <ul className={`list-unstyled`}>{this.renderSum(refundSum)}</ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-12`}>
            <h2>
              <strong>
                {t("Subaccount")}: {owner.subBankAccount}
              </strong>
            </h2>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-xs-12 col-sm-12`}>
            <h2>
              <strong>
                {t("Refund bank account")}: {owner.bankAccount}
              </strong>
            </h2>
          </div>
        </div>
        {dues && (
          <div className={`row`}>
            <div className={`col-xs-12`} id={`dues`}>
              <h2>{t("Dues")}</h2>
              <ReactTableWithSummary
                data={dues ? dues : []}
                summaryConfig={[
                  {
                    data: dues,
                  },
                ]}
                defaultPageSize={dues ? dues.length : 0}
                showPagination={false}
                manual
                columns={this.#duesTableColumns}
              />
            </div>
          </div>
        )}
        {incomes && (
          <div className={`row`}>
            <div className={`col-xs-12`} id={`incomes`}>
              <h2>{t("Incomes")}</h2>
              <ReactTableWithSummary
                data={incomes ? incomes : []}
                summaryConfig={[
                  {
                    data: incomes,
                  },
                ]}
                defaultPageSize={incomes ? incomes.length : 0}
                showPagination={false}
                manual
                columns={this.#incomesTableColumns}
              />
            </div>
          </div>
        )}
        {refunds && (
          <div className={`row`}>
            <div className={`col-xs-12`} id={`refunds`}>
              <h2>{t("Refunds")}</h2>
              <ReactTableWithSummary
                data={refunds ? refunds : []}
                summaryConfig={[
                  {
                    data: refunds,
                  },
                ]}
                defaultPageSize={refunds ? refunds.length : 0}
                showPagination={false}
                manual
                columns={this.#refundsTableColumns}
              />
            </div>
          </div>
        )}
        {contracts && (
          <div className={`row`}>
            <div className={`col-xs-12`} id={`contracts`}>
              <h2>{t("Contracts")}</h2>
              <ReactTableWithSummary
                data={contracts ? contracts : []}
                summaryConfig={[
                  {
                    data: contracts,
                  },
                ]}
                defaultPageSize={contracts ? contracts.length : 0}
                showPagination={false}
                manual
                columns={this.#contractsTableColumns}
              />
            </div>
          </div>
        )}
        {invoices && (
          <Tenant
            component={
              <InvoicesList
                t={t}
                invoices={invoices}
                downloadInvoice={this.downloadInvoice}
                showEditInvoiceForm={this.showEditInvoiceForm}
                handleChangeSendStatus={this.handleChangeSendStatus}
              />
            }
            feature={Features.invoices}
          />
        )}
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(BalancePreview);
