export default fields => {
  let errors = {};
  const {
    description,
    amountGross,
    sellDate,
    issueMonth,
    paymentType,
    receiver,
    vatRate,
    legalBasis,
    hasLegalBasis,
  } = fields;
  if (!description) {
    errors.description = "Description is required";
  }
  if (!paymentType) {
    errors.paymentType = "Select payment method";
  }
  if (!amountGross || (amountGross && !amountGross.amount)) {
    errors.amountGross = {
      amount: "Amount gross is required",
    };
  }
  if (!sellDate) {
    errors.sellDate = "Sell date is required";
  }
  if (!issueMonth) {
    errors.issueMonth = "Issue date is required";
  }
  if (receiver && !receiver.street) {
    errors.receiver = "User's address is not filled, please fill data";
  }
  if (vatRate && hasLegalBasis && !legalBasis) {
    errors.legalBasis = "You must select legal basis";
  }
  return errors;
};
