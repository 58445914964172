import React from "react";
import PropTypes from "prop-types";

const NestedEmployeeCompetency = props => {
  return (
    <div>
      <span>{props.name}</span>
    </div>
  );
};

NestedEmployeeCompetency.propTypes = { name: PropTypes.string };

export default NestedEmployeeCompetency;
