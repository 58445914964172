import React, { Component } from "react";
import Loader from "../../../Common/Components/Loader/Loader";
import { RenderText, RenderSelect, RenderNumber } from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import getNameByKey from "../../../Helpers/GetNameByKey";
import PropTypes from "prop-types";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import EditDictionaryField from "../Helpers/EditDictionaryField";

class PollsDictionary extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      pollsList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          daysToSend: PropTypes.number,
          ruleEvent: PropTypes.string,
          type: PropTypes.object,
          templateUrl: PropTypes.string,
        }),
      ).isRequired,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        daysToSend: PropTypes.number,
        type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        ruleEvent: PropTypes.string,
        templateUrl: PropTypes.string,
      }).isRequired,
    ),
    initialize: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    change: PropTypes.func,
    fetchPolls: PropTypes.func,
    updatePoll: PropTypes.func,
    createPoll: PropTypes.func,
    pollsList: PropTypes.array,
    fetchPollEvents: PropTypes.func,
    pollEvents: PropTypes.array,
    fetchDictionary: PropTypes.func,
    dictionaries: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchPolls, fetchPollEvents, fetchDictionary } = this.props;
    const { FETCH_POLL_TYPES } = ACTION_TYPES;
    this.formActions = {
      SAVE: "SAVE",
      CREATE: "CREATE",
    };
    this.state = {
      editing: [],
      pollsLoading: true,
    };
    fetchPollEvents(this.cancelToken);
    fetchDictionary(this.cancelToken, {
      path: "/poll/type",
      actionType: FETCH_POLL_TYPES,
    });
    fetchPolls(this.cancelToken, () => {
      this.setState({ pollsLoading: false });
    });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, values } = this.props;
    if (initialValues.pollsList !== prevProps.initialValues.pollsList) {
      initialize(initialValues);
    } else if (
      prevProps.values &&
      values.pollsList !== prevProps.values.pollsList
    ) {
      initialize(values);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;
    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }
  onSubmit = values => {
    const pollValues = values.pollsList.find(
      competency => competency.id === this.state.rowId,
    );
    const updatePolls = () => {
      fetchPolls(this.cancelToken, () => {
        const { editing } = this.state;
        const rowIndex = editing.indexOf(pollValues.id);
        rowIndex >= 0 && editing.splice(rowIndex, 1);
        this.setState({
          pollsLoading: false,
          editing,
        });
      });
    };
    const { createPoll, updatePoll, fetchPolls } = this.props;

    switch (this.state.action) {
      case this.formActions.SAVE:
        updatePoll(pollValues, updatePolls);
        break;
      case this.formActions.CREATE:
        createPoll(pollValues, updatePolls);
        break;
    }
  };

  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    this.setState(
      {
        action,
        rowId,
      },
      () => {
        const submitter = handleSubmit(this.onSubmit);
        submitter();
      },
    );
  };

  renderStatusField = row => {
    const { values, pollEvents, t } = this.props;
    const value = getNestedObject(values[row.index], row.column.id);
    const name = getNameByKey("code", value, pollEvents);
    return (
      <div className="editField">
        {!values[row.index].id ||
        this.state.editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              name={`pollsList[${row.index}][${row.column.id}]`}
              component={RenderSelect}
              dropdownConfig={{
                data: pollEvents,
                textField: "name",
                valueField: "code",
                placeholder: t("Select"),
              }}
              under={{
                fieldClassName: "col-sm-12",
              }}
            />
          </div>
        ) : (
          values[row.index].id && (
            <EditDictionaryField
              onClick={() => this.enableEditMode(row)}
              text={name}
            />
          )
        )}
      </div>
    );
  };

  renderSelectTypeField = row => {
    const {
      values,
      t,
      dictionaries: { pollTypes },
    } = this.props;
    const value = getNestedObject(values[row.index], row.column.id);
    const name = getNameByKey("id", value && value.id, pollTypes);
    return (
      <div className="editField">
        {!values[row.index].id ||
        this.state.editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              name={`pollsList[${row.index}][${row.column.id}]`}
              component={RenderSelect}
              dropdownConfig={{
                data: pollTypes,
                textField: "name",
                valueField: "id",
                placeholder: t("Select"),
              }}
              under={{
                fieldClassName: "col-sm-12",
              }}
            />
          </div>
        ) : (
          values[row.index].id && (
            <EditDictionaryField
              onClick={() => this.enableEditMode(row)}
              text={name}
            />
          )
        )}
      </div>
    );
  };

  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`pollsList[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  renderNumberField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`pollsList[${row.index}][${row.column.id}]`}
              component={RenderNumber}
              minimum={-1000}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  addPoll = () => {
    const { change, values } = this.props;
    const newPollsList = [
      {
        name: null,
        daysToSend: 0,
        ruleEvent: "",
        type: null,
        templateUrl: "",
      },
      ...values,
    ];
    change("pollsList", newPollsList);
  };

  render() {
    const { t, values } = this.props;
    const { pollsLoading } = this.state;
    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={
                  values[values.length - 1] && !values[values.length - 1].id
                }
                className="btn bottom-space btn btn-dark"
                onClick={() => {
                  this.addPoll();
                }}
              >
                {t("Add survey")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={pollsLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("NameItem"),
                accessor: "name",
                Cell: this.renderEditField,
              },
              {
                Header: t("Url"),
                accessor: "templateUrl",
                Cell: this.renderEditField,
              },
              {
                Header: t("Days to send"),
                accessor: "daysToSend",
                Cell: this.renderNumberField,
              },
              {
                Header: t("Type"),
                accessor: "type",
                Cell: this.renderSelectTypeField,
              },
              {
                Header: t("Sending event"),
                accessor: "ruleEvent",
                Cell: this.renderStatusField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(this.formActions.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(this.formActions.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(PollsDictionary);
