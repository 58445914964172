import React from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import CompanyListElement from "./CompanyListElement";
import Paginator from "../../../Common/Components/Paginator/Paginator";

const CompaniesList = ({
  companies,
  translations,
  counter,
  currentPage,
  pageSize,
  onChangePage,
}) => {
  const numberOfPages = Math.ceil(counter / pageSize);

  const listPaging = numberOfPages > 1 && (
    <Paginator
      onClick={onChangePage}
      pages={numberOfPages}
      currentPage={currentPage}
    />
  );

  const noData = !companies.length && (
    <div className={`row product-item`}>
      <div className={`vertical-center horizontal-center`}>
        <p>{translations("No records found")}</p>
      </div>
    </div>
  );

  return (
    <div className="row">
      {companies.map(company => (
        <CompanyListElement
          translations={translations}
          company={company}
          key={String(company.id)}
        />
      ))}

      {noData}
      {listPaging}
    </div>
  );
};

CompaniesList.propTypes = {
  counter: PropTypes.number,
  companies: PropTypes.array,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
  translations: PropTypes.func,
};

export default translate()(CompaniesList);
