import _ from "lodash";
import ACTION_TYPES from "../../Enums/ActionTypes";

const initialState = {
  rooms: [],
  coursesKinds: [],
  coursesModes: [],
  employeeAvailability: [],
  languages: [],
  installments: [],
  currencies: [],
  contractTypes: [],
  personTypes: [],
  payerTypes: [],
  cities: [],
  countries: [],
  contractFinishReasons: [],
  employeeCompetencies: [],
  contractStatus: [],
  studentCourse: [],
  courseStatuses: [],
  logEventGroups: [],
  pollTypes: [],
  lecturesTypes: [],
};

export function fetchDictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ROOMS:
      return {
        ...state,
        rooms: [...action.payload.data.data],
      };
    case ACTION_TYPES.CREATE_NEW_ROOM:
      return {
        ...state,
        rooms: [...state.rooms, action.payload.data.data],
      };
    case ACTION_TYPES.CREATE_EMPLOYEE_COMPETENCY:
      return {
        ...state,
        employeeCompetencies: [
          ...state.employeeCompetencies,
          action.payload.data.data,
        ],
      };
    case ACTION_TYPES.CREATE_POLL_TYPE:
      return {
        ...state,
        pollTypes: [...state.pollTypes, action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_PRODUCTS_KINDS:
      return {
        ...state,
        coursesKinds: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_COURSES_MODES:
      return {
        ...state,
        coursesModes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_EMPLOYEE_AVAILABILITY:
      return {
        ...state,
        employeeAvailability: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_LANGUAGES:
      return {
        ...state,
        languages: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_INSTALLMENTS:
      return {
        ...state,
        installments: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_CURRENCIES:
      return {
        ...state,
        currencies: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_EMPLOYEE_COMPETENCIES:
      return {
        ...state,
        employeeCompetencies: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_CITIES: {
      let cities = [...state.cities, ...action.payload.data.data.items];
      return {
        ...state,
        cities: [..._.uniqBy(cities, "id")],
      };
    }
    case ACTION_TYPES.RESET_CITIES:
      return {
        ...state,
        cities: [],
      };
    case ACTION_TYPES.FETCH_CONTRACT_TYPES:
      return {
        ...state,
        contractTypes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_PERSON_TYPES:
      return {
        ...state,
        personTypes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_COUNTRIES:
      return {
        ...state,
        countries: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_CONTRACT_FINISH_REASONS:
      return {
        ...state,
        contractFinishReasons: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_CONTRACT_FUNDING_TYPES:
      return {
        ...state,
        contractFundingTypes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_CONTRACT_STATUS:
      return {
        ...state,
        contractStatus: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_STUDENT_COURSE:
      return {
        ...state,
        studentCourse: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_PAYER_TYPES:
      return {
        ...state,
        payerTypes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_COURSE_STATUSES:
      return {
        ...state,
        courseStatuses: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_LOG_EVENT_GROUP:
      return {
        ...state,
        logEventGroups: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_POLL_TYPES:
      return {
        ...state,
        pollTypes: [...action.payload.data.data],
      };
    case ACTION_TYPES.FETCH_LECTURES_TYPES:
      return {
        ...state,
        lecturesTypes: [...action.payload.data.data],
      };
  }
  return state;
}

export function fetchDictionaryOnlyTeach(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CITIES_ONLY_TEACH:
      return [...action.payload.data.data.items];
    default:
      return state;
  }
}

export function getEmployeeId(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSON_TYPES: {
      const personTypes = action.payload.data.data;
      const EMPLOYEE_ID = 2;
      const employeeType = personTypes.filter(item => item.id === EMPLOYEE_ID);
      return employeeType[0].id;
    }
    default:
      return state;
  }
}
export function getStudentId(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PERSON_TYPES: {
      const personTypes = action.payload.data.data;
      const STUDENT_ID = 3;
      const studentType = personTypes.filter(item => item.id === STUDENT_ID);
      return studentType[0].id;
    }
    default:
      return state;
  }
}

export function fetchPolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_POLLS:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function fetchPollEvents(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_POLL_EVENTS:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function fetchModulePolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MODULE_POLLS:
      return [...action.payload.data.data];
    default:
      return state;
  }
}

export function fetchCoursePolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_COURSE_POLLS: {
      const polls = [...state, ...action.payload.data.data];
      return [..._.uniqBy(polls, "id")];
    }
    case ACTION_TYPES.FETCH_EVENT_POLLS: {
      const polls = [...state, ...action.payload.data.data];
      return [..._.uniqBy(polls, "id")];
    }
    default:
      return state;
  }
}

export function fetchContractPolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_POLLS:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function fetchContractModulesPolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_MODULES_POLLS:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function fetchContractSlavePolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_SLAVE_POLLS:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchContractManualPolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTRACT_MANUAL_POLLS:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function fetchUserEngageLeadTypes(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USER_ENGAGE_LEAD_TYPE:
      return [...action.payload.data.data];

    default:
      return state;
  }
}

export function fetchDictComments(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_DICT_COMMENTS:
      return [...action.payload.data.data];

    default:
      return state;
  }
}

export function fetchDictPromotions(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_DICT_PROMOTIONS:
      return [...action.payload.data.data];

    default:
      return state;
  }
}
