import React, { Component } from "react";
import { translate } from "react-i18next";
import EventForm from "./EventForm";
import {
  EditEventFormProps,
  EditEventFormState,
  EventFormValues,
  FetchEmployeesParams,
} from "../Types/EditEventForm";
import getCancelToken from "../../../Helpers/GetCancelToken";

const COUNTER_FOR_PAGING = 11;

class EditEventForm extends Component<EditEventFormProps, EditEventFormState> {
  state = {
    fetchingLecturers: false,
    searchLecturerQuery: "",
  };
  cancelToken = getCancelToken();

  componentDidMount() {
    const { fetchEmployees } = this.props;

    fetchEmployees(
      this.cancelToken,
      this.getRequestConfig("", COUNTER_FOR_PAGING),
    );
  }

  handleSubmit = (values: EventFormValues): void => {
    const { onEdit, event } = this.props;
    const { lecturer: lecturerArray, room } = values;
    const [lecturer] = lecturerArray;

    const payload = {
      ...values,
      lecturer: lecturer && lecturer.personId,
      room: room && room.id,
      roomRequired: values.roomReq,
      id: event.id,
    };

    onEdit(payload);
  };

  getRequestConfig = (search = null, counter = null): FetchEmployeesParams => {
    const START_PAGE = 1;
    const config: FetchEmployeesParams = {
      simpleResponse: true,
    };

    if (counter) {
      config.pageNo = START_PAGE;
      config.pageAmount = counter;
    }

    if (search) {
      config.q = search;
    }

    return config;
  };

  handleSearchLecturer = (query: string): void => {
    const { fetchEmployees } = this.props;

    this.setState(
      { fetchingLecturers: true, searchLecturerQuery: query },
      () => {
        fetchEmployees(
          this.cancelToken,
          this.getRequestConfig(query, COUNTER_FOR_PAGING),
          () => this.setState({ fetchingLecturers: false }),
        );
      },
    );
  };

  onShowMoreLecturersResults = (counter: number): void => {
    const { fetchEmployees } = this.props;
    const counterForPaging = counter + 1;
    const { searchLecturerQuery } = this.state;

    fetchEmployees(
      this.cancelToken,
      this.getRequestConfig(searchLecturerQuery, counterForPaging),
      () => this.setState({ fetchingLecturers: false }),
    );
  };

  render() {
    const { t, rooms, lecturers, onRemoveEvent, event } = this.props;
    const { fetchingLecturers } = this.state;

    return (
      <div>
        <EventForm
          t={t}
          handleSubmit={this.handleSubmit}
          handleRemoveEvent={() => onRemoveEvent(event.id)}
          rooms={rooms}
          event={event}
          fetchData={this.handleSearchLecturer}
          lecturers={lecturers}
          fetchingLecturers={fetchingLecturers}
          onShowMoreLecturers={this.onShowMoreLecturersResults}
        />
      </div>
    );
  }
}

export default translate()(EditEventForm);
