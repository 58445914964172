import Dinero from "dinero.js";

export default ({ amount, currency }) => {
  const dineroFormat = Dinero({ amount, currency });
  const dineroString = dineroFormat.toFormat();
  const clearAmount = dineroString
    .match(new RegExp("\\d+\\s*\\,*\\.*", "gmi"))
    .join("");
  const clearCurrency = dineroString
    .replace(clearAmount, "")
    .replace("-", "")
    .trim();
  return `${
    dineroFormat.isNegative() ? "- " : ""
  }${clearAmount} ${clearCurrency}`;
};
