import React from "react";
import { CourseMode } from "../../../Types/DictionaryItem";
import { Field, FieldArray } from "formik";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { RenderSelect } from "../../../Forms/InputComponents/Select";
import TimePickerSingle from "../../../Forms/InputComponents/TimePickerSingle";
import CourseDays from "../../../Forms/InputComponents/CourseDays";
import WEEK_DAYS from "../../../Enums/WeeksDays";

type CourseScheduleProps = InjectedTranslateProps & {
  disabled: boolean;
  modes: Array<CourseMode>;
  onChangeCourseMode: (mode: CourseMode) => void;
  daysError: string;
};

const CourseSchedule: React.FunctionComponent<CourseScheduleProps> = ({
  t,
  modes,
  disabled,
  onChangeCourseMode,
  daysError,
}): React.ReactElement => {
  const daysOfWeek = WEEK_DAYS.SHORT.map((weekDay, index) => ({
    name: t(weekDay),
    id: index + 1,
  }));

  const fieldClasses = {
    containerClassName: "col-xs-12",
    fieldClassName: "col-xs-12",
  };

  return (
    <div className="col-md-4 col-sm-4">
      <div className="row">
        <Field
          id="courseMode"
          name="courseSchedule.courseMode"
          label={t("Mode")}
          component={RenderSelect}
          required
          disabled={disabled}
          dropdownConfig={{
            data: modes,
            textField: "name",
            valueField: "id",
            onChange: item => onChangeCourseMode(item),
          }}
          under={fieldClasses}
        />
      </div>
      <div className="course-schedule__mode">
        <FieldArray
          name="courseSchedule.days"
          render={props => (
            <CourseDays
              options={daysOfWeek}
              disabled={disabled}
              t={t}
              daysError={daysError}
              {...props}
            />
          )}
        />

        <div className="row ">
          <div className="col-xs-12">
            <p>
              <small>{t("Lecture hours")}</small>
            </p>
          </div>

          <div className="col-xs-6 form-group">
            <div className="col-xs-3">
              <label>{t("From")}</label>
            </div>

            <div className="col-xs-9">
              <Field
                name="courseSchedule.startHour"
                component={TimePickerSingle}
                required
                disabled={disabled}
              />
            </div>
          </div>

          <div className="col-xs-6 form-group">
            <div className="col-xs-3">
              <label>{t("To")}</label>
            </div>

            <div className="col-xs-9">
              <Field
                name="courseSchedule.endHour"
                component={TimePickerSingle}
                required
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSchedule;
