import React from "react";
import PropTypes from "prop-types";
import { Briefcase, Check, X } from "react-feather/dist/index";
import ListElement from "../../../Common/Components/ListElement/ListElement";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

const CompanyListElement = ({ company, translations }) => {
  const {
    id,
    name,
    taxNr,
    isTaxNrEUActive,
    regonNr,
    address: { street, zipCode, city, country },
    balance: { total },
    taxRate,
  } = company;

  const getBalance = () => {
    const keys = Object.keys(total);
    return (
      <span>
        {keys.map(currency => (
          <span key={currency}>{`${total[currency]} ${currency}`}</span>
        ))}
      </span>
    );
  };

  return (
    <ListElement
      icon={<Briefcase size={28} />}
      path={`/companies/${id}`}
      clickable={havePermissions([permissions.COMPANY_VIEW])}
      actionClasses="horizontal-center"
    >
      <div>
        <h5 className="text product-name">
          {name}
          <span className="signature">
            {` - ${street}, ${zipCode} ${city.name}, ${country.name}`}
          </span>
        </h5>
        <div className="product-info">
          <p>
            <span className="product-info-details">
              {translations("REGON")}: {regonNr}
            </span>
            <span className="product-info-details">
              {translations("TIN")}: {taxNr}
            </span>
            <span className="product-info-details">
              {translations("UE VAT")}:{" "}
              {isTaxNrEUActive ? <Check size={14} /> : <X size={14} />}
            </span>
          </p>
          <p>
            <span className="product-info-details">
              {translations("Balance")}: {getBalance()}
            </span>
            <span className="product-info-details">
              {taxRate}
              {translations("% VAT")}
            </span>
          </p>
        </div>
      </div>
    </ListElement>
  );
};

CompanyListElement.propTypes = {
  company: PropTypes.object,
  translations: PropTypes.func,
};

export default CompanyListElement;
