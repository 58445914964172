import React from "react";
import StudentContractForm from "../Components/StudentContractForm";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDictionary,
  fetchDictPromotions,
} from "../../Dictionaries/Actions";
import {
  fetchQuestionnaire,
  clearContract,
  fetchContract,
  updateContract,
  fundingCalculate,
  contractOverpay,
  getDiploma,
  updateFinishedContract,
  fetchContractPackagesTransitions,
  updatePayments,
  fetchPayments,
  fetchContractMeta,
  createOrder,
  getContractAmounts,
} from "../Actions";
import {
  searchCourse,
  getCourse,
  fetchCourses,
} from "../../Courses/Actions/CoursesActions";
import validate from "../Validators/ContractValidator";
import {
  fetchProductById,
  fetchProductModulesInstallmentStartSimple,
  fetchProductModulesSimple,
  fetchProductPricesById,
  priceGrossCalculator,
} from "../../Products/Actions";
import { passErrorToStore, clearErrorFromStore } from "../../App/Actions";
import { fetchContractPolls } from "../../Dictionaries/Actions";
import { paymentActivate, paymentCancel, getContract } from "../Actions";
import { success } from "react-notification-system-redux";
import { fetchCompanies } from "../../Companies/Actions/CompaniesActions";
import { get } from "lodash";

const FORM_NAME = "CONTRACT_DETAILS";

let ContractUpdate = props => <StudentContractForm {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(
    state,
    "contract",
    "student",
    "course",
    "payments",
    "type",
    "sumOfModulesAmounts",
    "blockAmounts",
    "blockPercents",
    "sumOfParties",
    "studentAmount",
    "thirdPartyAmount",
    "maxThirdPartyAmount",
    "contractDocType",
  );
  const validationErrors = state.form[FORM_NAME]
    ? state.form[FORM_NAME].syncErrors
    : {};
  let {
    foundCourses,
    companies: { companiesList },
    dictionaries,
    contract,
    showError,
    payments,
    currentProductVersion,
    coursesList,
    contractPackagesTransitions,
    incomesThirdPartyArray,
    __metadata,
    productVersionModulesContract,
    dictPromotions,
  } = state;

  const dictionariesWithMetadata = { ...dictionaries, ...__metadata.contract };

  return {
    values,
    companies: companiesList,
    foundCourses,
    dictionaries: dictionariesWithMetadata,
    showError,
    payments,
    contract,
    originalContractStatus: get(contract, "contract.status.code"),
    currentProductVersion,
    currentProductVersionModules: productVersionModulesContract,
    coursesList,
    contractPackagesTransitions,
    incomesThirdPartyArray,
    validationErrors,
    productPrices: __metadata.prices,
    contractAmounts: __metadata.contractAmounts,
    specialOffers: dictPromotions,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      fetchCompanies,
      fundingCalculate,
      getCourse,
      fetchContract,
      updateContract,
      searchCourse,
      contractOverpay,
      fetchQuestionnaire,
      passErrorToStore,
      clearErrorFromStore,
      clearContract,
      getDiploma,
      fetchProductById,
      fetchCourses,
      updateFinishedContract,
      fetchContractPackagesTransitions,
      fetchContractPolls,
      updatePayments,
      fetchPayments,
      priceGrossCalculator,
      paymentActivate,
      paymentCancel,
      success,
      fetchContractMeta,
      fetchProductModulesSimple,
      fetchProductModulesInstallmentStartSimple,
      fetchProductPricesById,
      createOrder,
      getContract,
      getContractAmounts,
      fetchDictPromotions,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractUpdate),
);
