import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import SideModal from "../../../Common/Components/SideModal/SideModal";
import getCancelToken from "../../../Helpers/GetCancelToken";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import AddNotesForm from "./AddNotesForm";
import { getDateWithTime } from "../../../Common/Utils/DateFormatters";
import NotesList from "./NotesList";
import ContractNoteSource from "../../../Enums/ContractNoteSource";

class Notes extends Component {
  cancelToken = getCancelToken();
  state = {
    showModal: false,
    addNoteModalVisible: false,
  };

  componentDidMount() {
    const { fetchNotes, contractId } = this.props;
    fetchNotes(this.cancelToken, contractId, ContractNoteSource.CONTRACT);
  }

  toggleSideModal = () =>
    this.setState(prevState => ({ showModal: !prevState.showModal }));

  showAddNoteModal = () => this.setState({ addNoteModalVisible: true });

  hideAddNoteModal = () => this.setState({ addNoteModalVisible: false });

  handleAddNote = async noteContent => {
    const { addNote, fetchNotes, contractId } = this.props;
    const payload = { note: noteContent, source: ContractNoteSource.CONTRACT };

    await addNote(this.cancelToken, contractId, payload);
    await fetchNotes(this.cancelToken, contractId, ContractNoteSource.CONTRACT);

    this.hideAddNoteModal();
  };

  handleRemoveNote = async noteId => {
    const { removeNote, fetchNotes, contractId } = this.props;

    await removeNote(this.cancelToken, noteId, () =>
      fetchNotes(this.cancelToken, contractId, ContractNoteSource.CONTRACT),
    );
  };

  render() {
    const { t, notes } = this.props;
    const { showModal, addNoteModalVisible } = this.state;

    const [lastNote] = notes;

    return (
      <div className="margin__t25 position-relative">
        <SideModal
          shouldRender={showModal}
          toggleSideModal={this.toggleSideModal}
          position="right"
        >
          <NotesList notes={notes} handleRemoveNote={this.handleRemoveNote} />
        </SideModal>

        <CustomModal
          isOpen={addNoteModalVisible}
          title={t("Add note")}
          onRequestClose={this.hideAddNoteModal}
        >
          <AddNotesForm onAddNote={this.handleAddNote} />
        </CustomModal>
        <h2>{t("Notes")}</h2>

        {lastNote && (
          <div className="content--with-background note-card">
            <hr />
            <div className="note-card__content">{lastNote.note}</div>
            <div className="small--grey">
              {getDateWithTime(lastNote.createdAt)} {lastNote.createdBy}
            </div>
          </div>
        )}
        <div className="margin__b25 margin__t25 notes-actions">
          <button className="btn btn-dark" onClick={this.showAddNoteModal}>
            {t("Add note")}
          </button>
          <button className="btn btn-dark" onClick={this.toggleSideModal}>
            {t("Show all notes")}
          </button>
        </div>
      </div>
    );
  }
}

Notes.propTypes = {
  contractId: PropTypes.number,
  notes: PropTypes.array,
  fetchNotes: PropTypes.func,
  addNote: PropTypes.func,
  removeNote: PropTypes.func,
  t: PropTypes.func,
};

export default translate()(Notes);
