import ACTION_TYPES from "../../Enums/ActionTypes";
import PRODUCT_STATUSES from "../../Enums/ProductStatuses";

const initialProduct = {
  feePackageChange: 0,
  fees: {
    resign: {
      student: { amount: 0 },
      thirdParty: { amount: 0 },
    },
    transfer: {
      student: { amount: 0 },
      thirdParty: { amount: 0 },
    },
    packageChange: {
      student: { amount: 0 },
      thirdParty: { amount: 0 },
    },
  },
  productModules: [
    {
      name: "",
      prices: [],
      nr: 0,
      type: 0,
      lectures: [{}],
      packages: [],
      // TODO: Uncomment after add feature lecturer price
      // maxLecturerPrice: 0,
    },
  ],
};
export function fetchProductById(state = initialProduct, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT:
      return action.payload.data.data;
    case ACTION_TYPES.UPDATE_PRODUCT_VERSION:
      return action.payload.data.data;
    case ACTION_TYPES.RESET_PRODUCT:
      return initialProduct;
    case ACTION_TYPES.CLEAR_CONTRACT:
      return initialProduct;
    default:
      return state;
  }
}
export function fetchProductVersions(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_VERSIONS:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function fetchProductsList(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCTS:
      return { ...action.payload.data.data };
    case ACTION_TYPES.CLEAR_PRODUCTS_LIST:
      return {};
    default:
      return state;
  }
}
export function fetchAllActiveProductsList(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_ACTIVE_PRODUCTS:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function fetchProductsForCourses(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCTS_FOR_COURSES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function getLastVersionOfProduct(state = null, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_VERSIONS: {
      const versions = [...action.payload.data.data];
      const idArray = versions && versions.map(element => element.version);
      return versions ? Math.max(...idArray) : 1;
    }
    default:
      return state;
  }
}
export function fetchLecturesForImport(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LECTURES_FOR_IMPORT:
      return [...action.payload.data.data];
    case ACTION_TYPES.RESET_PERSON:
      return [];
    default:
      return state;
  }
}
export function fetchProductActiveVersion(state = {}, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_VERSIONS: {
      const versions = action.payload.data.data;
      let activeVersion = {};
      for (let i = 0; i < versions.length; i++) {
        if (versions[i].status === PRODUCT_STATUSES.ACTIVE) {
          activeVersion = { ...versions[i] };
          break;
        }
      }
      return { ...activeVersion };
    }
    default:
      return state;
  }
}
export function fetchProductModules(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_MODULES:
      return action.payload.data.data;
    case ACTION_TYPES.FETCH_PRODUCT_WITHDRAW_MODULES:
      return action.payload.data.data.productVersionBootcamp.productModules.map(
        module => {
          return { id: module };
        },
      );
    case ACTION_TYPES.FETCH_PRODUCT_MODULES_FOR_SLAVE:
      return action.payload.data.data;
    case ACTION_TYPES.CLEAR_PRODUCT_MODULES:
      return [];
    default:
      return state;
  }
}
export function fetchProductModulesSimple(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_MODULES_SIMPLE:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function fetchProductWithdrawModules(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_WITHDRAW_MODULES:
      return action.payload.data.data.productVersionBootcamp.productModules.map(
        module => {
          return { id: module };
        },
      );
    default:
      return state;
  }
}
export function fetchLecturesCompetencies(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LECTURES_COMPETENCIES:
      return [...action.payload.data.data];
    default:
      return state;
  }
}
export function getLectureData(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.GET_LECTURE_DATA:
      return action.payload;
    default:
      return state;
  }
}
export function fetchAllProductsFullInfo(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_PRODUCTS_FULL_INFO:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function fetchProductVersionsMeta(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_VERSIONS_META:
      return action.payload.data.data;
    default:
      return state;
  }
}
export function fetchProductPolls(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_POLLS:
      return action.payload.data.data;
    default:
      return state;
  }
}

export function productEvents(state = [], action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_EVENTS:
      return action.payload.data.data;
    default:
      return state;
  }
}
