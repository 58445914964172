import React from "react";
import PropTypes from "prop-types";
import { Authorize, permissions } from "../../../Common/Utils/Acl";

const Filters = ({
  addNewCompany,
  translations,
  expiredOnly,
  overpaidOnly,
  toggleExpiredFilter,
  toggleOverpaidFilter,
}) => {
  return (
    <div className="row">
      <Authorize
        component={
          <button
            id={"add-company-button"}
            className="btn bottom-space btn btn-dark"
            onClick={addNewCompany}
          >
            {translations("Add company")}
          </button>
        }
        allows={[permissions.COMPANY_EDIT]}
      />
      <div className={`pull-right`}>
        <div className={`pull-right checkbox margin__t0`}>
          <input
            id={`expiredOnly`}
            type={`checkbox`}
            checked={expiredOnly}
            onChange={toggleExpiredFilter}
          />
          <label className={`font-small`} htmlFor={`expiredOnly`}>
            {translations("Overdue")}
          </label>
        </div>
        <div className={`pull-right checkbox margin__t0 margin__r45`}>
          <input
            id={`overpaidOnly`}
            type={`checkbox`}
            checked={overpaidOnly}
            onChange={toggleOverpaidFilter}
          />
          <label className={`font-small`} htmlFor={`overpaidOnly`}>
            {translations("Overpaid")}
          </label>
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  addNewCompany: PropTypes.func,
  translations: PropTypes.func,
  expiredOnly: PropTypes.bool,
  overpaidOnly: PropTypes.bool,
  toggleExpiredFilter: PropTypes.func,
  toggleOverpaidFilter: PropTypes.func,
};

export default Filters;
