import React, { Component } from "react";
import {
  RenderRadio,
  RenderCheckbox,
  RenderCheckboxList,
  RenderDateTime,
  RenderSelect,
  RenderTextArea,
  RenderMultiselect,
} from "../../../Forms/forms";
import { Field, FieldArray } from "redux-form";
import NestedList from "../../../Common/Components/NestedList/NestedList";
import NestedEmployeeCompetency from "./NestedEmployeeCompetency";
import NestedProductElement from "../../Products/Components/NestedProductElement";
import NestedVersionElement from "../../Products/Components/NestedVersionElement";
import NestedModulesElement from "../../Products/Components/NestedModulesElement";
import NestedLectureElement from "../../Products/Components/NestedLectureElement";
import PersonEmployeePermissions from "./PersonEmployeePermissions";
import { Trash } from "react-feather";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import {
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";

class PersonEmployeeData extends Component {
  static propTypes = {
    values: PropTypes.object,
    change: PropTypes.func,
    competenciesIds: PropTypes.array,
    lecturesForImport: PropTypes.array,
    dictionaries: PropTypes.object,
    roles: PropTypes.array,
    isUser: PropTypes.bool,
    t: PropTypes.func,
    additionalCompetenciesIds: PropTypes.array,
    coursesSpecialists: PropTypes.array,
    onlyTeachCities: PropTypes.array,
    offices: PropTypes.array,
  };
  constructor(props) {
    super(props);
    const { values } = props;
    this.state = {
      lecturesForImport: [],
      availableType:
        values && values.availabilityTypeId ? values.availabilityTypeId : 0,
    };
    this.cantEdit = !havePermissions([permissions.PERSON_EMP_DETAILS_EDIT]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lecturesForImport !== this.state.lecturesForImport) {
      this.setState({ lecturesForImport: nextProps.lecturesForImport });
    }
  }

  onEmployeeCompetenciesListUpdate = values => {
    this.props.change("additionalCompetenciesIds", values);
  };

  uncheckItemAndChildren(item, competencies) {
    if (item.key) {
      const itemIsInValue = competencies.indexOf(item[item.key]);
      if (itemIsInValue < 0) {
        item.checked = false;
      }
    }
    if (item.childrenKey && item[item.childrenKey].length > 0) {
      item[item.childrenKey] = item[item.childrenKey].map(child => {
        return this.uncheckItemAndChildren(child, competencies);
      });
      let checked = 0;
      item[item.childrenKey].forEach(childrenItem => {
        if (childrenItem.checked) {
          checked++;
        }
      });
      if (item[item.childrenKey].length !== checked) {
        item.checked = false;
      }
    }
    return item;
  }

  removeCompetency(lecture) {
    let competencies = [...this.props.competenciesIds];
    const lectureIndex = competencies.indexOf(lecture.id);
    if (lectureIndex >= 0) {
      competencies.splice(lectureIndex, 1);
    }
    const lecturesForImport = this.state.lecturesForImport.map(item => {
      return this.uncheckItemAndChildren(item, competencies);
    });
    this.setState({ lecturesForImport }, () => {
      this.props.change("competenciesIds", competencies);
    });
  }

  availableChangeHandler = (event, value) => {
    this.setState({ availableType: value });
  };

  onCompetenciesListUpdate = (competencies, lecturesForImport) => {
    this.setState({ lecturesForImport }, () => {
      this.props.change("competenciesIds", competencies);
    });
  };

  renderChosenCompetencies = () => {
    let items = [];
    const {
      lecturesForImport,
      values: { competenciesIds },
    } = this.props;
    lecturesForImport &&
      lecturesForImport.map(product => {
        let productName = product.product.name;
        product.versions &&
          product.versions.map(version => {
            version.modules &&
              version.modules.map(module => {
                module && module.lectures
                  ? module.lectures.map(lecture => {
                      const lectureIsChecked =
                        competenciesIds && competenciesIds.indexOf(lecture.id);

                      if (lectureIsChecked >= 0) {
                        items.push(
                          <li
                            key={`${product.product.id}-${productName}-${version.version}-${lecture.id}`}
                            className="competencies__item"
                          >
                            <span>{lecture.name}</span>
                            <button
                              type="button"
                              className="btn btn-link btn-regular"
                              onClick={() => {
                                this.removeCompetency(lecture);
                              }}
                              disabled={this.cantEdit}
                            >
                              <Trash size={16} />
                            </button>

                            <p>
                              <span className="small--grey">
                                {" "}
                                {lecture.importedFrom &&
                                !!lecture.importedFrom.product
                                  ? `Import - ${productName} \\`
                                  : ""}{" "}
                                {lecture.importedFrom &&
                                !!lecture.importedFrom.version
                                  ? `Wersja ${lecture.importedFrom.version} \\`
                                  : ""}{" "}
                                {lecture.importedFrom &&
                                lecture.importedFrom.module
                                  ? `Moduł ${lecture.importedFrom.module}`
                                  : ""}{" "}
                              </span>
                            </p>
                          </li>,
                        );
                      }
                    })
                  : [];
              });
          });
      });
    return items;
  };

  render() {
    const {
      dictionaries,
      values: { isCourseSpecialist },
      roles,
      isUser,
      t,
      coursesSpecialists,
      onlyTeachCities,
      offices,
    } = this.props;
    const contractTypes = dictionaries.contractTypes.map(({ type, id }) => ({
      name: type,
      value: id,
    }));
    const coursesModes = dictionaries.coursesModes.map(({ name, id }) => ({
      name,
      value: id,
    }));
    const filteredCompetencies = dictionaries.employeeCompetencies.filter(
      competency => competency.active,
    );
    return (
      <div>
        <div className="row ">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h2>{t("Partnership")}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  options={[
                    { name: t("Yes"), value: true },
                    { name: t("No"), value: false },
                  ]}
                  required
                  name="isCourseSpecialist"
                  label={t("Course Specialist")}
                  component={RenderRadio}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  options={contractTypes}
                  required
                  name="contractTypeId"
                  label={t("Contract type")}
                  component={RenderRadio}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  label={t("Contract start date")}
                  name="contractStartDate"
                  component={RenderDateTime}
                  required="required"
                  time={false}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  label={t("Contract expiration date")}
                  name="contractEndDate"
                  component={RenderDateTime}
                  time={false}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <FieldArray
                  label={t("Mode")}
                  name="courseModeIds"
                  component={RenderCheckboxList}
                  options={coursesModes}
                  required
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  label={t("Availability")}
                  name="availabilityTypeId"
                  component={RenderSelect}
                  required="required"
                  dropdownConfig={{
                    data: this.props.dictionaries.employeeAvailability,
                    textField: "name",
                    valueField: "id",
                  }}
                  onChange={this.availableChangeHandler}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  label={t("Comments")}
                  name="availabilityComment"
                  component={RenderTextArea}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Field
                  label={t("Wants to be a mentor")}
                  name="isMentor"
                  component={RenderCheckbox}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
          </div>
          <Authorize
            component={
              <div className="col-md-6">
                <PersonEmployeePermissions roles={roles} disabled={!isUser} />
              </div>
            }
            allows={[permissions.ROLE_MANAGMENT]}
          />

          {(!isCourseSpecialist || isCourseSpecialist === "false") && (
            <div className="col-md-4 course_specialist">
              <Field
                name="courseSpecialist"
                component={RenderSelect}
                label={t("Guardian")}
                dropdownConfig={{
                  data: coursesSpecialists,
                  filter: "contains",
                  textField(person) {
                    return `${person.name} ${person.surname}`;
                  },
                  valueField: "id",
                }}
              />
            </div>
          )}
          <div className="col-md-4 multi_city">
            <Field
              label={t("City")}
              name="activeCities"
              component={RenderMultiselect}
              dropdownConfig={{
                data: onlyTeachCities,
                textField: "name",
                valueField: "id",
              }}
              shouldDisabled={this.cantEdit}
            />
          </div>
          <div className="col-md-4">
            <Field
              label={t("Office")}
              name="office"
              component={RenderSelect}
              dropdownConfig={{
                data: offices,
                textField: "address",
                valueField: "id",
              }}
              shouldDisabled={this.cantEdit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-8">
            <h2>{t("Competencies")}</h2>
            <NestedList
              dataSource={this.state.lecturesForImport}
              values={this.props.competenciesIds}
              config={[
                {
                  childrenKey: "versions",
                  decorator: NestedProductElement,
                  checkAvailable: true,
                },
                {
                  childrenKey: "modules",
                  decorator: NestedVersionElement,
                  checkAvailable: true,
                },
                {
                  childrenKey: "lectures",
                  decorator: NestedModulesElement,
                  checkAvailable: true,
                },
                {
                  decorator: NestedLectureElement,
                  checkAvailable: true,
                  key: "id",
                },
              ]}
              onListUpdate={this.onCompetenciesListUpdate}
              shouldDisabled={this.cantEdit}
            />
            <div className="row">
              <div className="col-md-12" />
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h2>{t("Additional Competencies")}</h2>
                <NestedList
                  dataSource={filteredCompetencies}
                  values={this.props.additionalCompetenciesIds}
                  config={[
                    {
                      childrenKey: "none",
                      decorator: NestedEmployeeCompetency,
                      checkAvailable: true,
                      key: "id",
                    },
                  ]}
                  onListUpdate={values => {
                    this.onEmployeeCompetenciesListUpdate(values);
                  }}
                  shouldDisabled={this.cantEdit}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-4 competencies">
            <h2>{t("Chosen Competencies")}</h2>
            <ul className="list list-unstyled competencies__list">
              {this.renderChosenCompetencies()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(PersonEmployeeData);
