import React from "react";

export default data => {
  const { name, city, street } = data;
  return (
    <div>
      <div>{name}</div>
      {city && street && (
        <div className={"small--grey"}>{`${city}, ${street}`}</div>
      )}
    </div>
  );
};
