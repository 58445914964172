import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDictionary,
  updateDictionary,
  createDictionaryItem,
} from "../Actions";
import PollTypesDictionary from "../Components/PollTypesDictionary";
import validate from "../Validators/PollsDictionaryValidator";

const FORM_NAME = `DICTIONARY_POLL_TYPES`;

let DictionaryPollTypes = props => <PollTypesDictionary {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "pollTypes");
  let {
    dictionaries: { pollTypes },
  } = state;
  return {
    values,
    initialValues: { pollTypes },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
      createDictionaryItem,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryPollTypes),
);
