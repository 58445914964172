import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDictionary, updateDictionary } from "../Actions";
import EmployeeAvailability from "../Components/EmployeeAvailability";

const FORM_NAME = "DICTIONARY_EMPLOYEE_AVAILABILITY";

let DictionaryEmployeeAvailability = props => (
  <EmployeeAvailability {...props} />
);

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state, "employeeAvailability");
  let {
    dictionaries: { employeeAvailability },
  } = state;
  return {
    values,
    initialValues: { employeeAvailability: employeeAvailability },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      updateDictionary,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DictionaryEmployeeAvailability),
);
