import React from "react";
import PropTypes from "prop-types";

const HomePageCustomIcon = ({
  icon,
  color,
  size,
  viewBox = "0 0 24 24",
  className,
  onClick,
  cursor = "pointer",
}) => {
  const iconTypes = {
    courses:
      "M 5 13.18 v 4 L 12 21 l 7 -3.82 v -4 L 12 17 l -7 -3.82 Z M 12 3 L 1 9 l 11 6 l 9 -4.91 V 17 h 2 V 9 L 12 3 Z",
    products:
      "M 21.41 11.58 l -9 -9 C 12.05 2.22 11.55 2 11 2 H 4 c -1.1 0 -2 0.9 -2 2 v 7 c 0 0.55 0.22 1.05 0.59 1.42 l 9 9 c 0.36 0.36 0.86 0.58 1.41 0.58 c 0.55 0 1.05 -0.22 1.41 -0.59 l 7 -7 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -0.55 -0.23 -1.06 -0.59 -1.42 Z M 5.5 7 C 4.67 7 4 6.33 4 5.5 S 4.67 4 5.5 4 S 7 4.67 7 5.5 S 6.33 7 5.5 7 Z",
    persons:
      "M 20 0 H 4 v 2 h 16 V 0 Z M 4 24 h 16 v -2 H 4 v 2 Z M 20 4 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 Z m -8 2.75 c 1.24 0 2.25 1.01 2.25 2.25 s -1.01 2.25 -2.25 2.25 S 9.75 10.24 9.75 9 S 10.76 6.75 12 6.75 Z M 17 17 H 7 v -1.5 c 0 -1.67 3.33 -2.5 5 -2.5 s 5 0.83 5 2.5 V 17 Z",
    reports:
      "M 4 6 H 2 v 14 c 0 1.1 0.9 2 2 2 h 14 v -2 H 4 V 6 Z m 16 -4 H 8 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z m -1 9 H 9 V 9 h 10 v 2 Z m -4 4 H 9 v -2 h 6 v 2 Z m 4 -8 H 9 V 5 h 10 v 2 Z",
    xls:
      "M458 118.2L346.5 8.4C345 6.9 342.9 6 340.7 6H135c-31.1 0-56.3 26.6-56.3 59.4v219.2H51.3v148.6h27.4v13.4c0 32.7 25.3 59.4 56.3 59.4h269.4c31.1 0 56.3-26.6 56.3-59.4V124.7c0-2.4-.9-4.8-2.7-6.5zM337.9 24.4l97 95.6h-52.8c-24.4 0-44.2-19.5-44.2-43.4V24.4zm105.8 422.2c0 22.9-17.6 41.5-39.3 41.5H135c-21.7 0-39.3-18.6-39.3-41.5v-13.4h187c36.5 0 66-33.3 66-74.3s-29.6-74.3-66-74.3h-187V65.4c0-22.9 17.6-41.5 39.3-41.5h185.9v52.7c0 33.8 27.4 61.3 61.2 61.3h61.6v308.7zm-335-124.1H123l15.2 26.9 15.4-26.9H168l-22.4 36.1 23.3 36.7h-14.6L138.4 368l-15.8 27.4h-14.4l22.9-36.7-22.4-36.2zm119.1 63.1v9.7h-46.3v-72.9h12.2v63.2h34.1zm48.6-16.4c-2.2-1.9-6-3.7-11.4-5.2-7.8-2.2-13.9-5-18.1-8.5-4.3-3.5-6.4-8.1-6.4-13.8 0-5.9 2.3-10.7 7-14.5 4.7-3.8 10.7-5.7 18-5.7 7.9 0 14.2 2.1 18.9 6.3 4.7 4.2 7 9.4 6.8 15.6l-.1.3h-11.7c0-3.8-1.3-6.9-3.8-9.2-2.6-2.3-6-3.4-10.2-3.4-4.1 0-7.3 1-9.5 2.9s-3.3 4.4-3.3 7.5c0 2.8 1.2 5.1 3.7 6.9 2.4 1.8 6.5 3.5 12.1 5.2 7.6 2.1 13.5 5 17.5 8.7 4 3.7 6 8.4 6 14.1 0 6-2.4 10.9-7.1 14.5-4.7 3.6-11 5.4-18.7 5.4-7.4 0-13.9-2-19.4-5.9-5.5-3.9-8.2-9.5-8-16.7l.1-.3h11.7c0 4.4 1.5 7.7 4.5 9.9s6.7 3.3 11 3.3 7.7-.9 10-2.7c2.4-1.8 3.6-4.3 3.6-7.4 0-2.9-1.1-5.4-3.2-7.3z",
    pdf: [
      "M458 118.2L346.5 8.4C345 6.9 342.9 6 340.7 6H135c-31.1 0-56.3 26.6-56.3 59.4v219.2H51.3v148.6h27.4v13.4c0 32.7 25.3 59.4 56.3 59.4h269.4c31.1 0 56.3-26.6 56.3-59.4V124.7c0-2.4-.9-4.8-2.7-6.5zM337.9 24.4l97 95.6h-52.8c-24.4 0-44.2-19.5-44.2-43.4V24.4zm105.8 422.2c0 22.9-17.6 41.5-39.3 41.5H135c-21.7 0-39.3-18.6-39.3-41.5v-13.4h187c36.5 0 66-33.3 66-74.3s-29.6-74.3-66-74.3h-187V65.4c0-22.9 17.6-41.5 39.3-41.5h185.9v52.7c0 33.8 27.4 61.3 61.2 61.3h61.6v308.7zm-339.8-50.2v-75h28.7c8.3 0 14.8 2.1 19.4 6.4s7 9.9 7 16.9c0 7-2.3 12.7-7 16.9-4.7 4.2-11.2 6.4-19.4 6.4h-16.2v28.4h-12.5zm70.3 0v-75H199c9.5 0 17.2 3 23.2 8.9s8.9 13.6 8.9 22.9v11.4c0 9.4-3 17-8.9 22.9-6 5.9-13.7 8.9-23.2 8.9h-24.8zm116.6-42v10h-30.2v32H248v-75h48v10h-35.5v22.9h30.3z",
      "M143.1 354.2c2.3-2.5 3.5-5.6 3.5-9.4s-1.2-7-3.5-9.5-5.8-3.8-10.5-3.8h-16.2V358h16.2c4.7-.1 8.1-1.3 10.5-3.8zM199 331.5h-12.3v55H199c6 0 10.8-2 14.3-6.1s5.3-9.3 5.3-15.7v-11.5c0-6.3-1.8-11.5-5.3-15.6s-8.3-6.1-14.3-6.1z",
    ],
    zip: [
      "M349.657,18.343A8,8,0,0,0,344,16H120A56.063,56.063,0,0,0,64,72V440a56.063,56.063,0,0,0,56,56H392a56.063,56.063,0,0,0,56-56V120a8,8,0,0,0-2.343-5.657ZM352,43.313,420.687,112H392a40.045,40.045,0,0,1-40-40ZM120,32h56V48H160a8,8,0,0,0,0,16h16V80H160a8,8,0,0,0,0,16h16v16H160a8,8,0,0,0,0,16h16v16H160a8,8,0,0,0,0,16h16v16H160a8,8,0,0,0,0,16h16v16H160a8,8,0,0,0,0,16h16v16a8,8,0,0,0-7.761,6.06l-5.906,23.622a24,24,0,1,0,43.334,0l-5.906-23.622A8,8,0,0,0,192,240V224h16a8,8,0,0,0,0-16H192V192h16a8,8,0,0,0,0-16H192V160h16a8,8,0,0,0,0-16H192V128h16a8,8,0,0,0,0-16H192V96h16a8,8,0,0,0,0-16H192V64h16a8,8,0,0,0,0-16H192V32H336V72a56.063,56.063,0,0,0,56,56h40V352H80V72A40.045,40.045,0,0,1,120,32Zm72,248a8,8,0,0,1-16,0,7.9,7.9,0,0,1,.938-3.756,8,8,0,0,0,.7-1.825L182.246,256h3.508l4.605,18.419a8,8,0,0,0,.7,1.825A7.9,7.9,0,0,1,192,280ZM392,480H120a40.045,40.045,0,0,1-40-40V368H432v72A40.045,40.045,0,0,1,392,480Z",
      "M272,400a8,8,0,0,0,0-16H240a8,8,0,0,0,0,16h8v48h-8a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16h-8V400Z",
      "M320,384H304a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V432h8a24,24,0,0,0,0-48Zm0,32h-8V400h8a8,8,0,0,1,0,16Z",
      "M208,448H188.944l26.211-52.422A8,8,0,0,0,208,384H176a8,8,0,0,0,0,16h19.056l-26.211,52.422A8,8,0,0,0,176,464h32a8,8,0,0,0,0-16Z",
    ],
    send: [
      "m36.16,8.98L27.24.19c-.12-.12-.29-.19-.46-.19H10.32c-2.49,0-4.5,2.13-4.5,4.75v17.54h-2.19v11.89h2.19v1.07c0,2.62,2.02,4.75,4.5,4.75h21.55c2.49,0,4.5-2.13,4.5-4.75V9.5c0-.19-.07-.38-.22-.52ZM26.55,1.47l7.76,7.65h-4.22c-1.95,0-3.54-1.56-3.54-3.47V1.47Zm8.46,33.78c0,1.83-1.41,3.32-3.14,3.32H10.32c-1.74,0-3.14-1.49-3.14-3.32v-1.07h14.96c2.92,0,5.28-2.66,5.28-5.94s-2.37-5.94-5.28-5.94H7.18V4.75c0-1.83,1.41-3.32,3.14-3.32h14.87v4.22c0,2.7,2.19,4.9,4.9,4.9h4.93v24.7Zm-25.56-10.57h4.12v1.07h-2.82v1.97h2.63v1.07h-2.63v3.03h-1.3v-7.14Zm7.02,7.14l-2.31-7.14h1.42l.97,3.24c.28.9.51,1.74.7,2.6h.02c.19-.85.44-1.72.73-2.58l1.04-3.26h1.39l-2.47,7.14h-1.49Zm6.49-19.99c-.15-.31-.4-.57-.72-.72l-10.13-4.9c-.37-.18-.79-.2-1.18-.07-.39.14-.7.41-.88.78-.19.39-.2.84-.04,1.25l1.73,4.33-1.73,4.33c-.15.38-.15.8.02,1.18.16.38.46.67.85.82.18.07.37.11.56.11.23,0,.47-.05.67-.15l10.13-4.9c.77-.37,1.09-1.3.72-2.06Zm-2.16,1.32l-9.25,4.48h0c-.06.03-.13.03-.19.01-.06-.02-.11-.07-.14-.13-.03-.06-.03-.14,0-.2l1.66-4.16h7.92Zm0-1.29h-7.92l-1.67-4.16c-.04-.09-.01-.2.07-.27.07-.07.18-.09.27-.04l9.25,4.48Z",
    ],
  };
  function renderPath() {
    return Array.isArray(iconTypes[icon]) ? (
      iconTypes[icon].map((type, index) => {
        return <path key={index} d={iconTypes[icon][index]} />;
      })
    ) : (
      <path d={iconTypes[icon]} />
    );
  }
  return (
    <div
      className={className ? className : ""}
      onClick={onClick ? onClick : () => {}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox={viewBox}
        style={{
          minHeight: size,
          maxHeight: size,
          minWidth: size,
          maxWidth: size,
          fill: color,
          cursor: cursor,
        }}
      >
        {renderPath()}
      </svg>
    </div>
  );
};

HomePageCustomIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  cursor: PropTypes.string,
};

export default HomePageCustomIcon;
