export default {
  SAVE_COMPANY: "SAVE_COMPANY",
  ADD_NEW_COMPANY: "ADD_NEW_COMPANY",
  FETCH_COMPANIES: "FETCH_COMPANIES",
  FETCH_COMPANY_DETAILS: "FETCH_COMPANY_DETAILS",
  REFRESH_COMPANY_DETAILS: "REFRESH_COMPANY_DETAILS",
  FETCH_COMPANY_FROM_GUS: "FETCH_COMPANY_FROM_GUS",
  CLEAR_COMPANY: "CLEAR_COMPANY",
  FETCH_CONTACTS: "FETCH_CONTACTS",
  UPDATE_CONTACTS: "UPDATE_CONTACT",
  UPDATE_INVOICE_RECEIVER: "UPDATE_INVOICE_RECEIVER",
};
