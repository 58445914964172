import React, { Component } from "react";
import { renderFieldsHelper } from "../../../Forms/forms";
import { XCircle } from "react-feather";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import getCancelToken from "../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";

class CourseSpecialistSelectWithPagination extends Component {
  static propTypes = {
    getCoursesSpecialists: PropTypes.func,
    coursesSpecialists: PropTypes.array,
    under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    label: PropTypes.string,
    onClearCallback: PropTypes.func,
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func,
    input: PropTypes.object,
    multiple: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.PAGE_NO = 1;
    this.PAGE_AMOUNT = 10;
    this.state = {
      page_no: 1,
      selectedCourseSpecialist: 0,
      selected: [],
    };
    const { getCoursesSpecialists } = this.props;
    getCoursesSpecialists(
      this.cancelToken,
      {
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
      },
      () => {
        if (this.props.input.value) {
          this.setDefaultValue(this.props.input.value);
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    const { input: prevInput } = prevProps;
    if (prevInput.value !== input.value) {
      this.setDefaultValue(input.value);
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  setDefaultValue = value => {
    if (!value) {
      this.setState({
        selected: [],
        selectedCourseSpecialist: null,
      });
      return;
    }
    if (_.has(this.props, "multiple") && this.props.multiple) {
      this.selectMultipleCourseSpecialists(value);
    } else {
      this.selectSingleCourseSpecialist(value);
    }
  };

  selectMultipleCourseSpecialists = selectedCourseSpecialists => {
    const { coursesSpecialists } = this.props;
    this.setState({
      selected: coursesSpecialists.filter(
        courseSpecialist =>
          selectedCourseSpecialists.indexOf(courseSpecialist.id) >= 0,
      ),
    });
  };

  selectSingleCourseSpecialist = value => {
    if (
      value &&
      !_.isEqual(this.state.selectedCourseSpecialist, value) &&
      this.props.coursesSpecialists &&
      this.props.coursesSpecialists.length > 0
    ) {
      const courseSpecialist = this.props.coursesSpecialists.find(person => {
        return person.id === value;
      });
      if (courseSpecialist) {
        this.setState({
          selected: [courseSpecialist],
          selectedCourseSpecialist: value,
        });
      } else {
        this.props.getCoursesSpecialists(
          this.cancelToken,
          {
            pageNo: this.state.page_no,
            pageAmount: this.PAGE_AMOUNT,
          },
          () => {
            if (this.props.input.value) {
              this.setDefaultValue(this.props.input.value);
            }
          },
        );
      }
    }
  };

  onSearchHandler = query => {
    this.setState({ query });
    const { getCoursesSpecialists } = this.props;
    getCoursesSpecialists(this.cancelToken, {
      pageNo: this.state.page_no,
      pageAmount: this.PAGE_AMOUNT,
      search: query,
    });
  };

  onInputChangeHandler = query => {
    const { getCoursesSpecialists } = this.props;
    this.setState({ selected: [] }, () => {
      query.length === 0 &&
        getCoursesSpecialists(this.cancelToken, {
          pageNo: this.state.page_no,
          pageAmount: this.PAGE_AMOUNT,
          search: query,
        });
    });
  };

  onPaginateHandler = () => {
    const { getCoursesSpecialists } = this.props;
    this.setState({ page_no: this.state.page_no + 1 }, () => {
      getCoursesSpecialists(this.cancelToken, {
        pageNo: this.state.page_no,
        pageAmount: this.PAGE_AMOUNT,
      });
    });
  };

  render() {
    const { classes, displayError, shouldDisabled } = renderFieldsHelper(
      this.props,
    );
    const isMultiple =
      this.props.multiple === undefined ? false : this.props.multiple;
    const { under, label, onClearCallback, input } = this.props;
    return (
      <div
        className={`courseSpecialistsSelect form-group  ${classes} ${
          under ? under.containerClassName : "row"
        }`}
      >
        <div>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                id={"courseSpecialistsSelect"}
                isLoading={false}
                onSearch={this.onSearchHandler}
                onInputChange={this.onInputChangeHandler}
                selected={this.state.selected}
                onChange={selected => {
                  if (!this.props.onChangeHandler) {
                    return;
                  }
                  if (isMultiple) {
                    return this.props.onChangeHandler(
                      selected,
                      null,
                      input.name,
                    );
                  }
                  if (selected[0]) {
                    return this.props.onChangeHandler(
                      selected[0],
                      null,
                      input.name,
                    );
                  }
                }}
                options={this.props.coursesSpecialists.map(courseSpecialist => {
                  courseSpecialist.fullName = `${courseSpecialist.name} ${courseSpecialist.surname}`;
                  return courseSpecialist;
                })}
                labelKey={person => `${person.fullName}`}
                renderMenuItemChildren={person => (
                  <span>{person.fullName}</span>
                )}
                filterBy={(option, { text }) => {
                  const search = `${option.fullName}`;
                  return search.toLowerCase().indexOf(text.toLowerCase()) > -1;
                }}
                minLength={0}
                maxResults={9}
                paginate={true}
                placeholder={this.props.placeholder}
                paginationText="Pokaż więcej wyników"
                onPaginate={this.onPaginateHandler}
                disabled={shouldDisabled}
                multiple={isMultiple}
              />
              <span aria-hidden="true" className="rw-i rw-i-caret-down" />
              {onClearCallback && !shouldDisabled && (
                <div onClick={onClearCallback} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
            {displayError}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseSpecialistSelectWithPagination;
