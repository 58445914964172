import React from "react";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { FieldProps } from "formik";
import { get } from "lodash";

type RenderErrorProps = InjectedTranslateProps & {
  error:
    | {
        key: string;
        values: string;
      }
    | string;
};
export const RenderError: React.FunctionComponent<RenderErrorProps> = ({
  t,
  error,
}) => {
  const interpolation = typeof error === "object";
  return interpolation ? (
    <span className="help-block">{t(error.key, error.values)}</span>
  ) : (
    <span className="help-block">{t(error)}</span>
  );
};

export const renderFieldsHelper = (
  props: FieldProps & { required?: boolean },
): {
  touched: boolean;
  error: string;
  classes: string;
  displayError: boolean;
} => {
  const { name } = props.field;
  const {
    touched: formTouched,
    errors: formErrors,
    isSubmitting: submitting,
  } = props.form;

  const error = get(formErrors, name);
  const touched = get(formTouched, name);

  const classes = `${touched && error ? "has-error" : ""} ${
    props.required ? "required" : ""
  } ${submitting ? "disabled" : ""}`;

  const displayError = touched && !!error;

  return {
    touched,
    error,
    classes,
    displayError,
  };
};
