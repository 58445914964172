import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { XCircle } from "react-feather/dist/index";

class SelectWithPagingForFilters extends Component {
  state = {
    searchQuery: "",
    isLoading: false,
    selected: [],
  };

  getRequestConfig = (search = null, counter = null) => {
    const START_PAGE = 1;

    const config = _.get(this.props, "additionalRequestConfig", {});

    if (counter) {
      config.pageNo = START_PAGE;
      config.pageAmount = counter;
    }

    if (search) {
      config.search = search;
    }
    return config;
  };

  handleSearch = query => {
    const COUNTER_FOR_PAGING = 11;
    const { fetchData } = this.props;
    this.setState({ isLoading: true, searchQuery: query }, () => {
      const { searchQuery } = this.state;
      fetchData(
        this.cancelToken,
        this.getRequestConfig(searchQuery, COUNTER_FOR_PAGING),
        () => this.setState({ isLoading: false }),
      );
    });
  };

  onShowMoreResults = (event, counter) => {
    const { fetchData } = this.props;
    const counterForPaging = counter + 1;

    this.setState({ isLoading: true }, () => {
      const { searchQuery } = this.state;
      fetchData(
        this.cancelToken,
        this.getRequestConfig(searchQuery, counterForPaging),
        () => this.setState({ isLoading: false }),
      );
    });
  };

  onChangeSelect = item => {
    const { onChangeSelect } = this.props;
    this.setState({ selected: item });
    const [currentItem] = item;
    onChangeSelect(currentItem);
  };

  onClear = () => {
    const { onClear } = this.props;
    this.setState({ selected: [] }, onClear);
  };

  render() {
    const {
      t,
      under,
      label,
      dataItems,
      onClear,
      customItemRender,
      placeholder,
      labelKey,
      selectId,
      containerId,
    } = this.props;
    const { isLoading, selected } = this.state;
    const PER_PAGE = 10;
    const MIN_STRING_LENGTH_FOR_SEARCH = 0;

    return (
      <div
        id={containerId}
        className={`form-group ${under ? under.containerClassName : "row"}`}
      >
        <div className={`row`}>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                isLoading={isLoading}
                options={dataItems}
                selected={selected}
                id={selectId}
                multiple={false}
                maxResults={PER_PAGE}
                labelKey={labelKey}
                minLength={MIN_STRING_LENGTH_FOR_SEARCH}
                onSearch={this.handleSearch}
                placeholder={placeholder ? placeholder : t("Select the item")}
                onChange={this.onChangeSelect}
                paginate={true}
                promptText={t("Type to search...")}
                searchText={t("Searching...")}
                paginationText={t("Show more")}
                emptyLabel={t("Items not found")}
                useCache={false}
                onPaginate={this.onShowMoreResults}
                renderMenuItemChildren={
                  !!customItemRender ? customItemRender : undefined
                }
              />

              {onClear && (
                <div onClick={this.onClear} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectWithPagingForFilters.propTypes = {
  t: PropTypes.func,
  fetchData: PropTypes.func,
  onClear: PropTypes.func,
  customItemRender: PropTypes.func,
  onChangeSelect: PropTypes.func,
  dataItems: PropTypes.array,
  under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  additionalRequestConfig: PropTypes.object,
  labelKey: PropTypes.string,
  selectId: PropTypes.string,
  containerId: PropTypes.string,
};

export default translate()(SelectWithPagingForFilters);
