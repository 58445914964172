export default (person = { name: "", surname: "" }) => {
  const formattedSurname = person.surname
    .replace(/-/g, " ")
    .replace(/ +(?= )/g, "")
    .split(" ");

  return (
    person.name[0] +
    formattedSurname[0][0] +
    (formattedSurname[1] ? formattedSurname[1][0] : "")
  );
};
