import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { translate } from "react-i18next";
import dateHelper from "../../../../Helpers/DateHelper";
import PollGrade from "../../../../Common/Components/PollGrade/PollGrade";
import { Info } from "react-feather";

class CurrentPollResultsTable extends Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const { data, t } = this.props;
    return (
      <Fragment>
        <ReactTooltip id="module-name" />
        <table className="course-poll__table">
          <CurrentPollResultHead
            theads={data[0] && data[0].topicsGrade.map(topic => topic.name)}
            t={t}
          />
          <CurrentPollResultBody data={data} />
        </table>
      </Fragment>
    );
  }
}

const CurrentPollResultBody = ({ data }) => {
  return (
    data && (
      <tbody>
        {data.map((element, index) => {
          const { allPolls, filledPolls } = element;
          return (
            <tr
              key={index}
              className={
                index % 2 !== 0
                  ? "course-poll__row--highlight course-poll__row"
                  : "course-poll__row"
              }
            >
              <td className="course-poll__row__signature">
                <Link to={`/courses/${element.courseId}/polls`}>
                  {element.signature}
                </Link>
              </td>
              <td>{dateHelper(element.date).print()}</td>
              <td>
                <div data-tip={element.type} data-for="module-name">
                  {element.type}
                </div>
              </td>
              {element.topicsGrade.map((topic, topicIndex) => (
                <PollGrade key={topicIndex} data={topic.grade} />
              ))}
              <td>
                <strong>
                  {Math.floor((filledPolls / allPolls) * 100)}%{" "}
                  <small>
                    ({filledPolls}/{allPolls})
                  </small>
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    )
  );
};

const CurrentPollResultHead = ({ theads, t }) => {
  return (
    <thead>
      <tr className="course-poll__row">
        <th>{t("signature")}</th>
        <th>{t("avbl date")}</th>
        <th>{t("Type")}</th>
        {theads && theads.map(thead => <th key={thead}>{t(thead)}</th>)}
        <th>
          {t("filled")}
          <span
            data-tip={t(
              "Shows the number of completed surveys/per number of surveys assigned to the module (calculated based on product definition and number of participants).",
            )}
            data-for="module-name"
          >
            &nbsp;
            <Info size={16} />
          </span>
        </th>
      </tr>
    </thead>
  );
};

CurrentPollResultHead.propTypes = {
  theads: PropTypes.array,
  t: PropTypes.func,
};

CurrentPollResultBody.propTypes = {
  data: PropTypes.array,
};

CurrentPollResultsTable.propTypes = {
  data: PropTypes.array,
  t: PropTypes.func,
};

export default translate()(CurrentPollResultsTable);
