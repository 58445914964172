import React from "react";

import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NewCompanyForm from "../Components/NewCompanyForm";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { TenantValidator } from "../../../Common/Utils/Tenant";
import {
  addNewCompany,
  clearCompany,
  fetchCompanies,
  fetchCompanyFromGus,
} from "../Actions/CompaniesActions";

const FORM_NAME = "NEW_COMPANY_FORM";
const NewCompany = props => <NewCompanyForm {...props} />;

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(
    state,
    "name",
    "taxNr",
    "isTaxNrEUActive",
    "addressStreet",
    "addressZipCode",
    "addressCity",
    "addressCountry",
    "regonNr",
    "taxRate",
  );

  const validationErrors = state.form[FORM_NAME].syncErrors;
  const {
    companies: { companyFromGus },
    dictionaries,
    __metadata: {
      contract: { taxRates },
    },
  } = state;
  return {
    values,
    dictionaries,
    validationErrors,
    taxRates,
    initialValues: companyFromGus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      addNewCompany,
      fetchCompanies,
      fetchCompanyFromGus,
      clearCompany,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  validate: TenantValidator.forms.CompanyForm,
  initialValues: {
    name: "",
    isTaxNrEUActive: false,
    regonNr: "",
  },
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewCompany),
);
