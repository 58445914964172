import React, { Component } from "react";
import { translate } from "react-i18next";
import LectureForm from "./LectureForm";
import { EditLectureModalProps, LectureFormValues } from "../Types/EditLecture";

class EditLectureModal extends Component<EditLectureModalProps> {
  onSettleModule = (): void => {
    const { settleModule, unitId, refreshCurrentCourse } = this.props;
    settleModule(unitId, refreshCurrentCourse);
  };

  onSubmit = (values: LectureFormValues): void => {
    const { editUnit, onClose, unitId, refreshCurrentCourse } = this.props;
    const { settled } = values;
    editUnit(
      {
        ...values,
        settled: settled,
        lecturer: values.lecturer && values.lecturer.personId,
        room: values.room && values.room.id,
        students: Number.parseInt(values.students),
      },
      unitId,
      refreshCurrentCourse,
    );

    onClose();
  };

  render() {
    const {
      t,
      freeLecturesAndRooms,
      isLastUnit,
      lecture,
      disabled,
    } = this.props;

    return (
      <div>
        <LectureForm
          t={t}
          lecture={lecture}
          disabled={disabled}
          freeLecturesAndRooms={freeLecturesAndRooms}
          isLastUnit={isLastUnit}
          handleSettleModule={this.onSettleModule}
          handleSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export default translate()(EditLectureModal);
