import React from "react";
import { Formik, FormikProps, Field, Form } from "formik";
import { RenderSelect } from "../../../Forms/InputComponents/Select";
import { RenderDateTime } from "../../../Forms/InputComponents/DateTime";
import { RenderCheckbox } from "../../../Forms/InputComponents/Checkbox";
import { RenderTimePicker } from "../../../Forms/InputComponents/TimePicker";
import LecturerSelect from "../../../Forms/InputComponents/LecturerSelect";
import validate from "../Validators/EventEditValidator";
import { EventFormProps, EventFormValues } from "../Types/EditEventForm";

const EventForm: React.FunctionComponent<EventFormProps> = ({
  rooms,
  event,
  lecturers,
  t,
  handleSubmit,
  handleRemoveEvent,
  fetchingLecturers,
  onShowMoreLecturers,
  fetchData,
}): React.ReactElement => {
  const initialValues: EventFormValues = {
    date: event.date,
    startTime: event.startTime,
    roomReq: event.roomRequired,
    settled: event.settled,
    room: event.room,
    lecturer: event.lecturer ? [event.lecturer] : [],
  };

  const handleClearSelectedRoom = (
    props: FormikProps<EventFormValues>,
  ): void => {
    const { setFieldValue } = props;
    setFieldValue("room", null);
  };

  return (
    <div id="event-form">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Form>
            <div className="col-xs-12">
              <Field
                component={LecturerSelect}
                label={t("Lecturer")}
                name="lecturer"
                fetchData={fetchData}
                lecturers={lecturers}
                placeholder={t("Select")}
                onShowMoreResults={onShowMoreLecturers}
                isLoading={fetchingLecturers}
              />
            </div>
            <Field
              name="room"
              label={t("Room")}
              component={RenderSelect}
              className="courses-room-select"
              dropdownConfig={{
                data: rooms,
                textField: "name",
                valueField: "id",
              }}
              onClearCallback={() => handleClearSelectedRoom(formProps)}
            />
            <Field
              name="date"
              label={t("Date")}
              component={RenderDateTime}
              time={false}
            />
            <div className="form-group row">
              <div className="col-sm-4 col-md-3">
                <label>{t("Start time")}</label>
              </div>
              <div className="col-sm-8 col-md-9">
                <Field name="startTime" component={RenderTimePicker} />
              </div>
            </div>
            <Field
              name="settled"
              id="settled-edit-event-form-input"
              label={t("Settlement")}
              component={RenderCheckbox}
            />
            <Field
              name="roomReq"
              id="roomReq-edit-event-form-input"
              component={RenderCheckbox}
              label={t("Room required")}
            />
            <div className="text-center">
              <button type="submit" className="btn 2margin__r15 btn-dark">
                {t("Save")}
              </button>

              <button
                className="btn btn-done"
                onClick={() => handleRemoveEvent(event.id)}
              >
                {t("Remove")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EventForm;
