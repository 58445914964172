import { Features } from "../../../Common/Utils/Tenant";

export default fields => {
  const MIN_DESCRIPTION_LENGTH = 10;

  const { description, amountGross, currency, correction } = fields;
  let errors = {};

  if (!description || description.length < MIN_DESCRIPTION_LENGTH) {
    errors.description =
      "Enter description of the refund, not less than 10 characters";
  }

  if (!currency) {
    errors.currency = "Choose a currency";
  }

  if (Features.invoices && !correction) {
    errors.correction = "Correction number is required";
  }

  if (!amountGross) {
    errors.amountGross = "Amount is required";
  }

  return errors;
};
