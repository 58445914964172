import React, { Component } from "react";
import Loader from "../../../Common/Components/Loader/Loader";
import { RenderText, RenderSelect } from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import PropTypes from "prop-types";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import ACTION_TYPES from "../../../Enums/ActionTypes";

class AdditionalCompetenciesDictionary extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      employeeCompetencies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
        }).isRequired,
      ).isRequired,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        active: PropTypes.bool.isRequired,
      }).isRequired,
    ),
    fetchDictionary: PropTypes.func.isRequired,
    updateDictionary: PropTypes.func.isRequired,
    createDictionaryItem: PropTypes.func.isRequired,
    initialize: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    change: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchDictionary } = this.props;
    this.formActions = {
      SAVE: "SAVE",
      CREATE: "CREATE",
    };
    this.state = {
      editing: [],
      competenciesLoading: true,
    };
    const { FETCH_EMPLOYEE_COMPETENCIES } = ACTION_TYPES;

    fetchDictionary(
      this.cancelToken,
      {
        path: "/employee/competencies",
        actionType: FETCH_EMPLOYEE_COMPETENCIES,
      },
      () => {
        this.setState({ competenciesLoading: false });
      },
    );
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, values } = this.props;
    if (
      initialValues &&
      initialValues.employeeCompetencies &&
      prevProps &&
      prevProps.initialValues &&
      initialValues.employeeCompetencies !==
        prevProps.initialValues.employeeCompetencies
    ) {
      initialize(initialValues);
    } else if (
      prevProps &&
      prevProps.values &&
      prevProps.values.employeeCompetencies &&
      values &&
      values.employeeCompetencies &&
      values.employeeCompetencies !== prevProps.values.employeeCompetencies
    ) {
      initialize(values);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;
    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }
  onSubmit = values => {
    const competenciesValues = values.employeeCompetencies.find(
      competency => competency.id === this.state.rowId,
    );
    const {
      createDictionaryItem,
      updateDictionary,
      fetchDictionary,
    } = this.props;
    const {
      FETCH_EMPLOYEE_COMPETENCIES,
      CREATE_EMPLOYEE_COMPETENCY,
    } = ACTION_TYPES;
    switch (this.state.action) {
      case this.formActions.SAVE:
        updateDictionary(
          `/employee/competencies/${competenciesValues.id}`,
          this.prepareValues(competenciesValues),
          () => {
            fetchDictionary(
              this.cancelToken,
              {
                path: "/employee/competencies",
                actionType: FETCH_EMPLOYEE_COMPETENCIES,
              },
              () => {
                const { editing } = this.state;
                const rowIndex = editing.indexOf(competenciesValues.id);
                rowIndex >= 0 && editing.splice(rowIndex, 1);
                this.setState({
                  competenciesLoading: false,
                  editing,
                });
              },
            );
          },
        );
        break;
      case this.formActions.CREATE:
        createDictionaryItem(
          "/employee/competencies",
          CREATE_EMPLOYEE_COMPETENCY,
          this.prepareValues(competenciesValues),
          () => {
            fetchDictionary(
              this.cancelToken,
              {
                path: "/employee/competencies",
                actionType: FETCH_EMPLOYEE_COMPETENCIES,
              },
              () => {
                this.setState({
                  competenciesLoading: false,
                });
              },
            );
          },
        );
        break;
    }
  };
  prepareValues(values) {
    const { name, active } = values;
    return {
      name,
      active,
    };
  }
  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    this.setState(
      {
        action,
        rowId,
      },
      () => {
        const submitter = handleSubmit(this.onSubmit);
        submitter();
      },
    );
  };
  renderStatusField = row => {
    const { values, t } = this.props;
    const city = row.original;
    return (
      <div className="editField">
        {!values[row.index].id || this.state.editing.indexOf(city.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              name={`employeeCompetencies[${row.index}][${row.column.id}]`}
              component={RenderSelect}
              dropdownConfig={{
                data: [
                  { value: true, name: t("ActiveRoom") },
                  { value: false, name: t("InactiveRoom") },
                ],
                textField: "name",
                valueField: "value",
              }}
            />
          </div>
        ) : (
          values[row.index].id && (
            <EditDictionaryField
              onClick={() => this.enableEditMode(row)}
              text={
                city && city.active === true
                  ? t("ActiveRoom")
                  : t("InactiveRoom")
              }
            />
          )
        )}
      </div>
    );
  };
  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);

    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`employeeCompetencies[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  addCompetency = () => {
    const { change, values } = this.props;
    const competencies = [
      {
        name: null,
        active: false,
      },
      ...values,
    ];
    change("employeeCompetencies", competencies);
  };

  render() {
    const { values, t } = this.props;
    const { competenciesLoading } = this.state;
    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={
                  values[values.length - 1] && !values[values.length - 1].id
                }
                className="btn bottom-space btn btn-dark"
                onClick={() => {
                  this.addCompetency();
                }}
              >
                {t("Add competency")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={competenciesLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("NameItem"),
                accessor: "name",
                Cell: this.renderEditField,
              },
              {
                Header: t("Status"),
                accessor: "active",
                Cell: this.renderStatusField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(this.formActions.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(this.formActions.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(AdditionalCompetenciesDictionary);
