import React, { Component } from "react";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import { RenderSelect, RenderCheckbox } from "../../../../Forms/forms";
import _ from "lodash";
import getCancelToken from "../../../../Helpers/GetCancelToken";
import PropTypes from "prop-types";
import Paginator from "../../../../Common/Components/Paginator/Paginator";
import Loader from "../../../../Common/Components/Loader/Loader";
import CustomIcon from "../../../../Common/Components/CustomIcon/CustomIcon";
import ACTION_TYPES from "../../../../Enums/ActionTypes";
import CurrentPollResultsTable from "./CurrentPollResultsTable";
import MentorsSelectWithPagination from "../../../../Common/Components/MentorsSelectWithPagination/MentorsSelectWithPagination";
import CourseSpecialistSelectWithPagination from "../../../../Common/Components/CourseSpecialistSelectWithPagination/CourseSpecialistSelectWithPagination";
import CurrentPollResultsModel from "../../Models/CurrentPollResultsModel";

class CurrentPollResultsForm extends Component {
  static propTypes = {
    change: PropTypes.func,
    values: PropTypes.object,
    t: PropTypes.func,
    fetchMentors: PropTypes.func,
    getCoursesSpecialists: PropTypes.func,
    mentorsList: PropTypes.array,
    coursesSpecialists: PropTypes.array,
    fetchDictionary: PropTypes.func,
    dictionaries: PropTypes.object,
    fetchCurrentPollResults: PropTypes.func,
    currentPollResults: PropTypes.object,
    downloadCurrentPollResults: PropTypes.func,
    fetchAllProductsFullInfo: PropTypes.func,
    allProductsFullInfo: PropTypes.array,
  };
  constructor(props) {
    super(props);
    const {
      fetchMentors,
      getCoursesSpecialists,
      fetchDictionary,
      fetchCurrentPollResults,
      fetchAllProductsFullInfo,
    } = props;

    this.state = {
      currentPage: 1,
      pageAmount: 20,
      isLoading: true,
      downloadDisabled: false,
    };
    this.cancelToken = getCancelToken();

    fetchAllProductsFullInfo(this.cancelToken);
    fetchMentors(this.cancelToken);
    getCoursesSpecialists(this.cancelToken);
    fetchDictionary(this.cancelToken, {
      path: "/course/mode",
      actionType: ACTION_TYPES.FETCH_COURSES_MODES,
    });
    fetchCurrentPollResults(
      this.cancelToken,
      {
        lastWeek: true,
      },
      () => {
        this.setState({ isLoading: false });
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.values, this.props.values)) {
      this.changePage(1);
    }
  }

  changePage = page => {
    const currentPollResultsModel = new CurrentPollResultsModel({
      ...this.props.values,
      page,
    });
    this.setState(
      {
        isLoading: true,
        currentPage: page,
      },
      () => {
        this.props.fetchCurrentPollResults(
          this.cancelToken,
          currentPollResultsModel.removeNullableKeys(),
          () => {
            this.setState({ isLoading: false });
          },
        );
      },
    );
  };

  downloadReport = () => {
    const currentPollResultsModel = new CurrentPollResultsModel(
      this.props.values,
    );
    this.setState(
      {
        downloadDisabled: true,
      },
      () => {
        this.props.downloadCurrentPollResults(
          currentPollResultsModel.removeNullableKeys(),
          () => {
            this.setState({ downloadDisabled: false });
          },
        );
      },
    );
  };

  render() {
    const {
      t,
      change,
      allProductsFullInfo,
      mentorsList,
      coursesSpecialists,
      dictionaries: { coursesModes },
      currentPollResults,
    } = this.props;
    const { isLoading, pageAmount } = this.state;
    return (
      <div id={`currentPoolResultsForm`}>
        <div className="row col-md-12 vertical-center">
          <Field
            component={RenderSelect}
            name="productId"
            dropdownConfig={{
              data: allProductsFullInfo.map(product => ({
                ...product,
                productId: product.id,
                productRenderName: `${product.shortcut} ${product.name}`,
              })),
              textField: "productRenderName",
              valueField: "productId",
              placeholder: t("Products"),
              filter: "contains",
            }}
            under={{
              containerClassName: "col-md-3 col-xs-3 padding__l0",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("productId", null)}
          />
          <Field
            component={CourseSpecialistSelectWithPagination}
            name="csId"
            onChangeHandler={value => {
              this.props.change("csId", value.id);
            }}
            placeholder={t("Course Specialist")}
            getCoursesSpecialists={this.props.getCoursesSpecialists}
            coursesSpecialists={coursesSpecialists}
            under={{
              containerClassName: "col-md-3 col-xs-3 padding__l0",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("csId", null)}
          />
          <Field
            component={MentorsSelectWithPagination}
            name="mentorId"
            onChangeHandler={value => {
              this.props.change("mentorId", value.id);
            }}
            placeholder={t("Mentors")}
            getMentorsLists={this.props.fetchMentors}
            mentors={mentorsList}
            under={{
              containerClassName: "col-md-3 col-xs-3 padding__l0",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("mentorId", null)}
          />
          <Field
            component={RenderSelect}
            name="courseModeId"
            dropdownConfig={{
              data: coursesModes,
              textField: "name",
              valueField: "id",
              placeholder: t("Mode"),
            }}
            under={{
              containerClassName: "col-md-3 col-xs-3 padding__l0",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("courseModeId", null)}
          />
          <div className="col-md-4">
            <Field
              name="lastWeek"
              labelForCheckbox={t("Show only last week")}
              customLabel=" "
              component={RenderCheckbox}
            />
          </div>
          <div className="col-md-2" id="currentPollResultsDownload">
            <button
              className="btn btn-default btn-link"
              onClick={this.downloadReport}
              disabled={this.state.downloadDisabled}
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
              />
            </button>
          </div>
        </div>
        <div className="row col-md-12 current-poll-result">
          {isLoading ? (
            <Loader />
          ) : (
            <CurrentPollResultsTable data={currentPollResults.items} />
          )}
        </div>
        <div className="row col-md-12">
          {currentPollResults.counter >= pageAmount && (
            <Paginator
              onClick={this.changePage}
              pages={Math.ceil(currentPollResults.counter / pageAmount)}
              currentPage={this.state.currentPage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default translate()(CurrentPollResultsForm);
