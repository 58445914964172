import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CurrentPollResultsForm from "../Components/CurrentPollResultsForm/CurrentPollResultsForm";
import { fetchAllProductsFullInfo } from "../../Products/Actions";
import { fetchDictionary } from "../../Dictionaries/Actions";
import {
  fetchCurrentPollResults,
  downloadCurrentPollResults,
} from "../Actions";
import { fetchMentors, getCoursesSpecialists } from "../../Persons/Actions";

import { formValueSelector, reduxForm } from "redux-form";

const FORM_NAME = "CURRENT_POLL_RESULTS";

const CurrentPollResults = props => <CurrentPollResultsForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    groupDetailsSortTypes,
    groupDetailsData,
    mentorsList,
    coursesSpecialists,
    dictionaries,
    currentPollResults,
    allProductsFullInfo,
  } = state;
  const values = selector(
    state,
    "modeId",
    "csId",
    "mentorId",
    "courseModeId",
    "lastWeek",
    "productId",
  );
  return {
    values,
    groupDetailsSortTypes,
    groupDetailsData,
    mentorsList,
    coursesSpecialists,
    dictionaries,
    currentPollResults,
    allProductsFullInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMentors,
      getCoursesSpecialists,
      fetchDictionary,
      fetchCurrentPollResults,
      downloadCurrentPollResults,
      fetchAllProductsFullInfo,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    lastWeek: true,
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CurrentPollResults),
);
