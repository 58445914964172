import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function fetchBalance(cancelToken, balanceId, expired, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/balance/${balanceId}`,
    actionType: ACTION_TYPES.FETCH_BALANCE,
    callback,
    cancelToken,
    params: {
      expired,
    },
  });
}

export function fetchInvoiceAmount(cancelToken, balanceId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/invoice/amount/${balanceId}`,
    actionType: ACTION_TYPES.FETCH_INVOICE_AMOUNT,
    callback,
    cancelToken,
  });
}

export function addRefund(values, callback = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/refunds`,
    actionType: ACTION_TYPES.ADD_REFUND,
    values,
    callback,
  });
}

export function fetchBalanceSettings(cancelToken, balanceId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/balance/${balanceId}/settings`,
    actionType: ACTION_TYPES.FETCH_BALANCE_SETTINGS,
    callback,
    cancelToken,
  });
}

export function saveBalanceSettings(values, balanceId, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/balance/${balanceId}/settings`,
    actionType: ACTION_TYPES.SAVE_BALANCE_SETTINGS,
    values,
    callback,
  });
}

export function updateIncomeAutoInvoice(values, incomeId, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/incomes/auto-invoice/${incomeId}`,
    actionType: ACTION_TYPES.UPDATE_INCOME_AUTOINVOICE,
    values,
    callback,
  });
}

export function updateIncomeComment(values, incomeId, callback = null) {
  return Client.actionPUT({
    path: `${restApiConfig.API_URL}/incomes/comment/${incomeId}`,
    actionType: ACTION_TYPES.UPDATE_INCOME_COMMENT,
    values,
    callback,
  });
}
