/*
 * Function is checking if given finish reason match to comparing finish reason name
 * compare is doing by dictionary contract finish reasons
 * @param integer reasonId
 * @param string reasonName
 */
import { store } from "../Configs/Root";

export default (reasonId, reasonName) => {
  const finishReasons = store.getState().dictionaries.contractFinishReasons;
  const reason =
    finishReasons && reasonName
      ? finishReasons.find(element => {
          return element.code.toLowerCase() === reasonName.toLowerCase();
        })
      : null;
  if (typeof reasonId === "number") {
    return reason && reason.id === reasonId ? true : false;
  } else if (reasonId && typeof reasonId === "object") {
    return reason && reason.id === reasonId.id ? true : false;
  }
};
