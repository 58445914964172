import React from "react";

export const not_sent_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#727272" }} cx="14" cy="14" r="14" />
    <line
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeMiterlimit: "10",
        strokeWidth: "3px",
      }}
      x1="8.29"
      y1="14"
      x2="20.57"
      y2="14"
    />
  </svg>
);

export const ready_to_send_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#2196f3" }} cx="14" cy="14" r="14" />
    <path
      style={{ fill: "#fff" }}
      d="m22.83,9.65c.4-.4.4-1.04,0-1.43h0c-.4-.4-1.04-.4-1.43,0l-9.43,9.43-5.37-5.37c-.4-.4-1.04-.4-1.43,0-.4.4-.4,1.04,0,1.43l6.09,6.09c.2.2.46.3.72.3s.52-.1.72-.3"
    />
  </svg>
);

export const sent_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#008314" }} cx="14" cy="14" r="14" />
    <path
      style={{ fill: "#fff" }}
      d="m22.83,8.21c-.4-.4-1.04-.4-1.43,0l-9.43,9.43-5.37-5.37c-.4-.4-1.04-.4-1.43,0-.4.4-.4,1.04,0,1.43l6.09,6.09c.2.2.46.3.72.3s.52-.1.72-.3l10.14-10.14c.4-.4.4-1.04,0-1.43h0Z"
    />
  </svg>
);

export const in_review_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#ef7300" }} cx="14" cy="14" r="14" />
    <polyline
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "3px",
      }}
      points="20.57 15.05 13.48 15.05 13.48 8.97"
    />
  </svg>
);

export const in_send_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#009892" }} cx="14" cy="14" r="14" />
    <path
      style={{
        fill: "#fff",
        stroke: "#fff",
        strokeMiterlimit: 10,
        strokeWidth: ".75px",
      }}
      d="m22.92,13.28c-.16-.34-.43-.61-.77-.77l-12.48-6.04c-.82-.4-1.81-.05-2.21.77-.2.42-.22.9-.05,1.34l2.17,5.43-2.17,5.43c-.34.85.08,1.82.93,2.15.19.08.4.12.6.12.25,0,.5-.06.72-.17l12.48-6.04c.82-.4,1.17-1.39.77-2.21Zm-13.73,7.26c-.27.13-.6.02-.74-.26-.07-.14-.07-.3-.02-.45l2.11-5.28h11.01l-12.37,5.99Zm1.36-7.09l-2.11-5.28c-.09-.21-.03-.45.14-.61.16-.16.41-.2.62-.09l12.37,5.98h-11.01Z"
    />
  </svg>
);
export const in_send_retry_icon = (
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 28 28">
    <circle style={{ fill: "#009892" }} cx="14" cy="14" r="14" />
    <path
      style={{ fill: "#fff", strokeWidth: ".75px" }}
      d="m22.92,13.28c-.16-.34-.43-.61-.77-.77l-12.48-6.04c-.82-.4-1.81-.05-2.21.77-.2.42-.22.9-.05,1.34l2.17,5.43-2.17,5.43c-.34.85.08,1.82.93,2.15.19.08.4.12.6.12.25,0,.5-.06.72-.17l12.48-6.04c.82-.4,1.17-1.39.77-2.21Zm-13.73,7.26c-.27.13-.6.02-.74-.26-.07-.14-.07-.3-.02-.45l2.11-5.28h11.01l-12.37,5.99Zm1.36-7.09l-2.11-5.28c-.09-.21-.03-.45.14-.61.16-.16.41-.2.62-.09l12.37,5.98h-11.01Z"
    />
    <g>
      <circle style={{ fill: "#e10000" }} cx="5.95" cy="22.05" r="5.95" />
      <g>
        <line
          style={{
            stroke: "#fff",
            strokeMiterlimit: 10,
            fill: "none",
            strokeLinecap: "round",
            strokeWidth: "2px",
          }}
          x1="3.78"
          y1="24.22"
          x2="8.12"
          y2="19.88"
        />
        <line
          style={{
            stroke: "#fff",
            strokeMiterlimit: 10,
            fill: "none",
            strokeLinecap: "round",
            strokeWidth: "2px",
          }}
          x1="8.12"
          y1="24.22"
          x2="3.78"
          y2="19.88"
        />
      </g>
    </g>
  </svg>
);
export const sent_error_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#e10000" }} cx="14" cy="14" r="14" />
    <line
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "3px",
      }}
      x1="10.11"
      y1="18.4"
      x2="18.8"
      y2="9.72"
    />
    <line
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "3px",
      }}
      x1="18.8"
      y1="18.4"
      x2="10.11"
      y2="9.72"
    />
  </svg>
);
export const not_handled_icon = (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <circle style={{ fill: "#2a3030" }} cx="14" cy="14" r="14" />
    <path
      style={{ fill: "#fff" }}
      d="m18.72,6.68c-1.73,0-3.15,1.42-3.15,3.15v.31l-5.28,2.13c-.47-.55-1.1-.87-1.81-.87-1.34,0-2.36,1.02-2.36,2.36s1.02,2.36,2.36,2.36c.39,0,.79-.16,1.18-.31l2.44,2.13c-.16.39-.31.87-.31,1.34,0,1.73,1.42,3.15,3.15,3.15s3.15-1.42,3.15-3.15c0-1.1-.55-2.05-1.34-2.6l1.57-3.78c.08.08.24.08.39.08,1.73,0,3.15-1.42,3.15-3.15s-1.42-3.15-3.15-3.15h0Zm-10.24,7.87c-.47,0-.79-.31-.79-.79s.31-.79.79-.79c.31,0,.63.24.71.47.08.16.08.24.08.31,0,.47-.31.79-.79.79Zm6.46,6.3c-.87,0-1.57-.71-1.57-1.57s.71-1.57,1.57-1.57,1.57.71,1.57,1.57-.71,1.57-1.57,1.57Zm.31-4.72h-.31c-.71,0-1.34.24-1.81.63l-2.44-2.13c.08-.24.16-.55.16-.87v-.08l5.35-2.05c.16.24.39.47.63.71l-1.57,3.78Zm3.46-4.72c-.63,0-1.18-.39-1.42-.94-.08-.24-.16-.47-.16-.63,0-.87.71-1.57,1.57-1.57s1.57.71,1.57,1.57-.71,1.57-1.57,1.57Z"
    />
  </svg>
);
