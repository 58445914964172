import React from "react";
import { Trash, Users, PlusCircle, Download, Save } from "react-feather";
import { renderFieldsHelper, RenderError } from "./forms";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { Authorize, permissions } from "../Common/Utils/Acl";

const RenderTextWithoutLabelToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const { t } = field;

  return (
    <div className={`lectures__cell lectures__cell--title ${classes}`}>
      <input
        className="form-control"
        type="text"
        {...field.input}
        disabled={!!shouldDisabled}
      />
      {displayError ? <RenderError t={t} error={error} /> : ""}
    </div>
  );
};

const RenderDurationToTranslate = field => {
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    field,
  );
  const { t } = field;

  return (
    <div className={`lectures__cell lectures__cell--duration ${classes}`}>
      <input
        className="form-control"
        type="number"
        {...field.input}
        disabled={shouldDisabled}
        min={0}
      />
      <span className="entity">h</span>
      {displayError ? <RenderError t={t} error={error} /> : ""}
    </div>
  );
};

export const RenderLecturers = field => {
  <div className="lectures__cell lectures__cell--lecturers">
    <button
      onClick={clickFunction}
      className="btn btn-regular btn-link btn-with-icon"
      type="button"
    >
      <Users size={20} />
      <u>x {field.input.value}</u>
    </button>
  </div>;
};

export const RenderRemove = ({ callback, disabled }) => (
  <div className="lectures__cell lectures__cell--remove">
    <button
      className="btn btn-regular btn-link btn-with-icon"
      type="button"
      onClick={() => callback()}
      disabled={disabled}
    >
      <Trash size={20} />
    </button>
  </div>
);
const AddLectureToTranslate = ({ callback, disabled, t }) => (
  <div className="col-sm-4 col-md-4">
    <button
      className="btn btn-regular btn-link btn-with-icon"
      type="button"
      onClick={() => callback()}
      disabled={disabled}
    >
      <PlusCircle size={20} /> {t("Add lecture")}
    </button>
  </div>
);

const ImportLectureToTranslate = ({ callback, disabled, t }) => (
  <div className="col-sm-4 col-md-4">
    <Authorize
      component={
        <button
          className="btn btn-regular btn-link btn-with-icon"
          type="button"
          onClick={() => callback()}
          disabled={disabled}
        >
          <Download size={20} /> {t("Import lecture")}
        </button>
      }
      allows={[permissions.IMPORT_LECTURES]}
    />
  </div>
);

const SaveLectureToTranslate = ({ disabled, t, callback }) => (
  <div className="col-sm-4 col-md-4">
    <Authorize
      component={
        <button
          className="btn btn-regular btn-link btn-with-icon"
          onClick={() => callback()}
          disabled={disabled}
        >
          <Save size={20} />
          {t("Save lectures")}
        </button>
      }
      allows={[permissions.IMPORT_LECTURES]}
    />
  </div>
);

ImportLectureToTranslate.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.func,
};
AddLectureToTranslate.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.func,
};
RenderRemove.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};
SaveLectureToTranslate.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.func,
};

export const ImportLecture = translate()(ImportLectureToTranslate);
export const AddLecture = translate()(AddLectureToTranslate);
export const SaveLecture = translate()(SaveLectureToTranslate);
export const RenderTextWithoutLabel = translate()(
  RenderTextWithoutLabelToTranslate,
);
export const RenderDuration = translate()(RenderDurationToTranslate);
