import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { withRouter } from "react-router-dom";
import Loader from "../../../Common/Components/Loader/Loader";
import { FieldArray } from "redux-form";
import ModulesList from "./ModulesList";
import _, { isEqual } from "lodash";
import {
  timeInMinutesToString,
  timeInStringToMinuts,
} from "../../../Common/Utils/DateFormatters";
import { Lecture } from "../Types/Lecture";
import { ModulesFormProps } from "../Types/ModulesForm";
import notificationOpts from "../../../Helpers/NotificationOpts";

type ModulesFormState = {
  isLoadingModules: boolean;
  savingLecturersInProgress: boolean;
};

class ModulesForm extends Component<ModulesFormProps, ModulesFormState> {
  private cancelToken = getCancelToken();

  state = {
    isLoadingModules: false,
    savingLecturersInProgress: false,
  };

  componentDidMount() {
    const {
      fetchProductModules,
      fetchProductVersionsMeta,
      fetchLecturesForImport,
      match,
    } = this.props;
    const { versionId } = match.params;

    fetchProductVersionsMeta(this.cancelToken);
    fetchLecturesForImport(this.cancelToken);

    if (versionId) {
      this.setModulesIsLoading();
      fetchProductModules(this.cancelToken, versionId, this.setModulesIsLoaded);
    }
  }

  componentDidUpdate(prevProps: Readonly<ModulesFormProps>) {
    const { productModules, initialize } = this.props;
    const { productModules: prevProductModules } = prevProps;

    if (!isEqual(productModules, prevProductModules)) {
      const modules = productModules.map(module => {
        const packages = module.prices.map(price => price.package);
        const breaks = this.mapBreaksToObject(module);
        const lectures = module.lectures.map(lecture => ({
          ...lecture,
          duration: timeInMinutesToString(lecture.duration),
          courseMode: lecture.courseMode ? lecture.courseMode.id : null,
          type: lecture.type ? lecture.type.id : null,
        }));
        return {
          ...module,
          packages,
          breaks,
          lectures,
          duration: timeInMinutesToString(module.duration),
          durationPerStudent: timeInMinutesToString(module.durationPerStudent),
        };
      });

      initialize({ productModules: modules });
    }
  }

  componentWillUnmount() {
    const { clearProductVersionModules } = this.props;
    clearProductVersionModules();
  }

  mapBreaksToObject = module => {
    if (_.has(module, "breaks") && module.breaks) {
      const breaksForInitialize = {};

      module.breaks.forEach(moduleBreak => {
        const courseMode = this.props.courseModes.find(
          mode => mode.id === moduleBreak.courseMode,
        );

        breaksForInitialize[courseMode.mode] = moduleBreak.duration;
      });

      return { ...breaksForInitialize };
    }
    return null;
  };

  setModulesIsLoading = (): void => this.setState({ isLoadingModules: true });
  setModulesIsLoaded = (): void => this.setState({ isLoadingModules: false });

  onSaveLectures = (
    lectures: Array<Lecture>,
    moduleId: number,
    moduleField: string,
  ) => {
    this.setState({ savingLecturersInProgress: true });
    const { createProductModulesLectures, match, error, change } = this.props;
    const { versionId } = match.params;

    const lecturesWithDurationInMinutes = lectures.map((lecture: Lecture) => ({
      ...lecture,
      duration: timeInStringToMinuts(lecture.duration),
      courseDay: Number(lecture.courseDay),
      lectureName: lecture.name,
      participants: lecture.participants
        ? lecture.participants.map(participant => participant.id)
        : [],
    }));

    createProductModulesLectures(
      Number(versionId),
      moduleId,
      lecturesWithDurationInMinutes,
    ).then(response => {
      const isError = response.payload instanceof Error;
      if (!isError) {
        const responseLectures = _.get(response, "payload.data.data", []);
        const lectures = responseLectures.map(lecture => ({
          ...lecture,
          duration: timeInMinutesToString(lecture.duration),
          courseMode: lecture.courseMode ? lecture.courseMode.id : null,
          type: lecture.type ? lecture.type.id : null,
        }));

        change(`${moduleField}.lectures`, lectures);
      } else {
        const message = _.get(response, "payload.message");
        error(
          notificationOpts({
            title: message,
          }),
        );
      }

      this.setState({ savingLecturersInProgress: false });
    });
  };

  render() {
    const { isLoadingModules, savingLecturersInProgress } = this.state;
    const {
      productVersionsMeta,
      installments,
      change,
      currentProductVersion,
      values,
      currentStatus,
      courseModes,
      deleteProductModule,
      updateProductModules,
      createProductModules,
      modulesOrderChange,
      match,
      handleSubmit,
    } = this.props;
    const { versionId } = match.params;

    const isLoading = isLoadingModules;

    return isLoading ? (
      <Loader />
    ) : (
      <FieldArray
        name="productModules"
        component={ModulesList}
        change={change}
        productVersionsMeta={productVersionsMeta}
        installments={installments}
        currency={currentProductVersion.currency}
        values={values}
        currentStatus={currentStatus}
        courseModes={courseModes}
        onSaveLectures={this.onSaveLectures}
        deleteProductModule={deleteProductModule}
        productVersionId={Number(versionId)}
        updateProductModules={updateProductModules}
        createProductModules={createProductModules}
        modulesOrderChange={modulesOrderChange}
        handleSubmit={handleSubmit}
        savingLecturersInProgress={savingLecturersInProgress}
      />
    );
  }
}

export default withRouter(ModulesForm);
