import React from "react";
import { Field } from "redux-form";
import checkIfContractTypeIs from "../../../Helpers/CheckIfContractTypeIs";
import CONTRACT_PAYER_TYPES from "../../../Enums/ContractPayerType";
import CONTRACT_TYPE from "../../../Enums/ContractType.js";
import FUNDING_TYPES from "../../../Enums/FundingTypes";
import {
  RenderDateTime,
  RenderNumber,
  RenderSelect,
  RenderText,
} from "../../../Forms/forms";
import NewPayer from "../../Companies/Containers/NewCompany";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import {
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";
import ReactTable from "react-table";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import { Trash, X } from "react-feather";
import SelectWithPagination from "../../../Common/Components/SelectWithPagination/SelectWithPagination";
import KeyCodes from "../../../Enums/KeyCodes";
import AmountField from "../../../Forms/AmountField";
import PriceField from "../../../Forms/PriceField";
import { withRouter } from "react-router";
import { get } from "lodash";

class ContractFunding extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    payers: PropTypes.array,
    payerData: PropTypes.object,
    contractFundingTypes: PropTypes.array,
    contractPayers: PropTypes.array,
    searchInGus: PropTypes.bool,
    isDisabledField: PropTypes.bool,
    updatePackage: PropTypes.func,
    change: PropTypes.func,
    fetchCompanies: PropTypes.func,
    contractType: PropTypes.string,
    paymentScheduleReload: PropTypes.func,
    taxRates: PropTypes.array,
    payments: PropTypes.array,
    contractCurrency: PropTypes.string,
    contractPackage: PropTypes.number,
    priceForPackage: PropTypes.number,
    formInvalid: PropTypes.bool,
    isPercent: PropTypes.bool,
    isContractSigned: PropTypes.bool,
  };

  #defaultPayer = {
    balance: {},
    side: {
      id: CONTRACT_PAYER_TYPES.THIRD_PARTY,
    },
    partAmount: {},
    part: 0,
  };

  #columns = [
    {
      Header: this.props.t("Payer"),
      id: "payer",
      Cell: row => {
        return row.original.side &&
          row.original.side.id === CONTRACT_PAYER_TYPES.STUDENT ? (
          <p>{row.original.balance.owner.name}</p>
        ) : (
          <Field
            name={`payments.payers[${row.index}].balance.owner.name`}
            labelKey={"name"}
            placeholder={this.props.t("Select the payer")}
            fetchData={this.props.fetchCompanies}
            options={this.props.payers}
            valueKey={"name"}
            onChangeHandler={payer => {
              this.choosePayer(payer, row.index);
            }}
            under={{ containerClassName: "" }}
            component={SelectWithPagination}
          />
        );
      },
    },
    {
      Header: this.props.t("Side"),
      id: "side",
      Cell: ({ original }) => {
        return original.side &&
          original.side.id === CONTRACT_PAYER_TYPES.STUDENT ? (
          <p>{this.props.t(original.side.name)}</p>
        ) : (
          <p>{this.props.t("Third party")}</p>
        );
      },
    },
    {
      Header: this.props.t("Funding type"),
      id: "fundingType",
      Cell: row => {
        return (
          row.original.side && (
            <Field
              component={RenderSelect}
              under={{
                containerClassName: "fundingType",
                fieldClassName: "col-xs-12",
              }}
              name={`payments.payers[${row.index}].fundingType.id`}
              dropdownConfig={{
                data: this.props.contractFundingTypes,
                placeholder: this.props.t("Choose funding type"),
                textField: "name",
                valueField: "id",
              }}
            />
          )
        );
      },
    },
    {
      Header: this.props.t("% VAT"),
      id: "taxRate",
      Cell: row => {
        return (
          row.original.side && (
            <Field
              component={RenderSelect}
              disabled={this.props.isContractSigned}
              under={{
                containerClassName: "taxRate",
                fieldClassName: "col-xs-12",
              }}
              name={`payments.payers[${row.index}].taxRate`}
              dropdownConfig={{
                data: this.props.taxRates.map(rate => {
                  return { name: rate, value: rate };
                }),
                placeholder: this.props.t("Choose VAT rate"),
                textField: "name",
                valueField: "value",
              }}
            />
          )
        );
      },
    },
    {
      Header: (
        <div>
          {this.props.t("Part of payments") + " (%)"}
          <span
            className={`reset-parts-button`}
            title={this.props.t("Payments parts reset")}
            onClick={() => this.resetPartsPercent()}
          >
            {" "}
            <X size={16} />
          </span>
        </div>
      ),
      sortable: false,
      id: "part",
      Cell: row => {
        const fieldName = `payments.payers[${row.index}].part`;
        return (
          <Field
            under={{
              containerClassName: "partField",
              fieldClassName: "col-xs-12",
            }}
            name={fieldName}
            component={RenderNumber}
            parse={value => parseInt(value)}
            step="1"
            min={0}
            max={100}
            shouldDisabled={!this.props.isPercent}
            onKeyPress={event => {
              if (
                event.charCode === KeyCodes.COMMA ||
                event.charCode === KeyCodes.DOT
              ) {
                event.preventDefault();
              }
            }}
            onChange={(_, value) => this.onChangePercent(value, row.index)}
          />
        );
      },
    },
    {
      Header: (
        <div>
          {this.props.t("Amount of payments")}
          <span
            className={`reset-parts-button`}
            title={this.props.t("Payments parts reset")}
            onClick={() => this.resetAmountParts()}
          >
            {" "}
            <X size={16} />
          </span>
        </div>
      ),
      sortable: false,
      Cell: row => {
        const fieldName = `payments.payers[${row.index}].partAmount.amount`;
        return (
          <AmountField
            component={PriceField}
            under={{
              containerClassName: "partField",
              fieldClassName: "col-xs-12",
            }}
            name={fieldName}
            step="1"
            min={0}
            shouldDisabled={this.props.isPercent}
            onChange={(_, value) => this.onChangeAmount(value, row.index)}
          />
        );
      },
    },
    {
      Header: this.props.t("Bank account for refunds"),
      id: "iban",
      accessor: "bankAccount.iban",
      Cell: row => {
        return (
          <Field
            name={`payments.payers[${row.index}].bankAccount.iban`}
            component={RenderText}
          />
        );
      },
    },
    {
      Header: this.props.t("Email"),
      id: "email",
      Cell: ({ original }) => {
        const emails = get(original, "balance.owner.invoiceReceiverEmails", []);

        return (
          <div>
            {emails.map(email => (
              <div key={email} title={email}>
                {email}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: "",
      id: "goToPayer",
      Cell: ({ original }) => {
        const personId = get(original, "balance.owner.personId", null);
        const companyId = get(original, "balance.owner.companyId", null);

        const {
          history: { push },
          t,
        } = this.props;
        return original.side &&
          original.side.id === CONTRACT_PAYER_TYPES.STUDENT ? (
          <button
            className={`btn btn-dark`}
            onClick={() => push(`/persons/update/${personId}`)}
          >
            {t("Edit payer")}
          </button>
        ) : (
          <button
            className={`btn btn-dark`}
            onClick={() => push(`/companies/${companyId}`)}
          >
            {t("Edit payer")}
          </button>
        );
      },
    },
    {
      Header: "",
      maxWidth: 100,
      Cell: row => {
        const canDeletePayer =
          row.original.side &&
          row.original.side.id !== CONTRACT_PAYER_TYPES.STUDENT;
        return (
          <div>
            {canDeletePayer && (
              <button
                className={`btn btn--transparent`}
                onClick={() => {
                  this.deletePayer(row.index);
                }}
              >
                <Trash size={16} />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      searchInGus: false,
      showPayerForm: false,
      isDisabledField: props.isDisabledField,
      showFoundingTypeChangeAlert: false,
    };
  }

  componentDidMount() {
    this.setStartPartMethod();
  }

  componentDidUpdate(prevProps) {
    this.shouldReloadAfterChange(prevProps);
  }

  shouldReloadAfterChange = prevProps => {
    const {
      contractPayers,
      isPercent,
      paymentScheduleReload,
      priceForPackage,
    } = this.props;
    const { contractPayers: prevContractPayers } = prevProps;
    const MAX_PERCENT_VALUE = 100;
    const hasIncompletePayer = !!contractPayers.find(
      payer => !_.has(payer, "balance.id") || !payer.fundingType,
    );

    if (!_.isEqual(contractPayers, prevContractPayers) && !hasIncompletePayer) {
      if (isPercent) {
        const percentSum = contractPayers.reduce(
          (previousValue, currentValue) => previousValue + currentValue.part,
          0,
        );

        const prevPercentSum = prevContractPayers.reduce(
          (previousValue, currentValue) => previousValue + currentValue.part,
          0,
        );

        if (
          prevPercentSum !== MAX_PERCENT_VALUE &&
          percentSum === MAX_PERCENT_VALUE
        ) {
          paymentScheduleReload();
        }
      } else {
        const amountSum = contractPayers.reduce(
          (previousValue, currentValue) =>
            previousValue + _.get(currentValue, "partAmount.amount", 0),
          0,
        );
        const prevAmountSum = prevContractPayers.reduce(
          (previousValue, currentValue) =>
            previousValue + _.get(currentValue, "partAmount.amount", 0),
          0,
        );

        if (
          prevAmountSum !== priceForPackage &&
          amountSum === priceForPackage
        ) {
          paymentScheduleReload();
        }
      }
    }
  };

  setStartPartMethod = () => {
    const { contractPayers, change } = this.props;
    const [firstPayer] = contractPayers;
    change("payments.isPercent", firstPayer.part !== null);
  };

  choosePayer = (payer, index) => {
    const { contractPayers, change } = this.props;
    const invoiceReceiverEmails = get(payer, "invoiceReceiver.email");
    change(`payments.payers[${index}]`, {
      ...contractPayers[index],
      balance: {
        id: payer.balance.id,
        owner: {
          ...payer,
          companyId: payer.id,
          invoiceReceiverEmails: [invoiceReceiverEmails],
        },
      },
      side: {
        id: CONTRACT_PAYER_TYPES.THIRD_PARTY,
      },
      taxRate: payer.taxRate,
    });
  };

  setSearchInGusState = isSearched => {
    this.setState({
      searchInGus: isSearched,
    });
  };

  togglePayerForm = showPayerForm => {
    this.setState({
      showPayerForm,
    });
  };

  onChangeAmount = (value, index) => {
    const { contractPayers, change, contractCurrency } = this.props;

    change("payments.isPercent", false);

    const payersWithPartAmount = contractPayers.map(payer => ({
      ...payer,
      part: null,
    }));

    payersWithPartAmount[index].partAmount = {
      amount: value,
      currency: contractCurrency,
    };

    change("payments.payers", payersWithPartAmount);
  };

  onChangePercent = (value, index) => {
    const { contractPayers, change } = this.props;
    change("payments.isPercent", true);

    const payersWithPercent = contractPayers.map(payer => ({
      ...payer,
      partAmount: null,
    }));
    payersWithPercent[index].part = value;
    change("payments.payers", payersWithPercent);
  };

  resetAmountParts = () => {
    const { contractPayers, change } = this.props;
    const payers = contractPayers.map(payer => ({
      ...payer,
      part: 0,
      partAmount: null,
    }));

    change("payments.payers", payers);
    change("payments.isPercent", true);
  };

  resetPartsPercent = () => {
    const { contractPayers, change } = this.props;
    const payers = contractPayers.map(payer => ({
      ...payer,
      part: null,
    }));

    change("payments.payers", payers);
    change("payments.isPercent", false);
  };

  handleNewPayerFormClose = (newPayer = null) => {
    if (newPayer) {
      this.togglePayerForm(false);
      this.setState({
        showPayerAssignConfirmModal: true,
        tempPayerToAssign: {
          ...newPayer,
          balance: {
            id: newPayer.balance.id,
            owner: {
              name: newPayer.name,
              companyId: newPayer.id,
            },
          },
          side: { id: CONTRACT_PAYER_TYPES.THIRD_PARTY },
        },
      });
    } else {
      this.togglePayerForm(false);
    }
  };

  addPayer = (payer = this.#defaultPayer) => {
    const { contractPayers, change } = this.props;
    const payers = [...contractPayers, payer];
    change("payments.payers", payers);
  };

  declinePayerAssignment = () => {
    this.setState({
      showPayerAssignConfirmModal: false,
    });
  };

  acceptPayerAssignment = () => {
    this.declinePayerAssignment();
    this.addPayer(this.state.tempPayerToAssign);
  };

  deletePayer = index => {
    const contractPayers = [...this.props.contractPayers];
    contractPayers.splice(index, 1);
    if (contractPayers.length === 1) {
      this.props.change("type", CONTRACT_TYPE.SELF);
    }
    this.props.change("payments.payers", contractPayers);
  };

  openFoundingTypeChangeAlert = () =>
    this.setState({ showFoundingTypeChangeAlert: true });

  closeFoundingTypeChangeAlert = () =>
    this.setState({ showFoundingTypeChangeAlert: false });

  onChangeType = (value, event, fieldName) => {
    const { change, contractPayers } = this.props;
    const hasOtherPayerThenStudent = !!contractPayers.find(
      payer => payer.side.id !== CONTRACT_PAYER_TYPES.STUDENT,
    );

    if (value.id === CONTRACT_TYPE.SELF && hasOtherPayerThenStudent) {
      change(fieldName, event.lastValue);
      this.openFoundingTypeChangeAlert();
    } else {
      change(fieldName, value.id);
    }
  };

  render() {
    const {
      t,
      payerData,
      searchInGus,
      isDisabledField,
      contractPayers,
      contractType,
      isContractSigned,
    } = this.props;
    const { showPayerForm, showFoundingTypeChangeAlert } = this.state;

    return (
      havePermissions([permissions.STUDENT_CONTRACT_PAYER_EDIT]) &&
      (contractPayers && (
        <div id="student-contract-funding" className="row">
          <CustomModal
            isOpen={showFoundingTypeChangeAlert}
            title={t("Type of contract change")}
            onRequestClose={this.closeFoundingTypeChangeAlert}
          >
            <p>
              {t(
                "Before changing the type of contract, remove payers other than the student",
              )}
            </p>
          </CustomModal>
          <ConfirmModal
            ariaHideApp={false}
            isOpen={this.state.showPayerAssignConfirmModal}
            title={t("New payer created")}
            onRequestClose={this.declinePayerAssignment}
            config={{
              body: (
                <div>
                  <p>
                    {t(
                      "New payer was created. Do you want assign him to this contract?",
                    )}
                  </p>
                </div>
              ),
              onRequestConfirm: this.acceptPayerAssignment,
            }}
          />
          <Authorize
            component={
              <CustomModal
                customStyles={{
                  content: { overflow: "visible" },
                }}
                isOpen={showPayerForm}
                title={t("Add payer")}
                onRequestClose={() => {
                  this.togglePayerForm(false);
                }}
                ariaHideApp={false}
              >
                <NewPayer
                  payerData={payerData}
                  searchInGus={searchInGus}
                  isDisabledField={
                    isDisabledField ||
                    !havePermissions([permissions.STUDENT_CONTRACT_PAYER_EDIT])
                  }
                  onCancel={this.handleNewPayerFormClose}
                  setSearchInGusState={this.setSearchInGusState}
                />
              </CustomModal>
            }
            allows={[permissions.STUDENT_CONTRACT_PAYER_VIEW]}
          />
          <div className="col-xs-12">
            <h2>{t("Schedule and payment status")}</h2>

            <div className={`col-sm-4 col-xs-12`}>
              <Field
                under
                label={t(`Prework share date`)}
                name={`contract.preworkShareDate`}
                component={RenderDateTime}
                time={false}
              />
            </div>
            {!checkIfContractTypeIs(
              contractType,
              FUNDING_TYPES.INDEPENDENT,
            ) && (
              <div className={`row`}>
                <div className={`col-xs-12`}>
                  <button
                    disabled={isContractSigned}
                    onClick={() => {
                      this.addPayer();
                    }}
                    className={`btn pull-right assignPayer`}
                  >
                    {t("Assign payer")}
                  </button>
                  <Authorize
                    component={
                      <button
                        onClick={() => this.togglePayerForm(true)}
                        className="pull-right addPayer"
                      >
                        {t("Add payer")}
                      </button>
                    }
                    allows={[permissions.STUDENT_CONTRACT_PAYER_EDIT]}
                  />
                </div>
              </div>
            )}
            <ReactTable
              noDataText={t("No records found")}
              data={contractPayers}
              pageSize={contractPayers.length || 0}
              columns={this.#columns}
              className="contractPayersTable"
              showPagination={false}
            />
          </div>
        </div>
      ))
    );
  }
}

export default translate()(withRouter(ContractFunding));
