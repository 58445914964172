import { coursesInitState } from "./Courses";
import ACTION_TYPES from "../Actions/ActionTypes";

export function mentorsForCourseReducer(
  state = coursesInitState.mentors,
  action,
) {
  switch (action.type) {
    case ACTION_TYPES.GET_MENTORS_FOR_COURSE:
      return action.payload.data.data;
    default:
      return state;
  }
}
