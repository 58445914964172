import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import PropTypes from "prop-types";

const composeEnhancers = composeWithDevTools(applyMiddleware(promise, thunk));
export const store = createStore(reducers, composeEnhancers);

const provider = props => {
  return <Provider store={store}>{props.children}</Provider>;
};

provider.propTypes = { children: PropTypes.object };

export default provider;
