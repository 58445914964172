import Filters from "../Components/Contracts/Filters";
import React from "react";
import { formValueSelector, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchContractPackages,
  fetchCourses,
  getContractFinishReasons,
  getContractStatus,
} from "../Actions/CoursesActions";
import {
  fetchAllProducts,
  fetchProductVersionsMeta,
} from "../../Products/Actions";
import { getCoursesSpecialists } from "../../Persons/Actions";

const FORM_NAME = "CONTRACTS_FILTERS";

const FiltersContainer = props => <Filters {...props} />;
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = appState => {
  const values = selector(
    appState,
    "courseSpecialistId",
    "payer",
    "finishReason",
    "package",
    "courseSignature",
  );

  const {
    dictionaries: { contractFinishReasons, contractStatus },
    contractPackages,
    coursesList,
    productVersionsMeta: { moduleTypes },
    productsList,
    coursesSpecialists,
  } = appState;

  return {
    values,
    contractFinishReasons,
    contractStatus,
    contractPackages,
    coursesList,
    moduleTypes,
    productsList,
    coursesSpecialists,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getContractFinishReasons,
      getContractStatus,
      fetchContractPackages,
      fetchCourses,
      fetchProductVersionsMeta,
      fetchAllProducts,
      getCoursesSpecialists,
    },
    dispatch,
  );
};

export default reduxForm({
  validate: null,
  form: FORM_NAME,
  initialValues: { payer: null },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FiltersContainer),
);
