import React from "react";
import moment from "moment";
import TIME from "../../../../Enums/Time";
import { havePermissions, permissions } from "../../../../Common/Utils/Acl";
import { COURSE_CONTRACT_FINISH_REASON_ID } from "../../../../Enums/Contract";
import { Compass } from "react-feather";
import { FinishReason } from "../../../../Types/FinishReason";

type ContractFinishReasonProps = {
  t: (key: string) => string;
  finishReason: FinishReason;
  finishDate: number;
  diploma: string;
  transferCourse: { id: number; signature: string };
};

const ContractFinishReason: React.FunctionComponent<
  ContractFinishReasonProps
> = ({
  t,
  finishReason,
  finishDate,
  diploma,
  transferCourse,
}): React.ReactElement => {
  const date = finishReason && finishDate;
  const correctFormatDate = date ? moment(date).format(TIME.DATE_FORMAT) : "";
  const name = finishReason && finishReason.name;
  const iconSize = { size: 15 };

  if (!havePermissions([permissions.STUDENT_CONTRACT_FINISH_STATUS_VIEW])) {
    return null;
  }

  const {
    FINISHED,
    TRANSFER,
    RESIGN,
    FAIL,
    CANCELLED,
    ABSOLUTORY,
    TRANSFER_RESIGN,
    WAITING_FOR_SLAVES,
    MODULE_COMPLETED,
  } = COURSE_CONTRACT_FINISH_REASON_ID;

  switch (finishReason.id) {
    case FINISHED:
      return (
        <span className="finish-reason__finished text--bold">
          <Compass {...iconSize} />
          {`${name}, ${t("diploma number")}: ${diploma &&
            diploma} ${correctFormatDate}`}
        </span>
      );
    case TRANSFER:
      return (
        <span className="finish-reason__transfer text--bold">
          <Compass {...iconSize} />
          {`${name} ${t("InvoiceTo")} ${
            typeof transferCourse === "object"
              ? transferCourse.signature
              : transferCourse
          }`}
        </span>
      );
    case RESIGN:
    case FAIL:
    case CANCELLED:
    case ABSOLUTORY:
    case TRANSFER_RESIGN:
    case WAITING_FOR_SLAVES:
    case MODULE_COMPLETED:
      return (
        <span className={`finish-reason__${finishReason.code} text--bold`}>
          <Compass {...iconSize} />
          {`${name} ${correctFormatDate}`}
        </span>
      );

    default:
      return (
        <span className="finish-reason__fail text--bold">
          <Compass {...iconSize} />
          {`${name} ${correctFormatDate}`}
        </span>
      );
  }
};

export default ContractFinishReason;
