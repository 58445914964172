import React, { Component } from "react";
import { translate } from "react-i18next";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import Loader from "../../../Common/Components/Loader/Loader.js";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import POLL_TYPES from "../../../Enums/PollTypes";
import FUNDING_TYPES from "../../../Enums/FundingTypes";
import CONTRACT_FINISH_REASONS from "../../../Enums/ContractFinishReasons";
import CONTRACT_STATUSES from "../../../Enums/ContractStatuses";
import StudentHistory from "../Containers/StudentHistory";
import { Link } from "react-router-dom";
import checkIfContractStatusIs from "../../../Helpers/CheckIfContractStatusIs";
import checkIfContractFinishReasonIs from "../../../Helpers/CheckIfContractFinishReasonIs";
import getCancelToken from "../../../Helpers/GetCancelToken";
import laborOfficeIsExistInContractPayers from "../../../Helpers/LaborOfficeIsExistInContractPayers";
import SideModal from "../../../Common/Components/SideModal/SideModal";
import ContractAddressPopup from "../Containers/ContractAddressPopup";
import PropTypes from "prop-types";
import dateHelper from "../../../Helpers/DateHelper";
import ContractAdditionalFee from "../Containers/ContractAdditionalFee";
import ContractStatus from "./ContractStatus";
import ContractFunding from "./ContractFunding";
import ContractPayments from "./ContractPayments";
import {
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";
import * as _ from "lodash";
import { RotateCcw } from "react-feather";
import * as HttpStatus from "http-status-codes";
import Slaves from "./Slaves";
import { payerMapper } from "../Utils/PayerMapper";
import { getPriceForPackage } from "../Utils/GetPriceForPackage";
import { Features, Tenant } from "../../../Common/Utils/Tenant";
import PayU from "./PayU";
import { convertAmountToPrice } from "../../../Common/Utils/PriceFormatters";
import { getContractAmounts } from "../Actions";

const RESIGN_CODE = 3;

class StudentContractForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    untouch: PropTypes.func,
    getDiploma: PropTypes.func,
    updateFinishedContract: PropTypes.func,
    contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coursesList: PropTypes.object,
    change: PropTypes.func,
    updateContract: PropTypes.func,
    companies: PropTypes.object,
    payer: PropTypes.object,
    fetchDictionary: PropTypes.func,
    fetchCompanies: PropTypes.func,
    fetchProductById: PropTypes.func,
    fetchProductModulesSimple: PropTypes.func,
    fetchProductModulesInstallmentStartSimple: PropTypes.func,
    getCourse: PropTypes.func,
    passErrorToStore: PropTypes.func,
    submitFailed: PropTypes.bool,
    showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    fetchCourses: PropTypes.func,
    clearErrorFromStore: PropTypes.func,
    values: PropTypes.object,
    initialize: PropTypes.func,
    contract: PropTypes.object,
    clearContract: PropTypes.func,
    t: PropTypes.func,
    preworkShareDate: PropTypes.string,
    dictionaries: PropTypes.object,
    currentProductVersion: PropTypes.object,
    fundingCalculate: PropTypes.func,
    payments: PropTypes.object,
    reset: PropTypes.func,
    fetchContractPackagesTransitions: PropTypes.func,
    contractPackagesTransitions: PropTypes.array,
    currentProductVersionModules: PropTypes.array,
    incomesThirdPartyArray: PropTypes.array,
    fetchContractPolls: PropTypes.func,
    fetchContract: PropTypes.func,
    touch: PropTypes.func,
    updatePayments: PropTypes.func,
    fetchPayments: PropTypes.func,
    priceGrossCalculator: PropTypes.func,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    student: PropTypes.object,
    course: PropTypes.object,
    submitSucceeded: PropTypes.bool,
    valid: PropTypes.bool,
    contractLoading: PropTypes.bool,
    initialValues: PropTypes.object,
    paymentActivate: PropTypes.func,
    paymentCancel: PropTypes.func,
    fetchContractMeta: PropTypes.func,
    fetchProductPricesById: PropTypes.func,
    createOrder: PropTypes.func,
    productPrices: PropTypes.array,
    invalid: PropTypes.bool,
    originalContractStatus: PropTypes.string,
    getContract: PropTypes.func,
    getContractAmounts: PropTypes.func,
    contractAmounts: PropTypes.object,
    fetchDictPromotions: PropTypes.func,
    specialOffers: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const {
      FETCH_CONTRACT_FINISH_REASONS,
      FETCH_CONTRACT_STATUS,
      FETCH_INSTALLMENTS,
    } = ACTION_TYPES;
    const {
      fetchDictionary,
      fetchCompanies,
      fetchProductById,
      getCourse,
      fetchCourses,
      fetchContractPackagesTransitions,
      contract,
      fetchPayments,
      contractId,
      fetchContractMeta,
      fetchProductPricesById,
      getContractAmounts,
      fetchDictPromotions,
    } = this.props;
    this.state = {
      shouldDisplay: false,
      showPayerForm: false,
      tabIndex: 0,
      payments: [],
      temporaryFundingType: null,
      showChangeFundingConfirmation: false,
      renderModal: false,
      rebate: 0,
      tableLoading: true,
      userAddressModalIsOpen: false,
      hasAddress: true,
      showChangePackageConfirmation: false,
      temporaryPackageId: null,
      payerData: {},
      initialPayerData: null,
      createCompanyForm: false,
      searchInGus: false,
      showAdditionalFeeForm: false,
      manualIncomeData: null,
      isDataChanged: false,
      showChangeRebateAlert: false,
      statusChange: false,
    };
    fetchDictionary(this.cancelToken, {
      path: "/installments",
      actionType: FETCH_INSTALLMENTS,
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/contract/finishreason",
      actionType: FETCH_CONTRACT_FINISH_REASONS,
    });
    fetchDictionary(this.cancelToken, {
      path: "/course/contract/status",
      actionType: FETCH_CONTRACT_STATUS,
    });
    fetchCompanies(this.cancelToken);
    getCourse(this.cancelToken, contract.course.id, result => {
      fetchCourses(this.cancelToken, {
        productId: result.data.data.product.id,
        pageAmount: 100,
      });
      fetchProductById(this.cancelToken, result.data.data.product.id);
      fetchProductPricesById(this.cancelToken, result.data.data.product.id);
    });
    fetchContractPackagesTransitions(this.cancelToken, contractId);
    fetchPayments(this.cancelToken, contractId, () => {
      this.setState({
        tableLoading: false,
      });
    });
    fetchContractMeta(this.cancelToken, contractId);
    fetchDictPromotions(this.cancelToken, true, contractId);
    getContractAmounts(this.cancelToken, contractId);
  }

  componentDidUpdate(prevProps) {
    this.checkIfStudentHasAddress(prevProps);
    this.updatePayers();
    this.changeDatesAccordingToStatus();
    this.updateRebate(prevProps);
    this.handleErrors(prevProps);
    this.initializeValuesIfNeeded();
    this.updateProductPrices(prevProps);
  }

  updateProductPrices = prevProps => {
    const { productPrices, change } = this.props;
    const { productPrices: prevProductPrices } = prevProps;

    if (!_.isEqual(productPrices, prevProductPrices)) {
      change("payments.productPrices", productPrices);
    }
  };

  checkIfStudentHasAddress = prevProps => {
    const {
      values: { student },
    } = this.props;
    if (student && !_.isEqual(student, prevProps.values.student)) {
      this.setState({
        hasAddress: student.person.hasAddress,
      });
    }
  };

  updatePayers = () => {
    const { dictionaries } = this.props;
    if (
      dictionaries.payers &&
      !_.isEqual(
        dictionaries.payers.sort(
          (prevPayer, nextPayer) => prevPayer.id - nextPayer.id,
        ),
        this.state.payers.sort(
          (prevPayer, nextPayer) => prevPayer.id - nextPayer.id,
        ),
      )
    ) {
      this.setState({
        payers: dictionaries.payers,
      });
    }
  };

  changeDatesAccordingToStatus = () => {
    const {
      values: { contract },
      change,
    } = this.props;
    if (contract) {
      const today = dateHelper().getFormatted();
      if (
        checkIfContractStatusIs(
          (contract && contract.status && contract.status.id) ||
            contract.status,
          CONTRACT_STATUSES.CONTRACT_SEND,
        ) &&
        !contract.sendDate
      ) {
        change("contract.sendDate", today);
      }
      if (
        checkIfContractStatusIs(
          (contract && contract.status && contract.status.id) ||
            contract.status,
          CONTRACT_STATUSES.CONTRACT_SIGNED,
        ) &&
        !contract.signDate
      ) {
        change("contract.signDate", today);
      }
      if (
        checkIfContractStatusIs(
          (contract && contract.status && contract.status.id) ||
            contract.status,
          CONTRACT_STATUSES.CONTRACT_DENIED,
        ) &&
        !contract.withdrawDate
      ) {
        change("contract.withdrawDate", today);
      }
    }
  };

  updateRebate = prevProps => {
    if (
      _.has(this.props.payments, "rebate") &&
      _.has(prevProps.payments, "rebate") &&
      this.props.payments.rebate !== prevProps.payments.rebate
    ) {
      this.setState({
        rebate: this.props.payments.rebate,
      });
    }
  };

  handleErrors = prevProps => {
    const {
      submitSucceeded,
      passErrorToStore,
      submitFailed,
      showError,
      clearErrorFromStore,
      valid,
    } = this.props;
    if (
      submitFailed &&
      !valid &&
      !showError &&
      submitFailed !== prevProps.submitFailed
    ) {
      passErrorToStore();
    } else if (submitSucceeded && valid && showError) {
      clearErrorFromStore();
    }
  };

  initializeValuesIfNeeded = () => {
    const {
      initialize,
      contract,
      payments,
      values,
      productPrices,
    } = this.props;
    if (
      contract &&
      payments &&
      payments.type &&
      (!values || !values.course || !values.student || !values.payments)
    ) {
      initialize({
        contract: contract.contract,
        student: contract.student,
        course: contract.course,
        payments: {
          ...payments,
          isPercent: this.setIsPercent(payments),
          productPrices: productPrices ? productPrices : [],
        },
        type: payments.type.code,
        payers: [],
      });
    }
  };

  setIsPercent = payments => {
    const [firstPayer] = payments.payers;
    return _.isNumber(firstPayer.part);
  };

  modifyPayments = payments => {
    let previousInstallment = "";
    const visiblePayments = [...payments].filter(payment => payment.isVisible);
    visiblePayments.map((payment, index) => {
      if (payment.installment !== previousInstallment || !payment.installment) {
        if (visiblePayments[index - 1]) {
          visiblePayments[index - 1].lastInGroup = true;
        }
        previousInstallment = payment.installment;
      } else {
        payment.shoudHideInstallment = true;
      }
      return payment;
    });
    return visiblePayments.length > 0 ? visiblePayments : [];
  };

  componentWillUnmount() {
    this.cancelToken.cancel();
    this.props.clearContract();
    this.props.reset();
  }

  handleDownload = () => {
    const {
      getDiploma,
      values: {
        contract: { id },
      },
    } = this.props;
    getDiploma(id);
  };

  loadModules = value => {
    const { change, fetchProductById } = this.props;
    change("contract.transferCourse", value);
    fetchProductById(this.cancelToken, value.product.id);
  };

  searchCourse = search => {
    const { fetchCourses } = this.props;
    fetchCourses(this.cancelToken, {
      search,
    });
  };

  updatePackage = (event, packageId) => {
    event.preventDefault();
    const { change } = this.props;
    change("payments.package", packageId);
    this.setState(
      {
        temporaryPackageId: packageId,
        isDataChanged: true,
      },
      () => {
        this.recalculateSchedule("showChangePackageConfirmation");
      },
    );
  };

  recalculateSchedule = type => {
    this.setState({
      [type]: true,
      isDataChanged: false,
    });
  };

  onSubmit = values => {
    let parsedValues = { ...values };
    const parsedPayments = { ...parsedValues.payments, type: values.type };

    // eslint-disable-next-line no-console
    console.log(parsedValues);

    const {
      updateContract,
      contractId,
      fetchContractPackagesTransitions,
      fetchContractPolls,
      updatePayments,
      fetchPayments,
      change,
      payments,
      fetchContractMeta,
      getContractAmounts,
    } = this.props;
    parsedValues.payments.package =
      values.payments.package.id || values.payments.package;

    if (!this.state.hasAddress && this.state.statusChange) {
      this.setState({ userAddressModalIsOpen: true });
      return;
    }

    this.setState({ tableLoading: true, submitStatus: true }, () => {
      if (!!parsedValues.contract.status && !!parsedValues.contract.status.id) {
        parsedValues.contract.status = parsedValues.contract.status.id;
      }

      if (
        !!parsedValues.contract.finishReason &&
        !!parsedValues.contract.finishReason.id
      ) {
        parsedValues.contract.finishReason = values.contract.finishReason.id;
      }

      parsedPayments.payments = parsedPayments.payments.map(payment => {
        return {
          amountGross: payment.price,
          rebate: parsedPayments.rebate,
          id: payment.id,
          description: payment.description,
          deadline: payment.dates.deadline,
          type: payment.type,
          status: payment.status,
          installment:
            payment.installment && payment.installment.id
              ? payment.installment.id
              : null,
          balanceId: payment.contractPayer.balance.id,
          productModule: payment.productModule,
          slaveProductModule: payment.slaveProductModule,
        };
      });
      if (parsedPayments.package && parsedPayments.package.id) {
        parsedPayments.package = parsedPayments.package.id;
      }

      if (laborOfficeIsExistInContractPayers(values.payments.payers)) {
        parsedPayments.laborOfficeContractNr =
          values.payments.laborOfficeContractNr;
      }

      parsedPayments.payers = this.unifyPayers(parsedPayments.payers);

      updatePayments(parsedPayments, contractId, () => {
        updateContract(parsedValues.contract, { contractId }, response => {
          const responseData = response.data.data;
          if (responseData.student) {
            change("student", responseData.student);
          }
          fetchContractPackagesTransitions(
            this.cancelToken,
            parsedValues.contract.id,
          );
          fetchContractPolls(this.cancelToken, {
            contractId: parsedValues.contract.id,
            pollType: POLL_TYPES.COURSE,
            actionType: ACTION_TYPES.FETCH_CONTRACT_POLLS,
          });
          fetchContractPolls(this.cancelToken, {
            contractId: parsedValues.contract.id,
            pollType: POLL_TYPES.MODULE,
            actionType: ACTION_TYPES.FETCH_CONTRACT_MODULES_POLLS,
          });
          fetchContractPolls(this.cancelToken, {
            contractId: parsedValues.contract.id,
            pollType: POLL_TYPES.SLAVE,
            actionType: ACTION_TYPES.FETCH_CONTRACT_SLAVE_POLLS,
          });
          fetchContractPolls(this.cancelToken, {
            contractId: parsedValues.contract.id,
            pollType: POLL_TYPES.MANUAL,
            actionType: ACTION_TYPES.FETCH_CONTRACT_MANUAL_POLLS,
          });
          fetchContractMeta(this.cancelToken, contractId);
          fetchPayments(
            this.cancelToken,
            parsedValues.contract.id,
            ({ data }) => {
              if (!_.isEqual(data.data, payments)) {
                change("payments", data.data);
              }

              this.setState({
                tableLoading: false,
                submitStatus: false,
              });
            },
          ).then(({ payload: { data: { data } } }) => {
            if (
              values.payments.bankAccountThirdParty !==
              data.bankAccountThirdParty
            ) {
              change(
                "payments.bankAccountThirdParty",
                data.bankAccountThirdParty,
              );
            }
          });
        })
          .then(({ payload: { data: { data } } }) => {
            change("contract", data.contract);
            if (
              _.has(data, "student.bankAccount.nr") &&
              (values.student.bankAccount && values.student.bankAccount.nr) !==
                data.student.bankAccount.nr
            ) {
              change("student.bankAccount.nr", data.student.bankAccount.nr);
            }
          })
          .then(() => {
            getContractAmounts(this.cancelToken, contractId, data => {
              this.setState({
                tableLoading: false,
                contractAmounts: data.data,
              });
            });
          });
      });
    });
  };

  toggleSideModal = () => {
    this.setState({ renderModal: !this.state.renderModal });
  };

  setRebate = (event, rebate) => {
    rebate = Number.isNaN(rebate) ? 0 : rebate;
    this.setState({ rebate }, this.updateSetRebate);
  };

  openChangeRebateAlert = () => {
    const { rebate } = this.state;
    if (_.isInteger(rebate)) {
      this.setState({ showChangeRebateAlert: true });
    }
  };

  closeChangeRebateAlert = () =>
    this.setState({ showChangeRebateAlert: false });

  updateSetRebate = _.debounce(this.openChangeRebateAlert, 500);

  acceptChangeRebate = () => {
    const { rebate } = this.state;
    const { change, contract, payments } = this.props;

    const payload = {
      type: payments.type.id,
      contractId: contract.contract.id,
      fundingType: payments.fundingType,
      package: payments.package.id,
      payers: this.unifyPayers(payments.payers),
      rebate,
    };

    this.setState({ tableLoading: true });

    this.props.fundingCalculate(
      this.cancelToken,
      payload,
      ({ status, data }) => {
        if (status === 200) {
          change("payments.rebate", rebate);
          change("payments.payments", data.data.payments);
        }
        this.setState({ tableLoading: false });
      },
    );

    this.closeChangeRebateAlert();
  };

  declineChangeRebate = () => {
    const { rebate } = this.props.payments;
    this.props.change("payments.rebate", rebate);
    this.closeChangeRebateAlert();
  };

  handleUserAddressPopup = (isModalShow, isUserHaveAddress) => {
    this.setState({
      userAddressModalIsOpen: isModalShow,
      hasAddress: isUserHaveAddress,
    });
  };

  additionalFee = () => {
    this.setState({
      showAdditionalFeeForm: true,
    });
  };

  closePopup = type => {
    const { fetchPayments, contractId } = this.props;
    this.setState(
      {
        [type]: false,
        tableLoading: true,
      },
      () => {
        fetchPayments(this.cancelToken, contractId, () => {
          this.setState({
            tableLoading: false,
          });
        });
      },
    );
  };

  closeAdditionalFeeForm = () => {
    this.setState({
      showAdditionalFeeForm: false,
    });
  };
  handleCloseAdditionalFee = data => {
    const { change } = this.props;
    if (data && data.payments) {
      change("payments.payments", data.payments);
    }
    this.closeAdditionalFeeForm();
  };

  unifyPayers = payers => {
    const { payments } = this.props;

    return payerMapper(payers, payments.currency);
  };

  paymentScheduleReload = () => {
    const { temporaryPackageId } = this.state;
    const {
      change,
      contract,
      values: { payments, type },
      fundingCalculate,
    } = this.props;

    const choosedPackage =
      typeof payments.package === "object"
        ? payments.package.id
        : payments.package;

    const payload = {
      contractId: contract.contract.id,
      fundingType: payments.fundingType,
      type,
      package: choosedPackage,
      payers: this.unifyPayers(payments.payers),
      rebate: payments.rebate,
    };

    if (temporaryPackageId) {
      payload.package = temporaryPackageId;
    }

    this.setState({ tableLoading: true });
    fundingCalculate(this.cancelToken, payload, ({ status, data }) => {
      if (status === HttpStatus.OK) {
        change("payments.payments", data.data.payments);
        change(
          "payments.paymentPackageMismatch",
          data.data.paymentPackageMismatch,
        );
      }
      this.setState({ tableLoading: false });
    });

    getContractAmounts(this.cancelToken, contract.contract.id, data => {
      this.setState({
        tableLoading: false,
        contractAmounts: data.data,
      });
    });
  };

  updatePayments = newPayments => {
    const { change } = this.props;
    change("payments.payments", newPayments);
  };

  setSpecialOffer = specialOffer => {
    const { change } = this.props;
    change("contract.specialOfferId", specialOffer.id);
  };

  clearSpecialOffer = () => {
    const { change } = this.props;
    change("contract.specialOfferId", null);
  };

  getPriceByPackageId = () => {
    const {
      values: { payments },
    } = this.props;

    if (_.has(payments, "package") && _.has(payments, "productPrices")) {
      const priceForPackage = getPriceForPackage(
        payments.package,
        payments.productPrices,
      );

      return _.get(priceForPackage, "price.amount", 0);
    }
    return 0;
  };

  getProductModules = finishReason => {
    const { fetchProductModulesSimple, contract } = this.props;
    const productId = _.get(contract, "course.productVersion.id");
    if (productId && finishReason !== RESIGN_CODE) {
      fetchProductModulesSimple(this.cancelToken, productId);
    }
  };

  generatePayULink = async () => {
    const { createOrder, contract, fetchContract } = this.props;
    const payload = {
      contractId: contract.contract.id,
    };

    await createOrder(payload);
    fetchContract(this.cancelToken, payload);
  };

  render() {
    const {
      companies,
      fundingCalculate,
      contractId,
      change,
      values: { contract, course, payments, student, type },
      values,
      dictionaries,
      contract: {
        preworkShareDate,
        contract: { finishReason, status, isSlaveable, slaves, order },
      },
      t,
      submitting,
      priceGrossCalculator,
      coursesList: { coursesArray },
      fetchCompanies,
      untouch,
      invalid,
      originalContractStatus,
      getContract,
      contractAmounts,
      specialOffers,
    } = this.props;
    const { userAddressModalIsOpen, payerData, submitStatus } = this.state;
    const {
      contractStatus,
      contractFinishReasons,
      resignReasons,
      transferReasons,
      fundingTypes,
      packages: contractPackages,
      taxRates,
      newPaymentTypes,
      statusTransitions,
      resignModules,
      contractDocTypes,
    } = dictionaries ? dictionaries : [];
    const { contract: initialContract } = this.props;
    const isLoading =
      !contract ||
      !student ||
      !payments ||
      !course ||
      !contractStatus ||
      !contractStatus.length ||
      !contractFinishReasons ||
      !fundingTypes ||
      !contract.status;

    const visiblePayments =
      this.props.payments && this.props.payments.payments
        ? this.modifyPayments(this.props.payments.payments).filter(
            payment => payment.isVisible,
          )
        : [];
    const finishReasonId =
      finishReason && finishReason.id ? finishReason.id : finishReason;
    const contractStatusId = status && status.id ? status.id : status;
    const shouldShowStudentBalanceButton = _.has(
      this.props,
      "payments.student.balance.id",
    );
    const shouldShowPayerBalanceButton = _.has(
      this.props,
      "payments.payer.balance.id",
    );

    const contractAmountGross = _.get(contractAmounts, "amountGross");

    return isLoading ? (
      <Loader />
    ) : (
      <div>
        <CustomModal
          isOpen={this.state.showAdditionalFeeForm}
          title={t("Insert additional fee")}
          onRequestClose={() => {
            this.setState({ showAdditionalFeeForm: false });
          }}
          ariaHideApp={false}
        >
          <ContractAdditionalFee
            newPaymentTypes={newPaymentTypes}
            currency={payments.currency}
            contractId={contractId}
            close={this.handleCloseAdditionalFee}
            payers={this.props.payments.payers}
          />
        </CustomModal>
        <CustomModal
          isOpen={userAddressModalIsOpen}
          title={t("User's address is not filled, please fill data")}
          onRequestClose={() => {
            this.setState({ userAddressModalIsOpen: false });
          }}
          ariaHideApp={false}
        >
          <ContractAddressPopup
            studentId={student.person.id}
            isPopupVisible={this.handleUserAddressPopup}
            onSubmit={this.props.handleSubmit(this.onSubmit)}
          />
        </CustomModal>

        <ConfirmModal
          ariaHideApp={false}
          isOpen={this.state.showChangeRebateAlert}
          title={t("Calculate rebate")}
          onRequestClose={this.declineChangeRebate}
          config={{
            body: (
              <div>
                <p className={`change-rebate--alert`}>
                  {t("After edit rebate the payments are calculate again!")}
                </p>
                <p>{`${t("Change rebate to")}: ${this.state.rebate} (%)`}</p>
              </div>
            ),
            onRequestConfirm: this.acceptChangeRebate,
          }}
        />

        <ConfirmModal
          isOpen={this.state.showChangePackageConfirmation}
          title={t("Are You sure You want to change the package?")}
          onRequestClose={() =>
            this.setState({ showChangePackageConfirmation: false })
          }
          ariaHideApp={false}
          config={{
            body: <p>{t("You will lose the current table changes")}.</p>,
            onRequestConfirm: this.paymentScheduleReload,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showChangeFundingConfirmation}
          title={t("Are You sure You want to change the financing type?")}
          onRequestClose={() =>
            this.setState({ showChangeFundingConfirmation: false })
          }
          config={{
            body: <p>{t("You will lose the current table changes")}.</p>,
            onRequestConfirm: () => {
              const { temporaryFundingType } = this.state;
              this.setState({ tableLoading: true });
              if (temporaryFundingType.code === FUNDING_TYPES.INDEPENDENT) {
                delete values.payments.payer;
                this.setState({ showPayerForm: false });
              }
              fundingCalculate(
                this.cancelToken,
                {
                  contractId: contract.contract.id,
                  fundingType: temporaryFundingType.id,
                  type: payments.type,
                  package: payments.package.id || values.payments.package,
                  payer:
                    (values.payments.payer && values.payments.payer.id) || null,
                  payers: this.unifyPayers(payments.payers),
                },
                ({ data }) => {
                  change("payments.payments", data.data.payments);
                  this.setState({
                    tableLoading: false,
                  });
                },
              );
            },
          }}
        />
        <div className="row agreementForm">
          <SideModal
            shouldRender={this.state.renderModal}
            toggleSideModal={this.toggleSideModal}
            position="right"
          >
            <StudentHistory contractId={contractId} />
          </SideModal>
          <div>
            <ContractStatus
              cantEditStatus={
                !havePermissions([permissions.STUDENT_CONTRACT_STATUS_EDIT])
              }
              cantEditFinishStatus={
                !havePermissions([
                  permissions.STUDENT_CONTRACT_FINISH_STATUS_EDIT,
                ])
              }
              statusChanged={(statusChange = true) => {
                this.setState({
                  statusChange,
                });
              }}
              statusTransitions={statusTransitions}
              change={this.props.change}
              productModules={resignModules}
              courseSignature={course.signature}
              coursesArray={coursesArray}
              contractStatus={contractStatus}
              contractFinishReasons={contractFinishReasons}
              contractResignReasons={resignReasons}
              contractTransferReasons={transferReasons}
              preworkShareDate={preworkShareDate}
              contractStatusId={contractStatusId}
              contractFinishReason={finishReasonId}
              paymentsPaymentStatus={payments && payments.paymentStatus}
              tempContractStatusId={contract.status}
              searchCourse={this.searchCourse}
              tempContractFinishReason={contract.finishReason}
              toggleSideModal={this.toggleSideModal}
              loadModules={this.loadModules}
              handleDownload={this.handleDownload}
              fetchProductModules={this.getProductModules}
              untouch={untouch}
              getContract={getContract}
              contractId={contract.id}
              contractDocTypes={contractDocTypes}
              contractDocType={values.contractDocType}
              specialOffers={specialOffers}
              setRebate={this.setRebate}
              clearSpecialOffer={this.clearSpecialOffer}
              updatePackage={this.updatePackage}
              contractPackages={contractPackages}
              contractTypes={dictionaries.types}
              contractPayers={payments.payers}
              contractAmountGross={`${convertAmountToPrice(
                contractAmountGross,
              )} ${payments.currency}`}
            />

            <Tenant
              component={
                <div className="col-xs-12 col-sm-4 padding__l0 padding__b20">
                  {originalContractStatus ===
                    CONTRACT_STATUSES.CONTRACT_SEND && (
                    <>
                      <hr />
                      <h2>{t("PayU Order")}</h2>
                      <PayU
                        generatePayULink={this.generatePayULink}
                        order={order}
                      />
                    </>
                  )}
                </div>
              }
              feature={_.get(Features, "payu_order", false)}
            />

            <ContractFunding
              fetchCompanies={fetchCompanies}
              change={this.props.change}
              payers={companies.items}
              contractPayers={payments.payers}
              contractFundingTypes={fundingTypes}
              payments={this.props.payments.payments}
              payerData={payerData}
              contractType={type}
              searchInGus={this.state.searchInGus}
              isDisabledField={this.state.isDisabledField}
              updatePackage={this.updatePackage}
              paymentScheduleReload={this.paymentScheduleReload}
              taxRates={taxRates}
              isPercent={payments.isPercent}
              contractCurrency={payments.currency}
              contractPackage={payments.package.id || values.payments.package}
              formInvalid={invalid}
              priceForPackage={this.getPriceByPackageId()}
              isContractSigned={
                originalContractStatus === CONTRACT_STATUSES.CONTRACT_SIGNED
              }
            />
            <Authorize
              component={
                <div className={`pull-right`}>
                  <button
                    className={`btn btn-regular btn-link btn-with-icon`}
                    onClick={this.paymentScheduleReload}
                    title={t("Payment schedule reset")}
                  >
                    <RotateCcw size={20} />
                  </button>
                </div>
              }
              allows={[permissions.STUDENT_CONTRACT_AMOUNT_EDIT]}
            />
            <Authorize
              component={
                <ContractPayments
                  change={change}
                  submitStatus={submitStatus}
                  contractType={type}
                  fundingType={payments.fundingType}
                  rebate={payments.rebate ? payments.rebate : 0}
                  payments={visiblePayments}
                  tableLoading={this.state.tableLoading}
                  contractStatus={contract.status && contract.status.id}
                  priceGrossCalculator={priceGrossCalculator}
                  contractFinishReason={
                    contract.contractFinishReason || contract.finishReason
                  }
                  contractId={contract.id}
                  contractFinishReasonModule={contract.finishReasonModule}
                  currency={payments.currency}
                  closePopup={this.closePopup}
                  paymentActivate={this.props.paymentActivate}
                  paymentCancel={this.props.paymentCancel}
                  contractStatusId={contractStatusId}
                  paymentPackageMismatch={payments.paymentPackageMismatch}
                  paymentScheduleReload={this.paymentScheduleReload}
                />
              }
              allows={[permissions.STUDENT_CONTRACT_AMOUNT_VIEW]}
            />
            {(checkIfContractFinishReasonIs(
              initialContract.finishReason,
              CONTRACT_FINISH_REASONS.ABSOLUTORY,
            ) ||
              !initialContract.finishReason) && (
              <div id="student-contract-actions" className="row">
                <div className="col-xs-12">
                  <Authorize
                    component={
                      <button
                        id={`saveContractButton`}
                        type="submit"
                        className="btn btn-dark"
                        onClick={this.props.handleSubmit(this.onSubmit)}
                        disabled={
                          payments.paymentPackageMismatch ||
                          submitting ||
                          this.state.tableLoading
                        }
                      >
                        {" "}
                        {t("Submit")}{" "}
                      </button>
                    }
                    allows={[permissions.STUDENT_CONTRACT_EDIT]}
                  />
                  <Authorize
                    component={
                      <button
                        type="button"
                        onClick={this.props.reset}
                        className="btn btn-link btn-regular"
                      >
                        {t("Clear changes")}
                      </button>
                    }
                    allows={[permissions.STUDENT_CONTRACT_EDIT]}
                  />
                  <Authorize
                    component={
                      <button
                        type="button"
                        onClick={this.additionalFee}
                        className="btn btn-dark pull-right"
                      >
                        {t("Additional fee")}
                      </button>
                    }
                    allows={[permissions.STUDENT_CONTRACT_PAYMENTS_ADD]}
                  />
                  {shouldShowStudentBalanceButton && (
                    <Link
                      className="btn btn-dark pull-right mr-1"
                      to={`/balance/${this.props.payments.student.balance.id} `}
                    >
                      {t("Add student's income")}
                    </Link>
                  )}
                  {shouldShowPayerBalanceButton && (
                    <Link
                      className="btn btn-dark pull-right mr-1"
                      to={`/balance/${this.props.payments.payer.balance.id}`}
                    >
                      {t("Add third parties income")}
                    </Link>
                  )}
                </div>
                {isSlaveable && (
                  <Slaves
                    slaves={slaves}
                    contractId={contractId}
                    productId={course.productVersion.id}
                    updatePayments={this.updatePayments}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(StudentContractForm);
