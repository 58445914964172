import React from "react";
import { RenderError, renderFieldsHelper } from "./forms";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import InputNumber from "rc-input-number";

const PriceField = props => {
  const { label, input, t, under, disabled, precision = 2 } = props;
  const { classes, displayError, shouldDisabled, error } = renderFieldsHelper(
    props,
  );
  return (
    <div className={`form-group row ${classes}`}>
      {label && (
        <div className={` ${under ? "col-sm-12" : "col-sm-4 col-md-3"} `}>
          <label htmlFor="">{label}</label>
        </div>
      )}
      <div className={label && !under ? "col-sm-4 col-md-4" : "col-xs-12"}>
        <InputNumber
          {...input}
          defaultValue={0}
          precision={precision}
          value={input.value || 0}
          disabled={shouldDisabled || disabled}
          parser={value => value.replace(" ", "").replace(",", ".")}
          className={`price-field`}
        />
        {displayError ? <RenderError t={t} error={error} /> : ""}
      </div>
    </div>
  );
};

PriceField.propTypes = {
  input: PropTypes.object,
  t: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  under: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string,
  ]),
  precision: PropTypes.number,
};

export default translate()(PriceField);
