import React from "react";
import StatusIconProvider from "./StatusIconProvider";
import { InvoiceSendStatus } from "../../../../Enums/InvoiceSendStatus";
import { InjectedTranslateProps } from "react-i18next/src/props";

type StatusProviderProps = InjectedTranslateProps & {
  status: InvoiceSendStatus;
};

const InvoiceStatus: React.FunctionComponent<StatusProviderProps> = ({
  t,
  status,
}): React.ReactElement => {
  return (
    <div className="invoice-status-with-icon">
      <div className="invoice-status-icon">
        <StatusIconProvider status={status} />
      </div>
      <span>{t(status)}</span>
    </div>
  );
};

export default InvoiceStatus;
