import moment from "moment";
import COMMON from "../Enums/Common";

export default () => {
  const currentMonth = {
    id: moment().format("Y-MM"),
    label: moment().format("MMMM Y"),
  };

  const prevMonth = {
    id: moment()
      .subtract(1, "month")
      .format("Y-MM"),
    label: moment()
      .subtract(1, "month")
      .format("MMMM Y"),
  };

  return moment().format("D") <= COMMON.LAST_DAY_FOR_PREV_MONTH_INVOICES
    ? [currentMonth, prevMonth]
    : [currentMonth];
};
