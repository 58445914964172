import React from "react";
import { RenderTextArea } from "../../../../Forms/InputComponents/TextArea";
import CustomModal from "../../../../Common/Components/CustomModal/CustomModal";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { Form, Formik, Field } from "formik";

type RemoveInvoiceModalProps = InjectedTranslateProps & {
  isOpen: boolean;
  handleDeleteInvoice: (values: { comments?: string }) => void;
  handleCancelDeleteInvoice: () => void;
};

const RemoveInvoiceModal: React.FunctionComponent<RemoveInvoiceModalProps> = ({
  t,
  isOpen,
  handleDeleteInvoice,
  handleCancelDeleteInvoice,
}): React.ReactElement => {
  return (
    <CustomModal
      isOpen={!!isOpen}
      title={t("Remove an invoice")}
      onRequestClose={handleCancelDeleteInvoice}
    >
      <Formik initialValues={{}} onSubmit={handleDeleteInvoice}>
        {formProps => (
          <Form>
            <Field
              label={t("Comments")}
              under
              required
              name="comments"
              component={RenderTextArea}
            />
            <div className={"text-right"}>
              <button
                className={"btn margin__r15"}
                onClick={handleCancelDeleteInvoice}
              >
                {t("Cancel")}
              </button>
              <button
                disabled={!formProps.values["comments"]}
                className={"btn btn-dark"}
                type="submit"
              >
                {t("Remove")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default RemoveInvoiceModal;
