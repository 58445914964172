import PAYMENT_METHODS from "../Enums/PaymentMethods";

export default t => ({
  data: Object.keys(PAYMENT_METHODS).map(key => ({
    name: t(PAYMENT_METHODS[key].name),
    value: PAYMENT_METHODS[key].value,
  })),
  textField: "name",
  valueField: "value",
});
