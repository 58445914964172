import React from "react";
import EditButton from "../../../Common/Components/EditButton/EditButton";
import PropTypes from "prop-types";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

const EditDictionaryField = props => (
  <EditButton
    {...props}
    editable={havePermissions([permissions.DICTIONARY_EDIT])}
  />
);

EditButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  component: PropTypes.element,
};

export default EditDictionaryField;
