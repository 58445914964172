import React, { Component } from "react";
import Loader from "../../../Common/Components/Loader/Loader";
import {
  RenderText,
  RenderSelect,
  RenderColorPicker,
} from "../../../Forms/forms";
import ReactTable from "react-table";
import { Field } from "redux-form";
import { translate } from "react-i18next";
import CitySelectWithPagination from "../../../Forms/CitySelectWithPagination";
import getCancelToken from "../../../Helpers/GetCancelToken";
import getNestedObject from "../../../Helpers/GetNestedObject";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import PropTypes from "prop-types";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import ACTION_TYPES from "../../../Enums/ActionTypes";

class RoomsDictionary extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
      rooms: PropTypes.arrayOf(
        PropTypes.shape({
          comments: PropTypes.string,
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
          address: PropTypes.shape({
            city: PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              teach: PropTypes.bool.isRequired,
            }).isRequired,
            country: PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            street: PropTypes.string.isRequired,
            zipCode: PropTypes.string.isRequired,
          }),
        }).isRequired,
      ).isRequired,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        comments: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        active: PropTypes.bool.isRequired,
        address: PropTypes.shape({
          city: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            teach: PropTypes.bool,
          }),
          country: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }),
          id: PropTypes.number,
          street: PropTypes.string,
          zipCode: PropTypes.string,
        }),
      }).isRequired,
    ),
    dictionaries: PropTypes.shape({
      cities: PropTypes.arrayOf(
        PropTypes.shape({
          active: PropTypes.bool.isRequired,
          country: PropTypes.number.isRequired,
          events: PropTypes.array,
          id: PropTypes.number.isRequired,
          leads: PropTypes.array,
          name: PropTypes.string.isRequired,
          teach: PropTypes.bool.isRequired,
        }),
      ),
    }),
    fetchDictionary: PropTypes.func.isRequired,
    updateDictionary: PropTypes.func.isRequired,
    createDictionaryItem: PropTypes.func.isRequired,
    initialize: PropTypes.func,
    change: PropTypes.func,
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchDictionary } = this.props;
    this.formActions = {
      SAVE: "SAVE",
      CREATE: "CREATE",
    };
    this.state = {
      editing: [],
      roomsLoading: true,
      isColorPickerOpen: null,
    };
    const { FETCH_ROOMS, FETCH_CITIES } = ACTION_TYPES;

    fetchDictionary(
      this.cancelToken,
      {
        path: "/rooms",
        actionType: FETCH_ROOMS,
      },
      () => {
        this.setState({ roomsLoading: false });
      },
    );
    fetchDictionary(this.cancelToken, {
      path: "/cities",
      actionType: FETCH_CITIES,
      params: { onlyTeach: true },
    });
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, values } = this.props;
    if (
      prevProps.initialValues &&
      prevProps.initialValues.rooms &&
      initialValues &&
      initialValues.rooms &&
      initialValues.rooms !== prevProps.initialValues.rooms
    ) {
      initialize(initialValues);
    } else if (
      prevProps.values &&
      prevProps.values.rooms &&
      values &&
      values.rooms &&
      values.rooms !== prevProps.values.rooms
    ) {
      initialize(values);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;
    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }
  updateRoomCity = (value, row) => {
    if (value && value.id) {
      this.props.change(`rooms[${row.index}][${row.column.id}]`, value);
    }
  };
  renderSelectCityField = row => {
    const {
      values,
      fetchDictionary,
      dictionaries: { cities },
    } = this.props;
    const { editing } = this.state;
    const rowData = row.original;
    const city = values[row.index].address.city
      ? values[row.index].address.city
      : {};

    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(rowData.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              name={`rooms[${row.index}][address.city.name]`}
              component={CitySelectWithPagination}
              under={{
                fieldClassName: "col-12",
                containerClassName: "",
              }}
              props={{
                cities,
                fetchDictionary,
                value: city.name,
                onChangeHandler: value => {
                  this.updateRoomCity(value, row);
                },
              }}
            />
          </div>
        ) : (
          values[row.index].id && (
            <EditDictionaryField
              onClick={() => this.enableEditMode(row)}
              text={city && city.name}
            />
          )
        )}
      </div>
    );
  };
  onSubmit = values => {
    const { rowId, action } = this.state;
    const roomValues = values.rooms.find(room => room.id === rowId);
    const { createDictionaryItem, updateDictionary } = this.props;
    const { CREATE_NEW_ROOM } = ACTION_TYPES;
    switch (action) {
      case this.formActions.SAVE:
        updateDictionary(
          `/rooms/${roomValues.id}`,
          this.prepareValues(roomValues),
          () => {
            this.refreshData(rowId);
          },
        );
        break;
      case this.formActions.CREATE:
        createDictionaryItem(
          "/rooms",
          CREATE_NEW_ROOM,
          this.prepareValues(roomValues),
          () => {
            this.refreshData(rowId);
          },
        );
        break;
    }
  };
  refreshData(rowId) {
    const { editing } = this.state;
    const { fetchDictionary } = this.props;
    const { FETCH_ROOMS } = ACTION_TYPES;

    fetchDictionary(
      this.cancelToken,
      {
        path: "/rooms",
        actionType: FETCH_ROOMS,
      },
      () => {
        const rowIndex = editing.indexOf(rowId);
        rowIndex > -1 && editing.splice(rowIndex, 1);
        this.setState({
          editing,
          roomsLoading: false,
        });
      },
    );
  }
  prepareValues(values) {
    const DEFAULT_HEX_COLOR = "#ffffff";
    const {
      address: { street, city, zipCode },
      active,
      comments,
      name,
      hexColor,
    } = values;
    return {
      addrStreet: street,
      addrZipCode: zipCode,
      addrCity: city.id,
      comments,
      active,
      name,
      hexColor: hexColor ? hexColor : DEFAULT_HEX_COLOR,
    };
  }
  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    this.setState(
      {
        action,
        rowId,
      },
      () => {
        const submitter = handleSubmit(this.onSubmit);
        submitter();
      },
    );
  };
  renderStatusField = row => {
    const { values, t } = this.props;
    const city = row.original;
    return (
      <div className="editField">
        {!values[row.index].id || this.state.editing.indexOf(city.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              name={`rooms[${row.index}][${row.column.id}]`}
              component={RenderSelect}
              dropdownConfig={{
                data: [
                  {
                    value: true,
                    name: t("ActiveRoom"),
                  },
                  {
                    value: false,
                    name: t("InactiveRoom"),
                  },
                ],
                textField: "name",
                valueField: "value",
              }}
            />
          </div>
        ) : (
          values[row.index].id && (
            <EditDictionaryField
              onClick={() => this.enableEditMode(row)}
              text={
                city && city.active === true
                  ? t("ActiveRoom")
                  : t("InactiveRoom")
              }
            />
          )
        )}
      </div>
    );
  };
  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);

    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`rooms[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  onColorChange = (props, name) => {
    const { change } = this.props;
    change(`${name}`, props.hex);
    this.setState({
      isColorPickerOpen: null,
    });
  };

  renderColorPicker = row => {
    const { values } = this.props;
    const { isColorPickerOpen } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    const name = `rooms[${row.index}][${row.column.id}]`;
    return (
      <div className="editField">
        {!values[row.index].id || isColorPickerOpen === row.index ? (
          <div className="dictionary-text__input dictionary-text__picker-cell--active">
            <Field
              name={name}
              component={RenderColorPicker}
              colorPickerConfig={{
                onChange: data => this.onColorChange(data, name),
                triangle: "hide",
                color: value,
              }}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() =>
              this.setState({ isColorPickerOpen: row.index }, () =>
                this.enableEditMode(row),
              )
            }
            component={
              <div
                className="dictionary-text__color-picker"
                style={{
                  background: `${value}` || "#ffffff",
                }}
                id="colorPicker"
              />
            }
          />
        )}
      </div>
    );
  };

  addRoom = () => {
    const { change, values } = this.props;
    const rooms = [
      {
        name: null,
        address: {
          city: null,
          street: null,
          zipCode: null,
        },
        comments: null,
        active: false,
      },
      ...values,
    ];
    change("rooms", rooms);
  };

  render() {
    const { values, t } = this.props;
    const { roomsLoading } = this.state;
    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={values.length > 0 && !values[values.length - 1].id}
                className="btn bottom-space btn btn-dark"
                onClick={() => {
                  this.addRoom();
                }}
              >
                {t("Add room")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={roomsLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("NameItem"),
                accessor: "name",
                Cell: this.renderEditField,
              },
              {
                Header: t("Address"),
                accessor: "address.street",
                Cell: this.renderEditField,
              },
              {
                Header: t("Zip code"),
                accessor: "address.zipCode",
                Cell: this.renderEditField,
              },
              {
                Header: t("City"),
                accessor: "address.city",
                Cell: this.renderSelectCityField,
              },
              {
                Header: t("Comments"),
                accessor: "comments",
                Cell: this.renderEditField,
              },
              {
                Header: t("Color"),
                accessor: "hexColor",
                Cell: this.renderColorPicker,
              },
              {
                Header: t("Status"),
                accessor: "active",
                Cell: this.renderStatusField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(this.formActions.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(this.formActions.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default translate()(RoomsDictionary);
