import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "redux-form";
import { translate } from "react-i18next";
import {
  RenderCheckbox,
  RenderDateTime,
  RenderMultiselect,
  RenderSelect,
  RenderText,
} from "../../../Forms/forms";
import TimePickerInput from "../../../Forms/TimePickerInput";
import * as HttpStatus from "http-status-codes";
import { timeInStringToMinuts } from "../../../Common/Utils/DateFormatters";
import SelectLecturerForEvent from "../../Courses/Components/Settings/SelectLecturerForEvent";
import getCancelToken from "../../../Helpers/GetCancelToken";

const COUNTER_FOR_PAGING = 20;

class NewEventForm extends Component {
  cancelToken = getCancelToken();

  state = {
    fetchingLecturers: false,
    searchLecturerQuery: "",
  };

  componentDidMount() {
    const { change, isProduct, fetchEmployees } = this.props;
    change("isProduct", isProduct);
    if (!isProduct) {
      fetchEmployees(
        this.cancelToken,
        this.getRequestConfig("", COUNTER_FOR_PAGING),
      );
    }
  }

  onSubmit = async formData => {
    const {
      addProductEvent,
      versionId,
      reset,
      getEvents,
      isProduct,
    } = this.props;
    const participants = formData.participants.map(
      participant => participant.id,
    );

    const lecturer = isProduct
      ? null
      : formData.lecturer && formData.lecturer.personId;

    const duration = timeInStringToMinuts(formData.duration);
    const roomRequired = !!formData.roomRequired;
    const payload = {
      ...formData,
      participants,
      duration,
      lecturer,
      roomRequired,
    };

    const response = await addProductEvent(versionId, payload);

    if (response.payload.status === HttpStatus.OK) {
      getEvents();
      reset();
    }
  };

  getRequestConfig = (search = null, counter = null) => {
    const START_PAGE = 1;

    const config = {
      simpleResponse: true,
    };

    if (counter) {
      config.pageNo = START_PAGE;
      config.pageAmount = counter;
    }

    if (search) {
      config.q = search;
    }
    return config;
  };

  handleSearchLecturer = query => {
    const { fetchEmployees } = this.props;

    this.setState(
      {
        fetchingLecturers: true,
        searchLecturerQuery: query,
      },
      () => {
        const { searchLecturerQuery } = this.state;
        const realSearchLecturerQuery = searchLecturerQuery
          ? searchLecturerQuery
          : null;

        fetchEmployees(
          this.cancelToken,
          this.getRequestConfig(realSearchLecturerQuery, COUNTER_FOR_PAGING),
          () => this.setState({ fetchingLecturers: false }),
        );
      },
    );
  };

  setLecturer = lecturer => {
    const { change } = this.props;

    if (lecturer.length === 0) {
      change("lecturer", null);
    } else {
      const [currentLecturer] = lecturer;
      change("lecturer", currentLecturer);
    }
  };

  onShowMoreLecturersResults = counter => {
    const counterForPaging = counter + COUNTER_FOR_PAGING;
    const { fetchEmployees } = this.props;
    const { searchLecturerQuery } = this.state;

    fetchEmployees(
      this.cancelToken,
      this.getRequestConfig(searchLecturerQuery, counterForPaging),
      () => this.setState({ fetchingLecturers: false }),
    );
  };

  render() {
    const {
      t,
      handleSubmit,
      types,
      courseModes,
      participants,
      isLoading,
      isProduct,
      rooms,
      lecturers,
      values,
    } = this.props;

    const { fetchingLecturers } = this.state;

    return (
      <div>
        <h4 className="padding__t20">{t("Add new event")}</h4>
        <Form id="new-event-for-product" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-xs-2">
              <Field
                name="name"
                label={t("Title")}
                under
                component={RenderText}
              />
            </div>

            <div className="col-xs-2">
              <Field
                name="type"
                label={t("Type")}
                under
                component={RenderSelect}
                dropdownConfig={{
                  data: types,
                  textField: "name",
                  valueField: "id",
                }}
              />
            </div>
            {isProduct ? (
              <div className="col-xs-2 padding__l30">
                <Field
                  name="courseMode"
                  label={t("Mode")}
                  under
                  component={RenderSelect}
                  dropdownConfig={{
                    data: courseModes,
                    textField: "name",
                    valueField: "id",
                  }}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="col-xs-2 padding__l30">
                  <Field
                    component={SelectLecturerForEvent}
                    label={t("Lecturer")}
                    name="lecturer"
                    fetchData={this.handleSearchLecturer}
                    lecturers={lecturers}
                    setLecturer={this.setLecturer}
                    selected={!!values.lecturer ? [values.lecturer] : []}
                    placeholder={t("Select")}
                    onShowMoreResults={this.onShowMoreLecturersResults}
                    isLoading={fetchingLecturers}
                    under
                  />
                </div>

                <div className="col-xs-2 padding__l30">
                  <Field
                    name="room"
                    label={t("Room")}
                    under
                    component={RenderSelect}
                    className="courses-room-select"
                    dropdownConfig={{
                      data: rooms,
                      textField: "name",
                      valueField: "id",
                    }}
                  />
                </div>

                <div className="col-xs-2 padding__l30">
                  <Field
                    name="date"
                    label={t("Date")}
                    component={RenderDateTime}
                    time={false}
                    under
                  />
                </div>

                <div className="col-xs-1 padding__l30 margin__r15 clear-both">
                  <Field
                    name="settled"
                    label={t("Settlement")}
                    under
                    component={RenderCheckbox}
                  />
                </div>
              </React.Fragment>
            )}

            <div className="col-xs-2 form-group flex">
              <div className="row">
                <div className="col-xs-6 padding__r0">
                  <label>{t("Start time")}</label>
                  <Field name="startTime" component={TimePickerInput} />
                </div>

                <div className="col-xs-6">
                  <label>{t("Duration")}</label>
                  <Field name="duration" under component={TimePickerInput} />
                </div>
              </div>
            </div>

            <div className="col-xs-2">
              <Field
                name="participants"
                label={t("Participants")}
                under
                component={RenderMultiselect}
                dropdownConfig={{
                  valueField: "id",
                  textField: "name",
                  placeholder: t("Choose participants"),
                  data: participants,
                }}
              />
            </div>

            <div className="col-xs-1">
              <Field
                name="roomRequired"
                label={t("Room required")}
                under
                component={RenderCheckbox}
              />
            </div>

            <div className="col-xs-1 margin__t25 text-right">
              <button
                onClick={handleSubmit(this.onSubmit)}
                type="button"
                id="new-event-for-product-button"
                className="btn btn-dark"
                disabled={isLoading}
              >
                {t("Add")}
              </button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

NewEventForm.propTypes = {
  t: PropTypes.func,
  courseModes: PropTypes.array,
  participants: PropTypes.array,
  types: PropTypes.array,
  rooms: PropTypes.array,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  addProductEvent: PropTypes.func,
  getEvents: PropTypes.func,
  change: PropTypes.func,
  fetchEmployees: PropTypes.func,
  versionId: PropTypes.string,
  isLoading: PropTypes.bool,
  isProduct: PropTypes.bool,
  lecturers: PropTypes.array,
  values: PropTypes.object,
};

export default translate()(NewEventForm);
