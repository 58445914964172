import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RefundForm from "../Components/RefundForm";
import validate from "../Validators/AddRefundValidator";
import { priceGrossCalculator } from "../../Products/Actions";
import { addRefund } from "../Actions";

const FORM_NAME = "BALANCE_REFUND";

const BalanceRefund = props => <RefundForm {...props} />;

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const { invoices } = state;

  const values = selector(
    state,
    "description",
    "correction",
    "currency",
    "amountGross",
    "invoiceId",
  );

  return { values, invoices };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      priceGrossCalculator,
      addRefund,
    },
    dispatch,
  );

export default reduxForm({
  form: FORM_NAME,
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BalanceRefund),
);
