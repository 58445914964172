import { TenantValidator } from "../../../Common/Utils/Tenant";

export default fields => {
  const errors = {};
  const { name, taxNr, taxRate, regonNr, address } = fields;

  if (!name) {
    errors.name = "Enter the name";
  }

  if (!taxNr) {
    errors.taxNr = "Enter the tax ID";
  }

  if (taxRate === null || taxRate === "" || taxRate === undefined) {
    errors.taxRate = "You must select tax rate";
  }

  if (taxNr && !TenantValidator.isTaxIDValid(taxNr)) {
    errors.taxNr = "Incorrect tax ID";
  }

  if (!regonNr) {
    errors.regonNr = "Enter the company ID";
  }

  if (regonNr && !TenantValidator.isCompanyIdValid(regonNr)) {
    errors.regonNr = "Incorrect company ID";
  }

  if (address) {
    if (!address.street) {
      errors.address = {
        ...errors.address,
        street: "Give the street name",
      };
    }

    if (!address.zipCode) {
      errors.address = {
        ...errors.address,
        zipCode: "Give the postal code",
      };
    }

    if (!address.city || !address.city.name) {
      errors.address = {
        ...errors.address,
        city: { name: "Choose the city" },
      };
    }

    if (!address.country) {
      errors.address = {
        ...errors.address,
        country: { id: "Choose a country" },
      };
    }

    if (!address.country) {
      errors.address = {
        ...errors.address,
        country: { name: "Choose a country" },
      };
    }
  } else {
    errors.address = {
      street: "Give the street name",
      zipCode: "Give the postal code",
      city: { name: "Choose the city" },
      country: { id: "Choose a country", name: "Choose a country" },
    };
  }

  return errors;
};
