import { ProductModule, ProductModuleForApi } from "../Types/ProductModule";
import { CourseMode } from "../../../Types/DictionaryItem";
import { timeInStringToMinuts } from "../../../Common/Utils/DateFormatters";

export const getModuleModelForApi = (
  module: ProductModule,
  courseModes: Array<CourseMode>,
): ProductModuleForApi => {
  const brakesArray = [];

  Object.keys(module.breaks).forEach(key => {
    const curseMode = courseModes.find(mode => mode.mode === key);
    if (curseMode && curseMode.id && module.breaks[key]) {
      brakesArray.push({
        courseMode: curseMode.id,
        duration: module.breaks[key],
      });
    }
  });

  return {
    ...module,
    type: module.type.id,
    installment: module.installment.id,
    breaks: brakesArray,
    duration: timeInStringToMinuts(module.duration),
    durationPerStudent: timeInStringToMinuts(module.durationPerStudent),
    prices: module.prices.map(modulePrice => ({
      ...modulePrice,
      package: modulePrice.package.id,
    })),
  };
};
