import actionsTypes from "../../../Enums/ActionTypes";

const { FETCH_CITIES, FETCH_COUNTRIES } = actionsTypes;

const citesDictionary = {
  path: "/cities",
  actionType: FETCH_CITIES,
  params: { onlyTeach: true },
};

const countriesDictionary = {
  path: "/countries",
  actionType: FETCH_COUNTRIES,
};

export const getDataForDetailsFromApi = (props, cancelToken) => {
  const {
    fetchMeta,
    fetchDictionary,
    fetchCompany,
    companyId,
    initialize,
    isNew,
    fetchContacts,
  } = props;

  if (!isNew) {
    fetchCompany(cancelToken, companyId, ({ data }) => {
      initialize(data.data);
    });
    fetchContacts(cancelToken, companyId);
  }

  fetchDictionary(cancelToken, citesDictionary);
  fetchDictionary(cancelToken, countriesDictionary);
  fetchMeta(cancelToken);
};
