import React, { Component } from "react";
import { Field } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import PropTypes from "prop-types";

class CityAutoComplete extends Component {
  static propTypes = {
    name: PropTypes.string,
    change: PropTypes.func,
    label: PropTypes.string,
    nameToShow: PropTypes.string,
    shouldDisabled: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.fillInAddress = this.fillInAddress.bind(this);
    this.autocomplete = null;

    this.state = { place: {} };
  }

  componentDidMount() {
    let autocompleteElement = document.querySelector(
      `input[name="${this.props.name}"]`,
    );
    if (autocompleteElement) {
      this.autocomplete = new google.maps.places.Autocomplete(
        autocompleteElement,
        { types: ["geocode"] },
      );
      autocompleteElement.setAttribute("placeholder", "");
      this.autocomplete.addListener("place_changed", this.fillInAddress);
    }
  }

  fillInAddress() {
    const place = this.autocomplete.getPlace();

    const { name, change } = this.props;
    change(name, place.name);
    this.setState({ place });
  }

  render() {
    const { name, label, nameToShow, shouldDisabled } = this.props;
    return (
      <Field
        name={name}
        component={RenderText}
        label={label}
        value={this.state.place.name}
        nameToShow={nameToShow ? nameToShow : null}
        shouldDisabled={shouldDisabled}
      />
    );
  }
}

export default CityAutoComplete;
