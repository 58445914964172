import React, { Component } from "react";
import getCancelToken from "../../../../Helpers/GetCancelToken";
import MainTemplateContent from "../../../App/Containers/MainTemplateContent";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import CoursePollTableRow from "./CoursePollTableRow";
import Loader from "../../../../Common/Components/Loader/Loader";

class CoursePollTable extends Component {
  static propTypes = {
    t: PropTypes.func,
    match: PropTypes.object,
    fetchCoursePollResults: PropTypes.func,
    coursePollResults: PropTypes.array,
    getCourse: PropTypes.func,
    currentCourse: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {
      match: {
        params: { courseId },
      },
      getCourse,
    } = props;
    this.cancelToken = getCancelToken();
    getCourse(this.cancelToken, courseId);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props.fetchCoursePollResults(
      this.cancelToken,
      {
        courseId: this.props.match.params.courseId,
        type: "general",
      },
      () => {
        this.setState({ isLoading: false });
      },
    );
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  getSubrows = (typeId, moduleNr) => {
    this.props.fetchCoursePollResults(this.cancelToken, {
      courseId: this.props.match.params.courseId,
      type: "detailed",
      typeId,
      moduleNr,
    });
  };

  render() {
    const {
      match: {
        params: { courseId },
      },
      t,
      coursePollResults,
      currentCourse,
    } = this.props;

    const secondaryMenu = [
      { name: t("Settings"), link: `/courses/${courseId}/settings` },
      { name: t("Students"), link: `/courses/${courseId}/students` },
      {
        name: t("Surveys"),
        link: `/courses/${courseId}/polls`,
        position: "right",
      },
    ];
    return this.state.isLoading ? (
      <Loader />
    ) : (
      <MainTemplateContent
        secondaryMenu={secondaryMenu}
        background={true}
        title={t("Surveys")}
        subtitle={currentCourse && currentCourse.signature}
      >
        <div className="course-poll">
          <table className="course-poll__table">
            <PollResultHead
              theads={
                coursePollResults.length > 0 && coursePollResults[0].topicGrades
                  ? coursePollResults[0].topicGrades.map(item => item.name)
                  : []
              }
              t={t}
            />
            <PollResultBody
              data={coursePollResults}
              getSubrows={this.getSubrows}
            />
          </table>
        </div>
      </MainTemplateContent>
    );
  }
}

const PollResultBody = ({ data, getSubrows }) => {
  return (
    data && (
      <tbody>
        {data.map(element => (
          <CoursePollTableRow
            key={element.typeName}
            {...element}
            getSubrows={getSubrows}
          />
        ))}
      </tbody>
    )
  );
};

const PollResultHead = ({ theads, t }) => {
  return (
    <thead>
      <tr className="course-poll__row">
        <th />
        <th>{t("avbl date")}</th>
        <th>{t("module")}</th>
        {theads && theads.map(thead => <th key={thead}>{thead}</th>)}
        <th>{t("filled")}</th>
      </tr>
    </thead>
  );
};

PollResultHead.propTypes = {
  theads: PropTypes.array,
  t: PropTypes.func,
};

PollResultBody.propTypes = {
  data: PropTypes.array,
};

export default translate()(CoursePollTable);
