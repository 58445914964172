import React, { Component } from "react";
import { translate } from "react-i18next";
import CoursesSettingsSchedule from "./CoursesSettingsSchedule";
import getCancelToken from "../../../Helpers/GetCancelToken";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import EditLectureModal from "../Containers/EditLectureModal";
import Tabs from "../../../Common/Components/Tabs/Tabs";
import PARTICIPANTS from "../../../Enums/Participants";
import { DropdownList } from "react-widgets";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import * as HttpStatus from "http-status-codes";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import CourseCanceled from "./CourseCanceled";
import {
  CourseCalendarProps,
  CourseCalendarState,
} from "../Types/CourseCalendarComponent";

const ALL_PARTICIPANTS_INDEX = 0;
const CALENDAR_STATUS_DRAFT = "draft";

class CourseCalendar extends Component<
  CourseCalendarProps,
  CourseCalendarState
> {
  state = {
    currentLectureEdit: null,
    freeLecturesAndRoomsForUnit: null,
    isLastUnit: false,
    activeTab: ALL_PARTICIPANTS_INDEX,
    packageValue: null,
    courseCalendar: null,
    resetScheduleAlertShow: false,
  };

  cancelToken = getCancelToken();

  componentDidMount() {
    const { fetchPackagesDictionary } = this.props;
    fetchPackagesDictionary(this.cancelToken, this.initPackageSelect);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  initPackageSelect = ({ data }) => {
    if (data && data.data) {
      const [firstPackage] = data.data;
      this.onChangePackage(firstPackage);
    }
  };

  onRowEdit = rowData => {
    const { unitId } = rowData;
    if (unitId < 0) {
      return;
    }
    const {
      courseCalendar: { lectures },
      fetchFreeLecturesAndRooms,
    } = this.props;

    const isLastUnit =
      lectures.findIndex(lecture => lecture.unitId === unitId) ===
      lectures.length - 1;

    fetchFreeLecturesAndRooms(this.cancelToken, unitId, ({ status, data }) => {
      if (status === HttpStatus.OK) {
        this.setState({
          freeLecturesAndRoomsForUnit: data.data,
          currentLectureEdit: rowData,
          isLastUnit,
        });
      }
    });
  };

  closeEditRowModal = () =>
    this.setState({
      currentLectureEdit: null,
      freeLecturesAndRoomsForUnit: null,
      isLastUnit: false,
    });

  getTabsConfig = () => {
    const { t } = this.props;
    return [
      {
        name: t("All"),
        id: ALL_PARTICIPANTS_INDEX,
        visible: true,
      },
      {
        name: t("Student"),
        id: PARTICIPANTS.student.id,
        visible: true,
      },
      {
        name: `${t("Lecturer")} / ${t("Mentor")} `,
        id: PARTICIPANTS.lecturer.id,
        visible: true,
      },
    ];
  };

  onChangeTab = tabId => this.setState({ activeTab: tabId });

  onPublishCalendar = () => {
    const {
      publishCalendar,
      fetchCourse,
      courseId,
      courseCalendar: { id },
      refreshCurrentCourse,
    } = this.props;
    publishCalendar(id, () => {
      fetchCourse(this.cancelToken, courseId, data => {
        if (data.status === HttpStatus.OK) {
          refreshCurrentCourse(data);
        }
      });
    });
  };

  onResetCalendar = () => {
    const { resetCalendar, courseId, refreshCurrentCourse } = this.props;
    resetCalendar(Number(courseId), refreshCurrentCourse);
  };

  getFilteredLectures = lectures => {
    const { activeTab } = this.state;

    return lectures.filter(lecture => {
      switch (activeTab) {
        case ALL_PARTICIPANTS_INDEX:
          return true;
        case PARTICIPANTS.student.id:
          return !!lecture.participants
            ? lecture.participants.find(
                participant => participant.id === PARTICIPANTS.student.id,
              )
            : false;

        case PARTICIPANTS.lecturer.id:
          return !!lecture.participants
            ? lecture.participants.find(
                participant =>
                  participant.id === PARTICIPANTS.lecturer.id ||
                  participant.id === PARTICIPANTS.mentor.id,
              )
            : false;
      }
    });
  };

  onChangePackage = packageObj =>
    this.setState({ packageValue: packageObj.id });

  getScheduleXls = () => {
    const { t, getSchedule, courseId, courseSignature } = this.props;
    const { packageValue } = this.state;
    getSchedule({
      courseId: Number(courseId),
      format: "xls",
      fileName: `${t("Timetable")}_${courseSignature}`,
      packageId: packageValue,
    });
  };

  getSchedulePdf = () => {
    const { t, getSchedule, courseId, courseSignature } = this.props;
    const { packageValue } = this.state;
    getSchedule({
      courseId: Number(courseId),
      format: "pdf",
      fileName: `${t("Timetable")}_${courseSignature}`,
      packageId: packageValue,
    });
  };

  onDraftCancel = () => {
    const {
      cancelDraft,
      fetchCourse,
      courseId,
      courseCalendar: { id },
      refreshCurrentCourse,
    } = this.props;
    cancelDraft(id, () => {
      fetchCourse(this.cancelToken, courseId, data => {
        if (data.status === HttpStatus.OK) {
          refreshCurrentCourse(data);
        }
      });
    });
  };

  openResetScheduleAlert = () =>
    this.setState({ resetScheduleAlertShow: true });

  closeResetScheduleAlert = () =>
    this.setState({ resetScheduleAlertShow: false });

  render() {
    const {
      t,
      courseCalendar,
      isLoading,
      packages,
      isCancel,
      isEnd,
    } = this.props;
    const {
      currentLectureEdit,
      freeLecturesAndRoomsForUnit,
      isLastUnit,
      activeTab,
      packageValue,
      resetScheduleAlertShow,
    } = this.state;

    return (
      <div>
        {!!currentLectureEdit && !!freeLecturesAndRoomsForUnit && (
          <CustomModal
            isOpen={!!currentLectureEdit}
            title={t("Change schedule")}
            onRequestClose={this.closeEditRowModal}
            ariaHideApp={false}
          >
            <EditLectureModal
              lecture={currentLectureEdit}
              freeLecturesAndRooms={freeLecturesAndRoomsForUnit}
              onClose={this.closeEditRowModal}
              disabled={isEnd}
              isLastUnit={isLastUnit}
            />
          </CustomModal>
        )}

        <div className="padding__b20 row">
          <div className="col-xs-12 col-md-9">
            <h4>{t("Timetable")}</h4>
            <Tabs
              activeTab={activeTab}
              onChangeTab={this.onChangeTab}
              config={this.getTabsConfig()}
            />
          </div>
          <div className="col-xs-12 col-md-3 padding__t20">
            <div className="row">
              <div className="col-xs-9">
                <DropdownList
                  data={packages}
                  textField="name"
                  valueField="id"
                  onChange={this.onChangePackage}
                  value={packageValue}
                />
              </div>

              <div className="horizontal-center col-xs-3" id="downloadSchedule">
                <div onClick={this.getScheduleXls}>
                  <CustomIcon
                    icon="xls"
                    color="#4d4d4f"
                    size="40px"
                    viewBox="0 0 512 512"
                  />
                </div>
                <div onClick={this.getSchedulePdf}>
                  <CustomIcon
                    icon="pdf"
                    color="#4d4d4f"
                    size="40px"
                    viewBox="0 0 512 512"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CourseCanceled isCancel={isCancel}>
          <CoursesSettingsSchedule
            onRowEdit={this.onRowEdit}
            lectures={this.getFilteredLectures(courseCalendar.lectures)}
            isLoading={isLoading}
          />
        </CourseCanceled>

        <div className="col-xs-offset-8 col-xs-4 padding__b20">
          <div id="course-schedule-actions" className="row">
            <div className="col-md-7 col-sm-12 text-left">
              {courseCalendar.status &&
                courseCalendar.status.code === CALENDAR_STATUS_DRAFT && (
                  <div>
                    <button
                      onClick={this.onPublishCalendar}
                      className="btn btn-dark margin__r15"
                    >
                      {t("Publish")}
                    </button>

                    <button
                      onClick={this.onDraftCancel}
                      className="btn btn-dark"
                    >
                      {t("Cancel draft")}
                    </button>
                  </div>
                )}
            </div>
            <div className="col-md-5 col-sm-12 text-right">
              <button
                onClick={this.openResetScheduleAlert}
                disabled={isCancel || isEnd}
                className="btn btn-danger btn-reset-schedule"
              >
                {t("Reset Schedule")}
              </button>
            </div>
          </div>
        </div>
        <ConfirmModal
          isOpen={resetScheduleAlertShow}
          title={t("Reset schedule")}
          onRequestClose={this.closeResetScheduleAlert}
          config={{
            body: <p>{t("You will lose the current table changes")}</p>,
            onRequestConfirm: this.onResetCalendar,
          }}
        />
      </div>
    );
  }
}

export default translate()(CourseCalendar);
