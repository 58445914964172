export default {
  pl: {
    name: "pl",
    code: ["pl-PL", "pl"],
  },
  en: {
    name: "en",
    code: ["en-US", "en-GB", "en"],
  },
  de: {
    name: "de",
    code: ["de-DE", "de"],
  },
};
