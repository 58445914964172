import React from "react";
import { store } from "../../Configs/Root";
import { connect } from "react-redux";
import _ from "lodash";

export const Authorize = connect(mapStateToProps)(
  ({ component, allows, userPermissions: { permissions }, hasAll }) =>
    havePermissions(allows, permissions, hasAll ? hasAll : false)
      ? component
      : null,
);

export function havePermissions(allows, permissions = null, hasAll = false) {
  const userPermissions = permissions
    ? permissions
    : store.getState().userPermissions.permissions;

  return hasAll
    ? _.difference(allows, userPermissions).length === 0
    : allows.some(allow => userPermissions.includes(allow));
}

export const RouteAuthorize = (allows, hasAll = false) => WrappedComponent =>
  class WithAuthorization extends React.Component {
    render() {
      return (
        <Authorize
          component={<WrappedComponent {...this.props} />}
          allows={allows}
          hasAll={hasAll}
        />
      );
    }
  };

function mapStateToProps({ userPermissions }) {
  return { userPermissions };
}

export const permissions = {
  PRODUCT_DEACTIVATION: "product_deactivation",
  PRODUCT_ACTIVATION: "product_activation",
  PRODUCT_COPY: "product_copy",
  PRODUCT_DETAILS_EDIT: "product_edition",
  PRODUCT_DETAILS_VIEW: "product_view",
  PRODUCT_LIST: "product_list",
  PRODUCT_POLL_EDIT: "product_poll_edit",
  PRODUCT_VERSION_DELETE: "product_version_delete",

  COMPANY_VIEW: "company_view",
  COMPANY_EDIT: "company_edit",

  COURSE_CANCEL: "course_cancel",
  COURSE_LIST: "course_list",
  COURSE_SEARCH: "course_search",
  COURSE_DETAILS_VIEW: "course_details_view",
  COURSE_DETAILS_EDIT: "course_details_edit",
  COURSE_NUMBER_VIEW: "course_number_view",
  COURSE_NUMBER_EDIT: "course_number_edit",
  COURSE_STUDENT_LIST: "course_student_list",
  COURSE_STUDENT_ADD: "course_student_add",
  COURSE_POSTPONE_COUNTER_VIEW: "course_postpone_counter_view",
  COURSE_POSTPONE_COUNTER_EDIT: "course_postpone_counter_edit",
  COURSE_RESET_SCHEDULE: "course_reset_schedule",

  STUDENT_LIST_ALL: "student_list_all",

  REPORT_INVOICES_XLS: "report_invoices_xls",
  REPORT_EXTENDED_XLS: "report_extended_xls",
  REPORT_FINANCE_XLS: "report_finance_xls",
  REPORT_TIMELINE: "report_timeline",
  REPORT_LECTURER_SETTLEMENT: "report_lecturer_settlement",
  REPORT_TREND: "report_trend",
  REPORT_GROUPFILL: "report_groupfill",
  REPORT_GRADUATES: "report_graduates",
  REPORT_BANK_TRANSFERS: "report_bank_transfers",
  REPORT_BANK_TRANSFERS_TRANSFERS: "report_bank_transfers_transfers",
  REPORT_REFUNDS: "report_refunds",
  REPORT_TIME_TABLE: "report_time_table",
  REPORT_CONTRACT_PAYMENTS: "report_contract_payments",
  REPORT_BALANCE: "report_balance",
  REPORT_COURSE_DAYS_AND_GROUPS: "report_course_days_and_groups",
  REPORT_MONTHLY_CONTRACTS: "monthly_contracts_report",

  COMMENT_COURSE_EDIT: "comment_course_edit",
  COMMENT_TIMELINE_DAY_EDIT: "comment_timeline_day_edit",
  REPORT_TIMELINE_STATS: "report_timeline_stats",

  PERSON_LIST: "person_list",
  PERSON_SEARCH: "person_search",
  PERSON_DETAILS_VIEW: "person_details_view",
  PERSON_DETAILS_EDIT: "person_details_edit",
  PERSON_EMP_DETAILS_VIEW: "person_emp_details_view",
  PERSON_EMP_DETAILS_EDIT: "person_emp_details_edit",
  PERSON_COURSE_LIST: "person_course_list",

  ROLE_MANAGMENT: "role_management",

  STUDENT_CONTRACT_VIEW: "student_contract_view",
  STUDENT_CONTRACT_EDIT: "student_contract_edit",
  STUDENT_CONTRACT_STATUS_VIEW: "student_contract_status_view",
  STUDENT_CONTRACT_STATUS_EDIT: "student_contract_status_edit",
  STUDENT_CONTRACT_FINISH_STATUS_VIEW: "student_contract_finish_status_view",
  STUDENT_CONTRACT_FINISH_STATUS_EDIT: "student_contract_finish_status_edit",
  STUDENT_CONTRACT_BANK_ACCOUNT_VIEW: "student_contract_bank_account_view",
  STUDENT_CONTRACT_BANK_ACCOUNT_EDIT: "student_contract_bank_account_edit",
  STUDENT_CONTRACT_REBATE_VIEW: "student_contract_rebate_view",
  STUDENT_CONTRACT_REBATE_EDIT: "student_contract_rebate_edit",
  STUDENT_CONTRACT_PACKAGE_VIEW: "student_contract_package_view",
  STUDENT_CONTRACT_PACKAGE_EDIT: "student_contract_package_edit",
  STUDENT_CONTRACT_PAYER_VIEW: "student_contract_payer_view",
  STUDENT_CONTRACT_PAYER_EDIT: "student_contract_payer_edit",
  STUDENT_CONTRACT_AMOUNT_VIEW: "student_contract_amount_view",
  STUDENT_CONTRACT_AMOUNT_EDIT: "student_contract_amount_edit",
  STUDENT_CONTRACT_INVOICE_VIEW: "student_contract_invoice_view",
  STUDENT_CONTRACT_SPECIAL_PAYMENTS_EDIT_ON_FINISHED:
    "student_contract_special_payments_edit_on_finished",
  STUDENT_HISTORY: "student_history",
  IMPORT_LECTURES: "import_lectures",
  STUDENT_CONTRACT_MANUAL_INCOME: "student_contract_manual_income",
  STUDENT_CONTRACT_PAYMENTS_ADD: "student_contract_payments_add",

  DICTIONARY_VIEW: "dictionary_view",
  DICTIONARY_EDIT: "dictionary_edit",

  DEFAULT: "default",

  REFUND_ADD: "refund_add",

  AUTOINVOICE_MANAGEMENT: "autoinvoice_management",
  INCOME_AUTOINVOICE: "income_autoinvoice",

  CORRECTION_MANAGEMENT: "correction_management",

  UPDATE_INCOME: "update_income",
  INVOICE_ADDRESS_UPDATE: "invoice_address_update",

  CHANGE_PASSWORD: "change_password",

  QUESTIONNAIRE_ANSWERS_VIEW: "questionnaire_answers_view",
};

export function getReportLinkForPermissions() {
  const url = "/reports/";
  const userPermissions = store.getState().userPermissions.permissions;
  if (userPermissions.includes(permissions.REPORT_TREND)) {
    return url + "contracts";
  }
  if (userPermissions.includes(permissions.REPORT_TIMELINE)) {
    return url + "timeline";
  }
  if (userPermissions.includes(permissions.REPORT_GROUPFILL)) {
    return url + "groups";
  }
  if (userPermissions.includes(permissions.REPORT_GRADUATES)) {
    return url + "graduates";
  }
  if (userPermissions.includes(permissions.REPORT_INVOICES_XLS)) {
    return url + "invoices";
  }
  return url;
}
