import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import { Field } from "redux-form";
import {
  RenderSelect,
  RenderMultiselect,
  RenderDateTime,
} from "../../../Forms/forms";
import PropTypes from "prop-types";
import getCancelToken from "../../../Helpers/GetCancelToken";
import Loader from "../../../Common/Components/Loader/Loader";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import GraduatesTable from "./GraduatesTable";
import { Search } from "react-feather";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import _ from "lodash";
import GraduatesStudyFilter from "../Utils/GraduatesStudyFilter";
import Tooltip from "react-simple-tooltip";
import { Info } from "react-feather/dist/index";

const PAGE_AMOUNT = 100;

class GraduatesForm extends Component {
  static propTypes = {
    onlyTeachCities: PropTypes.array,
    productsListForCourse: PropTypes.array,
    change: PropTypes.func,
    addContractComment: PropTypes.func,
    fetchDictComments: PropTypes.func,
    dictComments: PropTypes.array,
    t: PropTypes.func,
    values: PropTypes.object,
    fetchDictionaryOnlyTeach: PropTypes.func,
    fetchReportGraduates: PropTypes.func,
    downloadReportGraduates: PropTypes.func,
    sendNps: PropTypes.func,
    groupDetailsData: PropTypes.object,
    reportGraduates: PropTypes.object,
    fetchAllProductsFullInfo: PropTypes.func,
    allProductsFullInfo: PropTypes.array,
  };
  constructor(props) {
    super(props);
    const {
      fetchDictionaryOnlyTeach,
      fetchReportGraduates,
      fetchAllProductsFullInfo,
      fetchDictComments,
    } = props;
    this.state = {
      currentPage: 1,
      isLoading: true,
      sortBy: null,
      sortAscending: true,
      downloadDisabled: false,
    };
    this.cancelToken = getCancelToken();
    fetchDictComments();
    fetchDictionaryOnlyTeach(this.cancelToken);
    fetchAllProductsFullInfo(this.cancelToken);
    fetchReportGraduates(
      this.cancelToken,
      {
        pageAmount: PAGE_AMOUNT,
        pageNo: 1,
      },
      () => this.setState({ isLoading: false }),
    );
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.values, prevProps.values)) {
      this.searchCourse();
    }
  }

  searchCourse = _.debounce(() => {
    this.changePage(1);
  }, 500);

  sendNpsHandler = async npsId => {
    const { sendNps } = this.props;
    const { currentPage } = this.state;
    await sendNps(npsId);
    this.changePage(currentPage);
  };

  addCommentHandler = async (contractId, commentId) => {
    const { addContractComment } = this.props;
    const { currentPage } = this.state;
    await addContractComment(contractId, commentId);
    this.changePage(currentPage);
  };

  handleSort = sortBy => {
    const { sortAscending } = this.state;
    this.setState(
      {
        sortBy,
        sortAscending:
          this.state.sortBy === sortBy ? !sortAscending : sortAscending,
      },
      () => {
        this.changePage(1);
      },
    );
  };

  getDataToRequest = () => {
    const { values } = this.props;
    const { sortBy, sortAscending } = this.state;
    const productIdArray =
      values.productId && values.productId.map(product => product.productId);
    return {
      sortBy,
      sortOrder: sortAscending ? "asc" : "desc",
      ...values,
      from: values.from ? values.from : null,
      to: values.to ? values.to : null,
      productId: productIdArray,
    };
  };

  changePage = currentPage => {
    this.setState(
      {
        currentPage,
        isLoading: true,
      },
      () => {
        this.props.fetchReportGraduates(
          this.cancelToken,
          {
            pageNo: currentPage,
            pageAmount: PAGE_AMOUNT,
            ...this.getDataToRequest(),
          },
          () => this.setState({ isLoading: false }),
        );
      },
    );
  };

  downloadReport = () => {
    this.setState(
      {
        downloadDisabled: true,
      },
      () => {
        this.props.downloadReportGraduates(this.getDataToRequest(), () => {
          this.setState({ downloadDisabled: false });
        });
      },
    );
  };

  render() {
    const {
      t,
      onlyTeachCities,
      change,
      reportGraduates,
      allProductsFullInfo,
      dictComments,
      values,
    } = this.props;

    const graduatesStudyFilterOptions = [
      {
        id: GraduatesStudyFilter.ALL,
        name: this.props.t("All"),
      },
      {
        id: GraduatesStudyFilter.NOT_FILLED,
        name: this.props.t("Not filled"),
      },
      {
        id: GraduatesStudyFilter.FILLED,
        name: this.props.t("Filled"),
      },
    ];
    return (
      <Fragment>
        <div className="row vertical-center">
          <div className="form-group col-md-3 col-xs-6">
            <div className="input-group ">
              <div className="input-group-addon">
                <Search size={16} />
              </div>
              <Field
                className="form-control"
                name="search"
                component="input"
                type="text"
              />
            </div>
          </div>
          <Field
            component={RenderSelect}
            name="cityId"
            dropdownConfig={{
              data: onlyTeachCities,
              textField: "name",
              valueField: "id",
              placeholder: t("Cities"),
            }}
            under={{
              containerClassName: "col-md-3 col-xs-6",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("cityId", null)}
          />
          <Field
            component={RenderMultiselect}
            name="productId"
            dropdownConfig={{
              data: allProductsFullInfo.map(product => ({
                ...product,
                productId: product.id,
                productRenderName: `${product.shortcut} ${product.name}`,
              })),
              textField: "productRenderName",
              valueField: "productId",
              placeholder: t("Products"),
            }}
            under={{
              containerClassName: "col-md-4 col-xs-6",
              fieldClassName: "col-xs-12",
            }}
          />

          <div className={"col-xs-2 text-center form-group"}>
            <label>
              {t("Date range")}
              <Tooltip padding={5} content={t("Courses end dates")}>
                &nbsp;
                <Info size={16} />
              </Tooltip>
            </label>
          </div>
          <Field
            name="from"
            component={RenderDateTime}
            under={{
              containerClassName:
                "col-md-3 col-md-offset-0 col-sm-6 col-sm-offset-0",
              fieldClassName: "col-sm-12 col-md-12",
            }}
            time={false}
            onClearCallback={() => change("from", null)}
          />
          <Field
            name="to"
            component={RenderDateTime}
            min={values.from}
            under={{
              containerClassName:
                "col-md-3 col-md-offset-0 col-sm-6 col-sm-offset-0",
              fieldClassName: "col-sm-12 col-md-12",
            }}
            time={false}
            onClearCallback={() => change("to", null)}
          />
          <Field
            component={RenderSelect}
            name="graduateStudy"
            dropdownConfig={{
              data: graduatesStudyFilterOptions,
              textField: "name",
              valueField: "id",
              placeholder: t("Graduate poll"),
            }}
            under={{
              containerClassName: "col-md-3 col-xs-6",
              fieldClassName: "col-xs-12",
            }}
            onClearCallback={() => change("graduateStudy", null)}
          />

          <div className="col-md-2 col-xs-6" id="groupFillDownload">
            <button
              className="btn btn-default btn-link"
              onClick={this.downloadReport}
              disabled={this.state.downloadDisabled}
            >
              <CustomIcon
                icon="xls"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
                className="pull-right"
              />
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 group-fill-table">
            {this.state.isLoading ? (
              <Loader />
            ) : (
              <GraduatesTable
                data={reportGraduates.elements}
                onClickHeading={this.handleSort}
                addCommentHandler={this.addCommentHandler}
                dictComments={dictComments}
                sendNpsHandler={this.sendNpsHandler}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {reportGraduates.counter ? (
              <Paginator
                onClick={this.changePage}
                pages={Math.ceil(reportGraduates.counter / PAGE_AMOUNT)}
                currentPage={this.state.currentPage}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default translate()(GraduatesForm);
