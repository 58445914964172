import React from "react";
import PropTypes from "prop-types";
import CONTRACTS_LIST_SORT_OPTIONS from "../../../../Enums/ContractsListSortOptions";
import { DropdownList } from "react-widgets";
import { translate } from "react-i18next";
import { XCircle } from "react-feather";

const Sorts = ({ t, onSortChange, value }) => {
  const sortOptions = CONTRACTS_LIST_SORT_OPTIONS.map(option => ({
    ...option,
    name: t(option.name),
  }));

  return (
    <div id="contracts-sort" className="row ">
      <div className="col-xs-2 padding__l0">
        <div className="form__clear form-group">
          <DropdownList
            data={sortOptions}
            textField={"name"}
            valueField={"value"}
            value={value}
            placeholder={`${t("Sort by")}:`}
            onChange={data => onSortChange(data.value)}
          />
          <div
            onClick={() => onSortChange(null)}
            className="form__clear-button"
          >
            <XCircle size={15} />
          </div>
        </div>
      </div>
    </div>
  );
};

Sorts.propTypes = {
  t: PropTypes.func,
  onSortChange: PropTypes.func,
  value: PropTypes.object,
};

export default translate()(Sorts);
