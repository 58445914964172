import React, { Fragment, useEffect, useState } from "react";
import { Field, Form } from "redux-form";
import {
  RenderCheckbox,
  RenderNumber,
  RenderSelect,
  RenderText,
  RenderTextArea,
} from "../../../Forms/forms";
import AmountField from "../../../Forms/AmountField";
import PriceField from "../../../Forms/PriceField";
import { get } from "lodash";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import { withRouter } from "react-router";
import { getProductPayload } from "../Utils/ProductObjectMapper";
import { X } from "react-feather";
import { ProductFormProps } from "../Types/ProductFormComponent";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import TotalPrices from "./TotalPrices";
import { AmountWithCurrency } from "../Types/CurrentProductVersionType";

const ProductForm: React.FunctionComponent<ProductFormProps> = ({
  values,
  initialize,
  initialValues,
  change,
  submitting,
  handleSubmit,
  saveProductVersion,
  updateProductVersion,
  changeProductVersionStatus,
  t,
  coursesKinds,
  languages,
  currencies,
  employees,
  currentStatus,
  cantEdit,
  isNew,
  history,
  pristine,
  fetchVersionsOfProductHandler,
  makeProductCopy,
  deleteProductVersion,
  currentProductVersions,
  fetchProduct,
  hasActiveVersion,
}): React.ReactElement => {
  const [
    activeStatusConfirmationVisible,
    setActiveStatusConfirmationVisible,
  ] = useState(false);
  useEffect(() => {
    initialize(initialValues);
  }, [initialValues]);

  const removeVersion = "removeVersion";
  const clearChanges = "clearChanges";
  const quitWithoutSaving = "quitWithoutSaving";

  const modalHeaders = {
    removeVersion: t("Are You sure, You want to remove this version"),
    quitWithoutSaving: t(
      "Are You sure, You want to leave without saving the changes?",
    ),
    clearChanges: t("Are You sure, You want to clear the changes?"),
  };

  const modalBody = {
    removeVersion: t(
      "After removing this version, there will be no possibility of getting it back",
    ),
    quitWithoutSaving: t(
      "After erasing the changes, there will be no possibility of getting them back",
    ),
    clearChanges: t(
      "After erasing the changes, there will be no possibility of getting them back",
    ),
  };

  const modalOnConfirm = {
    removeVersion: () => {
      removeProductVersion();
    },
    quitWithoutSaving: () => {
      history.push("/products");
    },
    clearChanges: () => {
      initialize(initialValues);
    },
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalBodyText, setModalBodyText] = useState("");
  const [modalMethod, setModalMethod] = useState("");

  const onSubmit = async values => {
    const payload = getProductPayload(values);

    if (isNew) {
      await saveProductVersion(payload, ({ data }) =>
        history.push(`/products/product/${data.data.versionId}`),
      );
    } else {
      updateProductVersion(payload, values.id);
    }
  };

  const parseEmployees = employees.map(element => {
    const [firstEmail] = element.person.email;
    return {
      id: element.id,
      name: element.person.name,
      surname: element.person.surname,
      email: firstEmail && firstEmail.email,
      isProductOwner: element.isProductOwner,
    };
  });

  const setProductVersionStatus = async (status: number): Promise<void> => {
    await changeProductVersionStatus(values.id, status);
    fetchVersionsOfProductHandler();
    fetchProduct();
  };

  const formDisabled = currentStatus.id !== PRODUCT_STATUSES.DRAFT;

  const getFullNameForSelectLabel = ({ name, surname }) =>
    name && surname ? `${name} ${surname}` : ``;

  const getCurrencyName = () => {
    const { currency } = values;
    return currency && currency.name ? currency.name : "";
  };

  const copyPriceOnBlur = (fromField, toField) => {
    change(toField, get(values, fromField));
  };

  const removeProductVersion = () => {
    deleteProductVersion(values.id, async () => {
      const availableVersions = currentProductVersions.filter(
        version => version.id != values.id,
      );
      if (availableVersions.length === 0) {
        history.push(`/products/`);
      } else {
        await fetchVersionsOfProductHandler();
        const destinationId = availableVersions[0].id;
        history.push(`/products/product/${destinationId}`);
      }
    });
  };

  const employeeConfig = {
    data: parseEmployees,
    textField: getFullNameForSelectLabel,
    valueField: "id",
    filter: "contains",
    onChange: data => change("businessOwner", data),
  };
  const essentialOwnerConfig = {
    ...employeeConfig,
    onChange: data => change("essentialOwner", data),
    data: parseEmployees.filter(employee => employee.isProductOwner),
  };

  const productVersion = currentProductVersions.find(
    productVersion => productVersion.id === values.id,
  );
  const prices: Record<string, AmountWithCurrency> | null = get(
    productVersion,
    "price",
    null,
  );

  return (
    <div>
      <ConfirmModal
        isOpen={showConfirmModal}
        title={modalHeaders[modalHeaderText]}
        onRequestClose={() => setShowConfirmModal(false)}
        config={{
          body: <p>{modalBody[modalBodyText]}</p>,
          onRequestConfirm: modalOnConfirm[modalMethod],
        }}
      />
      <CustomModal
        isOpen={activeStatusConfirmationVisible}
        title={t("This product has active version already")}
        onRequestClose={() => setActiveStatusConfirmationVisible(false)}
      >
        <p>
          {t(
            "If you want to active this version of product You must deactivate current active version.",
          )}
        </p>
      </CustomModal>
      <Form
        className="form new-product col-md-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          label={t("Course 2.0")}
          customLabel={t("Course version")}
          name="course20"
          component={RenderCheckbox}
          shouldDisabled={cantEdit || formDisabled}
        />
        <hr />
        <Field
          label={t("Product type")}
          name="kind"
          dropdownConfig={{
            data: coursesKinds,
            textField: "name",
            valueField: "id",
            onChange: item => change("kind", item),
          }}
          component={RenderSelect}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Product name")}
          name="name"
          component={RenderText}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Shortcut name")}
          name="shortcut"
          component={RenderText}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />

        <Field
          required="required"
          label={t("Currency")}
          name="currency"
          component={RenderSelect}
          disabled={cantEdit || formDisabled}
          status={currentStatus}
          dropdownConfig={{
            data: currencies,
            onChange: item => change("currency", item),
            placeholder: t("Currency"),
            textField: "name",
            valueField: "id",
          }}
        />

        <div className={`form-group row`}>
          <div className={`col-sm-4 col-md-3`} />
          <div className={`col-sm-8 col-md-9`}>
            <div className={`row`}>
              <div className={`col-sm-12 col-md-6`}>
                <label>
                  {t("Student")} ({t("gross")})
                </label>
              </div>
              <div className={`col-sm-12 col-md-6`}>
                <label>
                  {t("Company")} ({t("net")})
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={`form-group row`}>
          <div className={`col-sm-4 col-md-3`}>
            <label>{` ${t(
              "Charge for resignations",
            )} (${getCurrencyName()})`}</label>
          </div>
          <div className={`col-sm-8 col-md-9`}>
            <div className={`row`}>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.resign.student.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={cantEdit || formDisabled}
                  onBlur={() => {
                    copyPriceOnBlur(
                      "fees.resign.student.amount",
                      "fees.resign.thirdParty.amount",
                    );
                  }}
                />
              </div>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.resign.thirdParty.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={formDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`form-group row`}>
          <div className={`col-sm-4 col-md-3`}>
            <label>{` ${t(
              "Charge for transfer",
            )} (${getCurrencyName()})`}</label>
          </div>
          <div className={`col-sm-8 col-md-9`}>
            <div className={`row`}>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.transfer.student.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={cantEdit || formDisabled}
                  onBlur={() => {
                    copyPriceOnBlur(
                      "fees.transfer.student.amount",
                      "fees.transfer.thirdParty.amount",
                    );
                  }}
                />
              </div>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.transfer.thirdParty.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={formDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`form-group row`}>
          <div className={`col-sm-4 col-md-3`}>
            <label>{` ${t(
              "Charge for package change",
            )} (${getCurrencyName()})`}</label>
          </div>
          <div className={`col-sm-8 col-md-9`}>
            <div className={`row`}>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.packageChange.student.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={formDisabled}
                  onBlur={() => {
                    copyPriceOnBlur(
                      "fees.packageChange.student.amount",
                      "fees.packageChange.thirdParty.amount",
                    );
                  }}
                />
              </div>
              <div className={`col-sm-12 col-md-6`}>
                <AmountField
                  name={`fees.packageChange.thirdParty.amount`}
                  component={PriceField}
                  disabled={!getCurrencyName()}
                  required="required"
                  shouldDisabled={cantEdit || formDisabled}
                />
              </div>
            </div>
          </div>
        </div>
        <TotalPrices prices={prices} t={t} currency={getCurrencyName()} />

        <Field
          label={t("Language")}
          name="language"
          dropdownConfig={{
            data: languages,
            textField: "name",
            valueField: "id",
            onChange: item => change("language", item),
          }}
          component={RenderSelect}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Bussines owner")}
          name="businessOwner"
          dropdownConfig={employeeConfig}
          component={RenderSelect}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Essential owner")}
          name="essentialOwner"
          dropdownConfig={essentialOwnerConfig}
          component={RenderSelect}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Thematic scope")}
          name="thematicScope"
          component={RenderText}
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Course summary")}
          name="summary"
          component={RenderTextArea}
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />
        <Field
          label={t("Number of places")}
          name="numberOfPlaces"
          component={RenderNumber}
          required="required"
          status={currentStatus}
          shouldDisabled={cantEdit || formDisabled}
        />

        <Authorize
          component={
            <button
              key="submit"
              className="btn btn-dark"
              disabled={submitting || formDisabled}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
            >
              {t("Save product")}
            </button>
          }
          allows={[permissions.PRODUCT_DETAILS_EDIT]}
        />

        <footer className="">
          <div id="product-form-actions" className="col-xs-12 form__actions">
            {currentStatus.id === PRODUCT_STATUSES.DRAFT && (
              <Fragment>
                <Authorize
                  component={
                    <button
                      key="cancel"
                      className="btn btn-dark"
                      type="button"
                      disabled={pristine || submitting}
                      onClick={() => {
                        setModalHeaderText(quitWithoutSaving);
                        setModalBodyText(quitWithoutSaving);
                        setModalMethod(quitWithoutSaving);
                        setShowConfirmModal(true);
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  }
                  allows={[permissions.PRODUCT_DETAILS_EDIT]}
                />
                <Authorize
                  component={
                    <button
                      key="removeChanges"
                      className="btn btn-link btn-regular"
                      type="button"
                      disabled={pristine || submitting}
                      onClick={() => {
                        setModalHeaderText(clearChanges);
                        setModalBodyText(clearChanges);
                        setModalMethod(clearChanges);
                        setShowConfirmModal(true);
                      }}
                    >
                      {t("Clear changes")}
                    </button>
                  }
                  allows={[permissions.PRODUCT_DETAILS_EDIT]}
                />
              </Fragment>
            )}

            {currentStatus.id === PRODUCT_STATUSES.ACTIVE ? (
              <Authorize
                component={
                  <button
                    className="btn btn-warning"
                    disabled={submitting}
                    type="button"
                    onClick={() =>
                      setProductVersionStatus(PRODUCT_STATUSES.INACTIVE)
                    }
                  >
                    <X size={18} /> {t("Deactivate version")}
                  </button>
                }
                allows={[permissions.PRODUCT_DEACTIVATION]}
              />
            ) : (
              (currentStatus.id === PRODUCT_STATUSES.INACTIVE ||
                currentStatus.id === PRODUCT_STATUSES.DRAFT) && (
                <Authorize
                  component={
                    <button
                      className="btn btn-dark"
                      disabled={submitting}
                      type="button"
                      onClick={() =>
                        hasActiveVersion
                          ? setActiveStatusConfirmationVisible(true)
                          : setProductVersionStatus(PRODUCT_STATUSES.ACTIVE)
                      }
                    >
                      {t("Activate version")}
                    </button>
                  }
                  allows={[permissions.PRODUCT_ACTIVATION]}
                />
              )
            )}
            {!isNew && (
              <Authorize
                component={
                  <button
                    className="btn btn-link btn-regular"
                    disabled={submitting}
                    type="button"
                    onClick={() => makeProductCopy(values.id)}
                  >
                    {t("Copy version")}
                  </button>
                }
                allows={[permissions.PRODUCT_COPY]}
              />
            )}
            {!isNew && currentStatus.id === PRODUCT_STATUSES.DRAFT && (
              <Authorize
                component={
                  <button
                    className="btn btn-link btn-regular"
                    type="button"
                    disabled={submitting}
                    onClick={() => {
                      setModalHeaderText(removeVersion);
                      setModalBodyText(removeVersion);
                      setModalMethod(removeVersion);
                      setShowConfirmModal(true);
                    }}
                  >
                    {t("Remove version")}
                  </button>
                }
                allows={[permissions.PRODUCT_DETAILS_EDIT]}
              />
            )}
          </div>
        </footer>
      </Form>
    </div>
  );
};

export default withRouter(ProductForm);
