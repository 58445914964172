import React from "react";
import PropTypes from "prop-types";
import { HelpCircle, Search } from "react-feather/dist/index";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import common from "../../../Enums/Common";

const Header = ({ translations, logout, searchValue, onChangeSearch }) => {
  return (
    <header className="main-header">
      <div className="row">
        <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6 pull-right main-header__flex">
          <Authorize
            component={
              <div className="col-md-8 col-xs-12">
                <div className="input-group">
                  <div className="input-group-addon">
                    <Search size={16} />
                  </div>
                  <input
                    value={searchValue}
                    onChange={onChangeSearch}
                    className="form-control"
                    name="search"
                    type="text"
                  />
                </div>
              </div>
            }
            allows={[permissions.COMPANY_VIEW]}
          />
          <button onClick={logout} className="btn btn--logout pull-right">
            {translations("Log out")}
          </button>
          <a
            href={common.URL.MANUAL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--logout pull-right left-space"
          >
            <HelpCircle size={16} /> {translations("Help")}
          </a>
        </div>
        <div className="col-sm-4 col-md-6 col-lg-6">
          <h1>{translations("Companies")}</h1>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  translations: PropTypes.func,
  logout: PropTypes.func,
  searchValue: PropTypes.string,
  onChangeSearch: PropTypes.func,
};

export default Header;
