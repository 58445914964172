import { FormikErrors } from "formik";
import { isEmail } from "validator";

export type InvoiceReceiverFormValues = {
  email: string;
  name: string;
  phone: string;
};

const phoneValidRegEx = /^(?=.*[0-9])[- +()0-9]+$/;

export default (fields: InvoiceReceiverFormValues) => {
  const errors: FormikErrors<InvoiceReceiverFormValues> = {};
  const { name, email, phone } = fields;

  if (!name) {
    errors.name = "Enter a valid contact name";
  }

  if (!phone || !phone.match(phoneValidRegEx)) {
    errors.phone = "Enter a valid phone number";
  }

  if (!email || !isEmail(email)) {
    errors.email = "Enter a valid e-mail address formatted name@domain.com";
  }
  return errors;
};
