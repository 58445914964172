import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import _ from "lodash";
import ConfirmModal from "./ConfirmModal";
import InvoicesList from "./InvoicesList";
import InvoicesListActions, { FiltersValues } from "./InvoicesListActions";
import RemoveInvoiceModal from "./RemoveInvoiceModal";
import ReactSelect from "react-select";
import Paginator from "../../../../Common/Components/Paginator/Paginator";
import CustomModal from "../../../../Common/Components/CustomModal/CustomModal";
import CorrectionsEdit from "../../Containers/CorrectionsEdit";
import Loader from "../../../../Common/Components/Loader/Loader";
import getCancelToken from "../../../../Helpers/GetCancelToken";
import { Invoice, SendStatusTransition } from "../Types/Invoice";
import { PageSizeOption, pageSizeOptions } from "../Utils/PageSizeOptions";
import { InvoiceReportProps, InvoiceReportState } from "../Types/InvoiceReport";
import { shouldCheckboxDisabled } from "../Utils/CheckboxDisabledStatus";

class InvoicesReport extends Component<InvoiceReportProps, InvoiceReportState> {
  cancelToken = getCancelToken();
  state = {
    isLoading: true,
    currentPage: 1,
    currentInvoice: null,
    invoiceForDelete: null,
    selectedRows: [],
    confirmModalShow: false,
    pageSize: pageSizeOptions[0],
    filters: {},
  } as InvoiceReportState;

  componentDidMount() {
    const {
      getInvoicePossibleStatus,
      getInvoicePossibleSendStatus,
    } = this.props;
    this.getData(true);
    getInvoicePossibleStatus();
    getInvoicePossibleSendStatus();
  }

  changePage = (currentPage: number): void => {
    this.setState(
      {
        currentPage,
      },
      this.getData,
    );
  };

  changePageSize = (option: PageSizeOption): void => {
    this.setState({ pageSize: option, currentPage: 1 }, () =>
      this.getData(true),
    );
  };

  download = (invoice: Invoice): void => {
    const { downloadInvoice } = this.props;
    downloadInvoice(this.cancelToken, {
      invoiceId: invoice.id,
      invoiceNumber: invoice.nr,
    });
  };

  downloadPackage = (filters: FiltersValues): void => {
    const { downloadInvoicesPackage } = this.props;
    const { startDate, endDate } = filters;

    downloadInvoicesPackage({
      from: startDate,
      to: endDate,
    });
  };

  getFiltersParams = (
    filters: FiltersValues,
    forceFirstPage = false,
  ): Omit<FiltersValues, "status"> & {
    format: string;
    deleted: boolean;
    pageNo: number;
    status?: string;
  } => {
    const { currentPage: page } = this.state;
    const {
      query,
      startDate,
      endDate,
      deletedOnly,
      status,
      sendStatus,
    } = filters;
    this.setState({
      currentPage: forceFirstPage ? 1 : page,
    });

    return {
      query,
      startDate,
      endDate,
      status: status ? status.name : null,
      sendStatus,
      format: "json",
      deleted: !!deletedOnly,
      pageNo: forceFirstPage ? 1 : page,
    };
  };

  getData = (forceFirstPage = false): void => {
    const { fetchInvoices } = this.props;
    const { filters, currentPage, pageSize } = this.state;
    const params = { pageNo: currentPage, pageAmount: pageSize.value };
    fetchInvoices(
      this.cancelToken,
      this.getFiltersParams(filters, forceFirstPage),
      params,
      () => this.setState({ isLoading: false }),
    );
  };
  changeFilter = (filters: FiltersValues): void => {
    this.setState({ filters }, () =>
      _.debounce(() => {
        this.getData(true);
      }, 1000)(),
    );
  };

  closeCorrectionsEditModal = (): void => {
    this.setState({ currentInvoice: null });
    this.getData();
  };

  handleInvoicesXlsDownload = (filters: FiltersValues): void => {
    const { downloadInvoices } = this.props;
    const params = this.getFiltersParams(filters);
    downloadInvoices({ ...params, format: "xls" });
  };

  setInvoiceForDelete = (invoice: Invoice): void => {
    this.setState({ invoiceForDelete: invoice });
  };

  clearInvoiceForDelete = (): void => {
    this.setState({ invoiceForDelete: null });
  };

  deleteInvoice = (values: { comments: string }): void => {
    const { deleteInvoice } = this.props;

    const {
      invoiceForDelete: { id },
    } = this.state;

    deleteInvoice(this.cancelToken, id, { reason: values.comments });

    this.clearInvoiceForDelete();
    this.getData(true);
  };

  onRowSelect = (invoice: Invoice): void => {
    const { selectedRows } = this.state;
    const currentSelectedRows = [...selectedRows];

    const selectRowId = currentSelectedRows.indexOf(invoice.id);
    if (selectRowId > -1) {
      currentSelectedRows.splice(selectRowId, 1);
    } else {
      currentSelectedRows.push(invoice.id);
    }

    this.setState({ selectedRows: currentSelectedRows });
  };

  onColumnSelect = (): void => {
    const { selectedRows } = this.state;
    const {
      invoicesReportResults: { elements: items },
    } = this.props;
    if (selectedRows.length > 0) {
      this.setState({ selectedRows: [] });
    } else {
      const filteredItems = items.filter(item => !shouldCheckboxDisabled(item));
      this.setState({ selectedRows: filteredItems.map(item => item.id) });
    }
  };

  handleCancelSendInvoices = (): void =>
    this.setState({ confirmModalShow: false });

  handleOpenInvoiceConfirmation = (): void => {
    const { selectedRows } = this.state;

    if (selectedRows.length) {
      this.setState({ confirmModalShow: true });
    }
  };
  handleSendInvoices = (): void => {
    const {
      updateInvoiceStatus,
      invoicesReportResults: { elements: items },
    } = this.props;
    const { selectedRows } = this.state;
    const payload = [];
    selectedRows.forEach(row => {
      const invoice = items.find(item => item.id === row);
      if (invoice) {
        const transition = invoice.sendStatusTransitions.find(transition => {
          const [toStatus] = transition.tos;
          return toStatus === "in_send";
        }) as SendStatusTransition;

        payload.push({
          invoiceId: row,
          transition: transition.name,
        });
      }
    });
    updateInvoiceStatus(payload, () => {
      this.getData();
      this.setState({ confirmModalShow: false, selectedRows: [] });
    });
  };

  handleChangeSendStatus = (
    transition: { name: string },
    invoice: Invoice,
  ): void => {
    const { updateInvoiceStatus } = this.props;
    const payload = [
      {
        invoiceId: invoice.id,
        transition: transition.name,
      },
    ];
    this.setState({ isLoading: true });
    updateInvoiceStatus(payload, () => {
      this.getData();
      this.setState({ isLoading: false });
    });
  };

  render() {
    const {
      isLoading,
      currentPage,
      currentInvoice,
      invoiceForDelete,
      selectedRows,
      confirmModalShow,
      pageSize,
    } = this.state;
    const {
      t,
      invoiceStatusPossiblePlaces,
      invoiceSendStatusPossiblePlaces,
      invoicesReportResults: { counter, elements: items },
    } = this.props;
    const perPage = pageSize.value;
    const pages = Math.ceil(counter / perPage);

    return isLoading ? (
      <Loader />
    ) : (
      <Fragment>
        <ConfirmModal
          t={t}
          isOpen={confirmModalShow}
          invoiceCount={selectedRows.length}
          handleClose={this.handleCancelSendInvoices}
          handleSend={this.handleSendInvoices}
        />

        <RemoveInvoiceModal
          t={t}
          isOpen={!!invoiceForDelete}
          handleCancelDeleteInvoice={this.clearInvoiceForDelete}
          handleDeleteInvoice={this.deleteInvoice}
        />

        <section className="form-section">
          <InvoicesListActions
            t={t}
            handleOpenInvoiceConfirmation={this.handleOpenInvoiceConfirmation}
            invoiceStatusPossiblePlaces={invoiceStatusPossiblePlaces}
            invoiceSendStatusPossiblePlaces={invoiceSendStatusPossiblePlaces}
            onChangeFilters={this.changeFilter}
            handleDownloadInvoicesZip={this.downloadPackage}
            handleDownloadInvoicesXls={this.handleInvoicesXlsDownload}
          />
          <InvoicesList
            t={t}
            invoices={items}
            pageSize={perPage}
            handleDownloadInvoice={this.download}
            handleSelectInvoiceForDelete={this.setInvoiceForDelete}
            handleSelectInvoiceForCorrection={invoice =>
              this.setState({ currentInvoice: invoice })
            }
            onRowSelect={this.onRowSelect}
            onColumnSelect={this.onColumnSelect}
            selectedRows={selectedRows}
            onChangeStatus={this.handleChangeSendStatus}
          />
          <div className="row">
            <div id="invoices-container-paginator" className="col-md-10">
              <Paginator
                pages={pages}
                onClick={this.changePage}
                currentPage={currentPage}
              />
              {pages > 1 ? (
                <ReactSelect
                  className="pull-right padding__t20 margin__r15 invoices-report-page-size-select"
                  options={pageSizeOptions}
                  value={pageSize}
                  onChange={this.changePageSize}
                  menuPlacement="auto"
                />
              ) : null}
            </div>
          </div>
        </section>
        <CustomModal
          isOpen={!!currentInvoice}
          title={`${t("Edit corrections")}`}
          onRequestClose={this.closeCorrectionsEditModal}
          ariaHideApp={false}
        >
          <CorrectionsEdit invoice={currentInvoice} />
        </CustomModal>
      </Fragment>
    );
  }
}

export default translate()(InvoicesReport);
