import React, { Component } from "react";
import { Minus, Plus } from "react-feather";
import PropTypes from "prop-types";
import getUniqueIdentifier from "../../../Helpers/GetUniqueIdentifier";

class NestedList extends Component {
  static propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    onListUpdate: PropTypes.func.isRequired,
    shouldDisabled: PropTypes.bool,
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    config: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      checkedItems: props.values ? props.values : [],
      items: this.prepareDataSet(props.dataSource),
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.dataSource, prevProps.dataSource)) {
      this.setState({
        items: this.prepareDataSet(this.props.dataSource),
      });
    }
  }

  setItemDefaultValues = (item, level) => {
    const { config, values } = this.props;
    const childrenKey = config[level]["childrenKey"];
    const levelKey = config[level]["key"];
    item.toogle = !!item.toogle ? item.toogle : false;
    item.checked = !!item.checked ? item.checked : false;
    if (levelKey && values && values.indexOf(item[levelKey]) > -1) {
      item.checked = true;
    }

    item.childrenKey = childrenKey;
    item.key = levelKey;
    item.identifier = getUniqueIdentifier();
    return item;
  };

  prepareDataSet = (dataSource, level = null) => {
    level = level !== null ? level + 1 : 0;
    return dataSource.map(element => {
      element = this.setItemDefaultValues(element, level);
      const { childrenKey } = element;
      if (element[childrenKey] && element[childrenKey].length > 0) {
        element[childrenKey] = this.prepareDataSet(element[childrenKey], level);
      }
      element.checked =
        this.itemIsInValues(element) || this.allChildrensInValues(element);
      return element;
    });
  };

  verifyChildrens = (dataSource, level = null) => {
    level = level !== null ? level + 1 : 0;
    return dataSource.map(element => {
      const { childrenKey, key } = element;
      if (
        element[key] &&
        element[key] > 0 &&
        this.state.checkedItems.indexOf(element[key]) !== -1
      ) {
        element.checked = true;
        return element;
      } else if (
        element[key] &&
        element[key] > 0 &&
        this.state.checkedItems.indexOf(element[key]) === -1
      ) {
        element.checked = false;
        return element;
      }
      if (element[childrenKey] && element[childrenKey].length > 0) {
        element[childrenKey] = this.verifyChildrens(
          element[childrenKey],
          level,
        );
        const everyChildChecked = element[childrenKey].every(
          childItem => childItem.checked === true,
        );
        element.checked = everyChildChecked;
      }
      return element;
    });
  };

  checkChildrenToToogle(children, item) {
    return children.map(child => {
      const { childrenKey } = child;
      if (item.identifier === child.identifier) {
        child.toogle = !child.toogle;
      }
      if (child[childrenKey] && child[childrenKey].length > 0) {
        this.checkChildrenToToogle(child[childrenKey], item);
      }
      return child;
    });
  }

  toogleItem = item => {
    this.setState({
      items: this.state.items.map(element => {
        const { childrenKey } = element;
        if (element.identifier === item.identifier) {
          element.toogle = !element.toogle;
        }
        if (element[childrenKey] && element[childrenKey].length > 0) {
          this.checkChildrenToToogle(element[childrenKey], item);
        }
        return element;
      }),
    });
  };

  getLastLevelItems(item, lastLevelItems) {
    const { childrenKey } = item;
    if (!!item[childrenKey] && item[childrenKey].length > 0) {
      item[childrenKey].forEach(child => {
        this.getLastLevelItems(child, lastLevelItems);
      });
    } else {
      lastLevelItems.push(item);
    }
  }

  checkItem = item => {
    const { items, checkedItems } = this.state;
    const { onListUpdate } = this.props;
    let checkedValues = [...checkedItems];
    let lastLevelItems = [];
    this.getLastLevelItems(item, lastLevelItems);
    if (lastLevelItems.length === 1 && lastLevelItems[0] === item) {
      const { key } = item;
      const itemValue = item[key];
      const itemIndex = checkedValues.indexOf(itemValue);
      if (itemIndex !== -1) {
        checkedValues.splice(itemIndex, 1);
      } else if (itemIndex === -1) {
        checkedValues.push(itemValue);
      }
    } else {
      item.checked = !item.checked;
      lastLevelItems.forEach(lastLevelItem => {
        const { key } = lastLevelItem;
        if (key && lastLevelItem[key] > 0) {
          const itemValue = lastLevelItem[key];
          const itemIndex = checkedValues.indexOf(itemValue);
          if (!item.checked && itemIndex !== -1) {
            checkedValues.splice(itemIndex, 1);
          } else if (item.checked && itemIndex === -1) {
            checkedValues.push(itemValue);
          }
        }
      });
    }
    this.setState(
      {
        checkedItems: _.uniq(checkedValues),
      },
      () => {
        this.setState(
          {
            items: this.verifyChildrens(items),
          },
          () => {
            onListUpdate(checkedValues, this.state.items);
          },
        );
      },
    );
  };

  allChildrensInValues = item => {
    const { childrenKey } = item;
    let allInValues = true;
    if (!childrenKey || !item[childrenKey] || item[childrenKey].length === 0) {
      return false;
    }
    if (childrenKey && item[childrenKey] && item[childrenKey].length > 0) {
      allInValues = item[childrenKey].every(childItem => {
        if (childItem.childrenKey) {
          return this.allChildrensInValues(childItem);
        } else if (childItem.key) {
          return this.itemIsInValues(childItem);
        }
      });
    }
    return allInValues;
  };

  itemIsInValues = item => {
    const { key } = item;
    return (
      key &&
      item[key] > 0 &&
      this.state &&
      this.state.checkedItems &&
      this.state.checkedItems.indexOf(item[key]) !== -1
    );
  };

  renderTree(item, level, isOdd) {
    const { config } = this.props;
    if (
      !config ||
      !config[level] ||
      !config[level]["decorator"] ||
      !config[level]["checkAvailable"]
    ) {
      return "";
    }
    const { childrenKey } = item;
    const decorator = config[level]["decorator"];
    const checkAvailable = config[level]["checkAvailable"];

    return (
      <li className={isOdd ? "odd" : "even"}>
        <div>
          <div className="row nestedList__elementHeader">
            <div
              onClick={() => {
                this.toogleItem(item);
              }}
              className={"col-md-1 nestedList__elementHeader__ico"}
            >
              <div
                className={
                  !item[childrenKey] || item[childrenKey].length === 0
                    ? "hidden"
                    : ""
                }
              >
                {item.toogle ? <Minus size={16} /> : <Plus size={16} />}
              </div>
            </div>
            <div
              onClick={() => {
                this.toogleItem(item);
              }}
              className="col-md-10 nestedList__elementHeader__info"
            >
              {decorator(item)}
            </div>
            <div className={"col-md-1 text-right"}>
              {checkAvailable ? (
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => {
                    this.checkItem(item);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={item.toogle ? "" : "hidden"}>
            {item[childrenKey] &&
              item[childrenKey].length > 0 &&
              this.renderLevel(item[childrenKey], level, isOdd)}
          </div>
        </div>
      </li>
    );
  }

  renderLevel(items, level, isOdd) {
    level++;
    return items.map((item, index) => {
      isOdd = !isOdd;
      return (
        <div key={index} className={`nestedLevel-${level}`}>
          <ul id={`list-${index}`} className="list">
            {this.renderTree(item, level, isOdd)}
          </ul>
        </div>
      );
    });
  }

  render() {
    const { items } = this.state;
    const list = this.renderLevel(items, -1, true);
    return <div className="nestedList">{list}</div>;
  }
}

export default NestedList;
