import { formValueSelector, reduxForm } from "redux-form";
import { bindActionCreators, compose } from "redux";
import validate from "../Validators/ModulesFormValidator";
import ModulesForm from "../Components/ModulesForm";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import {
  clearProductVersionModules,
  createProductModules,
  createProductModulesLectures,
  deleteProductModule,
  fetchLecturesForImport,
  fetchProductModules,
  fetchProductVersionsMeta,
  modulesOrderChange,
  updateProductModules,
} from "../../Products/Actions";
import { ProductModulesFormValues } from "../Types/ProductModulesFormValues";
import ProductStatuses from "../../../Enums/ProductStatuses";
import { ModulesFormProps } from "../Types/ModulesForm";
import { error } from "react-notification-system-redux";

const FORM_NAME = `ProductModulesForm`;
const selector = formValueSelector(FORM_NAME);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductModules,
      createProductModulesLectures,
      createProductModules,
      updateProductModules,
      deleteProductModule,
      fetchProductVersionsMeta,
      fetchLecturesForImport,
      modulesOrderChange,
      clearProductVersionModules,
      error,
    },
    dispatch,
  );

const mapStateToProps = store => {
  const {
    currentProductVersion,
    productModules,
    dictionaries,
    productVersionsMeta,
    courses: { courseModes },
  } = store;
  const { currencies, installments } = dictionaries;

  const values = selector(store, "productModules");

  return {
    currentProductVersion,
    productModules,
    values,
    currencies,
    productVersionsMeta,
    courseModes,
    installments,
    currentStatus:
      currentProductVersion && currentProductVersion.id
        ? currentProductVersion.status
        : { id: ProductStatuses.DRAFT },
  };
};

export default reduxForm<ProductModulesFormValues, ModulesFormProps>({
  validate,
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  )(ModulesForm),
);
