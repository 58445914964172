import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { ChevronRight, Eye } from "react-feather";
import dateHelper from "../../../../Helpers/DateHelper";
import PollGrade from "../../../../Common/Components/PollGrade/PollGrade";

class CoursePollTableRow extends Component {
  static propTypes = {
    allPolls: PropTypes.number,
    filledPolls: PropTypes.number,
    date: PropTypes.number,
    name: PropTypes.string,
    typeName: PropTypes.string,
    topicGrades: PropTypes.array,
    subrows: PropTypes.array,
    getSubrows: PropTypes.func,
    typeId: PropTypes.number,
    moduleNr: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = () => {
    const { subrows, getSubrows, typeId, moduleNr } = this.props;
    if (subrows.length === 0) {
      getSubrows(typeId, moduleNr);
    }
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const {
      allPolls,
      filledPolls,
      date,
      name,
      typeName,
      topicGrades,
      subrows,
    } = this.props;
    const { collapsed } = this.state;
    return (
      <Fragment>
        <tr
          className={`course-poll__row ${
            collapsed ? "course-poll__row--opened" : ""
          }`}
        >
          <td>
            <button className="btn btn-regular" onClick={this.onCollapse}>
              <ChevronRight size={20} className="collapse-trigger" />
            </button>
          </td>
          <td>{date ? dateHelper(date).print() : "–"}</td>
          <td>
            <div data-tip={name} data-for="module-name">
              {typeName}
            </div>
          </td>
          {topicGrades.map((topic, topicIndex) => (
            <PollGrade key={topicIndex} data={topic.grade} />
          ))}
          <td>
            <strong>
              {Math.floor((filledPolls / allPolls) * 100)}%{" "}
              <small>
                ({filledPolls}/{allPolls})
              </small>
            </strong>
          </td>
        </tr>
        {collapsed &&
          subrows.map((item, index) => (
            <tr
              key={index}
              className="course-poll__row course-poll__row--inside "
            >
              <td />
              <td>{item.date ? dateHelper(item.date).print() : "–"}</td>
              <td>{item.typeName}</td>
              {item.topicGrades.map((topic, topicIndex) => (
                <PollGrade key={topicIndex} data={topic.grade} />
              ))}
              <td>
                <a
                  className="btn btn-regular"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Eye size={16} />
                </a>
              </td>
            </tr>
          ))}
      </Fragment>
    );
  }
}

export default CoursePollTableRow;
