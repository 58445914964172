import Client from "../../../Common/Utils/ClientApi";
import restApiConfig from "../../../Configs/restApiConfig";
import COMPANIES_ACTION_TYPES from "./ActionTypes";
import ACTION_TYPES from "../../../Enums/ActionTypes";

export function fetchCompanies(
  cancelToken,
  params,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company`,
    actionType: COMPANIES_ACTION_TYPES.FETCH_COMPANIES,
    callback,
    cancelToken,
    params,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}
export function fetchCompany(
  cancelToken,
  companyId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company/${companyId}`,
    actionType: COMPANIES_ACTION_TYPES.FETCH_COMPANY_DETAILS,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function refreshCompanyData(companyData) {
  return {
    type: COMPANIES_ACTION_TYPES.REFRESH_COMPANY_DETAILS,
    payload: companyData,
  };
}

export function fetchCompanyFromGus(
  nipNumber,
  callback,
  cancelToken,
  callGlobalLoader = true,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/gus/`,
    actionType: COMPANIES_ACTION_TYPES.FETCH_COMPANY_FROM_GUS,
    params: { nipNumber },
    callback,
    cancelToken,
    callGlobalLoader,
  });
}

export function updateCompany(
  values,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company/${values.id}`,
    actionType: COMPANIES_ACTION_TYPES.SAVE_COMPANY,
    values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}

export function addNewCompany(
  values,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company`,
    actionType: COMPANIES_ACTION_TYPES.ADD_NEW_COMPANY,
    values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPOST(config);
}

export function fetchMeta(
  cancelToken,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/contract/meta`,
    actionType: ACTION_TYPES.FETCH_CONTRACT_META,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function clearCompany() {
  return {
    type: COMPANIES_ACTION_TYPES.CLEAR_COMPANY,
    payload: {},
  };
}

export function fetchContacts(
  cancelToken,
  companyId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company/${companyId}/contact`,
    actionType: COMPANIES_ACTION_TYPES.FETCH_CONTACTS,
    callback,
    cancelToken,
    callGlobalLoader,
  };

  return Client.actionGET(config);
}

export function updateContacts(
  values,
  companyId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company/${companyId}/contact`,
    actionType: COMPANIES_ACTION_TYPES.UPDATE_CONTACTS,
    values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPOST(config);
}

export function updateInvoiceContact(
  companyId,
  values,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/company/${companyId}/invoice-receiver`,
    actionType: COMPANIES_ACTION_TYPES.UPDATE_INVOICE_RECEIVER,
    values,
    callback,
    callGlobalLoader,
  };

  return Client.actionPUT(config);
}
