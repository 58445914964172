import ReactTable from "react-table";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import _ from "lodash";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";

class ReactTableWithSummary extends Component {
  static propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    t: PropTypes.func,
    summaryConfig: PropTypes.array,
  };
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      columnsWidth: [],
    };
  }
  componentDidMount() {
    this.fillColumnsWidth();
  }
  fillColumnsWidth = () => {
    const { columns } = this.props;
    const tableColumns = this.tableRef.current.querySelectorAll(".rt-th");
    this.setState({
      columnsWidth: columns.map((column, index) =>
        tableColumns[index] ? tableColumns[index].offsetWidth : 0,
      ),
    });
  };
  renderSummary = () => {
    const { t, summaryConfig } = this.props;
    return (
      <div className="ReactTable -striped -highlight tableSummary">
        <div className="rt-table" role="grid">
          <div className="rt-tbody">
            <div className="rt-tr-group" role="rowgroup">
              <div className="rt-tr -odd" role="row">
                <p className={`text-center`}>{t("Summary")}</p>
              </div>
            </div>
            <div className="rt-tr-group" role="rowgroup">
              {summaryConfig.map((config, index) =>
                this.renderSummaryRow(config, index),
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderSummaryRow = (config, index) => {
    const { columns } = this.props;
    const { columnsWidth } = this.state;
    const { data } = config;

    return (
      <div key={index} className="rt-tr -odd" role="row">
        {columns.map((column, index) => {
          const { summaryAmount, summaryCurrency } = column;
          const canMakeSummary =
            summaryAmount &&
            summaryAmount.length > 0 &&
            summaryCurrency &&
            summaryCurrency.length > 0 &&
            data &&
            data.length > 0;
          if (canMakeSummary) {
            let currency = _.get(data[0], summaryCurrency);
            let columnSum = 0;
            data.forEach(element => {
              columnSum += _.get(element, summaryAmount);
            });
            return columnSum >= 0 ? (
              <div
                key={index}
                className="rt-td"
                role="gridcell"
                style={{
                  flex: "100 0 auto",
                  width: `${columnsWidth[index]}px`,
                }}
              >
                {config.render ? (
                  config.render(columnSum, currency)
                ) : (
                  <span className={`tableAmount`}>
                    {renderAmountWithCurrency({
                      amount: columnSum,
                      currency,
                    })}
                  </span>
                )}
              </div>
            ) : (
              <div
                key={index}
                className="rt-td"
                role="gridcell"
                style={{
                  flex: "100 0 auto",
                  width: `${columnsWidth[index]}px`,
                }}
              />
            );
          }
          return (
            <div
              key={index}
              className="rt-td"
              role="gridcell"
              style={{ flex: "100 0 auto", width: `${columnsWidth[index]}px` }}
            />
          );
        })}
      </div>
    );
  };
  unifyColumnsWidth = () => {
    this.fillColumnsWidth();
  };
  render() {
    const { t } = this.props;
    return (
      <div ref={this.tableRef}>
        <ReactTable
          {...this.props}
          onResizedChange={this.unifyColumnsWidth}
          showPagination={false}
          noDataText={t("No records found")}
          className="-striped -highlight withSummary"
        />
        {this.renderSummary()}
      </div>
    );
  }
}

export default translate()(ReactTableWithSummary);
