class Model {
  removeEmptyKeys = () => {
    const cleanObject = { ...this.values };
    Object.keys(cleanObject).forEach(
      objectKey =>
        (cleanObject[objectKey] === undefined || !cleanObject[objectKey]) &&
        delete cleanObject[objectKey],
    );
    return cleanObject;
  };
  removeNullableKeys = () => {
    const cleanObject = { ...this.values };
    Object.keys(cleanObject).forEach(
      objectKey =>
        (cleanObject[objectKey] === undefined ||
          cleanObject[objectKey] === null) &&
        delete cleanObject[objectKey],
    );
    return cleanObject;
  };
}

export default Model;
