import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../Configs/i18n";

const Loader = props => {
  return (
    <div className="loader" style={props.style || {}}>
      <div
        className={`loader-ico loader-ico${
          i18n.language !== "pl" ? "--en" : ""
        }`}
      />
    </div>
  );
};

Loader.propTypes = { style: PropTypes.object };

export default Loader;
