import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { formValueSelector, reduxForm } from "redux-form";
import ContractPaymentsReport from "../Components/ContractPaymentsReport";
import {
  fetchContractPayments,
  downloadContractPayments,
  fetchContractPaymentsFilters,
} from "../Actions";

const FORM_NAME = "CONTRACT_PAYMENTS_REPORT";

const ContractPayments = props => <ContractPaymentsReport {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const {
    contractPaymentsReportResults,
    coursesSpecialists,
    contractPaymentFilters,
  } = state;
  const values = selector(state, "courseSpecialistId", "onlyExpired", "sort");
  return {
    contractPaymentsReportResults,
    coursesSpecialists,
    contractPaymentFilters,
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchContractPaymentsFilters,
      fetchContractPayments,
      downloadContractPayments,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    onlyExpired: true,
    sort: "deadline.desc",
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContractPayments),
);
