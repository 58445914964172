import React from "react";
import { Link } from "react-router-dom";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import PropTypes from "prop-types";

const HomePageElement = ({ link, icon, title, text, disabled }) => {
  const iconColor = "rgb(232, 156, 48)";
  const iconSize = "47px";

  return (
    <div
      className={`col-sm-10 col-md-5 home-page__element${
        disabled ? " muted" : ""
      }`}
    >
      <Link to={link}>
        <CustomIcon icon={icon} color={iconColor} size={iconSize} />
        <div className="home-page__element-text">
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
      </Link>
    </div>
  );
};

HomePageElement.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HomePageElement;
