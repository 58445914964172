import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Field, FieldArray } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import getCancelToken from "../../../Helpers/GetCancelToken";
import notificationOpts from "../../../Helpers/NotificationOpts";
import { Delete, Plus, Save } from "react-feather";
import * as HttpStatus from "http-status-codes";

class CorrectionsEditForm extends Component {
  cancelToken = getCancelToken();

  state = {
    newCorrections: [],
  };

  componentDidMount() {
    const { invoice, initialize } = this.props;
    initialize({
      newCorrection: "",
      corrections: invoice.corrections,
    });
  }

  removeCorrection = index => {
    const {
      values: { corrections },
      change,
      deleteInvoiceCorrection,
    } = this.props;

    const correctionId = corrections[index].id;

    const successCallback = ({ status }) => {
      if (status === HttpStatus.NO_CONTENT) {
        change(
          "corrections",
          corrections.filter(correction => correction.id !== correctionId),
        );
      }
    };

    deleteInvoiceCorrection(Number(correctionId), successCallback);
  };

  updateCorrection = index => {
    const {
      values: { corrections },
      updateInvoiceCorrection,
      success,
      t,
    } = this.props;

    const correctionId = corrections[index].id;

    const correctionForSave = corrections.find(
      correction => correction.id === correctionId,
    );

    const successCallback = ({ status }) => {
      if (status === HttpStatus.NO_CONTENT) {
        success(
          notificationOpts({
            title: t("Your data was saved"),
          }),
        );
      }
    };

    if (correctionForSave) {
      const { id: correctionId, number: correctionNumber } = correctionForSave;
      const payload = {
        nr: correctionNumber,
      };

      updateInvoiceCorrection(
        this.cancelToken,
        Number(correctionId),
        payload,
        successCallback,
      );
    }
  };

  addCorrection = () => {
    const {
      addInvoiceCorrection,
      values: { newCorrection },
      invoice: { id: invoiceId },
      change,
      untouch,
    } = this.props;

    const successCallback = ({ status }) => {
      if (status === HttpStatus.CREATED) {
        this.setState(state => ({
          newCorrections: [...state.newCorrections, newCorrection],
        }));
        change("newCorrection", "");
        untouch("newCorrection");
      }
    };

    const payload = { nr: newCorrection, invoiceId };
    addInvoiceCorrection(this.cancelToken, payload, successCallback);
  };

  renderCorrections = ({ fields }) => (
    <div>
      {fields.map((correction, index) => (
        <div className={`row`} key={correction.number}>
          <div className={`col-xs-9`}>
            <Field
              component={RenderText}
              name={`corrections[${index}].number`}
            />
          </div>

          <div className={`col-xs-3`}>
            <button
              className={`btn btn-margin`}
              onClick={() => this.updateCorrection(index)}
            >
              <Save size={18} />
              {`  ${this.props.t("Save")}`}
            </button>

            <button
              className={`btn`}
              onClick={() => this.removeCorrection(index)}
            >
              <Delete size={18} />
              {`  ${this.props.t("Remove")}`}
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    const { t, pristine, submitting } = this.props;
    const { newCorrections } = this.state;

    return (
      <>
        <FieldArray name="corrections" component={this.renderCorrections} />

        <div className={`row margin__t25`}>
          <div className={`col-xs-12`}>
            <h5>{t("Add new correction")}</h5>
          </div>
          <div className={`col-xs-9`}>
            <Field under component={RenderText} name={`newCorrection`} />
          </div>

          <div className={`col-xs-3`}>
            <button
              className={`btn`}
              onClick={this.addCorrection}
              disabled={pristine || submitting}
            >
              <Plus size={18} />
              {`  ${t("Add")}`}
            </button>
          </div>
        </div>
        <ul>
          {newCorrections.map((newCorrection, index) => (
            <li key={`${newCorrection}#${index}`}>{newCorrection}</li>
          ))}
        </ul>
      </>
    );
  }
}

CorrectionsEditForm.propTypes = {
  invoice: PropTypes.object,
  change: PropTypes.func,
  t: PropTypes.func,
  values: PropTypes.object,
  addInvoiceCorrection: PropTypes.func,
  updateInvoiceCorrection: PropTypes.func,
  deleteInvoiceCorrection: PropTypes.func,
  success: PropTypes.func,
  untouch: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  initialize: PropTypes.func,
};

export default translate()(CorrectionsEditForm);
