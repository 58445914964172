import React, { useState } from "react";
import { DateTimePicker } from "react-widgets";
import { InjectedTranslateProps } from "react-i18next/src/props";

type CancelCourseProps = InjectedTranslateProps & {
  disabled: boolean;
  handleCancelCourse: (date: Date) => void;
};

const CancelCourse: React.FunctionComponent<CancelCourseProps> = ({
  t,
  disabled,
  handleCancelCourse,
}): React.ReactElement => {
  const [cancelCourseDate, setCancelCourseDate] = useState<Date>();
  const handleChangeCancelCourseDate = (date: Date): void =>
    setCancelCourseDate(date);

  return (
    <div className="row">
      <div className="col-xs-8">
        <DateTimePicker
          min={new Date()}
          value={cancelCourseDate}
          onChange={handleChangeCancelCourseDate}
          disabled={disabled}
          time={false}
        />
      </div>

      <div className="col-xs-4">
        <button
          disabled={!cancelCourseDate || disabled}
          onClick={() => handleCancelCourse(cancelCourseDate)}
          className="btn btn-danger"
        >
          {t("Cancel course")}
        </button>
      </div>
    </div>
  );
};

export default CancelCourse;
