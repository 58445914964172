export const ajaxStatusActions = {
  BEGIN_AJAX_CALL: "BEGIN_AJAX_CALL",
  END_AJAX_CALL: "END_AJAX_CALL",
};

export function beginAjaxCall() {
  return { type: ajaxStatusActions.BEGIN_AJAX_CALL };
}

export function endAjaxCall() {
  return { type: ajaxStatusActions.END_AJAX_CALL };
}
