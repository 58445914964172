import React, { Component } from "react";
import { translate } from "react-i18next";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { AlertTriangle } from "react-feather/dist/index";
import { withRouter } from "react-router";
import LoaderProvider from "../../../Common/Components/Loader/LoaderProvider";
import dateHelper from "../../../Helpers/DateHelper";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import CourseCalendarStatuses from "../../../Enums/CourseCalendarStatuses";
import EventsList from "../../Products/Components/EventsList";
import NewEvent from "../../Products/Containers/NewEvent";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import * as _ from "lodash";
import {
  RequestParams,
  SettingsFormProps,
  SettingsFormState,
} from "../Types/SettingsForm";
import EditEventForm from "./EditEventForm";
import CourseSettingsForm from "./CourseSettingsForm";
import { CurrentProductVersionItem } from "../../ProductsNew/Types/CurrentProductVersionType";
import { CourseSettingsFormValues } from "../Types/CourseSettingsForm";

const COUNTER_FOR_PAGING = 11;

class SettingsForm extends Component<SettingsFormProps, SettingsFormState> {
  cancelToken = getCancelToken();

  state = {
    warningModalShow: false,
    warningConfirmCallback: null,
    warningCancelCallback: null,
    prevProduct: null,
    searchProductQuery: "",
    isLoadingProducts: false,
    searchCourseSpecialistQuery: "",
    isLoadingCourseSpecialist: false,
    currentEventEdit: null,
  };

  componentDidMount() {
    const {
      fetchCities,
      fetchCourseModes,
      isNew = false,
      fetchCourse,
      courseId,
      fetchProductVersions,
      fetchCourseSpecialists,
      fetchProductVersionsMeta,
      fetchDictionary,
    } = this.props;
    const { FETCH_ROOMS } = ACTION_TYPES;

    fetchCities(this.cancelToken, { onlyTeach: true });
    fetchCourseModes(this.cancelToken, { onlyActive: true });
    fetchProductVersionsMeta(this.cancelToken);
    fetchDictionary(this.cancelToken, {
      path: "/rooms",
      actionType: FETCH_ROOMS,
    });

    if (!isNew) {
      fetchCourse(this.cancelToken, courseId, ({ data }) => {
        this.getMentorsForCourses(data.data.product.id);
      });
    } else {
      fetchProductVersions(
        this.cancelToken,
        this.getRequestConfig("", COUNTER_FOR_PAGING),
      );

      fetchCourseSpecialists(
        this.cancelToken,
        this.getRequestConfig("", COUNTER_FOR_PAGING),
      );
    }
  }

  onCancelCourse = (date: Date): void => {
    const { cancelCourse, courseId } = this.props;

    const timestamp = dateHelper(date).getFormatted();
    const values = {
      id: courseId,
      cancelDate: timestamp,
    };

    cancelCourse(values, null, true);
  };

  getMentorsForCourses = (productId: number): void => {
    const { fetchMentorsForCourse } = this.props;

    fetchMentorsForCourse(this.cancelToken, productId);
  };

  componentWillUnmount() {
    const { clearCourseState } = this.props;
    this.cancelToken.cancel();
    clearCourseState();
  }

  onSubmit = async (values: CourseSettingsFormValues): Promise<void> => {
    const {
      isNew,
      addNewCourse,
      editCourse,
      refreshCurrentCourse,
      courseId,
      history,
      course,
    } = this.props;
    const {
      courseSchedule,
      city,
      product,
      courseSpecialist,
      mentors,
      numberOfPlaces,
    } = values;

    const parsedCourseSchedule = {
      ...courseSchedule,
      days: courseSchedule.days.map(courseDay => courseDay.id),
      courseMode: courseSchedule.courseMode.id,
      id: course.courseSchedule.id,
    };

    const [courseSpecialistPerson] = courseSpecialist;
    const [productSelected] = product;

    const payload = {
      ...course,
      ...values,
      courseSchedule: parsedCourseSchedule,
      city: city.id,
      mentors: mentors ? mentors.map(mentor => mentor.personId) : [],
      courseSpecialist: courseSpecialistPerson && courseSpecialistPerson.id,
      product: productSelected && productSelected.id,
      numberOfPlaces: Number(numberOfPlaces),
    };

    if (isNew) {
      await addNewCourse(
        payload,
        ({ data }) => {
          const { id } = data.data;
          history.replace(`/courses/${id}/settings`);
        },
        false,
      );
    } else {
      editCourse(courseId, payload, refreshCurrentCourse);
    }
  };

  getRequestConfig = (search = null, counter = null) => {
    const ACTIVE = 2;
    const START_PAGE = 1;

    const config: RequestParams = {
      simpleResponse: true,
      status: ACTIVE,
    };

    if (counter) {
      config.pageNo = START_PAGE;
      config.pageAmount = counter;
    }

    if (search) {
      config.search = search;
    }
    return config;
  };

  handleSearchProducts = (query: string): void => {
    const { fetchProductVersions } = this.props;
    this.setState(
      { isLoadingProducts: true, searchProductQuery: query },
      () => {
        const { searchProductQuery } = this.state;
        fetchProductVersions(
          this.cancelToken,
          this.getRequestConfig(searchProductQuery, COUNTER_FOR_PAGING),
          () => this.setState({ isLoadingProducts: false }),
        );
      },
    );
  };

  onShowMoreProductResults = counter => {
    const { fetchProductVersions } = this.props;
    const counterForPaging = counter + 1;

    this.setState({ isLoadingProducts: true }, () => {
      const { searchProductQuery } = this.state;
      fetchProductVersions(
        this.cancelToken,
        this.getRequestConfig(searchProductQuery, counterForPaging),
        () => this.setState({ isLoadingProducts: false }),
      );
    });
  };

  handleSearchCourseSpecialist = query => {
    const { fetchCourseSpecialists } = this.props;
    this.setState(
      { isLoadingCourseSpecialist: true, searchCourseSpecialistQuery: query },
      () => {
        const { searchCourseSpecialistQuery } = this.state;
        fetchCourseSpecialists(
          this.cancelToken,
          this.getRequestConfig(
            searchCourseSpecialistQuery,
            COUNTER_FOR_PAGING,
          ),
          () => this.setState({ isLoadingCourseSpecialist: false }),
        );
      },
    );
  };

  onShowMoreCourseSpecialistResults = counter => {
    const { fetchCourseSpecialists } = this.props;
    const counterForPaging = counter + 1;

    this.setState({ isLoadingCourseSpecialist: true }, () => {
      const { searchCourseSpecialistQuery } = this.state;
      fetchCourseSpecialists(
        this.cancelToken,
        this.getRequestConfig(searchCourseSpecialistQuery, counterForPaging),
        () => this.setState({ isLoadingCourseSpecialist: false }),
      );
    });
  };

  getProductsOptionsForSelect = (): Array<CurrentProductVersionItem> => {
    const { products, course } = this.props;

    if (_.has(course, "product")) {
      const options = [course.product, ...products];
      const productIsExist = products.find(
        product => product.id === course.product.id,
      );

      return productIsExist ? products : options;
    }

    return products;
  };

  fetchCourse = () => {
    const { fetchCourse, courseId } = this.props;

    fetchCourse(this.cancelToken, courseId);
  };

  removeEvent = async eventId => {
    const { removeAdditionalEvent, courseId } = this.props;

    await removeAdditionalEvent(courseId, eventId);
    this.closeEditRowModal();
    this.fetchCourse();
  };

  onRowEdit = rowData =>
    this.setState({
      currentEventEdit: rowData,
    });

  closeEditRowModal = () =>
    this.setState({
      currentEventEdit: null,
    });

  handleEventEdit = event => {
    const { editEvent, courseId } = this.props;
    editEvent(courseId, event.id, event, () => {
      this.fetchCourse();
      this.closeEditRowModal();
    });
  };

  render() {
    const {
      t,
      cities,
      course: { courseCalendar, additionalEvents, id: courseId },
      course,
      modes,
      courseSpecialists,
      mentors,
      isNew,
      isLoading,
      isCancel,
      isEnd,
      lectureParticipants,
      lectureTypes,
      rooms,
      addCourseEvent,
      fetchEmployees,
      lecturers,
    } = this.props;

    const {
      isLoadingProducts,
      isLoadingCourseSpecialist,
      currentEventEdit,
    } = this.state;

    const draftAlert = _.get(courseCalendar, "status.code") ===
      CourseCalendarStatuses.DRAFT && (
      <div className="draft-alert">
        {t("You are in draft mode")}
        <AlertTriangle />
      </div>
    );

    return (
      <LoaderProvider isLoading={isLoading}>
        <div>
          <h2 className="form-section__title">{t("Parameters")}</h2>
          {draftAlert}
          <CourseSettingsForm
            t={t}
            isNew={isNew}
            course={course}
            cities={cities}
            modes={modes}
            mentors={mentors || []}
            products={this.getProductsOptionsForSelect()}
            isProductsLoading={isLoadingProducts}
            isCancel={isCancel}
            isEnd={isEnd}
            handleSubmit={this.onSubmit}
            fetchProductsData={this.handleSearchProducts}
            onShowMoreProductsResults={this.onShowMoreProductResults}
            courseSpecialists={courseSpecialists}
            fetchCourseSpecialists={this.handleSearchCourseSpecialist}
            onShowMoreCourseSpecialistsResults={
              this.onShowMoreCourseSpecialistResults
            }
            isCourseSpecialistsLoading={isLoadingCourseSpecialist}
            onCancelCourse={this.onCancelCourse}
            fetchMentorsForCourse={this.getMentorsForCourses}
          />
        </div>

        <div className="margin__b25">
          <EventsList
            isProduct={false}
            events={additionalEvents}
            disabled={false}
            isLoading={isLoading}
            onRowClick={this.onRowEdit}
          />
          {!isNew && (
            <NewEvent
              isProduct={false}
              versionId={courseId}
              courseModes={modes}
              participants={lectureParticipants}
              types={lectureTypes}
              addProductEvent={addCourseEvent}
              rooms={rooms}
              getEvents={this.fetchCourse}
              fetchEmployees={fetchEmployees}
              lecturers={lecturers}
            />
          )}

          {!!currentEventEdit && (
            <CustomModal
              isOpen={!!currentEventEdit}
              title={`${t("Edit event")} - ${currentEventEdit.name}`}
              onRequestClose={this.closeEditRowModal}
              ariaHideApp={false}
            >
              <EditEventForm
                event={currentEventEdit}
                rooms={rooms}
                lecturers={lecturers}
                fetchEmployees={fetchEmployees}
                onEdit={this.handleEventEdit}
                onRemoveEvent={this.removeEvent}
              />
            </CustomModal>
          )}
        </div>
      </LoaderProvider>
    );
  }
}

export default translate()(withRouter(SettingsForm));
