import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import CompaniesList from "./CompaniesList";
import Filters from "./Filters";
import Header from "./Header";
import GetCancelToken from "../../../Helpers/GetCancelToken";
import LoaderProvider from "../../../Common/Components/Loader/LoaderProvider";

class Companies extends Component {
  state = {
    expiredOnly: false,
    overpaidOnly: false,
    search: "",
    companies: [],
    pageSize: 7,
    currentPage: 1,
  };

  cancelToken = GetCancelToken();

  componentDidMount() {
    this.loadCompanies();
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  loadCompanies = (pageNo = 1, pageAmount = 7) => {
    const { fetchCompanies } = this.props;
    const { expiredOnly, overpaidOnly, search } = this.state;
    const params = { pageNo, pageAmount };
    if (expiredOnly) {
      params.expiredOnly = true;
    }
    if (overpaidOnly) {
      params.overpaidOnly = true;
    }
    if (search && search.length > 0) {
      params.search = search;
    }

    fetchCompanies(this.cancelToken, params);
  };

  toggleExpiredFilter = () =>
    this.setState(
      state => ({ expiredOnly: !state.expiredOnly }),
      this.loadCompanies,
    );

  toggleOverpaidFilter = () =>
    this.setState(
      state => ({ overpaidOnly: !state.overpaidOnly }),
      this.loadCompanies,
    );

  searchPayers = _.debounce(this.loadCompanies, 500);

  onChangeSearch = ({ target }) =>
    this.setState({ search: target.value }, this.searchPayers);

  onChangePage = currentPage => {
    const { pageSize } = this.state;
    this.setState({ currentPage }, () =>
      this.loadCompanies(currentPage, pageSize),
    );
  };

  goToAddNewCompany = () => this.props.history.push("/companies/new");

  render() {
    const { t, isLoading, forgetUser, counter, companies } = this.props;
    const {
      expiredOnly,
      overpaidOnly,
      search,
      pageSize,
      currentPage,
    } = this.state;

    return (
      <main>
        <div className="custom-container">
          <Header
            searchValue={search}
            onChangeSearch={this.onChangeSearch}
            translations={t}
            logout={forgetUser}
          />
          <LoaderProvider isLoading={isLoading}>
            <div>
              <Filters
                addNewCompany={this.goToAddNewCompany}
                translations={t}
                expiredOnly={expiredOnly}
                overpaidOnly={overpaidOnly}
                toggleExpiredFilter={this.toggleExpiredFilter}
                toggleOverpaidFilter={this.toggleOverpaidFilter}
              />
            </div>
            <div>
              <CompaniesList
                counter={counter}
                companies={companies}
                translations={t}
                loadCompanies={this.loadCompanies}
                pageSize={pageSize}
                currentPage={currentPage}
                onChangePage={this.onChangePage}
              />
            </div>
          </LoaderProvider>
        </div>
      </main>
    );
  }
}

Companies.propTypes = {
  companies: PropTypes.array,
  counter: PropTypes.number,
  fetchCompanies: PropTypes.func,
  t: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  forgetUser: PropTypes.func,
};

export default translate()(Companies);
