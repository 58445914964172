import React from "react";
import {
  convertAmountToPrice,
  convertPriceToAmount,
} from "../Common/Utils/PriceFormatters";
import { Field } from "redux-form";

const AmountField = props => (
  <Field
    {...props}
    format={convertAmountToPrice}
    parse={convertPriceToAmount}
  />
);

export default AmountField;
