import dateHelper from "../../../Helpers/DateHelper";
import moment from "moment";
import DATE_FORMATS from "../../../Enums/DateFormats";

export default fields => {
  let errors = {};
  const {
    issueMonth,
    paymentDate,
    sellDate,
    amountGross,
    vatRate,
    receiver,
    legalBasis,
    hasLegalBasis,
  } = fields;
  if (!paymentDate) {
    errors.paymentDate = "Payment date is required";
  }
  if (!sellDate) {
    errors.sellDate = "Sell date is required";
  }
  if (!amountGross || !amountGross.amount || amountGross.amount <= 0) {
    errors.amountGross = "Amount is required";
  }
  if (issueMonth && paymentDate) {
    const paymentDateObj = dateHelper(paymentDate).get();
    const issueDate = moment(issueMonth).format(DATE_FORMATS.YEAR_MONTH);
    const theSameMonth =
      paymentDateObj.format(DATE_FORMATS.YEAR_MONTH) === issueDate;
    if (
      theSameMonth &&
      (paymentDateObj.isBefore(moment()) ||
        paymentDateObj.format(DATE_FORMATS.FULL_DATE) ===
          moment().format(DATE_FORMATS.FULL_DATE))
    ) {
      errors.paymentDate = "Payment date must be after issue date";
    } else if (paymentDateObj.isBefore(issueDate)) {
      errors.paymentDate = "Payment date must be after issue date";
    }
  }
  if (sellDate) {
    const sellDateObj = dateHelper(sellDate).get();
    const startOfPrevMonth = moment()
      .subtract(1, "month")
      .startOf("month");
    if (sellDateObj.isBefore(startOfPrevMonth)) {
      errors.sellDate = "Sell date must be after start of previous month";
    }
  }
  if (typeof vatRate === "undefined" || vatRate < 0) {
    errors.vatRate = "You must select tax rate";
  }

  if (receiver && !receiver.street) {
    errors.receiver = "User's address is not filled, please fill data";
  }

  if (
    receiver &&
    (!receiver.receiverEmails || receiver.receiverEmails.length === 0)
  ) {
    errors.receiver =
      "The e-mail address of the invoice sending has not been added";
  }

  if (vatRate && hasLegalBasis && !legalBasis) {
    errors.legalBasis = "You must select legal basis";
  }

  return errors;
};
