import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "../../../Common/Components/Tabs/Tabs";
import Courses from "./Courses";
import { translate } from "react-i18next";
import Contracts from "./Contracts";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

const COURSES_TAB_INDEX = 0;
const CONTRACTS_TAB_INDEX = 1;

class CoursesPage extends Component {
  state = {
    activeTab: COURSES_TAB_INDEX,
  };

  getTabsConfig = () => {
    const { t } = this.props;
    return [
      {
        name: t("Courses"),
        id: COURSES_TAB_INDEX,
        visible: true,
      },
      {
        name: t("Contracts"),
        id: CONTRACTS_TAB_INDEX,
        visible: havePermissions([permissions.STUDENT_LIST_ALL]),
      },
    ];
  };

  onChangeTab = tabId => this.setState({ activeTab: tabId });

  render() {
    const { activeTab } = this.state;

    return (
      <div>
        <div className="custom-container margin__t25">
          <Tabs
            activeTab={activeTab}
            onChangeTab={this.onChangeTab}
            config={this.getTabsConfig()}
          />
        </div>
        {activeTab === COURSES_TAB_INDEX && <Courses />}

        {activeTab === CONTRACTS_TAB_INDEX && <Contracts />}
      </div>
    );
  }
}

CoursesPage.propTypes = {
  t: PropTypes.func,
};

export default translate()(CoursesPage);
