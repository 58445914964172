import React, { Component } from "react";
import { translate } from "react-i18next";
import { Field } from "redux-form";
import PropTypes from "prop-types";

import CityAutoComplete from "../../../Common/Components/CityAutoComplete/CityAutoComplete";
import CitySelectWithPagination from "../../../Forms/CitySelectWithPagination";
import checkCountryIsPoland from "../../../Helpers/CheckCountryIsPoland";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { RenderSelect, RenderText } from "../../../Forms/forms";
import ACTION_TYPES from "../../../Enums/ActionTypes";

class AddressPopup extends Component {
  static propTypes = {
    updatePersonAddress: PropTypes.func,
    onSubmit: PropTypes.func,
    isPopupVisible: PropTypes.func,
    studentId: PropTypes.number,
    dictionaries: PropTypes.object,
    values: PropTypes.object,
    change: PropTypes.func,
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    t: PropTypes.func,
    fetchDictionary: PropTypes.func,
  };
  #cancelToken = getCancelToken();
  constructor(props) {
    super(props);
    this.state = {
      cityName: null,
      cities: [],
      dictionaries: {},
    };
    props.fetchDictionary(this.#cancelToken, {
      path: "/countries",
      actionType: ACTION_TYPES.FETCH_COUNTRIES,
    });
  }
  componentDidUpdate() {
    const {
      dictionaries: { cities },
    } = this.props;
    if (cities && !_.isEqual(this.state.cities, cities)) {
      this.setState({
        cities,
      });
    }
  }

  handleUserAddressPopup = fields => {
    const {
      studentAddress: { address, zipCode },
    } = fields;
    const {
      updatePersonAddress,
      onSubmit,
      isPopupVisible,
      studentId,
      dictionaries: { countries },
      values: { country, city },
    } = this.props;

    const { cityName } = this.state;

    const countryName = countries.find(item => item.id === country);
    const correctCityName =
      !cityName && typeof city === "string" ? city : cityName;

    if (correctCityName && countryName.name && address && zipCode) {
      updatePersonAddress(
        studentId,
        {
          city: correctCityName,
          country: countryName.name,
          address,
          zipCode,
        },
        () => {
          const isModalShow = false;
          const isUserHaveAddress = true;

          isPopupVisible(isModalShow, isUserHaveAddress);
          onSubmit();
        },
      );
    }
  };
  cityAddressSelected = city => {
    const cityName = city && city.name ? city.name : null;
    this.props.change("studentAddress.city", cityName);
    this.setState({
      cityName,
    });
  };
  globalCitiesList = cities => {
    this.setState({ cities });
  };
  handleCountry = country => {
    this.props.change("studentAddress.country", country.id);
    this.props.change("studentAddress.city", "");
    this.setState({
      cityName: "",
    });
  };
  render() {
    const {
      change,
      handleSubmit,
      isPopupVisible,
      reset,
      dictionaries: { countries },
      t,
    } = this.props;
    return (
      <div className="student__address">
        <div className="col-xs-12">
          <Field
            label={t("Street and number")}
            name="studentAddress.address"
            component={RenderText}
            required="required"
          />
          <Field
            label={t("Country")}
            name="studentAddress.country"
            component={RenderSelect}
            dropdownConfig={{
              data: countries,
              textField: "name",
              valueField: "id",
              filter: "contains",
              onChange: this.handleCountry,
            }}
            required="required"
          />

          {checkCountryIsPoland(countries, this.props.values.country) ? (
            <div className="col-xs-12">
              <Field
                label={t("City")}
                name="studentAddress.city"
                component={CitySelectWithPagination}
                props={{
                  onChangeHandler: this.cityAddressSelected,
                  cities: this.state.cities,
                  fetchDictionary: this.props.fetchDictionary,
                  value: this.state.cityName,
                  onCitiesListUpdate: this.globalCitiesList,
                }}
              />
            </div>
          ) : (
            <CityAutoComplete
              label={t("City")}
              change={change}
              name="studentAddress.city"
            />
          )}

          <Field
            label={t("Zip code")}
            name="studentAddress.zipCode"
            component={RenderText}
            required="required"
          />
          <div className="student__address-actions">
            <button
              type="submit"
              className="btn btn-dark"
              onClick={handleSubmit(this.handleUserAddressPopup)}
            >
              {t("Save")}
            </button>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                isPopupVisible(false);
              }}
            >
              {t("Cancel")}
            </button>
            <button
              type="button"
              onClick={reset}
              className="btn btn-link btn-regular"
            >
              {t("Clear changes")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(AddressPopup);
