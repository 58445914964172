export default fields => {
  const errors = {};
  const { startDate, endDate } = fields;

  if (!startDate) {
    errors.startDate = "Enter the start date";
  }

  if (!endDate) {
    errors.endDate = "Enter the end date";
  }

  if (startDate > endDate) {
    errors.endDate =
      "The report end date cannot be earlier than the contract start date";
  }

  return errors;
};
