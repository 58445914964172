import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { Field, Form } from "redux-form";
import { RenderCheckbox, RenderText } from "../../../Forms/forms";
import { Features, Tenant } from "../../../Common/Utils/Tenant";

class IncomesTransferForm extends Component {
  static propTypes = {
    transferIncomeData: PropTypes.object,
    values: PropTypes.object,
    initialize: PropTypes.func,
    t: PropTypes.func,
    transferDoneHandler: PropTypes.func,
    makeIncomeTransfer: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

  componentDidMount() {
    const {
      transferIncomeData: {
        account: { number: sourceAccount },
        transaction: transactionNumber,
        invoicesFv,
      },
      initialize,
    } = this.props;
    initialize({
      sourceAccount,
      transactionNumber,
      invoicesFv,
      invoicingDisabled: true,
      transferInvoice: false,
    });
  }

  submitHandler = values => {
    values.invoicingDisabled = values.invoicingDisabled ? true : false;
    values.transferInvoice = values.transferInvoice ? true : false;
    const {
      transferIncomeData: { typeId },
      makeIncomeTransfer,
      transferDoneHandler,
    } = this.props;
    makeIncomeTransfer(typeId, values, () => {
      transferDoneHandler();
    });
  };

  render() {
    const { t, handleSubmit } = this.props;
    const {
      transferIncomeData: { invoicesFv: invoices },
      values: { transferInvoice, invoicingDisabled },
    } = this.props;
    return (
      <div className={`col-sm-12`}>
        <Form
          id={`transferIncomeForm`}
          onSubmit={handleSubmit(this.submitHandler)}
        >
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <Field
                name={`transactionNumber`}
                component={RenderText}
                label={t(`Transaction number`)}
                shouldDisabled
              />
            </div>
            <div className={`col-sm-12`}>
              <Field
                name={`sourceAccount`}
                component={RenderText}
                label={t(`Source subaccount`)}
                shouldDisabled
              />
            </div>
            <div className={`col-sm-12`}>
              <Field
                name={`destinationAccount`}
                component={RenderText}
                label={t(`Destination subaccount`)}
              />
            </div>
            <div className={`col-sm-12`}>
              <Field
                name={`reason`}
                component={RenderText}
                label={t(`Transfer's reason`)}
              />
            </div>
            <Tenant
              component={
                <div>
                  {!transferInvoice ? (
                    <div className={`col-sm-12`}>
                      <Field
                        name={`correction`}
                        component={RenderText}
                        label={t(`Correction's number`)}
                      />
                    </div>
                  ) : null}
                  <div className="col-12">
                    <Field
                      name={`invoicingDisabled`}
                      labelForCheckbox={t("Income already invoiced")}
                      shouldDisabled={transferInvoice}
                      component={RenderCheckbox}
                    />
                  </div>
                  {invoices && invoices.length === 1 ? (
                    <div className="col-12">
                      <Field
                        name={`transferInvoice`}
                        labelForCheckbox={t("Transfer invoices")}
                        shouldDisabled={invoicingDisabled}
                        component={RenderCheckbox}
                      />
                    </div>
                  ) : null}
                </div>
              }
              feature={Features.invoices}
            />
            <div className={`col-sm-12`}>
              <button className={`btn btn-dark pull-right`}>{t(`Save`)}</button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default translate()(IncomesTransferForm);
