/*
 * Function is checking if given funding type match to comparing funding type's name
 * compare is doing by dictionary funding types
 * @param integer fundingTypeId
 * @param string fundingTypeName
 */
import { store } from "../Configs/Root";

export default (contractType, contractTypeName) => {
  const contractTypes = store.getState().__metadata.contract.types;
  const foundContractType =
    contractTypes && contractTypeName
      ? contractTypes.find(element => {
          return element.code.toLowerCase() === contractTypeName.toLowerCase();
        })
      : null;
  return foundContractType && foundContractType.id === contractType
    ? true
    : false;
};
