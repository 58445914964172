import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { formValueSelector, reduxForm } from "redux-form";
import IncomesReport from "../Components/IncomesReport";
import { fetchBankIncomes, downloadBankIncomes } from "../Actions";
import { downloadInvoice } from "../../Invoices/Actions";
import { updateIncome } from "../Actions";

const FORM_NAME = "INCOMES_REPORT";

const Incomes = props => <IncomesReport {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const { bankIncomes } = state;
  const values = selector(
    state,
    "transaction",
    "onlyUnused",
    "onlyDeleted",
    "invoiced",
  );
  return {
    bankIncomes,
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBankIncomes,
      downloadBankIncomes,
      downloadInvoice,
      updateIncome,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    onlyUnused: true,
    onlyDeleted: false,
    invoiced: "all",
  },
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Incomes),
);
