import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import IncomesTransferForm from "../Components/IncomesTransferForm";
import { makeIncomeTransfer } from "../Actions";
import validate from "../../Contracts/Validators/IncomeTransferValidator";

const FORM_NAME = "INCOME_TRANSFER";

const IncomeTransfer = props => <IncomesTransferForm {...props} />;
const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(
    state,
    "bankTransferId",
    "destinationAccount",
    "reason",
    "correction",
    "incomes",
    "invoicingDisabled",
    "transferInvoice",
  );
  return {
    values,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      makeIncomeTransfer,
    },
    dispatch,
  );
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(IncomeTransfer),
);
