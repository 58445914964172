import React from "react";
import { Field } from "redux-form";
import TIME from "../../../Enums/Time";
import CONTRACT_PAYMENT_STATUSE_CODES from "../../../Enums/ContractPaymentStatusCodes";
import CONTRACT_STATUSES from "../../../Enums/ContractStatuses";
import {
  RenderCheckbox,
  RenderDateTime,
  RenderFullWidthSelect,
  RenderSelect,
  RenderText,
  RenderTextArea,
} from "../../../Forms/forms";
import moment from "moment";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { File } from "react-feather";
import {
  Authorize,
  permissions,
  havePermissions,
} from "../../../Common/Utils/Acl";
import CustomIcon from "../../../Common/Components/CustomIcon/CustomIcon";
import Notes from "../Containers/Notes";
import ContractBankAccounts from "./ContractBankAccounts";
import ContractRebate from "./ContractRebate";
import checkIfContractFinishReasonIs from "../../../Helpers/CheckIfContractFinishReasonIs";
import CONTRACT_FINISH_REASONS from "../../../Enums/ContractFinishReasons";
import laborOfficeIsExistInContractPayers from "../../../Helpers/LaborOfficeIsExistInContractPayers";
import { get } from "lodash";

class ContractStatus extends React.Component {
  renderResignationAdditionalFields() {
    const {
      coursesArray,
      t,
      courseSignature,
      productModules,
      tempContractFinishReason,
      contractResignReasons,
      contractTransferReasons,
      loadModules,
      handleDownload,
    } = this.props;

    const resignReasonSelectConfig = {
      data: contractResignReasons || [],
      textField: "name",
      valueField: "id",
    };

    const reasonId =
      tempContractFinishReason && tempContractFinishReason.id
        ? tempContractFinishReason.id
        : tempContractFinishReason;

    const showDownloadDiplomaButton = !!get(
      tempContractFinishReason,
      "obtainedDiplomaType",
      false,
    );

    switch (reasonId) {
      case 1:
        return (
          <div className="row">
            {showDownloadDiplomaButton && (
              <>
                <div className="col-xs-12 col-md-5">
                  <Field
                    title={t("Diploma's number")}
                    status="inactive"
                    under={true}
                    name="contract.diploma"
                    component={RenderText}
                  />
                </div>
                <div className="col-xs-12 col-md-7">
                  <a
                    href="#"
                    onClick={handleDownload}
                    className="showDiploma form-group"
                  >
                    <File size={18} /> {t("Show certificate")}
                  </a>
                </div>
              </>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            <Field
              label={t("Enter course's signature")}
              under={{ fieldClassName: "col-xs-12" }}
              required={true}
              name="contract.transferCourse"
              component={RenderSelect}
              dropdownConfig={{
                data: coursesArray
                  ? coursesArray.filter(
                      item => item.signature !== courseSignature,
                    )
                  : [],
                filter: "contains",
                textField: "signature",
                valueField: "id",
                onChange: loadModules,
                onSearch: this.props.searchCourse,
              }}
            />
            <Field
              label={t("Transfer reason")}
              under={{ fieldClassName: "col-xs-12" }}
              name="contract.transferReason"
              required={true}
              dropdownConfig={{
                data: contractTransferReasons || [],
                textField: "name",
                valueField: "id",
              }}
              component={RenderSelect}
            />

            <Field
              label={t("Starting module at the new course")}
              under={{ fieldClassName: "col-xs-12" }}
              name="contract.finishReasonModule"
              time={false}
              required={true}
              dropdownConfig={{
                data: this.props.productModules || [],
                textField: data =>
                  `${data.absNr !== null ? `${data.absNr} - ` : "   "}${
                    data.name
                  }`,
                valueField: "nr",
              }}
              component={RenderSelect}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <Field
              label={t("Date of resignation")}
              required
              under={{
                containerClassName: "",
                fieldClassName: "col-xs-12",
              }}
              name="contract.finishDate"
              component={RenderDateTime}
              time={false}
            />
            <Field
              label={t("Reason for resigning")}
              under={{ fieldClassName: "col-xs-12" }}
              required={true}
              name="contract.resignReason"
              component={RenderSelect}
              dropdownConfig={resignReasonSelectConfig}
            />
            <Field
              label={t("Comments")}
              under={true}
              name="contract.finishNote"
              component={RenderTextArea}
            />
            <Field
              label={t(
                "From which module does not attend the course (does not pay)",
              )}
              under={{ fieldClassName: "col-xs-12" }}
              required={true}
              name="contract.finishReasonModule"
              dropdownConfig={{
                data: productModules || [],
                textField: data =>
                  `${data.absNr !== null ? `${data.absNr} - ` : "   "}${
                    data.name
                  }`,
                valueField: "nr",
              }}
              component={RenderSelect}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <Field
              label={t(
                "From which module does not attend the course (does not pay)",
              )}
              under={{ fieldClassName: "col-xs-12" }}
              required={true}
              name="contract.finishReasonModule"
              dropdownConfig={{
                data: this.props.productModules || [],
                textField: data =>
                  `${data.absNr !== null ? `${data.absNr} - ` : "   "}${
                    data.name
                  }`,
                valueField: "nr",
              }}
              component={RenderSelect}
            />
          </div>
        );

      case 6:
        return (
          <div className="row">
            {showDownloadDiplomaButton && (
              <>
                <div className="col-xs-12 col-md-7">
                  <a
                    href="#"
                    onClick={handleDownload}
                    className="showDiploma form-group"
                  >
                    <File size={18} /> {t("Show certificate")}
                  </a>
                </div>
              </>
            )}
          </div>
        );
    }
  }

  renderPaymentStatus() {
    const { paymentsPaymentStatus } = this.props;
    let statusClassName = "";

    switch (paymentsPaymentStatus.code) {
      case CONTRACT_PAYMENT_STATUSE_CODES.CLEAR:
        statusClassName = "paymentStatus paymentStatus--grey";
        break;

      case CONTRACT_PAYMENT_STATUSE_CODES.EXPIRED:
        statusClassName = "paymentStatus paymentStatus--yellow";
        break;

      default:
        statusClassName = "paymentStatus";
    }

    return (
      <span className={statusClassName}>{paymentsPaymentStatus.name}</span>
    );
  }

  renderContractAdditionalFields() {
    const {
      t,
      tempContractStatusId,
      cantEditStatus,
      contractResignReasons,
      contractStatus,
    } = this.props;
    const statusId = tempContractStatusId.id
      ? tempContractStatusId.id
      : tempContractStatusId;
    const currentContractStatus = contractStatus.find(
      status => status.id === statusId,
    );
    const resignReasonSelectConfig = {
      data: contractResignReasons || [],
      textField: "name",
      valueField: "id",
    };
    switch (currentContractStatus.code) {
      case CONTRACT_STATUSES.CONTRACT_SEND:
        return (
          <div>
            <Field
              label={t("Contract number")}
              name="contract.nr"
              under={true}
              status="inactive"
              component={RenderText}
              shouldDisabled={cantEditStatus}
            />
            <Field
              label={t("Contract send date")}
              name="contract.sendDate"
              time={false}
              under={{ fieldClassName: "col-xs-12" }}
              component={RenderDateTime}
              shouldDisabled={cantEditStatus}
            />
          </div>
        );
      case CONTRACT_STATUSES.CONTRACT_SIGNED:
        return (
          <div>
            <Field
              label={t("Contract number")}
              name="contract.nr"
              under={true}
              status="inactive"
              component={RenderText}
              shouldDisabled={cantEditStatus}
            />
            <Field
              label={t("Date of signing the contract")}
              name="contract.signDate"
              time={false}
              under={{ fieldClassName: "col-xs-12" }}
              component={RenderDateTime}
              shouldDisabled={cantEditStatus}
            />
          </div>
        );
      case CONTRACT_STATUSES.CONTRACT_DENIED:
        return (
          <div>
            <Field
              label={t("Contract number")}
              name="contract.nr"
              under={true}
              status="inactive"
              component={RenderText}
              shouldDisabled={cantEditStatus}
            />
            <Field
              label={t("Reason for withdrawal")}
              under={{ fieldClassName: "col-xs-12" }}
              required={true}
              name="contract.withdrawReason"
              component={RenderSelect}
              dropdownConfig={resignReasonSelectConfig}
            />
            <Field
              label={t("Withdrawal description")}
              required={true}
              name="contract.withdrawDescription"
              under={true}
              component={RenderText}
              shouldDisabled={cantEditStatus}
            />
            <Field
              label={t("Withdrawal date")}
              name="contract.withdrawDate"
              time={false}
              under={{ fieldClassName: "col-xs-12" }}
              component={RenderDateTime}
              shouldDisabled={cantEditStatus}
            />
          </div>
        );
    }
  }

  changeStatus = (_, value) => {
    const { contractStatus, statusChanged } = this.props;
    const selectedContractStatus = contractStatus.find(
      status => status.id === value,
    );
    if (selectedContractStatus.code === CONTRACT_STATUSES.CONTRACT_CANCELED) {
      statusChanged(false);
    } else {
      statusChanged();
    }
  };

  onClearFinishReason = () => {
    const { change, untouch } = this.props;
    change("contract.finishReason", null);
    untouch("contract.finishReasonModule");
    untouch("contract.finishNote");
    untouch("contract.finishDate");
  };

  onChangeContractDocType = (value, _, fieldName) => {
    const { change } = this.props;
    change(fieldName, value.data);
  };

  componentDidMount() {
    const { change, contractDocTypes } = this.props;
    change("contractDocType", contractDocTypes[0]);
  }

  downloadContract = () => {
    const { contractId, getContract, contractDocType } = this.props;
    getContract(contractId, contractDocType);
  };

  render() {
    const {
      contractFinishReason,
      contractFinishReasons,
      t,
      preworkShareDate,
      cantEditFinishStatus,
      cantEditStatus,
      statusTransitions,
      fetchProductModules,
      contractDocTypes,
      contractId,
      contractStatus,
      contractStatusId,
      specialOffers,
      setSpecialOffer,
      clearSpecialOffer,
      setRebate,
      contractAmountGross,
      contractPackages,
      updatePackage,
      contractTypes,
      contractPayers,
      tempContractStatusId,
    } = this.props;
    let allowedStatuses = [
      CONTRACT_STATUSES.CONTRACT_SIGNED,
      CONTRACT_STATUSES.NEEDED_TERMS_EDITION,
    ];

    let currentStatus = contractStatus.find(function(status) {
      return status.id === contractStatusId;
    });
    const isContractSigned = allowedStatuses.includes(currentStatus.code);

    return (
      <div id="student-contract-status" className="row">
        <div className="col-xs-12 col-sm-4">
          <h2>{t("Contract")}</h2>
          <div style={{ display: "flex" }}>
            <div onClick={this.downloadContract} style={{ width: "10%" }}>
              <CustomIcon
                icon="zip"
                color="#4d4d4f"
                size="40px"
                viewBox="0 0 512 512"
              />
            </div>
            <div style={{ width: "90%" }}>
              <Field
                name="contractDocType"
                required={true}
                dropdownConfig={{
                  onChange: this.onChangeContractDocType,
                  data: (() =>
                    contractDocTypes.map(value => {
                      return { name: t(value), data: value };
                    }))(),
                  textField: "name",
                  valueField: "data",
                }}
                component={RenderSelect}
              />
            </div>
          </div>

          <Field
            id="contractStatus"
            label={t("Contract status")}
            name="contract.status"
            onChange={this.changeStatus}
            required={true}
            status={contractFinishReason > 0 ? "inactive" : ""}
            shouldDisabled={cantEditStatus}
            dropdownConfig={{
              data: statusTransitions || [],
              textField: "name",
              valueField: "id",
            }}
            component={RenderSelect}
            under={{ fieldClassName: "col-xs-12" }}
          />
          {this.renderContractAdditionalFields()}
          <hr />
          <div>
            <Authorize
              component={
                <div className="row">
                  <div className="col-xs-12" id="packageSection">
                    <Field
                      label={t("Package")}
                      name="payments.package"
                      required={true}
                      shouldDisabled={
                        tempContractStatusId.code ===
                          CONTRACT_STATUSES.CONTRACT_SIGNED ||
                        !havePermissions([
                          permissions.STUDENT_CONTRACT_PACKAGE_EDIT,
                        ])
                      }
                      status={
                        checkIfContractFinishReasonIs(
                          contractFinishReason,
                          CONTRACT_FINISH_REASONS.RESIGN,
                        ) ||
                        checkIfContractFinishReasonIs(
                          contractFinishReason,
                          CONTRACT_FINISH_REASONS.TRANSFER,
                        )
                          ? "inactive"
                          : ""
                      }
                      dropdownConfig={{
                        data: contractPackages,
                        textField: "name",
                        valueField: "id",
                      }}
                      onChange={updatePackage}
                      component={RenderSelect}
                    />
                  </div>
                </div>
              }
              allows={[permissions.STUDENT_CONTRACT_PACKAGE_VIEW]}
            />
            <div className="row">
              <div className="col-xs-12">
                <Field
                  label={t("Contract type")}
                  name="type"
                  required={true}
                  shouldDisabled={
                    tempContractStatusId.code ===
                      CONTRACT_STATUSES.CONTRACT_SIGNED ||
                    !havePermissions([permissions.STUDENT_CONTRACT_EDIT])
                  }
                  dropdownConfig={{
                    onChange: this.onChangeType,
                    data: contractTypes,
                    textField: "name",
                    valueField: "id",
                  }}
                  component={RenderSelect}
                />
              </div>
            </div>

            {laborOfficeIsExistInContractPayers(contractPayers) && (
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    label={t("Number of agreement with LO")}
                    name={`payments.laborOfficeContractNr`}
                    component={RenderText}
                  />
                </div>
              </div>
            )}
            <Authorize
              component={
                <ContractRebate
                  contractFinishReason={contractFinishReason}
                  specialOffers={specialOffers}
                  setSpecialOffer={setSpecialOffer}
                  setRebate={setRebate}
                  clearSpecialOffer={clearSpecialOffer}
                  disabled={
                    tempContractStatusId.code ===
                    CONTRACT_STATUSES.CONTRACT_SIGNED
                  }
                />
              }
              allows={[permissions.STUDENT_CONTRACT_INVOICE_VIEW]}
            />
            {isContractSigned ? (
              <div className="form-group row">
                <div className="col-sm-4 col-md-3">
                  <label htmlFor="">{t("Contracted value")}</label>
                </div>
                <div className="col-sm-8 col-md-9">
                  <input
                    className="form-control"
                    type="text"
                    value={contractAmountGross}
                    disabled
                  />
                </div>
              </div>
            ) : null}
            <Field
              labelForCheckbox={t(`Bought in shop`)}
              name={`contract.shop`}
              component={RenderCheckbox}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <h2>{t("Payments")}</h2>
          <div className="form-group row">
            <div className="col-xs-12">
              <label>{t("Payment status")}</label>
            </div>
            <div className="col-xs-12">{this.renderPaymentStatus()}</div>
            <div className="col-xs-12">
              <label>{t("Prework shared")}</label>
            </div>
            <div className="col-xs-12">
              <span className="paymentStatus">
                {preworkShareDate
                  ? moment(preworkShareDate).format(TIME.DATE_FORMAT)
                  : t("Not shared")}
              </span>
            </div>
          </div>
          <div id="student-contract-bank-accounts" className="row">
            <div className="col-xs-12">
              <h2>{t("Bank accounts")}</h2>
              <div className="row">
                <Authorize
                  component={<ContractBankAccounts />}
                  allows={[permissions.STUDENT_CONTRACT_BANK_ACCOUNT_VIEW]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <h2>{t("Course end")}</h2>
          <Authorize
            component={
              <Field
                title={t("Final status")}
                name="contract.finishReason"
                component={RenderFullWidthSelect}
                onClearCallback={this.onClearFinishReason}
                shouldDisabled={cantEditFinishStatus}
                dropdownConfig={{
                  onChange: value => {
                    this.setState({
                      contractFinishReason: value.id,
                    });
                    fetchProductModules(value.id);
                    this.props.change("contract.finishReason", value.id);
                    this.props.change("contract.finishReasonModule", null);
                  },
                  data: contractFinishReasons || [],
                  textField: "name",
                  valueField: "id",
                }}
              />
            }
            allows={[permissions.STUDENT_CONTRACT_FINISH_STATUS_VIEW]}
          />
          {havePermissions([permissions.STUDENT_CONTRACT_FINISH_STATUS_VIEW]) &&
            this.renderResignationAdditionalFields()}
          <Authorize
            component={
              <button
                id={`showStudentHistoryButton`}
                type="button"
                className="btn btn-dark"
                onClick={this.props.toggleSideModal}
              >
                {t("Show student's history")}
              </button>
            }
            allows={[permissions.STUDENT_HISTORY]}
          />
          <Notes contractId={contractId} />
        </div>
      </div>
    );
  }
}

export default translate()(ContractStatus);

ContractStatus.propTypes = {
  paymentsPaymentStatus: PropTypes.object,
  t: PropTypes.func,
  getContract: PropTypes.func,
  contractStatus: PropTypes.array,
  contractStatusId: PropTypes.number,
  contractFinishReason: PropTypes.number,
  preworkShareDate: PropTypes.number,
  contractFinishReasons: PropTypes.array,
  contractResignReasons: PropTypes.array,
  contractTransferReasons: PropTypes.array,
  coursesArray: PropTypes.array,
  courseSignature: PropTypes.string,
  currentProductVersion: PropTypes.string,
  productModules: PropTypes.array,
  finishReason: PropTypes.string,
  change: PropTypes.func,
  tempContractFinishReason: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  tempContractStatusId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  toggleSideModal: PropTypes.func,
  loadModules: PropTypes.func,
  searchCourse: PropTypes.func,
  cantEditStatus: PropTypes.bool,
  cantEditFinishStatus: PropTypes.bool,
  handleDownload: PropTypes.func,
  statusChanged: PropTypes.func,
  untouch: PropTypes.func,
  fetchProductModules: PropTypes.func,
  statusTransitions: PropTypes.array,
  contractDocTypes: PropTypes.array,
  contractDocType: PropTypes.string,
  contractId: PropTypes.number,
  specialOffers: PropTypes.array,
  contractPackages: PropTypes.array,
  contractTypes: PropTypes.array,
  setSpecialOffer: PropTypes.func,
  clearSpecialOffer: PropTypes.func,
  setRebate: PropTypes.func,
  contractAmountGross: PropTypes.string,
  updatePackage: PropTypes.func,
  contractPayers: PropTypes.array,
};
