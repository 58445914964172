import React from "react";
import { InjectedTranslateProps } from "react-i18next/src/props";
import CustomModal from "../../../../Common/Components/CustomModal/CustomModal";

type ConfirmModalProps = InjectedTranslateProps & {
  invoiceCount: number;
  isOpen: boolean;
  handleClose: () => void;
  handleSend: () => void;
};

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({
  t,
  isOpen,
  invoiceCount,
  handleClose,
  handleSend,
}): React.ReactElement => {
  return (
    <div>
      <CustomModal
        isOpen={isOpen}
        title={t("Are you sure you want to send the invoices?")}
        onRequestClose={handleClose}
      >
        <div className="padding__b20 text-success">
          {`${t("Send")} ${invoiceCount} ${t(
            invoiceCount > 1 ? "invoices" : "invoice",
          )}`}
        </div>
        <div>
          <button className={"btn btn-dark margin__r15"} onClick={handleSend}>
            {t("Send")}
          </button>
          <button className={"btn btn-danger"} onClick={handleClose}>
            {t("Cancel")}
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default ConfirmModal;
