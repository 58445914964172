import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import moment from "moment";
import ACTION_TYPES from "../../Enums/ActionTypes";

export function getJwt(params) {
  const options = {
    googleAccessToken: "",
    email: "",
    name: "",
    image: "",
    ...params,
  };
  const { googleAccessToken, email, name, image } = options;

  if (localStorage.bertaSettings) {
    const bertaSettings = JSON.parse(localStorage.getItem("bertaSettings")),
      newStorage = bertaSettings ? { ...bertaSettings } : {},
      currDate = moment().valueOf();
    if (currDate < bertaSettings.tokenExpiryTime) {
      return {
        type: ACTION_TYPES.GET_JWT_LOCAL,
        payload: { ...newStorage },
      };
    }
  }

  if (googleAccessToken.length > 0 && email.length > 0) {
    const config = {
      path: `${restApiConfig.API_URL}/public/getJWT`,
      privateRequest: false,
      params: {
        email: email,
        accessToken: googleAccessToken,
      },
      callback: ({ data }, dispatch) => {
        localStorage.setItem(
          "bertaSettings",
          JSON.stringify({
            ...data.data,
            userName: name,
            imageUrl: image,
          }),
        );
        dispatch({
          type: ACTION_TYPES.GET_JWT_SERVER,
          payload: {
            ...data.data,
            userName: name,
            imageUrl: image,
          },
        });
      },
    };
    return Client.actionGET(config);
  }

  return {
    type: "",
    payload: null,
  };
}

export function getJwtByPass(params) {
  const { email, password } = {
    email: "",
    password: "",
    ...params,
  };

  if (localStorage.bertaSettings) {
    const bertaSettings = JSON.parse(localStorage.getItem("bertaSettings")),
      newStorage = bertaSettings ? { ...bertaSettings } : {},
      currDate = moment().valueOf();
    if (currDate < bertaSettings.tokenExpiryTime) {
      return {
        type: ACTION_TYPES.GET_JWT_LOCAL,
        payload: { ...newStorage },
      };
    }
  }

  if (email.length > 0 && password.length > 0) {
    const config = {
      path: `${restApiConfig.API_URL}/public/password/getJWT`,
      privateRequest: false,
      params: {
        email: email,
        password: password,
      },
      callback: ({ data }, dispatch) => {
        localStorage.setItem(
          "bertaSettings",
          JSON.stringify({
            ...data.data,
            userName: data.data.nameSurname,
            imageUrl: "",
          }),
        );
        dispatch({
          type: ACTION_TYPES.GET_JWT_SERVER_PASS,
          payload: {
            ...data.data,
            userName: data.data.nameSurname,
            imageUrl: "",
          },
        });
      },
    };
    return Client.actionGET(config);
  }

  return {
    type: "",
    payload: null,
  };
}

export function forgetUser() {
  localStorage.removeItem("bertaSettings");
  return {
    type: ACTION_TYPES.GET_JWT_LOCAL,
    payload: {
      token: "",
      refreshToken: "",
      tokenExpiryTime: null,
      userId: null,
      personId: null,
      employeeId: null,
      userName: "",
      imageUrl: "",
    },
  };
}

export function fetchRefreshToken(refresh_token = null) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/token/refresh`,
    privateRequest: false,
    actionType: ACTION_TYPES.FETCH_REFRESH_TOKEN,
    values: { refresh_token },
    callback: ({ data }) => {
      const bertaSettings = JSON.parse(localStorage.getItem("bertaSettings"));
      const newStorage = {
        ...bertaSettings,
        ...data.data,
      };
      localStorage.setItem("bertaSettings", JSON.stringify({ ...newStorage }));
    },
  });
}

export function getPermissionsList() {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/acl/accessType`,
    actionType: ACTION_TYPES.GET_PERMISSIONS_LIST,
  });
}

export function getUserPermissions(
  userId,
  actionType = ACTION_TYPES.GET_USER_PERMISSIONS,
  callback,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/acl/user/${userId}`,
    actionType,
    callback,
  });
}

export function getRolesList() {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/acl/role`,
    actionType: ACTION_TYPES.GET_ROLES_LIST,
  });
}
