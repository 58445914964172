import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { fetchAllProducts, fetchEmployees, copyProduct } from "../Actions";
import { HelpCircle, PlusCircle, Search } from "react-feather";
import ProductsList from "../Components/ProductsList";
import {
  Authorize,
  havePermissions,
  permissions,
} from "../../../Common/Utils/Acl";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { RenderSelect } from "../../../Forms/forms";
import PropTypes from "prop-types";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";
import COMMON from "../../../Enums/Common";
import SecondaryMenu from "../../App/Components/MainTemplateSecondaryMenu";

const FORM_NAME = "FILTER_PRODUCTS";
const START_PAGE = 1;

class Products extends Component {
  static propTypes = {
    fetchEmployees: PropTypes.func,
    fetchDictionary: PropTypes.func,
    fetchAllProducts: PropTypes.func,
    productsList: PropTypes.object,
    change: PropTypes.func,
    copyProduct: PropTypes.func,
    coursesKinds: PropTypes.array,
    t: PropTypes.func,
    employees: PropTypes.array,
    secondaryMenu: PropTypes.object,
  };
  #numberOfRowPerPage = 10;
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    this.state = {
      filters: {},
      shouldRender: false,
      currentPage: START_PAGE,
      productsList: [],
      counter: 1,
    };
    const { fetchEmployees, fetchDictionary, fetchAllProducts } = props;
    fetchDictionary(this.cancelToken, {
      path: "/languages",
      actionType: ACTION_TYPES.FETCH_LANGUAGES,
    });
    fetchDictionary(this.cancelToken, {
      path: "/product/kinds",
      actionType: ACTION_TYPES.FETCH_PRODUCTS_KINDS,
    });
    fetchEmployees(this.cancelToken);
    fetchAllProducts(
      this.cancelToken,
      {
        pageNo: START_PAGE,
      },
      this.showList,
    );

    this.canAddNewProduct = havePermissions([permissions.PRODUCT_DETAILS_EDIT]);
    this.itemPermissions = {
      canRedirectToProduct: havePermissions([permissions.PRODUCT_DETAILS_VIEW]),
      canCopyProduct: havePermissions([permissions.PRODUCT_COPY]),
    };
  }

  componentDidUpdate() {
    const { productsList } = this.props;
    if (
      productsList.items &&
      !_.isEqual(productsList.items, this.state.productsList)
    ) {
      this.setState({
        productsList: productsList.items,
        counter: productsList.counter,
      });
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  removeEmptyFilters = object => {
    const filters = { ...object };
    for (let propName in filters) {
      if (filters[propName] === "") {
        delete filters[propName];
      }
    }
    return filters;
  };

  filter(value, filter) {
    const { change, fetchAllProducts } = this.props;
    const updatedFilters = this.removeEmptyFilters({
      ...this.state.filters,
      [filter]: value.id,
    });
    this.setState(
      {
        shouldRender: false,
        filters: updatedFilters,
      },
      () => {
        fetchAllProducts(
          this.cancelToken,
          { ...updatedFilters, pageNo: START_PAGE },
          () => {
            change(filter, value.name);
            this.setState({
              shouldRender: true,
              currentPage: START_PAGE,
            });
          },
        );
      },
    );
  }

  copyProduct = productId => {
    const { copyProduct, fetchAllProducts } = this.props;
    this.setState({ shouldRender: false });
    copyProduct(productId, () => {
      fetchAllProducts(this.cancelToken, this.state.filters, () => {
        this.setState({ shouldRender: true });
      });
    });
  };

  renderFilters() {
    const coursesKinds = [...this.props.coursesKinds];

    const status = [
      {
        id: PRODUCT_STATUSES.ACTIVE,
        name: this.props.t("Active"),
      },
      {
        id: PRODUCT_STATUSES.INACTIVE,
        name: this.props.t("Inactive"),
      },
      {
        id: PRODUCT_STATUSES.DRAFT,
        name: this.props.t("Draft"),
      },
    ];

    const employees = this.props.employees.map(element => {
      return {
        id: element.id,
        name: element.person.surname + " " + element.person.name,
        email: element.person.email.email,
        surname: element.person.surname,
      };
    });
    const sortedEmployees = _.sortBy(employees, "surname");

    return (
      <div className="row products__filters">
        <Field
          component={RenderSelect}
          name="kind_id"
          dropdownConfig={{
            data: coursesKinds,
            textField: "name",
            valueField: "id",
            placeholder: this.props.t("Product type"),
            onChange: value => this.filter(value, "kind_id"),
          }}
          under={{
            containerClassName: "col-sm-3 col-xs-6",
            fieldClassName: "col-xs-12",
          }}
          onClearCallback={() =>
            this.filter(
              {
                id: null,
                name: null,
              },
              "kind_id",
            )
          }
        />
        <Field
          component={RenderSelect}
          name="status"
          dropdownConfig={{
            data: status,
            textField: "name",
            valueField: "id",
            placeholder: this.props.t("Product status"),
            onChange: value => this.filter(value, "status"),
          }}
          under={{
            containerClassName: "col-sm-3 col-xs-6",
            fieldClassName: "col-xs-12",
          }}
          onClearCallback={() =>
            this.filter(
              {
                id: null,
                name: null,
              },
              "status",
            )
          }
        />
        <Field
          component={RenderSelect}
          name="business_owner_id"
          dropdownConfig={{
            data: sortedEmployees,
            textField: "name",
            valueField: "id",
            placeholder: this.props.t("Bussines owner"),
            onChange: value => this.filter(value, "business_owner_id"),
          }}
          under={{
            containerClassName: "col-sm-3 col-xs-6",
            fieldClassName: "col-xs-12",
          }}
          onClearCallback={() =>
            this.filter(
              {
                id: null,
                name: null,
              },
              "business_owner_id",
            )
          }
        />
        <Field
          component={RenderSelect}
          name="essential_owner_id"
          dropdownConfig={{
            data: sortedEmployees,
            textField: "name",
            valueField: "id",
            placeholder: this.props.t("Essential owner"),
            onChange: value => this.filter(value, "essential_owner_id"),
          }}
          under={{
            containerClassName: "col-sm-3 col-xs-6",
            fieldClassName: "col-xs-12",
          }}
          onClearCallback={() =>
            this.filter(
              {
                id: null,
                name: null,
              },
              "essential_owner_id",
            )
          }
        />
      </div>
    );
  }

  showList = () => {
    this.setState({
      shouldRender: true,
    });
  };

  changePage = currentPage => {
    const { fetchAllProducts } = this.props;
    const { filters } = this.state;
    this.setState({ currentPage, shouldRender: false }, () => {
      fetchAllProducts(
        this.cancelToken,
        {
          pageNo: this.state.currentPage,
          ...filters,
        },
        this.showList,
      );
    });
  };

  render() {
    const { productsList, counter, currentPage, shouldRender } = this.state;
    const pages = Math.ceil(counter / this.#numberOfRowPerPage);

    const noData = !productsList.length && (
      <div className={`row product-item`}>
        <div className={`vertical-center horizontal-center`}>
          <p>{this.props.t("No records found")}</p>
        </div>
      </div>
    );

    return (
      <main>
        <div className="custom-container">
          <header className="main-header">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6 pull-right main-header__flex">
                <Authorize
                  component={
                    <div className="col-md-8 col-xs-12">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <Search size={16} />
                        </div>
                        <Field
                          className="form-control"
                          name="search"
                          component="input"
                          type="text"
                          placeholder={this.props.t(`Product name`)}
                          onChange={(event, newValue) =>
                            this.filter({ id: newValue }, "search")
                          }
                        />
                      </div>
                    </div>
                  }
                  allows={[permissions.PRODUCT_LIST]}
                />
                <button
                  onClick={this.logout}
                  className="btn btn--logout pull-right"
                >
                  {this.props.t("Log out")}
                </button>
                <a
                  href={COMMON.URL.MANUAL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--logout pull-right left-space"
                >
                  <HelpCircle size={16} /> {this.props.t("Help")}
                </a>
              </div>

              <div className="col-sm-4 col-md-6 col-lg-6">
                <h1>{this.props.t("Products")}</h1>
              </div>
            </div>
          </header>
          <SecondaryMenu links={this.props.secondaryMenu} />
        </div>
        <div className=" row">
          <div className="custom-container">
            <div className="products">
              {this.canAddNewProduct && (
                <div className="row products__nav">
                  <div id="add-new-product" className="col-sm-4">
                    <Link
                      to="/products/new"
                      className="btn btn-regular btn-link btn-with-icon"
                    >
                      <PlusCircle size={20} />
                      {this.props.t("Add new product")}
                    </Link>
                  </div>
                </div>
              )}
              {this.renderFilters()}
              <ProductsList
                products={productsList}
                copyProduct={this.copyProduct}
                shouldRender={shouldRender}
                itemPermissions={this.itemPermissions}
              />
              {noData}
              <Paginator
                pages={pages || 0}
                onClick={this.changePage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDictionary,
      fetchEmployees,
      fetchAllProducts,
      copyProduct,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { dictionaries, employees, productsList } = state;
  const { coursesKinds } = dictionaries;
  return {
    coursesKinds,
    employees,
    productsList,
  };
}

export default reduxForm({
  form: FORM_NAME,
  initialValues: {},
})(
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(Products),
);
