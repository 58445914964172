import React from "react";
import Contract from "../Components/Contract";
import { fetchContract, fetchPayments, fetchContractMeta } from "../Actions";
import { fetchInvoices } from "../../Invoices/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { resetCourse } from "../../Courses/Actions/CoursesActions";
import { fetchContractPolls, sendPoll } from "../../Dictionaries/Actions";
import {
  fetchProductModulesInstallmentStartSimple,
  fetchProductModulesSimple,
} from "../../Products/Actions";

let ContractDetails = props => <Contract {...props} />;

function mapStateToProps(state) {
  let {
    contract,
    contractPolls,
    contractModulesPolls,
    contractSlavePolls,
    contractManualPolls,
    payments,
  } = state;
  return {
    contract,
    contractPolls,
    contractModulesPolls,
    contractSlavePolls,
    contractManualPolls,
    payments,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchContract,
      resetCourse,
      fetchPayments,
      fetchContractPolls,
      sendPoll,
      fetchInvoices,
      fetchContractMeta,
      fetchProductModulesSimple,
      fetchProductModulesInstallmentStartSimple,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractDetails);
