export enum InvoiceSendStatus {
  NOT_SENT = "not_sent",
  READY_TO_SEND = "ready_to_send",
  SENT = "sent",
  IN_REVIEW = "in_review",
  IN_SEND = "in_send",
  IN_SEND_RETRY = "in_send_retry",
  SENT_ERROR = "sent_error",
  NOT_HANDLED = "not_handled",
}
