import React, { Component } from "react";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import ReactTable from "react-table";
import getNestedObject from "../../../Helpers/GetNestedObject";
import { Field } from "redux-form";
import { RenderDateTime, RenderText } from "../../../Forms/forms";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import Switch from "react-switch";
import { getDate } from "../../../Common/Utils/DateFormatters";

const FORM_ACTIONS = {
  SAVE: "SAVE",
  CREATE: "CREATE",
};

class Promotions extends Component {
  cancelToken = getCancelToken();
  state = {
    editing: [],
    isLoading: false,
  };

  componentDidMount() {
    const { fetchDictPromotions } = this.props;
    fetchDictPromotions(this.cancelToken);
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize } = this.props;
    if (
      initialValues.dictPromotions !== prevProps.initialValues.dictPromotions
    ) {
      initialize(initialValues);
    }
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;

    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }

  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`dictPromotions[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  renderTimeEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="">
            <Field
              value={value}
              name={`dictPromotions[${row.index}][${row.column.id}]`}
              component={RenderDateTime}
              time={false}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={getDate(value)}
          />
        )}
      </div>
    );
  };

  renderStatusEditField = row => {
    const { values, change, t } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField text-center">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <Switch
            onChange={value =>
              change(
                `dictPromotions[${row.index}][${row.column.id}]`,
                value ? 1 : 0,
              )
            }
            checked={value}
          />
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            component={<div>{value ? t("Active") : t("Inactive")}</div>}
          />
        )}
      </div>
    );
  };

  addDictPromotions = () => {
    const { change, values } = this.props;
    const newValues = [
      {
        name: null,
        status: 0,
      },
      ...values,
    ];
    change("dictPromotions", newValues);
  };

  onSubmit = values => {
    const { action } = this.state;

    const {
      addDictPromotion,
      fetchDictPromotions,
      updateDictPromotion,
    } = this.props;
    const dictPromotions = values.dictPromotions.find(
      dictPromotions => dictPromotions.id === this.state.rowId,
    );

    switch (action) {
      case FORM_ACTIONS.SAVE: {
        updateDictPromotion(dictPromotions, () => {
          fetchDictPromotions(this.cancelToken);
          const { editing } = this.state;
          const rowIndex = editing.indexOf(dictPromotions.id);

          rowIndex >= 0 && editing.splice(rowIndex, 1);
          this.setState({
            editing,
          });
        });

        break;
      }
      case FORM_ACTIONS.CREATE: {
        addDictPromotion(dictPromotions, () =>
          fetchDictPromotions(this.cancelToken),
        );
        break;
      }
    }
  };

  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    const submitter = handleSubmit(this.onSubmit);

    this.setState(
      {
        action,
        rowId,
      },
      submitter,
    );
  };

  render() {
    const { isLoading } = this.state;
    const { t, values } = this.props;
    const addButtonDisabled = values && values.find(value => !value.id);

    return (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={addButtonDisabled}
                className="btn bottom-space btn btn-dark"
                onClick={this.addDictPromotions}
              >
                {t("Add special offer")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={isLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("NameItem"),
                accessor: "name",
                Cell: this.renderEditField,
              },
              {
                Header: t("Description"),
                accessor: "description",
                Cell: this.renderEditField,
              },
              {
                Header: t("Status"),
                accessor: "status",
                Cell: this.renderStatusEditField,
              },
              {
                Header: t("Start At"),
                accessor: "startDate",
                Cell: this.renderTimeEditField,
              },
              {
                Header: t("End At"),
                accessor: "endDate",
                Cell: this.renderTimeEditField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() =>
                          this.submitForm(FORM_ACTIONS.SAVE, rowData.id)
                        }
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() =>
                        this.submitForm(FORM_ACTIONS.CREATE, rowData.id)
                      }
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
      </div>
    );
  }
}

Promotions.propTypes = {
  t: PropTypes.func,
  change: PropTypes.func,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.object,
  fetchDictPromotions: PropTypes.func,
  updateDictPromotion: PropTypes.func,
  addDictPromotion: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  ),
};

export default translate()(Promotions);
