import React from "react";
import { AmountWithCurrency } from "../Types/CurrentProductVersionType";
import renderAmountWithCurrency from "../../../Helpers/RenderAmountWithCurrency";

type TotalPricesProps = {
  prices: Record<string, AmountWithCurrency> | null;
  currency: string;
  t: (key: string) => string;
};

const TotalPrices: React.FunctionComponent<TotalPricesProps> = ({
  prices,
  currency,
  t,
}): React.ReactElement => {
  const mappedPrices = prices
    ? Object.keys(prices).map(key => ({
        ...prices[key],
        name: key,
      }))
    : [];

  return (
    <div>
      {mappedPrices.map((totalPrice, index) => {
        return (
          <div key={index} className={`form-group row`}>
            <div className={`col-sm-4 col-md-3`}>
              <label>{`${t("Price")} (${currency}) ${totalPrice.name}`}</label>
            </div>
            <div className={`col-sm-8 col-md-9`}>
              <div className={`row`}>
                <div className={`col-sm-12 col-md-6`}>
                  <div className={`form-group row packagePrice`}>
                    <div className="col-xs-12">
                      <div>
                        {renderAmountWithCurrency({
                          amount: totalPrice.amount,
                          currency: totalPrice.currency,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-sm-12 col-md-6`}>
                  <div className={`form-group row packagePrice`}>
                    <div className="col-xs-12">
                      <div>
                        {renderAmountWithCurrency({
                          amount: totalPrice.amount,
                          currency: totalPrice.currency,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TotalPrices;
