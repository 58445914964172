import TenantConfig from "../../../../../../config/tenant.yml";
import { ValidatorFactory } from "../Validators/Validator";

export function Tenant({ component, feature }) {
  return feature === true && component;
}

export const Features = TenantConfig.tenant.features;
export const InstanceCountryCode = TenantConfig.tenant.settings.country_code;
export const TenantValidator = ValidatorFactory.Validator(
  TenantConfig.tenant.settings.country_code,
);
