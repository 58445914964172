export const isRomanianCNPValid = value => {
  let regExp = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/;
  let bigSum = 0;
  let control = "279146358279";

  if (!regExp.test(value)) {
    return false;
  }
  for (let i = 0; i < 12; i++) {
    bigSum += value[i] * control[i];
  }
  let ctrlDigit = bigSum % 11;
  if (ctrlDigit === 10) {
    ctrlDigit = 1;
  }
  return ctrlDigit !== parseInt(value[12], 10);
};

export const isRomanianTaxIDValid = value => {
  // Checks the check digits of a Romanian VAT number.
  let multipliers = [7, 5, 3, 2, 1, 7, 5, 3, 2];
  // Extract the next digit and multiply by the counter.
  multipliers = multipliers.slice(10 - value.length);
  let total = 0;
  for (let i = 0; i < value.length - 1; i++) {
    total = total + Number(value.charAt(i)) * multipliers[i];
  }
  // Establish check digits by getting modulus 11.
  total = (10 * total) % 11;
  if (total === 10) {
    total = 0;
  }
  // Compare it with the last character of the VAT number. If it is the same, then it's a valid
  // check digit.
  return total == value.slice(value.length - 1, value.length);
};
