const API_URL = "https://hub.coderslab.pl/api";
const baseUrl = window.location.origin;

export default {
  API_URL,
  baseUrl,
  googleClientId: "860288514084-482r95vbch0p8nssj6kbv2f7hm4tmbba.apps.googleusercontent.com",
  googleClientSecret: "F8vT9Gn-wAX0HYMDx5tRvqgo",
  sentryDsn: "https://f31b322f47eb40feb4d7011d0c2c63c0@sentry.io/1318642"
};

