import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { PlusCircle, Save } from "react-feather";
import { translate } from "react-i18next";
import { Trash } from "react-feather";
import { RenderMultiselect, RenderSelect } from "../../../Forms/forms";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

class ProductsAdditionalPolls extends React.Component {
  addPoll = () => {
    this.props.fields.push({ poll: null, package: null });
  };

  removePoll = index => {
    this.props.fields.remove(index);
  };

  render() {
    const { fields, t, cantEdit, status, saveAdditionalPolls } = this.props;

    return (
      <div className="additional-polls">
        <div className="row pull-left col-xs-12">
          <div className="row col-sm-12 col-md-11">
            <h3>{t("Additional surveys")}</h3>
          </div>
          <div className={`col-sm-12 col-md-1`}>
            <button
              disabled={cantEdit}
              id="addAdditionalPoll"
              type="button"
              className="btn btn-with-icon btn--transparent btn--no-border additional-polls__add-poll"
              onClick={() => {
                this.addPoll();
              }}
            >
              <PlusCircle size={20} />
              {t("Add survey")}
            </button>
          </div>
        </div>
        <div className="row pull-left col-xs-12">
          <div className="col-xs-12">
            {fields.map((field, index) => (
              <div className="poll-group--additional" key={index}>
                <div>
                  <Field
                    component={RenderSelect}
                    under
                    shouldDisabled={
                      cantEdit ||
                      !havePermissions([permissions.PRODUCT_POLL_EDIT])
                    }
                    dropdownConfig={{
                      valueField: "id",
                      textField: "name",
                      placeholder: t("Choose survey"),
                      data: this.props.pollsList,
                    }}
                    name={`${field}.poll`}
                    status={status}
                  />
                </div>
                <div>
                  <Field
                    component={RenderMultiselect}
                    under
                    shouldDisabled={
                      cantEdit ||
                      !havePermissions([permissions.PRODUCT_POLL_EDIT])
                    }
                    status={status}
                    dropdownConfig={{
                      valueField: "id",
                      textField: "name",
                      placeholder: t("Choose packages"),
                      data: this.props.packages,
                    }}
                    name={`${field}.packages`}
                  />
                </div>
                <div>
                  <button
                    disabled={cantEdit}
                    type="button"
                    onClick={() => {
                      this.removePoll(index);
                    }}
                    className="btn btn-with-icon btn--transparent btn--no-border"
                  >
                    <Trash size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-xs-12`}>
            <button
              className="btn btn-dark"
              type="button"
              disabled={cantEdit}
              onClick={() => saveAdditionalPolls()}
            >
              <Save size={20} />
              {t("Save additional polls")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(ProductsAdditionalPolls);

ProductsAdditionalPolls.propTypes = {
  fields: PropTypes.object,
  t: PropTypes.func,
  pollsList: PropTypes.array,
  packages: PropTypes.array,
  cantEdit: PropTypes.bool,
  status: PropTypes.string,
  saveAdditionalPolls: PropTypes.func,
};
