import restApiConfig from "../../Configs/restApiConfig";
import Client from "../../Common/Utils/ClientApi";
import ACTION_TYPES from "../../Enums/ActionTypes";
import COMMON from "../../Enums/Common";

export function updateProductVersion(values = {}, versionId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}`,
    actionType: ACTION_TYPES.SAVE_PRODUCT_VERSION,
    values: values,
    callback,
  };

  return Client.actionPUT(config);
}

export function changeProductVersionStatus(versionId, status, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/status`,
    values: { status },
    actionType: ACTION_TYPES.INACTIVE_PRODUCT_VERSION,
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function deleteProductVersion(versionId, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}`,
    actionType: ACTION_TYPES.DELETE_PRODUCT_VERSION,
    callback: callback,
  };

  return Client.actionDELETE(config);
}

export function saveProductVersion(values = {}, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/products`,
    actionType: ACTION_TYPES.SAVE_PRODUCT_VERSION,
    values: values,
    callback: callback,
  };

  return Client.actionPOST(config);
}

export function fetchProductById(cancelToken, versionId, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}`,
    actionType: ACTION_TYPES.FETCH_PRODUCT,
    callback,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  });
}

export function fetchProductPricesById(
  cancelToken,
  versionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/prices`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_PRICES,
    callback,
    cancelToken,
  });
}

export function createProductModules(versionId, values, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules`,
    actionType: ACTION_TYPES.CREATE_PRODUCT_MODULES,
    values,
    callback: callback,
  };

  return Client.actionPOST(config);
}

export function updateProductModules(
  versionId,
  moduleId,
  values,
  callback = null,
) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules/${moduleId}`,
    actionType: ACTION_TYPES.UPDATE_PRODUCT_MODULES,
    values,
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function createProductModulesLectures(
  versionId,
  moduleId,
  values,
  callback = null,
) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules/${moduleId}/lectures`,
    actionType: ACTION_TYPES.CREATE_PRODUCT_MODULES_LECTURES,
    values,
    callback: callback,
  };

  return Client.actionPUT(config);
}

export function fetchProductModules(
  cancelToken,
  versionId,
  callback = null,
  params = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_MODULES,
    callback,
    params,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  });
}

export function clearProductVersionModules() {
  return {
    type: ACTION_TYPES.CLEAR_PRODUCT_MODULES,
  };
}

export function fetchProductModulesSimple(
  cancelToken,
  versionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_MODULES_SIMPLE,
    params: { simpleResponse: true },
    callback,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  });
}

export function fetchProductModulesInstallmentStartSimple(
  cancelToken,
  versionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_MODULES_SIMPLE,
    params: { simpleResponse: true, installmentStart: true },
    callback,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  });
}

export function fetchVersionsOfProduct(
  cancelToken,
  productId,
  callback = null,
) {
  const config = {
    path: `${restApiConfig.API_URL}/products/${productId}/versions`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_VERSIONS,
    callback,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  };

  return Client.actionGET(config);
}

export function fetchProductEvents(
  cancelToken,
  productId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${productId}/events`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_EVENTS,
    callback,
    callGlobalLoader,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  };

  return Client.actionGET(config);
}

export function addProductEvent(
  productId,
  values,
  callback = null,
  callGlobalLoader = true,
) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/productVersions/${productId}/events`,
    type: ACTION_TYPES.ADD_PRODUCT_EVENTS,
    callback,
    callGlobalLoader,
    values,
  });
}

export function removeProductEvent(
  productId,
  eventId,
  callback = null,
  callGlobalLoader = true,
) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${productId}/event/${eventId}`,
    actionType: ACTION_TYPES.DELETE_PRODUCT_EVENTS,
    callback: callback,
    callGlobalLoader,
  };

  return Client.actionDELETE(config);
}

export function fetchEmployees(cancelToken, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/employee`,
    actionType: ACTION_TYPES.FETCH_EMPLOYEES,
    callback,
    cancelToken,
  });
}

// TODO: remove after refactor courses module

export function fetchAllProducts(cancelToken, params = {}, callback = null) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions`,
    actionType: ACTION_TYPES.FETCH_PRODUCTS,
    params,
    callback,
    apiVersion: COMMON.API_VERSION_V1,
    cancelToken,
  });
}
export function fetchLecturesForImport(cancelToken, callback) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/import`,
    actionType: ACTION_TYPES.FETCH_LECTURES_FOR_IMPORT,
    callback,
    cancelToken,
  });
}
export function copyProduct(productId, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/products/${productId}/copies`,
    actionType: ACTION_TYPES.COPY_PRODUCT,
    callback,
  };

  return Client.actionPOST(config);
}
export function copyVersionProduct(productVersionId, callback = null) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${productVersionId}/copies`,
    actionType: ACTION_TYPES.COPY_PRODUCT,
    callback,
  };

  return Client.actionPOST(config);
}

export function fetchProductsForCourses(
  cancelToken,
  params = null,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/products/courseFilter`,
    actionType: ACTION_TYPES.FETCH_PRODUCTS_FOR_COURSES,
    params,
    callback,
    cancelToken,
  });
}

export function getLectureData(lectureData) {
  return {
    type: ACTION_TYPES.GET_LECTURE_DATA,
    payload: lectureData,
  };
}
export function resetProduct() {
  return {
    type: ACTION_TYPES.RESET_PRODUCT,
    payload: {},
  };
}

export function priceNetCalculator(values, callback) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/priceCalculator/nettToGross`,
    type: ACTION_TYPES.PRICE_CALCULATOR,
    callback,
    values,
  });
}
export function priceGrossCalculator(values, callback) {
  return Client.actionPOST({
    path: `${restApiConfig.API_URL}/priceCalculator/grossToNett`,
    type: ACTION_TYPES.PRICE_CALCULATOR,
    callback,
    values,
  });
}

export function fetchAllProductsFullInfo(
  cancelToken,
  productId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/products`,
    actionType: ACTION_TYPES.FETCH_ALL_PRODUCTS_FULL_INFO,
    callback,
    cancelToken,
  });
}

export function clearProductsList() {
  return {
    type: ACTION_TYPES.CLEAR_PRODUCTS_LIST,
    payload: [],
  };
}

export function fetchProductVersionsMeta(
  cancelToken,
  params = null,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/meta`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_VERSIONS_META,
    params,
    callback,
    cancelToken,
  });
}

export function deleteProductModule(versionId, moduleId, callback) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules/${moduleId}`,
    actionType: ACTION_TYPES.DELETE_PRODUCT_MODULE,
    callback: callback,
  };

  return Client.actionDELETE(config);
}
export function fetchProductVersionPolls(
  cancelToken,
  versionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/pollPackage/${versionId}`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_POLLS,
    callback,
    cancelToken,
  });
}

export function modulesOrderChange(productId, values, callback = () => {}) {
  const config = {
    path: `${restApiConfig.API_URL}/productVersions/${productId}/modulesOrder`,
    actionType: ACTION_TYPES.MODULES_ORDER,
    callback,
    values,
  };

  return Client.actionPUT(config);
}

export function fetchProductModulesForSlave(
  cancelToken,
  versionId,
  callback = null,
) {
  return Client.actionGET({
    path: `${restApiConfig.API_URL}/productVersions/${versionId}/modules`,
    actionType: ACTION_TYPES.FETCH_PRODUCT_MODULES_FOR_SLAVE,
    params: { simpleResponse: true, slaveOnly: true },
    callback,
    cancelToken,
    apiVersion: COMMON.API_VERSION_V1,
  });
}
