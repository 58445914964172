export default fields => {
  let errors = {};
  const { description, deadline, amountGross, type, balanceId } = fields;
  if (!description) {
    errors.description = "Enter subject of the fee";
  }
  if (!deadline) {
    errors.deadline = "Enter deadline of the fee";
  }
  if (!amountGross) {
    errors.amountGross = "Enter amount gross";
  }
  if (!type) {
    errors.type = "Enter payments type";
  }
  if (!balanceId) {
    errors.balanceId = "Choose payer";
  }
  return errors;
};
