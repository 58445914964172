import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { XCircle } from "react-feather/dist/index";

class SelectMultipleProductWithPagingForFilters extends Component {
  state = {
    searchProductQuery: "",
    isLoadingProducts: false,
    selected: [],
  };

  getRequestConfig = (search = null, counter = null) => {
    const ACTIVE = 2;
    const START_PAGE = 1;

    const config = {
      simpleResponse: true,
      status: ACTIVE,
    };

    if (counter) {
      config.pageNo = START_PAGE;
      config.pageAmount = counter;
    }

    if (search) {
      config.search = search;
    }
    return config;
  };

  handleSearch = query => {
    const COUNTER_FOR_PAGING = 11;
    const { fetchProducts } = this.props;
    this.setState(
      { isLoadingProducts: true, searchProductQuery: query },
      () => {
        const { searchProductQuery } = this.state;
        fetchProducts(
          this.cancelToken,
          this.getRequestConfig(searchProductQuery, COUNTER_FOR_PAGING),
          () => this.setState({ isLoadingProducts: false }),
        );
      },
    );
  };

  onShowMoreResults = (event, counter) => {
    const { fetchProducts } = this.props;
    const counterForPaging = counter + 1;

    this.setState({ isLoadingProducts: true }, () => {
      const { searchProductQuery } = this.state;
      fetchProducts(
        this.cancelToken,
        this.getRequestConfig(searchProductQuery, counterForPaging),
        () => this.setState({ isLoadingProducts: false }),
      );
    });
  };

  onChangeProduct = product => {
    const { onChangeProduct } = this.props;
    this.setState({ selected: product });
    onChangeProduct(product);
  };

  onClear = () => {
    const { onClear } = this.props;
    this.setState({ selected: [] }, onClear);
  };

  render() {
    const {
      t,
      under,
      label,
      products,
      onClear,
      customItemRender,
      placeholder,
    } = this.props;
    const { isLoadingProducts, selected } = this.state;
    const PER_PAGE = 10;
    const MIN_STRING_LENGTH_FOR_SEARCH = 0;

    return (
      <div
        id="product-version"
        className={`form-group ${under ? under.containerClassName : "row"}`}
      >
        <div className={`row`}>
          {label && (
            <div
              className={`${
                under ? under.fieldClassName : "col-sm-4 col-md-3"
              } ${!label || label.length === 0 ? "hidden" : ""}`}
            >
              <label htmlFor="">{label}</label>
            </div>
          )}
          <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
            <div className="form__clear">
              <AsyncTypeahead
                isLoading={isLoadingProducts}
                options={products}
                selected={selected}
                id="product-version-select"
                multiple={true}
                maxResults={PER_PAGE}
                labelKey="name"
                minLength={MIN_STRING_LENGTH_FOR_SEARCH}
                onSearch={this.handleSearch}
                placeholder={
                  placeholder ? placeholder : t("Select the product")
                }
                onChange={this.onChangeProduct}
                paginate={true}
                promptText={t("Type to search...")}
                searchText={t("Searching...")}
                paginationText={t("Show more")}
                emptyLabel={t("Products not found")}
                useCache={false}
                onPaginate={this.onShowMoreResults}
                renderMenuItemChildren={
                  !!customItemRender ? customItemRender : undefined
                }
              />
              {onClear && (
                <div onClick={this.onClear} className="form__clear-button">
                  <XCircle size={15} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SelectMultipleProductWithPagingForFilters.propTypes = {
  t: PropTypes.func,
  fetchProducts: PropTypes.func,
  onClear: PropTypes.func,
  customItemRender: PropTypes.func,
  onChangeProduct: PropTypes.func,
  products: PropTypes.array,
  under: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default translate()(SelectMultipleProductWithPagingForFilters);
