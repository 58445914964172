export default {
  1: {
    days: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    startHour: 9,
    endHour: 17,
  },
  2: {
    days: [{ id: 6 }, { id: 7 }],
    startHour: 9,
    endHour: 17,
  },
  3: {
    days: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
    ],
    startHour: 0,
    endHour: 0,
  },
  4: {
    days: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
    ],
    startHour: 0,
    endHour: 0,
  },
  5: {
    days: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
    ],
    startHour: 0,
    endHour: 0,
  },
  6: {
    days: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
    ],
    startHour: 0,
    endHour: 0,
  },
  default: {
    days: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    startHour: 9,
    endHour: 17,
  },
};
