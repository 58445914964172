import React, { useState } from "react";
import { ProductModule, ProductModuleForForm } from "../Types/ProductModule";
import {
  ChevronsDown,
  ChevronsUp,
  PlusCircle,
  Save,
  Trash,
} from "react-feather";
import { translate } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Field, FieldArray } from "redux-form";
import {
  RenderMultiselect,
  RenderNumber,
  RenderSelect,
  RenderText,
} from "../../../Forms/forms";
import MODULE_TYPES from "../../../Enums/ModuleTypes";
import TimePickerInput from "../../../Forms/TimePickerInput";
import PackagesPrices from "./PackagesPrices";
import ProductsLectures from "../../Products/Containers/ProductsLectures";
import ConfirmModal from "../../../Common/Components/CustomModal/ConfirmModal";
import { getModuleModelForApi } from "../Utils/ModuleObjectMapper";
import { ModulesListProps } from "../Types/ModulesList";
import PRODUCT_STATUSES from "../../../Enums/ProductStatuses";

const ModulesList: React.FunctionComponent<ModulesListProps> = (
  props,
): React.ReactElement => {
  const {
    t,
    change,
    fields,
    fields: { length: modulesCount, get: getField },
    meta,
    productVersionsMeta,
    installments,
    currency,
    currentStatus,
    courseModes,
    onSaveLectures,
    deleteProductModule,
    productVersionId,
    createProductModules,
    updateProductModules,
    handleSubmit,
    savingLecturersInProgress,
  } = props;

  const [modulesListIsCollapse, setModulesListIsCollapse] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [indexModuleForRemove, setIndexModuleForRemove] = useState(null);
  const switchListView = () => setModulesListIsCollapse(!modulesListIsCollapse);

  const shouldDisabled =
    meta.submitting || currentStatus.id !== PRODUCT_STATUSES.DRAFT;

  const onClickRemoveModuleButton = (moduleIndex: number) => {
    setShowRemoveConfirmation(true);
    setIndexModuleForRemove(moduleIndex);
  };

  const handleCancelModuleRemove = () => {
    setShowRemoveConfirmation(false);
    setIndexModuleForRemove(null);
  };

  const handleRemoveModule = (indexModuleForRemove: number) => {
    const moduleForRemove = fields.get(indexModuleForRemove);
    if (moduleForRemove.id) {
      deleteProductModule(productVersionId, moduleForRemove.id, () =>
        fields.remove(indexModuleForRemove),
      );
    } else {
      fields.remove(indexModuleForRemove);
    }
  };

  const handleSaveModule = (module: ProductModule, field: string) => {
    const moduleForSave = getModuleModelForApi(module, courseModes);
    if (module.id) {
      updateProductModules(productVersionId, module.id, moduleForSave);
    } else {
      createProductModules(
        productVersionId,
        moduleForSave,
        ({ data: { data } }) => change(`${field}.id`, data.id),
      );
    }
  };

  const onDragEnd = result => {
    const { fields, productVersionId, modulesOrderChange } = props;

    if (
      !result.destination ||
      result.destination.index === 0 ||
      fields.length === 1
    ) {
      return;
    }

    const items = [...fields.getAll()];
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    const newModulesOrderIds = [];

    fields.removeAll();
    items.forEach((item, index) => {
      if (!!item.id) {
        newModulesOrderIds.push(item.id);
      }

      fields.push({
        ...item,
        number: index,
      });
    });
    if (productVersionId) {
      modulesOrderChange(productVersionId, { ids: newModulesOrderIds });
    }
  };

  const copyOnBlur = event => {
    const inputName = event.target.name;
    if (inputName.indexOf("student") !== -1) {
      const inputValue = event.target.value;
      const targetName = inputName.replace(/student/, "thirdParty");
      change(targetName, inputValue * 100);
    }
  };

  const addModule = () => {
    const {
      productVersionsMeta,
      fields: { length: modulesCount, push: modulePush },
    } = props;

    const [firstModuleType] = productVersionsMeta.moduleTypes;

    const module: ProductModuleForForm = {
      id: null,
      packages: [],
      name: "",
      type: firstModuleType,
      number: modulesCount > 0 ? modulesCount - 1 : 0,
      duration: 0,
      installment: null,
      durationPerStudent: 0,
      prices: [],
      lectures: [],
      breaks: {},
    };
    modulePush(module);
  };

  const packagesSelectOnBlur = (field: string, index: number) => {
    const { packages, prices } = getField(index);

    const newPrices = packages.map(singlePackage => {
      const price = prices.find(price => price.package.id === singlePackage.id);
      return price
        ? price
        : {
            package: singlePackage,
            price: {
              student: { amount: 0, currency: currency.name },
              thirdParty: { amount: 0, currency: currency.name },
            },
          };
    });

    change(`${field}.prices`, newPrices);
  };

  const renderModules = () => {
    return fields.map((field, index) => {
      const module = getField(index);
      const { id: moduleId = null } = module;
      const moduleType = module.type.id;
      const displayLecturesForm = moduleType === MODULE_TYPES.STANDARD;

      const durationTimeActive =
        moduleType === MODULE_TYPES.PORTFOLIO_LAB ||
        moduleType === MODULE_TYPES.CAREER_LAB ||
        moduleType === MODULE_TYPES.SCRUM_LAB ||
        moduleType === MODULE_TYPES.MIX;

      return (
        <Draggable
          isDragDisabled={index === 0 || !modulesListIsCollapse}
          key={index}
          draggableId={index + 1}
          index={index}
        >
          {provided => (
            <li
              ref={provided !== null && provided.innerRef}
              {...(provided !== null ? provided.draggableProps : {})}
              {...(provided !== null ? provided.dragHandleProps : {})}
              id={`module-${moduleId}`}
              key={index}
              className={`module ${
                modulesListIsCollapse ? "module--list" : ""
              }`}
            >
              <div
                className={`${
                  modulesListIsCollapse ? "module--list__num" : "module__num"
                }`}
              >
                <span>{index}</span>
              </div>
              <div
                className={`${
                  modulesListIsCollapse
                    ? "module--list__content"
                    : "module__content"
                }`}
              >
                <Field
                  label={t("Module name")}
                  name={`${field}.name`}
                  component={RenderText}
                  required="required"
                  shouldDisabled={shouldDisabled}
                />

                <Field
                  component={RenderMultiselect}
                  shouldDisabled={shouldDisabled}
                  dropdownConfig={{
                    valueField: "id",
                    textField: "name",
                    placeholder: t("Choose packages"),
                    data: productVersionsMeta.packages,
                  }}
                  customBlurHandler={() => packagesSelectOnBlur(field, index)}
                  required
                  type="text"
                  name={`${field}.packages`}
                  label={t("Choose packages")}
                />
                <Field
                  component={RenderSelect}
                  label={t("Module's type")}
                  shouldDisabled={shouldDisabled}
                  dropdownConfig={{
                    valueField: "id",
                    textField: "name",
                    onChange: type => change(`${field}.type`, type),
                    placeholder: t("Module's type"),
                    data: productVersionsMeta.moduleTypes,
                  }}
                  name={`${field}.type`}
                />
                {moduleType === MODULE_TYPES.PREWORK && (
                  <>
                    <div className="form-group row">
                      <div className="col-sm-4 col-md-3">
                        <label>{t("Duration per student")}</label>
                      </div>
                      <div className="col-sm-8 col-md-9">
                        <Field
                          name={`${field}.durationPerStudent`}
                          component={TimePickerInput}
                          required="required"
                          shouldDisabled={shouldDisabled}
                          onClearCallback={() => {
                            change(`${field}.durationPerStudent`, null);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-4 col-md-3">
                        <label>{t("Duration")}</label>
                      </div>
                      <div className="col-sm-8 col-md-9">
                        <Field
                          name={`${field}.duration`}
                          component={TimePickerInput}
                          required="required"
                          limited={false}
                          shouldDisabled={shouldDisabled}
                          onClearCallback={() => {
                            change(`${field}.duration`, null);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {durationTimeActive && (
                  <>
                    <div>
                      <label>{t("Lecture duration")}</label>
                    </div>

                    <Field
                      label={t("Stationary mode")}
                      name={`${field}.breaks.stationary`}
                      required="required"
                      shouldDisabled={shouldDisabled}
                      component={RenderNumber}
                      parse={string => parseInt(string)}
                      precision={1}
                    />

                    <Field
                      label={t("Part time mode")}
                      name={`${field}.breaks.part_time`}
                      required="required"
                      shouldDisabled={shouldDisabled}
                      component={RenderNumber}
                      parse={string => parseInt(string)}
                      precision={1}
                    />

                    <Field
                      label={t("Online mode")}
                      name={`${field}.breaks.online`}
                      required="required"
                      shouldDisabled={shouldDisabled}
                      component={RenderNumber}
                      parse={string => parseInt(string)}
                      precision={1}
                    />

                    <Field
                      label={t("Free mode")}
                      name={`${field}.breaks.free`}
                      required="required"
                      shouldDisabled={shouldDisabled}
                      component={RenderNumber}
                      parse={string => parseInt(string)}
                      precision={1}
                    />
                  </>
                )}
                <>
                  <div className="form-group row">
                    <div className="col-sm-4 col-md-3" />
                    <div className="col-sm-4 col-md-3">
                      <label>
                        {t("Student")} ({t("gross")})
                      </label>
                    </div>
                    <div className="col-sm-4 col-md-3">
                      <label>
                        {t("Company")} ({t("net")})
                      </label>
                    </div>
                  </div>
                  <FieldArray
                    moduleIndex={index}
                    currencyName={
                      currency && currency.name ? currency.name : ""
                    }
                    t={t}
                    copyOnBlur={copyOnBlur}
                    name={`${field}.prices`}
                    component={PackagesPrices}
                    disabled={shouldDisabled}
                  />
                </>
                <Field
                  label={t("Installment number")}
                  name={`${field}.installment`}
                  dropdownConfig={{
                    data: installments,
                    textField: "name",
                    valueField: "id",
                    onChange: installment =>
                      change(`${field}.installment`, installment),
                  }}
                  component={RenderSelect}
                  required="required"
                  shouldDisabled={shouldDisabled}
                />
                <div>
                  {displayLecturesForm && (
                    <FieldArray
                      name={`${field}.lectures`}
                      component={ProductsLectures}
                      props={{
                        change,
                        status: currentStatus.id,
                        moduleId: moduleId,
                        cantEdit: shouldDisabled || savingLecturersInProgress,
                        meta,
                        productVersionsMeta,
                        courseModes,
                        saveLecturesHandler: (lectures, moduleId) =>
                          onSaveLectures(lectures, moduleId, field),
                        handleSubmit,
                      }}
                    />
                  )}
                  {!shouldDisabled && (
                    <div className={`module-buttons`}>
                      <button
                        type="submit"
                        className="btn btn-regular btn-link btn-with-icon"
                        onClick={handleSubmit(() =>
                          handleSaveModule(module, field),
                        )}
                      >
                        <Save size={20} />
                        {t("Save module")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-regular btn-link btn-with-icon"
                        onClick={() => onClickRemoveModuleButton(index)}
                      >
                        <Trash size={20} />
                        {t("Remove module")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </li>
          )}
        </Draggable>
      );
    });
  };

  return (
    <div className="modules-container">
      <ConfirmModal
        isOpen={showRemoveConfirmation}
        title={t("Are you sure you want to remove this module?")}
        onRequestClose={handleCancelModuleRemove}
        config={{
          body: (
            <p>
              {t("By removing this module, you will not be able to restore it")}
            </p>
          ),
          onRequestConfirm: () => handleRemoveModule(indexModuleForRemove),
        }}
      />
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          {modulesListIsCollapse ? (
            <Droppable
              droppableId="modulesDroppableId"
              isDropDisabled={shouldDisabled || !modulesListIsCollapse}
            >
              {provided => {
                return (
                  <ul
                    className="modules"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {renderModules()}
                    {provided.placeholder}
                  </ul>
                );
              }}
            </Droppable>
          ) : (
            <ul className="modules"> {renderModules()}</ul>
          )}
        </DragDropContext>

        {!shouldDisabled && (
          <button
            id="addModule"
            type="button"
            className="btn btn-regular btn-link btn-with-icon col-md-3"
            onClick={addModule}
          >
            <PlusCircle size={20} />
            {t("Add module")}
          </button>
        )}

        {modulesCount > 0 && (
          <button
            type="button"
            className="btn btn-regular btn-link btn-with-icon col-md-3"
            onClick={switchListView}
            disabled={meta.submitting}
          >
            {modulesListIsCollapse ? (
              <span>
                <ChevronsDown size={20} /> {t("Expand modules")}
              </span>
            ) : (
              <span>
                <ChevronsUp size={20} /> {t("Roll up modules")}
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default translate()(ModulesList);
