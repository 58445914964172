import React, { Component } from "react";
import PropTypes from "prop-types";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import ReactTable from "react-table";
import Loader from "../../../Common/Components/Loader/Loader";
import getNestedObject from "../../../Helpers/GetNestedObject";
import { Field } from "redux-form";
import { RenderText } from "../../../Forms/forms";
import EditDictionaryField from "../Helpers/EditDictionaryField";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";

const FORM_ACTIONS = {
  SAVE: "SAVE",
  CREATE: "CREATE",
};

class PipedriveMap extends Component {
  cancelToken = getCancelToken();

  state = {
    editing: [],
    isLoading: false,
    action: null,
    confirmationPopup: false,
    leadIdForRemove: null,
  };

  componentDidMount() {
    const { fetchUserEngageLeadType } = this.props;
    fetchUserEngageLeadType(this.cancelToken);
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, values } = this.props;
    if (
      initialValues.userEngageLeadTypes !==
      prevProps.initialValues.userEngageLeadTypes
    ) {
      initialize(initialValues);
    } else if (
      prevProps.values &&
      values.userEngageLeadTypes !== prevProps.values.userEngageLeadTypes
    ) {
      initialize(values);
    }
  }

  enableEditMode(row) {
    const { editing } = this.state;
    const rowData = row.original;

    !editing.includes(rowData.id) && editing.push(rowData.id);
    this.setState({ editing });
  }

  removeUserEngageLeadType = () => {
    const { deleteUserEngageLeadType, fetchUserEngageLeadType } = this.props;
    const { leadIdForRemove } = this.state;

    deleteUserEngageLeadType(leadIdForRemove, () => {
      fetchUserEngageLeadType(this.cancelToken);
      this.closeConfirmationModal();
    });
  };

  openConfirmationModal = leadId => {
    this.setState({ leadIdForRemove: leadId, confirmationPopup: true });
  };

  closeConfirmationModal = () => {
    this.setState({ leadIdForRemove: null, confirmationPopup: false });
  };

  renderEditField = row => {
    const { values } = this.props;
    const { editing } = this.state;
    const value = getNestedObject(values[row.index], row.column.id);
    return (
      <div className="editField">
        {!values[row.index].id || editing.indexOf(row.original.id) > -1 ? (
          <div className="dictionary-text__input">
            <Field
              value={value}
              name={`userEngageLeadTypes[${row.index}][${row.column.id}]`}
              component={RenderText}
            />
          </div>
        ) : (
          <EditDictionaryField
            onClick={() => this.enableEditMode(row)}
            text={value}
          />
        )}
      </div>
    );
  };

  renderRemoveField = row => {
    const { t } = this.props;
    const { id: leadId = null } = row.original;

    return (
      <button
        className="btn btn-dark"
        onClick={() =>
          leadId ? this.openConfirmationModal(leadId) : this.removeEmptyLead()
        }
      >
        {t("Delete")}
      </button>
    );
  };

  addUserEngageLeadType = () => {
    const { change, values } = this.props;
    const newUserEngageLeadTypes = [
      {
        pdValue: null,
        ueValue: null,
      },
      ...values,
    ];
    change("userEngageLeadTypes", newUserEngageLeadTypes);
  };

  submitForm = (action, rowId) => {
    const { handleSubmit } = this.props;
    const submitter = handleSubmit(this.onSubmit);

    this.setState(
      {
        action,
        rowId,
      },
      submitter,
    );
  };

  onSubmit = values => {
    const {
      addUserEngageLeadType,
      updateUserEngageLeadType,
      fetchUserEngageLeadType,
    } = this.props;
    const { action } = this.state;

    const userEngageLeadType = values.userEngageLeadTypes.find(
      userEngageLeadType => userEngageLeadType.id === this.state.rowId,
    );

    switch (action) {
      case FORM_ACTIONS.SAVE:
        updateUserEngageLeadType(userEngageLeadType, () => {
          fetchUserEngageLeadType(this.cancelToken);
          const { editing } = this.state;
          const rowIndex = editing.indexOf(userEngageLeadType.id);

          rowIndex >= 0 && editing.splice(rowIndex, 1);
          this.setState({
            editing,
          });
        });
        break;

      case FORM_ACTIONS.CREATE:
        addUserEngageLeadType(userEngageLeadType, () =>
          fetchUserEngageLeadType(this.cancelToken),
        );

        break;
    }
  };

  removeEmptyLead = () => {
    const { change, values } = this.props;
    const newUserEngageLeadTypes = values.filter(value => value.id);

    change("userEngageLeadTypes", newUserEngageLeadTypes);
  };

  render() {
    const { t, values } = this.props;
    const { isLoading, confirmationPopup } = this.state;
    const addButtonDisabled = values && values.find(value => !value.id);

    return values ? (
      <div>
        <div className="row">
          <Authorize
            component={
              <button
                disabled={addButtonDisabled}
                className="btn bottom-space btn btn-dark"
                onClick={this.addUserEngageLeadType}
              >
                {t("Add new mapping")}
              </button>
            }
            allows={[permissions.DICTIONARY_EDIT]}
          />
        </div>
        <div className="row">
          <ReactTable
            noDataText={t("No records found")}
            loading={isLoading}
            data={values}
            columns={[
              {
                Header: t("No."),
                accessor: "id",
                Cell: row => <span>{row.index + 1}.</span>,
                resizable: false,
                width: 50,
              },
              {
                Header: t("PipeDrive"),
                accessor: "pdValue",
                Cell: this.renderEditField,
              },
              {
                Header: t("UserEngage"),
                accessor: "ueValue",
                Cell: this.renderEditField,
              },
              {
                Header: t("Delete"),
                Cell: this.renderRemoveField,
              },
              {
                Header: "",
                Cell: row => {
                  const rowData = row.original;
                  return rowData.id ? (
                    this.state.editing.indexOf(rowData.id) > -1 && (
                      <button
                        onClick={() => {
                          this.submitForm(FORM_ACTIONS.SAVE, rowData.id);
                        }}
                        className="btn btn-dark"
                      >
                        {t("Submit")}
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        this.submitForm(FORM_ACTIONS.CREATE, rowData.id);
                      }}
                      className="btn btn-dark"
                    >
                      {t("Add")}
                    </button>
                  );
                },
              },
            ]}
            className="dictionaryTable"
            defaultPageSize={10}
            showPagination={true}
          />
        </div>
        <CustomModal
          isOpen={confirmationPopup}
          title={t("Remove lead map")}
          onRequestClose={this.closeConfirmationModal}
        >
          <div className="row">
            <div className="col-xs-12 padding__b20">
              {t("Are You sure You want to remove lead map?")}
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-dark"
              onClick={this.removeUserEngageLeadType}
            >
              {t("Yes")}
            </button>
            <button
              type="button"
              className="btn btn-regular"
              onClick={this.closeConfirmationModal}
            >
              {t("No")}
            </button>
          </div>
        </CustomModal>
      </div>
    ) : (
      <Loader />
    );
  }
}

PipedriveMap.propTypes = {
  fetchUserEngageLeadType: PropTypes.func,
  addUserEngageLeadType: PropTypes.func,
  updateUserEngageLeadType: PropTypes.func,
  deleteUserEngageLeadType: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  change: PropTypes.func,
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    userEngageLeadTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        pdValue: PropTypes.string,
        ueValue: PropTypes.string,
      }),
    ).isRequired,
  }),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pdValue: PropTypes.string,
      ueValue: PropTypes.string,
    }).isRequired,
  ),
};

export default translate()(PipedriveMap);
