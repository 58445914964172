import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { fetchPersons, fetchPersonTypes } from "../Actions";
import { fetchDictionary } from "../../Dictionaries/Actions";
import { forgetUser } from "../../Auth/Actions";
import PersonListElement from "../Components/PersonListElement";
import SecondaryMenu from "../../App/Components/MainTemplateSecondaryMenu.js";
import { PlusCircle, Search, HelpCircle } from "react-feather";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import _ from "lodash";
import Paginator from "../../../Common/Components/Paginator/Paginator";
import Loader from "../../../Common/Components/Loader/Loader";
import COMMON from "../../../Enums/Common";
import ACTION_TYPES from "../../../Enums/ActionTypes";
import PERSON_TYPES from "../../../Enums/PersonTypes";
import { Authorize, permissions } from "../../../Common/Utils/Acl";
import getCancelToken from "../../../Helpers/GetCancelToken";
import { translate } from "react-i18next";
import { RenderSelect } from "../../../Forms/forms";
import { fetchLecturesForImport } from "../../Products/Actions";
import CustomModal from "../../../Common/Components/CustomModal/CustomModal";
import NestedList from "../../../Common/Components/NestedList/NestedList";
import NestedEmployeeCompetency from "../Components/NestedEmployeeCompetency";
import NestedProductElement from "../../Products/Components/NestedProductElement";
import NestedVersionElement from "../../Products/Components/NestedVersionElement";
import NestedModulesElement from "../../Products/Components/NestedModulesElement";
import NestedLectureElement from "../../Products/Components/NestedLectureElement";
import PropTypes from "prop-types";

const FORM_NAME = "SEARCH_PERSONS";
const selector = formValueSelector(FORM_NAME);

class PersonsList extends Component {
  static propTypes = {
    fetchPersons: PropTypes.func,
    fetchPersonTypes: PropTypes.func,
    fetchLecturesForImport: PropTypes.func,
    fetchDictionary: PropTypes.func,
    lecturesForImport: PropTypes.array,
    change: PropTypes.func,
    values: PropTypes.object,
    forgetUser: PropTypes.func,
    personTypes: PropTypes.array,
    t: PropTypes.func,
    dictionaries: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    persons: PropTypes.object,
    secondaryMenu: PropTypes.object,
    noAccess: PropTypes.bool,
    cantEdit: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      numberOfRowPerPage: 10,
      currentPage: 1,
      cancelToken: getCancelToken(),
      isSearchPupupExist: false,
      selectedEmployeeCompetencies: [],
      userSelectedCompetencies: [],
      userSelectedAdditionalCompetencies: [],
    };

    props.fetchPersons(this.state.cancelToken, { emptyEmails: 1 });
    props.fetchPersonTypes(this.state.cancelToken);
  }

  componentDidMount() {
    this.props.fetchLecturesForImport(this.cancelToken);
    this.props.fetchDictionary(this.cancelToken, {
      path: "/employee/competencies",
      actionType: ACTION_TYPES.FETCH_EMPLOYEE_COMPETENCIES,
    });
  }

  componentWillUnmount() {
    this.state.cancelToken.cancel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lecturesForImport !== this.state.lecturesForImport) {
      this.setState({ lecturesForImport: prevProps.lecturesForImport });
    }
  }

  filterType = value => {
    this.props.change("personType", value);
    this.setState({ currentPage: 1 });
    this.getFilteredPersons({
      filterType: value.id,
      page: 1,
    });
  };

  searchPerson = _.debounce(event => {
    const { value } = event.currentTarget;
    this.setState({ currentPage: 1 }, () => {
      this.getFilteredPersons({
        filterText: value,
        page: 1,
      });
    });
  }, 300);

  changePage = currentPage => {
    this.setState({ currentPage }, () => {
      this.getFilteredPersons({ page: currentPage });
    });
  };

  getFilteredPersons({ page = null, filterText = null, filterType = null }) {
    const {
      numberOfRowPerPage,
      currentPage,
      userSelectedCompetencies,
      userSelectedAdditionalCompetencies,
    } = this.state;
    const {
      fetchPersons,
      values: { search, personType },
    } = this.props;
    const personTypeId = personType && personType.id;

    this.state.cancelToken.cancel();
    this.setState({ cancelToken: getCancelToken() }, () => {
      fetchPersons(this.state.cancelToken, {
        type: filterType ? filterType : personTypeId,
        search: filterText ? filterText : search,
        pageNo: page ? page : currentPage,
        pageAmount: numberOfRowPerPage,
        emptyEmails: 1,
        competencies: userSelectedCompetencies,
        additionalCompetencies: userSelectedAdditionalCompetencies,
      });
    });
  }

  clearFilter = () => {
    const { numberOfRowPerPage, currentPage } = this.state;
    const {
      fetchPersons,
      change,
      values: { search },
    } = this.props;

    change("personType", null);

    fetchPersons(this.cancelToken, {
      search: search,
      pageNo: currentPage,
      pageAmount: numberOfRowPerPage,
      emptyEmails: 1,
    });
  };

  logout = () => {
    this.props.forgetUser();
  };

  renderPersonsList() {
    const { personTypes, t, persons } = this.props;

    return !!persons.items ? (
      persons.items.map(person => {
        person.positions = person.types
          ? person.types.map(personTypeId => {
              for (let i = 0; i < personTypes.length; i++) {
                if (personTypes[i].id === personTypeId) {
                  return t(PERSON_TYPES[personTypes[i].id].firstUpperCase);
                }
              }
            })
          : [];
        return (
          <li key={person.id}>
            <PersonListElement {...person} />
          </li>
        );
      })
    ) : (
      <Loader />
    );
  }

  onCompetenciesListUpdate(values, items) {
    this.setState({ lecturesForImport: items }, () => {
      this.props.change("competenciesIds", values);
    });
  }
  onEmployeeCompetenciesListUpdate(values) {
    const {
      change,
      dictionaries: { employeeCompetencies },
    } = this.props;
    change("additionalCompetenciesIds", values);
    const selectedEmployeeCompetencies = employeeCompetencies.filter(
      competency => {
        return (
          competency.id ===
          values.find(competency_id => competency_id === competency.id)
        );
      },
    );
    this.setState({ selectedEmployeeCompetencies });
  }
  onPopupSubmit = event => {
    event.preventDefault();
    const {
      fetchPersons,
      values: { competenciesIds, additionalCompetenciesIds },
    } = this.props;
    const competencies = competenciesIds ? competenciesIds : [];
    const additionalCompetencies = additionalCompetenciesIds
      ? additionalCompetenciesIds
      : [];

    this.setState({
      userSelectedCompetencies: competencies,
      userSelectedAdditionalCompetencies: additionalCompetencies,
    });

    if (
      (competenciesIds && competenciesIds.length > 0) ||
      (additionalCompetenciesIds && additionalCompetenciesIds.length > 0)
    ) {
      fetchPersons(
        this.state.cancelToken,
        {
          emptyEmails: 1,
          competencies,
          additionalCompetencies,
        },
        () => {
          this.setState({ isSearchPupupExist: false });
        },
      );
    }
  };
  renderChosenCompetencies() {
    let selectedMainCompetencies = [];
    let selectedAdditionalCompetencies = [];
    let competencies = [];
    const {
      lecturesForImport,
      values: { competenciesIds },
    } = this.props;
    const { selectedEmployeeCompetencies } = this.state;

    lecturesForImport &&
      lecturesForImport.map(product => {
        let productName = product.productName;
        product.versions &&
          product.versions.map(version => {
            version.modules &&
              version.modules.map(module => {
                module.lectures &&
                  module.lectures.map((lecture, index) => {
                    const lectureIsChecked =
                      competenciesIds && competenciesIds.indexOf(lecture.id);
                    if (lectureIsChecked >= 0) {
                      selectedMainCompetencies.push(
                        <span
                          key={`${index}-${product.productId}-${productName}-${version.version}-${lecture.id}`}
                          className="label label-warning"
                        >
                          {lecture.name}
                        </span>,
                      );
                    }
                  });
              });
          });
      });

    if (selectedEmployeeCompetencies) {
      selectedEmployeeCompetencies.map((competency, index) => {
        selectedAdditionalCompetencies.push(
          <span
            className="label label-success"
            key={`${index}-${competency.id}-additional`}
          >
            {competency.name}
          </span>,
        );
      });
    }
    competencies = [
      ...selectedMainCompetencies,
      ...selectedAdditionalCompetencies,
    ];
    return competencies;
  }

  render() {
    const {
      t,
      values: { competenciesIds, additionalCompetenciesIds },
      dictionaries: { employeeCompetencies },
      lecturesForImport,
    } = this.props;
    const personTypesList = this.props.personTypes.map(person => ({
      ...person,
      name: t(PERSON_TYPES[person.id].lowerCase),
    }));
    return (
      <main>
        <div className="custom-container">
          <header className="main-header">
            <div className="row">
              <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6 pull-right main-header__flex">
                <Authorize
                  component={
                    <div className="col-md-8 col-xs-12">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <Search size={16} />
                        </div>
                        <Field
                          onChange={this.searchPerson}
                          className="form-control"
                          name="search"
                          component="input"
                          type="text"
                        />
                      </div>
                    </div>
                  }
                  allows={[permissions.PERSON_SEARCH]}
                />

                <button
                  onClick={this.logout}
                  className="btn btn--logout pull-right"
                >
                  {t("Log out")}
                </button>
                <a
                  href={COMMON.URL.MANUAL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--logout pull-right left-space"
                >
                  <HelpCircle size={16} /> {t("Help")}
                </a>
              </div>
              <div className="col-sm-4 col-md-6 col-lg-6">
                <h1>{t("Persons")}</h1>
              </div>
            </div>
          </header>
          <SecondaryMenu links={this.props.secondaryMenu} />
        </div>
        <div className=" row">
          <div className="col-xs-12 content">
            <div className="custom-container">
              <div>
                <div className="row products__nav">
                  <div className="col-md-8 col-xs-12">
                    <Authorize
                      component={
                        <Link
                          to="/persons/new"
                          className="btn btn-regular btn-link btn-with-icon"
                        >
                          <PlusCircle size={20} />
                          {t("Add person")}
                        </Link>
                      }
                      allows={[permissions.PERSON_DETAILS_EDIT]}
                    />
                  </div>
                  <div className="col-md-4 col-xs-12" />
                </div>
                <Authorize
                  component={
                    <div className="row padding-top10 products__filters">
                      <Field
                        component={RenderSelect}
                        name="personType"
                        dropdownConfig={{
                          data: personTypesList,
                          textField: "name",
                          valueField: "name",
                          placeholder: t("Type"),
                          onChange: this.filterType,
                        }}
                        under={{
                          containerClassName: "col-sm-2 col-xs-6",
                          fieldClassName: "col-xs-12",
                        }}
                        onClearCallback={this.clearFilter}
                      />
                      <div className="col-sm-10 col-xs-6">
                        <button
                          className="btn btn-dark btn-margin pull-right search-person__button"
                          onClick={() => {
                            this.setState({
                              isSearchPupupExist: true,
                            });
                          }}
                        >
                          <Search size={16} />{" "}
                          {t("Search persons by competences")}
                        </button>
                      </div>
                    </div>
                  }
                  allows={[permissions.PERSON_SEARCH]}
                />
                <div className="row">
                  <ul className="list-unstyled">{this.renderPersonsList()}</ul>
                </div>
                <div className="row">
                  {this.props.persons.counter >
                  this.state.numberOfRowPerPage ? (
                    <Paginator
                      onClick={this.changePage}
                      pages={Math.ceil(
                        this.props.persons.counter /
                          this.state.numberOfRowPerPage,
                      )}
                      currentPage={this.state.currentPage}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isSearchPupupExist}
          title={t("Search persons by competences")}
          onRequestClose={() => {
            this.setState({ isSearchPupupExist: false });
          }}
          ariaHideApp={false}
        >
          <div className="row searchCompetences">
            <div className="col-md-12 col-sm-12">
              <NestedList
                dataSource={lecturesForImport || []}
                values={
                  this.props.values && competenciesIds ? competenciesIds : []
                }
                config={[
                  {
                    childrenKey: "versions",
                    decorator: NestedProductElement,
                    checkAvailable: true,
                  },
                  {
                    childrenKey: "modules",
                    decorator: NestedVersionElement,
                    checkAvailable: true,
                  },
                  {
                    childrenKey: "lectures",
                    decorator: NestedModulesElement,
                    checkAvailable: true,
                  },
                  {
                    decorator: NestedLectureElement,
                    checkAvailable: true,
                    key: "id",
                  },
                ]}
                onListUpdate={(values, items) => {
                  this.onCompetenciesListUpdate(values, items);
                }}
                shouldDisabled={this.props.cantEdit}
              />

              <h2>{t("Additional Competencies")}</h2>

              <NestedList
                dataSource={employeeCompetencies || []}
                values={
                  this.props.values && additionalCompetenciesIds
                    ? additionalCompetenciesIds
                    : []
                }
                config={[
                  {
                    childrenKey: "none",
                    decorator: NestedEmployeeCompetency,
                    checkAvailable: true,
                    key: "id",
                  },
                ]}
                onListUpdate={values => {
                  this.onEmployeeCompetenciesListUpdate(values);
                }}
                shouldDisabled={this.props.cantEdit}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h2>{t("Your selected competences")}</h2>
              <div className="labels__list">
                {this.renderChosenCompetencies()}
              </div>
              <button
                onClick={this.onPopupSubmit}
                className="btn btn-dark btn-margin pull-left"
                disabled={
                  (!competenciesIds || competenciesIds.length === 0) &&
                  (!additionalCompetenciesIds ||
                    additionalCompetenciesIds.length === 0)
                }
              >
                {t("Search persons")}
              </button>
            </div>
          </div>
        </CustomModal>
      </main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPersons,
      fetchPersonTypes,
      forgetUser,
      fetchLecturesForImport,
      fetchDictionary,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { persons, personTypes, lecturesForImport, dictionaries } = state;
  const values = selector(
    state,
    "personType",
    "search",
    "competenciesIds",
    "additionalCompetenciesIds",
  );
  return {
    persons,
    personTypes,
    values,
    dictionaries,
    lecturesForImport,
  };
}

function validate(fields) {
  const errors = {};
  if (!fields.search) {
    errors.search = "Wpisz czego szukasz.";
  }
  return errors;
}

export default reduxForm({
  validate,
  form: FORM_NAME,
  initialValues: {
    competenciesIds: [],
  },
})(
  compose(
    translate(),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(PersonsList),
);
