import React from "react";
import { translate } from "react-i18next";
import { InjectedTranslateProps } from "react-i18next/src/props";
import { FieldProps } from "formik";
import { Multiselect } from "react-widgets";
import { XCircle } from "react-feather";
import { RenderError } from "../forms";
import { renderFieldsHelper } from "../FormikUtils";

type MultiSelectProps<T> = InjectedTranslateProps &
  FieldProps & {
    label?: string;
    className?: string;
    required?: boolean;
    disabled?: boolean;
    under?: {
      containerClassName?: string;
      fieldClassName?: string;
    };
    dropdownConfig?: {
      data: Array<T>;
      textField?: null | string;
      valueField?: string;
      placeholder?: string;
      onClearCallback?: () => void;
      onChange?: (item: T) => void;
    };
  };

const MultiSelect: React.FunctionComponent<MultiSelectProps<unknown>> = (
  props,
): React.ReactElement => {
  const { classes, displayError, error } = renderFieldsHelper(props);
  const {
    t,
    field,
    form,
    label,
    disabled,
    className,
    under,
    required,
    dropdownConfig: {
      data,
      valueField,
      textField,
      placeholder,
      onClearCallback,
      onChange: customChange,
    },
  } = props;

  return (
    <div
      className={`form-group ${className} ${classes} ${
        under ? under.containerClassName : "row"
      }`}
    >
      <div className={under ? "row" : ""}>
        <div
          className={`${required ? "required" : ""} ${
            under ? under.fieldClassName : "col-sm-4 col-md-3"
          } ${!label || label.length === 0 ? "hidden" : ""}`}
        >
          <label htmlFor="">{label}</label>
        </div>
        <div className={under ? under.fieldClassName : "col-sm-8 col-md-9"}>
          <div className="form__clear">
            <Multiselect
              id={field.name}
              disabled={disabled}
              data={data}
              value={field.value || []}
              filter="contains"
              placeholder={placeholder ? placeholder : t("Select")}
              valueField={valueField}
              textField={textField}
              onChange={
                customChange
                  ? item => customChange(item)
                  : item => form.setFieldValue(field.name, item)
              }
            />
            {onClearCallback && !disabled && (
              <div onClick={onClearCallback} className="form__clear-button">
                <XCircle size={15} />
              </div>
            )}
          </div>
          {displayError ? <RenderError t={t} error={error} /> : ""}
        </div>
      </div>
    </div>
  );
};

export const RenderMultiselect = translate()(MultiSelect);
