import React from "react";
import ListElement from "../../../Common/Components/ListElement/ListElement";
import { User } from "react-feather";
import PropTypes from "prop-types";

const LecturersList = ({ lectureData, lecturerSkillsValue }) => (
  <div className="col-sm-12 col-md-12">
    {lectureData.length > 0 ? (
      <ul className="list-unstyled">
        {lectureData.map(competencies => {
          const filteredCompetencies = competencies.lecturers.filter(
            lecturer => {
              return (
                lecturerSkillsValue &&
                lecturer.name
                  .toLowerCase()
                  .includes(lecturerSkillsValue.toLowerCase())
              );
            },
          );
          const competenciesList =
            lecturerSkillsValue && lecturerSkillsValue.length > 0
              ? filteredCompetencies
              : competencies.lecturers;

          return competenciesList.map(user => {
            return (
              <li key={user.id}>
                <ListElement icon={<User size={30} />}>
                  <div>
                    <h4 className="text product-name">{user.name}</h4>
                    <div className="product-info">
                      <p>
                        {user.phone.length > 0 && (
                          <span className="product-info-details">
                            {user.phone.map((phone, index) =>
                              index > 0 && index & 1 ? " / " + phone : phone,
                            )}
                          </span>
                        )}
                        {user.email.length > 0 && (
                          <span className="product-info-details">
                            {user.email.map((email, index) =>
                              index > 0 && index & 1 ? " / " + email : email,
                            )}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </ListElement>
              </li>
            );
          });
        })}
      </ul>
    ) : (
      <div>Brak danych</div>
    )}
  </div>
);

LecturersList.propTypes = {
  lectureData: PropTypes.array,
  lecturerSkillsValue: PropTypes.object,
};

export default LecturersList;
