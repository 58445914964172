import React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import getCancelToken from "../../../Helpers/GetCancelToken";
import Loader from "../../../Common/Components/Loader/Loader";
import TimeLineCommentHeader from "./TimeLineCommentHeader";

class TimeLineComment extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    commentPosition: PropTypes.array,
    commentData: PropTypes.object,
    employees: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fetchComment: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
    comment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideComment: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
    const { fetchComment, commentData } = props;

    this.state = {
      value: "",
      showComment: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);

    fetchComment(this.cancelToken, {
      type: commentData.objectType || commentData.data.comment.objectType,
      id: commentData.objectId || commentData.data.comment.objectId,
    }).then(this.populateValue);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  populateValue = prevProps => {
    const { comment } = this.props;
    if (!this.state.value && comment !== prevProps.comment) {
      this.setState({
        value: comment || "",
        showComment: true,
      });
    }
  };

  handleChange = event => {
    this.setState({
      value: event.target.value,
    });
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideComment(false);
    }
  };

  submitComment = () => {
    const { commentData, hideComment } = this.props;
    if (this.state.value) {
      this.props
        .updateComment({
          type: commentData.objectType || commentData.data.comment.objectType,
          id: commentData.objectId || commentData.data.comment.objectId,
          text: this.state.value,
        })
        .then(() => hideComment(true));
    } else {
      this.props
        .deleteComment({
          type: commentData.objectType || commentData.data.comment.objectType,
          id: commentData.objectId || commentData.data.comment.objectId,
        })
        .then(() => hideComment(true));
    }
  };

  render() {
    const { t, commentPosition, employees, commentData } = this.props;
    return (
      <div
        className="timeline__comment"
        ref={this.setWrapperRef}
        style={{
          left: commentPosition[0],
          top: commentPosition[1],
        }}
      >
        <TimeLineCommentHeader data={commentData} t={t} />
        <div className="timeline__comment__text">
          {this.state.showComment ? (
            <MentionsInput
              value={this.state.value}
              className="timeline__textarea"
              onChange={this.handleChange}
              autoFocus
              displayTransform={(identifier, name) => `@${name}`}
              markup="@[__display__](__id__)"
              spellCheck="false"
            >
              <Mention
                trigger="@"
                data={employees}
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused,
                ) => {
                  return focused ? (
                    <div className="timeline__comment__user--focused">
                      {highlightedDisplay}
                    </div>
                  ) : (
                    <div className="timeline__comment__user">
                      {highlightedDisplay}
                    </div>
                  );
                }}
              />
            </MentionsInput>
          ) : (
            <Loader style={{ height: "100%" }} />
          )}
        </div>
        <div className="timeline__comment__submit">
          <button
            className="btn btn-dark btn-margin"
            onClick={this.submitComment}
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    );
  }
}

export default TimeLineComment;
