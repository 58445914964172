import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import StudentPoll from "./StudentPoll";

import getCancelToken from "../../../Helpers/GetCancelToken";

class StudentPolls extends Component {
  static propTypes = {
    contractPolls: PropTypes.array,
    contractModulesPolls: PropTypes.array,
    contractSlavePolls: PropTypes.array,
    contractManualPolls: PropTypes.array,
    handleSendPoll: PropTypes.func,
    t: PropTypes.func,
    contract: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.cancelToken = getCancelToken();
  }

  componentWillUnmount() {
    this.cancelToken.cancel();
  }

  render() {
    const {
      t,
      contractPolls,
      contractModulesPolls,
      contractSlavePolls,
      contractManualPolls,
      handleSendPoll,
      contract,
    } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <h4 className="col-xs-12 horizontal-center">
              {t("Course partial ratings")}
            </h4>
            {contractPolls.map(item => (
              <StudentPoll
                {...item}
                key={item.id}
                handleSendPoll={handleSendPoll}
                contract={contract}
              />
            ))}
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="col-xs-12 horizontal-center">
              {t("Modules ratings")}
            </h4>
            {contractModulesPolls.map(item => (
              <StudentPoll
                {...item}
                key={item.id}
                handleSendPoll={handleSendPoll}
                contract={contract}
              />
            ))}
          </div>
          <div className="col-xs-12 col-md-4">
            <h4 className="col-xs-12 horizontal-center">
              {t("Slave ratings")}
            </h4>
            {contractSlavePolls.map(item => (
              <StudentPoll
                {...item}
                key={item.id}
                handleSendPoll={handleSendPoll}
                contract={contract}
              />
            ))}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <h4 className="col-xs-12 horizontal-center">
              {t("Manual ratings")}
            </h4>
            {contractManualPolls.map(item => (
              <StudentPoll
                {...item}
                key={item.id}
                handleSendPoll={handleSendPoll}
                contract={contract}
              />
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default translate()(StudentPolls);
