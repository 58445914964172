import React from "react";
import { User, Phone, Mail } from "react-feather";
import ListElement from "../../../Common/Components/ListElement/ListElement";
import PropTypes from "prop-types";
import dateHelper from "../../../Helpers/DateHelper";
import TIME from "../../../Enums/Time";
import { Link } from "react-router-dom";
import { translate } from "react-i18next";
import { havePermissions, permissions } from "../../../Common/Utils/Acl";

const PersonListElement = props => {
  const {
    id,
    name,
    surname,
    phones,
    emails,
    positions,
    currentModule,
    actions: { view },
    t,
  } = props;
  const renderPhone = phones.length > 0 && (
    <span className="product-info-details">
      <Phone size={16} />
      {phones[0]}
    </span>
  );
  const renderEmail = emails.length > 0 && (
    <span className="product-info-details">
      <Mail size={16} />
      {emails[0]}
    </span>
  );
  const renderPositions = positions.length > 0 && (
    <span className="product-info-details">{positions.join(", ")}</span>
  );
  return (
    <ListElement
      icon={<User size={30} />}
      path={`/persons/update/${id}`}
      id={id}
      actions={
        currentModule && (
          <div className="col-sm-12 lecturer-status">
            <Link
              className="lecturer-status__current-signature"
              to={`/courses/${currentModule.courseId}/settings`}
            >
              {currentModule.courseSignature}
            </Link>
            <span>{`${t("Module")} ${currentModule.moduleNr}: ${
              currentModule.moduleName
            }`}</span>
            <span>{`${dateHelper(currentModule.startDate)
              .get()
              .format(TIME.DATE_FORMAT)} - ${dateHelper(currentModule.endDate)
              .get()
              .format(TIME.DATE_FORMAT)}`}</span>
          </div>
        )
      }
      clickable={view && havePermissions([permissions.PERSON_DETAILS_VIEW])}
    >
      <div>
        <h4 className="text product-name">
          {name} {surname}
        </h4>

        <div className="product-info">
          <p>
            {renderPositions}
            {renderPhone}
            {renderEmail}
          </p>
        </div>
      </div>
    </ListElement>
  );
};

PersonListElement.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  surname: PropTypes.string,
  phones: PropTypes.array,
  emails: PropTypes.array,
  positions: PropTypes.array,
  currentModule: PropTypes.object,
  t: PropTypes.func,
  actions: PropTypes.object,
};

export default translate()(PersonListElement);
